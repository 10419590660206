import React, { useEffect } from "react";
import apiGlobal from "../../../global/api.global";
import { useSelector } from "react-redux";
import { RootState } from "../../../";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    Form,
    Input,
    Label,
    Row,
} from "reactstrap";
import { errorToast, successToast } from "../../../Components/Toasts";
import { errResponse, isConfigurationButtonDisabled } from "GenericForms/Helper";
import { loadReeferContainerWithkWh } from "VesselMaster/vesselMaster.hooks";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import { FormikProps, useFormik } from "formik";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { queryClient } from "react-query/queryClient";
import { SpOpsConfigurationConstant } from "shared/constants";
import isEqual from "fast-deep-equal";

interface ReeferContainerType {
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;  
    activeTab: any;
    setTabFormik?: (value: FormikProps<any>) => void;
    refreshForm:boolean;
    refreshVesselMachineries:number;
}

const ReeferContainer = ({
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    setFormik,
    activeTab,
    setTabFormik,
    refreshForm,
    refreshVesselMachineries,
}: ReeferContainerType) => {
    /** Retrieves the VesselID from the Redux store. */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /**
     * Fetches the reefer container data along with machinery kWh meter details using the `useQuery` hook.
     * It loads data specific to the vessel's ID and keeps the result in cache indefinitely (`staleTime: Infinity`).
     */
    const {
        data: ReeferContainer,
        isLoading: ReeferContainerLoading,
        isError: ReeferContainerError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.ReeferContainerWithkWhMachineries.key, VesselID],
        async () => {
            return await loadReeferContainerWithkWh(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /**
     * Determines the initial values for the reefer container form based on the fetched data.
     * If there is any reefer container data available, it is returned for use as initial values.
     */
    const getReeferContainerInitailValues = () => {
        if (ReeferContainer?.length > 0) {
            return ReeferContainer;
        }
    };

    /** Defines the Formik configuration object for handling the reefer container form. */
    const ReeferContainerFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            reeferContainer: getReeferContainerInitailValues(),
        },
        onSubmit: () => {
            ReeferContainerFormik.setSubmitting(false);
            ReeferContainerFormik?.values?.reeferContainer?.forEach((val: any) => {
                apiGlobal
                    .put(`/special_operation_energy_setting/${val.id}/`, val)
                    .then((res) => {
                        if (res.status === 200) {
                            successToast("Data saved successfully!");
                            queryClient.invalidateQueries(queryKeyes.vessel.ReeferContainerWithkWhMachineries.key)
                        }
                    })
                    .catch((err) => {
                        if (errResponse.includes(err?.response?.status)) {
                            errorToast(
                                "Internal error occured, please contact the admin"
                            );
                        }
                    });
            });
        }
    });

    const tabName = 'Reefer Container';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = isEqual(values,initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
    };

    useEffect(() => {
        if (activeTab === SpOpsConfigurationConstant.SpOpsReeferContainerId && ReeferContainerFormik.values !== ReeferContainerFormik.initialValues) {
            setFormik(ReeferContainerFormik);
            setTabFormik(ReeferContainerFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [activeTab,  ReeferContainerFormik]);
    useEffect(() => {
        handleFormikStateChange(ReeferContainerFormik?.values, ReeferContainerFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ReeferContainerFormik?.values, ReeferContainerFormik?.initialValues]);


    if (ReeferContainerLoading) {
        return <Loading message='Loading required data!' />
    } else if (ReeferContainerError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <>
                <Card className="border-0">
                    {ReeferContainerLoading && <Loading message="Loading required data!" />}
                    {ReeferContainerError && (
                        <ErrorComponent message="Error loading required data!" />
                    )}
                    {!ReeferContainerLoading && !ReeferContainerError && (
                        <Form
                            onSubmit={ReeferContainerFormik?.handleSubmit}
                            noValidate
                            autoComplete="off"
                        >
                            <CardBody className="p-0">
                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive p-0">
                                            <table className="table mb-2" key={`${refreshVesselMachineries}-${refreshForm}-reefer-container`}>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="p-2 text-center align-middle sr-no-width">
                                                            #
                                                        </th>
                                                        <th className="p-2 align-middle">
                                                            Machinery name
                                                        </th>
                                                        <th className="p-2 align-middle text-center">
                                                            Is kWh meter fitted on power pack/electrical
                                                            motor panel?
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ReeferContainerFormik?.initialValues?.reeferContainer?.map(
                                                        (reeferContainer: any, index: number) => (
                                                            <tr key={index}>
                                                                <td className="p-2 align-middle text-center">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="p-2 align-middle">
                                                                    {
                                                                        reeferContainer.vessel_machinery_ec_group_name
                                                                    }
                                                                </td>
                                                                <td className="p-2 align-middle text-center">
                                                                    <div className="square-switch sqswitch mt-1">
                                                                        <Input
                                                                            type="checkbox"
                                                                            switch="none"
                                                                            name={`reeferContainer.${index}.reefer_container_kwh_meter`}
                                                                            id={`reefer_container_kwh_meter_${index}`}
                                                                            defaultChecked={
                                                                                ReeferContainerFormik
                                                                                    ?.values
                                                                                    ?.reeferContainer?.[
                                                                                    index
                                                                                ]
                                                                                    ?.reefer_container_kwh_meter
                                                                            }
                                                                            onChange={(e: any) =>
                                                                                ReeferContainerFormik.setFieldValue(
                                                                                    `reeferContainer.${index}.reefer_container_kwh_meter`,
                                                                                    e.target.checked
                                                                                )
                                                                            }
                                                                        />
                                                                        <Label
                                                                            htmlFor={`reefer_container_kwh_meter_${index}`}
                                                                            data-on-label="Yes"
                                                                            data-off-label="No"
                                                                            className="mb-0"
                                                                        ></Label>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="p-2 py-3 mb-3">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="justify_right"
                                    disabled={isConfigurationButtonDisabled(vessel)}
                                >
                                    Save
                                </Button>
                            </CardFooter>
                            <Row className="mt-2">
                                <FormValuesDebug
                                    values={[
                                        ReeferContainerFormik?.values,
                                        ReeferContainerFormik?.errors,
                                        ReeferContainerFormik.initialValues,
                                    ]}
                                />
                            </Row>
                        </Form>
                    )}
                </Card>
            </>
        );
    }

};
export default ReeferContainer;
