import React, { useEffect, useState } from 'react'
import classnames from "classnames";
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import SatelliteCommunication from 'VesselMaster/AdditionalInformation/SatelliteComm';
import VesselGeneralInfo from 'VesselMaster/AdditionalInformation/VesselGeneralInfo/VesselGeneralInfo';
import EmailReporting from 'VesselMaster/AdditionalInformation/EmailReporting';
import SaveBeforeChange from 'VesselMaster/SaveBeforeChange';
import PopUp from 'Components/PopUp';
import { FormikProps } from 'formik';
import {VesselConfigrationTabs, VesselDataConstants} from 'shared/constants';
interface VesselDataType {
    setCheckValuesBeforeSwitch: (value: boolean) => void;
    setTabName: (value: string) => void;
    VesselConfActiveTab: any;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
    setRefreshForm: (value: boolean) => void;
    refreshForm: boolean;
    setDisableYesButton?: (value: boolean) => void;
    saveBeforeChangePopUp?: boolean;
}

const VesselData: React.FC<VesselDataType> = ({
    setCheckValuesBeforeSwitch,
    setTabName,
    VesselConfActiveTab,
    setFormik,
    formik,
    setRefreshForm,
    refreshForm,
    setDisableYesButton,
}) => {
    const [activeSettingsTab, setActiveSettingsTab] = useState(VesselDataConstants.VESSEL_GENERAL_INFO);
    const [checkVesselDataValuesBeforeSwitch, setCheckVesselDataValuesBeforeSwitch] = useState(false);
    const [vesselDataTabName, setVesselDataTabName] = useState("");
    const [saveBeforeChangePopUpInsideTab, setSaveBeforeChangePopUpInsideTab] = useState(false);
    const [nextTab, setNextTab] = useState<number | null>(null);
    const [tabFormik, setTabFormik] = useState<FormikProps<any>>(null);
    const [disableYesButtonInsideTab, setDisableYesButtonInsideTab] = useState(false);
    useEffect(()=>{
        if(VesselConfActiveTab === VesselConfigrationTabs.VESSEL_DATA && tabFormik?.values !== tabFormik?.initialValues){ 
            setDisableYesButton(disableYesButtonInsideTab);
            setFormik(tabFormik);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[activeSettingsTab, VesselConfActiveTab, tabFormik])

    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            if (checkVesselDataValuesBeforeSwitch) {
                setActiveSettingsTab(tab); // Switch immediately
            } else {
                setNextTab(tab); // Store the next tab
                setSaveBeforeChangePopUpInsideTab(true); // Show confirmation popup
            }
            setRefreshForm(!refreshForm);
        }
    };
    
    const handleSaveAndSwitchTab = async (formik: any) => {
        if (formik) {
            await formik?.submitForm();
            formik.initialValues = formik.values;
            formik?.resetForm({ values: formik.initialValues });
            setRefreshForm(!refreshForm);
        }
        setCheckValuesBeforeSwitch(true);
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab);
            setNextTab(null);
        }
        setSaveBeforeChangePopUpInsideTab(false);
    };
    
    const handleNoSaveAndSwitchTab = (formik:any) => {
        formik?.resetForm({ values: formik.initialValues });
        setRefreshForm(!refreshForm);
        setCheckValuesBeforeSwitch(true);
        if (nextTab !== null) {
            setActiveSettingsTab(nextTab);
            setNextTab(null);
        }
        setSaveBeforeChangePopUpInsideTab(false);
    };
    
    return (
        <React.Fragment>
            <PopUp
                state={saveBeforeChangePopUpInsideTab}
                setState={setSaveBeforeChangePopUpInsideTab}
                body={
                    <SaveBeforeChange
                        tabName={vesselDataTabName}
                        onYes={() => handleSaveAndSwitchTab(tabFormik)} // Save and switch
                        onNo={()=>{
                            handleNoSaveAndSwitchTab(tabFormik);
                            setDisableYesButtonInsideTab(false);
                        }} // Discard and switch
                        disableYesButton={disableYesButtonInsideTab}
                    />
                }
                title="Alert"
                size="sm"
            />
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === VesselDataConstants.VESSEL_GENERAL_INFO,
                            })}
                            onClick={() => {
                                setFormik(null);
                                setTabFormik(null);
                                toggle(VesselDataConstants.VESSEL_GENERAL_INFO);
                            }} to={''}
                        >
                            Vessel General Information
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === VesselDataConstants.SATELLITE_COMMUNICATION,
                            })}
                            onClick={() => {
                                setFormik(null);
                                setTabFormik(null);
                                toggle(VesselDataConstants.SATELLITE_COMMUNICATION);
                            }} to={''}
                        >
                            Satellite Communication
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === VesselDataConstants.EMAIL_REPORTING,
                            })}
                            onClick={() => {
                                toggle(VesselDataConstants.EMAIL_REPORTING);
                            }} to={''}
                        >
                            Email Reporting
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardBody>
            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId={VesselDataConstants.VESSEL_GENERAL_INFO} className='m-0'>
                    <VesselGeneralInfo
                        activeTab={activeSettingsTab}
                        VesselConfActiveTab={VesselConfActiveTab}
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        setTabFormik={setTabFormik}
                        refreshForm={refreshForm}
                        setDisableYesButtonInsideTab={setDisableYesButtonInsideTab}
                        saveBeforeChangePopUpInsideTab={saveBeforeChangePopUpInsideTab}
                    />
                </TabPane>
                <TabPane tabId={VesselDataConstants.SATELLITE_COMMUNICATION}>
                    <SatelliteCommunication
                        activeTab={activeSettingsTab}
                        VesselConfActiveTab={VesselConfActiveTab}
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        setTabFormik={setTabFormik}
                        setDisableYesButtonInsideTab={setDisableYesButtonInsideTab}
                        saveBeforeChangePopUpInsideTab={saveBeforeChangePopUpInsideTab}
                    />
                </TabPane>
                <TabPane tabId= {VesselDataConstants.EMAIL_REPORTING}>
                    <EmailReporting
                        activeTab={activeSettingsTab as any}
                        VesselConfActiveTab={VesselConfActiveTab.VesselConfActiveTab}
                        setCheckVesselDataValuesBeforeSwitch={setCheckVesselDataValuesBeforeSwitch}
                        setVesselDataTabName={setVesselDataTabName}
                        setCheckValuesBeforeSwitch={setCheckValuesBeforeSwitch}
                        setTabName={setTabName}
                        setFormik={setFormik}
                        formik={formik}
                        setTabFormik={setTabFormik}
                        setDisableYesButtonInsideTab={setDisableYesButtonInsideTab}
                        saveBeforeChangePopUpInsideTab={saveBeforeChangePopUpInsideTab}
                    />
                </TabPane>
            </TabContent>
        </React.Fragment>
    )
}

export default VesselData