import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { Row } from "reactstrap";
import Message from "Components/Message";

class VoyagewiseCompliance extends Component<any> {
    Chart = () => {
        return {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        {
                            value: this.props.pieChartData?.filter((item: any) => item?.fuel_eu_record?.Compliance_Balance < 0)?.length,
                            name: 'Not complying'
                        },
                        {
                            value: this.props.pieChartData?.filter((item: any) => item?.fuel_eu_record?.Compliance_Balance > 0)?.length,
                            name: 'Complying'
                        },
                    ]
                }
            ]
        };
    }

    render() {
        return (
            <>
                <Row className="mt-3">
                    {this.props.pieChartData && this.props.pieChartData?.length > 0 ?
                        <ReactEcharts style={{ height: "350px" }} option={this.Chart()} /> :
                        <div className="mt-3">
                            <Message message='No data available!' type='danger' width='175px' />
                        </div>
                    }
                </Row>
            </>
        );
    }
}

export default VoyagewiseCompliance;
