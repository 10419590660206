import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { PageObject } from 'shared/CommonInterface';

interface PageNavButtonProps<T> {
    pageobj: PageObject<T>;
    setPageUrl: (url: string) => void;
    pageUrl?: string;
    isLoading?: boolean;
}

const PageNavButton = <T,>({ setPageUrl, pageobj, pageUrl, isLoading = false }: PageNavButtonProps<T>) => {
    // Default currentPage to 1 if pageUrl is undefined or doesn't have 'page' parameter
    const currentPage = (() => {
        if (!pageUrl) return 1;
        const urlParams = new URLSearchParams(pageUrl.split('?')[1]);
        return parseInt(urlParams.get('page') || '1', 10);
    })();
    // Calculate total pages based on total results and page size (10)
    const totalResults = pageobj?.count || 0;
    const totalPages = Math.ceil(totalResults / 10);

    // Return nothing if there is only one page or no pages
    if (totalPages <= 1) return <>Total count: {totalResults}</>;

    return (
        <Row className="m-1">
            <Col xs="7">
                Page {currentPage} of {totalPages} <br />
                Total count: {totalResults || '0'}
            </Col>
            <Col xs="5" className='d-flex align-items-center justify-content-end'>
                <div className="btn-group float-end">
                    <Button
                        type="button"
                        color="success"
                        size="sm"
                        className="waves-effect"
                        disabled={!(pageobj?.previous) || isLoading}
                        onClick={() => {
                            if (pageobj?.previous) setPageUrl(pageobj?.previous);
                        }}
                    >
                        {!isLoading || !(pageobj?.previous) ? (
                            <i className="fa fa-chevron-left" />
                        ) : (
                            <i className="bx bx-loader bx-spin" />
                        )}
                    </Button>
                    <Button
                        type="button"
                        color="success"
                        size="sm"
                        className="waves-effect ms-2"
                        disabled={!(pageobj?.next) || isLoading}
                        onClick={() => {
                            if (pageobj?.next) setPageUrl(pageobj?.next);
                        }}
                    >
                        {!isLoading || !(pageobj?.next) ? (
                            <i className="fa fa-chevron-right" />
                        ) : (
                            <i className="bx bx-loader bx-spin" />
                        )}
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default PageNavButton;