import apiGlobal from "global/api.global";
import { CfDO, FUELLCV, FuelTypes } from "shared/constants";
import { queryKeyes } from "shared/queryKeys";

/** Type for Simulator Operations  */
interface simulatorOperationsType {
  operation: string;
  field: string;
  consumptionLabel: string;
  steaming: boolean;
}

/** Operations to consider for Simulator */
export const simulatorOperations: simulatorOperationsType[] = [
  {
    operation: "Anchorage",
    field: "anchorage_percentage",
    consumptionLabel: "anchorage",
    steaming: false,
  },
  {
    operation: "Drifting",
    field: "drifting_percentage",
    consumptionLabel: "drifting",
    steaming: false,
  },
  {
    operation: "Manoevering",
    field: "manoevering_percentage",
    consumptionLabel: "manoevering",
    steaming: false,
  },
  {
    operation: "Port stay(loading)",
    field: "port_stay_loading_percentage",
    consumptionLabel: "port_stay_loading",
    steaming: false,
  },
  {
    operation: "Port stay(discharging)",
    field: "port_stay_discharging_percentage",
    consumptionLabel: "port_stay_discharging",
    steaming: false,
  },
  {
    operation: "Port stay(idle)",
    field: "port_stay_idle_records_percentage",
    consumptionLabel: "port_stay_idle",
    steaming: false,
  },
  {
    operation: "",
    field: "ballast_percentage",
    consumptionLabel: "ballast",
    steaming: true,
  },
  {
    operation: "",
    field: "laden_percentage",
    consumptionLabel: "laden",
    steaming: true,
  },
];

/** Calculate operation specific DOeq consumption */
export const calculateOpDoeq = (
  obj: any,
  key: string,
  op: string,
  meConsumption: number
): number => {
  let total = 0;
  Object?.keys(obj)?.forEach((arrayKey) => {
    const array = obj[arrayKey];
    if (Array.isArray(array)) {
      array.forEach((item) => {
        if (typeof item[key] === "number") {
          total += item[key] as number;
        }
      });
    }
  });
  if (op === "ballast" || op === "laden") {
    return total + meConsumption;
  } else {
    return total;
  }
};

/** Calculate DOeq of steaming & non-steaming duration */
export const calculateSteamingNonSteamingTimeDOeq = (
  values: any,
  simulatorOperations: any[],
  steamingPeriod: boolean,
  setFieldValue: any
) => {
  let total = 0;
  simulatorOperations?.forEach((op: any) => {
    total += (values[`${op?.consumptionLabel}_do_eq`]);
  });
  if (steamingPeriod === false) {
    setFieldValue("non_steaming_time_do_eq", total);
  } else {
    setFieldValue("steaming_time_do_eq", total);
  }
};

/** Calculate total DOeq consumption */
export const calculateTotalDOeqConsumed = (values: any, setFieldValue: any) => {
  let total = 0;
  simulatorOperations?.forEach((op: any) => {
    total +=
      values[`${op?.consumptionLabel}_do_eq`] *
      values[`${op?.consumptionLabel}_duration`];
  });
  setFieldValue(
    "expected_do_eq_consumption",
    total
  );
  setFieldValue(
    "total_do_eq_consumed",
    total + values?.actual_do_eq_consumption
  );
};

export const calculateTotalDOeq = async (
  values: any,
  fuelConsumption: any[],
  setFieldValue: any,
  do_equivalent: string | null,
  fieldValue: boolean,
  precedenceField: string,
  consumptionField: string,
  simulator: boolean = true,
) => {
  let HFOConsumption = 0;
  let LFOConsumption = 0;
  let DOeq: number = 0;
  let FuelLCVMaster: any;
  await apiGlobal.get(queryKeyes.masters.FuelMultiplyingFactor.url()).then(res => {
    FuelLCVMaster = res.data;
  }).catch(err => {
    console.error(err);
  })
  fuelConsumption?.forEach((fuel: any) => {
    let fuelTypeLCV = FuelLCVMaster?.filter((item: any) => item.fuel_type_precedence_id === fuel[precedenceField])[0]?.lcv * 1000000;
    if (fuel[precedenceField] === FuelTypes.FUEL_OIL) {
      if (simulator) {
        HFOConsumption = (parseFloat(fuel[consumptionField]) * values?.HFOPercentage) / 100;
        LFOConsumption = (parseFloat(fuel[consumptionField]) * values?.LFOPercentage) / 100;
        DOeq += (((HFOConsumption * FUELLCV.HFO) + (LFOConsumption * FUELLCV.LFO)) / FUELLCV.DO);
        if (fieldValue && simulator) {
          setFieldValue(do_equivalent, (((HFOConsumption * FUELLCV.HFO) + (LFOConsumption * FUELLCV.LFO)) / FUELLCV.DO));
        }
      } else {
        DOeq += (((parseFloat(fuel[consumptionField] ?? 0) * FUELLCV.HFO)) / FUELLCV.DO);
      }
    } else if (fuel[precedenceField] === FuelTypes.DIESEL_OIL) {
      DOeq += parseFloat(fuel[consumptionField] ?? 0);
      if (fieldValue && simulator) {
        setFieldValue(do_equivalent, fuel[consumptionField]);
      }
    } else if (fuel[precedenceField] !== undefined) {
      if (simulator) {
        DOeq += ((parseFloat(fuel[consumptionField] ?? 0) * fuelTypeLCV) / FUELLCV.DO);
        if (fieldValue && simulator) {
          setFieldValue(do_equivalent, ((fuel[consumptionField] * fuelTypeLCV) / FUELLCV.DO));
        }
      } else {
        DOeq += ((parseFloat(fuel[consumptionField] ?? 0) * fuelTypeLCV) / FUELLCV.DO);
      }
    }
    if (!fieldValue && simulator) {
      setFieldValue('expected_do_eq_consumption', parseFloat(DOeq?.toFixed(2)));
      setFieldValue('total_do_eq_consumed', parseFloat(DOeq?.toFixed(2)));
    }
  })
  if (simulator) {
    return parseFloat(DOeq?.toFixed(2));
  }
  return {
    FuelLCVMaster,
    DOeq: parseFloat(DOeq?.toFixed(2))
  };
}

export const calculateAttainedCII = (values: any, fuelDistribution: boolean, setFieldValue?: any) => {
  let total: any = 0;
  if (fuelDistribution) {
    total = calculateTotalDOeq(
      values,
      values?.fuel_distribution,
      setFieldValue,
      null,
      false,
      'fuel_type_precedence_id',
      'fuel_consumption'
    )
  } else {
    simulatorOperations?.forEach((op: any) => {
      total +=
        values[`${op?.consumptionLabel}_do_eq`] *
        values[`${op?.consumptionLabel}_duration`];
    });
  }
  return (
    (CfDO * (total + values?.actual_do_eq_consumption)) /
    (values?.fi *
      values?.fm *
      values?.fivse *
      values?.capacity *
      values?.vessel_stay?.total_distance)
  );
};

export const calculateReferenceCII = (values: any) => {
  let a: number = 0;
  let c: number = 0;
  let capacity: number = 0;
  if (values?.type === "Bulk Carrier" && values?.dwt >= 279000) {
    a = 4745;
    c = 0.622;
    capacity = 279000;
  } else if (values?.type === "Bulk Carrier" && values?.dwt < 279000) {
    a = 4745;
    c = 0.622;
    capacity = values?.dwt;
  } else if (values?.type === "Gas Carrier" && values?.dwt >= 65000) {
    a = 144050000000;
    c = 2.071;
    capacity = values?.dwt;
  } else if (values?.type === "Gas Carrier" && values?.dwt < 65000) {
    a = 8104;
    c = 0.639;
    capacity = values?.dwt;
  } else if (values?.type === "Tanker") {
    a = 5247;
    c = 0.61;
    capacity = values?.dwt;
  } else if (values?.type === "Container Ship") {
    a = 1984;
    c = 0.489;
    capacity = values?.dwt;
  } else if (values?.type === "General Cargo Ship" && values?.dwt >= 20000) {
    a = 31948;
    c = 0.792;
    capacity = values?.dwt;
  } else if (values?.type === "General Cargo Ship" && values?.dwt < 20000) {
    a = 588;
    c = 0.3885;
    capacity = values?.dwt;
  } else if (values?.type === "Refrigerated Cargo Carrier") {
    a = 4600;
    c = 0.557;
    capacity = values?.dwt;
  } else if (values?.type === "Combination Carrier" && values?.dwt >= 40853) {
    a = 40853;
    c = 0.812;
    capacity = values?.dwt;
  } else if (values?.type === "LNG Carrier" && values?.dwt >= 100000) {
    a = 9.827;
    c = 0;
    capacity = values?.dwt;
  } else if (
    values?.type === "LNG Carrier" &&
    100000 > values?.dwt &&
    values?.dwt >= 65000
  ) {
    a = 144790000000000;
    c = 2.673;
    capacity = values?.dwt;
  } else if (values?.type === "LNG Carrier" && values?.dwt < 65000) {
    a = 144790000000000;
    c = 2.673;
    capacity = 65000;
  } else if (values?.type === "Ro-Ro Cargo Ship(VC)") {
    a = 5739;
    c = 0.631;
    capacity = values?.gt;
  } else if (values?.type === "Ro-Ro Cargo Ship") {
    a = 10952;
    c = 0.637;
    capacity = values?.dwt;
  } else if (values?.type === "Ro-Ro Passenger Ship") {
    a = 7540;
    c = 0.587;
    capacity = values?.gt;
  } else if (values?.type === "Cruise Passenger Ship") {
    a = 930;
    c = 0.383;
    capacity = values?.gt;
  }
  return a * Math.pow(capacity, -c);
};

/** get current year required for Required CII */
export const currentYear = new Date().getFullYear();

/** values of Reduction factor for the year for Required CII */
export const reductionFactor = [
  { year: 2023, z: 5 },
  { year: 2024, z: 7 },
  { year: 2025, z: 9 },
  { year: 2026, z: 11 },
  { year: 2027, z: 14 },
  { year: 2028, z: 17 },
  { year: 2029, z: 20 },
  { year: 2030, z: 23 },
];

export const calculateRequiredCII = (values: any, year: number) => {
  const refCII = calculateReferenceCII(values);
  let z: number = 0;
  if (year === 2023) {
    z = 5;
  } else if (year === 2024) {
    z = 7;
  } else if (year === 2025) {
    z = 9;
  } else if (year === 2026) {
    z = 11;
  } else if (year === 2027) {
    z = 14;
  } else if (year === 2028) {
    z = 17;
  } else if (year === 2029) {
    z = 20;
  } else if (year === 2030) {
    z = 23;
  }
  return refCII * ((100 - z) / 100);
};
