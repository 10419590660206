import ErrorTooltip from 'Components/ErrorTooltip';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react'
import { Row, Col, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import env from "environment_system/env_system";

interface BillOfLaddingProps {
    alertBool: boolean
}
const BillOfLadding = ({ alertBool }: BillOfLaddingProps) => {
    const { values, errors, handleChange }:
        { values: any, errors: any, handleChange: any } = useFormikContext<any>();
    return (
        <React.Fragment>
            <Row className="mt-3 mb-2">
                <Col sm={4}>
                    <Label
                        className="mb-0"
                        for="is_bill_of_lading_issued_since_last_report"
                    >
                        Is Bill of Lading issued since last report?
                        <i
                            className="bx bx-info-circle ml-2p"
                            id="bill_of_lading_msg"
                        ></i>
                    </Label>
                    <ToolTip
                        target="bill_of_lading_msg"
                        message={`${TooltipMsg.Draft_and_Displacement.filter(
                            (item: any) => item.target === "bill_of_lading_msg"
                        ).map((tool: any) => {
                            return tool.message;
                        })}`}
                    />
                </Col>
                <Col lg={1}>
                    <Field
                        name={
                            "bill_of_lading.is_bill_of_lading_issued_since_last_report"
                        }
                    >
                        {({ field }: FieldProps) => (
                            <div className="square-switch sqswitch">
                                <Input
                                    type="checkbox"
                                    switch="none"
                                    name={field.name}
                                    id="is_bill_of_lading_issued_since_last_report"
                                    onChange={handleChange}
                                    defaultChecked={
                                        values?.bill_of_lading
                                            .is_bill_of_lading_issued_since_last_report
                                    }
                                />
                                <Label
                                    htmlFor="is_bill_of_lading_issued_since_last_report"
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    className="mb-0"
                                ></Label>
                            </div>
                        )}
                    </Field>
                </Col>
            </Row>
            {values?.bill_of_lading
                .is_bill_of_lading_issued_since_last_report === true && (
                    <Card className="p-0 mb-0 rounded-0 border-start-0 border-end-0 border-bottom-0">
                        <CardHeader className="p-2">
                            <div className="text-center">
                                <Row>
                                    <Col>
                                        <h4 className="page_title pos-start mb-0">
                                            Bill of Lading
                                        </h4>
                                        <p className="card-title-desc pos-start">
                                            All readings since last report
                                        </p>
                                    </Col>
                                    <Col></Col>
                                </Row>
                            </div>
                        </CardHeader>
                        <CardBody className="px-2 py-0 mt-2">
                            <Row>
                                <Col sm={3}>
                                    <Label className="asteric mb-0" for="bl_figure">
                                        BL figures (Cargo)
                                        <i
                                            className="bx bx-info-circle ml-2p"
                                            id="bl_figure_msg"
                                        ></i>
                                    </Label>
                                    <ToolTip
                                        target="bl_figure_msg"
                                        message={`${TooltipMsg.Draft_and_Displacement.filter(
                                            (item: any) => item.target === "bl_figure_msg"
                                        ).map((tool: any) => {
                                            return tool.message;
                                        })}`}
                                    />
                                    <div className="mb-3 input-group">
                                        <Field
                                            type="text"
                                            className="form-control text-right max-width-7"
                                            id="bl_figure"
                                            name="bill_of_lading.bl_figure"
                                        />
                                        <div className="input-group-text round_border">
                                            mt
                                        </div>
                                    </div>
                                    {errors?.bill_of_lading &&
                                        errors?.bill_of_lading?.bl_figure &&
                                        !alertBool &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target="bl_figure"
                                                message={errors?.bill_of_lading?.bl_figure}
                                                open={
                                                    errors?.bill_of_lading &&
                                                        errors?.bill_of_lading?.bl_figure
                                                        ? true
                                                        : false
                                                }
                                            />
                                        )}
                                </Col>
                                <Col lg={3}>
                                    <Label
                                        className="asteric mb-0"
                                        for="ship_figure_of_bl_figure"
                                    >
                                        Ship figures (Cargo)
                                        <i
                                            className="bx bx-info-circle ml-2p"
                                            id="ship_figure_of_bl_figure_msg"
                                        ></i>
                                    </Label>
                                    <ToolTip
                                        target="ship_figure_of_bl_figure_msg"
                                        message={`${TooltipMsg.Draft_and_Displacement.filter(
                                            (item: any) =>
                                                item.target === "ship_figure_of_bl_figure_msg"
                                        ).map((tool: any) => {
                                            return tool.message;
                                        })}`}
                                    />
                                    <div className="mb-3 input-group">
                                        <Field
                                            type="text"
                                            className="form-control text-right max-width-7"
                                            id="ship_figure_of_bl_figure"
                                            name="bill_of_lading.ship_figure_of_bl_figure"
                                        />
                                        <div className="input-group-text round_border">
                                            mt
                                        </div>
                                        {errors?.bill_of_lading &&
                                            errors?.bill_of_lading
                                                ?.ship_figure_of_bl_figure &&
                                            !alertBool &&
                                            env?.form_validation === true && (
                                                <ErrorTooltip
                                                    target="ship_figure_of_bl_figure"
                                                    message={
                                                        errors?.bill_of_lading
                                                            ?.ship_figure_of_bl_figure
                                                    }
                                                    open={
                                                        errors?.bill_of_lading &&
                                                            errors?.bill_of_lading
                                                                ?.ship_figure_of_bl_figure
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            )}
                                    </div>
                                </Col>
                                {/* <Col lg={3}>
                                                <Label for="bl_figure_attachment" className="mt-3 mb-0">BL figure attachment
                                                    <i className='bx bx-info-circle ml-2p' id='bl_figure_attachment_msg' />
                                                    <i className="ml-1 dripicons-upload icon_s23 mt-3"
                                                        onClick={() => {
                                                            setBlFigureAttachmentBool(true);
                                                        }}
                                                        data-toggle="modal"
                                                        id='bl_figure_attachment'></i>
                                                </Label>
                                                {(BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) ?
                                                    <>
                                                        <br /><Label className='mt-0 primary-label'>{values?.bill_of_lading.bl_figure_attachment.toString().substring(10, 34)}</Label>
                                                    </> : null
                                                }
                                                <ToolTip target='bl_figure_attachment_msg'
                                                    message={`${TooltipMsg.Draft_and_Displacement.filter((item: any) => item.target === 'bl_figure_attachment_msg')
                                                        .map((tool: any) => { return tool.message })}`}
                                                />
                                            </Col>
                                            <ReportingFileUploadModal
                                                state={blFigureAttachmentBool}
                                                stateName={'BLFigureAttachment'}
                                                array={blFigureAttachment}
                                                setArray={setBlFigureAttachment}
                                                modalId={'bl_figure_attachment'}
                                            /> */}
                            </Row>
                            <Row>
                                <Col lg={3}>
                                    <div className="d-flex flex-wrap gap-2 mb-3 mt-2">
                                        <Label
                                            className="mb-0 mt-3 w-10"
                                            for="cargo_survey"
                                        >
                                            Cargo survey?
                                            <i
                                                className="bx bx-info-circle ml-2p"
                                                id="cargo_survey_msg"
                                            ></i>
                                        </Label>
                                        <ToolTip
                                            target="cargo_survey_msg"
                                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                                (item: any) =>
                                                    item.target === "cargo_survey_msg"
                                            ).map((tool: any) => {
                                                return tool.message;
                                            })}`}
                                        />
                                        <Field name={"bill_of_lading.cargo_survey"}>
                                            {({ field }: FieldProps) => (
                                                <div className="square-switch sqswitch mt-3">
                                                    <Input
                                                        type="checkbox"
                                                        switch="none"
                                                        name={field.name}
                                                        id="cargo_survey"
                                                        onChange={handleChange}
                                                        defaultChecked={
                                                            values?.bill_of_lading.cargo_survey
                                                        }
                                                    />
                                                    <Label
                                                        htmlFor="cargo_survey"
                                                        data-on-label="Yes"
                                                        data-off-label="No"
                                                    ></Label>
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </Col>
                                {values?.bill_of_lading.cargo_survey === true && (
                                    <>
                                        <Col lg={3}>
                                            <Label
                                                className="asteric mb-0"
                                                for="surveyor_figure_of_bl_figure"
                                            >
                                                Surveyor figures (Cargo)
                                                <i
                                                    className="bx bx-info-circle ml-2p"
                                                    id="surveyor_figure_of_bl_figure_msg"
                                                ></i>
                                            </Label>
                                            <ToolTip
                                                target="surveyor_figure_of_bl_figure_msg"
                                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                                    (item: any) =>
                                                        item.target ===
                                                        "surveyor_figure_of_bl_figure_msg"
                                                ).map((tool: any) => {
                                                    return tool.message;
                                                })}`}
                                            />
                                            <div className="input-group">
                                                <Field
                                                    type="text"
                                                    className="form-control text-right max-width-7"
                                                    id="surveyor_figure_of_bl_figure"
                                                    name="bill_of_lading.surveyor_figure_of_bl_figure"
                                                />
                                                <div className="input-group-text round_border">
                                                    mt
                                                </div>
                                                {errors?.bill_of_lading &&
                                                    errors?.bill_of_lading
                                                        ?.surveyor_figure_of_bl_figure &&
                                                    !alertBool &&
                                                    env?.form_validation === true && (
                                                        <ErrorTooltip
                                                            target="surveyor_figure_of_bl_figure"
                                                            message={
                                                                errors?.bill_of_lading
                                                                    ?.surveyor_figure_of_bl_figure
                                                            }
                                                            open={
                                                                errors?.bill_of_lading &&
                                                                    errors?.bill_of_lading
                                                                        ?.surveyor_figure_of_bl_figure
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )}
                                            </div>
                                        </Col>
                                        {/* <Col lg={3}>
                                                        <Label for="survey_attachment" className="mt-3 mb-0">Surveyor figure attachment
                                                            <i className='bx bx-info-circle ml-2p' id='survey_attachment_msg' />
                                                            <i className="ml-1 dripicons-upload icon_s23 mt-3"
                                                                onClick={() => {
                                                                    setSurveyAttachmentBool(true);
                                                                }}
                                                                data-toggle="modal"
                                                                id='survey_attachment'></i>
                                                        </Label>
                                                        {(BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) ?
                                                            <Label className='mt-0 primary-label'>{values?.bill_of_lading.survey_attachment}</Label> : null
                                                        }
                                                        <ToolTip target='survey_attachment_msg'
                                                            message={`${TooltipMsg.Draft_and_Displacement
                                                                .filter((item: any) => item.target === 'survey_attachment_msg')
                                                                .map((tool: any) => { return tool.message })}`}
                                                        />
                                                    </Col>
                                                    <ReportingFileUploadModal
                                                        state={surveyAttachmentBool}
                                                        array={surveyAttachment}
                                                        setArray={setSurveyAttachment}
                                                        modalId={'survey_attachment'}
                                                    /> */}
                                    </>
                                )}
                            </Row>
                            {/* <Row>
                                            <Col lg={3}>
                                                <div className="d-flex flex-wrap gap-2 mb-3">
                                                    <Label className="mt-3 w-10">
                                                        Letter of protest?<i className='bx bx-info-circle ml-2p' id='letter_of_protest_msg'></i>
                                                    </Label>
                                                    <ToolTip target='letter_of_protest_msg'
                                                        message={`${TooltipMsg.Draft_and_Displacement.filter((item: any) => item.target === 'letter_of_protest_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'bill_of_lading.letter_of_protest'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch mt-3">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id="letter_of_protest"
                                                                    onChange={handleChange}
                                                                    defaultChecked={values?.bill_of_lading.letter_of_protest}
                                                                />
                                                                <Label
                                                                    htmlFor="letter_of_protest"
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'>
                                                                </Label>
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </Col>
                                            {values?.bill_of_lading.letter_of_protest &&
                                                <>
                                                    <Col lg={3}>
                                                        <Label for="letter_of_protest_attachment" className="mt-2">Letter of protest attachment
                                                            <i className='bx bx-info-circle ml-2p' id='letter_of_protest_attachment_msg' />
                                                            <i className="ml-1 dripicons-upload icon_s23 mt-2"
                                                                onClick={() => {
                                                                    setLetterofProtestAttachmentBool(true);
                                                                }}
                                                                data-toggle="modal"
                                                                id='letter_of_protest_attachment' />
                                                        </Label>
                                                        {(BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) ?
                                                            <Label className='mt-0 primary-label'>{values?.bill_of_lading.letter_of_protest_attachment}</Label> : null
                                                        }
                                                        <ToolTip target='letter_of_protest_attachment_msg'
                                                            message={`${TooltipMsg.Draft_and_Displacement
                                                                .filter((item: any) => item.target === 'letter_of_protest_attachment_msg')
                                                                .map((tool: any) => { return tool.message })}`}
                                                        />
                                                    </Col>
                                                    <ReportingFileUploadModal
                                                        state={letterofProtestAttachmentBool}
                                                        array={letterofProtestAttachment}
                                                        setArray={setLetterofProtestAttachment}
                                                        modalId={'letter_of_protest_attachment'}
                                                    />
                                                </>
                                            }
                                        </Row> */}
                        </CardBody>
                    </Card>
                )}
        </React.Fragment>
    )
}

export default BillOfLadding