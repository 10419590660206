import React from 'react'

const VoyageTableHeadComponent = () => {
    return (
        <thead className="table-light">
            <tr>
                <th className='p-2 align-middle sr-no-width' style={{ width: '5%' }}>#</th>
                <th className='p-2 align-middle' style={{ width: '20%' }}>Voyage number</th>
                <th className='p-2 align-middle text-left' style={{ width: '20%' }}>Voyage status</th>
                <th className='p-2 align-middle text-left' style={{ width: '25%' }}>Departure date time</th>
                <th className='p-2 align-middle text-center' style={{ width: '20%' }} colSpan={4}>Actions</th>
            </tr>
        </thead>
    )
}

export default VoyageTableHeadComponent