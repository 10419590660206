import React from 'react'
import { Roles } from 'shared/constants'
import { hasRole } from 'utils/auth/authUtils'

interface VesselTableHeadComponentType {
    reportStatus: string
}

const VesselTableHeadComponent = ({
    reportStatus
}: VesselTableHeadComponentType) => {
    return (
        <thead className="table-light">
            <tr>
                <th className='p-2 align-middle sr-no-width'>#</th>
                {reportStatus === 'ongoing' ?
                    <React.Fragment>
                        <th className='p-2 align-middle' style={{ width: '15%' }}>Vessel report name</th>
                        <th className='p-2 align-middle text-center' style={{ width: '12%' }}>Report type</th>
                    </React.Fragment> :
                    <th className='p-2 align-middle' style={{ width: '15%' }}>Vessel report name (Report type)</th>
                }
                <th className='p-2 align-middle text-center' style={{ width: '15%' }}>Reporting time (Local)</th>
                <th className='p-2 align-middle text-center' style={{ width: '20%' }} colSpan={
                    hasRole(Roles.ES_ADMIN) ? 5 : hasRole(Roles.MASTER) ? 4 : hasRole(Roles.CHIEFENGINEER) ? 2 : 0
                }>
                    Actions
                </th>
            </tr>
        </thead>
    )
}

export default VesselTableHeadComponent