import ErrorTooltip from 'Components/ErrorTooltip';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react'
import { Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import env from "environment_system/env_system";
interface DraftsAndDisplacementProps {
    alertBool: boolean;
}

const DraftsAndDisplacement = ({ alertBool }: DraftsAndDisplacementProps) => {
    const { values, errors, touched, handleChange, handleBlur }:
        { values: any, errors: any, touched: any, handleChange: any, handleBlur: any } = useFormikContext<any>();
    return (
        <Card className="p-0 mb-0 rounded-0 border-top-0 border-start-0 border-end-0">
            <CardHeader className="py-2 px-0">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Drafts & Displacement
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <CardBody className="px-0 py-0 mt-2">
                <Row>
                    <div className="d-flex flex-wrap">
                        <Col lg={2} className="ps-0 py-1">
                            <Label className="asteric mb-0" for="fwd_draft">
                                Fwd draft
                                <i
                                    className="bx bx-info-circle ml-2p"
                                    id="fwd_draft_msg"
                                ></i>
                            </Label>
                            <ToolTip
                                target="fwd_draft_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) => item.target === "fwd_draft_msg"
                                ).map((tool: any) => {
                                    return tool.message;
                                })}`}
                            />
                            <div className="mb-1 input-group">
                                <Field name="draft_and_displacement.fwd_draft">
                                    {({ field, form }: FieldProps) => (
                                        <Input
                                            type="text"
                                            className="form-control text-right border-end-0"
                                            id="fwd_draft"
                                            name={field.name}
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                form.setFieldValue(
                                                    "draft_and_displacement.trim",
                                                    (
                                                        values?.draft_and_displacement.draft_aft -
                                                        e.target.value
                                                    ).toFixed(2)
                                                );
                                            }}
                                            onBlur={(e: any) => {
                                                handleBlur(e);
                                                handleChange(e);
                                                form.setFieldValue(
                                                    "draft_and_displacement.trim",
                                                    (
                                                        values?.draft_and_displacement.draft_aft -
                                                        e.target.value
                                                    ).toFixed(2)
                                                );
                                            }}
                                            defaultValue={
                                                values &&
                                                values?.draft_and_displacement?.fwd_draft
                                            }
                                        />
                                    )}
                                </Field>
                                <div className="input-group-text round_border">
                                    m
                                </div>{" "}
                            </div>
                            {errors?.draft_and_displacement &&
                                touched?.draft_and_displacement?.fwd_draft &&
                                errors?.draft_and_displacement?.fwd_draft &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="fwd_draft"
                                        message={
                                            errors?.draft_and_displacement?.fwd_draft
                                        }
                                        open={
                                            errors?.draft_and_displacement &&
                                                errors?.draft_and_displacement?.fwd_draft
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </Col>
                        <Col lg={2} className="p-1 ps-2">
                            <Label className="asteric mb-0" for="draft_mid">
                                Mid draft
                                <i
                                    className="bx bx-info-circle ml-2p"
                                    id="draft_mid_msg"
                                ></i>
                            </Label>
                            <ToolTip
                                target="draft_mid_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) => item.target === "draft_mid_msg"
                                ).map((tool: any) => {
                                    return tool.message;
                                })}`}
                            />
                            <div className="mb-3 input-group">
                                <Field name="draft_mid">
                                    {() => (
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            id="draft_mid"
                                            name="draft_and_displacement.draft_mid"
                                            onBlur={handleBlur}
                                            onChange={(e: any) => handleChange(e)}
                                            defaultValue={
                                                values?.draft_and_displacement?.draft_mid
                                            }
                                        />
                                    )}
                                </Field>
                                <div className="input-group-text round_border">
                                    m
                                </div>{" "}
                            </div>
                            {errors?.draft_and_displacement &&
                                touched?.draft_and_displacement?.draft_mid &&
                                errors?.draft_and_displacement?.draft_mid &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="draft_mid"
                                        message={
                                            errors?.draft_and_displacement?.draft_mid
                                        }
                                        open={
                                            errors?.draft_and_displacement &&
                                                errors?.draft_and_displacement?.draft_mid
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </Col>
                        <Col lg={2} className="p-1">
                            <Label className="asteric mb-0" for="draft_aft">
                                Aft draft
                                <i
                                    className="bx bx-info-circle ml-2p"
                                    id="draft_aft_msg"
                                ></i>
                            </Label>
                            <ToolTip
                                target="draft_aft_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) => item.target === "draft_aft_msg"
                                ).map((tool: any) => {
                                    return tool.message;
                                })}`}
                            />
                            <div className="mb-3 input-group">
                                <Field name="draft_and_displacement.draft_aft">
                                    {({ field, form }: FieldProps) => (
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            id="draft_aft"
                                            name={field.name}
                                            onChange={(e: any) => {
                                                handleChange(e);
                                                form.setFieldValue(
                                                    "draft_and_displacement.trim",
                                                    (
                                                        e.target.value -
                                                        values?.draft_and_displacement.fwd_draft
                                                    ).toFixed(2)
                                                );
                                            }}
                                            onBlur={(e: any) => {
                                                handleBlur(e);
                                                handleChange(e);
                                                form.setFieldValue(
                                                    "draft_and_displacement.trim",
                                                    (
                                                        e.target.value -
                                                        values?.draft_and_displacement.fwd_draft
                                                    ).toFixed(2)
                                                );
                                            }}
                                            defaultValue={
                                                values &&
                                                values?.draft_and_displacement.draft_aft
                                            }
                                        />
                                    )}
                                </Field>
                                <div className="input-group-text round_border">
                                    m
                                </div>
                            </div>
                            {errors?.draft_and_displacement &&
                                touched?.draft_and_displacement?.draft_aft &&
                                errors?.draft_and_displacement?.draft_aft &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="draft_aft"
                                        message={
                                            errors?.draft_and_displacement?.draft_aft
                                        }
                                        open={
                                            errors?.draft_and_displacement &&
                                                errors?.draft_and_displacement?.draft_aft
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </Col>
                        <Col lg={2} className="p-1">
                            <Label className="asteric mb-0" for="displacement">
                                Displacement
                                <i
                                    className="bx bx-info-circle ml-2p"
                                    id="displacement_msg"
                                ></i>
                            </Label>
                            <ToolTip
                                target="displacement_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) =>
                                        item.target === "displacement_msg"
                                ).map((tool: any) => {
                                    return tool.message;
                                })}`}
                            />
                            <div className="mb-3 input-group">
                                <Field name="draft_and_displacement.displacement">
                                    {({ field }: FieldProps) => (
                                        <Input
                                            type="text"
                                            className="form-control text-right border-end-0"
                                            id="displacement"
                                            name={field.name}
                                            onBlur={handleBlur}
                                            onChange={(e: any) => handleChange(e)}
                                            defaultValue={
                                                values &&
                                                values?.draft_and_displacement?.displacement
                                            }
                                        />
                                    )}
                                </Field>
                                <div className="input-group-text round_border">
                                    mt
                                </div>
                            </div>
                            {errors?.draft_and_displacement &&
                                touched?.draft_and_displacement?.displacement &&
                                errors?.draft_and_displacement?.displacement &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="displacement"
                                        message={
                                            errors?.draft_and_displacement?.displacement
                                        }
                                        open={
                                            errors?.draft_and_displacement &&
                                                errors?.draft_and_displacement?.displacement
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </Col>
                        <Col lg={2} className="p-1">
                            <Label className="mb-0" for="trim">
                                Trim
                                <i
                                    className="bx bx-info-circle ml-2p"
                                    id="trim_msg"
                                ></i>
                            </Label>
                            <ToolTip
                                target="trim_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) => item.target === "trim_msg"
                                ).map((tool: any) => {
                                    return tool.message;
                                })}`}
                            />
                            <div className="mb-3 input-group">
                                <Field
                                    type="text"
                                    className="form-control text-right"
                                    id="trim"
                                    name="draft_and_displacement.trim"
                                    disabled
                                />
                                <div className="input-group-text round_border">
                                    m
                                </div>
                                {errors?.draft_and_displacement &&
                                    errors?.draft_and_displacement?.trim &&
                                    !alertBool &&
                                    env?.form_validation === true && (
                                        <ErrorTooltip
                                            target="trim"
                                            message={errors?.draft_and_displacement?.trim}
                                            open={
                                                errors?.draft_and_displacement &&
                                                    errors?.draft_and_displacement?.trim
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                            </div>
                        </Col>
                    </div>
                </Row>
            </CardBody>
        </Card>
    )
}

export default DraftsAndDisplacement