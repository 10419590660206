import { commonValidationMessages } from "Components/ValidationErrorMessages";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  Label,
  Row,
} from "reactstrap";
import { Field, FieldArray, Formik } from "formik";
import { EventPrecedence, ReportingOpConstant } from "shared/constants";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import apiGlobal from "global/api.global";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { loadThermalOilHeaterObject } from "VesselMaster/vesselMaster.hooks";
import Loading from "Components/Loading";
import { queryClient } from "react-query/queryClient";
import { handleServerResponse } from "GenericForms/Helper";
import ErrorTooltip from "Components/ErrorTooltip";
import env from "environment_system/env_system";
import DeletePopOver from "Components/DeletePopOver";
import { successToast } from "Components/Toasts";

interface ThermalOilHeaterComponentType {
  VesselID: number;
  VoyageID: number;
  ReportID: number;
  toggleAddTab: any;
  toggleDynamicTabs: any;
  previousTabIndex: number;
  record: any;
  setErrorMessage: (value: boolean) => void;
}

const ThermalOilHeaterComponent = ({
  VesselID,
  VoyageID,
  ReportID,
  toggleAddTab,
  toggleDynamicTabs,
  previousTabIndex,
  record,
  setErrorMessage
}: ThermalOilHeaterComponentType) => {
  /** State variables start */
  const [refreshKey, setRefreshKey] = useState(0);
  const [popOverBool, setPopOverBool] = useState<boolean | null>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [thermalOilHeaterObj, setThermalOilHeaterObj] = useState<any>([]);
  const [romoveIndex, setRomoveIndex] = useState<any>(null);
  /** State variables end */

  /** Queries */
  /** Thermal Oil Heater Object used for edit */
  const {
    data: ThermalOilHeaterObject,
    isLoading: ThermalOilHeaterObjectLoading,
    isError: ThermalOilHeaterObjectError,
  } = useQuery(
    [queryKeyes.vessel.LfondsThermalOilHeaterObject.key, VesselID, ReportID],
    async () => {
      return await loadThermalOilHeaterObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Queries end */
  /** Assign values to initial object of Thermal Oil Heater */
  const getInitialThermalOilHeaterValues = () => {
    if (ThermalOilHeaterObject && ThermalOilHeaterObject.length > 0) {
      return ThermalOilHeaterObject;
    } else {
      return [
        {
          name_of_thermal_oil_heater: "",
          outlet_temperature: 0,
          inlet_temperature: 0,
          exhaust_temperature: 0,
          outlet_press: 0,
          inlet_press: 0,
          temperature_set_point: 0,
          th_expansion_tank_level: 0,
          vessel: VesselID,
          voyage_information: VoyageID,
          vessel_reporting_information: ReportID,
        },
      ];
    }
  };

  /** Thermal oil heater's formik object */
  const ThermalOilHeaterFormik = {
    initialValues: {
      thermalOilHeater: getInitialThermalOilHeaterValues(),
    },
    validationSchema: Yup.object().shape({
      thermalOilHeater: Yup.array(
        Yup.object({
          outlet_temperature: Yup.string().matches(
            /^\d{0,3}(\.\d{1,4})?$/,
            `${commonValidationMessages.before3after4}`
          ),
          inlet_temperature: Yup.string().matches(
            /^\d{0,3}(\.\d{1,4})?$/,
            `${commonValidationMessages.before3after4}`
          ),
          exhaust_temperature: Yup.string().matches(
            /^\d{0,3}(\.\d{1,4})?$/,
            `${commonValidationMessages.before3after4}`
          ),
          outlet_press: Yup.string().matches(
            /^\d{0,3}(?:\.\d{1,4})?$/,
            `${commonValidationMessages.before3after4}`
          ),
          inlet_press: Yup.string().matches(
            /^\d{0,3}(?:\.\d{1,4})?$/,
            `${commonValidationMessages.before3after4}`
          ),
          temperature_set_point: Yup.string().matches(
            /^\d{0,4}(\.\d{1,4})?$/,
            `${commonValidationMessages.before4after4}`
          ),
          th_expansion_tank_level: Yup.string().matches(
            /^\d{0,4}(?:\.\d{1,4})?$/,
            `${commonValidationMessages.before4after4}`
          ),
        })
      ),
    }),
  };

  /**
   *  Deletes the thermal oil heater object from the database
   * @param indexId 
   */
  const handleDeletion = async (indexId: number) => {
    try {
      if (thermalOilHeaterObj && thermalOilHeaterObj.id) {
        await apiGlobal.delete(`/lfonds_thermal_oil_heater/${thermalOilHeaterObj.id}/`, thermalOilHeaterObj);
        successToast('Deleted successfully');
        await queryClient.invalidateQueries(queryKeyes.vessel.LfondsThermalOilHeaterObject.key);
        setRefreshKey(refreshKey + 1);
      } else {
        romoveIndex(indexId); // Correctly calling the remove function
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  return (
    <Card className="p-0 mb-0 border-0">
      <CardHeader className="p-2">
        <div className="text-center mb-2">
          <Row>
            <Col>
              <h4 className="page_title pos-start mb-0">Thermal Oil Heaters</h4>
              <p className="card-title-desc pos-start">
                All readings since last report
              </p>
            </Col>
          </Row>
        </div>
      </CardHeader>
      {ThermalOilHeaterObjectLoading && (
        <Loading message="Loading required data!" />
      )}
      {ThermalOilHeaterObjectError && getInitialThermalOilHeaterValues()}
      {!ThermalOilHeaterObjectLoading && (
        <Formik
          key={refreshKey}
          onSubmit={async (values: any, actions: any) => {
            if (env?.form_validation === false) {
              setErrorMessage(null)
              toggleDynamicTabs(previousTabIndex + 1);
            }
            actions.setSubmitting(true);
            let responseArray: any = [];
            if (ThermalOilHeaterObject && ThermalOilHeaterObject.length > 0) {
              values.thermalOilHeater.forEach((heater: any) => {
                if (heater.id) {
                  responseArray.push(apiGlobal.put(`/lfonds_thermal_oil_heater/${heater.id}/`, heater));
                } else {
                  responseArray.push(apiGlobal.post(`/lfonds_thermal_oil_heater/`, heater));
                }
              });
            } else {
              responseArray.push(apiGlobal.post(`/lfonds_thermal_oil_heater/`, values.thermalOilHeater));
            }
            await handleServerResponse(responseArray).then(async (res: any) => {
              if (res === true) {
                await queryClient.invalidateQueries(queryKeyes.vessel.LfondsThermalOilHeaterObject.key);
                setRefreshKey(refreshKey + 1);
                if (env?.form_validation === true) {
                  setErrorMessage(null)
                  toggleDynamicTabs(previousTabIndex + 1);
                }
              } else {
                setErrorMessage(res)
              }
              actions.setSubmitting(false);
            })
          }}
          initialValues={ThermalOilHeaterFormik.initialValues}
          validationSchema={env?.form_validation === true ? ThermalOilHeaterFormik.validationSchema : null}
        >
          {({
            values,
            errors,
            handleSubmit,
            setErrors,
            isSubmitting,
          }: {
            values: any;
            errors: any;
            handleSubmit: any;
            handleChange: any;
            setErrors: any;
            isSubmitting: any;
          }) => (
            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <CardBody className="engineer-card-body">
                <FieldArray name="thermalOilHeater">
                  {({ push, remove }) => (
                    <React.Fragment>
                      {values &&
                        values?.thermalOilHeater?.map(
                          (_heater: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <Row>
                                  <Col>
                                    {values?.thermalOilHeater?.length ===
                                      1 ? null : (
                                      <button
                                        type="button"
                                        className="btn justify_right"
                                      >
                                        <i
                                          className="dripicons-trash icon_s18"
                                          id={`delete_${index}`}
                                          onClick={() => {
                                            setThermalOilHeaterObj(_heater); // Ensure we pass the whole array
                                            setDeleteIndex(index);
                                            setPopOverBool(true);
                                            setRomoveIndex(() => remove); // Store function reference correctly
                                          }}
                                        />
                                      </button>
                                    )}
                                  </Col>
                                </Row>
                                <Row className="mb-2">
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`name_of_thermal_oil_heater-${index}`}
                                    >
                                      Name
                                    </Label>
                                    <Field
                                      name={`thermalOilHeater.${index}.name_of_thermal_oil_heater`}
                                      type="text"
                                      className="form-control"
                                      id={`name_of_thermal_oil_heater-${index}`}
                                      autoFocus
                                    />
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.name_of_thermal_oil_heater &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`name_of_thermal_oil_heater-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.name_of_thermal_oil_heater
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.name_of_thermal_oil_heater
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`outlet_temperature-${index}`}
                                    >
                                      Outlet temperature
                                    </Label>
                                    <div className="input-group">
                                      <Field
                                        name={`thermalOilHeater.${index}.outlet_temperature`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`outlet_temperature-${index}`}
                                      />
                                      <div className="input-group-text round_border">
                                        <sup>o</sup>C
                                      </div>
                                    </div>
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.outlet_temperature &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`outlet_temperature-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.outlet_temperature
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.outlet_temperature
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`inlet_temperature-${index}`}
                                    >
                                      Inlet temperature
                                    </Label>
                                    <div className="input-group">
                                      <Field
                                        name={`thermalOilHeater.${index}.inlet_temperature`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`inlet_temperature-${index}`}
                                      />
                                      <div className="input-group-text round_border">
                                        <sup>o</sup>C
                                      </div>
                                    </div>
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.inlet_temperature &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`inlet_temperature-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.inlet_temperature
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.inlet_temperature
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`exhaust_temperature-${index}`}
                                    >
                                      Exhaust temperature
                                    </Label>
                                    <div className="input-group">
                                      <Field
                                        name={`thermalOilHeater.${index}.exhaust_temperature`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`exhaust_temperature-${index}`}
                                      />
                                      <div className="input-group-text round_border">
                                        <sup>o</sup>C
                                      </div>
                                    </div>
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.exhaust_temperature &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`exhaust_temperature-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.exhaust_temperature
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.exhaust_temperature
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                </Row>
                                <Row className="mb-3">
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`outlet_press-${index}`}
                                    >
                                      Outlet press
                                    </Label>
                                    <Field
                                      name={`thermalOilHeater.${index}.outlet_press`}
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id={`outlet_press-${index}`}
                                    />
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.outlet_press &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`outlet_press-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.outlet_press
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.outlet_press
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`inlet_press-${index}`}
                                    >
                                      Inlet press
                                    </Label>
                                    <Field
                                      name={`thermalOilHeater.${index}.inlet_press`}
                                      type="text"
                                      className="form-control text-right max-width-7"
                                      id={`inlet_press-${index}`}
                                    />
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.inlet_press &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`inlet_press-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.inlet_press
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.inlet_press
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`thermalOilHeater.${index}.temperature_set_point`}
                                    >
                                      Temperature set point
                                    </Label>
                                    <div className="input-group">
                                      <Field
                                        name={`thermalOilHeater.${index}.temperature_set_point`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`temperature_set_point-${index}`}
                                      />
                                      <div className="input-group-text round_border">
                                        <sup>o</sup>C
                                      </div>
                                    </div>
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.temperature_set_point &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`temperature_set_point-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.temperature_set_point
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.temperature_set_point
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                  <Col lg={3}>
                                    <Label
                                      className="mb-0"
                                      for={`th_expansion_tank_level-${index}`}
                                    >
                                      TH expansion tank level
                                    </Label>
                                    <div className="input-group">
                                      <Field
                                        name={`thermalOilHeater.${index}.th_expansion_tank_level`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`th_expansion_tank_level-${index}`}
                                      />
                                      <div className="input-group-text round_border">
                                        m
                                      </div>
                                    </div>
                                    {errors?.thermalOilHeater &&
                                      errors?.thermalOilHeater[index]
                                        ?.th_expansion_tank_level &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={`th_expansion_tank_level-${index}`}
                                          message={
                                            errors?.thermalOilHeater[index]
                                              ?.th_expansion_tank_level
                                          }
                                          open={
                                            errors?.thermalOilHeater &&
                                              errors?.thermalOilHeater[index]
                                                ?.th_expansion_tank_level
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </Col>
                                </Row>
                                {index ===
                                  values?.thermalOilHeater?.length - 1 && (
                                    <Label
                                      className="link_color_blue"
                                      onClick={() =>
                                        push({
                                          name_of_thermal_oil_heater: "",
                                          outlet_temperature: 0,
                                          inlet_temperature: 0,
                                          exhaust_temperature: 0,
                                          outlet_press: 0,
                                          inlet_press: 0,
                                          temperature_set_point: 0,
                                          th_expansion_tank_level: 0,
                                          vessel: VesselID,
                                          voyage_information: VoyageID,
                                          vessel_reporting_information: ReportID,
                                        })
                                      }
                                    >
                                      Add another heater
                                    </Label>
                                  )}
                              </React.Fragment>
                            );
                          }
                        )}
                    </React.Fragment>
                  )}
                </FieldArray>
                {popOverBool && deleteIndex !== null && (
                  <DeletePopOver
                    target={`delete_${deleteIndex}`}
                    state={popOverBool}
                    setState={setPopOverBool}
                    onClick={async () => {
                      await handleDeletion(deleteIndex);
                      setPopOverBool(false);
                    }}
                  />
                )}
              </CardBody>
              <CardFooter className="p-2 py-3">
                <Row className="ele_row1">
                  <div className="d-flex flex-wrap gap-5">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn_size_cstm pos-end"
                      disabled={isSubmitting}
                    >
                      {isSubmitting &&
                        <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                      }
                      Next <i className="bx bx-chevron-right ms-1" />
                    </Button>
                    {record &&
                      (record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") &&
                      record.operation_precedence_id ===
                      ReportingOpConstant.SEA_PASSAGE ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm"
                        onClick={() => {
                          setErrors({})
                          errors = {};
                          setErrorMessage(null)
                          toggleAddTab(2);
                        }}
                      >
                        <i className="bx bx-chevron-left" /> Previous
                      </Button>
                    ) : (record &&
                      (record?.event_precedence_id !== EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event !== "combine_with_other_report") &&
                      record.operation_precedence_id ===
                      ReportingOpConstant.SEA_PASSAGE) ||
                      (record &&
                        (record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") &&
                        record.operation_precedence_id !==
                        ReportingOpConstant.SEA_PASSAGE) ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm"
                        onClick={() => {
                          setErrors({})
                          errors = {};
                          setErrorMessage(null)
                          toggleAddTab(1);
                        }}
                      >
                        <i className="bx bx-chevron-left" /> Previous
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm"
                        onClick={() => {
                          setErrors({})
                          errors = {};
                          setErrorMessage(null)
                          toggleDynamicTabs(previousTabIndex - 1);
                        }}
                      >
                        <i className="bx bx-chevron-left " /> Previous
                      </Button>
                    )}
                  </div>
                </Row>
              </CardFooter>
              <FormValuesDebug
                values={[values, errors, ThermalOilHeaterFormik.initialValues]}
              />
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
};

export default ThermalOilHeaterComponent;
