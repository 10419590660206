import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import React from 'react';
import { Card, CardHeader, Col, Row, TabContent, TabPane } from 'reactstrap';
import { EventPrecedence, ReportingOpConstant } from 'shared/constants';
import SludgeBilgeWaterComponent from './partials/SludgeBilgeWaterComponent';
import EngineParameterComponent from './partials/EngineParameterComponent';
import ThermalOilHeaterComponent from './partials/ThermalOilHeaterComponent';

interface LfondsAddInformationType {
    activeTab: number,
    toggleTab: any,
    tabsIdList: any[],
    toggleDynamicTabs: any
    record: any
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    previousTabIndex: number,
    setErrorMessage: (value: boolean) => void,
    activeLfondsAddTab: number,
    toggleAddLfondsTab: (value: number) => void,
}

const LfondsAddInformation = ({
    toggleDynamicTabs,
    record,
    ReportID,
    VesselID,
    VoyageID,
    previousTabIndex,
    setErrorMessage,
    activeLfondsAddTab,
    toggleAddLfondsTab
}: LfondsAddInformationType) => {
    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                <TabContent activeTab={activeLfondsAddTab} className="tabHeight">
                    <TabPane tabId={record &&
                        (record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") ? 1 : 0}>
                        <SludgeBilgeWaterComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddLfondsTab}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                            setErrorMessage={setErrorMessage}
                        />
                    </TabPane>
                    <TabPane tabId={(record &&
                        (record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") &&
                        record.operation_precedence_id === ReportingOpConstant.SEA_PASSAGE) ? 2 :
                        (record && (record?.event_precedence_id !== EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event !== "combine_with_other_report") &&
                            record.operation_precedence_id === ReportingOpConstant.SEA_PASSAGE) ? 1 : 0}>
                        <EngineParameterComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddLfondsTab}
                            activeAddTab={activeLfondsAddTab}
                            record={record}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                            setErrorMessage={setErrorMessage}
                        />
                    </TabPane>
                    <TabPane tabId={(record &&
                        (record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") &&
                        record.operation_precedence_id === ReportingOpConstant.SEA_PASSAGE) ? 3 :
                        ((record && (record?.event_precedence_id !== EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event !== "combine_with_other_report") &&
                            record.operation_precedence_id === ReportingOpConstant.SEA_PASSAGE) ||
                            (record &&
                                (record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") &&
                                record.operation_precedence_id !== ReportingOpConstant.SEA_PASSAGE)) ? 2 : 1}>
                        <ThermalOilHeaterComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddLfondsTab}
                            record={record}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                            setErrorMessage={setErrorMessage}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </Card>
    )
}

export default LfondsAddInformation