import React from 'react';
import { Formik, Field, FieldArray, Form, FieldProps } from 'formik';
import { Button, Label, Row, Col, CardFooter } from 'reactstrap';
import * as Yup from 'yup';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import apiGlobal from 'global/api.global';
import { successToast } from 'Components/Toasts';
import { queryClient } from 'react-query/queryClient';
import { queryKeyes } from 'shared/queryKeys';

interface VesselMachinery {
    id: number;
    vessel_machinery_name: string;
}

interface EditFuelConsumerGroupProps {
    group: string;
    groupName: string;
    vesselMachinery: any;
    vesselMachineriesList: VesselMachinery[];
    setEditFuelConsumerGroup: (value: boolean) => void;
    editConsumerGroup: boolean; // If needed for conditional logic within the component
    editPutURL: string;
    refreshVesselMachineries: number;
    setRefreshVesselMachineries: (value: number) => void;
    state: boolean;
    setState: (value: boolean) => void;
}

const EditFuelConsumerGroup = (props: EditFuelConsumerGroupProps) => {
    let {
        group,
        groupName: fcGroupName,
        vesselMachinery,
        vesselMachineriesList,
        setEditFuelConsumerGroup,
        editPutURL,
        refreshVesselMachineries,
        setRefreshVesselMachineries,
        state,
        setState
    } = props;
    // Initial form values
    const initialValues = {
        vessel_machinery_fc_group_name: vesselMachinery.vessel_machinery_fc_group_name || '',
        vessel: vesselMachinery.vessel || null,
        machinery: vesselMachinery.machinery || null,
        vessel_machinery: vesselMachinery.vessel_machinery || [], // Prepopulate with selected IDs
    };

    // Form validation schema
    const validationSchema = Yup.object().shape({
        vessel_machinery: Yup.array()
            .min(1, 'At least one machinery must be selected')
            .required('Machinery selection is required'),
    });
    // Handle form submission
    const handleSubmit = async (values: any, actions: any) => {
        try {
            apiGlobal.put(`${editPutURL}${vesselMachinery.id}/`, values).then(async res => {
                if (res.status === 200) {
                    successToast('Group created successfully edited');
                    actions.setSubmitting(false);
                    if (group === 'fuel') {
                        await queryClient.invalidateQueries(queryKeyes.vessel.MachinaryFuelGroupByVesselId.key);
                    } else {
                        await queryClient.invalidateQueries(queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key);
                    }
                    setRefreshVesselMachineries(refreshVesselMachineries + 1);
                    setState(!state);
                }
            })
        } catch (err) {
            console.error('Error:', err);
        }
    };

    return (
        <div className="ps-4 pe-4">
            <h5 className="mb-3">{fcGroupName}</h5>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values: any, actions: any) => {
                    actions.setSubmitting(true);
                    handleSubmit(values, actions);
                }}
            >
                {({ values, isSubmitting }) => (
                    <Form noValidate autoComplete="off">
                        <div className="table-responsive">
                            <table className="table table-striped table-bordered w-100">
                                <thead className="table-light">
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th>Machinery Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <FieldArray name="vessel_machinery">
                                        {({ form: { setFieldValue, values } }) => (
                                            vesselMachineriesList
                                                .filter((machinery: any) => machinery?.precedence_id === vesselMachinery?.machinery_precedence_id)
                                                .map((machinery: any, index: number) => (
                                                    <tr key={machinery.id}>
                                                        <td className="text-center align-middle">{index + 1}</td>
                                                        <td className="align-middle">
                                                            <div className="d-flex align-items-center">
                                                                <Field name="vessel_machinery">
                                                                    {({ field }: FieldProps) => {
                                                                        const isChecked = Array.isArray(values.vessel_machinery)
                                                                            ? values.vessel_machinery.includes(machinery.id)
                                                                            : false;
                                                                        return (
                                                                            <div className="d-flex align-items-center">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`checkbox-${machinery.id}`}
                                                                                    className="form-check-input"
                                                                                    checked={isChecked}
                                                                                    onChange={(e) => {
                                                                                        const updatedValue = e.target.checked
                                                                                            ? [...field.value, machinery.id]
                                                                                            : field.value.filter((id: number) => id !== machinery.id);
                                                                                        setFieldValue('vessel_machinery', updatedValue);
                                                                                    }}
                                                                                />
                                                                                <Label for={`checkbox-${machinery.id}`} className="ms-2 mb-0">
                                                                                    {machinery.vessel_machinery_name}
                                                                                </Label>
                                                                            </div>
                                                                        );
                                                                    }}
                                                                </Field>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                        )}
                                    </FieldArray>
                                </tbody>
                            </table>
                        </div>
                        <CardFooter className="p-2 py-3 mt-3">
                            <Row>
                                <Col>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="float-end"
                                        disabled={isSubmitting}
                                    >
                                        Update
                                    </Button>
                                    <Button
                                        type="button"
                                        color="secondary"
                                        className="float-end me-2"
                                        onClick={() => setEditFuelConsumerGroup(false)}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <FormValuesDebug values={[values, initialValues]} />
                            </Row>
                        </CardFooter>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default EditFuelConsumerGroup;