/**
 * file used in Machinery Fuel tab in vessel configuration
 */
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Form,
  CardFooter,
  Input,
} from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import {
  loadFuelSettings,
  loadMachineryFuelGroup,
  loadVesselFuelTypes,
} from "VesselMaster/vesselMaster.hooks";
import { FormikProps, useFormik } from "formik";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import apiGlobal from "global/api.global";
import { handleServerResponse, isConfigurationButtonDisabled } from "GenericForms/Helper";
import ToolTip from "Components/ToolTip";
import { useEffect, useState } from "react";
import { queryClient } from "react-query/queryClient";
import { VesselConfigrationTabs } from "shared/constants";
import isEqual from "fast-deep-equal";
import Message from "Components/Message";

interface FuelMachineryConfigurationType {
  refreshVesselMachineries: number;
  setRefreshVesselMachineries: (value: number) => void;
  setCheckValuesBeforeSwitch?: (value: boolean) => void;
  setTabName?: (value: string) => void;
  setFormik?: (value: FormikProps<any>) => void;
  VesselConfActiveTab: number;
  formik?: FormikProps<any>;
  refreshForm: boolean;
}

const FuelMachineryConfiguration = ({
  refreshVesselMachineries,
  setRefreshVesselMachineries,
  setCheckValuesBeforeSwitch,
  setTabName,
  setFormik,
  VesselConfActiveTab,
  refreshForm
}: FuelMachineryConfigurationType) => {
  /** State variables start */
  const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);
  const [hoverId, setHoverId] = useState<string | null>(null);
  /** State variables end */

  /** useQueries */
  /** Load distinct fuel types configured on vessel */
  const {
    data: FuelTypes,
    isLoading: FuelTypesLoading,
    isError: FuelTypesError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.vesselFuelType.key, VesselID],
    async () => {
      return await loadVesselFuelTypes(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load FC machinery */
  const {
    data: VesselMachinery,
    isLoading: VesselMachineryLoading,
    isError: VesselMachineryError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.MachinaryFuelGroupByVesselId.key, VesselID],
    async () => {
      return await loadMachineryFuelGroup(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load Fuel setting */
  const {
    data: FuelSetting,
    isLoading: FuelSettingLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.FuelSettings.key, VesselID],
    async () => {
      return await loadFuelSettings(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** useQueries end */

  /** Assign initial values to fuel consumption Formik object */
  const getFuelInitialValues = () => {
    let arr: any[] = [];
    if (FuelSetting && FuelSetting.length > 0) {
      arr = [...FuelSetting];
      let distinctFuelSetting: any = [];
      FuelSetting && FuelSetting.forEach((fuel: any) => {
        const existing = distinctFuelSetting.find((item: any) => item.fuel_name === fuel.fuel_name &&
          item.vessel_machinery_fc_group === fuel.vessel_machinery_fc_group);
        // If not found, push the new fuel class
        if (!existing) {
          distinctFuelSetting.push(fuel);
        }
      });
      VesselMachinery && VesselMachinery?.flatMap((machine: any) => {
        FuelTypes?.filter((item: any) =>
          !distinctFuelSetting.some((fuel: any) => item?.fuel_type === fuel?.fuel_name &&
            machine?.id === fuel?.vessel_machinery_fc_group)
        )?.forEach((fuelType: any) => {
          arr.push({
            vessel: VesselID,
            vessel_machinery_fc_group: machine.id,
            vessel_machinery_fc_group_name: machine.vessel_machinery_fc_group_name,
            fuel_name: fuelType.fuel_type,
            selected: false,
          })
        })
        return '';
      })
      return arr;
    } else if (VesselMachinery && FuelTypes) {
      const FuelConsumption = VesselMachinery.flatMap((machine: any) =>
        FuelTypes.map((fuel: any) => ({
          vessel: VesselID,
          vessel_machinery_fc_group: machine.id,
          vessel_machinery_fc_group_name: machine.vessel_machinery_fc_group_name,
          fuel_name: fuel.fuel_type,
          selected: false,
        }))
      );
      return FuelConsumption;
    }
  };

  /** General Settings Formik Object */
  const FuelFormik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      fuel: getFuelInitialValues()
    },
    onSubmit: async () => {
      FuelFormik.setSubmitting(true);
      let responseArray: any[] = [];
      FuelFormik?.values.fuel.forEach((val: any) => {
        if (val?.id > 0) {
          if (val?.selected === false) {
            responseArray.push(apiGlobal.delete(`/vessel_fuel_setting/${val.id}/`));
          }
        } else {
          if (val?.selected === true) {
            responseArray.push(apiGlobal.post(`/vessel_fuel_setting/`, val));
          }
        }
      })
      /** handle server response */
      await handleServerResponse(responseArray).then(async (res) => {
        if (res) {
          await queryClient.invalidateQueries(queryKeyes.vessel.FuelSettings.key);
          setRefreshVesselMachineries(refreshVesselMachineries + 1);
        }
      });
      FuelFormik.setSubmitting(false);
    }
  });

  const tabName = 'Machinery Fuel';
  const handleFormikStateChange = (values: any, initialValues: any) => {
    const areValuesEqual = isEqual(values, initialValues);;
    setCheckValuesBeforeSwitch(areValuesEqual);
    setTabName(tabName);
    return areValuesEqual;
  };

  useEffect(() => {
    handleFormikStateChange(FuelFormik?.values, FuelFormik?.initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FuelFormik?.values, FuelFormik?.initialValues]);

  /** useEffect */
  useEffect(() => {
    FuelFormik.initialValues.fuel = getFuelInitialValues();
    FuelFormik.values.fuel = getFuelInitialValues();
    setRefreshVesselMachineries(refreshVesselMachineries + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FuelTypes, VesselMachinery, VesselID])

  useEffect(() => {
    if (VesselConfActiveTab === VesselConfigrationTabs.MACHINERY_FUEL && FuelFormik?.initialValues !== FuelFormik?.values) {
      setFormik(FuelFormik); // Example: Assign formik instance for the "Other" tab
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VesselConfActiveTab, FuelFormik]);

  if (FuelTypesLoading || VesselMachineryLoading || FuelSettingLoading) {
    return <Loading message='Loading required data!' />
  } else if (FuelTypesError || VesselMachineryError) {
    return <ErrorComponent message='Error loading required data!' />
  } else {
    return (
      <>
        <Card className="border-0">
          {(FuelTypesLoading || VesselMachineryLoading || FuelSettingLoading) && (
            <Loading message="Loading required data!" />
          )}
          {(FuelTypesError || VesselMachineryError) && (
            <ErrorComponent message="Error loading required data!" />
          )}
          {/* {FuelSettingError && FuelFormik.initialValues=getFuelInitialValues()} */}
          {!(FuelTypesLoading || VesselMachineryLoading || FuelSettingLoading) &&
            !(FuelTypesError || VesselMachineryError) &&
            <Form
              onSubmit={FuelFormik.handleSubmit}
              noValidate
              autoComplete="off"
            >
              <CardBody className="p-0">
                <Row>
                  <Col md={12} className="px-0">
                    <div className="table-responsive p-0">
                      <table className="table mb-2" key={`${refreshForm}-${refreshVesselMachineries}`}>
                        <thead className="table-light">
                          <tr>
                            <th className="p-2 align-middle sr-no-width">
                              #
                            </th>
                            <th className="p-2 align-middle">
                              Machinery name
                            </th>
                            {FuelTypes.length > 0 &&
                              FuelTypes.map(
                                (fuel_name: any, index: number) => (
                                  <th
                                    key={fuel_name.id || index}
                                    className="p-2 align-middle text-center"
                                    style={{ width: "10%" }}
                                    id={`Fuel_Type_${fuel_name.id}`}
                                    onMouseEnter={() => setHoverId(`Fuel_Type_${fuel_name.id}`)}
                                    onMouseLeave={() => setHoverId(null)}
                                  >
                                    {fuel_name.fuel_type_name}
                                    <i className="bx bx-info-circle ml-2p" />
                                  </th>
                                )
                              )}
                          </tr>
                        </thead>
                        <tbody>
                          {(!VesselMachinery || VesselMachinery.length === 0) ? (
                            <tr>
                              <td colSpan={FuelTypes.length + 2} className="text-center">
                                <Message message="No vessel machinery available." type="warning" width="30rem" />
                              </td>
                            </tr>
                          ) : (
                            VesselMachinery.map((machine: any, machineIndex: number) => {
                              const filteredFuels = FuelFormik.values.fuel?.filter(
                                (fuel: any) => fuel.vessel_machinery_fc_group === machine.id
                              );

                              return (
                                <tr key={machine.id || machineIndex}>
                                  <td className="p-2 align-middle text-center">{machineIndex + 1}</td>
                                  <td className="p-2 align-middle">
                                    {machine.vessel_machinery_fc_group_name}
                                  </td>
                                  {FuelTypes.map((fuelType: any, fuelIndex: number) => {
                                    const fuelEntry = filteredFuels?.find(
                                      (fuel: any) => fuel.fuel_name === fuelType.fuel_type
                                    );

                                    const fieldName = `fuel.${FuelFormik?.values?.fuel?.indexOf(
                                      fuelEntry || {}
                                    )}.selected`;

                                    return (
                                      <td key={fuelIndex} className="p-2 align-middle text-center">
                                        <Input
                                          type="checkbox"
                                          id={`${machine.id}_${fuelType.id}`}
                                          name={fieldName}
                                          onBlur={FuelFormik.handleBlur}
                                          onChange={(e: any) => {
                                            FuelFormik.handleChange(e);
                                          }}
                                          defaultChecked={fuelEntry?.selected || false}
                                        />
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="p-2 py-3 mb-3">
                <Button
                  type="submit"
                  color="primary"
                  className="justify_right"
                  disabled={isConfigurationButtonDisabled(vessel)}
                >
                  Save
                </Button>
              </CardFooter>
              <Row className="mt-2">
                <FormValuesDebug
                  values={[
                    FuelFormik.values,
                    FuelFormik.errors,
                    FuelFormik.initialValues,
                  ]}
                />
              </Row>
            </Form>}
        </Card>
        {hoverId !== null &&
          <ToolTip
            target={hoverId}
            message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
            isOpen={hoverId !== null}
          />
        }
        <p>- Set all types of fuels used by the machinery.</p>
      </>
    )
  };
};

export default FuelMachineryConfiguration;
