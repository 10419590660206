import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import { loadCompletedReports } from 'VesselMaster/vesselMaster.hooks';
import { CardBody } from 'reactstrap';
import ToolTip from 'Components/ToolTip';
import ViewReport from '../Media/VD2.png'
import ViewReportModal from '../Components/ViewReportModal';
import VesselTableHeadComponent from './VesselTableHeadComponent';
import PageNavButton from 'Dashboard/PendingFiles/PageNavButton';
import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import Message from 'Components/Message';

interface CompletedReportsInterface {
    isPreviousVoyage?: boolean;
}

const CompletedReports = ({ isPreviousVoyage }: CompletedReportsInterface) => {
    const { VoyageID, VesselID } = useSelector((state: RootState) => state.Reporting);
    const [viewReport, setViewReport] = useState(false);
    const [vesselId, setVesselId] = useState(0);
    const [reportId, setReportId] = useState(0);
    const [hoverId, setHoverId] = useState<string | null>(null)
    const [pageURL, setPageURL] = useState("");
    const [PopOverState, setPopOverState] = useState(false);
    const [cachedReports, setCachedReports] = useState<any>(null);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    /**
     * useEffect to set page url
     */
    useEffect(() => {
        setPageURL(queryKeyes.pagination.completeReportPage.url(VesselID, VoyageID));
    }, [VesselID, VoyageID]);

    /**
    * function returns Vessel Voyage Reporting information 
    * @param number VesselID
    * @param number VoyageID
    * @returns mix
    */
    const { data: reports, isLoading: reportsFetching, isError: reportsError } = useQuery(
        [queryKeyes.pagination.completeReportPage.key, VesselID, VoyageID, pageURL],
        async () => { return await loadCompletedReports(VesselID, VoyageID, pageURL) },
        {
            enabled: true,
            onSuccess: (data) => {
                setInitialLoadComplete(true);
                // Update cached reports when new data arrives
                if (data?.results?.length > 0) {
                    setCachedReports(data);
                }
            }
        }
    );

    // Determine which data to display - use current data if available, otherwise use cached data
    const displayData = reports || cachedReports;
    const page = displayData?.results;

    const handleViewChanges = (id: number) => {
        setViewReport(true);
        setReportId(id);
    }

    function sendEmail(vesselId: number, reportId: number) {
        setPopOverState(false); // Ensure this executes regardless of success or failure

        return apiGlobal.post(queryKeyes.vessel.sendEmail.url(), {
            vessel_id: vesselId,
            report_id: reportId,
        })
            .then(res => {
                // Check if the response is successful
                if (res.status === 201 || res.status === 200) {
                    if (res?.data?.state === true) {
                        const successMessage = res?.data?.message || "Email sent successfully!";
                        successToast(successMessage); // Display success message from response if available
                    }
                }
            })
            .catch(err => {
                // Extract the most useful error message
                const errorMessage =
                    err?.response?.data?.error ||  // Custom API error message
                    err?.response?.data?.message ||
                    err?.response?.statusText ||   // Default HTTP status text
                    err?.message ||                // Generic error message from Axios
                    "An unknown error occurred";   // Fallback message
                errorToast(errorMessage); // Show the error message in a toast
            });
    }

    // Show loading indicator only during initial load
    const showLoadingIndicator = reportsFetching && !initialLoadComplete;
    return (
        <CardBody className='p-0'>
            <div className="table-responsive" id='owner_table'>
                {!isPreviousVoyage &&
                    <div className='ele_row mb-2'>
                        <h4 id='owner_table' className='mb-3'>Vessel Reporting Information</h4>
                    </div>}
                {showLoadingIndicator && (
                    <Loading message={'Loading required data!'} />
                )}

                {reportsError && (
                    <ErrorComponent message={'Unable to load required data!'} />
                )}
                {!reportsError && (
                    <table className="table mb-0">
                        <VesselTableHeadComponent reportStatus="completed" />
                        <tbody>
                            {page ? (
                                page.map((report: any, index: number) => (
                                    <tr key={report.id}>
                                        <td className="p-2 align-middle text-center">{index + 1}</td>
                                        <td className="p-2 align-middle">{`${report.reporting_event_name} (${report.reporting_type})`}</td>
                                        <td className="p-2 align-middle text-center">{report?.local_time?.slice(0, 10)} {report?.local_time?.slice(11, 16)}</td>
                                        <td className="p-2 align-middle text-center">
                                            <img
                                                alt="View Report"
                                                className="align-middle pointer mr-2"
                                                src={ViewReport}
                                                height="29"
                                                id={`view_details_${report.id}`}
                                                onMouseEnter={() => setHoverId(`view_details_${report.id}`)}
                                                onMouseLeave={() => setHoverId(null)}
                                                onClick={() => {
                                                    handleViewChanges(report.id);
                                                    setHoverId(null);
                                                }}
                                            />
                                            {!isPreviousVoyage && (
                                                <i
                                                    className="bx bx-mail-send pointer icon_s32 color-assign-user align-middle"
                                                    id={`send_email_${report.id}`}
                                                    onMouseEnter={() => setHoverId(`send_email_${report.id}`)}
                                                    onMouseLeave={() => setHoverId(null)}
                                                    onClick={() => {
                                                        setHoverId(null);
                                                        setReportId(report.id);
                                                        setVesselId(report.vessel);
                                                        setPopOverState(true);
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={4} className="p-3 text-center">
                                        <Message message="No completed reports available at the moment." type="danger" width="500px" />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            {page?.length > 0 &&
                                <tr>
                                    <td colSpan={5} className="p-2">
                                        <PageNavButton
                                            setPageUrl={setPageURL}
                                            pageobj={displayData}
                                            pageUrl={pageURL}
                                            isLoading={reportsFetching}
                                        />
                                    </td>
                                </tr>
                            }
                        </tfoot>
                    </table>
                )}
                {hoverId !== null &&
                    <ToolTip
                        target={hoverId}
                        message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                        isOpen={hoverId !== null}
                    />
                }

                {PopOverState && reportId !== 0 &&
                    <PopOver
                        state={PopOverState}
                        setState={setPopOverState}
                        message={'Are you sure you want to send email ?'}
                        target={`send_email_${reportId}`}
                        handleClick={() => {
                            sendEmail(vesselId, reportId);
                        }}
                    />
                }
            </div>

            <ViewReportModal
                state={viewReport}
                setState={setViewReport}
                vesselId={VesselID}
                reportId={reportId}
                modalId={`${VesselID}${reportId}`}
            />
        </CardBody>
    )
}

export default CompletedReports;