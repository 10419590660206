import React, { useState, useEffect } from 'react';
import { CardBody, Col, Row } from "reactstrap";
import '../global/GlobalCSS.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { VesselState, getVoyagesAction, setVesselID, setVesselState, setVoyageID } from "../Store/Generic/ReportingSlice";
import CreateVoyage from './CreateVoyage';
import ToolTip from '../Components/ToolTip';
import VesselReportingIcon from '../Media/vesselReporting.png';
import DeletePopOver from '../Components/DeletePopOver';
import { vesselVoyageReportingList, voyageList } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { successToast, voyageErrorToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';
import { hasRole } from 'utils/auth/authUtils';
import { ReportingStatusConstant, Roles, VoyageConstant } from 'shared/constants';
import PopOver from 'Components/PopOver';
import { queryClient } from 'react-query/queryClient';
import VoyageTableHeadComponent from './VoyageTableHeadComponent';
import EditIcon from '../Media/ED2.png';
import DisableEditIcon from '../Media/ED2_disabled.png';
import DeleteIcon from '../Media/delete.png';
import DisabledDeleteIcon from '../Media/delete_disabled.png';
import CompleteVoyageIcon from '../Media/doubleTick.png'
import DisabledCompleteVoyageIcon from '../Media/doubleTick_disabled.png'
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import DisabledVesselReportingIcon from '../Media/vesselReporting_disabled.png';

const OngoingVoyage = () => {
    const dispatch = useDispatch();
    const { VesselID, VesselState, VoyageID } = useSelector((state: RootState) => state.Reporting);
    const [deletePopOverState, setDeletePopOverState] = useState<boolean>(false);
    const [completeVoyBoolean, setCompleteVoyBoolean] = useState(false);
    const [target, setTarget] = useState<string>("");
    const [voyObj, setVoyObj] = useState<any>({});
    const [report, setReport] = useState<any>([]);
    const [hoverId, setHoverId] = useState<string | null>(null);
    const [deleteName, setDeleteName] = useState<string>('');
    const handleVesselState = (rowID: number, voyageID: number, record: VesselState) => {
        dispatch(setVesselID(rowID))
        dispatch(setVoyageID(voyageID))
        dispatch(setVesselState(record))
    }
    useEffect(() => {
        queryClient.invalidateQueries(
            queryKeyes.vessel.VoyageList.key
        );
    }, [])

    useEffect(() => {
        dispatch(getVoyagesAction('voyage_information' as string));
    }, [dispatch]);

    const { data: voyages, isFetching: voyagesFetching, isError: voyagesError } = useQuery(
        [queryKeyes.vessel.VoyageList.key, VesselID],
        async () => await voyageList(VesselID), {
        staleTime: 600000
    })

    const handleCloseVoyage = async (voyage: any) => {
        try {
            voyage.voyage_status = VoyageConstant.CLOSED;
            const response = await apiGlobal.put(`/voyage_information/${voyage.id}/`, voyage).then(res => {
                if (res.status === 200) {
                    setCompleteVoyBoolean(false);
                    queryClient.invalidateQueries(queryKeyes.pagination.VoyageListPage.key);
                    dispatch(getVoyagesAction('voyage_information' as string));
                    successToast("Voyage completed successfully!");
                }
            });
            return response;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    const handleDeleteVoyage = async (voyage: any) => {
        try {
            voyage.voyage_status = VoyageConstant.CLOSED;
            const response = await apiGlobal.delete(`/voyage_information/${voyage.id}/`, voyage).then(res => {
                if (res.status === 200 || res.status === 204) {
                    setDeletePopOverState(false);
                    queryClient.invalidateQueries(queryKeyes.vessel.VoyageList.key);
                    successToast("Voyage deleted successfully!");
                }
            });
            return response;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    useEffect(() => {
        const fetchReports = async () => {
            let newRecords = []
            const filteredVoyages = voyages?.filter((item: any) => item.display === true &&
                (item.voyage_status === VoyageConstant.NEWVOYAGE || item.voyage_status === VoyageConstant.ONGOING))
            if (filteredVoyages) {
                for (const voyage of filteredVoyages) {
                    const record = await vesselVoyageReportingList(VesselID, voyage?.id)
                    newRecords.push(record[0])
                }
            }
            setReport(newRecords)
        }
        fetchReports()
    }, [voyages, VesselID])

    return (
        <React.Fragment>
            <React.Fragment>
                <CardBody className='p-0'>
                    <Row className='d-flex'>
                        <Col className='' sm={6}>
                            <h4 id='owner_table'>Voyage Reporting Information</h4>
                        </Col>
                        <Col sm={{ size: 2, offset: 4 }} className='text-right'>
                            {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MASTER)) &&
                                <button
                                    color='primary'
                                    className='btn btn-primary mb-3'
                                    onClick={async () => {
                                        await apiGlobal.get(
                                            queryKeyes.vessel.CheckNewVoyage.url(VesselID)
                                        ).then((res: any) => {
                                            if (res.data.voyage === false) {
                                                handleVesselState(VesselID, -1, 'CREATE_VOYAGE_REPORTING');
                                            } else {
                                                voyageErrorToast(res.data.detail);
                                            }
                                        }).catch(err => {
                                            console.error(err);
                                        });
                                    }}>
                                    <i className="dripicons-plus font-size-16 align-middle me-2" />
                                    Create New
                                </button>}
                        </Col>
                    </Row>
                    <div className="table-responsive" id='owner_table'>
                        {(voyagesFetching) && (
                            <Loading message='Loading required data!' />
                        )}
                        {voyagesError && (
                            <ErrorComponent message='Unable to load voyage list!' />
                        )}
                        {((!voyagesFetching && !voyagesError) && voyages.length > 0) &&
                            <table className="table mb-0">
                                <VoyageTableHeadComponent />
                                <tbody>
                                    {voyages.filter((item: any) => item.display === true &&
                                        (item.voyage_status === VoyageConstant.NEWVOYAGE || item.voyage_status === VoyageConstant.ONGOING))
                                        .map((Voyage: any, index: number) => (
                                            <React.Fragment>
                                                <tr key={Voyage.id} >
                                                    <td className="p-2 align-middle text-center">{index + 1}</td>
                                                    <td className="p-2 align-middle">{Voyage.voyage_number}</td>
                                                    <td className="p-2 align-middle text-left">{Voyage.voyage_status === 1 ? <p>New</p> : <p>Ongoing</p>}</td>
                                                    <td className="p-2 align-middle text-left">{Voyage?.departure_date_time?.toString()?.substring(0, 10)} {Voyage?.departure_date_time?.toString()?.substring(11, 16)}</td>
                                                    <td className="p-2 align-middle text-center">
                                                        {(
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MANAGER)
                                                        ) &&
                                                            <React.Fragment>
                                                                <img
                                                                    src={Voyage?.voyage_status === VoyageConstant.NEWVOYAGE ? EditIcon : DisableEditIcon}
                                                                    alt="Edit Icon"
                                                                    height="28"
                                                                    className='pointer'
                                                                    id={`${Voyage?.voyage_status === VoyageConstant.NEWVOYAGE ? `Edit_voyage_${Voyage.id}` : `Edit_voyage_disabled_${Voyage.id}`}`}
                                                                    onMouseEnter={() => setHoverId(`${Voyage?.voyage_status === VoyageConstant.NEWVOYAGE ? `Edit_voyage_${Voyage.id}` : `Edit_voyage_disabled_${Voyage.id}`}`)}
                                                                    onMouseLeave={() => setHoverId(null)}
                                                                    onClick={() => {
                                                                        if (Voyage?.voyage_status === VoyageConstant.NEWVOYAGE) {
                                                                            handleVesselState(VesselID, Voyage.id, 'VOYAGE_REPORTING_EDIT');
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                    </td>
                                                    <td className="p-2 align-middle text-center">
                                                        {(
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MASTER) ||
                                                            hasRole(Roles.CHIEFENGINEER) ||
                                                            hasRole(Roles.MANAGER)
                                                        ) &&
                                                            <React.Fragment>
                                                                <img alt=""
                                                                    src={(Voyage?.voyage_status === VoyageConstant.NEWVOYAGE && voyages.filter((item: any) =>
                                                                        item.display === true && item?.voyage_status === VoyageConstant.ONGOING)?.length > 0) ?
                                                                        DisabledVesselReportingIcon : VesselReportingIcon}
                                                                    height="32"
                                                                    id={(Voyage?.voyage_status === VoyageConstant.NEWVOYAGE && voyages.filter((item: any) =>
                                                                        item.display === true && item?.voyage_status === VoyageConstant.ONGOING)?.length > 0) ?
                                                                        `Vessel_Reporting_disabled_${Voyage.id}` : `Vessel_Reporting_${Voyage.id}`}
                                                                    onMouseEnter={() => setHoverId((Voyage?.voyage_status === VoyageConstant.NEWVOYAGE && voyages.filter((item: any) =>
                                                                        item.display === true && item?.voyage_status === VoyageConstant.ONGOING)?.length > 0) ?
                                                                        `Vessel_Reporting_disabled_${Voyage.id}` : `Vessel_Reporting_${Voyage.id}`)}
                                                                    onMouseLeave={() => setHoverId(null)}
                                                                    className={`${(Voyage?.voyage_status === VoyageConstant.ONGOING ||
                                                                        (Voyage?.voyage_status === VoyageConstant.NEWVOYAGE && voyages.filter((item: any) =>
                                                                            item.display === true && item?.voyage_status === VoyageConstant.ONGOING)?.length === 0)) ?
                                                                        'pointer' : ''}`
                                                                    }
                                                                    onClick={() => {
                                                                        if (Voyage?.voyage_status === VoyageConstant.ONGOING ||
                                                                            (Voyage?.voyage_status === VoyageConstant.NEWVOYAGE && voyages.filter((item: any) =>
                                                                                item.display === true && item?.voyage_status === VoyageConstant.ONGOING)?.length === 0)
                                                                        ) {
                                                                            handleVesselState(VesselID, Voyage.id, 'VESSEL_REPORTING');
                                                                            setHoverId(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                    </td>
                                                    <td className="p-2 align-middle text-center">
                                                        {(
                                                            hasRole(Roles.ES_ADMIN) ||
                                                            hasRole(Roles.MASTER)
                                                        ) &&
                                                            <React.Fragment>
                                                                <img alt=""
                                                                    src={Voyage?.voyage_status === VoyageConstant.NEWVOYAGE ||
                                                                        report[0]?.vessel_reporting_status === ReportingStatusConstant.OPEN ? DisabledCompleteVoyageIcon :
                                                                        CompleteVoyageIcon
                                                                    }
                                                                    height="28"
                                                                    className='pointer'
                                                                    id={`Complete_Voyage_${index}`}
                                                                    onMouseEnter={() => setHoverId(`Complete_Voyage_${index}`)}
                                                                    onMouseLeave={() => setHoverId(null)}
                                                                    onClick={async () => {
                                                                        if (
                                                                            Voyage?.voyage_status !== VoyageConstant.NEWVOYAGE &&
                                                                            report[0]?.vessel_reporting_status !== ReportingStatusConstant.OPEN
                                                                        ) {
                                                                            setTarget(`Complete_Voyage_${index}`);
                                                                            setVoyObj(Voyage);
                                                                            setCompleteVoyBoolean(true);
                                                                        }
                                                                        setHoverId(null);
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                    </td>
                                                    <td className="p-2 align-middle text-center">
                                                        {(hasRole(Roles.ES_ADMIN)) &&
                                                            <React.Fragment>
                                                                <img
                                                                    alt=""
                                                                    src={Voyage?.voyage_status === VoyageConstant.NEWVOYAGE || report.length === 0 ?
                                                                        DeleteIcon : DisabledDeleteIcon}
                                                                    height="28"
                                                                    className='pointer'
                                                                    id={Voyage?.voyage_status === VoyageConstant.NEWVOYAGE || report.length === 0 ?
                                                                        `Delete_Voyage_${index}` : `Delete_Voyage_Disabled_${index}`}
                                                                    onMouseEnter={() => setHoverId(Voyage?.voyage_status === VoyageConstant.NEWVOYAGE || report.length === 0 ?
                                                                        `Delete_Voyage_${index}` : `Delete_Voyage_Disabled_${index}`)}
                                                                    onMouseLeave={() => setHoverId(null)}
                                                                    onClick={async () => {
                                                                        if (Voyage?.voyage_status === VoyageConstant.NEWVOYAGE || report.length === 0) {
                                                                            setTarget(`Delete_Voyage_${index}`);
                                                                            setDeletePopOverState(true);
                                                                            setVoyObj(Voyage);
                                                                            setDeleteName(Voyage.voyage_number)
                                                                            setHoverId(null);
                                                                        }
                                                                        setHoverId(null);
                                                                    }}
                                                                />
                                                            </React.Fragment>
                                                        }
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))
                                    }
                                </tbody>
                            </table>
                        }
                        {completeVoyBoolean &&
                            <PopOver
                                target={target}
                                handleClick={() => {
                                    handleCloseVoyage(voyObj)
                                    setHoverId(null);
                                }}
                                message={'Are you sure you want to complete the voyage?'}
                                state={completeVoyBoolean}
                                setState={setCompleteVoyBoolean}
                            />
                        }
                        {deletePopOverState &&
                            <DeletePopOver
                                target={target}
                                onClick={() => {
                                    handleDeleteVoyage(voyObj)
                                    setHoverId(null);
                                }}
                                state={deletePopOverState}
                                setState={setDeletePopOverState}
                                message={`Are you sure you want to delete ${deleteName}`}
                            />
                        }
                        {hoverId !== null &&
                            <ToolTip
                                target={hoverId}
                                message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                                isOpen={hoverId !== null}
                            />
                        }
                    </div>
                </CardBody>
            </React.Fragment >
            {VesselState === 'CREATE_VOYAGE_REPORTING' && <CreateVoyage />
            }
            {VesselState === 'VOYAGE_REPORTING_EDIT' && <CreateVoyage voyageId={VoyageID} />}
        </React.Fragment>
    )
}
export default OngoingVoyage;