import React, { useState } from 'react';
import { Formik, Form, Field, FieldProps, FieldArray } from 'formik';
import * as Yup from 'yup'; // Assuming you are using Yup for validation
import { Button, Label, Input, Row, Col } from 'reactstrap';
import apiGlobal from 'global/api.global';
import { successToast } from 'Components/Toasts';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { loadNotificationGroupList, loadNotificationCategoryList, loadNotificationPriorityList } from 'VesselMaster/vesselMaster.hooks';
import Select from "react-select";
import { customStyle } from 'shared/CommonCSS';
// import { useQuery } from 'react-query';
// import { queryKeyes } from 'shared/queryKeys';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import Loading from 'Components/Loading';

const Compose = ({ handleBackClick }: { handleBackClick: any }) => {
    // State to manage group and user ID selections
    const [arrObj, setArrObj] = useState([{ groupIds: [], userIds: [] }]);

    // Query to fetch notification state
    // const { data: notificationStateObj }: { data: any } = useQuery([queryKeyes.notification.NotificationState],
    //     async () => { return await loadNotificationState() },
    //     {
    //         enabled: true,
    //         staleTime: Infinity,
    //     }
    // )

    // Initial form values
    const initialValues = {
        category: null as number | null,
        current_state: 2,
        date: new Date(),
        message: null as string,
        priority: null as number | null,
        title: null as string,
        users: [] as { groupId: number | null; userId: number | null }[],
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().max(250, commonValidationMessages.max250).required(commonValidationMessages.required),
        current_state: Yup.number(),
        category: Yup.number().required(commonValidationMessages.required),
        priority: Yup.number().required(commonValidationMessages.required),
        message: Yup.string().max(1000, 'Message cannot exceed 1000 characters').required(commonValidationMessages.required),
        users: Yup.array().of(
            Yup.object().shape({
                groupId: Yup.number().nullable(),
                userId: Yup.number()
            })
        ),
    });

    // const { VesselID, VoyageID, ReportID, Reports, Vessels } = useSelector((state: RootState) => state.Reporting);
    // const record = Reports.find((rec: any) => rec.id === ReportID);
    // const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    // const handleGroupChange = (selectedGroups: any[], index: number, setFieldValue: any) => {
    //     const groupIds = selectedGroups.map(group => group.id); 
    //     const updatedGroups = [...arrObj];
    //     updatedGroups[index] = { ...updatedGroups[index], groupIds };
    //     setArrObj(updatedGroups);
    //     setFieldValue('users', transformUsers(updatedGroups));
    // };

    // Handle change when group is selected
    const handleGroupChange = (selectedGroups: any[], index: number, setFieldValue: any) => {
        const groupIds = selectedGroups.map(group => group.id); // Extract group IDs
        const userIds = selectedGroups.map(group => group.user); // Extract associated user IDs

        const updatedGroups = [...arrObj]; // Update the state object
        updatedGroups[index] = { ...updatedGroups[index], groupIds, userIds }; // Add group and user IDs to the state
        setArrObj(updatedGroups); // Update the state

        // Update form values for users field based on selected groups
        setFieldValue('users', transformUsers(updatedGroups)); // Transform the users based on group selection
    };

    // Handle change when user is selected
    const handleUserChange = (selectedUsers: any[], index: number, setFieldValue: any) => {
        const userIds = selectedUsers.map(user => user.user); // Extract user IDs
        const updatedGroups = [...arrObj]; // Copy current state
        updatedGroups[index] = { ...updatedGroups[index], userIds }; // Update user IDs in the state
        setArrObj(updatedGroups); // Update state with new user IDs

        // Update form values for users field
        setFieldValue('users', transformUsers(updatedGroups)); // Transform users array based on updated selections
    };

    // Transform users based on selected group and user IDs
    const transformUsers = (arr: any) => {
        const uniqueUsers = new Set(); // Ensure unique user IDs across groups

        return arr.flatMap((item: any) => {
            const groupIds = item.groupIds || []; // Extract group IDs
            const userIds = item.userIds || []; // Extract user IDs

            // Combine groupId and userId ensuring uniqueness of userId
            return groupIds.flatMap((groupId: number) =>
                userIds
                    .filter((userId: number) => {
                        if (!uniqueUsers.has(userId)) {
                            uniqueUsers.add(userId); // Add only unique user IDs
                            return true;
                        }
                        return false;
                    })
                    .map((userId: number) => ({ groupId, userId })) // Map each unique userId with corresponding groupId
            );
        });
    };
    // Handle form submission to different endpoints
    const handleFormSubmit = async (values: any, actions: any, endPoint: string) => {
        try {
            await apiGlobal.post(endPoint, values); // Post form data to the specified endpoint
            successToast("Data saved successfully!"); // Show success notification
            actions.resetForm(); // Reset the form on successful submission
            setArrObj([{ groupIds: [], userIds: [] }]); // Reset the state object
        } catch (err) {
            console.error('Error:', err); // Log errors
        }
    };
    /**Load Notifaction Group options */
    const { data: notificationGroupList, isLoading: isLoadingNotificationGroupList } = useQuery(
        queryKeyes.notification.GroupList.key,
        () => loadNotificationGroupList()
    )
    /**Load Notifaction Category options */
    const { data: notificationCategoryList, isLoading: isLoadingNotificationCategoryList } = useQuery(
        queryKeyes.notification.NotificationCategoryList.key,
        () => loadNotificationCategoryList()
    )
    /**Load Notifaction Priority options */
    const { data: notificationPriorityList, isLoading: isLoadingNotificationPriorityList } = useQuery(
        queryKeyes.notification.NotificationPriorityList.key,
        () => loadNotificationPriorityList()
    )
    const isLoading = (isLoadingNotificationCategoryList || isLoadingNotificationGroupList || isLoadingNotificationPriorityList);

    return (
        <React.Fragment>
            {isLoading && <Loading message='Loading required data!' />}
            {!isLoading &&
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, actions) => {
                        handleFormSubmit(values, actions, '/notification/');
                    }}
                >
                    {({ values, errors, handleSubmit, setFieldValue, isSubmitting, handleBlur, touched, resetForm }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="p-3">
                                <Button onClick={handleBackClick} className="mb-4">Back</Button>

                                <div className="mb-3">
                                    <Label for="title">Title</Label>
                                    <Field
                                        type="text"
                                        name="title"
                                        id="title"
                                        className="form-control"
                                    />
                                    {errors.title && <div className="text-danger">{errors.title}</div>}
                                </div>

                                <Row>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label for="category">Category</Label>
                                            <Field name="category">
                                                {({ field }: FieldProps) => (
                                                    <Select
                                                        name={field.name}
                                                        inputId={field.name}
                                                        options={notificationCategoryList}
                                                        getOptionLabel={(e: any) => e?.category}
                                                        getOptionValue={(e: any) => e?.id}
                                                        onBlur={handleBlur}
                                                        onChange={(e: any) => setFieldValue(field.name, e?.id)}
                                                        menuPortalTarget={document.body}
                                                        styles={customStyle}
                                                    />
                                                )}
                                            </Field>{errors?.category && touched?.category &&
                                                touched?.category &&
                                                errors?.category && env?.form_validation === true && (
                                                    <ErrorTooltip
                                                        target={`category`}
                                                        message={errors?.category}
                                                        open={errors?.category}
                                                    />
                                                )}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="mb-3">
                                            <Label for="priority">Priority</Label>
                                            <Field name="priority">
                                                {({ field }: FieldProps) => (
                                                    <Select
                                                        name={field.name}
                                                        inputId={field.name}
                                                        options={notificationPriorityList}
                                                        getOptionLabel={(e: any) => e.priority}
                                                        getOptionValue={(e: any) => e.id}
                                                        onBlur={handleBlur}
                                                        onChange={(e: any) => setFieldValue(field.name, e.id)}
                                                        menuPortalTarget={document.body}
                                                        styles={customStyle}
                                                    />
                                                )}
                                            </Field>
                                            {errors?.priority && touched?.priority &&
                                                touched?.priority &&
                                                errors?.priority && env?.form_validation === true && (
                                                    <ErrorTooltip
                                                        target={`priority`}
                                                        message={errors?.priority}
                                                        open={errors?.priority}
                                                    />
                                                )}
                                        </div>
                                    </Col>
                                </Row>

                                <div className="mb-3">
                                    <Label for="message">Message</Label>
                                    {/* <Field name="message"> */}
                                    <Input
                                        type="textarea"
                                        name="message"
                                        id="message"
                                        value={values.message}
                                        onChange={(e) => setFieldValue('message', e.target.value)}
                                        className="form-control"
                                    />
                                    {/* </Field> */}
                                    {errors.message && touched.message && <div className="text-danger">{errors.message}</div>}
                                </div>

                                <div className="mb-3">
                                    <Label for="users">Users</Label>
                                    <FieldArray name="users">
                                        {() => (
                                            <>
                                                {arrObj.map((group, index) => (
                                                    <Row key={index}>
                                                        <Col md={5}>
                                                            <Label>Group ID</Label>
                                                            <Select
                                                                name={`users[${index}].groupId`}
                                                                options={notificationGroupList}
                                                                getOptionLabel={(e: any) => `Group ${e.group}`}
                                                                getOptionValue={(e: any) => e.id}
                                                                onChange={(selectedGroup: any) => handleGroupChange(selectedGroup, index, setFieldValue)}
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                                isMulti
                                                            />
                                                        </Col>
                                                        <Col md={5}>
                                                            <Label>User ID</Label>
                                                            <Select
                                                                isMulti
                                                                name={`users[${index}].userId`}
                                                                options={notificationGroupList}
                                                                getOptionLabel={(e: any) => `User ${e.user}`}
                                                                getOptionValue={(e: any) => e.id}
                                                                onChange={(selectedUsers: any) => handleUserChange(selectedUsers, index, setFieldValue)}
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                            />
                                                        </Col>
                                                        <Col md={2} className="d-flex align-items-end">
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </>
                                        )}
                                    </FieldArray>
                                </div>
                                <div>
                                    <Row>
                                        <Col md={2}>
                                            <Button
                                                type="button"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => handleFormSubmit(values, resetForm, '/notification/sent/')}
                                            >
                                                Save
                                            </Button></Col>
                                        <Col md={2}>
                                            <Button
                                                type="button"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => handleFormSubmit(values, resetForm, '/notification/')}
                                            >
                                                Send
                                            </Button></Col></Row></div>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[values, errors, initialValues]} />
                                </Row>
                            </div>
                        </Form>
                    )}
                </Formik>
            }
        </React.Fragment>
    );
};


export default Compose;
