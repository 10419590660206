import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { Field, Formik, FieldProps } from 'formik';
import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Label, Row } from 'reactstrap';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from "environment_system/env_system";
import { fetchUserProfile, fetchUserProfileById, loadRoleMaster, loadUserGroups } from 'VesselMaster/vesselMaster.hooks';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from 'Components/Toasts';
import { queryClient } from 'react-query/queryClient';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import Loading from 'Components/Loading';
import { RoleID, RoleName, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';

interface CreateUserType {
    setState: (state: boolean) => void;
    userID?: number;
    setRefreshTableKey?: (key: number) => void;
    refreshTableKey?: number;
}

const CreateUserComponent = ({ setState, userID, setRefreshTableKey, refreshTableKey }: CreateUserType) => {
    /** State varibales start */
    const [mobileError, setMobileError] = useState<boolean>(false)
    const [showMoreBool, setShowMoreBool] = useState<boolean>(false)
    /** State variables end */
    /** useQueries */
    /** User profile by id */
    const { data: userProfile, isLoading: userProfileLoading, isError: userProfileError } = useQuery(
        [queryKeyes.user.profileID.key, userID, refreshTableKey],
        async () => {
            return await fetchUserProfileById(userID);
        },
        { enabled: !!userID, staleTime: Infinity }
    )
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { data: userTable } = useQuery(
        [queryKeyes.user.profile.key, userID],
        async () => {
            return await fetchUserProfile();
        },
    )
    /** useQueries end */
    /**
     * function to validate phone input
     * @param input number in string 
     * @param code country code
     * @returns 
     */
    const validateInput = (input: string, code: string) => {
        if (`+${code}` === input) {
            return true;
        }
        return false;
    };

    const getUserInitialValues = () => {
        if (userID && userID > 0) {
            return userProfile;
        } else {
            return ({
                first_name: '',
                middle_name: '',
                last_name: '',
                role: null,
                gender: '',
                mobile: '',
                email: '',
                group: null,
                remark: '',
            });
        };
    }

    const createUserFormik = {
        initialValues: getUserInitialValues(),
        validationSchema: Yup.object({
            first_name: Yup.string()
                .max(50, commonValidationMessages.max50Char)
                .required(commonValidationMessages.required),
            middle_name: Yup.string()
                .max(50, commonValidationMessages.max50Char).nullable(),
            last_name: Yup.string()
                .max(50, commonValidationMessages.max50Char)
                .required(commonValidationMessages.required),
            role: Yup.string().required(commonValidationMessages.required),
            gender: Yup.string().required(commonValidationMessages.required),
            mobile: Yup.string().required(commonValidationMessages.required),
            email: Yup.string()
                .max(100, commonValidationMessages.max100Char)
                .required(commonValidationMessages.required),
            group: Yup.string().nullable(),
            remark: Yup.string().nullable()
        })
    }
    /** useEffect end */
    /**load roles  */
    const { data: roles, isLoading: rolesLoading, } = useQuery(
        [queryKeyes.masters.RoleMaster.key],
        async () => {
            return await loadRoleMaster();
        },
        {
            enabled: true,
        }
    );
    const filteredRoles = roles?.filter((role: { constant: string; }) => role.constant !== 'ES_ADMIN' || hasRole(Roles.ES_ADMIN));

    const { data: userGroups, isLoading: userGroupsLoading } = useQuery(
        [queryKeyes.user.group.key],
        async () => {
            return await loadUserGroups();
        },
        {
            enabled: true,
        }
    );
    const isLoading = rolesLoading || userGroupsLoading || userProfileLoading;
    return (
        <React.Fragment>
            {isLoading && <Loading message='Loading required data!' />}
            {(!isLoading && !userProfileError) &&
                <Formik
                    onSubmit={async (values, actions) => {
                        actions.setSubmitting(true);

                        if (userID > 0) {
                            apiGlobal.put(queryKeyes.user.profileID.url(userID), values)
                                .then(async () => {
                                    await queryClient.invalidateQueries(queryKeyes.user.profile.key);
                                    await setRefreshTableKey(refreshTableKey + 1);
                                    successToast("User updated successfully");
                                    setState(false);
                                })
                                .catch((err) => {
                                    console.error(err);
                                    errorToast("An error occurred");
                                })
                                .finally(() => {
                                    actions.setSubmitting(false);
                                });
                            await queryClient.invalidateQueries(queryKeyes.user.profile.key);
                            setRefreshTableKey(refreshTableKey + 1);
                        } else {
                            apiGlobal.post(queryKeyes.user.register.url(), values)
                                .then(async () => {
                                    successToast("User created successfully");
                                    await queryClient.invalidateQueries(queryKeyes.user.profile.key);
                                    setRefreshTableKey(refreshTableKey + 1);
                                    setState(false);
                                })
                                .catch((err) => {
                                    console.error(err);
                                    errorToast("An error occurred");
                                })
                                .finally(() => {
                                    actions.setSubmitting(false);
                                });
                            await queryClient.invalidateQueries(queryKeyes.user.profile.key);
                            setRefreshTableKey(refreshTableKey + 1);
                        }
                    }}
                    initialValues={createUserFormik.initialValues}
                    validationSchema={createUserFormik.validationSchema}
                >
                    {(props: any) => (
                        <Form onSubmit={props.handleSubmit} autoComplete="off" noValidate key={refreshTableKey}>
                            <Card className='border-0'>
                                <CardBody className='p-3'>
                                    <Row className='mb-2'>
                                        <Col>
                                            <Label className='asteric mb-0'>First Name</Label>
                                            <Field name="first_name">
                                                {({ field }: FieldProps) => (
                                                    <Input
                                                        {...field}
                                                        type='text'
                                                        id="first_name"
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                        defaultValue={props?.values?.first_name}
                                                    />
                                                )}
                                            </Field>
                                            {props?.errors && props?.touched &&
                                                props?.errors?.first_name &&
                                                env?.form_validation === true &&
                                                (
                                                    <ErrorTooltip
                                                        target={`first_name`}
                                                        message={props?.errors?.first_name}
                                                        open={props?.errors?.first_name}
                                                    />
                                                )}
                                        </Col>
                                        <Col>
                                            <Label className='mb-0'>Middle Name</Label>
                                            <Field name="middle_name">
                                                {({ field }: FieldProps) => (
                                                    <Input
                                                        {...field}
                                                        type='text'
                                                        id="middle_name"
                                                        defaultValue={props?.values?.middle_name} />
                                                )}
                                            </Field>
                                        </Col>
                                        <Col>
                                            <Label className='asteric mb-0'>Last Name</Label>
                                            <Field name="last_name">
                                                {({ field }: FieldProps) => (
                                                    <Input
                                                        {...field}
                                                        type='text'
                                                        id="last_name"
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                        defaultValue={props?.values?.last_name} />
                                                )}
                                            </Field>
                                            {props?.errors && props?.touched &&
                                                props?.errors?.last_name &&
                                                env?.form_validation === true &&
                                                (
                                                    <ErrorTooltip
                                                        target={`last_name`}
                                                        message={props?.errors?.last_name}
                                                        open={props?.errors?.last_name}
                                                    />
                                                )}
                                        </Col>
                                    </Row>
                                    <Row className='mb-2'>
                                        <Col>
                                            <Label className='asteric mb-0'>Role</Label>
                                            <Field name="role">
                                                {({ field, form }: FieldProps) => (
                                                    <Select
                                                        name={field.name}
                                                        id="role"
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(selectedOption: any) => {
                                                            props?.setFieldTouched(field.name, true);
                                                            form.setFieldValue(field.name, selectedOption?.id);
                                                        }}
                                                        defaultValue={{
                                                            id: props?.values?.role,
                                                            name: RoleName[Object.keys(RoleID).find(key => RoleID[key as keyof typeof RoleID] === props?.values?.role) as keyof typeof RoleName] || ''
                                                        }}
                                                        options={filteredRoles}
                                                        getOptionLabel={(e: any) => e.name}
                                                        getOptionValue={(e: any) => e.id}
                                                    />
                                                )}
                                            </Field>

                                            {props?.errors && props?.touched &&
                                                props?.errors?.role &&
                                                env?.form_validation === true &&
                                                (
                                                    <ErrorTooltip
                                                        target={`role`}
                                                        message={props?.errors?.role}
                                                        open={props?.errors?.role}
                                                    />
                                                )}
                                        </Col>
                                        <Col className="d-flex flex-column mt-3">
                                            <Label className="mb-0 asteric">Gender</Label>
                                            <div className="d-flex">
                                                <Field name="gender">
                                                    {({ field, form }: FieldProps) => (
                                                        <div className="d-flex align-items-center me-3">
                                                            <Input
                                                                {...field}
                                                                type="radio"
                                                                id="Male"
                                                                name="gender"
                                                                value="Male"
                                                                checked={props?.values?.gender === 'Male'}
                                                                className="me-1"
                                                                onClick={() => {
                                                                    if (props.values.gender === 'Male') {
                                                                        form.setFieldValue('gender', '');
                                                                    } else {
                                                                        form.setFieldValue('gender', 'Male');
                                                                    }
                                                                }}
                                                            />
                                                            <Label className="mb-0" htmlFor="Male">Male</Label>
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="gender">
                                                    {({ field, form }: FieldProps) => (
                                                        <div className="d-flex align-items-center me-3">
                                                            <Input
                                                                {...field}
                                                                type="radio"
                                                                id="Female"
                                                                name="gender"
                                                                value="Female"
                                                                checked={props?.values?.gender === 'Female'}
                                                                className="me-1"
                                                                onClick={() => {
                                                                    if (props.values.gender === 'Female') {
                                                                        form.setFieldValue('gender', '');
                                                                    } else {
                                                                        form.setFieldValue('gender', 'Female');
                                                                    }
                                                                }}
                                                            />
                                                            <Label className="mb-0" htmlFor="Female">Female</Label>
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="gender">
                                                    {({ field, form }: FieldProps) => (
                                                        <div className="d-flex align-items-center">
                                                            <Input
                                                                {...field}
                                                                type="radio"
                                                                id="Other"
                                                                name="gender"
                                                                value="Other"
                                                                checked={props?.values?.gender === 'Other'}
                                                                className="me-1"
                                                                onClick={() => {
                                                                    if (props?.values?.gender === 'Other') {
                                                                        form.setFieldValue('gender', '');
                                                                    } else {
                                                                        form.setFieldValue('gender', 'Other');
                                                                    }
                                                                }}
                                                            />
                                                            <Label className="mb-0" htmlFor="Other">Other</Label>
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mb-2'>
                                        <Col>
                                            <Label className='asteric mb-0'>Mobile</Label>
                                            <Field name={`mobile`}>
                                                {({ field, form }: FieldProps) => (
                                                    <PhoneInput
                                                        {...field}
                                                        containerStyle={{ width: '100%' }}
                                                        inputStyle={{ width: '100%' }}
                                                        country={'in'}
                                                        value={props?.values?.mobile}
                                                        onBlur={(e: any, country: any) => {
                                                            const phoneNumber = e.target.value.substring(country.dialCode.length + 2);
                                                            form.setFieldValue(field.name, `+${country.dialCode} ${phoneNumber}`);
                                                            e.target.id = "mobile";
                                                            if (validateInput(e.target.value, country.dialCode) === true) {
                                                                setMobileError(true);
                                                            } else {
                                                                setMobileError(false);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                            {mobileError &&
                                                <ErrorTooltip
                                                    message={'Field is required!'}
                                                    target="mobile"
                                                    open={mobileError}
                                                />
                                            }
                                        </Col>
                                        <Col>
                                            <Label className='asteric mb-0'>Email</Label>
                                            <Field name="email">
                                                {({ field }: FieldProps) => (
                                                    <Input
                                                        {...field}
                                                        type='text'
                                                        id="email"
                                                        onBlur={(e: any) => props?.handleBlur(e)}
                                                        onChange={(e: any) => props?.handleChange(e)}
                                                        defaultValue={props?.values?.email}
                                                    />
                                                )}
                                            </Field>
                                            {props?.errors && props?.touched &&
                                                props?.errors?.email &&
                                                env?.form_validation === true &&
                                                (
                                                    <ErrorTooltip
                                                        target={`email`}
                                                        message={props?.errors?.email}
                                                        open={props?.errors?.email}
                                                    />
                                                )}
                                        </Col>
                                    </Row>
                                    <Row className='mb-2'>
                                        <Label
                                            onClick={() => { setShowMoreBool(!showMoreBool) }}
                                            className='pointer link_color_blue'
                                        >{showMoreBool ? `Show less` : `Show more`}</Label>
                                        {showMoreBool &&
                                            <React.Fragment>
                                                <Col>
                                                    <Label className='mb-0'>Group</Label>
                                                    <Field name="group">
                                                        {({ field, form }: FieldProps) => (
                                                            <Select
                                                                name={field.name}
                                                                id="group"
                                                                onBlur={(e: any) => props?.handleBlur(e)}
                                                                onChange={(selectedOption: any) => {
                                                                    props?.setFieldTouched(field.name, true);
                                                                    form.setFieldValue(field.name, selectedOption?.id);
                                                                }}
                                                                options={userGroups}
                                                                getOptionLabel={(e: any) => e.group}
                                                                getOptionValue={(e: any) => e.id}
                                                                defaultValue={{
                                                                    id: props?.values?.group,
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Col>
                                                <Col>
                                                    <Field name="remark">
                                                        {({ field }: FieldProps) => (
                                                            <Input
                                                                {...field}
                                                                type='textarea'
                                                                id="remark"
                                                                placeholder='Remark'
                                                            />
                                                        )}
                                                    </Field>
                                                </Col>
                                            </React.Fragment>
                                        }
                                    </Row>
                                    <Row className='d-flex ms-auto justify_right'>
                                        <Col>
                                            <Button color='danger' onClick={() => props.resetForm()}>Reset</Button>
                                        </Col>
                                        <Col>
                                            <Button color='primary' type='submit' disabled={props.isSubmitting} >{(userID > 0) ? 'Save ' : 'Ok'}</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <FormValuesDebug
                                        values={[
                                            props?.values,
                                            props?.errors,
                                            createUserFormik.initialValues,
                                        ]}
                                    />
                                </CardFooter>
                            </Card>
                        </Form>
                    )}
                </Formik>
            }
        </React.Fragment>
    );
};

export default CreateUserComponent;
