import DeletePopOver from "Components/DeletePopOver";
import ErrorComponent from "Components/ErrorComponent";
import Loading from "Components/Loading";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import { errorToast, successToast } from "Components/Toasts";
import { useDocumentTitle } from "Components/useDocument.hooks";
import { errResponse, handleDynamicPreviousTabId, handleServerResponse } from "GenericForms/Helper";
import {
    loadAuxEngineMaxSFOC,
    loadMachineryEnergyGroup,
    loadMachineryFuelGroup,
    loadOtherSpOpFuelEngergyByVesselAndReport,
    vesselFuel,
} from "VesselMaster/vesselMaster.hooks";
import env from "environment_system/env_system";
import { useFormik } from "formik";
import apiGlobal from "global/api.global";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { queryClient } from "react-query/queryClient";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Input,
    Label,
    Form,
    Button,
} from "reactstrap";
import { EngineerReportTabsConstant } from "shared/constants";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";

interface OtherSpecialOperationType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    activeTab: number;
    toggleTab: any;
    tabsIdList: any[];
    record: any;
    lastRecord: any;
    toggleDynamicTabs: any;
    previousTabIndex: number;
    setErrorMessage: any;
}

const OtherSpecialOperationComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    toggleTab,
    tabsIdList,
    record,
    toggleDynamicTabs,
    previousTabIndex,
    setErrorMessage,
}: OtherSpecialOperationType) => {
    const [deleteBool, setDeleteBool] = useState(false);
    const [deleteRequestID, setDeleteRequestID] = useState<number>(null);
    const [deleteIndex, setDeleteIndex] = useState<number>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0)
    useDocumentTitle("Other Special Operations - ecoSAIL");
    /** Queries */
    /** Fetch Vessel distinct fuel  */
    const {
        data: fuelTypes,
        isLoading: fuelTypesLoading,
        isError: fuelTypesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Machinary fuel Group */
    const {
        data: machineryFuelGroup,
        isLoading: machineryFuelGroupLoading,
        isError: machineryFuelGroupError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryFuelGroupByVesselId.key, VesselID],
        async () => {
            return await loadMachineryFuelGroup(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Machinary energy Group */
    const {
        data: machineryEnergyGroup,
        isLoading: machineryEnergyGroupLoading,
        isError: machineryEnergyGroupError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryEnergyGroupByVesselId.key, VesselID],
        async () => {
            return await loadMachineryEnergyGroup(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Other special opeation edit data with fuel and energy */
    const {
        data: OtherSpOpFuelEngergy,
        isLoading: OtherSpOpFuelEngergyLoading,
        isError: OtherSpOpFuelEngergyError,
    } = useQuery(
        [queryKeyes.vessel.OtherSpOpFuelEngergyByVesselAndReport.key, VesselID, ReportID],
        async () => {
            return await loadOtherSpOpFuelEngergyByVesselAndReport(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Max SFOC of vessel's auxiliary engines */
    const { data: AuxEngineMaxSFOC, isLoading: AuxEngineMaxSFOCLoading, isError: AuxEngineMaxSFOCError } = useQuery(
        [queryKeyes.vessel.AuxEngineMaxSFOC.key, VesselID],
        async () => {
            return await loadAuxEngineMaxSFOC(VesselID);
        }, { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to formik's fuel consumption initial object */
    const getInitialFuelConsumptionValue = () => {
        let obj: any[] = [];
        if (
            obj !== undefined &&
            machineryFuelGroup !== undefined &&
            fuelTypes !== undefined &&
            obj.length <= machineryFuelGroup.length * fuelTypes.length
        ) {
            machineryFuelGroup?.flatMap((machine: any) => {
                fuelTypes?.map((fuel: any) => {
                    obj.push({
                        vessel_machinery_fc_group_name:
                            machine.vessel_machinery_fc_group_name,
                        fuel_consumption_value: 0,
                        other_special_operation: null,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        vessel_machinery_fc_group: machine.id,
                        fuel_name: fuel.fuel_type,
                        visible: false,
                    });
                    return fuel;
                });
                return machine;
            });
            return obj;
        } else {
            return null;
        }
    };

    /** Assign values to formik's energy consumption initial object */
    const getInitialEnergyConsumptionValue = () => {
        let obj: any[] = [];
        if (
            obj !== undefined &&
            machineryEnergyGroup !== undefined &&
            obj.length <= machineryEnergyGroup.length
        ) {
            machineryEnergyGroup?.map((machine: any) => {
                obj.push({
                    vessel_machinery_ec_group_name:
                        machine.vessel_machinery_ec_group_name,
                    energy_consumption_value: 0,
                    equivalent_fuel_consumption: 0,
                    other_special_operation: null,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                    vessel_machinery_ec_group: machine.id,
                    visible: false,
                });
                return machine;
            });
            return obj;
        } else {
            return null;
        }
    };

    /**
     * get initial values form getInitialFuelConsumptionValue() & getInitialEnergyConsumptionValue() 
     * and assign to formik's initial object
     * @returns 
     */
    const getOtherSpOpIntialvalues = () => {
        if (OtherSpOpFuelEngergy && OtherSpOpFuelEngergy.length > 0) {
            return OtherSpOpFuelEngergy;
        } else {
            return [{
                other_operation: {
                    other_operation_name: null,
                    fuel_consumption: true,
                    energy_consumption: true,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID
                },
                other_operation_fuel: getInitialFuelConsumptionValue(),
                other_operation_energy: getInitialEnergyConsumptionValue()
            }]
        }
    }

    /**
     * To submit values of Other Special operation
     * @param values values of formik
     * @param actions actions of formik
     */
    const submitOtherSpecialOperation = async (values: any) => {
        const responseArray: any = [];
        if (env?.form_validation === false) {
            setErrorMessage(null)
            toggleDynamicTabs(previousTabIndex + 1);
        }
        OtherSpecialOperationFormik.setSubmitting(true);
        if (values[0].other_operation?.id) {
            responseArray.push(apiGlobal.put(`${queryKeyes.vessel.UpdateOtherSpecialOperation.url()}`, values))
        } else {
            responseArray.push(apiGlobal.post(`${queryKeyes.vessel.OtherSpecialOperation.url()}`, values))
        }
        await handleServerResponse(responseArray).then(async (res) => {
            if (res === true) {
                await queryClient.invalidateQueries(
                    queryKeyes.vessel.OtherSpOpFuelEngergyByVesselAndReport.key
                );
                if (env?.form_validation === true) {
                    toggleDynamicTabs(previousTabIndex + 1);
                }
                setRefreshKey(refreshKey + 1)
                setErrorMessage(null)
            } else {
                setErrorMessage(res)
            }
            OtherSpecialOperationFormik.setSubmitting(false);
        })
    }

    /**
     * delete special operation object from server and initival values
     */
    const deleteOtherSpecialOperation = async () => {
        if (deleteRequestID) {
            apiGlobal.delete(`/other_special_operation/${deleteRequestID}/`).then(async res => {
                if (res.status === 200 || res.status === 204) {
                    successToast("Data deleted successfully!");
                    await queryClient.invalidateQueries(
                        queryKeyes.vessel.OtherSpOpFuelEngergyByVesselAndReport.key
                    );
                }
            }).catch(err => {
                if (errResponse.includes(err.response.status)) {
                    errorToast("Internal error occured, please contact the admin");
                }
            });
            setDeleteRequestID(null)
            setRefreshKey(refreshKey + 1)
        }
        const updatedValues = [...OtherSpecialOperationFormik?.values as any];
        updatedValues.splice(deleteIndex, 1); // Remove 1 item at the given index
        OtherSpecialOperationFormik.setValues(updatedValues as any); // Update the entire array in Formik
    }

    /** Other Special Operation's formik object */
    const OtherSpecialOperationFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: getOtherSpOpIntialvalues(),
        onSubmit: (values) => submitOtherSpecialOperation(values),
    });
    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Other Special Operations
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(machineryFuelGroupLoading ||
                machineryEnergyGroupLoading ||
                fuelTypesLoading ||
                OtherSpOpFuelEngergyLoading ||
                AuxEngineMaxSFOCLoading) && (
                    <Loading message="Loading required data!" />
                )}
            {(machineryFuelGroupError ||
                machineryEnergyGroupError ||
                fuelTypesError ||
                OtherSpOpFuelEngergyError ||
                AuxEngineMaxSFOCError
            ) && (
                    <ErrorComponent message="Unable to load required data!" />
                )}
            {!(
                machineryFuelGroupLoading ||
                machineryEnergyGroupLoading ||
                fuelTypesLoading ||
                OtherSpOpFuelEngergyLoading ||
                AuxEngineMaxSFOCLoading
            ) &&
                !(
                    machineryFuelGroupError ||
                    machineryEnergyGroupError ||
                    fuelTypesError ||
                    OtherSpOpFuelEngergyError ||
                    AuxEngineMaxSFOCError
                ) && (
                    <Form autoComplete="off" onSubmit={OtherSpecialOperationFormik.handleSubmit} noValidate key={refreshKey}>
                        <CardBody className="p-0">
                            <React.Fragment>
                                {OtherSpecialOperationFormik?.values &&
                                    OtherSpecialOperationFormik?.values?.map(
                                        (other: any, index: number) => {
                                            return (
                                                <React.Fragment>
                                                    <Card>
                                                        <CardHeader className="p-2">
                                                            <div className="d-flex align-items-center">
                                                                <h4 className="mb-0 pe-2">{index + 1}. Special operation name</h4>
                                                                <Input
                                                                    name={`${index}.other_operation.other_operation_name`}
                                                                    type="text"
                                                                    className="max-width-15"
                                                                    value={OtherSpecialOperationFormik?.values[index]?.other_operation.other_operation_name}
                                                                    onChange={OtherSpecialOperationFormik.handleChange}
                                                                />
                                                                {OtherSpecialOperationFormik?.values?.length === 1 ? null : (
                                                                    <div className="ms-auto">
                                                                        <i
                                                                            id={`delete_other_operation${index}`}
                                                                            className='dripicons-trash icon_s18 pointer'
                                                                            onClick={() => {
                                                                                setDeleteBool(true);
                                                                                setDeleteRequestID(other.other_operation.id ?? null);
                                                                                setDeleteIndex(index);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </CardHeader>
                                                        <CardBody className="p-0">
                                                            <Row className="m-0">
                                                                <Card className="mb-0 border-0">
                                                                    <CardHeader className="p-2">
                                                                        <div className="text-center">
                                                                            <Row>
                                                                                <Col className="ps-0">
                                                                                    <h5 className="page_title pos-start mb-0">
                                                                                        Fuel Consumption
                                                                                    </h5>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </CardHeader>
                                                                    <CardBody className="p-0 mt-2">
                                                                        <Row>
                                                                            {machineryFuelGroup?.map(
                                                                                (fuel: any, indx: number) => {
                                                                                    return (
                                                                                        <Col lg={3}>
                                                                                            <div className="ele_row1">
                                                                                                <div className="form-check mb-2">
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id={`machinery_${index}_${indx}`}
                                                                                                        checked={
                                                                                                            OtherSpecialOperationFormik?.values[index]?.other_operation_fuel
                                                                                                                ?.filter((vesselFuel: any) => vesselFuel?.vessel_machinery_fc_group === fuel?.id)
                                                                                                            [0]?.visible === true
                                                                                                        }
                                                                                                        onChange={(e: any) => {
                                                                                                            OtherSpecialOperationFormik.values[index].other_operation_fuel.forEach((other_fuel: any, idx: any) => {
                                                                                                                if (other_fuel.vessel_machinery_fc_group === fuel.id) {
                                                                                                                    OtherSpecialOperationFormik.setFieldValue(
                                                                                                                        `[${index}].other_operation_fuel[${idx}].visible`,
                                                                                                                        e.target.checked
                                                                                                                    );
                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <Label
                                                                                                    for={`machinery_${index}_${indx}`}
                                                                                                >
                                                                                                    {fuel.machinery_name}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </Col>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Row>
                                                                        <div className="table-responsive">
                                                                            <table className="table mb-0">
                                                                                <thead className="table-light">
                                                                                    <tr>
                                                                                        <th className="p-2 align-middle sr-no-width ">
                                                                                            #
                                                                                        </th>
                                                                                        <th
                                                                                            className="p-2 align-middle"
                                                                                            style={{ width: "20%" }}
                                                                                        >
                                                                                            Machinery name
                                                                                        </th>
                                                                                        {fuelTypes &&
                                                                                            fuelTypes?.map(
                                                                                                (fuelType: any) => {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <th
                                                                                                                className="p-2 align-middle asteric text-center"
                                                                                                            >
                                                                                                                {
                                                                                                                    fuelType.fuel_type_name
                                                                                                                }
                                                                                                            </th>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {OtherSpecialOperationFormik.values[index].other_operation_fuel
                                                                                        ?.filter((fuel: any) => fuel.visible === true)  // Filter visible fuels
                                                                                        ?.map((fuel: any) => fuel.vessel_machinery_fc_group_name)  // Get machinery names
                                                                                        ?.filter((value: any, index: any, self: any) => self.indexOf(value) === index)  // Remove duplicates
                                                                                        ?.length === 0  // Check if no machinery is selected
                                                                                        ? (
                                                                                            <tr>
                                                                                                <td colSpan={fuelTypes.length + 2} className="text-center">No vessel fuel machinery selected</td> {/* Display the message */}
                                                                                            </tr>
                                                                                        )
                                                                                        : OtherSpecialOperationFormik.values[index].other_operation_fuel
                                                                                            ?.filter((fuel: any) => fuel.visible === true)  // Filter visible fuels again
                                                                                            ?.map((fuel: any) => fuel.vessel_machinery_fc_group_name)  // Get machinery names again
                                                                                            ?.filter((value: any, index: any, self: any) => self.indexOf(value) === index)  // Remove duplicates again
                                                                                            ?.map((uniqueMachineryName: string, idx: number) => {
                                                                                                // Find all the fuels that match the current unique machinery name
                                                                                                const relatedFuels = OtherSpecialOperationFormik.values[index].other_operation_fuel.filter(
                                                                                                    (fuel: any) => fuel.vessel_machinery_fc_group_name === uniqueMachineryName && fuel.visible === true
                                                                                                );
                                                                                                return (
                                                                                                    <tr key={idx}>
                                                                                                        <td>{idx + 1}</td>
                                                                                                        <td>{uniqueMachineryName}</td>
                                                                                                        {relatedFuels?.map((fuel: any, fuelIdx: number) => (
                                                                                                            <td key={fuelIdx} className="p-2 align-middle text-center">
                                                                                                                <div className="d-flex justify-content-center align-items-center input-group">
                                                                                                                    <Input
                                                                                                                        className="max-width-5"
                                                                                                                        type="text"
                                                                                                                        name={`${index}.other_operation_fuel.${OtherSpecialOperationFormik.values[index].other_operation_fuel.indexOf(fuel)}.fuel_consumption_value`}  // Correct the path
                                                                                                                        value={fuel.fuel_consumption_value}  // Set value from Formik
                                                                                                                        onChange={OtherSpecialOperationFormik.handleChange}  // Handle change for Formik
                                                                                                                    />
                                                                                                                    <div className="input-group-text">
                                                                                                                        mt
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        ))}
                                                                                                    </tr>
                                                                                                );
                                                                                            })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Row>
                                                            <Row className="m-0">
                                                                {/* Electrical Energy Consumption */}
                                                                <Card className="mb-2 border-0">
                                                                    <CardHeader className="p-2">
                                                                        <div className="text-center">
                                                                            <Row>
                                                                                <Col className="ps-0">
                                                                                    <h5 className="page_title pos-start mb-0">
                                                                                        Electrical Energy Consumption
                                                                                    </h5>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </CardHeader>
                                                                    <CardBody className="p-0 mt-2">
                                                                        <Row>
                                                                            {machineryEnergyGroup?.map(
                                                                                (energy: any, indx: number) => {
                                                                                    return (
                                                                                        <Col lg={3}>
                                                                                            <div className="ele_row1">
                                                                                                <div className="form-check mb-2">
                                                                                                    <Input
                                                                                                        className="form-check-input"
                                                                                                        type="checkbox"
                                                                                                        id={`machinery_energy_${index}_${indx}`}
                                                                                                        checked={Array.isArray(OtherSpecialOperationFormik?.values?.[index]?.other_operation_energy) &&
                                                                                                            OtherSpecialOperationFormik?.values?.[index]?.other_operation_energy[indx]?.visible === true}
                                                                                                        onChange={(e: any) => {
                                                                                                            OtherSpecialOperationFormik.values[index].other_operation_energy.forEach((other_energy: any, idx: any) => {
                                                                                                                if (other_energy.vessel_machinery_ec_group === energy.id) {
                                                                                                                    OtherSpecialOperationFormik.setFieldValue(
                                                                                                                        `[${index}].other_operation_energy[${idx}].visible`,
                                                                                                                        e.target.checked
                                                                                                                    );
                                                                                                                }
                                                                                                            })
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                                <Label
                                                                                                    for={`machinery_energy_${index}_${indx}`}
                                                                                                >
                                                                                                    {energy.machinery_name}
                                                                                                </Label>
                                                                                            </div>
                                                                                        </Col>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Row>
                                                                        <div className="table-responsive">
                                                                            <table className="table mb-0">
                                                                                <thead className="table-light">
                                                                                    <tr>
                                                                                        <th className="p-2 align-middle sr-no-width">
                                                                                            #
                                                                                        </th>
                                                                                        <th
                                                                                            className="p-2 align-middle"
                                                                                            style={{ width: "35%" }}
                                                                                        >
                                                                                            Machinery name
                                                                                        </th>
                                                                                        <th
                                                                                            className="p-2 align-middle asteric text-center"
                                                                                        >
                                                                                            Energy consumed
                                                                                        </th>
                                                                                        <th
                                                                                            className="p-2 align-middle text-center"
                                                                                        >
                                                                                            Equivalent fuel consumption
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {OtherSpecialOperationFormik.values[index].other_operation_energy
                                                                                        ?.filter((energy: any) => energy.visible === true)  // Filter visible energy
                                                                                        ?.map((energy: any) => energy.vessel_machinery_ec_group_name)  // Get machinery names
                                                                                        ?.filter((value: any, index: any, self: any) => self.indexOf(value) === index)  // Remove duplicates
                                                                                        .length === 0  // Check if no machinery is selected
                                                                                        ? (
                                                                                            <tr>
                                                                                                <td colSpan={machineryEnergyGroup.length + 2} className="text-center">No vessel energy machinery selected</td> {/* Display the message */}
                                                                                            </tr>
                                                                                        )
                                                                                        : OtherSpecialOperationFormik.values[index].other_operation_energy
                                                                                            ?.filter((energy: any) => energy.visible === true)  // Filter visible energy again
                                                                                            ?.map((energy: any, idx: number) => {
                                                                                                return (
                                                                                                    <tr key={idx}>
                                                                                                        <td className="p-2 align-middle text-center">{idx + 1}</td>
                                                                                                        <td className="p-2 align-middle" >{energy.vessel_machinery_ec_group_name}</td>
                                                                                                        <td className="p-2 align-middle text-center">
                                                                                                            <div className="d-flex justify-content-center align-items-center input-group">
                                                                                                                <Input
                                                                                                                    className="max-width-5"
                                                                                                                    type="text"
                                                                                                                    name={`${index}.other_operation_energy.${idx}.energy_consumption_value`}  // Correct the path
                                                                                                                    value={energy.energy_consumption_value}  // Set value from Formik
                                                                                                                    onChange={OtherSpecialOperationFormik.handleChange}  // Handle change for Formik
                                                                                                                    onBlur={(e: any) => {
                                                                                                                        OtherSpecialOperationFormik.handleChange(e);
                                                                                                                        OtherSpecialOperationFormik.setFieldValue(`${index}.other_operation_energy.${idx}.equivalent_fuel_consumption`,
                                                                                                                            (AuxEngineMaxSFOC?.max_eedi_eexi_technical_file * (e.target.value) / 1000000)?.toFixed(4));
                                                                                                                    }}
                                                                                                                />
                                                                                                                <div className="input-group-text">kWh</div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td className="p-2 align-middle text-center">
                                                                                                            <strong>{OtherSpecialOperationFormik?.values[index]?.other_operation_energy[idx]?.equivalent_fuel_consumption}</strong>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                );
                                                                                            })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Row>
                                                            {(index === OtherSpecialOperationFormik?.values?.length - 1) && (
                                                                <Label
                                                                    className="link_color_blue p-2 pb-0 pt-0"
                                                                    onClick={() => {
                                                                        /** Create a new object to add to the values array  */
                                                                        const newOtherOperation = {
                                                                            other_operation: {
                                                                                other_operation_name: null as any,
                                                                                fuel_consumption: true,
                                                                                energy_consumption: true,
                                                                                vessel: VesselID,
                                                                                voyage_information: VoyageID,
                                                                                vessel_reporting_information: ReportID
                                                                            },
                                                                            other_operation_fuel: getInitialFuelConsumptionValue(),
                                                                            other_operation_energy: getInitialEnergyConsumptionValue()
                                                                        };
                                                                        /** Use Formik's setValues to update the entire array  */
                                                                        const updatedValues = [...OtherSpecialOperationFormik?.values, newOtherOperation];
                                                                        OtherSpecialOperationFormik.setValues(updatedValues); // Update Formik state
                                                                    }}
                                                                >
                                                                    Add new Other Operation
                                                                </Label>
                                                            )}

                                                        </CardBody>
                                                    </Card>
                                                </React.Fragment>
                                            );
                                        }
                                    )}
                            </React.Fragment>
                            {deleteBool &&
                                <DeletePopOver
                                    target={`delete_other_operation${deleteIndex}`}
                                    state={deleteBool}
                                    setState={setDeleteBool}
                                    onClick={async () => {
                                        await deleteOtherSpecialOperation()
                                    }}
                                />
                            }
                        </CardBody>
                        <Row className="ele_row1 px-2 py-3">
                            <div className="d-flex flex-wrap gap-5 m-0 btn_Margin">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size_cstm pos-end"
                                    disabled={OtherSpecialOperationFormik.isSubmitting}
                                    onClick={setErrorMessage(null)}
                                >
                                    {OtherSpecialOperationFormik.isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                    Next <i className="bx bx-chevron-right ms-1" />
                                </Button>
                                <Button
                                    type="button"
                                    color="primary"
                                    className="btn_size_cstm"
                                    onClick={() => {
                                        OtherSpecialOperationFormik?.setErrors({});
                                        handleDynamicPreviousTabId(
                                            false,
                                            tabsIdList,
                                            record,
                                            toggleTab,
                                            toggleDynamicTabs,
                                            previousTabIndex,
                                            null,
                                            null,
                                            EngineerReportTabsConstant.OtherSpecialOperationId
                                        );
                                    }}
                                >
                                    <i className="bx bx-chevron-left me-1" /> Previous
                                </Button>
                            </div>
                        </Row>
                        <FormValuesDebug
                            values={[
                                OtherSpecialOperationFormik.values,
                                OtherSpecialOperationFormik.errors,
                                OtherSpecialOperationFormik.initialValues,
                            ]}
                        />
                    </Form>
                )}
        </Card>
    );
};

export default OtherSpecialOperationComponent;
