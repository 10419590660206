import { useDocumentTitle } from 'Components/useDocument.hooks'
import Layout from 'HorizontalMenu/Menu'
import React from 'react'
import { Navbar, Container, Row } from 'reactstrap'

const FCeboAdjustment = () => {
    useDocumentTitle('FC(elec + boiler + other) Adjustment - ecoSAIL')
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className='page-content'>
                <Container fluid>
                    <Row className='mb-3'>
                        <h4>FC<sub>(elec + boiler + other)</sub>&nbsp;&nbsp;Adjustment</h4>
                    </Row>
                    <Row>
                        <p className='text-center'>No FC<sub>(elec + bolier + other)</sub> adjustments are available for this vessel</p>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FCeboAdjustment