import React from "react";

interface MessageProps {
  message: string;
  type: "info" | "warning" | "danger";
  width?: string;
  html?: boolean;
}

const Message = ({ message, type, width = "100%", html = false }: MessageProps) => {
  const getIcon = (type: "info" | "warning" | "danger") => {
    switch (type) {
      case "info":
        return <i className="mdi mdi-alert-circle-outline label-icon me-2 icon-info"></i>;
      case "warning":
        return <i className="mdi mdi-alert-circle-outline label-icon me-2 icon-warning"></i>;
      case "danger":
        return <i className="mdi mdi-block-helper label-icon me-2 icon-danger"></i>;
      default:
        return null;
    }
  };

  // Base alert classes
  const baseAlertClass = "custom-alert alert-top-border mb-0 ";

  // Color-specific classes based on type
  const colorClasses = {
    info: "alert-info",
    warning: "alert-warning",
    danger: "alert-danger"
  };

  // Render message content
  const renderMessage = () => {
    if (html) {
      return <span dangerouslySetInnerHTML={{ __html: message }} />;
    }
    return message;
  };

  return (
    <div
      className={`${baseAlertClass} ${colorClasses[type]}`}
      style={{ width }}
    >
      {getIcon(type)} {renderMessage()}
    </div>
  );
};

export default Message;