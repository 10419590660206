import React from 'react'
import { Card, Modal } from 'reactstrap'
import Alert from './Alert';
import { AlertColourTypeConstant } from 'shared/constants';
import env from 'environment_system/env_system';


interface AlertPopupType {
    state: boolean,
    setState: (value: boolean) => void;
    alertMessage: string;
    setAlertMessage: (value: string) => void;
    AlertColourType: any;
    setAlertColourType: (value: any) => void;
    alertType: string;
    setAlertType: (value: string) => void;
    formTitle: string;
    generatedFor?: number | null;
    fieldId?: string;
}

const AlertPopup = ({
    state,
    setState,
    alertMessage,
    setAlertMessage,
    AlertColourType,
    setAlertColourType,
    alertType,
    setAlertType,
    fieldId,
    formTitle,
    generatedFor = null,
}: AlertPopupType) => {
    /** Set the styles based on the alert type  */
    const cardStyle =
        AlertColourType === AlertColourTypeConstant.WARNING ?
            'alert-warning' : AlertColourType === AlertColourTypeConstant.DANGER ?
                'alert-danger' : 'alert-info';

    /** Set icon */
    const iconClass =
        AlertColourType === AlertColourTypeConstant.WARNING ?
            "mdi mdi-alert-outline" : AlertColourType === AlertColourTypeConstant.DANGER ?
                "mdi mdi-block-helper" : "mdi mdi-alert-circle-outline";

    /** Set the icon color dynamically based on alert type  */
    const iconColor =
        AlertColourType === AlertColourTypeConstant.WARNING ?
            'text-warning' : AlertColourType === AlertColourTypeConstant.DANGER ?
                'text-danger' : 'text-info';
    const handlePopUpState = () => {
        setState(!state);
        setAlertMessage(null);
        setAlertType(null);
        setAlertColourType(null);
    }
    return (
        <React.Fragment>
            {env?.alerts &&
                <Modal
                    size={'sm'}
                    isOpen={state}
                    toggle={() => {
                        handlePopUpState();
                    }}
                    backdrop={"static"}
                    id="staticBackdrop"
                >
                    <Card
                        className={`px-4 mb-0 text-center alert w-100 ${cardStyle}`}
                    >
                        <i className={`${iconClass} d-block display-4 mt-2 mb-2 ${iconColor}`}></i>
                        <h5 className={`${iconColor}`}>Alert!</h5>
                        <Alert
                            alertMessage={alertMessage}
                            alertType={alertType}
                            handlePopUpState={handlePopUpState}
                            fieldId={fieldId}
                            formTitle={formTitle}
                            generatedFor={generatedFor}
                        />
                    </Card>
                </Modal>
            }
        </React.Fragment>
    )
}

export default AlertPopup