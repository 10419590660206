import ErrorTooltip from 'Components/ErrorTooltip';
import ReportingFileUpload from 'Components/ReportingFileUpload';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react';
import { Card, CardHeader, Row, Col, CardBody, Label, Input } from 'reactstrap';
import { ReportingEvents, FileStatus } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import env from 'environment_system/env_system';
import { useSelector } from 'react-redux';
import { RootState } from 'index';

interface ReeferContainerProps {
  alertBool: boolean;
  deleteBaplieFileObj: any;
  setRefreshKey: any;
  refreshKey: number;
  baplieFiles: any[];
  setBaplieFiles: any;
  deleteLogBookObj: any;
  logBook: any[];
  setLogBook: any;
}
const ReeferContainerComponent = ({
  alertBool,
  deleteBaplieFileObj,
  setRefreshKey,
  refreshKey,
  baplieFiles,
  setBaplieFiles,
  deleteLogBookObj,
  logBook,
  setLogBook,
}: ReeferContainerProps) => {
  const {
    values,
    errors,
    handleChange,
    handleBlur,
  }: { values: any; errors: any; handleChange: any; handleBlur: any } =
    useFormikContext<any>();
  const { ReportID, Reports } = useSelector(
    (state: RootState) => state.Reporting,
  );
  const record = Reports.find((rec: any) => rec.id === ReportID);
  return (
    <Card className="p-0 mb-0 rounded-0 border-start-0 border-end-0">
      <CardHeader className="py-2 px-0">
        <div className="text-center">
          <Row>
            <Col>
              <h4 className="page_title pos-start mb-0">Reefer Containers</h4>
              <p className="card-title-desc pos-start">
                All readings since last report
              </p>
            </Col>
          </Row>
        </div>
      </CardHeader>
      <CardBody className="px-0 py-0 mt-2">
        <Row>
          <Col sm={2} className="p-2">
            <Label for="no_of_reefer_containers_loaded" className="mb-0">
              No. of reefer containers loaded
              <i
                className="bx bx-info-circle ml-2p"
                id="no_of_reefer_containers_loaded_msg"
              />
            </Label>
            <ToolTip
              target="no_of_reefer_containers_loaded_msg"
              message={`${TooltipMsg.Reefer_Container.filter(
                (item: any) =>
                  item.target === 'no_of_reefer_containers_loaded_msg',
              ).map((tool: any) => {
                return tool.message;
              })}`}
            />
            <Field
              type="text"
              className="form-control text-right"
              id="no_of_reefer_containers_loaded"
              name="reefer_container_details.loaded_container"
            />
            {errors?.reefer_container_details &&
              errors?.reefer_container_details?.loaded_container &&
              !alertBool &&
              env?.form_validation === true && (
                <ErrorTooltip
                  target="no_of_reefer_containers_loaded"
                  message={errors?.reefer_container_details?.loaded_container}
                  open={
                    errors?.reefer_container_details &&
                    errors?.reefer_container_details?.loaded_container
                      ? true
                      : false
                  }
                />
              )}
          </Col>
          <Col sm={2} className="p-2">
            <Label for="no_of_reefer_containers_discharged" className="mb-0">
              No. of reefer containers discharged
              <i
                className="bx bx-info-circle ml-2p"
                id="no_of_reefer_containers_discharged_msg"
              />
            </Label>
            <ToolTip
              target="no_of_reefer_containers_discharged_msg"
              message={`${TooltipMsg.Reefer_Container.filter(
                (item: any) =>
                  item.target === 'no_of_reefer_containers_discharged_msg',
              ).map((tool: any) => {
                return tool.message;
              })}`}
            />
            <Field
              type="text"
              className="form-control text-right"
              id="no_of_reefer_containers_discharged"
              name="reefer_container_details.discharged_container"
            />
            {errors?.reefer_container_details &&
              errors?.reefer_container_details?.discharged_container &&
              !alertBool &&
              env?.form_validation === true && (
                <ErrorTooltip
                  target="no_of_reefer_containers_discharged"
                  message={
                    errors?.reefer_container_details?.discharged_container
                  }
                  open={
                    errors?.reefer_container_details &&
                    errors?.reefer_container_details?.discharged_container
                      ? true
                      : false
                  }
                />
              )}
          </Col>
          <Col sm={2} className="p-2">
            <Label for="total_reefer_containers_onboard" className="mb-0">
              Total reefer containers onboard
              <i
                className="bx bx-info-circle ml-2p"
                id="total_reefer_containers_onboard_msg"
              />
            </Label>
            <ToolTip
              target="total_reefer_containers_onboard_msg"
              message={`${TooltipMsg.Reefer_Container.filter(
                (item: any) =>
                  item.target === 'total_reefer_containers_onboard_msg',
              ).map((tool: any) => {
                return tool.message;
              })}`}
            />
            <Field
              type="text"
              className="form-control text-right"
              name="reefer_container_details.total_onboard_container"
              id="total_reefer_containers_onboard"
            />
            {errors?.reefer_container_details &&
              errors?.reefer_container_details?.total_onboard_container &&
              !alertBool &&
              env?.form_validation === true && (
                <ErrorTooltip
                  target="total_reefer_containers_onboard"
                  message={
                    errors?.reefer_container_details?.total_onboard_container
                  }
                  open={
                    errors?.reefer_container_details &&
                    errors?.reefer_container_details?.total_onboard_container
                      ? true
                      : false
                  }
                />
              )}
          </Col>
          <Col sm={3} className="p-2">
            <Label for="reason_for_change" className="mb-0">
              Reason for change in number of reefer containers onboard
              <i
                className="bx bx-info-circle ml-2p"
                id="reason_for_change_msg"
              />
            </Label>
            <ToolTip
              target="reason_for_change_msg"
              message={`${TooltipMsg.Reefer_Container.filter(
                (item: any) => item.target === 'reason_for_change_msg',
              ).map((tool: any) => {
                return tool.message;
              })}`}
            />
            <Field name="reefer_container_details.reason_for_change">
              {() => (
                <textarea
                  className="form-control"
                  name="reefer_container_details.reason_for_change"
                  id="reason_for_change"
                  rows={
                    values?.reefer_container_details?.reason_for_change?.toString()
                      ?.length < 10
                      ? 1
                      : 2
                  }
                  onChange={(e: any) => handleChange(e)}
                  onBlur={(e: any) => handleChange(e)}
                  defaultValue={
                    values?.reefer_container_details?.reason_for_change
                  }
                />
              )}
            </Field>
            {errors?.reefer_container_details &&
              errors?.reefer_container_details?.reason_for_change &&
              !alertBool &&
              env?.form_validation === true && (
                <ErrorTooltip
                  target="reason_for_change"
                  message={errors?.reefer_container_details?.reason_for_change}
                  open={
                    errors?.reefer_container_details &&
                    errors?.reefer_container_details?.reason_for_change
                      ? true
                      : false
                  }
                />
              )}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col sm={2}>
            <Label className="mb-0" for="feet_20">
              20 ft units
              <i
                className="bx bx-info-circle ml-2p"
                id="referContainer_feet_20_msg"
              ></i>
            </Label>
            <ToolTip
              target="referContainer_feet_20_msg"
              message={`${TooltipMsg.Draft_and_Displacement.filter(
                (item: any) => item.target === 'feet_20_msg',
              ).map((tool: any) => {
                return tool.message;
              })}`}
            />
            <div className="input-group">
              <Field name="reefer_container_details.feet_20">
                {({ field }: FieldProps) => (
                  <Input
                    type="text"
                    className="form-control text-right"
                    id="referContainer_feet_20"
                    name={field.name}
                    onBlur={(e: any) => {
                      handleBlur(e);
                      handleChange(e);
                    }}
                    onChange={(e: any) => {
                      handleBlur(e);
                      handleChange(e);
                    }}
                    defaultValue={values.reefer_container_details.feet_20}
                  />
                )}
              </Field>
              <div className="input-group-text round_border">TEU</div>
            </div>
            {errors?.reefer_container_details &&
              errors?.reefer_container_details?.feet_20 &&
              !alertBool &&
              env?.form_validation === true && (
                <ErrorTooltip
                  target="referContainer_feet_20"
                  message={errors?.reefer_container_details?.feet_20}
                  open={
                    errors?.reefer_container_details &&
                    errors?.reefer_container_details?.feet_20
                      ? true
                      : false
                  }
                />
              )}
          </Col>
          <Col sm={2}>
            <Label className="mb-0" for="feet_40">
              40 ft units
              <i
                className="bx bx-info-circle ml-2p"
                id="referContainer_feet_40_msg"
              ></i>
            </Label>
            <ToolTip
              target="referContainer_feet_40_msg"
              message={`${TooltipMsg.Draft_and_Displacement.filter(
                (item: any) => item.target === 'feet_40_msg',
              ).map((tool: any) => {
                return tool.message;
              })}`}
            />
            <div className="input-group">
              <Field name="reefer_container_details.feet_40">
                {({ field }: FieldProps) => (
                  <Input
                    type="text"
                    className="form-control text-right"
                    id="referContainer_feet_40"
                    name={field.name}
                    onBlur={(e: any) => {
                      handleBlur(e);
                      handleChange(e);
                    }}
                    onChange={(e: any) => {
                      handleBlur(e);
                      handleChange(e);
                    }}
                    defaultValue={values.reefer_container_details.feet_40}
                  />
                )}
              </Field>
              <div className="input-group-text round_border">TEU</div>
            </div>
            {errors?.reefer_container_details &&
              errors?.reefer_container_details?.feet_40 &&
              !alertBool &&
              env?.form_validation === true && (
                <ErrorTooltip
                  target="referContainer_feet_40"
                  message={errors?.reefer_container_details?.feet_40}
                  open={
                    errors?.reefer_container_details &&
                    errors?.reefer_container_details?.feet_40
                      ? true
                      : false
                  }
                />
              )}
          </Col>
        </Row>
        {record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH && (
          <Row className="py-0">
            <ReportingFileUpload
              setFile={setBaplieFiles}
              file={baplieFiles}
              fileUploadStatus={
                values?.reefer_container_details?.baplie_file_status ===
                FileStatus.UPLOADED
              }
              fileURL={values?.reefer_container_details?.baplie_file_path}
              deleteURL={`/reefer_container_details/`}
              invalidateQuery={
                queryKeyes.vessel.ReeferContainerDetailsObject.key
              }
              deleteID={values?.reefer_container_details?.id}
              refreshKey={refreshKey}
              setRefreshKey={setRefreshKey}
              title={'Baplie File'}
              DeleteFileObj={deleteBaplieFileObj}
              index={0}
            />
          </Row>
        )}
        <Row className="py-0">
          <ReportingFileUpload
            setFile={setLogBook}
            file={logBook}
            fileUploadStatus={
              values?.reefer_container_details?.log_book_status ===
              FileStatus.UPLOADED
            }
            fileURL={values?.reefer_container_details?.log_book_path}
            deleteURL={`/reefer_container_details/`}
            invalidateQuery={queryKeyes.vessel.ReeferContainerDetailsObject.key}
            deleteID={values?.reefer_container_details?.id}
            refreshKey={refreshKey}
            setRefreshKey={setRefreshKey}
            title={'Log Book Upload'}
            DeleteFileObj={deleteLogBookObj}
            index={1}
          />
        </Row>
      </CardBody>
    </Card>
  );
};

export default ReeferContainerComponent;
