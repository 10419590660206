/**
 * A hook to update the document title dynamically.
 * Supports setting a custom favicon and Open Graph metadata.
 * @param {string} title - The new document title.
 * @param {string} [defaultTitle="My App"] - The fallback title if none is provided.
 * @param {string} [faviconUrl] - URL of the favicon to set.
 * @param {string} [ogImageUrl] - URL of the Open Graph image for social previews.
 */
import { useEffect } from "react";

export const useDocumentTitle = (
    title: string | undefined | null = "ecoSAIL",
    defaultTitle: string = "ecoSAIL",
    faviconUrl?: string,
    ogImageUrl?: string
): void => {
    useEffect(() => {
        // Ensure title is a valid non-empty string; otherwise, fallback to defaultTitle
        document.title = title && typeof title === "string" && title.trim() !== "" ? title : defaultTitle;

        if (faviconUrl) {
            let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.head.appendChild(link);
            }
            link.href = faviconUrl;
        }

        if (ogImageUrl) {
            let meta = document.querySelector("meta[property='og:image']") as HTMLMetaElement;
            if (!meta) {
                meta = document.createElement("meta");
                meta.setAttribute("property", "og:image");
                document.head.appendChild(meta);
            }
            meta.content = ogImageUrl;
        }

        // Reset title on unmount
        return () => {
            document.title = defaultTitle;
        };
    }, [title, defaultTitle, faviconUrl, ogImageUrl]);
};
