import VoyageInformation from "./VoyageInformation/VoyageInformation";
import VesselReportingInfo from "./VesselReportingInfo/VesselReportingInfo";
import DeckOfficerWizard from "./ReportingWizard/DeckOfficerReport/DeckOfficerReporting";
import EngineerWizard from "./ReportingWizard/EngineerReport/EngineerReporting";
import VesselMachinerySettings from "./VesselMaster/VesselConfiguration/VesselConfiguration";
import VesselAdditionalInformation from "./VesselMaster/AdditionalInformation/AdditionalInformation";
import VesselPerformance from 'VesselPerformance/VesselPerformance';
import SingleReportView from "./ReportingWizard/SingleReportView";
import { Roles } from "./shared/constants";
import React, { Suspense, lazy } from "react";
import DashboardFleet from "Dashboard/DashboardFleet";
import Loading from "Components/Loading";
import STSAdjustment from "Adjustments/STSAdjustment/STSAdjustment";
import FCeboAdjustment from "Adjustments/FCeboAdjustment/FCeboAdjustment";
import VoyageAdjustment from "Adjustments/VoyageAdjustment/VoyageAdjustment";
import N2Adjustment from "Adjustments/N2Adjustment/N2Adjustment";
import { getAuthData } from "utils/auth/authUtils";
import SyncReport from "SyncReport/SyncReport";
import Notification from "Notification/Notification";
import FuelEUAnalysis from "FuelEU/FuelEUAnalysis";
import env from "environment_system/env_system";
import Pooling from "Pooling/Pooling";
import PoolingVessels from "Pooling/PoolingVessels";
import PoolingList from "Pooling/PoolingList";
import OrganizationConfiguration from "OrganizationConfiguration/OrganizationConfiguration";
import Users from "Users/Users";
import ProfileSetting from "Header/TopbarDropdown/ProfileSetting";
import CreateVesselReport from "VesselReportingInfo/CreateVesselReport";
import Help from "Header/TopbarDropdown/Help/Help";
interface RouteProps {
  path: string;
  component?: any;
  exact?: boolean;
  allowedRole?: string[]
}

const CIIAssessment = lazy(() => import('CIIAssessment/CIIAssessment'));
const CIISimulator = lazy(() => import('Simulator/CIIProjection/CIISimulator'));
const EuMrvEts = lazy(() => import('./EuMrvEts/EuMrvEts'));
const VoyageEstimator = lazy(() => import('Simulator/VoyageEstimator/VoyageEstimator'));
const Fleets = lazy(() => import('Fleets/Fleets'));
const GenericIndex = lazy(() => import('./GenericForms/Generic.index'));
const ImoDcs = lazy(() => import('ImoDcs.tsx/ImoDcs'));
const CustomReportGeneration = lazy(() => import(`ReportGeneration/CustomReport`));
const ReportGenerationFilters = lazy(() => import('ReportGeneration/ReportGenerationFilters'));
const UkMrv = lazy(() => import('UkMrv/UkMrv'));
const VesselMaster = lazy(() => import('./VesselMaster/VesselMaster'));
const Dashboard = lazy(() => import('./Dashboard/Dashboard'));

const masters = [
  'region_master',
  'hull_type_master',
  'direction_master',
  'beaufort_scale_master',
  'contact_type_master',
  'port_master',
  'vessel_owner_master',
  'country_master',
  'vessel_load_condition_master',
  'wind_direction_master',
  'unit_group_master',
  'unit_master',
  'vessel_type_master',
  'vessel_sub_type_master',
  'fuel_type_master',
  'fuel_master',
  'fuel_sub_type_master',
  'reporting_operation_mode_master',
  'reporting_events_master',
  'reporting_type_master',
  'current_direction_master',
  'machinery_list_master',
  'vessel_machinery_list',
  'sensor_list_master',
  'vessel_sensors_list',
  'lube_oil_list_master',
  'vessel_lube_oil_list',
  'reporting_events_list_master',
  'vessel_reporting_events_list',
  'douglas_sea_scale_master',
  'vessel_owner_master',
  'strait_canal_transit_list_master',
  'cii_adjustment_events_master',
  'vessel_owner_master',
]
const userRoutes: Array<RouteProps> = [
  //Generic Master
  ...masters.map((master: any) => {
    return ({
      path: `/masters/${master}`,
      component:
        <Suspense
          fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div >}>
          <GenericIndex />
        </Suspense >,
      allowedRole: [Roles.ES_ADMIN]
    })
  }),
  {
    path: "/reporting/voyage_reporting",
    component: <VoyageInformation />,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN, Roles.CHIEFENGINEER]
  },
  {
    path: "/reporting/vessel_reporting",
    exact: true,
    component: <VesselReportingInfo />,
    allowedRole: [Roles.MASTER, Roles.CHIEFENGINEER, Roles.ES_ADMIN]
  },
  {
    path: "/reporting/deck_officer_reporting",
    exact: true,
    component: <DeckOfficerWizard />,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN]
  },
  {
    path: "/reporting/engineer_reporting",
    exact: true,
    component: <EngineerWizard />,
    allowedRole: [Roles.CHIEFENGINEER, Roles.ES_ADMIN]
  },
  {
    path: "/masters/vessel_reporting",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <VesselMaster
          operation='vessel_reporting'
        />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN, Roles.MASTER, Roles.MANAGER, Roles.CHIEFENGINEER]
  },
  {
    path: '/dashboard',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <Dashboard />
      </Suspense>,
    allowedRole: [Roles.MANAGER, Roles.MASTER, Roles.CHIEFENGINEER, Roles.ES_ADMIN]
  },
  {
    path: "/dashboard_fleet",
    component: <DashboardFleet />,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/fleet_assessment",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <Fleets />
      </Suspense>,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/cii_assessment",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <CIIAssessment />
      </Suspense>,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/imo_dcs",
    component: <Suspense
      fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
      <ImoDcs />
    </Suspense>,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/eu_mrv_ets",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <EuMrvEts />
      </Suspense>,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/uk_mrv",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <UkMrv />
      </Suspense>,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/vessel_performance",
    component: <VesselPerformance />,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/vessel_configuration",
    component: <VesselMachinerySettings />,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: "/vessel_additional_information",
    component: <VesselAdditionalInformation />,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: "/contacts-profile",
    component: <ProfileSetting />,
    allowedRole: [Roles.ES_ADMIN, Roles.MANAGER, Roles.CHIEFENGINEER, Roles.MASTER]
  },
  {
    path: '/testing',
    component: <SingleReportView />,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/cii_simulator',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <CIISimulator />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/voyage_estimator',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <VoyageEstimator />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/custom_report_generation',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <CustomReportGeneration />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/custom_company_report',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <ReportGenerationFilters />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/voyage_adjustment',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <VoyageAdjustment />
      </Suspense>,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN, Roles.MANAGER]
  },
  {
    path: '/sts_adjustment',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <STSAdjustment />
      </Suspense>,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN, Roles.MANAGER]
  },
  {
    path: '/FC(elec+boiler+other)_adjustment',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <FCeboAdjustment />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/n2_correction',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <N2Adjustment />
      </Suspense>,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN, Roles.MANAGER]
  },
  {
    path: '/sync_report',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <SyncReport />
      </Suspense>,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN, Roles.MANAGER]
  },
  {
    path: '/notifications',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <Notification />
      </Suspense>,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN, Roles.MANAGER, Roles.CHIEFENGINEER]
  },
  {
    path: '/fuel_eu',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <FuelEUAnalysis />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN, Roles.MANAGER]
  },
  {
    path: '/notifications/:id',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <Notification />
      </Suspense>,
    allowedRole: [Roles.MASTER, Roles.ES_ADMIN, Roles.MANAGER, Roles.CHIEFENGINEER]
  },
  {
    path: '/pooling',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <Pooling />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/pooling_vessels',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <PoolingVessels />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/pooling_list',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <PoolingList />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  {
    path: '/onboarding',
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <VesselMaster
          operation='onboarding'
        />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN, Roles.MANAGER, Roles.MASTER]
  },
  {
    path: "/org_config", exact: true, component: <Suspense
      fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
      <OrganizationConfiguration />
    </Suspense>, allowedRole: [Roles.ES_ADMIN, Roles.MANAGER]
  },
  {
    path: "/users",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <Users />
      </Suspense>,
    allowedRole: [Roles.MANAGER, Roles.ES_ADMIN]
  },
  {
    path: "/create_report_testing",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <CreateVesselReport />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN]
  },
  { path: "/", exact: true, component: <Dashboard /> },
  {
    path: "/help",
    component:
      <Suspense
        fallback={<div className="lazy-load-component"><Loading message="Please wait..." /></div>}>
        <Help />
      </Suspense>,
    allowedRole: [Roles.ES_ADMIN, Roles.MANAGER, Roles.CHIEFENGINEER, Roles.MASTER]
  }
];

/**
 * Filter out roles which are applicable to the role based on session
 */
const roles = getAuthData('roles') ?? [];
const userAllowedRoutes = userRoutes.filter((route: any) =>
  route?.allowedRole?.some((item: any) =>
    env.desktop_app === true
      ? item === roles?.role_constant      // Offline logic
      : item === roles[0]?.role_constant   // Online logic
  )
) ?? [];
export { userAllowedRoutes };