export const AlertMessages = {
  VesselReporting: {
    reporting_time_utc: "More than 24 hours have passed since the last report. Please confirm the timing.",
    distance_travelled_position_coordindates: `The value entered seems to be less than the actual geometric distance between the two positions`,
    distance_travelled_speed_time: `Based on the vessel's configured speed and the time elapsed since the last report, the distance travelled seems impractical.
         Please confirm the distance travelled with a valid explanation.`,
    load_condition:
      'Kindly confirm the change in condition, as no cargo operation has been reported since the last report.',
  },
  CpWarranties: {
    ordered_fuel_oil_consumption_gt_3:
      'The consumption rate mentioned is higher than the average range of consumption.',
    ordered_fuel_oil_consumption_lt_3:
      'The consumption rate mentioned is less than the average range of consumption.',
  },
  CargoDetails: {
    amount_of_cargo_discharged: "Quantity of cargo discharged is more than the cargo onboard qty reported in the last report.",
    total_amount_of_cargo_onboard: "Quantity of cargo is not in line with the previous reports."
  },
  EnergyParameter: {
    expected_power: "Value of energy developed deviates from the average value for given speed."
  },
  WeatherData: {
    high_weather_data_alert_msg: "The value indicates abnormal weather. Please reconfirm the value.",
    normal_wind_speed_alert_msg: "The value indicates rough weather. Please re confirm the value.",
    normal_weather_data_alert_msg: " The value indicates abnormal weather. Please reconfirm the value.",
    weather_data_barometric_pressure_alert_msg: "Please recheck the value as it seems out of practicable range."
  },
  FuelConsumption: {
    five_per_more: "The provided value is slightly above the average consumption rate. Kindly review and confirm",
    ten_per_more: "The provided value exceeds the average rate of consumption. Kindly review and confirm"
  }
};
