import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { Field, FieldArray, Formik } from 'formik';
import env from 'environment_system/env_system';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Label, Row } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import { loadEconFmCounterMaster, loadEconFmCounterObject } from 'VesselMaster/vesselMaster.hooks';
import { handleServerResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import { CountersAndFMPrecedence, EventPrecedence } from 'shared/constants';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from 'yup';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ErrorTooltip from 'Components/ErrorTooltip';

interface CountersFlowmetersComponentType {
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    toggleAddTab: (value: number) => void,
    toggleDynamicTabs: (value: number) => void,
    previousTabIndex: number,
    record: any,
    setErrorMessage: any
}

const CountersFlowmetersComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    toggleAddTab,
    toggleDynamicTabs,
    previousTabIndex,
    record,
    setErrorMessage
}: CountersFlowmetersComponentType) => {
    /** State variables start */
    const [refreshKey, setRefreshKey] = useState(0);
    /** State variables end */

    /** useQueries */
    /** Load vessel's flowmeters and counters */
    const {
        data: fmCounters,
        isLoading: fmCountersLoading,
        isError: fmCountersError,
    } = useQuery(
        [queryKeyes.vessel.EconFMCounterMaster.key, VesselID],
        async () => {
            return await loadEconFmCounterMaster(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Load vessel's flowmeters and counters report obbject used for edit */
    const {
        data: fmCountersObject,
        isLoading: fmCountersObjectLoading,
    } = useQuery(
        [queryKeyes.vessel.EconFMCounterObject.key, VesselID, ReportID],
        async () => {
            return await loadEconFmCounterObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** useQueries end */

    /** Assign values to CountersFlowmeters's initial object */
    const getInitialValues = () => {
        let arr: any[] = [];
        if (fmCountersObject && fmCountersObject?.length > 0) {
            return fmCountersObject;
        } else {
            if (fmCounters && fmCounters?.length > 0) {
                fmCounters?.forEach((fm: any) => {
                    arr.push({
                        counter: fm.id,
                        fm_counter_name: fm.fm_counter_name,
                        counter_value: 0,
                        unit: fm.unit,
                        precedence_id: fm.precedence_id,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID
                    })
                })
            }
            return arr;
        }
    }

    /** CountersFlowmetersFormik object */
    const CountersFlowmetersFormik = {
        initialValues: {
            fmCounters: getInitialValues()
        },
        validationSchema: Yup.object().shape({
            fmCounters: Yup.array().of(
                Yup.object({
                    counter_value: Yup.string().matches(/^\d{0,10}(\.\d{1,4})?$/, `${commonValidationMessages.before10after4}`).nullable(),
                })
            )
        })
    }

    /** POST request for Counters & Flowmeters */
    const CountersFlowmetersSubmit = async (values: any, actions: any) => {
        const responseArray: any[] = [];
        if (fmCountersObject && fmCountersObject?.length > 0) {
            values?.fmCounters?.forEach((fmcounter: any) => {
                responseArray.push(apiGlobal.put(`${queryKeyes.vessel.EconFMCounter.url()}${fmcounter?.id}/`, fmcounter))
            });
        } else {
            responseArray.push(apiGlobal.post(queryKeyes.vessel.EconFMCounter.url(), values?.fmCounters));
        }
        /** handle server response */
        await handleServerResponse(responseArray).then(async (res) => {
            if (res === true) {
                await queryClient.invalidateQueries(queryKeyes.vessel.EconFMCounterObject.key);
                if (env?.form_validation === true) {
                    setErrorMessage(null)
                    toggleDynamicTabs(previousTabIndex + 1);
                }
                setRefreshKey(refreshKey + 1);
                actions.setSubmitting(false);
            } else {
                setErrorMessage(res)
            }
        });
    }

    return (
        <React.Fragment>
            {(fmCountersLoading || fmCountersObjectLoading) && <Loading message='Loading required data!' />}
            {fmCountersError && <ErrorComponent message='Error loading required data!' />}
            {!(fmCountersLoading || fmCountersObjectLoading) && !fmCountersError &&
                <Formik
                    initialValues={CountersFlowmetersFormik.initialValues}
                    onSubmit={(values: any, actions: any) => {
                        if (env?.form_validation === false) {
                            setErrorMessage(null)
                            toggleDynamicTabs(previousTabIndex + 1);
                        }
                        actions.setSubmitting(true);
                        CountersFlowmetersSubmit(values, actions);
                    }}
                    validationSchema={env?.form_validation === true ? CountersFlowmetersFormik.validationSchema : null}
                    key={refreshKey}
                >
                    {(props: any) => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <Card className='p-0 mb-0 border-0'>
                                <CardHeader className='p-2 border-bottom-0'>
                                    <h5 className='mb-0'>Counters & Flowmeters</h5>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    {/* Revolution counters */}
                                    <Card>
                                        <CardHeader className='p-2'>
                                            <h5 className='mb-0'>Revolution counters</h5>
                                        </CardHeader>
                                        <CardBody className='p-2'>
                                            {props?.values && props?.values?.fmCounters?.filter((value: any) => value?.precedence_id === CountersAndFMPrecedence.REVOLUTION_COUNRER).length > 0 ?
                                                <Row>
                                                    <FieldArray name='fmCounters'>
                                                        {() => (
                                                            <React.Fragment>
                                                                {props?.values?.fmCounters
                                                                    .filter((value: any) => value?.precedence_id === CountersAndFMPrecedence.REVOLUTION_COUNRER)
                                                                    .map((value: any, index: number) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <Col sm={3} className='mb-2'>
                                                                                    <Label className="mb-0" htmlFor={`counter_${props?.values?.fmCounters.indexOf(value)}`}>{value?.fm_counter_name}</Label>
                                                                                    <div className='input-group'>
                                                                                        <Field name={`fmCounters.${props?.values?.fmCounters.indexOf(value)}.counter_value`}>
                                                                                            {() => (
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id={`counter_${props?.values?.fmCounters.indexOf(value)}`}
                                                                                                    name={`fmCounters.${props?.values?.fmCounters.indexOf(value)}.counter_value`}
                                                                                                    className="form-cntrol max-width-9 text-right"
                                                                                                    onChange={(e: any) => props?.handleChange(e)}
                                                                                                    onBlur={(e: any) => {
                                                                                                        props?.handleBlur(e);
                                                                                                        props?.handleChange(e);
                                                                                                    }}
                                                                                                    defaultValue={value?.counter_value}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                        <div className="input-group-text">{value?.unit}</div>
                                                                                    </div>
                                                                                    {env?.form_validation === true &&
                                                                                        props?.errors && props?.errors?.fmCounters && props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)] &&
                                                                                        props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                        props?.touched && props?.touched?.fmCounters && props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)] &&
                                                                                        props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                        <ErrorTooltip
                                                                                            target={`counter_${props?.values?.fmCounters.indexOf(value)}`}
                                                                                            message={props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value}
                                                                                            open={props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                                props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value}
                                                                                        />
                                                                                    }
                                                                                </Col>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                    )}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Row> :
                                                <Row>
                                                    <Col className='text-center'>
                                                        <Label className='mb-3'>No Revolution counters are configured for this vessel</Label>
                                                    </Col>
                                                </Row>
                                            }
                                        </CardBody>
                                    </Card>
                                    {/* Energy meters */}
                                    <Card>
                                        <CardHeader className='p-2'>
                                            <h5 className='mb-0'>Energy meter counter</h5>
                                        </CardHeader>
                                        <CardBody className='p-2'>
                                            {props?.values && props?.values?.fmCounters?.filter((value: any) => value?.precedence_id === CountersAndFMPrecedence.ENERGY_METER).length > 0 ?
                                                <Row>
                                                    <FieldArray name='fmCounters'>
                                                        {() => (
                                                            <React.Fragment>
                                                                {props?.values?.fmCounters
                                                                    .filter((value: any) => value?.precedence_id === CountersAndFMPrecedence.ENERGY_METER)
                                                                    .map((value: any, index: number) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <Col sm={3} className='mb-2'>
                                                                                    <Label className="mb-0" htmlFor={`counter_${props?.values?.fmCounters.indexOf(value)}`}>{value?.fm_counter_name}</Label>
                                                                                    <div className='input-group'>
                                                                                        <Field name={`fmCounters.${props?.values?.fmCounters.indexOf(value)}.counter_value`}>
                                                                                            {() => (
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id={`counter_${props?.values?.fmCounters.indexOf(value)}`}
                                                                                                    name={`fmCounters.${props?.values?.fmCounters.indexOf(value)}.counter_value`}
                                                                                                    className="form-cntrol max-width-9 text-right"
                                                                                                    onChange={(e: any) => props?.handleChange(e)}
                                                                                                    onBlur={(e: any) => {
                                                                                                        props?.handleBlur(e);
                                                                                                        props?.handleChange(e);
                                                                                                    }}
                                                                                                    defaultValue={value?.counter_value}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                        <div className="input-group-text">{value?.unit}</div>
                                                                                    </div>
                                                                                    {env?.form_validation === true &&
                                                                                        props?.errors && props?.errors?.fmCounters && props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)] &&
                                                                                        props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                        props?.touched && props?.touched?.fmCounters && props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)] &&
                                                                                        props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                        <ErrorTooltip
                                                                                            target={`counter_${props?.values?.fmCounters.indexOf(value)}`}
                                                                                            message={props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value}
                                                                                            open={props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                                props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value}
                                                                                        />
                                                                                    }
                                                                                </Col>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                    )}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Row> :
                                                <Row>
                                                    <Col className='text-center'>
                                                        <Label className='mb-3'>No Energy meters are configured for this vessel</Label>
                                                    </Col>
                                                </Row>
                                            }
                                        </CardBody>
                                    </Card>
                                    {/* Flowmeters */}
                                    <Card className='mb-0'>
                                        <CardHeader className='p-2'>
                                            <h5 className='mb-0'>Flowmeters</h5>
                                        </CardHeader>
                                        <CardBody className='p-2'>
                                            {props?.values && props?.values?.fmCounters?.filter((value: any) => value?.precedence_id === CountersAndFMPrecedence.FLOW_METERS).length > 0 ?
                                                <Row>
                                                    <FieldArray name='fmCounters'>
                                                        {() => (
                                                            <React.Fragment>
                                                                {props?.values?.fmCounters
                                                                    .filter((value: any) => value?.precedence_id === CountersAndFMPrecedence.FLOW_METERS)
                                                                    .map((value: any, index: number) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                <Col sm={3} className='mb-2'>
                                                                                    <Label className="mb-0" htmlFor={`counter_${props?.values?.fmCounters.indexOf(value)}`}>{value?.fm_counter_name}</Label>
                                                                                    <div className='input-group'>
                                                                                        <Field name={`fmCounters.${props?.values?.fmCounters.indexOf(value)}.counter_value`}>
                                                                                            {() => (
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    id={`counter_${props?.values?.fmCounters.indexOf(value)}`}
                                                                                                    name={`fmCounters.${props?.values?.fmCounters.indexOf(value)}.counter_value`}
                                                                                                    className="form-cntrol max-width-9 text-right"
                                                                                                    onChange={(e: any) => props?.handleChange(e)}
                                                                                                    onBlur={(e: any) => {
                                                                                                        props?.handleBlur(e);
                                                                                                        props?.handleChange(e);
                                                                                                    }}
                                                                                                    defaultValue={value?.counter_value}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                        <div className="input-group-text">{value?.unit}</div>
                                                                                    </div>
                                                                                    {env?.form_validation === true &&
                                                                                        props?.errors && props?.errors?.fmCounters && props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)] &&
                                                                                        props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                        props?.touched && props?.touched?.fmCounters && props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)] &&
                                                                                        props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                        <ErrorTooltip
                                                                                            target={`counter_${props?.values?.fmCounters.indexOf(value)}`}
                                                                                            message={props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value}
                                                                                            open={props?.errors?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value &&
                                                                                                props?.touched?.fmCounters[props?.values?.fmCounters.indexOf(value)]?.counter_value}
                                                                                        />
                                                                                    }
                                                                                </Col>
                                                                            </React.Fragment>
                                                                        )
                                                                    }
                                                                    )}
                                                            </React.Fragment>
                                                        )}
                                                    </FieldArray>
                                                </Row> :
                                                <Row>
                                                    <Col className='text-center'>
                                                        <Label className='mb-3'>No Flowmeters are configured for this vessel</Label>
                                                    </Col>
                                                </Row>
                                            }
                                        </CardBody>
                                    </Card>
                                </CardBody>
                                <CardFooter className='p-2 py-3'>
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn_size_cstm pos-end"
                                            >
                                                Next <i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                props.setErrors({})
                                                props.errors = {};
                                                setErrorMessage(null)
                                                if (record?.event_precedence_id === EventPrecedence.NOON) {
                                                    toggleAddTab(2);
                                                } else {
                                                    toggleDynamicTabs(previousTabIndex - 1);
                                                }
                                            }}>
                                                <i className="bx bx-chevron-left me-1" />Previous
                                            </Button>
                                        </div>
                                    </Row>
                                </CardFooter>
                            </Card>
                            <FormValuesDebug values={[props.values, props.errors, props?.touched]} />
                        </Form>
                    )}
                </Formik>
            }
        </React.Fragment>
    )
}

export default CountersFlowmetersComponent