import ErrorTooltip from 'Components/ErrorTooltip';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { Field, useFormikContext } from 'formik';
import React from 'react'
import { Card, CardHeader, Row, Col, CardBody, Label, Input } from 'reactstrap';
import env from "environment_system/env_system";

interface BallastOperationsProps {
    alertBool: boolean
}

const BallastOperations = ({ alertBool }: BallastOperationsProps) => {
    const { values, errors, touched, handleChange, handleBlur }:
        { values: any, errors: any, touched: any; handleChange: any, handleBlur: any } = useFormikContext<any>();
    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="px-0 py-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Ballast Operations
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <CardBody className="ps-2 pr-2 pt-0 pb-0 mt-2">
                <Row>
                    <Col sm={2} className="px-0">
                        <Label className="asteric mb-0" for="loaded">
                            Loaded
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="loaded_msg"
                            ></i>
                        </Label>
                        <ToolTip
                            target="loaded_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "loaded_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group mb-3">
                            <Field name="ballast_details.loaded">
                                {() => (
                                    <Input
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id="loaded"
                                        name="ballast_details.loaded"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        defaultValue={values?.ballast_details?.loaded}
                                    />
                                )}
                            </Field>
                            <div className="input-group-text round_border">
                                mt
                            </div>
                            {errors?.ballast_details &&
                                touched?.ballast_details?.loaded &&
                                errors?.ballast_details?.loaded &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="loaded"
                                        message={errors?.ballast_details?.loaded}
                                        open={
                                            errors?.ballast_details &&
                                                errors?.ballast_details?.loaded
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </div>
                    </Col>
                    <Col sm={2} className="px-0">
                        <Label className="asteric mb-0" for="discharged">
                            Discharged
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="discharged_msg"
                            ></i>
                        </Label>
                        <ToolTip
                            target="discharged_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "discharged_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group mb-3">
                            <Field name="ballast_details.discharged">
                                {() => (
                                    <Input
                                        type="text"
                                        className="form-control text-right max-width-7 border-end-0Fwd draft"
                                        id="discharged"
                                        name="ballast_details.discharged"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        defaultValue={
                                            values?.ballast_details?.discharged
                                        }
                                    />
                                )}
                            </Field>
                            <div className="input-group-text round_border">
                                mt
                            </div>
                            {errors?.ballast_details &&
                                touched?.ballast_details?.discharged &&
                                errors?.ballast_details?.discharged &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="discharged"
                                        message={errors?.ballast_details?.discharged}
                                        open={
                                            errors?.ballast_details &&
                                                errors?.ballast_details?.discharged
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default BallastOperations