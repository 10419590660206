import ToolTip from 'Components/ToolTip'
import { TooltipMsg } from 'Components/ToolTipMessage'
import env from 'environment_system/env_system'
import { useFormikContext } from 'formik'
import React from 'react'
import { Row, Col } from 'reactstrap'
import { FuelTypes, AddInfoConstant } from 'shared/constants'
interface LNGCargoROBComponentType {
  CargoDetailsObject: any;
  AdditionalDetailsObject: any;
  count: number;
  loadFuelConsumptionSum: any;
}

const LNGCargoROB = ({
  CargoDetailsObject,
  AdditionalDetailsObject,
  count,
  loadFuelConsumptionSum
}: LNGCargoROBComponentType) => {
  const { values }: { values: any } = useFormikContext();
  return (
    <Row className='mt-3 mb-3 px-2'>
      <Col sm={4}>
        <span className='mb-0'>LNG Cargo ROB before consumption
          <i className='bx bx-info-circle ml-2p' id='initial_rob_msg' /> :
        </span>
        <ToolTip target='initial_rob_msg'
          message={`${TooltipMsg.FuelConsumption
            .filter((item: any) => item.target === 'initial_rob_msg')
            .map((tool: any) => { return tool.message })}`}
        />
        <strong>&nbsp;{(CargoDetailsObject && CargoDetailsObject?.length > 0) ?
          CargoDetailsObject[0]?.total_amount_of_cargo_onboard :
          (parseFloat(values?.lngCargoROB?.cargo_rob))} mt
        </strong>
      </Col>
      <Col sm={5}>
        <span className='mb-0'>LNG Cargo ROB after consumption
          <i className='bx bx-info-circle ml-2p' id='cargo_rob_msg' /> :
        </span>
        <ToolTip target='cargo_rob_msg'
          message={`${TooltipMsg.FuelConsumption
            .filter((item: any) => item.target === 'cargo_rob_msg')
            .map((tool: any) => { return tool.message })}`}
        />
        <strong key={count}>&nbsp;
          {(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]) === null ||
            Number.isNaN(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]))) ? values?.lngCargoROB?.cargo_rob :
            ((((CargoDetailsObject && CargoDetailsObject?.length > 0) ?
              CargoDetailsObject[0]?.total_amount_of_cargo_onboard :
              parseFloat(values?.lngCargoROB?.cargo_rob))
              - parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])))?.toFixed(4)
          } mt
        </strong>
      </Col>
      {env?.additional_info_engg === AddInfoConstant.PROACTIVE_ENGG &&
        <Col sm={3}>
          <span className='mb-0'>LNG FOE :</span>
          <strong>&nbsp;
            {Number.isNaN((parseFloat(AdditionalDetailsObject[0]?.foe_factor) * ((parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]) === null ||
              Number.isNaN(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]))) ? values?.lngCargoROB?.cargo_rob :
              ((parseFloat(values?.lngCargoROB?.cargo_rob) - parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])))))) === true ? AdditionalDetailsObject[0]?.foe_factor ?? 0 :
              (parseFloat(AdditionalDetailsObject[0]?.foe_factor) * ((parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]) === null ||
                Number.isNaN(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]))) ? values?.lngCargoROB?.cargo_rob :
                ((parseFloat(values?.lngCargoROB?.cargo_rob) - parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])))))?.toFixed(4)} mt</strong>
        </Col>
      }
    </Row>
  )
}

export default LNGCargoROB
