import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Button,
    Form,
    CardHeader,
    CardBody,
    Card,
    CardFooter,
} from "reactstrap";
import { Formik } from "formik";
import { getInputs } from "../../EngineerReport.model";
import {
    FileStatus,
    FuelTypes,
    ReportingEvents,
    ReportingOpConstant,
    ReportingTypes,
    // VesselMachineryConstant,
    VesselTypeConstant,
} from "../../../../shared/constants";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import {
    loadSpeedConsumptionObject,
    lastETADObject,
    lastETADPort,
    loadBatchConsumptionObject,
    loadCargoDetails,
    loadFuelBatches,
    loadFuelConsumptionObject,
    loadFuelSettings,
    loadFuelTypeROB,
    loadLNGCargoPreviousROB,
    loadLNGCargoROBObject,
    loadMachineryFuelGroup,
    loadPreviousEOSPPort,
    loadPreviousFuelROB,
    loadProactiveAddDetailsObject,
    loadScrubingUnitObject,
    loadShoreSupplyObject,
    loadSteamDumpDetailsObject,
    loadVesselMachineries,
    loadVesselOtherSettingsOperation,
    vesselFuel,
} from "VesselMaster/vesselMaster.hooks";
import apiGlobal, { apiMedia } from "global/api.global";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { queryClient } from "../../../../react-query/queryClient";
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { handleServerResponse } from 'GenericForms/Helper';
import env from 'environment_system/env_system';
import { useSelector } from "react-redux";
import { RootState } from "index";
import { calculateTotalDOeq } from "Simulator/CIIProjection/CommonFunctions";
import AlertPopup from "Alerts/AlertPopup";
import { useDocumentTitle } from "Components/useDocument.hooks";
import SteamDumpDetailsComponent from "./SteamDumpDetailsComponent";
import ShoreSupplyComponent from "./ShoreSupplyComponent";
import FuelConsumption from "./FuelConsumption";
import LNGCargoROB from "./LNGCargoROBComponent";
import FuelConsumptionBatchComponent from "./FuelConsumptionBatchComponent";
interface FuelConsumptionComponentType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    toggleTab: any;
    activeTab: any;
    vessel: any;
    lastRecord: any;
    setErrorMessage: any,
}

interface DeleteFileObject {
    edn_file_upload: null;
    file_path: null;
}

const FuelConsumptionComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    toggleTab,
    activeTab,
    vessel,
    lastRecord,
    setErrorMessage,
}: FuelConsumptionComponentType) => {
    /** State for the form */
    const { Reports } = useSelector((state: RootState) => state.Reporting);
    let [fuelConsumptionSum] = useState<any>({});
    let [fuelBatchConsumptionSum] = useState<any>({});
    let [fuelBatchAdjustmentSum] = useState<any>({});
    const [forob] = useState<Array<any>>([]);
    const [batchROB] = useState<Array<any>>([]);
    let { Fuel_Consumption_inputs } = getInputs("fuel_consumption");
    const [measurementMethod, setMeasurementMethod] = useState<string>("Flow meter");
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [count, setCount] = useState(0);
    const [fuelBatchIndex, setFuelBatchIndex] = useState<any>(null);
    const [finalBatchConsumptionArray, setFinalBatchConsumptionArray] = useState<Array<any>>([]);
    const [totalLNGBunkerROB, setTotalLNGBunkerROB] = useState(0);
    const [EDNFiles, setEDNFiles] = useState<any>([{}]);
    const record = Reports.find((rec: any) => rec.id === ReportID);
    const [refreshKey, setRefreshKey] = useState<number>(0)
    const [bunker, setBunker] = useState<any>({});
    const [shoreSupplyPort, setShoreSupplyPort] = useState<string>();
    const [shoreSupplyPortID, setShoreSupplyPortID] = useState<string>();
    const [alertBool, setAlertBool] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>(null);
    const [alertColourType, setAlertColourType] = useState<any>(null);
    const [alertType, setAlertType] = useState<string>(null);
    useDocumentTitle("Fuel Consumption & ROB Report  - ecoSAIL");
    const DeleteFileObj: DeleteFileObject = {
        edn_file_upload: null,
        file_path: null,
    }
    /** End State */

    /** Queries */
    /** Machinary fuel Group */
    const {
        data: machineryFuelGroup,
        isLoading: machineryFuelGroupLoading,
        isError: machineryFuelGroupError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryFuelGroupByVesselId.key, VesselID],
        async () => {
            return await loadMachineryFuelGroup(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Load Vessel Machinary */
    const {
        data: vesselMachineries,
        isLoading: vesselMachineriesLoading,
        isError: vesselMachineriesError,
    } = useQuery(
        [queryKeyes.vessel.MachinaryFuelGroupByVesselId.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Fetch fuel Batch Bunkering */
    const {
        data: fuelBatches,
        isLoading: fuelBatchesLoading,
        isError: fuelBatchesError,
    } = useQuery(
        [queryKeyes.vessel.BunkeringByVessel.key, VesselID],
        async () => {
            return await loadFuelBatches(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Fetch Vessel distinct fuel  */
    const {
        data: fuelTypes,
        isLoading: fuelTypesLoading,
        isError: fuelTypesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Fetch Vessel fuel settings */
    const {
        data: fuelSettings,
        isLoading: fuelSettingsLoading,
        isError: fuelSettingsError,
    } = useQuery(
        [queryKeyes.vessel.FuelSettings.key, VesselID],
        async () => {
            return await loadFuelSettings(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Previous report's fuel ROB */
    const {
        data: PreviousFuelROB,
        isLoading: previousFuelROBLoading,
        isError: previousFuelROBError,
    } = useQuery(
        [queryKeyes.vessel.PreviousFuelROB.key, VesselID, ReportID],
        async () => {
            return await loadPreviousFuelROB(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Fuel Consumption Object used for edit */
    const {
        data: FuelConsumptionObject,
        isLoading: FuelConsumptionObjectLoading,
        isError: FuelConsumptionObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.FuelConsumptionObject.key, VesselID, ReportID],
        async () => {
            return await loadFuelConsumptionObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Batch Consumption Object used for edit */
    const {
        data: BatchConsumptionObject,
        isLoading: BatchConsumptionObjectLoading,
        isError: BatchConsumptionObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.BatchConsumptionObject.key, VesselID, ReportID],
        async () => {
            return await loadBatchConsumptionObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Fuel ROB Object used for edit */
    const {
        data: FuelROBObject,
        isLoading: FuelROBObjectLoading,
        isError: FuelROBObjectError,
    } = useQuery(
        [queryKeyes.vessel.FuelTypeROB.key, VesselID, VoyageID, ReportID],
        async () => {
            return await loadFuelTypeROB(VesselID, VoyageID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Shore supply Object used for edit */
    const {
        data: ShoreSupplyObject,
        isLoading: ShoreSupplyObjectLoading,
        isError: ShoreSupplyObjectError,
    } = useQuery(
        [queryKeyes.vessel.ShoreSupplyObject.key, VesselID, ReportID],
        async () => {
            return await loadShoreSupplyObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Scrubing unit Object used for edit */
    const {
        data: ScrubingUnitObject,
        isLoading: ScrubingUnitObjectLoading,
        isError: ScrubingUnitObjectError,
    } = useQuery(
        [queryKeyes.vessel.ScrubingUnitObject.key, VesselID, ReportID],
        async () => {
            return await loadScrubingUnitObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** LNG cargo's previous report's */
    const {
        data: LNGCargoPreviousROB,
        isLoading: LNGCargoPreviousROBLoading,
        isError: LNGCargoPreviousROBError,
    } = useQuery(
        [queryKeyes.vessel.LNGCargoPreviousROB.key, VesselID, ReportID],
        async () => {
            return await loadLNGCargoPreviousROB(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** LNG cargo ROB Object used for edit */
    const {
        data: LNGCargoROBObject,
        isLoading: LNGCargoROBObjectLoading,
        isError: LNGCargoROBObjectError,
    } = useQuery(
        [queryKeyes.vessel.LNGCargoROBObject.key, VesselID, ReportID],
        async () => {
            return await loadLNGCargoROBObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Vessel's other setting;s object used for edit */
    const {
        data: OtherSettings,
        isLoading: OtherSettingsLoading,
        isError: OtherSettingsError,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
        async () => {
            return await loadVesselOtherSettingsOperation(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Steam dump details Object used for edit */
    const {
        data: SteamDumpDetailsObject,
        isLoading: SteamDumpDetailsObjectLoading,
        isError: SteamDumpDetailsObjectError,
    } = useQuery(
        [queryKeyes.vessel.SteamDumpDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadSteamDumpDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Additional details object used for edit */
    const {
        data: AdditionalDetailsObject,
        isLoading: AdditionalDetailsObjectLoading,
    } = useQuery(
        [queryKeyes.vessel.ProactiveAddDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadProactiveAddDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Previous End of Sea Passage's arrival port */
    const { data: PreviousEOSPPortObject, isLoading: PreviousEOSPPortObjectLoading, isError: PreviousEOSPPortObjectError, } = useQuery(
        [queryKeyes.vessel.PreviousEOSPPort.key, VesselID, ReportID],
        async () => {
            return await loadPreviousEOSPPort(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** */
    const { data: lastEOSPPort } = useQuery(
        [queryKeyes.vessel.LastETADPort.key, VesselID, ReportID],
        async () => {
            return await lastETADPort(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Cargo Details object used for edit */
    const {
        data: CargoDetailsObject,
        isLoading: CargoDetailsObjectLoading,
        isError: CargoDetailsObjectError,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.CargoDetails.key, VesselID, ReportID],
        async () => {
            return await loadCargoDetails(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** last ETAD Object */
    const {
        data: LastETADObject,
    }: { data: any; } = useQuery(
        [queryKeyes.vessel.LastETADObject.key, VesselID, ReportID],
        async () => {
            return await lastETADObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Load speed and consumption of vessel */
    const { data: SpeedAndConsumption, isLoading: SpeedAndConsumptionLoading } = useQuery(
        [queryKeyes.vessel.SpeedConsumptionObject.key, VesselID, ReportID],
        async () => {
            return await loadSpeedConsumptionObject(VesselID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** End Queries */

    /** Enable/disable fields based on machine-fuel applicability */
    const machineryEnabled = (machine: number, fuel: number) => {
        if (fuelSettings?.filter((item: any) => item.vessel_machinery_fc_group === machine &&
            item.fuel_name === fuel &&
            item.vessel === VesselID)[0]?.selected === true) {
            return false;
        }
        return true;
    };

    /** Assign initial values to fuel consumptiom formik object */
    const getFuelInitialValues = () => {
        let newFuelConsumption: any[] = [];
        if (FuelConsumptionObject && FuelConsumptionObject?.length > 0) {
            return FuelConsumptionObject;
        } else if (
            newFuelConsumption !== undefined &&
            machineryFuelGroup !== undefined &&
            fuelTypes !== undefined &&
            newFuelConsumption.length <= machineryFuelGroup.length * fuelTypes.length
        ) {
            machineryFuelGroup.flatMap((machine: any) => {
                fuelTypes.map((fuel: any) => {
                    newFuelConsumption.push({
                        vessel: VesselID,
                        vessel_machinery_fc_group: machine.id,
                        sub_type_precedence_id: fuel?.precedence_id_sub_type,
                        machinery_precedence_id: machine?.machinery_precedence_id,
                        precedence_id: fuel?.precedence_id,
                        vessel_fuel: fuel.fuel_type,
                        method_used_to_measure_fuel_oil_consumption: "Flow meter",
                        fuel_consumed: machineryEnabled(machine?.id, fuel?.fuel_type) ? 0 : null,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                    });
                    return fuel;
                });
                return machine;
            });
            return newFuelConsumption;
        } else {
            return (newFuelConsumption = [
                {
                    fuel_consumed: 0,
                    vessel_machinery_fc_group: 0,
                    vessel_fuel: 0,
                    method_used_to_measure_fuel_oil_consumption: "Flow meter",
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                },
            ]);
        }
    };
    /** Assign initial values to batch consumptiom formik object */
    const getFuelBatchInitialValues = () => {
        let newFuelBatchConsumption: any[] = [];
        if (BatchConsumptionObject && BatchConsumptionObject.length > 0) {
            return BatchConsumptionObject.sort((a: any, b: any) => a.id - b.id);
        } else if (
            fuelBatches !== undefined &&
            newFuelBatchConsumption.length <= fuelBatches.length
        ) {
            fuelBatches
                ?.sort((a: any, b: any) => a.id - b.id)
                ?.filter((item: any) => item.status === "inuse")
                .map((batch: any) => {
                    newFuelBatchConsumption.push({
                        fuel_consumption: null,
                        viscosity: 0,
                        rob: 0,
                        previous_rob: 0,
                        adjustment_quantity: 0,
                        precedence_id: batch?.precedence_id_type,
                        adjustment_reason: null as string,
                        fuel_name: batch?.fuel_type_name,
                        fuel_sub_type: batch?.fuel_sub_type,
                        fuel_batches: batch?.id,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                    });
                    return batch;
                });
            return newFuelBatchConsumption;
        } else {
            return (newFuelBatchConsumption = [
                {
                    fuel_consumption: 0,
                    viscosity: 0,
                    rob: 0,
                    previous_rob: 0,
                    fuel_name: 0,
                    fuel_batches: 0,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                },
            ]);
        }
    };
    /** Assign initial values to shore supply formik object */
    const getShoreSupplyInitialValues = () => {
        if (ShoreSupplyObject && ShoreSupplyObject.length > 0) {
            return ShoreSupplyObject[0];
        } else {
            return ({
                is_edn_issue: false,
                total_kw_used: null,
                total_time: null,
                co2_equivalent: null,
                edn_file_upload: "",
                is_shore_supply_used: false,
                port: shoreSupplyPortID,
                time_on_shore_supply: 0,
                quantity_of_shore_supply: 0,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            });
        }
    };
    /** Assign values to initial object of Srubing units */
    const getScrubingInitialValues = () => {
        if (ScrubingUnitObject && ScrubingUnitObject.length > 0) {
            return ScrubingUnitObject[0];
        } else {
            return {
                is_egcs_in_use: false,
                is_scr_in_use: false,
                is_egr_in_use: false,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            };
        }
    };
    /** Assign initial values to batch consumptiom formik object */
    const getLNGCargoROBInitialValues = () => {
        if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
            if (LNGCargoROBObject && LNGCargoROBObject.length > 0 && CargoDetailsObject && CargoDetailsObject?.length > 0) {
                LNGCargoROBObject[0].cargo_adjusted = CargoDetailsObject[0]?.cargo_adjusted;
                return LNGCargoROBObject[0];
            } else {
                return {
                    initial_rob: 0,
                    lng_initial_cargo_rob: null,
                    cargo_consumed: 0,
                    cargo_rob: (LNGCargoPreviousROB && LNGCargoPreviousROB?.id > 0) ?
                        LNGCargoPreviousROB?.cargo_rob ?? 0 :
                        CargoDetailsObject && (CargoDetailsObject[0]?.total_amount_of_cargo_onboard + CargoDetailsObject[0]?.cargo_used),
                    cargo_adjusted: (CargoDetailsObject && CargoDetailsObject[0]?.cargo_adjusted) ?? 0,
                    cargo_loaded_cargo_detail: (CargoDetailsObject && CargoDetailsObject?.length > 0) ? CargoDetailsObject[0]?.id : null,
                    cargo_discharge_cargo_detail: (CargoDetailsObject && CargoDetailsObject?.length > 0) ? CargoDetailsObject[0]?.id : null,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                };
            }
        }
    };

    /** Assign initial values to steam details formik object */
    const getInitialSteamDumpValues = () => {
        if (SteamDumpDetailsObject && SteamDumpDetailsObject[0]?.id > 0) {
            return SteamDumpDetailsObject[0];
        } else {
            return {
                steam_dump_valve_percentage: null as number,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            };
        }
    };

    useEffect(() => {
        const getShoreSupplyPort = () => {
            if (record?.reporting_type === ReportingTypes?.IN_PORT &&
                lastRecord?.reporting_type === ReportingTypes?.AT_SEA &&
                record?.additional_events === true &&
                lastRecord?.reporting_event !== ReportingEvents?.EOSP
            ) {
                setShoreSupplyPort(LastETADObject?.arrival_port_name)
                setShoreSupplyPortID(LastETADObject?.arrival_port)
            } else if (lastEOSPPort && Object.keys(lastEOSPPort)?.length > 0) {
                setShoreSupplyPort(PreviousEOSPPortObject?.arrival_port_name ?? null)
                setShoreSupplyPortID(PreviousEOSPPortObject?.arrival_port ?? null)
            } else {
                setShoreSupplyPort(null);
                setShoreSupplyPortID(null);
            }
        }
        getShoreSupplyPort()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record, lastRecord, lastEOSPPort, LastETADObject])

    /** useEffect */
    useEffect(() => {
        FuelConsumptionFormik.initialValues.fuelConsumption =
            getFuelInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FuelConsumptionObject, machineryFuelGroup, fuelTypes]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.fuelConsumptionBatch =
            getFuelBatchInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BatchConsumptionObject, fuelBatches]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.shoreSupply =
            getShoreSupplyInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ShoreSupplyObject, PreviousEOSPPortObject]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.lngCargoROB =
            getLNGCargoROBInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [LNGCargoPreviousROB, LNGCargoROBObject, CargoDetailsObject]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.steamDumpDetails =
            getInitialSteamDumpValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OtherSettings, SteamDumpDetailsObject]);
    useEffect(() => {
        const withLNGBunker: any[] = fuelBatches?.filter(
            (item: any) => item.precedence_id === FuelTypes.LNG_BUNKER
        );
        const withoutLNGBunker: any[] = fuelBatches?.filter(
            (item: any) => item.precedence_id !== FuelTypes.LNG_BUNKER
        );
        let finalArray: any[] = [];
        withoutLNGBunker?.forEach((without: any) => {
            finalArray.push(without);
        });
        if (withLNGBunker?.length > 0) {
            finalArray.push(withLNGBunker[0]);
        }
        setFinalBatchConsumptionArray(finalArray);
    }, [fuelBatches]);
    useEffect(() => {
        setTotalLNGBunkerROB(
            fuelBatches
                ?.filter(
                    (item: any) => item.precedence_id_type === FuelTypes.LNG_BUNKER
                )
                ?.reduce(
                    (sum: number, item: any) => sum + (item.quantity_remaining || 0),
                    0
                )
        );
    }, [fuelBatches]);
    /** Fuel Consumption Formik object */
    let FuelConsumptionFormik = {
        initialValues: {
            // scrubingUnit: getScrubingInitialValues(),
            shoreSupply: getShoreSupplyInitialValues(),
            fuelConsumption: getFuelInitialValues(),
            fuelConsumptionBatch: getFuelBatchInitialValues(),
            lngCargoROB: getLNGCargoROBInitialValues(),
            steamDumpDetails: getInitialSteamDumpValues(),
        },
        validationSchema: Yup.object().shape({
            // egcs_in_use: Yup.boolean(),
            // scr_in_use: Yup.boolean(),
            // egr_in_use: Yup.boolean(),
            shoreSupply: Yup.object({
                time_on_shore_supply: Yup.string()
                    .matches(/^(?:25(?:\.00?)?|(?:[0-1]?\d|2[0-4])(?:\.\d{1,2})?)$/, commonValidationMessages.max25)
                    .when('is_shore_supply_used', {
                        is: true,
                        then: (schema: any) => schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    }),
                quantity_of_shore_supply: Yup.string()
                    .when('is_shore_supply_used', {
                        is: true,
                        then: (schema: any) => schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    })
                    .matches(/^\d{0,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4}`),
                total_time: Yup.string()
                    .matches(/^\d{0,5}(?:\.\d{1,4})?$/, commonValidationMessages.before5after4)
                    .when('is_edn_issue', {
                        is: true,
                        then: (schema: any) => schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    }),
                total_kw_used: Yup.string()
                    .matches(/^\d{0,6}(?:\.\d{1,4})?$/, commonValidationMessages.before6after4)
                    .when('is_edn_issue', {
                        is: true,
                        then: (schema: any) => schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    }),
                co2_equivalent: Yup.string()
                    .matches(/^\d{0,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4} (unit: mt)`)
                    .when('is_edn_issue', {
                        is: true,
                        then: (schema: any) => schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    }),
            }),
            fuelConsumption: Yup.array(
                Yup.object({
                    fuel_consumed: Yup.string().matches(/^\d*\.?\d+$/, commonValidationMessages.validNumber)
                        .required(commonValidationMessages.required),
                    vessel_machinery_fc_group: Yup.number(),
                    vessel_fuel: Yup.number(),
                    vessel: Yup.number(),
                    voyage_information: Yup.number(),
                    vessel_reporting_information: Yup.number(),
                })
            ),
            fuelConsumptionBatch: Yup.array(
                Yup.object({
                    fuel_consumption: Yup.string().matches(/^\d*\.?\d+$/, commonValidationMessages.validNumber)
                        .required(commonValidationMessages.required),
                    viscosity: Yup.number().nullable(),
                    rob: Yup.number(),
                    previous_rob: Yup.number(),
                    fuel_name: Yup.number(),
                    fuel_batches: Yup.number(),
                    adjustment_quantity: Yup.string().matches(
                        /^[+-]?\d{0,7}(?:\.\d{1,4})?$/,
                        commonValidationMessages.before7after4
                    ),
                    vessel: Yup.number(),
                    voyage_information: Yup.number(),
                    vessel_reporting_information: Yup.number(),
                }).test(
                    "fuerlConsumptionbatch",
                    "Total fuel batch consumption should be same as the total fuel consumption",
                    function (value: any) {
                        const fuelConsumptionBatch: any = this.parent ? this.parent : [];
                        let index = fuelConsumptionBatch.indexOf(value);
                        if (value.fuel_consumption === null) {
                            return new Yup.ValidationError(commonValidationMessages.required, undefined, ``);
                        }
                        if (value.precedence_id !== FuelTypes.LNG_CARGO) {
                            const currentFuelType = value.precedence_id;
                            const currentBatch = value.fuel_batches;
                            const totalConsumptionForFuelType = parseFloat(fuelConsumptionBatch
                                .filter((item: any) => item.precedence_id === currentFuelType)
                                .reduce((sum: number, item: any) => sum + (parseFloat(item.fuel_consumption ?? 0)), 0));
                            if (
                                currentFuelType !== FuelTypes.LNG_CARGO &&
                                currentFuelType !== FuelTypes.LNG_BUNKER
                            ) {
                                if (
                                    fuelBatches.filter(
                                        (item: any) =>
                                            item.id === currentBatch &&
                                            item.precedence_id_type !== FuelTypes.LNG_BUNKER
                                    )[0]?.previous_quantity_remaining < value.fuel_consumption
                                ) {
                                    return new Yup.ValidationError(
                                        `Fuel batch consumption should be equal to or less than fuel batch ROB`,
                                        undefined,
                                        `finalBatchConsumptionArray.${index}`
                                    );
                                }
                                if (
                                    totalConsumptionForFuelType?.toFixed(4) !==
                                    fuelConsumptionSum[currentFuelType]?.toFixed(4)
                                ) {
                                    return new Yup.ValidationError(
                                        `Total fuel batch consumption should be same as the total fuel consumption`,
                                        undefined,
                                        `finalBatchConsumptionArray.${index}`
                                    );
                                }
                            }
                            if (
                                currentFuelType === FuelTypes.LNG_BUNKER &&
                                totalLNGBunkerROB < value.fuel_consumption
                            ) {
                                return new Yup.ValidationError(
                                    `Fuel batch consumption should be equal to or less than fuel ROB`,
                                    undefined,
                                    `finalBatchConsumptionArray.${index}`
                                );
                            }
                            return true;
                        }
                        return true;
                    })
            ),
            steamDumpDetails: Yup.object({
                steam_dump_valve_percentage: Yup.number()
                    .min(0, commonValidationMessages.min0)
                    .max(100, commonValidationMessages.maxVal100)
                    .nullable(),
            }),
        }),
    };

    /** Calculate the sum of fuel consumption */
    const loadFuelConsumptionSum = (fuelConsumption: any) => {
        if (fuelConsumption) {
            fuelConsumptionSum = fuelConsumption?.reduce((acc: any, curr: any) => {
                const { precedence_id, fuel_consumed } = curr;
                let fuel_consumed_float = parseFloat(fuel_consumed);
                if (isNaN(fuel_consumed_float)) {
                    acc[precedence_id] = (acc[precedence_id] ?? 0) + 0;
                } else {
                    acc[precedence_id] = (acc[precedence_id] ?? 0) + fuel_consumed_float;
                }
                return acc;
            }, {});
            return fuelConsumptionSum;
        } else {
            return {};
        }
    };

    /** Calculate the sum of fuel batch consumption according to fuel type */
    const loadFuelBatchConsumptionSum = (fuelBatchConsumption: any) => {
        if (fuelBatchConsumption) {
            fuelBatchConsumptionSum = fuelBatchConsumption?.reduce(
                (acc: any, curr: any) => {
                    const { fuel_name, fuel_consumption } = curr;
                    let fuel_consumed_float = parseFloat(fuel_consumption);
                    if (isNaN(fuel_consumed_float)) {
                    } else {
                        acc[fuel_name] = (acc[fuel_name] ?? 0) + fuel_consumed_float;
                    }
                    return acc;
                },
                {}
            );
            return fuelBatchConsumptionSum;
        } else {
            return {};
        }
    };

    /** Calculate the sum of fuel batch adjustment according to fuel type */
    const loadFuelBatchAdjustmentSum = (fuelBatchAdjustment: any) => {
        if (fuelBatchAdjustment) {
            fuelBatchAdjustmentSum = fuelBatchAdjustment?.reduce(
                (acc: any, curr: any) => {
                    const { fuel_name, adjustment_quantity } = curr;
                    let fuel_adjusted_float = parseFloat(adjustment_quantity);
                    if (isNaN(fuel_adjusted_float)) {
                    } else {
                        acc[fuel_name] = (acc[fuel_name] ?? 0) + fuel_adjusted_float;
                    }
                    return acc;
                },
                {}
            );
            return fuelBatchAdjustmentSum;
        } else {
            return {};
        }
    };

    /** Update fuel batch rob */
    const handleFuelBatchROB = (
        bunker: any,
        value: any,
        operation: string,
        fuelConsumptionBatch: any
    ) => {
        let batchObj = { ...bunker };
        if (!(BatchConsumptionObject && BatchConsumptionObject?.length > 0)) {
            batchObj.previous_quantity_remaining = batchObj.quantity_remaining;
        }
        if (operation === 'consumption') {
            batchObj.quantity_remaining = (
                batchObj.previous_quantity_remaining - parseFloat(value) + parseFloat(fuelConsumptionBatch?.adjustment_quantity)
            ).toFixed(2);
        } else {
            batchObj.quantity_remaining = (
                batchObj.previous_quantity_remaining + parseFloat(value) - parseFloat(fuelConsumptionBatch?.fuel_consumption)
            ).toFixed(2);
        }
        if (batchROB.length > 0) {
            batchROB.map((rob: any, robIdx: number) => {
                if (rob.id === bunker.id) {
                    batchROB.splice(robIdx, 1);
                }
                return batchROB;
            });
        }
        batchROB.push(batchObj);
    }

    /** Assign values to viscosity, rob, previous_rob */
    const handleFuelBatchConsumptionChanges = (
        e: any,
        form: any,
        bunker: any,
        values: any,
        fuelconsumptionBatch: any,
        fuelbatch?: any,
    ) => {
        form.setFieldValue(
            `fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(
                fuelbatch
            )}.viscosity`,
            bunker.viscosity
        );
        if (fuelbatch.precedence_id !== FuelTypes.LNG_BUNKER) {
            if (BatchConsumptionObject && BatchConsumptionObject?.length > 0) {
                form.setFieldValue(
                    `fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(
                        fuelbatch
                    )}.rob`,
                    (bunker.previous_quantity_remaining - parseFloat(e.target.value) + parseFloat(fuelbatch?.adjustment_quantity)).toFixed(2)
                );
            } else {
                form.setFieldValue(
                    `fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(
                        fuelbatch
                    )}.previous_rob`,
                    bunker.quantity_remaining
                );
                form.setFieldValue(
                    `fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(
                        fuelbatch
                    )}.rob`,
                    (bunker.quantity_remaining - parseFloat(e.target.value) + parseFloat(fuelbatch?.adjustment_quantity)).toFixed(2)
                );
            }
        } else {
            let remainder: number = 0;
            values?.fuelConsumptionBatch
                .filter((item: any) => item.precedence_id === FuelTypes.LNG_BUNKER)
                .forEach((batch: any, index: number) => {
                    fuelBatches
                        .filter((item: any) => item.id === batch.fuel_batches)
                        .forEach((lngBunker: any) => {
                            batch.previous_rob = lngBunker.quantity_remaining;
                            if (!(parseFloat(e.target.value) > totalLNGBunkerROB)) {
                                if (
                                    lngBunker.quantity_remaining === e.target.value ||
                                    lngBunker.quantity_remaining > e.target.value
                                ) {
                                    if (index === 0) {
                                        batch.fuel_consumption = e.target.value;
                                        batch.rob = lngBunker.quantity_remaining - (parseFloat(e.target.value));
                                    } else {
                                        batch.fuel_consumption = 0;
                                        batch.rob = lngBunker.quantity_remaining;
                                    }
                                } else if (index === 0) {
                                    if (lngBunker.quantity_remaining < e.target.value) {
                                        batch.fuel_consumption = lngBunker.quantity_remaining;
                                        batch.rob = 0;
                                        remainder = (parseFloat(e.target.value)) - lngBunker.quantity_remaining;
                                    }
                                } else if (index > 0) {
                                    if (lngBunker.quantity_remaining < remainder) {
                                        batch.fuel_consumption = lngBunker.quantity_remaining;
                                        batch.rob = 0;
                                        remainder -= lngBunker.quantity_remaining;
                                    } else {
                                        batch.fuel_consumption = remainder;
                                        batch.rob = lngBunker.quantity_remaining - remainder;
                                    }
                                }
                            }
                        });
                });
        }
        handleFuelBatchROB(bunker, e.target.value, 'consumption', fuelconsumptionBatch);
    };

    /** Fuel measurement method */
    const measurementMethodOptions: any[] = [
        { id: "1", label: "BDN" },
        { id: "2", label: "Flow meter" },
        { id: "3", label: "Bunkered fuel tank monitoring" },
        { id: "4", label: "None" },
    ];

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        setFuelBatchIndex(null);
        removeBodyCss();
    }

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Fuel Consumption & ROB
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(previousFuelROBLoading ||
                FuelConsumptionObjectLoading ||
                BatchConsumptionObjectLoading ||
                FuelROBObjectLoading ||
                fuelSettingsLoading ||
                fuelTypesLoading ||
                machineryFuelGroupLoading ||
                vesselMachineriesLoading ||
                fuelBatchesLoading ||
                ShoreSupplyObjectLoading ||
                ScrubingUnitObjectLoading ||
                LNGCargoPreviousROBLoading ||
                LNGCargoROBObjectLoading ||
                OtherSettingsLoading ||
                SteamDumpDetailsObjectLoading ||
                AdditionalDetailsObjectLoading ||
                PreviousEOSPPortObjectLoading ||
                CargoDetailsObjectLoading ||
                SpeedAndConsumptionLoading) && <Loading message='Loading required data!' />}
            {(previousFuelROBError ||
                fuelTypesError ||
                fuelBatchesError ||
                vesselMachineriesError ||
                machineryFuelGroupError ||
                fuelSettingsError ||
                FuelROBObjectError ||
                LNGCargoPreviousROBError ||
                OtherSettingsError ||
                PreviousEOSPPortObjectError) && (
                    <ErrorComponent message="Unable to load required data!" />
                )}
            {FuelConsumptionObjectError && getFuelInitialValues()}
            {BatchConsumptionObjectError && getFuelBatchInitialValues()}
            {ShoreSupplyObjectError && getShoreSupplyInitialValues()}
            {ScrubingUnitObjectError && getScrubingInitialValues()}
            {(LNGCargoROBObjectError || CargoDetailsObjectError) && getLNGCargoROBInitialValues()}
            {SteamDumpDetailsObjectError && getInitialSteamDumpValues()}
            {!(
                previousFuelROBLoading ||
                FuelConsumptionObjectLoading ||
                BatchConsumptionObjectLoading ||
                FuelROBObjectLoading ||
                fuelSettingsLoading ||
                fuelTypesLoading ||
                machineryFuelGroupLoading ||
                vesselMachineriesLoading ||
                fuelBatchesLoading ||
                ShoreSupplyObjectLoading ||
                ScrubingUnitObjectLoading ||
                LNGCargoPreviousROBLoading ||
                LNGCargoROBObjectLoading ||
                OtherSettingsLoading ||
                SteamDumpDetailsObjectLoading ||
                AdditionalDetailsObjectLoading ||
                PreviousEOSPPortObjectLoading ||
                CargoDetailsObjectLoading ||
                SpeedAndConsumptionLoading) &&
                !(previousFuelROBError ||
                    fuelTypesError ||
                    fuelBatchesError ||
                    vesselMachineriesError ||
                    machineryFuelGroupError ||
                    fuelSettingsError ||
                    FuelROBObjectError ||
                    LNGCargoPreviousROBError ||
                    OtherSettingsError ||
                    PreviousEOSPPortObjectError) &&
                <Formik
                    key={refreshKey}
                    onSubmit={async (values: any, actions: any) => {
                        if (env?.form_validation === false) {
                            setErrorMessage(null)
                            toggleTab(activeTab + 1);
                        }
                        actions.setSubmitting(true);
                        const responseArray: any[] = [];
                        /** Scrubing unit submit */
                        // if (ScrubingUnitObject && ScrubingUnitObject.length > 0) {
                        //     responseArray.push(apiGlobal.put(`/reporting_scrubing_units_detail/${values?.scrubingUnit?.id}/`, values?.scrubingUnit));
                        // } else {
                        //     responseArray.push(apiGlobal.post(`/reporting_scrubing_units_detail/`, values.scrubingUnit));
                        // }
                        /** Shore supply submit */
                        if (record && (record?.reporting_operation_mode === ReportingOpConstant.ANCHOR_STAY ||
                            record?.reporting_operation_mode === ReportingOpConstant.PORT_STAY)) {
                            if (values?.shoreSupply?.id) {
                                const formData = new FormData();
                                Object.keys(values.shoreSupply).forEach((item: any) => {
                                    if (values.shoreSupply[item] === null || undefined) return; // if null assign blank string
                                    formData.append(item, values.shoreSupply[item]);// else continue with value
                                });
                                if (values?.shoreSupply?.is_shore_supply_used === false) {
                                    formData.set('time_on_shore_supply', "0")
                                    formData.set('quantity_of_shore_supply', "0")
                                    formData.delete('port')
                                }
                                if (values?.shoreSupply?.is_edn_issue === false ||
                                    values?.shoreSupply?.is_shore_supply_used === false) {
                                    formData.set('total_time', "0")
                                    formData.set('total_kw_used', "0")
                                    formData.set('co2_equivalent', "0")
                                }
                                if (EDNFiles && Array.isArray(EDNFiles) && EDNFiles[0].file) {
                                    formData.set('edn_file_upload', EDNFiles[0].file)
                                } else {
                                    formData.set('edn_file_upload', "")
                                }
                                formData.delete('status')
                                responseArray.push(apiMedia.put(`/shore_supply/${values?.shoreSupply?.id}/`, formData))
                            } else {
                                if (values?.shoreSupply?.is_edn_issue === true) {
                                    values.shoreSupply.edn_file_upload = EDNFiles[0];
                                    values.shoreSupply.port = shoreSupplyPortID;
                                }
                                const formData = new FormData();
                                Object.keys(values.shoreSupply).forEach((item: any) => {
                                    if (values.shoreSupply[item] === null || undefined) return;
                                    formData.append(item, values.shoreSupply[item]);
                                });
                                if (EDNFiles && Array.isArray(EDNFiles) && EDNFiles[0].file) {
                                    formData.set('edn_file_upload', EDNFiles[0].file)
                                } else {
                                    formData.set('edn_file_upload', "")
                                }
                                if (values?.shoreSupply?.is_shore_supply_used === false) {
                                    formData.set('time_on_shore_supply', "0")
                                    formData.set('quantity_of_shore_supply', "0")
                                    formData.delete('port')
                                }
                                if (values?.shoreSupply?.is_edn_issue === false ||
                                    values?.shoreSupply?.is_shore_supply_used === false) {
                                    formData.set('total_time', "0")
                                    formData.set('total_kw_used', "0")
                                    formData.set('co2_equivalent', "0")
                                }
                                formData.delete('status')
                                responseArray.push(apiMedia.post(`/shore_supply/`, formData))
                            }
                        }
                        /**Fuel consumption submit */
                        values.fuelConsumption.forEach((val: any) => {
                            val.method_used_to_measure_fuel_oil_consumption = measurementMethod;
                        })
                        if (FuelConsumptionObject && FuelConsumptionObject?.length > 0) {
                            values.fuelConsumption.map((val: any) => {
                                responseArray.push(apiGlobal.put(`/FO_fuel_consumption/${val.id}/`, val))
                                return '';
                            })
                        } else {
                            responseArray.push(apiGlobal.post(`/FO_fuel_consumption/`, values.fuelConsumption))
                        }
                        /**Batch consumption submit */
                        values.fuelConsumptionBatch.map((batch: any) => {
                            fuelBatches.filter((item: any) => item.id === batch.fuel_batches && batch.fuel_consumption === 0.0)
                                .map((bunker: any) => {
                                    batch.rob = bunker.quantity_remaining;
                                    batch.previous_rob = bunker.quantity_remaining;
                                    return '';
                                })
                            return '';
                        })
                        finalBatchConsumptionArray?.filter((item: any) => item.quantity_remaining > 0)
                            .forEach((batch: any) => {
                                values?.fuelConsumptionBatch?.filter((fuel: any) => fuel.fuel_batches === batch.id)?.forEach((fuelbatch: any) => {
                                    fuelbatch.viscosity = batch.viscosity;
                                    if (fuelbatch.precedence_id !== FuelTypes.LNG_BUNKER) {
                                        if (BatchConsumptionObject && BatchConsumptionObject?.length > 0) {
                                            fuelbatch.rob = (
                                                batch.previous_quantity_remaining + parseFloat(fuelbatch?.adjustment_quantity) - parseFloat(fuelbatch?.fuel_consumption)
                                            ).toFixed(2);
                                        } else {
                                            fuelbatch.previous_rob = batch.quantity_remaining;
                                            fuelbatch.rob = (
                                                batch.quantity_remaining + parseFloat(fuelbatch?.adjustment_quantity) - parseFloat(fuelbatch?.fuel_consumption)
                                            ).toFixed(2);
                                        }
                                    } else {
                                        let remainder: number = 0;
                                        values?.fuelConsumptionBatch
                                            .filter((item: any) => item.precedence_id === FuelTypes.LNG_BUNKER)
                                            .forEach((batch: any, index: number) => {
                                                fuelBatches
                                                    .filter((item: any) => item.id === batch.fuel_batches)
                                                    .forEach((lngBunker: any) => {
                                                        batch.previous_rob = lngBunker.quantity_remaining;
                                                        if (!(parseFloat(fuelbatch?.fuel_consumption) > totalLNGBunkerROB)) {
                                                            if (
                                                                lngBunker.quantity_remaining === fuelbatch?.fuel_consumption ||
                                                                lngBunker.quantity_remaining > fuelbatch?.fuel_consumption
                                                            ) {
                                                                if (index === 0) {
                                                                    batch.fuel_consumption = fuelbatch?.fuel_consumption;
                                                                    batch.rob = lngBunker.quantity_remaining - (parseFloat(fuelbatch?.fuel_consumption));
                                                                } else {
                                                                    batch.fuel_consumption = 0;
                                                                    batch.rob = lngBunker.quantity_remaining;
                                                                }
                                                            } else if (index === 0) {
                                                                if (lngBunker.quantity_remaining < fuelbatch?.fuel_consumption) {
                                                                    batch.fuel_consumption = lngBunker.quantity_remaining;
                                                                    batch.rob = 0;
                                                                    remainder = (parseFloat(fuelbatch?.fuel_consumption)) - lngBunker.quantity_remaining;
                                                                }
                                                            } else if (index > 0) {
                                                                if (lngBunker.quantity_remaining < remainder) {
                                                                    batch.fuel_consumption = lngBunker.quantity_remaining;
                                                                    batch.rob = 0;
                                                                    remainder -= lngBunker.quantity_remaining;
                                                                } else {
                                                                    batch.fuel_consumption = remainder;
                                                                    batch.rob = lngBunker.quantity_remaining - remainder;
                                                                }
                                                            }
                                                        }
                                                    });
                                            });
                                    }
                                })
                            })
                        if (BatchConsumptionObject && BatchConsumptionObject?.length > 0) {
                            values.fuelConsumptionBatch.map((val: any) => {
                                responseArray.push(apiGlobal.put(`/fuel_consumption_batch/${val.id}/`, val))
                                return val;
                            })
                        } else {
                            responseArray.push(apiGlobal.post(`/fuel_consumption_batch/`, values.fuelConsumptionBatch))
                        }
                        /**Fuel ROB submit */
                        if (FuelROBObject && FuelROBObject?.length > 0) {
                            FuelROBObject.map((val: any) => {
                                fuelTypes.filter((item: any) => item.precedence_id === val.type_precedence_id).map((type: any) => {
                                    if (type.precedence_id !== FuelTypes.LNG_CARGO) {
                                        let prevFuelROB: any = FuelROBObject?.filter((item: any) =>
                                            item.type_precedence_id === type.precedence_id)[0].previous_rob;
                                        let fuelTotalConsumption = fuelConsumptionSum[type.precedence_id];
                                        let fuelBatchTotalAdjustment = fuelBatchAdjustmentSum[type.fuel_type]
                                        val.rob = (prevFuelROB - fuelTotalConsumption + fuelBatchTotalAdjustment)?.toFixed(2);
                                        val.total_fuel_consumed = fuelTotalConsumption?.toFixed(2);
                                    }
                                    return '';
                                })
                                responseArray.push(apiGlobal.put(`/fo_rob/${val.id}/`, val))
                                return fuelTypes;
                            })
                        } else {
                            fuelTypes?.filter((item: any) => item.precedence_id !== FuelTypes.LNG_CARGO).map((type: any) => {
                                let prevFuelROB: any = PreviousFuelROB?.filter((item: any) => item?.type_precedence_id === type?.precedence_id)[0]?.rob ?? 0;
                                let fuelTotalConsumption = loadFuelConsumptionSum(values?.fuelConsumption)[type.precedence_id];
                                return forob.push({
                                    rob: (prevFuelROB - fuelTotalConsumption < 0) ? 0 :
                                        (prevFuelROB - fuelTotalConsumption)?.toFixed(2),
                                    bunkered_quantity: 0,
                                    debunkered_quantity: 0,
                                    total_fuel_consumed: fuelTotalConsumption?.toFixed(2),
                                    previous_rob: prevFuelROB,
                                    vessel_fuel: type.fuel_type,
                                    vessel: VesselID,
                                    voyage_information: VoyageID,
                                    vessel_reporting_information: ReportID
                                })
                            });
                            responseArray.push(apiGlobal.post(`/fo_rob/`, forob))
                        }
                        /**Bunkering update */
                        finalBatchConsumptionArray?.filter((item: any) => item.quantity_remaining > 0)
                            .forEach((batch: any) => {
                                values?.fuelConsumptionBatch?.filter((fuel: any) => fuel.fuel_batches === batch.id)?.forEach((fuelbatch: any) => {
                                    if (!(BatchConsumptionObject && BatchConsumptionObject?.length > 0)) {
                                        batch.previous_quantity_remaining = batch.quantity_remaining;
                                    }
                                    batch.quantity_remaining = (
                                        batch.previous_quantity_remaining + parseFloat(fuelbatch?.adjustment_quantity) - parseFloat(fuelbatch?.fuel_consumption)
                                    ).toFixed(2);
                                    if (batchROB.length > 0) {
                                        batchROB.map((rob: any, robIdx: number) => {
                                            if (rob.id === batch.id) {
                                                batchROB.splice(robIdx, 1);
                                            }
                                            return batchROB;
                                        });
                                    }
                                })
                                responseArray.push(apiGlobal.put(`/bunkering/${batch.id}/`, batch))
                            })
                        /** LNG cargo ROB update */
                        if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
                            values.lngCargoROB.cargo_consumed = loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO];
                            if (LNGCargoPreviousROB && LNGCargoPreviousROB?.id > 0) {
                                values.lngCargoROB.cargo_rob = parseFloat(LNGCargoPreviousROB?.cargo_rob) ?? 0 -
                                    parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]) +
                                    parseFloat(CargoDetailsObject[0]?.amount_of_cargo_loaded) -
                                    parseFloat(CargoDetailsObject[0]?.amount_of_cargo_discharged) +
                                    parseFloat(CargoDetailsObject[0]?.cargo_adjusted)
                            }
                            values.lngCargoROB.cargo_rob = (((CargoDetailsObject && CargoDetailsObject?.length > 0) ?
                                parseFloat(CargoDetailsObject[0]?.total_amount_of_cargo_onboard) :
                                parseFloat(LNGCargoPreviousROB?.cargo_rob) ?? 0) -
                                parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])
                            ).toFixed(4)
                            if (LNGCargoROBObject && LNGCargoROBObject.length > 0 && values.lngCargoROB.id) {
                                responseArray.push(apiGlobal.put(`/lng_cargo_rob/${values?.lngCargoROB?.id}/`, values?.lngCargoROB))
                            } else {
                                responseArray.push(apiGlobal.post(`/lng_cargo_rob/`, values?.lngCargoROB))
                            }

                        }
                        /** Steam Dump Details submit */
                        if (OtherSettings[0]?.is_steam_dump_valve_in_use === true) {
                            if (SteamDumpDetailsObject && SteamDumpDetailsObject[0]?.id > 0) {
                                responseArray.push(apiGlobal.put(`/steam_dump_details/${values?.steamDumpDetails?.id}/`, values?.steamDumpDetails))
                            } else {
                                responseArray.push(apiGlobal.post(`/steam_dump_details/`, values?.steamDumpDetails))
                            }
                        }
                        await handleServerResponse(responseArray).then(async res => {
                            if (res === true) {
                                setEDNFiles([{}]);
                                // await queryClient.invalidateQueries(queryKeyes.vessel.ScrubingUnitObject.key);
                                if (record && (record?.reporting_operation_mode === ReportingOpConstant.ANCHOR_STAY ||
                                    record?.reporting_operation_mode === ReportingOpConstant.PORT_STAY)) {
                                    await queryClient.invalidateQueries(queryKeyes.vessel.ShoreSupplyObject.key);
                                }
                                await queryClient.invalidateQueries(queryKeyes.vessel.FuelConsumptionObject.key);
                                await queryClient.invalidateQueries(queryKeyes.vessel.BatchConsumptionObject.key);
                                await queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
                                await queryClient.invalidateQueries(queryKeyes.vessel.PreviousFuelROB.key);
                                await queryClient.invalidateQueries(queryKeyes.vessel.Bunkering.key);
                                if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
                                    await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoPreviousROB.key);
                                    await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoROBObject.key);
                                }
                                if (OtherSettings[0]?.is_steam_dump_valve_in_use === true) {
                                    await queryClient.invalidateQueries(queryKeyes.vessel.SteamDumpDetailsObject.key);
                                }
                                if (env?.form_validation === true) {
                                    setErrorMessage(null)
                                    toggleTab(activeTab + 1);
                                }
                                setRefreshKey(refreshKey + 1)
                            } else if (Array.isArray(res)) {
                                if (res.filter((item: any) => item.status === "fulfilled")?.length > 0) {
                                    res.filter((item: any) => item.status === "fulfilled")?.forEach(async (obj: any) => {
                                        const apiURL = obj?.value?.config?.url?.replace(/\d+/g, "").replace(/\/$/, "");
                                        if (OtherSettings[0]?.is_steam_dump_valve_in_use === true &&
                                            apiURL === "/steam_dump_details/") {
                                            await queryClient.invalidateQueries(queryKeyes.vessel.SteamDumpDetailsObject.key);
                                        } else if ((record && (record?.reporting_operation_mode === ReportingOpConstant.ANCHOR_STAY ||
                                            record?.reporting_operation_mode === ReportingOpConstant.PORT_STAY)) &&
                                            apiURL === "/shore_supply/") {
                                            await queryClient.invalidateQueries(queryKeyes.vessel.ShoreSupplyObject.key);
                                        } else if (apiURL === "/FO_fuel_consumption/") {
                                            await queryClient.invalidateQueries(queryKeyes.vessel.FuelConsumptionObject.key);
                                        } else if (apiURL === "/fuel_consumption_batch/") {
                                            await queryClient.invalidateQueries(queryKeyes.vessel.BatchConsumptionObject.key);
                                            await queryClient.invalidateQueries(queryKeyes.vessel.Bunkering.key);
                                        } else if (apiURL === "/fo_rob/") {
                                            await queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
                                            await queryClient.invalidateQueries(queryKeyes.vessel.PreviousFuelROB.key);
                                        } else if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                                            apiURL === "/lng_cargo_rob/") {
                                            await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoPreviousROB.key);
                                            await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoROBObject.key);
                                        }
                                    })
                                    setRefreshKey(refreshKey + 1);
                                }
                                setErrorMessage(res);
                            }
                        })
                        setRefreshKey(refreshKey + 1);
                        actions.setSubmitting(false);
                    }}
                    initialValues={FuelConsumptionFormik.initialValues}
                    validationSchema={env?.form_validation === true ? FuelConsumptionFormik.validationSchema : null}
                >
                    {({ values, errors, handleSubmit, setErrors, isSubmitting }:
                        { values: any, errors: any, handleSubmit: any, handleChange: any, setErrors: any, handleBlur: any, touched: any, isSubmitting: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='engineer-card-body'>
                                {/* {vesselMachineries?.filter((item: any) => (item.precedence_id === VesselMachineryConstant.EGCS ||
                                    item.precedence_id === VesselMachineryConstant.SCR ||
                                    item.precedence_id === VesselMachineryConstant.EGR
                                )).length > 0 &&
                                    <Row className='d-flex align-items-center p-2'>
                                        <Col sm={4}>
                                            {vesselMachineries?.filter((item: any) => item.precedence_id === VesselMachineryConstant.EGCS).length > 0 &&
                                                <div className="d-flex flex-wrap">
                                                    <Label className='mb-0' for='is_egcs_in_use'>
                                                        Is EGCS in use?<i className='bx bx-info-circle me-2' id='egcs_in_use_msg' />
                                                    </Label>
                                                    <ToolTip target='egcs_in_use_msg'
                                                        message={`${TooltipMsg.FuelConsumption
                                                            .filter((item: any) => item.target === 'egcs_in_use_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'scrubingUnit.is_egcs_in_use'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id='is_egcs_in_use'
                                                                    onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)} />
                                                                <Label
                                                                    htmlFor={field.name}
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'
                                                                    className='mb-0'
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {errors?.scrubingUnit && errors?.scrubingUnit?.is_egcs_in_use && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`is_egcs_in_use`}
                                                            message={errors?.scrubingUnit?.is_egcs_in_use}
                                                            open={(errors?.scrubingUnit && errors?.scrubingUnit?.is_egcs_in_use) && !adjustmentPopup ? true : false}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </Col>
                                        <Col sm={4}>
                                            {vesselMachineries?.filter((item: any) => item.precedence_id === VesselMachineryConstant.SCR).length > 0 &&
                                                <div className="d-flex flex-wrap">
                                                    <Label className='mb-0' for='is_scr_in_use'>Is SCR in use?
                                                        <i className='bx bx-info-circle me-2' id='scr_in_use_msg' />
                                                    </Label>
                                                    <ToolTip target='scr_in_use_msg'
                                                        message={`${TooltipMsg.FuelConsumption
                                                            .filter((item: any) => item.target === 'scr_in_use_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'scrubingUnit.is_scr_in_use'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id='is_scr_in_use'
                                                                    onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)} />
                                                                <Label
                                                                    htmlFor={field.name}
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'
                                                                    className='mb-0'
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {errors?.scrubingUnit && errors?.scrubingUnit?.is_scr_in_use && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`is_scr_in_use`}
                                                            message={errors?.scrubingUnit?.is_scr_in_use}
                                                            open={(errors?.scrubingUnit && errors?.scrubingUnit?.is_scr_in_use) && !adjustmentPopup ? true : false}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </Col>
                                        <Col sm={4}>
                                            {vesselMachineries?.filter((item: any) => item.precedence_id === VesselMachineryConstant.EGR).length > 0 &&
                                                <div className="d-flex flex-wrap">
                                                    <Label className='mb-0' for='is_egr_in_use'>
                                                        Is EGR in use?<i className='bx bx-info-circle me-2' id='egr_in_use_msg' />
                                                    </Label>
                                                    <ToolTip target='egr_in_use_msg'
                                                        message={`${TooltipMsg.FuelConsumption
                                                            .filter((item: any) => item.target === 'egr_in_use_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'scrubingUnit.is_egr_in_use'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id={'is_egr_in_use'}
                                                                    onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)} />
                                                                <Label
                                                                    htmlFor={field.name}
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'
                                                                    className='mb-0'
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {errors?.scrubingUnit && errors?.scrubingUnit?.is_egr_in_use && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`is_egr_in_use`}
                                                            message={errors?.scrubingUnit?.is_egr_in_use}
                                                            open={(errors?.scrubingUnit && errors?.scrubingUnit?.is_egr_in_use) && !adjustmentPopup ? true : false}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                } */}
                                {OtherSettings[0]?.is_steam_dump_valve_in_use === true &&
                                    <SteamDumpDetailsComponent
                                        adjustmentPopup={adjustmentPopup}
                                    />
                                }
                                <ShoreSupplyComponent
                                    adjustmentPopup={adjustmentPopup}
                                    record={record}
                                    shoreSupplyPort={shoreSupplyPort}
                                    refreshKey={refreshKey}
                                    setRefreshKey={setRefreshKey}
                                    EDNFiles={EDNFiles}
                                    setEDNFiles={setEDNFiles}
                                    FileStatus={FileStatus}
                                    DeleteFileObj={DeleteFileObj}
                                />
                                <FuelConsumption
                                    adjustmentPopup={adjustmentPopup}
                                    machineryFuelGroup={machineryFuelGroup}
                                    vesselMachineries={vesselMachineries}
                                    fuelTypes={fuelTypes}
                                    setMeasurementMethod={setMeasurementMethod}
                                    measurementMethodOptions={measurementMethodOptions}
                                    Fuel_Consumption_inputs={Fuel_Consumption_inputs}
                                    calculateTotalDOeq={calculateTotalDOeq}
                                    SpeedAndConsumption={SpeedAndConsumption}
                                    record={record}
                                    setCount={setCount}
                                    setAlertBool={setAlertBool}
                                    setAlertMessage={setAlertMessage}
                                    setAlertColourType={setAlertColourType}
                                    setAlertType={setAlertType}
                                    machineryEnabled={machineryEnabled}
                                    loadFuelConsumptionSum={loadFuelConsumptionSum}
                                    count={count}
                                />
                                {/* <hr /> */}
                                {vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                                    <LNGCargoROB
                                        CargoDetailsObject={CargoDetailsObject}
                                        AdditionalDetailsObject={AdditionalDetailsObject}
                                        count={count}
                                        loadFuelConsumptionSum={loadFuelConsumptionSum}
                                    />
                                }
                                <FuelConsumptionBatchComponent
                                    fuelTypes={fuelTypes}
                                    loadFuelConsumptionSum={loadFuelConsumptionSum}
                                    FuelROBObject={FuelROBObject}
                                    PreviousFuelROB={PreviousFuelROB}
                                    loadFuelBatchConsumptionSum={loadFuelBatchConsumptionSum}
                                    loadFuelBatchAdjustmentSum={loadFuelBatchAdjustmentSum}
                                    finalBatchConsumptionArray={finalBatchConsumptionArray}
                                    totalLNGBunkerROB={totalLNGBunkerROB}
                                    handleFuelBatchConsumptionChanges={handleFuelBatchConsumptionChanges}
                                    BatchConsumptionObject={BatchConsumptionObject}
                                    setFuelBatchIndex={setFuelBatchIndex}
                                    setAdjustmentPopup={setAdjustmentPopup}
                                    setBunker={setBunker}
                                    adjustmentPopup={adjustmentPopup}
                                    bunker={bunker}
                                    handleFuelBatchROB={handleFuelBatchROB}
                                    tog_backdrop={tog_backdrop}
                                    fuelBatchIndex={fuelBatchIndex}
                                />
                                {alertBool &&
                                    <AlertPopup
                                        state={alertBool}
                                        setState={setAlertBool}
                                        alertMessage={alertMessage}
                                        setAlertMessage={setAlertMessage}
                                        AlertColourType={alertColourType}
                                        setAlertColourType={setAlertColourType}
                                        alertType={alertType}
                                        setAlertType={setAlertType}
                                        formTitle="Fuel Consumption"
                                    />
                                }
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            onClick={() => {
                                                values?.fuelConsumption?.forEach((val: any) => {
                                                    if (machineryEnabled(val.vessel_machinery_fc_group, val.vessel_fuel) === true) {
                                                        val.fuel_consumed = 0;
                                                    }
                                                })
                                            }}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn_size_cstm"
                                            onClick={() => {
                                                setErrors({});
                                                toggleTab(activeTab - 1);
                                                setErrorMessage(null)
                                            }}
                                        >
                                            <i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, FuelConsumptionFormik.initialValues]} />
                        </Form>
                    )}
                </Formik >
            }
        </Card >
    )
}

export default FuelConsumptionComponent;
