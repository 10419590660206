import React, { useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import "../global/GlobalCSS.css";
import BulkCarrier from "../Media/bulk_carrier.png";
import Tanker from "../Media/tanker.png";
import Cargo from "../Media/general_cargo.png";
import GasCarrier from "../Media/gas_carrier.png";
import Container from "../Media/container_carrier.png";
import LNGCarrier from "../Media/lng_carrier.png";
import RoRo from "../Media/ro_ro.png";
import ToolTip from "./ToolTip";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setVesselID } from "../Store/Generic/ReportingSlice";
import { hasRole } from "utils/auth/authUtils";
import { Roles } from "shared/constants";
import env from "environment_system/env_system";

const VesselCard = ({ Vessels }: { Vessels: any[] }) => {
    /** State variables start */
    const [flagError, setFlagError] = useState<Array<any>>([]);
    const dispatch = useDispatch();
    const [hoverId, setHoverId] = useState<string | null>(null);
    /** State variables end */

    /**
     * Returns type of image based on vessel type
     *
     * @param type
     * @returns string
     */
    const vesselType = (type: string) => {
        switch (type) {
            case "Tanker":
                return Tanker;
            case "Bulk Carrier":
                return BulkCarrier;
            case "General Cargo Ship":
                return Cargo;
            case "Gas Carrier":
                return GasCarrier;
            case "Container":
                return Container;
            case "LNG Carrier":
                return LNGCarrier;
            case "RoRo Cargo VC":
            case "RoRo Cargo Ship":
                return RoRo;
            default:
                return "";
        }
    };

    /** Set global Vessel ID */
    const handleVesselSelectionChanges = (e: any) => {
        dispatch(setVesselID(e));
    };

    /** Scroll functions */
    const scrollLeft = () => {
        const container = document.querySelector(".scroll-container");
        container.scrollLeft -= 675;
    };

    const scrollRight = () => {
        const container = document.querySelector(".scroll-container");
        container.scrollLeft += 675;
    };

    /** Function to scroll using mouse wheel */
    document
        ?.querySelector(".scroll-container")
        ?.addEventListener("wheel", function (e: any) {
            e.preventDefault();
            const container = document.querySelector(".scroll-container");
            container.scrollLeft += e.deltaY;
        });
    /** Scroll functions end */

    Vessels.forEach((vessel: any) => {
        let arr: any[] = flagError;
        arr.push({
            id: vessel?.vessel_id,
            error: false
        })
    })

    return (
        <>
            <div className="scroll-container p-0">
                {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                    <i
                        className="bx bx-chevron-left chevron left-chevron"
                        onClick={scrollLeft}
                    />
                )}
                {Vessels && Vessels?.map((vessel: any, index: number) => {
                    return (
                        <Card
                            key={index}
                            className="rounded-4 border-secondary vessel-card card mb-0"
                        >
                            <div className="card-header bg-transparent border-secondary vessel-card-height">
                                <h4
                                    className="my-0 text-primary text-center overflow-hidden"
                                    id={`vessel-name-${vessel?.vessel_id}`}
                                >
                                    {vessel?.vessel_name}
                                </h4>
                                {vessel?.vessel_name?.toString()?.length > 25 && (
                                    <ToolTip
                                        target={`vessel-name-${vessel?.vessel_id}`}
                                        message={vessel?.vessel_name}
                                    />
                                )}
                            </div>
                            <CardBody className="vessel-card-height mb-0">
                                <Row>
                                    <Col sm={3}>
                                        <ToolTip
                                            message={`Port of registry : ${vessel?.vessel_port_of_registry ?? "-"
                                                } | ${vessel?.country_name ?? "-"}`}
                                            target={`flag-${vessel?.vessel_id}`}
                                        />
                                        <img
                                            alt={flagError.filter((item: any) => item?.id === vessel?.vessel_id)[0]?.error ? '' : 'Flag'}
                                            src={`${env?.base_url}/country_master/get_country_image/?country_name=${vessel?.country_name}`}
                                            height="25"
                                            className="mb-0 mt-0 border"
                                            id={`flag-${vessel?.vessel_id}`}
                                            onError={() => {
                                                setFlagError((prev) =>
                                                    prev.map((item) =>
                                                        item.id === vessel?.vessel_id ? { ...item, error: true } : item
                                                    )
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <h5 className="card-title">{vessel?.vessel_type}</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <img
                                        alt={vessel?.vessel_type}
                                        src={vesselType(vessel?.vessel_type)}
                                        height="75"
                                        className="mb-3"
                                    />
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="mt-2">
                                            <Col>
                                                <Label className="card-title vessel-card-details mb-0">
                                                    IMO:{" "}
                                                    {vessel?.imo_number === undefined
                                                        ? "-"
                                                        : vessel?.imo_number}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="ele_row1">
                                                <Label className="card-title vessel-card-details mb-0">
                                                    DWT:{" "}
                                                    {vessel?.vessel_dead_weight === undefined
                                                        ? "-"
                                                        : vessel?.vessel_dead_weight}
                                                </Label>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col className="ele_row1">
                                                <Label className="card-title vessel-card-details mb-0">
                                                    EUA:{" "}
                                                    {vessel?.vessel_dead_weight === undefined ? "-" : 0}
                                                </Label>
                                            </Col>
                                        </Row> */}
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        {vessel?.cii_category === null ? (
                                            <div
                                                className="card-height-3-8 ele_row bg-light bg-gradient rating-height width-100"
                                                id="null_rating"
                                            >
                                                <ToolTip
                                                    target="null_rating"
                                                    message="Yet to be calculated"
                                                />
                                                <i className="bx bx-minus icon_s23 pos-center pos-middle" />
                                            </div>
                                        ) : (
                                            <div
                                                className={`CII-rating-${vessel?.cii_category} card-height-3-8 ele_row width-100`}
                                            >
                                                <ToolTip
                                                    target="rating"
                                                    message="Projected CII rating"
                                                />
                                                <Label
                                                    className="align-self-center wid-data"
                                                    id="rating"
                                                >
                                                    {vessel?.cii_category}
                                                </Label>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                                    <Row className="mt-2">
                                        <div className="ele_row">
                                            <div
                                                className="vessel-card-footer bg-light bg-secondary mt-1_2rem rounded-3"
                                                id={`CII_Assessment_${vessel?.vessel_id}`}
                                                onMouseEnter={() => setHoverId(`CII_Assessment_${vessel?.vessel_id}`)}
                                                onMouseLeave={() => setHoverId(null)}
                                                onClick={() => setHoverId(null)}
                                            >
                                                <Link
                                                    to="/cii_assessment"
                                                    className="color-black d-flex justify-content-center"
                                                    onClick={() =>
                                                        handleVesselSelectionChanges(vessel?.vessel_id)
                                                    }
                                                >
                                                    CII
                                                </Link>
                                            </div>
                                            <div>
                                                <strong className="mb-0 d-flex justify-content-center">
                                                    IMO
                                                </strong>
                                                <div
                                                    className="vessel-card-footer bg-light bg-secondary rounded-3"
                                                    id={`IMO_DCS_${vessel?.vessel_id}`}
                                                    onMouseEnter={() => setHoverId(`IMO_DCS_${vessel?.vessel_id}`)}
                                                    onMouseLeave={() => setHoverId(null)}
                                                    onClick={() => setHoverId(null)}
                                                >
                                                    <Link
                                                        to="/imo_dcs"
                                                        className="color-black d-flex justify-content-center"
                                                        onClick={() =>
                                                            handleVesselSelectionChanges(vessel?.vessel_id)
                                                        }
                                                    >
                                                        DCS
                                                    </Link>
                                                </div>
                                            </div>
                                            <div>
                                                <strong className="mb-0 d-flex justify-content-center">
                                                    EU
                                                </strong>
                                                <div
                                                    className="vessel-card-footer bg-light bg-secondary rounded-3"
                                                    id={`MRV_${vessel?.vessel_id}`}
                                                    onMouseEnter={() => setHoverId(`MRV_${vessel?.vessel_id}`)}
                                                    onMouseLeave={() => setHoverId(null)}
                                                    onClick={() => setHoverId(null)}
                                                >
                                                    <Link
                                                        to="/eu_mrv_ets"
                                                        className="color-black d-flex justify-content-center"
                                                        onClick={() =>
                                                            handleVesselSelectionChanges(vessel?.vessel_id)
                                                        }
                                                    >
                                                        MRV
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    );
                })}
                {hoverId !== null &&
                    <ToolTip
                        target={hoverId}
                        message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                        isOpen={hoverId !== null}
                    />
                }
                {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                    <i
                        className="bx bx-chevron-right chevron right-chevron"
                        onClick={scrollRight}
                    />
                )}
            </div>
        </>
    );
};

export default VesselCard;
