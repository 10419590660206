import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { userAllowedRoutes } from './allRoutes';
import '../src/assets/scss/app.scss';
import '../src/assets/scss/bootstrap.scss';
import '../src/assets/scss/icons.scss';
import '../src/assets/scss/preloader.scss';
import '../src/assets/scss/theme.scss';
import Login from 'Login/Login';
import { getAuthData, getAuthSessionData, isLoggedIn } from 'utils/auth/authUtils';
import Logout from 'Login/Logout';
import env from 'environment_system/env_system';
import { useEffect, useState } from 'react';
import PageNotFound from 'Components/PageNotFound';
import React from 'react';
import ForcedPasswordChange from 'Users/ForcedPasswordChange';
import Dashboard from 'Dashboard/Dashboard';

function App() {
  const [showMessage, setShowMessage] = useState(true);
  const delay = 5000;
  const enforcedPasswordChangeLocal = `${getAuthData("enforced_password_change")}`;
  const enforcedPasswordChangeSession = `${getAuthSessionData("enforced_password_change")}`;


  useEffect(() => {
    const timer = setTimeout(() => setShowMessage(false), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  let location = useLocation();
  let currentRoute: any;

  if (isLoggedIn() === false && location.pathname !== '/login') {
    return <Navigate to="/login" />
  }

  currentRoute = userAllowedRoutes.find(route => {
    if (route.path === location.pathname) {
      return true
    }
    const regex = new RegExp(route.path.replace(':id', '(\\d+)'));
    return regex.test(location.pathname);
  });
  if (!currentRoute && userAllowedRoutes.length > 0 && location.pathname !== '/login' && location.pathname !== '/logout') {
    return (
      <React.Fragment>
        {showMessage ? (
          <PageNotFound />
        ) : (
          <Navigate to="/dashboard" />
        )}
      </React.Fragment>
    );
  }

  return (
    <div>
      {(enforcedPasswordChangeLocal === 'true' && enforcedPasswordChangeSession === 'true' &&
        location.pathname !== '/dashboard') ?
        <React.Fragment>
          {showMessage ? (
            <PageNotFound />
          ) : (
            <Navigate to="/dashboard" />
          )}
        </React.Fragment>
        :
        <Routes>
          {/* Static Routes */}
          <Route path={"/login"} element={<Login />} key={'login'} />
          <Route path={"/logout"} element={<Logout />} key={'logout'} />
          <Route path={"/"} element={<Navigate to="/login" />} key={'login-default'} />
          {/* Dashboard Route with Forced Password Reset Check */}
          <Route
            path={"/dashboard"}
            element={
              enforcedPasswordChangeLocal === 'true' && enforcedPasswordChangeSession === 'true' ? (
                <div className="d-flex justify-content-center align-items-center vh-100">
                  <ForcedPasswordChange message="Please create your new password." />
                </div>
              ) : (
                <Dashboard />
              )
            }
            key={'dashboard'}
          />
          {/* Dynamic Routes based on userAllowedRoutes */}
          {userAllowedRoutes && userAllowedRoutes.map((route, idx) => {
            if (route.component) {
              return (
                <Route
                  path={route.path}
                  element={
                    isLoggedIn() ? (
                      route.component
                    ) : (
                      <Navigate to={env.desktop_app === true ? "/#login" : "/login"} />
                    )
                  }
                  key={idx}
                />
              );
            }
            return null; // Ensure no undefined components are rendered
          })}
        </Routes>
      }
    </div>
  );
}

export default App;
