import React, { useEffect, useState } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { getVoyagesAction, setVoyageID } from "../Store/Generic/ReportingSlice";
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import VoyageTableHeadComponent from './VoyageTableHeadComponent';
import PageNavButton from 'Dashboard/PendingFiles/PageNavButton';
import { GetPageWithID } from 'Dashboard/PendingFiles/pendingpage.hook';
import PopUp from 'Components/PopUp';
import CompletedReports from 'VesselReportingInfo/CompletedReports';

const PreviousVoyages = () => {
    const dispatch = useDispatch();
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [pageUrl, setPageUrl] = useState<string>('');
    const [state, setState] = useState<boolean>(false)
    const [voyageNumber, setVoyageNumber] = useState<boolean>(null)

    useEffect(() => {
        setPageUrl(queryKeyes.pagination.VoyageListPage.url(VesselID));
    }, [VesselID])

    useEffect(() => {
        dispatch(getVoyagesAction('voyage_information' as string));
    }, [dispatch]);


    const { data: voyages, isFetching: voyagesFetching, isError: voyagesError } = useQuery(
        [queryKeyes.pagination.VoyageListPage.key, pageUrl, VesselID],
        async () => await GetPageWithID(VesselID, pageUrl),
        {
            enabled: true,
            staleTime: Infinity,
        }
    )
    return (
        <>
            <React.Fragment>
                <div className='ele_row mb-4'>
                    <h4 id='owner_table'>Voyage Reporting Information</h4>
                </div>
                <CardBody className='p-0'>
                    <div className="table-responsive" id='owner_table'>
                        {(voyagesFetching) && (
                            <Loading message='Voyage list is loading!' />
                        )}
                        {voyagesError && (
                            <ErrorComponent message='Unable to load voyage list!' />
                        )}
                        {(!voyagesFetching && !voyagesError) &&
                            <table className="table mb-0">
                                <VoyageTableHeadComponent />
                                <tbody>
                                    {(voyagesFetching) && (
                                        <tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <Loading message='Loading required data!' />
                                                </td>
                                            </tr>
                                        </tr>
                                    )}
                                    {voyagesError && (
                                        <tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <ErrorComponent message='Unable to load required data!' />
                                                </td>
                                            </tr>
                                        </tr>
                                    )}
                                    {voyages?.results?.length > 0 ? (
                                        voyages.results.map((voyage: any, index: number) => (
                                            <tr key={voyage.id}>
                                                <td className="p-2 align-middle text-center">{index + 1}</td>
                                                <td className="p-2 align-middle">{voyage.voyage_number}</td>
                                                <td className="p-2 align-middle text-left">Finished</td>
                                                <td className="p-2 align-middle text-left">{voyage?.departure_date_time?.toString()?.substring(0, 10)} {voyage?.departure_date_time?.toString()?.substring(11, 16)}</td>
                                                <td className="p-2 align-middle text-center">
                                                    <Button
                                                        color='primary'
                                                        onClick={() => {
                                                            setState(true);
                                                            setVoyageNumber(voyage.voyage_number);
                                                            dispatch(setVoyageID(voyage.id));
                                                        }}
                                                    >View Report</Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={8}>
                                                <p className='text-center mb-0'>No previous voyages available</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        {voyages?.results?.length > 0 &&
                                            <td colSpan={5} className="p-2 ">
                                                <PageNavButton setPageUrl={setPageUrl} pageobj={voyages} pageUrl={pageUrl} />
                                            </td>
                                        }
                                    </tr>
                                </tfoot>
                            </table>
                        }
                    </div>
                </CardBody>
                <PopUp
                    state={state}
                    setState={setState}
                    body={
                        <CompletedReports
                            isPreviousVoyage={true}
                        />
                    }
                    title={`Voyage: ${voyageNumber}`}
                    size="lg"
                />
            </React.Fragment>
        </>
    )
}

export default PreviousVoyages