import env from 'environment_system/env_system';
import React, { useState, useMemo } from 'react';
import { Button, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import { Roles, VesselTypeConstant } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import apiGlobal from 'global/api.global';
import { errorToast, successToast } from './Toasts';
import { queryClient } from 'react-query/queryClient';
import { queryKeyes } from 'shared/queryKeys';
import { errResponse } from 'GenericForms/Helper';
import ToolTip from './ToolTip';
import { useQuery } from 'react-query';
import { loadVesselMaster } from 'VesselMaster/vesselMaster.hooks';

interface NavigationButtonsProps {
    activeTab: number;
    toggleTab: (tab: number) => void;
    isSubmitting: boolean;
    formId?: number;
    form?: string;
    port?: number;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({ activeTab, toggleTab, isSubmitting, formId }) => {
    const { Vessels, VesselID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [verifySubmit, setVerifySubmit] = useState<boolean>(false);
    const [verifyTooltip, setVerifyTooltip] = useState<boolean>(false);

    const isLastTab = useMemo(() => {
        return (
            (activeTab === 5 && vessel?.vessel_type !== VesselTypeConstant.LNG_CARRIER) ||
            (activeTab === 6 && vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
        );
    }, [activeTab, vessel?.vessel_type]);

     // eslint-disable-next-line @typescript-eslint/no-unused-vars
     const {data:vesselMasterObj}= useQuery(
        [queryKeyes.masters.VesselMaster.key, VesselID],
        async () => {
            return await loadVesselMaster();
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    return (
        <Row className="ele_row1 pe-0">
            <div>
                {isLastTab && hasRole(Roles.ES_ADMIN) ? (
                    <div className="d-flex flex-wrap">
                        <div className='pos-end d-block b-flex justify-content-between w-10'>
                            {/* Save Button */}
                            <Button
                                type="submit"
                                color="primary"
                                className={`btn_size_cstm pos-end ${isSubmitting && 'disabled'}`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting && <i className="spinner-border spinner-border-sm text-light me-2" />}
                                Save
                            </Button>
                            {/* Verify Button */}
                            <span
                                id="verify_btn_span"
                                className="d-inline-block"
                                onMouseEnter={() => setVerifyTooltip(true)}
                                onMouseLeave={() => setVerifyTooltip(false)}
                            >
                                <Button
                                    type="submit"
                                    id="verify_btn"
                                    className="btn_size_cstm "
                                    color="primary"
                                    onClick={async () => {
                                        setVerifySubmit(true);
                                        apiGlobal
                                            .post('verify_onboarding_report/', { vessel_id: VesselID })
                                            .then(async (res: any) => {
                                                if (res.status === 200) {
                                                    successToast("Data saved successfully!");
                                                    await queryClient.invalidateQueries(queryKeyes.masters.VesselMaster.key);
                                                    setVerifySubmit(false);
                                                }
                                            })
                                            .catch(err => {
                                                if (errResponse.includes(err.response.status)) {
                                                    errorToast("Internal error occured, please contact the admin");
                                                    setVerifySubmit(false);
                                                }
                                            });
                                        await queryClient.invalidateQueries(queryKeyes.masters.VesselMaster.key);
                                    }}
                                    disabled={verifySubmit || !(formId && formId > 0)}
                                >
                                    {verifySubmit && <i className="spinner-border spinner-border-sm text-light me-2" />} Verify
                                </Button>
                            </span>
                        </div>
                        {verifyTooltip && (
                            <ToolTip target="verify_btn_span" message={'Please save the form before verifying.'} isOpen={verifyTooltip} />
                        )}
                    </div>
                ) : (
                    <React.Fragment>
                        <Button
                            type="submit"
                            color="primary"
                            style={{ order: 3 }}
                            className={`${isSubmitting && 'disabled'} btn_size_cstm pos-end`}
                            disabled={isSubmitting}
                            onClick={() => {
                                if (env.form_validation === false) {
                                    toggleTab(activeTab + 1);
                                }
                            }}
                        >
                            {isSubmitting && (
                                <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                            )}
                            Next <i className="bx bx-chevron-right ms-1" />
                        </Button>
                    </React.Fragment>
                )}

                {/* Previous Button */}
                <Button
                    type="button"
                    color="primary"
                    className={`btn_size_cstm ${isLastTab ? 'ms-0' : ''}`}
                    onClick={() => toggleTab(activeTab - 1)}
                    disabled={activeTab === 1}
                >
                    <i className="bx bx-chevron-left me-1" /> Previous
                </Button>
            </div>
        </Row>

    );
};

export default NavigationButtons;
