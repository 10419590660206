import { useFormik } from 'formik';
import { RootState } from 'index';
import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';

interface VesselFuelTableType {
  fuel_class_precedence_id: number;
  fuel_class_name: string;
  refreshKey?: number;
  formik: ReturnType<typeof useFormik>;
  refreshForm: boolean;
}

const VesselFuelTable = ({
  fuel_class_precedence_id,
  fuel_class_name,
  refreshKey,
  formik,
  refreshForm
}: VesselFuelTableType) => {
  /** State variables start */
  const { VesselID } = useSelector((state: RootState) => state.Reporting);
  const { values, handleBlur, setFieldValue } = formik;
  /** State variables end */

  return (
    <div className="table-responsive p-0">
      <table className="table mb-2" key={`${refreshForm}-${VesselID}`}>
        <thead className="table-light">
          <tr>
            <th className="p-2 align-middle sr-no-width">#</th>
            <th className="p-2 align-middle">{fuel_class_name}</th>
          </tr>
        </thead>
        <tbody>
          {(() => {
            const filteredFuels = values?.vesselFuels?.filter(
              (item: any) => item.fuel_class_precedence_id === fuel_class_precedence_id
            );
            return (
              <React.Fragment key={refreshKey}>
                {filteredFuels && filteredFuels.length > 0 ? (
                  filteredFuels.map(
                    (
                      value: {
                        [key: string]: string | number | boolean | Date | null;
                      },
                      index: number
                    ) => {
                      const fieldIndex = values?.vesselFuels.indexOf(value);
                      return (
                        <tr key={index}>
                          <td>
                            <Input
                              type="checkbox"
                              id={`selected_${fieldIndex}`}
                              name={`vesselFuels.${fieldIndex}.selected`}
                              onBlur={handleBlur}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue(
                                  `vesselFuels.${fieldIndex}.selected`,
                                  e.target.checked
                                )
                              }}
                              defaultChecked={value?.selected as boolean}
                            />
                          </td>
                          <td>
                            <Label className="mb-0" htmlFor={`selected_${fieldIndex}`}>
                              {value?.fuel_type_name as string}
                            </Label>
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <tr>
                    <td colSpan={2}>No fuels available for the selected category</td>
                  </tr>
                )}
              </React.Fragment>
            );
          })()}
        </tbody>
      </table>
    </div>
  );
};

export default VesselFuelTable;
