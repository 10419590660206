import React, { useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Modal,
    Row,
} from "reactstrap";
import apiGlobal from "global/api.global";
import { errorToast, successToast } from "Components/Toasts";
import { arraySubtract } from "GenericForms/Helper";
import { customStyle } from "../../../shared/CommonCSS";
import { RootState } from "index";
import { useSelector } from "react-redux";
import { AdjustmentApprovalStatus, CIIAdjustmentEventBeginningEnd } from "shared/constants";
import { queryClient } from "react-query/queryClient";
import { queryKeyes } from "shared/queryKeys";
import { ActionMeta, MultiValue } from "react-select";
import Select from "react-select";
import { useQuery } from "react-query";

interface CIIAdjustmentModalType {
    state: boolean,
    setState: (value: boolean) => void,
    ongoingEvents: { [key: string]: string | number | Date | boolean }[],
    record: { [key: string]: string | number | Date | boolean },
    loadCIIAdjustments: (value: number) => Promise<{ [key: string]: string | number | Date | boolean }[]>,
    reportId: number,
    vesselId: number,
    adjustmentEvents: { [key: string]: string | number | Date | boolean }[],
    setRefreshKey: (value: number) => void,
    refreshKey: number,
}
const CIIAdjustmentModal = ({
    state,
    setState,
    ongoingEvents,
    record,
    loadCIIAdjustments,
    reportId,
    vesselId,
    adjustmentEvents,
    setRefreshKey,
    refreshKey
}: CIIAdjustmentModalType) => {
    const { VesselID, Vessels } = useSelector(
        (state: RootState) => state.Reporting
    );
    let vessel = Vessels.find((rec: { [key: string]: string | number | Date | boolean }) => rec.id === VesselID) ?? null;
    let [CIIData, setCIIData] = useState<Array<{ [key: string]: string | number | Date | boolean }>>([]);
    const [selectedOptions, setSelectedOptions] = useState<Array<{ [key: string]: string | number | Date | boolean }>>([]);

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setState(!state);
        removeBodyCss();
    }

    const loadCIIAdjustmentOptions = async () => {
        try {
            const response = await apiGlobal.get(queryKeyes.masters.CIIAdjustmentEvent.url());
            if (vessel?.ice_class === "NA") {
                response.data = response.data.filter((item: { [key: string]: string | number | Date | boolean }) => item.id !== 8);
            }
            response.data = response.data.filter((item: { [key: string]: string | number | Date | boolean }) =>
                item.precedence_id === CIIAdjustmentEventBeginningEnd.BEGINNING);
            if (ongoingEvents?.length > 0) {
                let subtractedArray = arraySubtract(response.data, ongoingEvents);
                return arraySubtract(subtractedArray, adjustmentEvents);
            }
            return response.data;
        } catch (error) {
            console.error(error);
        }
    };
    /**
     * Load CII Adjustment Options
     */
    const { data: cii_adjustment_options } = useQuery(
        [queryKeyes.masters.CIIAdjustmentEvent.key, ongoingEvents],
        async () => await loadCIIAdjustmentOptions(),
        {
            staleTime: Infinity,
        }
    );

    const handleChange = (options: MultiValue<{ [key: string]: string | number | Date | boolean }>,
        actionMeta: ActionMeta<{ [key: string]: string | number | Date | boolean }>) => {
        setSelectedOptions([...options ?? []]);
        if (actionMeta.action === "remove-value") {
            const indexToRemove = CIIData.findIndex(
                (item: { [key: string]: string | number | Date | boolean }) =>
                    item.cii_adjustment_begin_event === actionMeta.removedValue.id
            );
            if (indexToRemove !== -1) {
                const updatedData = [...CIIData];
                updatedData.splice(indexToRemove, 1);
                setCIIData(updatedData);
            }
        }
        if (actionMeta.action === "select-option") {
            setCIIData((prev) => [
                ...prev,
                {
                    user_approved: AdjustmentApprovalStatus.APPROVED,
                    class_approved: false,
                    cii_begin_date_time: record.local_time,
                    cii_end_date_time: null,
                    cii_adjustment_status: true,
                    cii_begin_report: reportId,
                    cii_adjustment_begin_event: actionMeta.option.id,
                    cii_end_report: null,
                    cii_adjustment_end_event: null,
                    cii_adjustment: actionMeta.option.cii_adjustment,
                    file_upload_status: false,
                    vessel: vesselId,
                },
            ]);
        }
    };

    /** to reset selected CII adjustment event */
    const handleReset = () => {
        setCIIData([]);
        setSelectedOptions([]);
    };

    const CIIAdjustmentSubmit = async () => {
        try {
            if (CIIData[0]?.cii_adjustment) {
                setEventSave(true);
                const response = await apiGlobal.post(
                    `/vessel_reporting_cii_adjustment/`,
                    CIIData
                );
                if (response.status === 200 || response.status === 201) {
                    setEventSave(false);
                    setCIIData([]);
                    setSelectedOptions([]);
                    loadCIIAdjustments(VesselID);
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingCIIAdjusment.key)
                    setRefreshKey(refreshKey + 1)

                    setState(false);
                }
                return response.data;
            }
        } catch (error) {
            console.error(error);
            errorToast("Error. Unable to save!");
            setEventSave(false);
            return null;
        }
    };
    const [eventSave, setEventSave] = useState(false);
    return (
        <Modal
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    Start New Event
                </h5>
                <button
                    onClick={() => {
                        setState(false);
                        setEventSave(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body p-0 m-0">
                <Card className="m-0">
                    <CardBody>
                        <Form
                            className="needs-validation"
                            onSubmit={(e) => {
                                e.preventDefault();
                                CIIAdjustmentSubmit();
                                return false;
                            }}
                        >
                            <Row>
                                <FormGroup className="form-floating mb-3">
                                    <div className="mb-3">
                                        <Label className="asteric" for="cii_adjustment">
                                            Select CII adjustment event
                                        </Label>
                                        <Select
                                            name="cii_adjustment"
                                            inputId="cii_adjustment"
                                            options={cii_adjustment_options}
                                            getOptionLabel={(e) => e.adjustment_event_name as string}
                                            getOptionValue={(e) => e.id as string}
                                            onChange={handleChange}
                                            value={selectedOptions}
                                            isMulti
                                            menuPortalTarget={document.querySelector(
                                                ".MuiDialog-root"
                                            )} // fix for the modelbox
                                            styles={customStyle}
                                        />
                                    </div>
                                </FormGroup>
                            </Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size4_5_cstm"
                                    disabled={eventSave}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                    onClick={handleReset}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
};

export default CIIAdjustmentModal;
