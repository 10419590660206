import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { Field, FieldArray, Formik } from 'formik';
import env from 'environment_system/env_system';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, Input, Label, Row } from 'reactstrap';
import { VesselMachineryConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadEconMEAdditionalObject, loadEconPrevMEExhaustUnits, loadVesselMachineriesByMachineType } from 'VesselMaster/vesselMaster.hooks';
import apiGlobal from 'global/api.global';
import { handleServerResponse } from 'GenericForms/Helper';
import * as Yup from "yup";
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ErrorTooltip from 'Components/ErrorTooltip';
import { queryClient } from 'react-query/queryClient';

interface MainEngineComponentType {
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    toggleAddTab: (value: number) => void,
    toggleDynamicTabs: (value: number) => void,
    previousTabIndex: number,
    setErrorMessage: any,
}

const MainEngineComponent = ({
    VesselID,
    VoyageID,
    ReportID,
    toggleAddTab,
    toggleDynamicTabs,
    previousTabIndex,
    setErrorMessage,
}: MainEngineComponentType) => {
    /** State variables start */
    const [refreshKey, setRefreshKey] = useState(0);
    const [tableRefreshKey, setTableRefreshKey] = useState(0);
    const [exhaustUnits, setExhaustUnits] = useState<Array<number>>([0]);
    /** State variables end */

    /** useQueries */
    /** Load Vessel's Main Engines */
    const {
        data: mainEngines,
        isLoading: mainEnginesLoading,
        isError: mainEnginesError,
    } = useQuery(
        [queryKeyes.vessel.MEVesselMachineries.key, VesselID],
        async () => {
            return await loadVesselMachineriesByMachineType(VesselID, VesselMachineryConstant.MAIN_ENGINE);
        },
        { staleTime: Infinity }
    );
    /** Load Vessel's Main Engines */
    const {
        data: mainEnginesObject,
        isLoading: mainEnginesObjectLoading,
    } = useQuery(
        [queryKeyes.vessel.EconMEAdditionalObject.key, VesselID, ReportID],
        async () => {
            return await loadEconMEAdditionalObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Load Main engine's no. of exhaust units from previous noon report */
    const {
        data: prevExhaustUnitsObject,
        isLoading: prevExhaustUnitsObjectLoading,
    } = useQuery(
        [queryKeyes.vessel.EconPrevMEExhaustUnits.key, VesselID, ReportID],
        async () => {
            return await loadEconPrevMEExhaustUnits(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** useQueries end */

    /** Assign values to Main engine component's initial object */
    const getMEInitialValues = () => {
        let arr: any[] = [];
        if (mainEnginesObject && mainEnginesObject?.length > 0) {
            return mainEnginesObject;
        } else {
            if (mainEngines && mainEngines?.length > 0) {
                mainEngines?.forEach((engine: any) => {
                    arr.push({
                        additionalInfo: getAdditonalInfoInitialValues(engine?.id, engine?.vessel_machinery_name),
                        exhaustUnits: getExhausUnitInitialValues(engine?.id, engine?.vessel_machinery_name),
                        turboCharger: getTurbochargerInitialValues(engine?.id, engine?.vessel_machinery_name),
                        airCooler: getAirCoolerInitialValues(engine?.id, engine?.vessel_machinery_name),
                        exhaustGasEconomiser: getExhaustGasEconomiserInitialValues(engine?.id, engine?.vessel_machinery_name),
                    });
                });
            }
            return arr;
        }
    }

    /** Assign values to Additional informations's initial object */
    const getAdditonalInfoInitialValues = (machine: number, machineName: string) => {
        return ({
            me_load: null as number,
            me_fuel_rack: null as number,
            me_jw_temperature_inlet: null as number,
            me_jw_temperature_outlet: null as number,
            me_lo_temperature_inlet: null as number,
            me_lo_temperature_outlet: null as number,
            me_jw_in_pressure: null as number,
            me_lo_in_pressure: null as number,
            vessel_machinery: machine,
            vessel_machinery_name: machineName,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
        });
    }

    /** Assign values to Exhaust unit's initial object */
    const getExhausUnitInitialValues = (machine: number, machineName: string) => {
        let arr: any[] = [];
        for (let i = 0; i < 7; i++) {
            arr.push({
                no_of_exhaust_units: 7,
                exhaust_unit: `Unit ${i + 1}`,
                unit_temperature: null as number,
                vessel_machinery: machine,
                vessel_machinery_name: machineName,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            });
        }
        return arr;
    }

    /** Assign values to Turbocharger's initial object */
    const getTurbochargerInitialValues = (machine: number, machineName: string) => {
        let arr: any[] = [];
        arr.push({
            turbo_charger: 'Turbo Charger 1',
            gas_temperature_in: null as number,
            gas_temperature_out: null as number,
            gas_press_drop: null as number,
            tc_oil_temp_inlet: null as number,
            tc_oil_temp_outlet: null as number,
            tc_oil_inlet_pressure: null as number,
            tch_speed: null as number,
            vessel_machinery: machine,
            vessel_machinery_name: machineName,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
        })
        return arr;
    }

    /** Assign values to Air Cooler's initial object */
    const getAirCoolerInitialValues = (machine: number, machineName: string) => {
        let arr: any[] = [];
        arr.push({
            air_cooler: 'Air Cooler 1',
            air_cooler_cw_in_temp: null as number,
            me_lo_temp_outlet: null as number,
            air_side_press_drop: null as number,
            me_scav_air_temp: null as number,
            me_scav_air_pressure: null as number,
            eng_room_temp: null as number,
            sea_water_temperature: null as number,
            vessel_machinery: machine,
            vessel_machinery_name: machineName,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
        })
        return arr;
    }

    /** Assign values to Exhaust Gas Economiser's initial object */
    const getExhaustGasEconomiserInitialValues = (machine: number, machineName: string) => {
        return ({
            exh_gas_in_temp: null as number,
            exh_gas_out_temp: null as number,
            steam_pressure: null as number,
            hot_well_temp: null as number,
            vessel_machinery: machine,
            vessel_machinery_name: machineName,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
        })
    }

    /** useEffect */
    // useEffect(() => {
    //     let arr: any[] = [];
    //     if (mainEnginesObject && mainEnginesObject?.length > 0) {
    //         mainEnginesObject.forEach((value: any) => {
    //             arr.push((mainEnginesObject && mainEnginesObject?.length > 0) ?
    //                 value?.exhaustUnits[0]?.no_of_exhaust_units :
    //                 (prevExhaustUnitsObject && prevExhaustUnitsObject?.length > 0) ?
    //                     prevExhaustUnitsObject?.filter((unit: any) => unit?.vessel_machinery === value?.exhaustUnits[0]?.vessel_machinery)[0]?.no_of_exhaust_units :
    //                     null);
    //         })
    //     } else if (mainEngines && mainEngines?.length > 0) {
    //         arr.push((prevExhaustUnitsObject && prevExhaustUnitsObject?.length > 0) ?
    //             prevExhaustUnitsObject[0]?.no_of_exhaust_units : null)
    //     }
    //     setExhaustUnits(arr);
    //     setRefreshKey(refreshKey + 1);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [mainEnginesObject, prevExhaustUnitsObject])
    useEffect(() => {
        MainEngineFormik.initialValues = getMEInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainEnginesObject, mainEngines, prevExhaustUnitsObject])
    /** useEffect end */

    /** Main engine addtional info's formik object */
    const MainEngineFormik = {
        initialValues: getMEInitialValues(),
        validationSchema:
            Yup.array(
                Yup.object().shape({
                    additionalInfo: Yup.object().shape({
                        me_load: Yup.string()
                            .matches(
                                /^\d{0,3}(\.\d{1,4})?$/,
                                `${commonValidationMessages.before3after4}`
                            )
                            .test('max-value', `${commonValidationMessages.max115}`, (value) => {
                                if (value === null || value === '') return true;
                                const numericValue = parseFloat(value);
                                return numericValue <= 115;
                            })
                            .nullable(),
                        me_fuel_rack: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                        me_jw_temperature_inlet: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                        me_jw_temperature_outlet: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                        me_lo_temperature_inlet: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                        me_lo_temperature_outlet: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                        me_jw_in_pressure: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                        me_lo_in_pressure: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                    }),
                    exhaustUnits: Yup.array(
                        Yup.object().shape({
                            no_of_exhaust_units:
                                Yup.number()
                                    .integer(commonValidationMessages.integer).positive(commonValidationMessages.integer).min(1, "Please enter a value between 1 and 24.")
                                    .max(24, "Please enter a value between 1 and 24.").nullable(),
                            unit_temperature: Yup.string().matches(/^\d{0,4}(\.\d{1,4})?$/, `${commonValidationMessages.before4after4}`).nullable(),
                        })
                    ),
                    turboCharger: Yup.array(
                        Yup.object().shape({
                            gas_temperature_in: Yup.string().matches(/^\d{0,4}(\.\d{1,4})?$/, `${commonValidationMessages.before4after4}`).nullable(),
                            gas_temperature_out: Yup.string().matches(/^\d{0,4}(\.\d{1,4})?$/, `${commonValidationMessages.before4after4}`).nullable(),
                            gas_press_drop: Yup.string().matches(/^\d{0,4}(\.\d{1,4})?$/, `${commonValidationMessages.before4after4}`).nullable(),
                            tc_oil_temp_inlet: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                            tc_oil_temp_outlet: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                            tc_oil_inlet_pressure:
                                Yup.string().matches(/^-?[0-9]{0,2}(\.[0-9]{1,4})?$/, `${commonValidationMessages.before2after4}`).nullable(),
                            tch_speed: Yup.string().matches(/^\d{1,6}$/, commonValidationMessages.max6).nullable(),
                        })
                    ),
                    airCooler: Yup.array(
                        Yup.object().shape({
                            air_cooler_cw_in_temp: Yup.string().matches(/^-?[0-9]{0,2}(\.[0-9]{1,4})?$/, `${commonValidationMessages.before2after4}`).nullable(),
                            me_lo_temp_outlet: Yup.string().matches(/^-?[0-9]{0,2}(\.[0-9]{1,4})?$/, `${commonValidationMessages.before2after4}`).nullable(),
                            air_side_press_drop: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                            me_scav_air_temp: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                            me_scav_air_pressure: Yup.string().matches(/^-?[0-9]{0,2}(\.[0-9]{1,4})?$/, `${commonValidationMessages.before2after4}`).nullable(),
                            eng_room_temp: Yup.string().matches(/^-?[0-9]{0,2}(\.[0-9]{1,4})?$/, `${commonValidationMessages.before2after4}`).nullable(),
                            sea_water_temperature: Yup.string().matches(/^-?[0-9]{0,2}(\.[0-9]{1,4})?$/, `${commonValidationMessages.before2after4}`).nullable(),
                        })
                    )
                }),

            )
    }

    /** POST request for Main engine */
    const MainEngineSubmit = async (values: any, actions: any) => {
        const responseArray: any = [];
        values?.forEach((value: any) => {
            Object.keys(value).forEach((key: any) => {
                if (Array.isArray(value[key])) {
                    value[key]?.forEach((item: any) => {
                        delete item.vessel_machinery_name;
                    })
                } else {
                    delete value[key]?.vessel_machinery_name;
                }
            });
        })
        if (mainEnginesObject && Object.keys(mainEnginesObject).length > 0) {
            responseArray.push(apiGlobal.put(queryKeyes.vessel.EconMEAdditionalEdit.url(), values))
        } else {
            responseArray.push(apiGlobal.post(queryKeyes.vessel.EconMEAdditionalInfo.url(), values))
        }
        await handleServerResponse(responseArray).then(async (res) => {
            if (res === true) {
                await queryClient.invalidateQueries(queryKeyes.vessel.EconMEAdditionalObject.key);
                if (env?.form_validation === true) {
                    setRefreshKey(refreshKey + 1);
                    setErrorMessage(null);
                    toggleAddTab(2);
                }
            } else {
                setErrorMessage(res)
            }
            actions.setSubmitting(false);
        })
        actions.setSubmitting(false);
    }
    return (
        <React.Fragment>
            {(mainEnginesLoading || mainEnginesObjectLoading || prevExhaustUnitsObjectLoading) && <Loading message='Loading required data!' />}
            {mainEnginesError && <ErrorComponent message='Error loading required data!' />}
            {!(mainEnginesLoading || mainEnginesObjectLoading || prevExhaustUnitsObjectLoading) && !mainEnginesError &&
                <Formik
                    initialValues={MainEngineFormik.initialValues}
                    validationSchema={env?.form_validation === true ? MainEngineFormik.validationSchema : null}
                    onSubmit={(values: any, actions: any) => {
                        if (env?.form_validation === false) {
                            setErrorMessage(null)
                            toggleAddTab(2);
                        }
                        actions.setSubmitting(true);
                        MainEngineSubmit(values, actions);
                    }}
                    key={refreshKey}
                >
                    {(props: any) => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <Card className='p-0 mb-0 border-0'>
                                <CardHeader className='p-2 border-bottom-0'>
                                    <h5>Main engine</h5>
                                </CardHeader>
                                <CardBody className='p-0'>
                                    {props?.values && props?.values?.map((value: any, index: number) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Card className='card-bottom-0 p-0 mb-0'>
                                                    <CardHeader className='p-2'>
                                                        <h6 className='mb-0'>{value?.additionalInfo?.vessel_machinery_name}</h6>
                                                    </CardHeader>
                                                    <CardBody className='px-2 py-0'>
                                                        <Row className='mb-2 mt-2'>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_load_${index}`}>ME load</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additionalInfo.me_load`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`me_load_${index}`}
                                                                                name={`${index}.additionalInfo.me_load`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                }}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additionalInfo?.me_load}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text">% MCR</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo && props?.errors[index]?.additionalInfo.me_load &&
                                                                    <ErrorTooltip
                                                                        target={`me_load_${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_load}
                                                                        open={props?.errors[index]?.additionalInfo?.me_load && props?.touched[index]?.additionalInfo?.me_load}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_fuel_rack${index}`}>ME fuel rack</Label>
                                                                <Field name={`${index}.additionalInfo.me_fuel_rack`}>
                                                                    {() => (
                                                                        <Input
                                                                            type="text"
                                                                            id={`me_fuel_rack${index}`}
                                                                            name={`${index}.additionalInfo.me_fuel_rack`}
                                                                            className="form-cntrol max-width-7 text-right"
                                                                            onChange={(e: any) => {
                                                                                props?.handleChange(e);
                                                                            }}
                                                                            onBlur={(e: any) => {
                                                                                props?.handleChange(e);
                                                                                props?.handleBlur(e);
                                                                            }}
                                                                            defaultValue={value?.additionalInfo?.me_fuel_rack}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo && props?.errors[index]?.additionalInfo.me_fuel_rack &&
                                                                    <ErrorTooltip
                                                                        target={`me_fuel_rack${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_fuel_rack}
                                                                        open={props?.errors[index]?.additionalInfo?.me_fuel_rack && props?.touched[index]?.additionalInfo?.me_fuel_rack}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-2'>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_jw_temperature_inlet_${index}`}>ME J/W temperature inlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additionalInfo.me_jw_temperature_inlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`me_jw_temperature_inlet_${index}`}
                                                                                name={`${index}.additionalInfo.me_jw_temperature_inlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                }}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additionalInfo?.me_jw_temperature_inlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo && props?.errors[index]?.additionalInfo.me_jw_temperature_inlet &&
                                                                    <ErrorTooltip
                                                                        target={`me_jw_temperature_inlet_${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_jw_temperature_inlet}
                                                                        open={props?.errors[index]?.additionalInfo?.me_jw_temperature_inlet && props?.touched[index]?.additionalInfo?.me_jw_temperature_inlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_jw_temperature_outlet_${index}`}>ME J/W temperature outlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additionalInfo.me_jw_temperature_outlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`me_jw_temperature_outlet_${index}`}
                                                                                name={`${index}.additionalInfo.me_jw_temperature_outlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                }}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additionalInfo?.me_jw_temperature_outlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo && props?.errors[index]?.additionalInfo.me_jw_temperature_outlet &&
                                                                    <ErrorTooltip
                                                                        target={`me_jw_temperature_outlet_${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_jw_temperature_outlet}
                                                                        open={props?.errors[index]?.additionalInfo?.me_jw_temperature_outlet && props?.touched[index]?.additionalInfo?.me_jw_temperature_outlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_jw_in_pressure${index}`}>ME J/W pressure inlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additionalInfo.me_jw_in_pressure`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`me_jw_in_pressure${index}`}
                                                                                name={`${index}.additionalInfo.me_jw_in_pressure`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                }}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additionalInfo?.me_jw_in_pressure}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text">bar</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo && props?.errors[index]?.additionalInfo.me_jw_in_pressure &&
                                                                    <ErrorTooltip
                                                                        target={`me_jw_in_pressure${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_jw_in_pressure}
                                                                        open={props?.errors[index]?.additionalInfo?.me_jw_in_pressure && props?.touched[index]?.additionalInfo?.me_jw_in_pressure}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-2'>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_lo_temperature_inlet${index}`}>ME LO temperature inlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additionalInfo.me_lo_temperature_inlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`me_lo_temperature_inlet${index}`}
                                                                                name={`${index}.additionalInfo.me_lo_temperature_inlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                }}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additionalInfo?.me_lo_temperature_inlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo && props?.errors[index]?.additionalInfo.me_lo_temperature_inlet &&
                                                                    <ErrorTooltip
                                                                        target={`me_lo_temperature_inlet${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_lo_temperature_inlet}
                                                                        open={props?.errors[index]?.additionalInfo?.me_lo_temperature_inlet && props?.touched[index]?.additionalInfo?.me_lo_temperature_inlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_lo_temperature_outlet${index}`}>ME LO temperature outlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additionalInfo.me_lo_temperature_outlet`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`me_lo_temperature_outlet${index}`}
                                                                                name={`${index}.additionalInfo.me_lo_temperature_outlet`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                }}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additionalInfo?.me_lo_temperature_outlet}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.me_lo_temperature_outlet &&
                                                                    <ErrorTooltip
                                                                        target={`me_lo_temperature_outlet${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_lo_temperature_outlet}
                                                                        open={props?.errors[index]?.additionalInfo?.me_lo_temperature_outlet && props?.touched[index]?.additionalInfo?.me_lo_temperature_outlet}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={3}>
                                                                <Label className="mb-0" htmlFor={`me_lo_in_pressure${index}`}>ME LO pressure inlet</Label>
                                                                <div className='input-group'>
                                                                    <Field name={`${index}.additionalInfo.me_lo_in_pressure`}>
                                                                        {() => (
                                                                            <Input
                                                                                type="text"
                                                                                id={`me_lo_in_pressure${index}`}
                                                                                name={`${index}.additionalInfo.me_lo_in_pressure`}
                                                                                className="form-cntrol max-width-7 text-right"
                                                                                onChange={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                }}
                                                                                onBlur={(e: any) => {
                                                                                    props?.handleChange(e);
                                                                                    props?.handleBlur(e);
                                                                                }}
                                                                                defaultValue={value?.additionalInfo?.me_lo_in_pressure}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <div className="input-group-text">bar</div>
                                                                </div>
                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.additionalInfo?.me_lo_in_pressure &&
                                                                    <ErrorTooltip
                                                                        target={`me_lo_in_pressure${index}`}
                                                                        message={props?.errors[index]?.additionalInfo?.me_lo_in_pressure}
                                                                        open={props?.errors[index]?.additionalInfo?.me_lo_in_pressure && props?.touched[index]?.additionalInfo?.me_lo_in_pressure}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Card className='card-bottom-0 p-0 mb-0'>
                                                            <CardHeader className='p-2'>
                                                                <h6 className='mb-0'>Exhaust temperatures (<sup>o</sup>C)</h6>
                                                            </CardHeader>
                                                            <CardBody className='px-2 py-0'>
                                                                <Row className='mb-2 mt-2'>
                                                                    <Col sm={3}>
                                                                        <Label className="mb-0" htmlFor={`no_of_exhaust_units${index}`}>ME number of units</Label>
                                                                        <Field name={`${index}.exhaustUnits.0.no_of_exhaust_units`}>
                                                                            {() => (
                                                                                <Input
                                                                                    type="text"
                                                                                    id={`no_of_exhaust_units${index}`}
                                                                                    name={`${index}.exhaustUnits.0.no_of_exhaust_units`}
                                                                                    className="form-cntrol max-width-7 text-right"
                                                                                    onChange={(e: any) => {
                                                                                        props?.handleChange(e);
                                                                                        if (e.target.value <= 24) {
                                                                                            let exhaustArr: any[] = [...exhaustUnits];
                                                                                            exhaustArr[index] = parseInt(e.target.value)
                                                                                            setExhaustUnits(exhaustArr);
                                                                                            let arr: any = [];
                                                                                            for (let i = 1; i <= value?.exhaustUnits[0]?.no_of_exhaust_units; i++) {
                                                                                                arr.push({
                                                                                                    no_of_exhaust_units: e.target.value,
                                                                                                    exhaust_unit: `Unit ${i + 1}`,
                                                                                                    unit_temperature: null as number,
                                                                                                    vessel_machinery: value?.exhaustUnits[0]?.vessel_machinery,
                                                                                                    vessel_machinery_name: value?.exhaustUnits[0]?.vessel_machinery_name,
                                                                                                    vessel: VesselID,
                                                                                                    voyage_information: VoyageID,
                                                                                                    vessel_reporting_information: ReportID,
                                                                                                });
                                                                                            }
                                                                                            value.exhaustUnits = arr;
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        } else {
                                                                                            let arr: any = [];
                                                                                            value.exhaustUnits = arr;
                                                                                            setExhaustUnits([]);
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        }
                                                                                    }}
                                                                                    onBlur={(e: any) => {
                                                                                        props?.handleChange(e);
                                                                                        props?.handleBlur(e);
                                                                                        if (e.target.value <= 24) {
                                                                                            let exhaustArr: any[] = [...exhaustUnits];
                                                                                            exhaustArr[index] = parseInt(e.target.value)
                                                                                            setExhaustUnits(exhaustArr);
                                                                                            let arr: any = [];
                                                                                            for (let i = 1; i <= value?.exhaustUnits[0]?.no_of_exhaust_units; i++) {
                                                                                                arr.push({
                                                                                                    no_of_exhaust_units: e.target.value,
                                                                                                    exhaust_unit: `Unit ${i + 1}`,
                                                                                                    unit_temperature: null as number,
                                                                                                    vessel_machinery: value?.exhaustUnits[0]?.vessel_machinery,
                                                                                                    vessel_machinery_name: value?.exhaustUnits[0]?.vessel_machinery_name,
                                                                                                    vessel: VesselID,
                                                                                                    voyage_information: VoyageID,
                                                                                                    vessel_reporting_information: ReportID,
                                                                                                });
                                                                                            }
                                                                                            value.exhaustUnits = arr;
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        } else {
                                                                                            let arr: any = [];
                                                                                            value.exhaustUnits = arr;
                                                                                            setExhaustUnits([]);
                                                                                            setTableRefreshKey(tableRefreshKey + 1);
                                                                                        }
                                                                                    }}
                                                                                    value={value?.exhaustUnits?.[0]?.no_of_exhaust_units}
                                                                                    disabled
                                                                                // defaultValue={(mainEnginesObject && mainEnginesObject?.length > 0) ?
                                                                                //     value?.exhaustUnits?.[0]?.no_of_exhaust_units :
                                                                                //     (prevExhaustUnitsObject && prevExhaustUnitsObject?.length > 0) ?
                                                                                //         prevExhaustUnitsObject?.filter((unit: any) => unit?.vessel_machinery === value?.exhaustUnits?.[0]?.vessel_machinery)[0]?.no_of_exhaust_units :
                                                                                //         null
                                                                                // }
                                                                                // disabled={prevExhaustUnitsObject?.filter((unit: any) => unit?.vessel_machinery === value?.exhaustUnits[0]?.vessel_machinery)[0]?.no_of_exhaust_units > 0}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        {props?.errors && props?.errors[index] && props?.errors[index]?.exhaustUnits &&
                                                                            env?.form_validation === true &&
                                                                            <ErrorTooltip
                                                                                target={`no_of_exhaust_units${index}`}
                                                                                message={props?.errors[index]?.exhaustUnits[0]?.no_of_exhaust_units}
                                                                                open={
                                                                                    props?.errors[index]?.exhaustUnits[0]?.no_of_exhaust_units &&
                                                                                    props?.errors[index]?.exhaustUnits[0]?.no_of_exhaust_units
                                                                                }
                                                                            />
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                {/* {exhaustUnits[index] > 0 && */}
                                                                <Row className='mb-2'>
                                                                    <React.Fragment>
                                                                        {Array.from({ length: value?.exhaustUnits[0]?.no_of_exhaust_units || 0 }).map((_, i) => {
                                                                            return (
                                                                                <Col sm={1} className='mb-2'>
                                                                                    <Label className="mb-0" htmlFor={`unit_temperature${index}${i}`}>{`Unit ${i + 1}`}</Label>
                                                                                    <Field name={`${index}.exhaustUnits.${i}.unit_temperature`}>
                                                                                        {() => (
                                                                                            <Input
                                                                                                type="text"
                                                                                                id={`unit_temperature${index}${i}`}
                                                                                                name={`${index}.exhaustUnits.${i}.unit_temperature`}
                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                onChange={(e: any) => {
                                                                                                    props?.handleChange(e);
                                                                                                    props?.setFieldValue(`${index}.exhaustUnits.${i}.unit_temperature`, e.target.value);
                                                                                                }}
                                                                                                onBlur={(e: any) => {
                                                                                                    props?.handleChange(e);
                                                                                                    props?.handleBlur(e);
                                                                                                }}
                                                                                                defaultValue={value?.exhaustUnits?.[i]?.unit_temperature}
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                    {env?.form_validation === true && props?.errors && props?.errors[index] && props?.errors[index]?.exhaustUnits &&
                                                                                        props?.errors[index]?.exhaustUnits[i]?.unit_temperature &&
                                                                                        <ErrorTooltip
                                                                                            target={`unit_temperature${index}${i}`}
                                                                                            message={props?.errors[index]?.exhaustUnits[i]?.unit_temperature}
                                                                                            open={
                                                                                                props?.errors[index]?.exhaustUnits[i]?.unit_temperature &&
                                                                                                props?.errors[index]?.exhaustUnits[i]?.unit_temperature
                                                                                            }
                                                                                        />
                                                                                    }
                                                                                </Col>
                                                                            )
                                                                        })}
                                                                    </React.Fragment>
                                                                </Row>
                                                                {/* } */}
                                                            </CardBody>
                                                        </Card>
                                                        <Card className='card-bottom-0 p-0 mb-0'>
                                                            <CardHeader className='p-2'>
                                                                <h6 className='mb-0'>Turbocharger</h6>
                                                            </CardHeader>
                                                            <CardBody className='px-2 py-0'>
                                                                <FieldArray name={`${index}.turboCharger`}>
                                                                    {({ push, remove }) => (
                                                                        <React.Fragment>
                                                                            {value?.turboCharger?.map((turbo: any, indx: number) => {
                                                                                turbo.turbo_charger = `Turbo Charger ${indx + 1}`;
                                                                                return (
                                                                                    <React.Fragment key={indx}>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <h6 className='mb-2 mt-2'>{turbo.turbo_charger}</h6>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                {value?.turboCharger?.length === 1 ? null :
                                                                                                    <button type="button" className="btn justify_right">
                                                                                                        <i className='dripicons-trash icon_s18'
                                                                                                            onClick={() => {
                                                                                                                remove(indx);
                                                                                                            }}
                                                                                                        />
                                                                                                    </button>
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className='mb-2'>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`gas_temperature_in${index}${indx}`}>Gas temperature inlet</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.turboCharger.${indx}.gas_temperature_in`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`gas_temperature_in${index}${indx}`}
                                                                                                                name={`${index}.turboCharger.${indx}.gas_temperature_in`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.turboCharger?.[indx]?.gas_temperature_in}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.turboCharger && props?.errors[index]?.turboCharger[indx]?.gas_temperature_in &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`gas_temperature_in${index}${indx}`}
                                                                                                        message={props?.errors[index]?.turboCharger[indx]?.gas_temperature_in}
                                                                                                        open={
                                                                                                            props?.errors[index]?.turboCharger[indx]?.gas_temperature_in &&
                                                                                                            props?.errors[index]?.turboCharger[indx]?.gas_temperature_in
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`gas_temperature_out${index}${indx}`}>Gas temperature outlet</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.turboCharger.${indx}.gas_temperature_out`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`gas_temperature_out${index}${indx}`}
                                                                                                                name={`${index}.turboCharger.${indx}.gas_temperature_out`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.turboCharger?.[indx]?.gas_temperature_out}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.turboCharger && props?.errors[index]?.turboCharger &&
                                                                                                    props?.errors[index]?.turboCharger[indx]?.gas_temperature_out &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`gas_temperature_out${index}${indx}`}
                                                                                                        message={props?.errors[index]?.turboCharger[indx]?.gas_temperature_out}
                                                                                                        open={
                                                                                                            props?.errors[index]?.turboCharger[indx]?.gas_temperature_out &&
                                                                                                            props?.errors[index]?.turboCharger[indx]?.gas_temperature_out
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`gas_press_drop${index}${indx}`}>Gas pressure drop</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.turboCharger.${indx}.gas_press_drop`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`gas_press_drop${index}${indx}`}
                                                                                                                name={`${index}.turboCharger.${indx}.gas_press_drop`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.turboCharger?.[indx]?.gas_press_drop}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text">mmWC</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.turboCharger && props?.errors[index]?.turboCharger[indx]?.gas_press_drop &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`gas_press_drop${index}${indx}`}
                                                                                                        message={props?.errors[index]?.turboCharger[indx]?.gas_press_drop}
                                                                                                        open={
                                                                                                            props?.errors[index]?.turboCharger[indx]?.gas_press_drop &&
                                                                                                            props?.errors[index]?.turboCharger[indx]?.gas_press_drop
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className='mb-2'>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`tc_oil_temp_inlet${index}${indx}`}>T/C oil temperature inlet</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.turboCharger.${indx}.tc_oil_temp_inlet`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`tc_oil_temp_inlet${index}${indx}`}
                                                                                                                name={`${index}.turboCharger.${indx}.tc_oil_temp_inlet`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.turboCharger?.[indx]?.tc_oil_temp_inlet}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.turboCharger && props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_inlet &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`tc_oil_temp_inlet${index}${indx}`}
                                                                                                        message={props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_inlet}
                                                                                                        open={
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_inlet &&
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_inlet
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`tc_oil_temp_outlet${index}${indx}`}>T/C oil temperature outlet</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.turboCharger.${indx}.tc_oil_temp_outlet`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`tc_oil_temp_outlet${index}${indx}`}
                                                                                                                name={`${index}.turboCharger.${indx}.tc_oil_temp_outlet`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.turboCharger?.[indx]?.tc_oil_temp_outlet}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.turboCharger && props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_outlet &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`tc_oil_temp_outlet${index}${indx}`}
                                                                                                        message={props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_outlet}
                                                                                                        open={
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_outlet &&
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tc_oil_temp_outlet
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`tc_oil_inlet_pressure${index}${indx}`}>T/C oil pressure inlet</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.turboCharger.${indx}.tc_oil_inlet_pressure`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`tc_oil_inlet_pressure${index}${indx}`}
                                                                                                                name={`${index}.turboCharger.${indx}.tc_oil_inlet_pressure`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.turboCharger?.[indx]?.tc_oil_inlet_pressure}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.turboCharger && props?.errors[index]?.turboCharger[indx]?.tc_oil_inlet_pressure &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`tc_oil_inlet_pressure${index}${indx}`}
                                                                                                        message={props?.errors[index]?.turboCharger[indx]?.tc_oil_inlet_pressure}
                                                                                                        open={
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tc_oil_inlet_pressure &&
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tc_oil_inlet_pressure
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="mb-2">
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`tch_speed${index}${indx}`}>T/C speed</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.turboCharger.${indx}.tch_speed`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`tch_speed${index}${indx}`}
                                                                                                                name={`${index}.turboCharger.${indx}.tch_speed`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.turboCharger?.[indx]?.tch_speed}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.turboCharger && props?.errors[index]?.turboCharger[indx]?.tch_speed &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`tch_speed${index}${indx}`}
                                                                                                        message={props?.errors[index]?.turboCharger[indx]?.tch_speed}
                                                                                                        open={
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tch_speed &&
                                                                                                            props?.errors[index]?.turboCharger[indx]?.tch_speed
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        {indx === (value?.turboCharger?.length - 1) &&
                                                                                            <Row className='mb-2'>
                                                                                                <Col sm={4}>
                                                                                                    <Label
                                                                                                        className="link_color_blue mt-2 mb-0"
                                                                                                        onClick={() => {
                                                                                                            push({
                                                                                                                gas_temperature_in: null as number,
                                                                                                                gas_temperature_out: null as number,
                                                                                                                gas_press_drop: null as number,
                                                                                                                tc_oil_temp_inlet: null as number,
                                                                                                                tc_oil_temp_outlet: null as number,
                                                                                                                tc_oil_inlet_pressure: null as number,
                                                                                                                tch_speed: null as number,
                                                                                                                vessel_machinery: value?.turboCharger[0]?.vessel_machinery,
                                                                                                                vessel_machinery_name: value?.turboCharger[0]?.vessel_machinery_name,
                                                                                                                vessel: VesselID,
                                                                                                                voyage_information: VoyageID,
                                                                                                                vessel_reporting_information: ReportID,
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        Add another Turbocharger
                                                                                                    </Label>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>
                                                            </CardBody>
                                                        </Card>
                                                        <Card className='card-bottom-0 p-0 mb-0'>
                                                            <CardHeader className='p-2'>
                                                                <h6 className='mb-0'>Air Cooler</h6>
                                                            </CardHeader>
                                                            <CardBody className='px-2 py-0'>
                                                                <FieldArray name={`${index}.airCooler`}>
                                                                    {({ push, remove }) => (
                                                                        <React.Fragment>
                                                                            {value?.airCooler?.map((airCooler: any, indx: number) => {
                                                                                airCooler.air_cooler = `Air Cooler ${indx + 1}`;
                                                                                return (
                                                                                    <React.Fragment key={indx}>
                                                                                        <Row>
                                                                                            <Col>
                                                                                                <h6 className='mb-2 mt-2'>{airCooler.air_cooler}</h6>
                                                                                            </Col>
                                                                                            <Col>
                                                                                                {value?.airCooler?.length === 1 ? null :
                                                                                                    <button type="button" className="btn justify_right">
                                                                                                        <i className='dripicons-trash icon_s18'
                                                                                                            onClick={() => {
                                                                                                                remove(indx);
                                                                                                            }}
                                                                                                        />
                                                                                                    </button>
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className='mb-2'>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`air_cooler_cw_in_temp${index}${indx}`}>Air cooler C/W temperature inlet</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.airCooler.${indx}.air_cooler_cw_in_temp`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`air_cooler_cw_in_temp${index}${indx}`}
                                                                                                                name={`${index}.airCooler.${indx}.air_cooler_cw_in_temp`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.airCooler[indx]?.air_cooler_cw_in_temp}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.airCooler && props?.errors[index]?.airCooler[indx]?.air_cooler_cw_in_temp &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`air_cooler_cw_in_temp${index}${indx}`}
                                                                                                        message={props?.errors[index]?.airCooler[indx]?.air_cooler_cw_in_temp}
                                                                                                        open={
                                                                                                            props?.errors[index]?.airCooler[indx]?.air_cooler_cw_in_temp &&
                                                                                                            props?.errors[index]?.airCooler[indx]?.air_cooler_cw_in_temp
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`me_lo_temp_outlet${index}${indx}`}>Air cooler C/W temperature outlet</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.airCooler.${indx}.me_lo_temp_outlet`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`me_lo_temp_outlet${index}${indx}`}
                                                                                                                name={`${index}.airCooler.${indx}.me_lo_temp_outlet`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.airCooler[indx]?.me_lo_temp_outlet}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.airCooler && props?.errors[index]?.airCooler[indx]?.me_lo_temp_outlet &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`me_lo_temp_outlet${index}${indx}`}
                                                                                                        message={props?.errors[index]?.airCooler[indx]?.me_lo_temp_outlet}
                                                                                                        open={
                                                                                                            props?.errors[index]?.airCooler[indx]?.me_lo_temp_outlet &&
                                                                                                            props?.errors[index]?.airCooler[indx]?.me_lo_temp_outlet
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`air_side_press_drop${index}${indx}`}>Air cooler air side pressure drop</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.airCooler.${indx}.air_side_press_drop`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`air_side_press_drop${index}${indx}`}
                                                                                                                name={`${index}.airCooler.${indx}.air_side_press_drop`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.airCooler[indx]?.air_side_press_drop}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text">mmHg</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.airCooler && props?.errors[index]?.airCooler[indx]?.air_side_press_drop &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`air_side_press_drop${index}${indx}`}
                                                                                                        message={props?.errors[index]?.airCooler[indx]?.air_side_press_drop}
                                                                                                        open={
                                                                                                            props?.errors[index]?.airCooler[indx]?.air_side_press_drop &&
                                                                                                            props?.errors[index]?.airCooler[indx]?.air_side_press_drop
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className='mb-2'>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`me_scav_air_temp${index}${indx}`}>ME scav air temperature</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.airCooler.${indx}.me_scav_air_temp`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`me_scav_air_temp${index}${indx}`}
                                                                                                                name={`${index}.airCooler.${indx}.me_scav_air_temp`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.airCooler[indx]?.me_scav_air_temp}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.airCooler && props?.errors[index]?.airCooler[indx]?.me_scav_air_temp &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`me_scav_air_temp${index}${indx}`}
                                                                                                        message={props?.errors[index]?.airCooler[indx]?.me_scav_air_temp}
                                                                                                        open={
                                                                                                            props?.errors[index]?.airCooler[indx]?.me_scav_air_temp &&
                                                                                                            props?.errors[index]?.airCooler[indx]?.me_scav_air_temp
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`me_scav_air_pressure${index}${indx}`}>ME scav air pressure</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.airCooler.${indx}.me_scav_air_pressure`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`me_scav_air_pressure${index}${indx}`}
                                                                                                                name={`${index}.airCooler.${indx}.me_scav_air_pressure`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.airCooler[indx]?.me_scav_air_pressure}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text">bar</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.airCooler && props?.errors[index]?.airCooler[indx]?.me_scav_air_pressure &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`me_scav_air_pressure${index}${indx}`}
                                                                                                        message={props?.errors[index]?.airCooler[indx]?.me_scav_air_pressure}
                                                                                                        open={
                                                                                                            props?.errors[index]?.airCooler[indx]?.me_scav_air_pressure &&
                                                                                                            props?.errors[index]?.airCooler[indx]?.me_scav_air_pressure
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`eng_room_temp${index}${indx}`}>Eng room temperature</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.airCooler.${indx}.eng_room_temp`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`eng_room_temp${index}${indx}`}
                                                                                                                name={`${index}.airCooler.${indx}.eng_room_temp`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.airCooler[indx]?.eng_room_temp}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.airCooler && props?.errors[index]?.airCooler[indx]?.eng_room_temp &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`eng_room_temp${index}${indx}`}
                                                                                                        message={props?.errors[index]?.airCooler[indx]?.eng_room_temp}
                                                                                                        open={
                                                                                                            props?.errors[index]?.airCooler[indx]?.eng_room_temp &&
                                                                                                            props?.errors[index]?.airCooler[indx]?.eng_room_temp
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className='mb-2'>
                                                                                            <Col sm={4}>
                                                                                                <Label className="mb-0" htmlFor={`sea_water_temperature${index}${indx}`}>Sea water temperature</Label>
                                                                                                <div className='input-group'>
                                                                                                    <Field name={`${index}.airCooler.${indx}.sea_water_temperature`}>
                                                                                                        {() => (
                                                                                                            <Input
                                                                                                                type="text"
                                                                                                                id={`sea_water_temperature${index}${indx}`}
                                                                                                                name={`${index}.airCooler.${indx}.sea_water_temperature`}
                                                                                                                className="form-cntrol max-width-7 text-right"
                                                                                                                onChange={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                onBlur={(e: any) => {
                                                                                                                    props?.handleChange(e);
                                                                                                                    props?.handleBlur(e);
                                                                                                                }}
                                                                                                                defaultValue={value?.airCooler[indx]?.sea_water_temperature}
                                                                                                            />
                                                                                                        )}
                                                                                                    </Field>
                                                                                                    <div className="input-group-text"><sup>o</sup>C</div>
                                                                                                </div>
                                                                                                {env?.form_validation === true && props?.errors && props?.errors[index]?.airCooler && props?.errors[index]?.airCooler[indx]?.sea_water_temperature &&
                                                                                                    <ErrorTooltip
                                                                                                        target={`sea_water_temperature${index}${indx}`}
                                                                                                        message={props?.errors[index]?.airCooler[indx]?.sea_water_temperature}
                                                                                                        open={
                                                                                                            props?.errors[index]?.airCooler[indx]?.sea_water_temperature &&
                                                                                                            props?.errors[index]?.airCooler[indx]?.sea_water_temperature
                                                                                                        }
                                                                                                    />
                                                                                                }
                                                                                            </Col>
                                                                                        </Row>
                                                                                        {indx === (value?.airCooler?.length - 1) &&
                                                                                            <Row className='mb-2'>
                                                                                                <Col sm={4}>
                                                                                                    <Label
                                                                                                        className="link_color_blue mt-2 mb-0"
                                                                                                        onClick={() => {
                                                                                                            push({
                                                                                                                air_cooler_cw_in_temp: null as number,
                                                                                                                me_lo_temp_outlet: null as number,
                                                                                                                air_side_press_drop: null as number,
                                                                                                                me_scav_air_temp: null as number,
                                                                                                                me_scav_air_pressure: null as number,
                                                                                                                eng_room_temp: null as number,
                                                                                                                sea_water_temperature: null as number,
                                                                                                                vessel_machinery: value?.airCooler[0]?.vessel_machinery,
                                                                                                                vessel_machinery_name: value?.airCooler[0]?.vessel_machinery_name,
                                                                                                                vessel: VesselID,
                                                                                                                voyage_information: VoyageID,
                                                                                                                vessel_reporting_information: ReportID,
                                                                                                            });
                                                                                                        }}
                                                                                                    >
                                                                                                        Add another Air Cooler
                                                                                                    </Label>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        }
                                                                                    </React.Fragment>
                                                                                )
                                                                            })}
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>
                                                            </CardBody>
                                                        </Card>
                                                        <Card className='card-bottom-0 p-0 mb-0'>
                                                            <CardHeader className='p-2'>
                                                                <h6 className='mb-0'>Exhaust Gas Economiser</h6>
                                                            </CardHeader>
                                                            <CardBody className='px-2 py-0'>
                                                                <Row className='mb-2'>
                                                                    <Col sm={4}>
                                                                        <Label className="mb-0" htmlFor={`exh_gas_in_temp${index}`}>Exh gas temperature inlet</Label>
                                                                        <div className='input-group'>
                                                                            <Field name={`${index}.exhaustGasEconomiser.exh_gas_in_temp`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        id={`exh_gas_in_temp${index}`}
                                                                                        name={`${index}.exhaustGasEconomiser.exh_gas_in_temp`}
                                                                                        className="form-cntrol max-width-7 text-right"
                                                                                        onChange={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        onBlur={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        defaultValue={value?.exhaustGasEconomiser?.exh_gas_in_temp}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text"><sup>o</sup>C</div>
                                                                        </div>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.exhaustGasEconomiser && props?.errors[index]?.exhaustGasEconomiser?.exh_gas_in_temp &&
                                                                            <ErrorTooltip
                                                                                target={`exh_gas_in_temp${index}`}
                                                                                message={props?.errors[index]?.exhaustGasEconomiser?.exh_gas_in_temp}
                                                                                open={
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.exh_gas_in_temp &&
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.exh_gas_in_temp
                                                                                }
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col sm={4}>
                                                                        <Label className="mb-0" htmlFor={`exh_gas_out_temp${index}`}>Exh gas temperature outlet</Label>
                                                                        <div className='input-group'>
                                                                            <Field name={`${index}.exhaustGasEconomiser.exh_gas_out_temp`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        id={`exh_gas_out_temp${index}`}
                                                                                        name={`${index}.exhaustGasEconomiser.exh_gas_out_temp`}
                                                                                        className="form-cntrol max-width-7 text-right"
                                                                                        onChange={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        onBlur={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        defaultValue={value?.exhaustGasEconomiser?.exh_gas_out_temp}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text"><sup>o</sup>C</div>
                                                                        </div>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.exhaustGasEconomiser && props?.errors[index]?.exhaustGasEconomiser?.exh_gas_out_temp &&
                                                                            <ErrorTooltip
                                                                                target={`exh_gas_out_temp${index}`}
                                                                                message={props?.errors[index]?.exhaustGasEconomiser?.exh_gas_out_temp}
                                                                                open={
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.exh_gas_out_temp &&
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.exh_gas_out_temp
                                                                                }
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col sm={4}>
                                                                        <Label className="mb-0" htmlFor={`steam_pressure${index}`}>Steam pressure</Label>
                                                                        <div className='input-group'>
                                                                            <Field name={`${index}.exhaustGasEconomiser.steam_pressure`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        id={`steam_pressure${index}`}
                                                                                        name={`${index}.exhaustGasEconomiser.steam_pressure`}
                                                                                        className="form-cntrol max-width-7 text-right"
                                                                                        onChange={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        onBlur={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        defaultValue={value?.exhaustGasEconomiser?.steam_pressure}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text">bar</div>
                                                                        </div>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.exhaustGasEconomiser && props?.errors[index]?.exhaustGasEconomiser?.steam_pressure &&
                                                                            <ErrorTooltip
                                                                                target={`steam_pressure${index}`}
                                                                                message={props?.errors[index]?.exhaustGasEconomiser?.steam_pressure}
                                                                                open={
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.steam_pressure &&
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.steam_pressure
                                                                                }
                                                                            />
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row className='mb-2'>
                                                                    <Col sm={4}>
                                                                        <Label className="mb-0" htmlFor={`hot_well_temp${index}`}>Hot well temperature</Label>
                                                                        <div className='input-group'>
                                                                            <Field name={`${index}.exhaustGasEconomiser.hot_well_temp`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        type="text"
                                                                                        id={`hot_well_temp${index}`}
                                                                                        name={`${index}.exhaustGasEconomiser.hot_well_temp`}
                                                                                        className="form-cntrol max-width-7 text-right"
                                                                                        onChange={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        onBlur={(e: any) => {
                                                                                            props?.handleChange(e);
                                                                                            props?.handleBlur(e);
                                                                                        }}
                                                                                        defaultValue={value?.exhaustGasEconomiser?.hot_well_temp}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text"><sup>o</sup>C</div>
                                                                        </div>
                                                                        {env?.form_validation === true && props?.errors && props?.errors[index]?.exhaustGasEconomiser && props?.errors[index]?.exhaustGasEconomiser?.hot_well_temp &&
                                                                            <ErrorTooltip
                                                                                target={`hot_well_temp${index}`}
                                                                                message={props?.errors[index]?.exhaustGasEconomiser?.hot_well_temp}
                                                                                open={
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.hot_well_temp &&
                                                                                    props?.errors[index]?.exhaustGasEconomiser?.hot_well_temp
                                                                                }
                                                                            />
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </CardBody>
                                                        </Card>
                                                    </CardBody>
                                                </Card>
                                            </React.Fragment>
                                        )
                                    })}
                                    <CardFooter className='p-2 py-3'>
                                        <Row className="ele_row1">
                                            <div className="d-flex flex-wrap gap-5">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="btn_size_cstm pos-end"
                                                    disabled={props?.isSubmitting}
                                                >
                                                    {props?.isSubmitting &&
                                                        <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                                                    }
                                                    Next <i className="bx bx-chevron-right ms-1" />
                                                </Button>
                                                <Button type="button" color="primary" className="btn_size_cstm" onClick={() => {
                                                    props.setErrors({})
                                                    props.errors = {};
                                                    toggleDynamicTabs(previousTabIndex - 1);
                                                    setErrorMessage(null)
                                                }}>
                                                    <i className="bx bx-chevron-left me-1" />Previous
                                                </Button>
                                            </div>
                                        </Row>
                                    </CardFooter>
                                </CardBody >
                            </Card >
                            <FormValuesDebug values={[props?.values, props?.errors, MainEngineFormik.initialValues]} />
                        </Form >
                    )}
                </Formik >
            }
        </React.Fragment >
    )
}

export default MainEngineComponent