import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import { RootState } from 'index';
import { useQuery } from 'react-query';
import { FileStatus, VesselTypeConstant } from 'shared/constants';
import { apiMedia } from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import { errorToast, successToast } from 'Components/Toasts';
import ReportingFileUpload from 'Components/ReportingFileUpload';
import { checkInvalidPrimaryKey, errResponse } from 'GenericForms/Helper';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { GetPageWithID } from '../pendingpage.hook';
import PageNavButton from '../PageNavButton';

const PendingSpOpLogBook = () => {
    /** State variables */
    const { Vessels, VesselID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [modalState, setModalState] = useState(false);
    const [fileObject, setFileObject] = useState<any>({});
    const [logBookFile, setLogBookFile] = useState<any>([{}]);
    const [pageUrl, setPageUrl] = useState("");
    /** State variables end */

    /** ueffect to set page url on vessel id change */
    useEffect(() => {
        if (!checkInvalidPrimaryKey(VesselID)) {
            return;
        }
        if (vessel?.vessel_type !== VesselTypeConstant.CONTAINER && vessel?.vessel_type !== VesselTypeConstant.GENERAL_CARGO_SHIP) {
            setPageUrl(queryKeyes.pagination.SpOpLogBookPage.url(VesselID, FileStatus.PENDING));
        }
    }, [VesselID, vessel?.vessel_type]);

    /**
   * Fetches the list of pending Log Book files for a specific vessel. 
   * Uses the `useQuery` hook to load Log Book files associated with the selected vessel.
   */
    const { data: fileUploadingObj, isLoading: fileUploadingLoading, isError: fileUploadingError } = useQuery(
        [queryKeyes.pagination.SpOpLogBookPage.key, VesselID, FileStatus.PENDING, pageUrl],
        async () => await GetPageWithID(VesselID, pageUrl)
    );

    /**
     * Adds a CSS class to the document's body to handle modal styling.
     */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /**
     * Toggles the state of the modal and applies the necessary body styling.
     */
    function tog_backdrop() {
        setModalState(!modalState);
        removeBodyCss();
    }

    /**
    * Sets the modal state to open and stores the file name and object for uploading.
    * @param file - The file object associated with the selected Log Book entry.
    */
    const handleUploadButtonClick = (file: any) => {
        setModalState(!modalState);
        setFileObject(file);
    }

    /**
     * to upload logBook file
     */
    const handleUpload = () => {
        if (logBookFile?.length > 0 && logBookFile[0].file) {
            fileObject.file_name = logBookFile[0].file
            apiMedia.put(`special_operation_file_upload/${fileObject.id}/`, fileObject)
                .then(res => {
                    if (res.status === 200 || res.status === 201) {
                        queryClient.invalidateQueries(
                            queryKeyes.pagination.SpOpLogBookPage.key
                        );
                        setModalState(!modalState);
                        successToast("File uploaded successfully!");
                    } else {
                        errorToast("Unexpected response, please try again.");
                    }
                })
                .catch(err => {
                    if (err.response && errResponse.includes(err.response.status)) {
                        errorToast("Internal error occurred, please contact the admin");
                    } else {
                        errorToast("Failed to upload the file.");
                    }
                });
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    {fileUploadingLoading && <Loading message='Loading required data!' />}
                    {fileUploadingError && <ErrorComponent message='Unable to load required data!' />}
                </Col>
            </Row>
            {!fileUploadingLoading && !fileUploadingError &&
                <CardBody className="px-0 py-0 pb-0 mt-2">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th className="p-2 align-middle sr-no-width">#</th>
                                    <th className="p-2 align-middle" style={{ width: '20%' }}>Date & Time (Local)</th>
                                    <th className="p-2 align-middle" style={{ width: '65%' }}>Name of Report</th>
                                    <th className="p-2 align-middle text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fileUploadingObj?.results?.length > 0 ? (
                                    fileUploadingObj?.results?.map((file: any, index: number) => (
                                        <tr key={index}>
                                            <td className="p-2 align-middle text-center">{index + 1}</td>
                                            <td className="p-2 align-middle">{new Date(file.reporting_date_time).toUTCString()}</td>
                                            <td className="p-2 align-middle">{file.special_operation_name}</td>
                                            <td className="p-2 align-middle text-center">
                                                <Button
                                                    type="button"
                                                    className='btn-sm'
                                                    color="primary"
                                                    onClick={() => handleUploadButtonClick(file)}>
                                                    Upload
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={5} className="p-2 align-middle text-center">
                                            No files are pending for upload
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                            <tfoot>
                                {fileUploadingObj?.results?.length > 0 &&
                                    <tr>
                                        <td colSpan={5} className="p-2 ">
                                            <PageNavButton setPageUrl={setPageUrl} pageobj={fileUploadingObj} pageUrl={pageUrl} />
                                        </td>
                                    </tr>
                                }
                            </tfoot>
                        </table>
                    </div>
                </CardBody>
            }
            <Modal
                size='md'
                isOpen={modalState}
                toggle={() => tog_backdrop()}
                backdrop={"static"}
                id="staticBackdrop"
            >
                <ModalHeader className='p-2'>Upload Pending Log Book File
                    <button
                        onClick={async () => {
                            setModalState(false);
                            setLogBookFile([{}])
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className='p-2'>
                    <ReportingFileUpload
                        setFile={setLogBookFile}
                        file={logBookFile}
                        index={0}
                        sm={5}
                    />
                    <div className="mt-1 mb-5">
                        <Button type="submit" color='primary' className='pos-end' onClick={() => {
                            setModalState(false)
                            handleUpload()
                        }}>Save</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}
export default PendingSpOpLogBook