import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Col, Row, Input, Label, Button, Form, CardBody, CardFooter, CardHeader, Card } from "reactstrap";
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import AsyncSelect from "react-select/async";
import { loadBunkeringObject, loadFuelBatches, loadFuelTypeROB, loadOnboardingBunkeringObject, loadOtherPorts, loadPortOptions, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { dateTimeFormat, handleDynamicPreviousTabId, handleServerResponse, searchCountries, searchPorts } from 'GenericForms/Helper';
import { customStyle } from 'shared/CommonCSS';
import { FuelClassConstant, FuelSubTypes, FuelTypes, PortConstant, ViscosityConstant } from 'shared/constants';
import { queryClient } from 'react-query/queryClient';
import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';
import NavigationButtons from 'Components/NavigationButtons';
import DeletePopOver from 'Components/DeletePopOver';
import { successToast } from 'Components/Toasts';
import Select from 'react-select';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import { useDocumentTitle } from 'Components/useDocument.hooks';

interface BunkeringType {
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    isFirstReport: boolean,
    tabsIdList?: Array<number>,
    toggleDynamicTabs?: (value: number) => void,
    previousTabIndex?: number,
    tabsList?: Array<any>,
    report?: { [key: string]: string | number | Date | boolean },
    record?: { [key: string]: string | number | Date | boolean },
    lastRecord?: any,
    toggleTab?: (activeTab: number) => void,
    activeTab?: number,
    setErrorMessage?: any
}

const BunkeringComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    isFirstReport,
    tabsIdList,
    toggleDynamicTabs,
    previousTabIndex,
    tabsList,
    report,
    record,
    lastRecord,
    toggleTab,
    activeTab,
    setErrorMessage,
}: BunkeringType) => {
    /** State variables */
    const [port, setPort] = useState(null);
    const [otherPort, setOtherPort] = useState(false);
    const [otherPortName, setOtherPortName] = useState('');
    const [otherCountry, setOtherCountry] = useState(null);
    const [addOtherPort, setAddOtherPort] = useState(false);
    const [otherPortId, setOtherPortId] = useState(null);
    const [extraActions, setExtraActions] = useState<Array<any>>([{
        fuelClass: false,
        fuelSubTypePrecedence: 0,
        fuelTypePrecedence: 0,
        otherPort: false,
        addOtherPort: false
    }])
    const responseArray: any = [];
    const [deletePopoverBool, setDeletePopoverBool] = useState(false);
    const [deleteBatch, setDeleteBatch] = useState<any>(null);
    const [deleteIndex, setDeleteIndex] = useState<number>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    useDocumentTitle('Bunkering Report - ecoSAIL');
    /** State variables end */

    /** Queries */
    /** Rob according to fuel types */
    const { data: fuelTypeROB, isLoading: fuelTypeROBIsLoading, isError: fuelTypeROBIsError } = useQuery(
        [queryKeyes.vessel.FuelTypeROB.key, VesselID, VoyageID, ReportID],
        async () => {
            return await loadFuelTypeROB(VesselID, VoyageID, ReportID)
        },
        {
            enabled: true,
        }
    );
    /** Ports from port master */
    const { data: ports, isLoading: PortsIsLoading, isError: PortsIsError } = useQuery(
        [queryKeyes.masters.portMaster],
        async () => { return await loadPortOptions() },
        {
            enabled: true,
        }
    );

    /**Bunkering object used for edit */
    const {
        data: BunkeringObject, isLoading: BunkeringObjectLoading, isError: BunkeringObjectError
    } = useQuery(
        [
            isFirstReport
                ? queryKeyes.vessel.GetOnboardingBunkeringByVessel.key
                : queryKeyes.vessel.BunkeringObject.key,
            isFirstReport ? VesselID : VesselID, ReportID
        ],
        async () => {
            return isFirstReport
                ? await loadOnboardingBunkeringObject(VesselID)
                : await loadBunkeringObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );

    /** Other Ports from master */
    const { data: OtherPortsObject, isLoading: OtherPortsLoading }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.masters.OtherPortMaster.key, VesselID, ReportID],
            async () => {
                return await loadOtherPorts();
            },
            { staleTime: Infinity }
        )
    const { data: bunkeredFuelType } = useQuery([queryKeyes.vessel.vesselFuelType.key, VesselID],
        async () => await loadVesselFuelTypes(VesselID), {
        staleTime: Infinity,
    })
    const filteredOptions = bunkeredFuelType?.filter(
        (item: any) => item.precedence_id !== FuelTypes.LNG_CARGO
    );
    /** Queries end */

    /** Assign initial values to formik object */
    const getintialValueBunkering = () => {
        let obj: any[] = [];
        if (BunkeringObject &&
            Array.isArray(BunkeringObject) &&
            BunkeringObject?.length > 0 &&
            BunkeringObject[0].id > 0) {
            BunkeringObject.forEach((bunker: any) => {
                bunker.fuelKey = 0;
                bunker.fuelCategory = bunker?.fuel_class_precedence_id;
                if (bunker?.fuel_class_precedence_id === FuelClassConstant.BIOFUEL) {
                    if (bunker?.e === null) {
                        bunker.fuelEUParameter = 'co2_equivalent_factor_WtT';
                    } else {
                        bunker.fuelEUParameter = 'e';
                    }
                }
            })
            return BunkeringObject;
        } else {
            obj.push({
                fuelKey: 0,
                fuelCategory: null,
                fuelEUParameter: null,
                e: null,
                co2_equivalent_factor_WtT: null,
                lcv: null,
                precedence_id: null,
                precedence_id_type: null,
                bdn_identification_number: null,
                quantity_bunkered: null,
                bunkering_supply_name: '',
                viscosity: null,
                density_temperature: null,
                quantity_remaining: 0,
                previous_quantity_remaining: 0,
                status: '',
                full_consumption_date: null,
                sulphur_percentage: null,
                density: null,
                date_of_bunkering: '',
                vessel_fuel_name: '',
                cfco2: null,
                cfn2o: null,
                cfch4: null,
                fuel_type_name: null as number,
                fuel_sub_type: null,
                port_of_bunkering: null,
                other_port: null as number,
                other_port_name: null as string,
                other_country: null as number,
                other_country_name: null as string,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
                port_of_bunkering_name: null as string,
                fuel_typename: null as string,
            })
            return obj;
        }
    }

    /**useEffect start */
    /** Create and assign initial object to Formik's initial values' object  */
    useEffect(() => {
        queryClient.invalidateQueries(queryKeyes.vessel.BunkeringObject.key);
    }, [])
    useEffect(() => {
        if (!isFirstReport) {
            if ((BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) &&
                (BunkeringObject[0]?.port_precedence_id === PortConstant.OTHER)) {
                setOtherPort(true);
                setPort(BunkeringObject[0]?.port_of_bunkering);
                setOtherPortId(BunkeringObject[0]?.other_port);
                setOtherPortName(BunkeringObject[0]?.other_port_name);
                setOtherCountry(BunkeringObject[0]?.other_country);
            } else if (BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) {
                setPort(BunkeringObject[0]?.port_of_bunkering);
            }
        } else {
            let array: any[] = []
            if (BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) {
                BunkeringObject?.forEach((bunker: any) => {
                    array.push({
                        fuelClass: bunker?.fuel_class_precedence_id === FuelClassConstant.BIOFUEL ? true : false,
                        fuelSubTypePrecedence: bunker?.precedence_id,
                        fuelTypePrecedence: bunker?.precedence_id_type,
                        otherPort: bunker?.port_precedence_id === PortConstant.OTHER,
                        addOtherPort: false
                    });
                })
                setExtraActions(array);
            }
        }
    }, [BunkeringObject, isFirstReport])
    useEffect(() => {
        if (!isFirstReport) {
            let array: any[] = [...extraActions]
            if (BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) {
                array = [];
                BunkeringObject?.forEach((bunker: any) => {
                    array.push({
                        fuelClass: bunker?.fuel_class_precedence_id === FuelClassConstant.BIOFUEL ? true : false,
                        fuelSubTypePrecedence: bunker?.precedence_id,
                        fuelTypePrecedence: bunker?.precedence_id_type
                    });
                });
                setExtraActions(array);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BunkeringObject, isFirstReport])
    /**useEffect end */

    /** Bunkering Formik object */
    const BunkeringFormik = {
        initialValues: {
            bunkering: getintialValueBunkering(),
        },
        validationSchema: Yup.object().shape({
            bunkering: Yup.array(
                Yup.object({
                    bdn_identification_number: Yup.string().required(commonValidationMessages.required),
                    quantity_bunkered: Yup.string().matches(/^(?!0\d)\d{1,5}(?:\.\d{1,4})?$/, `${commonValidationMessages.before5after4}`).required(commonValidationMessages.required),
                    bunkering_supply_name: Yup.string(),
                    viscosity: Yup.string().matches(/^\d{0,3}(\.\d{1,4})?$/, `${commonValidationMessages.before3after4}`).nullable(),
                    quantity_remaining: Yup.number().nullable(),
                    previous_quantity_remaining: Yup.number().nullable(),
                    status: Yup.string(),
                    full_consumption_date: Yup.string().nullable(),
                    sulphur_percentage: Yup.string().matches(/^\d(?:\.\d{1,4})?$/, `${commonValidationMessages.before1after4}`).nullable(),
                    density: Yup.string().matches(/^\d{0,4}(\.\d{1,4})?$/, `${commonValidationMessages.before4after4}`).required(commonValidationMessages.required),
                    date_of_bunkering: Yup
                        .date().when('$fieldAvailability', (field: any, schema) => {
                            return !isFirstReport ?
                                schema.min(dateTimeFormat(lastRecord?.local_time, true), commonValidationMessages.minDateLastRecord)
                                    .max(dateTimeFormat(record?.local_time as string, true), commonValidationMessages.maxDateCurrentRecord) :
                                schema.max(dateTimeFormat(report?.local_time as string, true))
                        }).required(commonValidationMessages.required),
                    vessel_fuel_name: Yup.string().max(50, commonValidationMessages.max50Char).required(commonValidationMessages.required),
                    fuel_type_name: Yup.number().required(commonValidationMessages.required),
                    port_of_bunkering: Yup.number().required(commonValidationMessages.required),
                    other_port: Yup.string().when('$fieldAvailability', (field: any, schema) => {
                        return !(otherPort === true && OtherPortsObject)
                            ? schema.nullable()
                            : schema.required(commonValidationMessages.required);
                    }),
                    other_port_name: Yup.string().when('$fieldAvailability', (field: any, schema) => {
                        return !(addOtherPort === true || !OtherPortsObject)
                            ? schema.nullable()
                            : schema.required(commonValidationMessages.required);
                    }),
                    other_country: Yup.number().when('$fieldAvailability', (field: any, schema) => {
                        return !(addOtherPort === true || !OtherPortsObject)
                            ? schema.nullable()
                            : schema.required(commonValidationMessages.required);
                    }),
                    co2_equivalent_factor_WtT: Yup.string()
                        .when(["fuelEUParameter", "fuelCategory"], {
                            is: (fuelEUParameter: any, fuelCategory: any) =>
                                fuelCategory === FuelClassConstant.BIOFUEL && fuelEUParameter === 'co2_equivalent_factor_WtT',
                            then: () =>
                                Yup.string()
                                    .matches(/^\d{0,2}(?:\.\d{1,10})?$/, `${commonValidationMessages.before2after10}`)
                                    .required(commonValidationMessages.required),
                            otherwise: () =>
                                Yup.string()
                                    .matches(/^\d{0,2}(?:\.\d{1,10})?$/, `${commonValidationMessages.before2after10}`)
                                    .nullable(),
                        }),
                    e: Yup.string().matches(/^\d{0,3}(?:\.\d{1,10})?$/, `${commonValidationMessages.before3after10}`)
                        .when(["fuelEUParameter", "fuelCategory"], {
                            is: (fuelEUParameter: any, fuelCategory: any) =>
                                fuelCategory === FuelClassConstant.BIOFUEL && fuelEUParameter === 'e',
                            then: () =>
                                Yup.string()
                                    .matches(/^\d{0,3}(?:\.\d{1,10})?$/, `${commonValidationMessages.before3after10}`)
                                    .required(commonValidationMessages.required),
                            otherwise: () =>
                                Yup.string()
                                    .matches(/^\d{0,3}(?:\.\d{1,10})?$/, `${commonValidationMessages.before3after10}`)
                                    .nullable(),
                        }),
                    cfco2: Yup.string()
                        .matches(
                            /^\d{0,3}(?:\.\d{1,10})?$/,
                            `${commonValidationMessages.before3after10}`
                        ).nullable(),
                    lcv: Yup.string().matches(
                        /^\d{0,3}(?:\.\d{1,10})?$/,
                        `${commonValidationMessages.before3after10}`
                    ).nullable(),
                    cfn2o: Yup.string()
                        .matches(
                            /^\d{0,3}(?:\.\d{1,10})?$/,
                            `${commonValidationMessages.before3after10}`
                        ).nullable(),
                    cfch4: Yup.string()
                        .matches(
                            /^\d{0,3}(?:\.\d{1,10})?$/,
                            `${commonValidationMessages.before3after10}`
                        ).nullable(),
                    vessel: Yup.number(),
                    voyage_information: Yup.number(),
                    vessel_reporting_information: Yup.number()
                }).test(function (value: any, schema: any) {
                    let bukner = this.parent ? this.parent : [];
                    let index = bukner.indexOf(value);
                    if (extraActions[index]?.fuelSubTypePrecedence !== FuelSubTypes.LNGB) {
                        if (value?.sulphur_percentage === null || value?.sulphur_percentage === undefined) {
                            return this.createError({
                                path: `${schema.path}.sulphur_percentage`,
                                message: commonValidationMessages.required,
                            });
                        }
                    } else {
                        if (value?.density_temperature === null || value?.density_temperature === undefined) {
                            return this.createError({
                                path: `${schema.path}.density_temperature`,
                                message: commonValidationMessages.required,
                            });
                        }
                    }
                    return true;
                }).test(function (value: any, schema: any) {
                    let bukner = this.parent ? this.parent : [];
                    let index = bukner.indexOf(value);
                    if (extraActions[index]?.fuelSubTypePrecedence !== FuelSubTypes.LNGB) {
                        if (value?.viscosity === null || value?.viscosity === undefined) {
                            return this.createError({
                                path: `${schema.path}.viscosity`,
                                message: commonValidationMessages.required,
                            });
                        }
                    }
                    return true;
                }),
            )
        })
    }

    /** Convert entered fuel name into bunkering supply name format  */
    const transformString = (input: string): string => {
        if (input?.split(' ')?.length === 1) {
            return input.split(' ').map(word => word.substring(0, 3).toUpperCase()).join('');
        }
        return input.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
    }

    /** Handle changes of bunkering date */
    const handleBunkeringDateChanges = (e: any, form: any, values: any, index: number, fieldName: any, bunkering_supply_name: any, status: any) => {
        let fuelShortName = transformString(values.bunkering[index]?.vessel_fuel_name);
        let portName = ports.filter((item: any) => item.id === values.bunkering[index]?.port_of_bunkering).map((port: any) => { return port.port_name });
        form.setFieldValue(fieldName, e.target.value);
        form.setFieldValue(status, 'inuse');
        form.setFieldValue(bunkering_supply_name, `${fuelShortName}/${portName}/${ReportID}/${index + 1}/${e.target.value}`);
    }

    /** Post submit actions based on response */
    const handleResponse = () => {
        if (!isFirstReport) {
            loadFuelBatches(VesselID);
            queryClient.invalidateQueries(queryKeyes.vessel.BunkeringObject.key);
            queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
        } else {
            queryClient.invalidateQueries(queryKeyes.vessel.GetOnboardingBunkeringByVessel.key);
        }
        if (env.form_validation === true) {
            if (isFirstReport) {
                toggleTab(activeTab + 1);
            } else {
                setErrorMessage(null);
                toggleDynamicTabs(previousTabIndex + 1);
            }
        }
    }

    /** PUT request for Bunkering */
    const putBunkering = (value: any) => {
        responseArray.push(apiGlobal.put(`${isFirstReport ?
            queryKeyes.vessel.OnboardingBunkering.url() :
            queryKeyes.vessel.Bunkering.url()}${value?.id}/`, value));
    }

    /** POST request for Bunkering */
    const postBunkering = (values: any) => {
        responseArray.push(apiGlobal.post(`${isFirstReport ?
            queryKeyes.vessel.OnboardingBunkering.url() :
            queryKeyes.vessel.Bunkering.url()
            }`, values));
    }

    /** DELETE request for Bunkering */
    const deleteBunkering = (batch: any, values: any) => {/** Total quantity bunkered w.r.t. fuel type */
        let totalBunkering = BunkeringObject.reduce(
            (acc: any, curr: any) => {
                const { fuel_type_name, quantity_bunkered } = curr;
                const quantity = parseFloat(quantity_bunkered);
                if (!isNaN(quantity)) {
                    acc[fuel_type_name] = (acc[fuel_type_name] || 0) + quantity;
                    return acc;
                }
                return '';
            },
            {}
        );
        apiGlobal.delete(`${isFirstReport ?
            queryKeyes.vessel.OnboardingBunkering.url() :
            queryKeyes.vessel.Bunkering.url()}${batch?.id}/`).then(response => {
                if (!isFirstReport) {
                    /** Update fuel ROB */
                    fuelTypeROB?.filter((item: any) =>
                        item?.type_precedence_id === extraActions[deleteIndex]?.fuelTypePrecedence)?.forEach((type: any) => {
                            type.bunkered_quantity = (parseFloat(totalBunkering[type.vessel_fuel]) -
                                parseFloat(BunkeringObject[BunkeringObject?.indexOf(
                                    BunkeringObject?.filter((item: any) => item?.id === deleteBatch?.id)[0])]
                                    ?.quantity_bunkered)).toFixed(4);
                            type.rob = ((type.previous_rob - type.total_fuel_consumed) +
                                (parseFloat(totalBunkering[type.vessel_fuel]) -
                                    parseFloat(BunkeringObject[BunkeringObject?.indexOf(
                                        BunkeringObject?.filter((item: any) => item?.id === deleteBatch?.id)[0])]
                                        ?.quantity_bunkered))).toFixed(4);
                            apiGlobal.patch(`/fo_rob/${type.id}/`, type).then(res => {
                                if (res.status === 200) {
                                    successToast("Data deleted successfully!");
                                    loadFuelBatches(VesselID);
                                    queryClient.invalidateQueries(queryKeyes.vessel.BunkeringObject.key);
                                    queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
                                    values.bunkering.splice(deleteIndex, 1);
                                    setDeletePopoverBool(false);
                                    setDeleteBatch(null);
                                    setDeleteIndex(null);
                                }
                            });
                        })
                } else {
                    if (response.status === 200 || response.status === 204) {
                        queryClient.invalidateQueries(queryKeyes?.vessel?.GetOnboardingBunkeringByVessel?.key)
                        queryClient.invalidateQueries(queryKeyes.vessel.Bunkering.key);
                        successToast("Data deleted successfully!");
                        loadFuelBatches(VesselID);
                        queryClient.invalidateQueries(queryKeyes.vessel.BunkeringObject.key);
                        queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
                        values.bunkering.splice(deleteIndex, 1);
                        setDeletePopoverBool(false);
                        setDeleteBatch(null);
                        setDeleteIndex(null);
                    }
                }
            })
    }

    /** Bunkering submit function */
    const bunkeringSubmit = async (values: any, actions: any) => {
        /** Update quantity remaining, previous quantity remaining, other port & other country  */
        values.bunkering.forEach((bunker: any) => {
            if (!isFirstReport) {
                bunker.port_of_bunkering = port;
                bunker.other_port = otherPortId;
                bunker.other_port_name = otherPortName;
                bunker.other_country = otherCountry;
            }
            bunker.quantity_remaining = bunker?.quantity_bunkered;
            bunker.previous_quantity_remaining = bunker?.quantity_bunkered;
        })
        /** Bunkering submit */
        if (BunkeringObject?.length > 0 && BunkeringObject[0].id > 0) {
            values.bunkering.forEach((bunker: any) => {
                if (bunker.id === undefined) {
                    postBunkering(bunker);
                } else {
                    putBunkering(bunker);
                }
            })
        } else {
            postBunkering(values?.bunkering);
        }
        if (!isFirstReport) {
            /** Total quantity bunkered w.r.t. fuel type */
            let totalBunkering = values.bunkering.reduce(
                (acc: any, curr: any) => {
                    const { fuel_type_name, quantity_bunkered } = curr;
                    const quantity = parseFloat(quantity_bunkered);
                    if (!isNaN(quantity)) {
                        acc[fuel_type_name] = (acc[fuel_type_name] || 0) + quantity;
                        return acc;
                    }
                    return '';
                },
                {}
            );
            /** Update fuel ROB */
            fuelTypeROB?.filter((item: any) => totalBunkering[item.vessel_fuel] > 0)?.map((type: any) => {
                type.bunkered_quantity = totalBunkering[type.vessel_fuel].toFixed(4);
                type.rob = ((type.previous_rob - type.total_fuel_consumed) + totalBunkering[type.vessel_fuel]).toFixed(4);
                const res = apiGlobal.patch(`/fo_rob/${type.id}/`, type);
                responseArray.push(res);
                if (res) {

                }
                return '';
            })
        }
        const response = await handleServerResponse(responseArray);
        if (response === true) {
            handleResponse();
        } else {
            if (!isFirstReport) {
                setErrorMessage(response);
            }
        }
        actions.setSubmitting(false);
    }


    return (
        <React.Fragment>
            <Card className='border-0'>
                <CardHeader className="p-2">
                    <div className="text-center">
                        <Row>
                            <Col>
                                <h4 className="page_title pos-start mb-0">Bunkering</h4>
                                <p className="card-title-desc pos-start">
                                    All readings since last report
                                </p>
                            </Col>
                            <Col>
                            {!isFirstReport && <ReportDetailsHeader />}
                            </Col>
                        </Row>
                    </div>
                </CardHeader>
                {/* <hr className='m-1' /> */}
                {(fuelTypeROBIsLoading || PortsIsLoading || BunkeringObjectLoading || OtherPortsLoading) &&
                    <Loading message='Loading required data!' />}
                {(fuelTypeROBIsError || PortsIsError) && <ErrorComponent message='Unable to load required data!' />}
                {BunkeringObjectError && getintialValueBunkering()}
                {!(fuelTypeROBIsLoading || PortsIsLoading || BunkeringObjectLoading || OtherPortsLoading) &&
                    !(fuelTypeROBIsError || PortsIsError) &&
                    <Formik
                        initialValues={BunkeringFormik.initialValues}
                        validationSchema={env?.form_validation === true ? BunkeringFormik.validationSchema : null}
                        onSubmit={(values, actions) => {
                            if (env?.form_validation === false) {
                                if (isFirstReport) {
                                    toggleTab(activeTab + 1);
                                } else {
                                    setErrorMessage(null);
                                    toggleDynamicTabs(previousTabIndex + 1);
                                }
                            }
                            actions.setSubmitting(true);
                            bunkeringSubmit(values, actions);
                        }}
                        key={BunkeringObject}
                    >
                        {({ values, errors, handleSubmit, handleChange, touched, handleBlur, setErrors, isSubmitting }:
                            { values: any, errors: any, handleSubmit: any, handleChange: any, touched: any, handleBlur: any, setErrors: any, isSubmitting: any }) => (
                            <Form autoComplete="off" onSubmit={handleSubmit} noValidate key={refreshKey}>
                                <CardBody className='px-2 py-0 mt-2'>
                                    <FieldArray name="bunkering">
                                        {({ push }) => (
                                            <React.Fragment>
                                                {!isFirstReport &&
                                                    <Row className='mb-2'>
                                                        <Col sm={4}>
                                                            <Label className='asteric mb-0' for='port_of_bunkering'>Port of bunkering</Label>
                                                            <Field name={`bunkering.0.port_of_bunkering`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <AsyncSelect
                                                                        name={field.name}
                                                                        inputId={'port_of_bunkering'}
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        key={refreshKey}
                                                                        loadOptions={(e: any) => searchPorts(e)}
                                                                        getOptionLabel={e => e.port_name}
                                                                        getOptionValue={e => e.id}
                                                                        onBlur={() => form.setFieldTouched(field.name, true)}
                                                                        onChange={(e: any) => {
                                                                            setPort(e.id);
                                                                            values?.bunkering?.forEach((bunker: any, index: number) => {
                                                                                form.setFieldValue(`bunkering.${index}.port_of_bunkering`, e?.id);
                                                                                form.setFieldValue(`bunkering.${index}.port_of_bunkering_name`, e?.port_name);
                                                                            });
                                                                            if (e?.precedence_id === PortConstant.OTHER && addOtherPort === false) {
                                                                                setOtherPort(true);
                                                                            } else if (e?.precedence_id !== PortConstant.OTHER) {
                                                                                setOtherPort(false);
                                                                                setAddOtherPort(false);
                                                                                setOtherPortId(null);
                                                                                setOtherPortName(null);
                                                                                setOtherCountry(null);
                                                                                if (values?.bunkering && values?.bunkering?.length > 0) {
                                                                                    values?.bunkering?.forEach((bunker: any, index: any) => {
                                                                                        form.setFieldValue(`bunkering.${index}.other_port`, null);
                                                                                        form.setFieldValue(`bunkering.${index}.other_port_name`, null);
                                                                                        form.setFieldValue(`bunkering.${index}.other_country`, null);
                                                                                    });
                                                                                }
                                                                            }
                                                                            setRefreshKey(refreshKey + 1);
                                                                        }}
                                                                        className="select-height"
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyle}
                                                                        defaultValue={
                                                                            values?.bunkering?.[0]?.port_of_bunkering
                                                                                ? {
                                                                                    id: values?.bunkering?.[0]?.port_of_bunkering,
                                                                                    port_name: values?.bunkering?.[0]?.port_of_bunkering_name,
                                                                                }
                                                                                : null
                                                                        }
                                                                        noOptionsMessage={(e: any) => {
                                                                            if (e?.inputValue?.toString()?.length > 2) {
                                                                                return "Please select the Other option and enter the port name in the textbox provided";
                                                                            }
                                                                            return "Please enter the first 3 characters of port name";
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {errors?.bunkering && touched?.bunkering && touched?.bunkering[0]?.port_of_bunkering &&
                                                                errors?.bunkering[0]?.port_of_bunkering && env?.form_validation === true &&
                                                                <ErrorTooltip
                                                                    target={`port_of_bunkering`}
                                                                    message={errors?.bunkering[0]?.port_of_bunkering}
                                                                    open={(errors.bunkering && errors?.bunkering[0]?.port_of_bunkering) ? true : false}
                                                                />
                                                            }
                                                        </Col>
                                                        {otherPort === true && OtherPortsObject &&
                                                            <Col sm={4}>
                                                                <Label className="mb-0 asteric" for='other_port_name_dropdown'>Other port</Label>
                                                                <Field name="bunkering.0.other_port">
                                                                    {({ field, form }: FieldProps) => (
                                                                        <Select
                                                                            name={field.name}
                                                                            inputId='other_port_name_dropdown'
                                                                            options={OtherPortsObject}
                                                                            getOptionLabel={(e: any) => e.port_name}
                                                                            getOptionValue={(e: any) => e.id}
                                                                            onBlur={() => form.setFieldTouched(field.name, true)}
                                                                            onChange={(selectedOption: any) => {
                                                                                values?.bunkering?.forEach((bunker: any, index: any) => {
                                                                                    form.setFieldValue(`bunkering.${index}.other_port`, selectedOption.id);
                                                                                    form.setFieldValue(`bunkering.${index}.other_port_name`, selectedOption?.port_name);
                                                                                    form.setFieldValue(`bunkering.${index}.other_country`, selectedOption?.country);
                                                                                })
                                                                                setOtherPortId(selectedOption?.id);
                                                                                setOtherPortName(selectedOption?.port_name);
                                                                                setOtherCountry(selectedOption?.country)
                                                                            }}
                                                                            defaultValue={
                                                                                values?.bunkering?.[0]?.other_port
                                                                                    ? {
                                                                                        id: values?.bunkering?.[0]?.other_port,
                                                                                        port_name: values?.bunkering?.[0]?.other_port_name,
                                                                                    }
                                                                                    : null
                                                                            }
                                                                            menuPortalTarget={document.body}
                                                                            styles={customStyle}
                                                                            noOptionsMessage={(e: any) => {
                                                                                if (e?.inputValue?.toString()?.length > 2) {
                                                                                    return 'Please select the Other option and enter the port name in the textbox provided'
                                                                                }
                                                                                return 'Please enter the first 3 characters of port name';
                                                                            }}
                                                                        />)}
                                                                </Field>
                                                                <p className='link_color_blue pointer' onClick={() => {
                                                                    setAddOtherPort(true);
                                                                    setOtherPort(false);
                                                                    values?.bunkering?.forEach((bunker: any, index: any) => {
                                                                        values.bunkering[index].other_port = null;
                                                                        values.bunkering[index].other_port_name = null;
                                                                        values.bunkering[index].other_country = null;
                                                                    })
                                                                }}>Click here to add new port</p>
                                                            </Col>
                                                        }
                                                        {(addOtherPort === true || !OtherPortsObject) &&
                                                            <>
                                                                <Col sm={4}>
                                                                    <Label className='mb-0 asteric'>Other port name</Label>
                                                                    <Field name={`bunkering.0.other_port_name`}>
                                                                        {({ form }: FieldProps) => (
                                                                            <Input
                                                                                type='text'
                                                                                name={`bunkering.0.other_port_name`}
                                                                                id='other_port_name'
                                                                                className='form-control'
                                                                                onBlur={(e: any) => {
                                                                                    setOtherPortName(e.target.value);
                                                                                    if (values?.bunkering && values?.bunkering?.length > 0) {
                                                                                        values?.bunkering?.forEach((bunker: any, index: any) => {
                                                                                            form.setFieldValue(`bunkering.${index}.other_port_name`, e.target.value);
                                                                                        })
                                                                                    }
                                                                                }}
                                                                                onChange={(e: any) => {
                                                                                    setOtherPortName(e.target.value);
                                                                                    if (values?.bunkering && values?.bunkering?.length > 0) {
                                                                                        values?.bunkering?.forEach((bunker: any, index: any) => {
                                                                                            form.setFieldValue(`bunkering.${index}.other_port_name`, e.target.value);
                                                                                        })
                                                                                    }
                                                                                }}
                                                                                defaultValue={values?.bunkering[0]?.other_port_name}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[0]?.other_port_name &&
                                                                        errors?.bunkering[0]?.other_port_name && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`other_port_name`}
                                                                            message={errors?.bunkering[0]?.other_port_name}
                                                                            open={(errors && errors?.bunkering[0]?.other_port_name) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Label className='mb-0 asteric'>Other country name</Label>
                                                                    <Field name={`bunkering.0.other_country`}>
                                                                        {({ field, form }: FieldProps) => (
                                                                            <AsyncSelect
                                                                                name={field.name}
                                                                                id={'other_country'}
                                                                                cacheOptions
                                                                                defaultOptions
                                                                                loadOptions={(e: any) => searchCountries(e)}
                                                                                getOptionLabel={(e: any) => e.country_name}
                                                                                getOptionValue={(e: any) => e.id}
                                                                                onBlur={() => {
                                                                                    form.setFieldTouched(field.name, true);
                                                                                }}
                                                                                onChange={(e: any) => {
                                                                                    setOtherCountry(e?.id);
                                                                                    values?.bunkering?.forEach((bunker: any, index: any) => {
                                                                                        form.setFieldValue(`bunkering.${index}.other_country`, e?.id);
                                                                                        form.setFieldValue(`bunkering.${index}.other_country_name`, e?.country_name);
                                                                                    })
                                                                                }}
                                                                                defaultValue={
                                                                                    values?.bunkering?.[0]?.other_country
                                                                                        ? {
                                                                                            id: values?.bunkering?.[0]?.other_country,
                                                                                            country_name: values?.bunkering?.[0]?.other_country_name,
                                                                                        }
                                                                                        : null
                                                                                }
                                                                                className="select-height"
                                                                                menuPortalTarget={document.body}
                                                                                styles={customStyle}
                                                                                noOptionsMessage={() => {
                                                                                    return 'Please enter the first 3 characters of country name';
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[0]?.other_country &&
                                                                        errors?.bunkering[0]?.other_country && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`other_country`}
                                                                            message={errors?.bunkering[0]?.other_country}
                                                                            open={(errors && errors?.bunkering[0]?.other_country) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                            </>
                                                        }
                                                    </Row>
                                                }
                                                {values?.bunkering?.map((value: any, index: number) => {
                                                    return (
                                                        <div key={index}>
                                                            <Row>
                                                                <Col>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <h5 className='mb-2'>{isFirstReport ? 'Batch ' : 'Bunkering '}{index + 1}</h5>
                                                                        {values?.bunkering?.length === 1 ? null :
                                                                            <button type="button" className="btn justify_right">
                                                                                <i className='dripicons-trash icon_s18' id={`delete_bunkering_${index}`}
                                                                                    onClick={() => {
                                                                                        setDeletePopoverBool(true);
                                                                                        setDeleteBatch(value);
                                                                                        setDeleteIndex(index);
                                                                                        setErrors({});
                                                                                    }}
                                                                                />
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="mb-2">
                                                                <Col sm={4}>
                                                                    <Label for={`fuel_type_name-${index}`} className="asteric mb-0">
                                                                        Bunkered fuel type<i className='bx bx-info-circle ml-2p' id='fuel_type_name_msg' />
                                                                    </Label>
                                                                    <ToolTip target='fuel_type_name_msg' message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'fuel_type_name_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <Field name={`bunkering.${index}.fuel_type_name`}>
                                                                        {({ field, form }: FieldProps) => (
                                                                            <Select
                                                                                name={field.name}
                                                                                inputId={`fuel_type_name-${index}`}
                                                                                options={filteredOptions}
                                                                                getOptionLabel={(e: any) => e.fuel_type_name}
                                                                                getOptionValue={(e: any) => e.fuel_type}
                                                                                onChange={(selectedOption: any) => {
                                                                                    let array: any[] = [...extraActions];
                                                                                    form.setFieldValue(field.name, selectedOption.fuel_type);
                                                                                    form.setFieldValue(`bunkering.${index}.fuel_typename`, selectedOption?.fuel_type_name);
                                                                                    form.setFieldValue(`bunkering.${index}.fuelKey`,
                                                                                        (values && parseInt(values?.bunkering[index]?.fuelKey) + 1)
                                                                                    );
                                                                                    form.setFieldValue(`bunkering.${index}.fuelCategory`, selectedOption?.fuel_class_precedence_id)
                                                                                    if (array[index]) {
                                                                                        if (env?.fuel_eu === true &&
                                                                                            selectedOption?.fuel_class_precedence_id !== FuelClassConstant.FOSSIL) {
                                                                                            array[index].fuelClass = true;
                                                                                            form.setFieldValue(`bunkering.${index}.fuelEUParameter`, 'co2_equivalent_factor_WtT');
                                                                                        } else {
                                                                                            array[index].fuelClass = false;
                                                                                            form.setFieldValue(`bunkering.${index}.fuelEUParameter`, null);
                                                                                            form.setFieldValue(`bunkering.${index}.e`, null);
                                                                                            form.setFieldValue(`bunkering.${index}.co2_equivalent_factor_WtT`, null);
                                                                                        }
                                                                                        array[index].fuelSubTypePrecedence = selectedOption.precedence_id_sub_type;
                                                                                        array[index].fuelTypePrecedence = selectedOption.precedence_id;
                                                                                    }
                                                                                    setExtraActions(array);
                                                                                    if (selectedOption.fuel_type !== FuelTypes.FUEL_OIL) {
                                                                                        form.setFieldValue(`bunkering.${index}.fuel_sub_type`, selectedOption.fuel_sub_type);
                                                                                    }
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                styles={customStyle}
                                                                                defaultValue={values?.bunkering[index]?.fuel_type_name > 0 &&
                                                                                {
                                                                                    fuel_type: values?.bunkering[index]?.fuel_type_name,
                                                                                    fuel_type_name: values?.bunkering[index]?.fuel_typename
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.fuel_type_name &&
                                                                        errors?.bunkering[index]?.fuel_type_name && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`fuel_type_name-${index}`}
                                                                            message={errors?.bunkering[index]?.fuel_type_name}
                                                                            open={(errors.bunkering && errors?.bunkering[index]?.fuel_type_name) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Label for={`vessel_fuel_name_${index}`} className="asteric mb-0">
                                                                        Fuel Name<i className='bx bx-info-circle ml-2p' id='vessel_fuel_name_msg' />
                                                                    </Label>
                                                                    <ToolTip target='vessel_fuel_name_msg' message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'vessel_fuel_name_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <Field
                                                                        type="text"
                                                                        id={`vessel_fuel_name_${index}`}
                                                                        name={`bunkering.${index}.vessel_fuel_name`}
                                                                        className="form-control max-width-14"
                                                                    />
                                                                    {errors?.bunkering && errors.bunkering[index]?.vessel_fuel_name &&
                                                                        touched?.bunkering && touched.bunkering[index]?.vessel_fuel_name &&
                                                                        env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`vessel_fuel_name_${index}`}
                                                                            message={errors.bunkering[index]?.vessel_fuel_name}
                                                                            open={
                                                                                (errors?.bunkering && errors.bunkering[index]?.vessel_fuel_name &&
                                                                                    touched?.bunkering && touched.bunkering[index]?.vessel_fuel_name
                                                                                )
                                                                            }
                                                                        />
                                                                    }
                                                                </Col>
                                                                <Col sm={4}><Label className='asteric mb-0' for={`bdn_identification_number_${index}`}>
                                                                    BDN identification number<i className='bx bx-info-circle ml-2p' id='bdn_identification_number_msg' />
                                                                </Label>
                                                                    <ToolTip target='bdn_identification_number_msg' message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'bdn_identification_number_msg').map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className="input-group max-width-13">
                                                                        <Field name={`bunkering.${index}.bdn_identification_number`}>
                                                                            {() => (
                                                                                <Input
                                                                                    name={`bunkering.${index}.bdn_identification_number`}
                                                                                    id={`bdn_identification_number_${index}`}
                                                                                    type='text'
                                                                                    className='form-control text-right'
                                                                                    onBlur={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                    }}
                                                                                    onChange={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                    }}
                                                                                    defaultValue={values.bunkering[index]?.bdn_identification_number}
                                                                                />)}
                                                                        </Field>
                                                                    </div>
                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.bdn_identification_number &&
                                                                        errors?.bunkering[index]?.bdn_identification_number && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`bdn_identification_number_${index}`}
                                                                            message={errors.bunkering[index]?.bdn_identification_number}
                                                                            open={(errors?.bunkering && errors.bunkering[index]?.bdn_identification_number) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-2'>
                                                                <Col>
                                                                    <Label className='asteric mb-0' for={`quantity_bunkered-${index}`}>
                                                                        Quantity bunkered<i className='bx bx-info-circle ml-2p' id='quantity_bunkered_msg' />
                                                                    </Label>
                                                                    <ToolTip target='quantity_bunkered_msg' message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'quantity_bunkered_msg').map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className="input-group max-width-13">
                                                                        <Field name={`bunkering.${index}.quantity_bunkered`}>
                                                                            {({ field, form }: FieldProps) => (
                                                                                <Input
                                                                                    name={field.name}
                                                                                    id={`quantity_bunkered-${index}`}
                                                                                    type='text'
                                                                                    className='form-control text-right'
                                                                                    onBlur={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                        form.setFieldValue(`bunkering.${index}.quantity_remaining`,
                                                                                            e.target.value);
                                                                                    }}
                                                                                    onChange={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                        form.setFieldValue(`bunkering.${index}.quantity_remaining`,
                                                                                            e.target.value);
                                                                                    }}
                                                                                    defaultValue={values?.bunkering[index]?.quantity_bunkered}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <div className="input-group-text round_border">mt</div>
                                                                    </div>
                                                                    {errors?.bunkering && touched.bunkering && touched.bunkering[index]?.quantity_bunkered &&
                                                                        errors.bunkering[index]?.quantity_bunkered && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`quantity_bunkered-${index}`}
                                                                            message={errors.bunkering[index]?.quantity_bunkered}
                                                                            open={(errors?.bunkering && errors.bunkering[index]?.quantity_bunkered) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                                <Col>
                                                                    <Label className='asteric mb-0' for={`density_${index}`}>
                                                                        Fuel Density<i className='bx bx-info-circle ml-2p' id='density_msg' />
                                                                    </Label>
                                                                    <ToolTip target='density_msg' message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'density_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className="input-group">
                                                                        {extraActions[index]?.fuelSubTypePrecedence === FuelSubTypes.LNGB ?
                                                                            <React.Fragment>
                                                                                <div className='ele_row1'>
                                                                                    <div className='input-group'>
                                                                                        <Field name={`bunkering.${index}.density`}>
                                                                                            {({ field }: FieldProps) => (
                                                                                                <Input
                                                                                                    name={field.name}
                                                                                                    id={`density_${index}`}
                                                                                                    type='text'
                                                                                                    className='form-control text-right max-width-7'
                                                                                                    onBlur={(e: any) => {
                                                                                                        handleBlur(e);
                                                                                                        handleChange(e);
                                                                                                    }}
                                                                                                    onChange={(e: any) => {
                                                                                                        handleBlur(e);
                                                                                                        handleChange(e);
                                                                                                    }}
                                                                                                    defaultValue={values?.bunkering[index]?.density}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                        <div className="input-group-text round_border">
                                                                                            kg/m<sup>3</sup>
                                                                                        </div>
                                                                                    </div>
                                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.density &&
                                                                                        errors?.bunkering[index]?.density && env?.form_validation === true &&
                                                                                        <ErrorTooltip
                                                                                            target={`density_${index}`}
                                                                                            message={errors?.bunkering[index]?.density}
                                                                                            open={(errors?.bunkering && errors?.bunkering[index]?.density) ? true : false}
                                                                                        />
                                                                                    }
                                                                                    <div className='mt-2 me-3'>
                                                                                        <strong>@</strong>
                                                                                    </div>
                                                                                    <div>
                                                                                        <div className="input-group">
                                                                                            <Field
                                                                                                name={`bunkering.${index}.density_temperature`}
                                                                                                id={`density_temperature_${index}`}
                                                                                                type='text'
                                                                                                className='form-control text-right'
                                                                                            />
                                                                                            <div className="input-group-text round_border">
                                                                                                <sup>o</sup>C
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.density_temperature &&
                                                                                    errors?.bunkering[index]?.density_temperature && env?.form_validation === true &&
                                                                                    <ErrorTooltip
                                                                                        target={`density_temperature_${index}`}
                                                                                        message={errors?.bunkering[index]?.density_temperature}
                                                                                        open={(errors?.bunkering && errors?.bunkering[index]?.density_temperature) ? true : false}
                                                                                    />}
                                                                            </React.Fragment>
                                                                            :
                                                                            <Col>
                                                                                <div className="input-group">
                                                                                    <Field name={`bunkering.${index}.density`}>
                                                                                        {({ field }: FieldProps) => (
                                                                                            <Input
                                                                                                name={field.name}
                                                                                                id={`density_${index}`}
                                                                                                type='text'
                                                                                                className='form-control text-right max-width-7'
                                                                                                onBlur={(e: any) => {
                                                                                                    handleBlur(e);
                                                                                                    handleChange(e);
                                                                                                }}
                                                                                                onChange={(e: any) => {
                                                                                                    handleBlur(e);
                                                                                                    handleChange(e);
                                                                                                }}
                                                                                                defaultValue={values?.bunkering[index]?.density}
                                                                                            />)
                                                                                        }
                                                                                    </Field>
                                                                                    <div className="input-group-text round_border">
                                                                                        kg/m<sup>3</sup>@15<sup>o</sup>C
                                                                                    </div>
                                                                                </div>
                                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.density &&
                                                                                    errors?.bunkering[index]?.density && env?.form_validation === true &&
                                                                                    <ErrorTooltip
                                                                                        target={`density_${index}`}
                                                                                        message={errors?.bunkering[index]?.density}
                                                                                        open={(errors?.bunkering && errors?.bunkering[index]?.density) ? true : false}
                                                                                    />
                                                                                }
                                                                            </Col>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <Label className={`${extraActions[index]?.fuelSubTypePrecedence !== FuelSubTypes.LNGB ? 'asteric' : null} mb-0`}
                                                                        for={`sulphur_percentage-${index}`}>
                                                                        Sulphur Percentage<i className='bx bx-info-circle ml-2p' id='sulphur_percentage_msg' />
                                                                    </Label>
                                                                    <ToolTip target='sulphur_percentage_msg' message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'sulphur_percentage_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className="input-group max-width-13">
                                                                        <Field name={`bunkering.${index}.sulphur_percentage`}>
                                                                            {({ field }: FieldProps) => (
                                                                                <Input
                                                                                    name={field.name}
                                                                                    id={`sulphur_percentage-${index}`}
                                                                                    type='text'
                                                                                    className='form-control text-right'
                                                                                    onBlur={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                    }}
                                                                                    onChange={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                    }}
                                                                                    defaultValue={values?.bunkering[index]?.sulphur_percentage}
                                                                                    disabled={extraActions[index]?.fuelSubTypePrecedence === FuelSubTypes.LNGB ? true : false}
                                                                                />)}</Field>
                                                                        <div className="input-group-text round_border">% m/m</div>
                                                                    </div>
                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.sulphur_percentage &&
                                                                        errors?.bunkering[index]?.sulphur_percentage && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`sulphur_percentage-${index}`}
                                                                            message={errors?.bunkering[index]?.sulphur_percentage}
                                                                            open={(errors?.bunkering && errors?.bunkering[index]?.sulphur_percentage) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-2'>
                                                                <Col sm={4}>
                                                                    <Label className={`${extraActions[index]?.fuelSubTypePrecedence !== FuelSubTypes.LNGB ? 'asteric' : null} mb-0`} for={`viscosity-${index}`}>
                                                                        Viscosity<i className='bx bx-info-circle ml-2p' id='viscosity_msg' />
                                                                    </Label>
                                                                    <ToolTip target='viscosity_msg' message={`${TooltipMsg.Bunkering
                                                                        .filter((item: any) => item.target === 'viscosity_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className="input-group max-width-13">
                                                                        <Field name={`bunkering.${index}.viscosity`}>
                                                                            {({ field, form }: FieldProps) => (
                                                                                <Input
                                                                                    name={field.name}
                                                                                    id={`viscosity-${index}`}
                                                                                    type='text'
                                                                                    className='form-control text-right'
                                                                                    onBlur={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                        if (values && values.bunkering[index]?.fuel_type_name === FuelTypes.FUEL_OIL) {
                                                                                            form.setFieldValue(`bunkering.${index}.fuel_sub_type`,
                                                                                                e.target.value <= ViscosityConstant ? FuelSubTypes.LFO : FuelSubTypes.HFO
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                    onChange={(e: any) => {
                                                                                        handleBlur(e);
                                                                                        handleChange(e);
                                                                                        if (values && values.bunkering[index]?.fuel_type_name === FuelTypes.FUEL_OIL) {
                                                                                            form.setFieldValue(`bunkering.${index}.fuel_sub_type`,
                                                                                                e.target.value <= ViscosityConstant ? FuelSubTypes.LFO : FuelSubTypes.HFO
                                                                                            )
                                                                                        }
                                                                                    }}
                                                                                    defaultValue={values && values.bunkering[index]?.viscosity}
                                                                                    disabled={extraActions[index]?.fuelSubTypePrecedence === FuelSubTypes.LNGB ? true : false}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <div className="input-group-text round_border">cst@50<sup>o</sup>C</div>
                                                                    </div>
                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.viscosity &&
                                                                        errors?.bunkering[index]?.viscosity && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`viscosity-${index}`}
                                                                            message={errors?.bunkering[index]?.viscosity}
                                                                            open={(errors?.bunkering && errors?.bunkering[index]?.viscosity) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Label for={`date_of_bunkering-${index}`} className="mb-0 asteric">
                                                                        Date of bunkering<i className='bx bx-info-circle ml-2p' id='date_of_bunkering_msg' />
                                                                    </Label><br />
                                                                    <ToolTip target='date_of_bunkering_msg'
                                                                        message={`${TooltipMsg.Bunkering
                                                                            .filter((item: any) => item.target === 'date_of_bunkering_msg')
                                                                            .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <Field name={`bunkering.${index}.date_of_bunkering`}>
                                                                        {({ field, form }: FieldProps) => (
                                                                            <Input
                                                                                name={field.name}
                                                                                id={`date_of_bunkering-${index}`}
                                                                                type='date'
                                                                                className='datetimepicker text-uppercase'
                                                                                min={!isFirstReport ? dateTimeFormat(lastRecord?.local_time, true) : null}
                                                                                max={!isFirstReport ?
                                                                                    dateTimeFormat(record?.local_time as string, true) :
                                                                                    dateTimeFormat(report?.local_time as string, true)
                                                                                }
                                                                                onBlur={() => form.setFieldTouched(field.name, true)}
                                                                                onChange={(e: any) =>
                                                                                    handleBunkeringDateChanges
                                                                                        (
                                                                                            e,
                                                                                            form,
                                                                                            values,
                                                                                            index,
                                                                                            field.name,
                                                                                            `bunkering.${index}.bunkering_supply_name`,
                                                                                            `bunkering.${index}.status`
                                                                                        )}
                                                                                defaultValue={values?.bunkering[index]?.date_of_bunkering}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.date_of_bunkering &&
                                                                        errors.bunkering[index]?.date_of_bunkering && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`date_of_bunkering-${index}`}
                                                                            message={errors.bunkering[index]?.date_of_bunkering}
                                                                            open={(errors?.bunkering && errors.bunkering[index]?.date_of_bunkering) ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-2'>
                                                                {values?.bunkering[index]?.fuelCategory === FuelClassConstant.BIOFUEL &&
                                                                    <React.Fragment>
                                                                        <Col sm={4}>
                                                                            <Label className='mb-0'>
                                                                                CF<sub>NO<sub>2</sub></sub> <i className='bx bx-info-circle ml-2p' id='cfn2o_msg' />
                                                                            </Label>
                                                                            <ToolTip target='cfn2o_msg'
                                                                                message={`${TooltipMsg.Bunkering
                                                                                    .filter((item: any) => item.target === 'cfn2o_msg')
                                                                                    .map((tool: any) => { return tool.message })}`}
                                                                            />
                                                                            <div className="input-group max-width-13">
                                                                                <Field name={`bunkering.${index}.cfn2o`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            name={field.name}
                                                                                            id={`cfn2o-${index}`}
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            onBlur={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            onChange={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            defaultValue={values && values.bunkering[index]?.cfn2o}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                            {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.cfn2o &&
                                                                                errors?.bunkering[index]?.cfn2o && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`cfn2o-${index}`}
                                                                                    message={errors?.bunkering[index]?.cfn2o}
                                                                                    open={(errors?.bunkering && errors?.bunkering[index]?.cfn2o) ? true : false}
                                                                                />
                                                                            }
                                                                        </Col>
                                                                        <Col sm={4}>
                                                                            <Label className='mb-0'>
                                                                                CF<sub>CH<sub>4</sub></sub> <i className='bx bx-info-circle ml-2p' id='cfch4_msg' />
                                                                            </Label>
                                                                            <ToolTip target='cfch4_msg'
                                                                                message={`${TooltipMsg.Bunkering
                                                                                    .filter((item: any) => item.target === 'cfch4_msg')
                                                                                    .map((tool: any) => { return tool.message })}`}
                                                                            />
                                                                            <div className="input-group max-width-13">
                                                                                <Field name={`bunkering.${index}.cfch4`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            name={field.name}
                                                                                            id={`cfch4-${index}`}
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            onBlur={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            onChange={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            defaultValue={values && values.bunkering[index]?.cfch4}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                            {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.cfch4 &&
                                                                                errors?.bunkering[index]?.cfch4 && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`cfch4-${index}`}
                                                                                    message={errors?.bunkering[index]?.cfch4}
                                                                                    open={(errors?.bunkering && errors?.bunkering[index]?.cfch4) ? true : false}
                                                                                />
                                                                            }
                                                                        </Col>
                                                                        <Col sm={4}>
                                                                            <Label className='mb-0'>
                                                                                CF<sub>CO<sub>2</sub></sub> <i className='bx bx-info-circle ml-2p' id='cfco2_msg' />
                                                                            </Label>
                                                                            <ToolTip target='cfco2_msg'
                                                                                message={`${TooltipMsg.Bunkering
                                                                                    .filter((item: any) => item.target === 'cfco2_msg')
                                                                                    .map((tool: any) => { return tool.message })}`}
                                                                            />
                                                                            <div className="input-group max-width-13">
                                                                                <Field name={`bunkering.${index}.cfco2`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <Input
                                                                                            name={field.name}
                                                                                            id={`cfco2-${index}`}
                                                                                            type='text'
                                                                                            className='form-control text-right'
                                                                                            onBlur={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            onChange={(e: any) => {
                                                                                                handleBlur(e);
                                                                                                handleChange(e);
                                                                                            }}
                                                                                            defaultValue={values && values.bunkering[index]?.cfco2}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </div>
                                                                            {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.cfco2 &&
                                                                                errors?.bunkering[index]?.cfco2 && env?.form_validation === true &&
                                                                                <ErrorTooltip
                                                                                    target={`cfco2-${index}`}
                                                                                    message={errors?.bunkering[index]?.cfco2}
                                                                                    open={(errors?.bunkering && errors?.bunkering[index]?.cfco2) ? true : false}
                                                                                />
                                                                            }
                                                                        </Col>
                                                                    </React.Fragment>
                                                                }
                                                            </Row>
                                                            {extraActions[index]?.fuelClass === true && values?.bunkering[index]?.fuelCategory !== FuelClassConstant.FOSSIL &&
                                                                <Row className='mb-2'>
                                                                    <Col sm={4}>
                                                                        <Label for={`lcv-${index}`} className="mb-0 asteric">
                                                                            LCV<i className='bx bx-info-circle ml-2p' id='lcv_msg' />
                                                                        </Label><br />
                                                                        <ToolTip target='lcv_msg'
                                                                            message={`${TooltipMsg.Bunkering
                                                                                .filter((item: any) => item.target === 'lcv_msg')
                                                                                .map((tool: any) => { return tool.message })}`}
                                                                        />
                                                                        <div className="input-group">
                                                                            <Field name={`bunkering.${index}.lcv`}>
                                                                                {({ field }: FieldProps) => (
                                                                                    <Input
                                                                                        name={field.name}
                                                                                        id={`lcv_${index}`}
                                                                                        type='text'
                                                                                        className='form-control text-right max-width-7'
                                                                                        onBlur={(e: any) => {
                                                                                            handleBlur(e);
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        onChange={(e: any) => {
                                                                                            handleBlur(e);
                                                                                            handleChange(e);
                                                                                        }}
                                                                                        defaultValue={values?.bunkering[index]?.lcv}
                                                                                    />)}
                                                                            </Field>
                                                                            <div className="input-group-text round_border">
                                                                                MJ
                                                                            </div>
                                                                        </div>
                                                                        {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.lcv &&
                                                                            errors?.bunkering[index]?.lcv && env?.form_validation === true &&
                                                                            <ErrorTooltip
                                                                                target={`lcv_${index}`}
                                                                                message={errors?.bunkering[index]?.lcv}
                                                                                open={(errors?.bunkering && errors?.bunkering[index]?.lcv) ? true : false}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    <Col sm={4}>
                                                                        <div className='ele_row1'>
                                                                            {values?.bunkering[index]?.fuelCategory === FuelClassConstant.BIOFUEL &&
                                                                                <Field name={`bunkering.${index}.fuelEUParameter`}>
                                                                                    {({ field, form }: FieldProps) => (
                                                                                        <input
                                                                                            className="form-check-input me-2 mt-4"
                                                                                            type="radio"
                                                                                            name={field.name}
                                                                                            id={`co2_equivalent_factor_WtT_${index}_radio`}
                                                                                            defaultChecked={values?.bunkering[index]?.fuelEUParameter === 'co2_equivalent_factor_WtT'}
                                                                                            onChange={() => {
                                                                                                form.setFieldValue(field.name, `co2_equivalent_factor_WtT`);
                                                                                                form.setFieldValue(`bunkering.${index}.e`, null)
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            }
                                                                            <div>
                                                                                <Label for={`co2_equivalent_factor_WtT_${index}`} className=
                                                                                    {`mb-0 ${values && values?.bunkering[index]?.fuelEUParameter === 'co2_equivalent_factor_WtT' ? 'asteric' : null}`}>
                                                                                    CO<sub>2</sub> Equivalent (WtT)<i className='bx bx-info-circle ml-2p' id='co2_equivalent_factor_WtT_msg' />
                                                                                </Label><br />
                                                                                <ToolTip target='co2_equivalent_factor_WtT_msg'
                                                                                    message={`${TooltipMsg.Bunkering
                                                                                        .filter((item: any) => item.target === 'co2_equivalent_factor_WtT_msg')
                                                                                        .map((tool: any) => { return tool.message })}`}
                                                                                />
                                                                                <div className="input-group">
                                                                                    <Field name={`bunkering.${index}.co2_equivalent_factor_WtT`}>
                                                                                        {({ field }: FieldProps) => (
                                                                                            <Input
                                                                                                name={field.name}
                                                                                                id={`co2_equivalent_factor_WtT_${index}`}
                                                                                                type='text'
                                                                                                className='form-control text-right max-width-7'
                                                                                                onBlur={(e: any) => {
                                                                                                    handleBlur(e);
                                                                                                    handleChange(e);
                                                                                                }}
                                                                                                onChange={(e: any) => {
                                                                                                    handleBlur(e);
                                                                                                    handleChange(e);
                                                                                                }}
                                                                                                value={values?.bunkering[index]?.co2_equivalent_factor_WtT || ""}
                                                                                                disabled={(values?.bunkering[index]?.fuelCategory === FuelClassConstant.BIOFUEL &&
                                                                                                    values?.bunkering[index]?.fuelEUParameter === `co2_equivalent_factor_WtT`) ?
                                                                                                    false : true}
                                                                                            />)}
                                                                                    </Field>
                                                                                    <div className="input-group-text round_border">
                                                                                        CO<sub>2</sub> eq
                                                                                    </div>
                                                                                </div>
                                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.co2_equivalent_factor_WtT &&
                                                                                    errors?.bunkering[index]?.co2_equivalent_factor_WtT && env?.form_validation === true &&
                                                                                    <ErrorTooltip
                                                                                        target={`co2_equivalent_factor_WtT_${index}`}
                                                                                        message={errors?.bunkering[index]?.co2_equivalent_factor_WtT}
                                                                                        open={(errors?.bunkering && errors?.bunkering[index]?.co2_equivalent_factor_WtT) ? true : false}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col sm={4}>
                                                                        <div className='ele_row1'>
                                                                            {/* show E File only if fuel class is bio fuel and type is not bio blend */}
                                                                            {values?.bunkering[index]?.fuelCategory === FuelClassConstant.BIOFUEL &&
                                                                                extraActions[index]?.fuelTypePrecedence !== FuelTypes.BIO_BLEND &&
                                                                                <React.Fragment>
                                                                                    <Field name={`bunkering.${index}.fuelEUParameter`}>
                                                                                        {({ field, form }: FieldProps) => (

                                                                                            <input
                                                                                                className="form-check-input me-2 mt-4"
                                                                                                type="radio"
                                                                                                defaultChecked={values?.bunkering[index]?.fuelEUParameter === 'e'}
                                                                                                name={field.name}
                                                                                                id={`e_${index}_radio`}
                                                                                                onChange={() => {
                                                                                                    form.setFieldValue(field.name, `e`);
                                                                                                    form.setFieldValue(`bunkering.${index}.co2_equivalent_factor_WtT`, null)
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                    <div>
                                                                                        <Label for={`e-${index}`} className=
                                                                                            {`mb-0 ${values && values?.bunkering[index]?.fuelEUParameter === 'e' ? 'asteric' : null}`}>
                                                                                            E<i className='bx bx-info-circle ml-2p' id='e_msg' />
                                                                                        </Label><br />
                                                                                        <ToolTip target='e_msg'
                                                                                            message={`${TooltipMsg.Bunkering
                                                                                                .filter((item: any) => item.target === 'e_msg')
                                                                                                .map((tool: any) => { return tool.message })}`}
                                                                                        />
                                                                                        <div className="input-group">
                                                                                            <Field name={`bunkering.${index}.e`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        name={field.name}
                                                                                                        id={`e_${index}`}
                                                                                                        type='text'
                                                                                                        className='form-control text-right max-width-7'
                                                                                                        onBlur={(e: any) => {
                                                                                                            handleBlur(e);
                                                                                                            handleChange(e);
                                                                                                        }}
                                                                                                        onChange={(e: any) => {
                                                                                                            handleBlur(e);
                                                                                                            handleChange(e);
                                                                                                        }}
                                                                                                        value={values?.bunkering[index]?.e || ""}
                                                                                                        disabled={(values?.bunkering[index]?.fuelCategory === FuelClassConstant.BIOFUEL &&
                                                                                                            values?.bunkering[index]?.fuelEUParameter === `e`) ?
                                                                                                            false : true}
                                                                                                    />)}
                                                                                            </Field>
                                                                                            <div className="input-group-text round_border">
                                                                                                MJ
                                                                                            </div>
                                                                                        </div>
                                                                                        {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.e &&
                                                                                            errors?.bunkering[index]?.e && env?.form_validation === true &&
                                                                                            <ErrorTooltip
                                                                                                target={`e_${index}`}
                                                                                                message={errors?.bunkering[index]?.e}
                                                                                                open={(errors?.bunkering && errors?.bunkering[index]?.e) ? true : false}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {isFirstReport &&
                                                                <Row className='mb-2'>
                                                                    <Col sm={4}>
                                                                        <Label className='asteric mb-0' for='port_of_bunkering'>Port of bunkering</Label>
                                                                        <Field name={`bunkering.${index}.port_of_bunkering`}>
                                                                            {({ field, form }: FieldProps) => (
                                                                                <AsyncSelect
                                                                                    key={index}
                                                                                    name={field.name}
                                                                                    inputId={`port_of_bunkering_${index}`}
                                                                                    cacheOptions
                                                                                    defaultOptions
                                                                                    loadOptions={(e: any) => searchPorts(e)}
                                                                                    getOptionLabel={e => e.port_name}
                                                                                    getOptionValue={e => e.id}
                                                                                    onBlur={() => form.setFieldTouched(field.name, true)}
                                                                                    onChange={(e: any) => {
                                                                                        form.setFieldValue(`bunkering.${index}.port_of_bunkering`, e?.id)
                                                                                        if (e?.precedence_id === PortConstant.OTHER && extraActions[index]?.addOtherPort === false) {
                                                                                            extraActions[index].otherPort = true;
                                                                                        } else if (e?.precedence_id !== PortConstant.OTHER) {
                                                                                            form.setFieldValue(`bunkering.${index}.other_port`, null);
                                                                                            form.setFieldValue(`bunkering.${index}.other_port_name`, null);
                                                                                            form.setFieldValue(`bunkering.${index}.other_country`, null);
                                                                                        }
                                                                                    }}
                                                                                    className="select-height"
                                                                                    menuPortalTarget={document.body}
                                                                                    styles={customStyle}
                                                                                    defaultValue={(BunkeringObject && BunkeringObject?.length > 0 && BunkeringObject[index]?.id > 0) &&
                                                                                    {
                                                                                        id: BunkeringObject[index]?.port_of_bunkering,
                                                                                        port_name: BunkeringObject[index]?.port_of_bunkering_name
                                                                                    }}
                                                                                    noOptionsMessage={(e: any) => {
                                                                                        if (e?.inputValue?.toString()?.length > 2) {
                                                                                            return 'Please select the Other option and enter the port name in the textbox provided'
                                                                                        }
                                                                                        return 'Please enter the first 3 characters of port name';
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.port_of_bunkering &&
                                                                            errors?.bunkering[index]?.port_of_bunkering && env?.form_validation === true &&
                                                                            <ErrorTooltip
                                                                                target={`port_of_bunkering_${index}`}
                                                                                message={errors?.bunkering[index]?.port_of_bunkering}
                                                                                open={(errors.bunkering && errors?.bunkering[index]?.port_of_bunkering) ? true : false}
                                                                            />
                                                                        }
                                                                    </Col>
                                                                    {extraActions[index]?.otherPort === true &&
                                                                        <Col sm={4}>
                                                                            <Label className="mb-0 asteric" for='other_port_name_dropdown'>Other port</Label>
                                                                            <Field name="bunkering.0.other_port">
                                                                                {({ field, form }: FieldProps) => (
                                                                                    <Select
                                                                                        name={field.name}
                                                                                        inputId='other_port_name_dropdown'
                                                                                        options={OtherPortsObject}
                                                                                        getOptionLabel={(e: any) => e.port_name}
                                                                                        getOptionValue={(e: any) => e.id}
                                                                                        onBlur={() => form.setFieldTouched(field.name, true)}
                                                                                        onChange={(selectedOption: any) => {
                                                                                            form.setFieldValue(`bunkering.${index}.other_port`, selectedOption.id);
                                                                                            form.setFieldValue(`bunkering.${index}.other_port_name`, selectedOption?.port_name);
                                                                                            form.setFieldValue(`bunkering.${index}.other_country`, selectedOption?.country);
                                                                                        }}
                                                                                        defaultValue={BunkeringObject && BunkeringObject[index]?.id &&
                                                                                        {
                                                                                            id: BunkeringObject[index]?.other_port,
                                                                                            port_name: BunkeringObject[index]?.other_port_name
                                                                                        }}
                                                                                        menuPortalTarget={document.body}
                                                                                        styles={customStyle}
                                                                                        noOptionsMessage={(e: any) => {
                                                                                            if (e?.inputValue?.toString()?.length > 2) {
                                                                                                return 'Please select the Other option and enter the port name in the textbox provided'
                                                                                            }
                                                                                            return 'Please enter the first 3 characters of port name';
                                                                                        }}
                                                                                    />)}
                                                                            </Field>
                                                                            <p className='link_color_blue pointer' onClick={() => {
                                                                                let arr = [...extraActions];
                                                                                arr[index].addOtherPort = true;
                                                                                arr[index].otherPort = false;
                                                                                setExtraActions(arr);
                                                                                values.bunkering[index].other_port = null;
                                                                                values.bunkering[index].other_port_name = null;
                                                                                values.bunkering[index].other_country = null;
                                                                            }}>Click here to add new port</p>
                                                                        </Col>
                                                                    }
                                                                    {(extraActions[index]?.addOtherPort === true || !OtherPortsObject) &&
                                                                        <>
                                                                            <Col sm={4}>
                                                                                <Label className='mb-0 asteric'>Other port name</Label>
                                                                                <Field name={`bunkering.${index}.other_port_name`}>
                                                                                    {({ form }: FieldProps) => (
                                                                                        <Input
                                                                                            type='text'
                                                                                            name={`bunkering.${index}.other_port_name`}
                                                                                            id='other_port_name'
                                                                                            className='form-control'
                                                                                            onBlur={(e: any) => {
                                                                                                form.setFieldValue(`bunkering.${index}.other_port_name`, e.target.value);
                                                                                            }}
                                                                                            onChange={(e: any) => {
                                                                                                form.setFieldValue(`bunkering.${index}.other_port_name`, e.target.value);
                                                                                            }}
                                                                                            defaultValue={values?.bunkering[index]?.other_port_name}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.other_port_name &&
                                                                                    errors?.bunkering[index]?.other_port_name && env?.form_validation === true &&
                                                                                    <ErrorTooltip
                                                                                        target={`other_port_name`}
                                                                                        message={errors?.bunkering[index]?.other_port_name}
                                                                                        open={(errors && errors?.bunkering[index]?.other_port_name) ? true : false}
                                                                                    />
                                                                                }
                                                                            </Col>
                                                                            <Col sm={4}>
                                                                                <Label className='mb-0 asteric'>Other country name</Label>
                                                                                <Field name={`bunkering.${index}.other_country`}>
                                                                                    {({ field, form }: FieldProps) => (
                                                                                        <AsyncSelect
                                                                                            name={field.name}
                                                                                            id={'other_country'}
                                                                                            cacheOptions
                                                                                            defaultOptions
                                                                                            loadOptions={(e: any) => searchCountries(e)}
                                                                                            getOptionLabel={(e: any) => e.country_name}
                                                                                            getOptionValue={(e: any) => e.id}
                                                                                            onBlur={() => {
                                                                                                form.setFieldTouched(field.name, true);
                                                                                            }}
                                                                                            onChange={(e: any) => {
                                                                                                form.setFieldValue(`bunkering.${index}.other_country`, e?.id);
                                                                                                form.setFieldValue(`bunkering.${index}.other_country_name`, e?.country_name);
                                                                                            }}
                                                                                            defaultValue={
                                                                                                values?.bunkering?.[0]?.other_country
                                                                                                    ? {
                                                                                                        id: values?.bunkering?.[0]?.other_country,
                                                                                                        country_name: values?.bunkering?.[0]?.other_country_name,
                                                                                                    }
                                                                                                    : null
                                                                                            }
                                                                                            className="select-height"
                                                                                            menuPortalTarget={document.body}
                                                                                            styles={customStyle}
                                                                                            noOptionsMessage={() => {
                                                                                                return 'Please enter the first 3 characters of country name';
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                                {errors?.bunkering && touched?.bunkering && touched?.bunkering[index]?.other_country &&
                                                                                    errors?.bunkering[index]?.other_country && env?.form_validation === true &&
                                                                                    <ErrorTooltip
                                                                                        target={`other_country`}
                                                                                        message={errors?.bunkering[index]?.other_country}
                                                                                        open={(errors && errors?.bunkering[index]?.other_country) ? true : false}
                                                                                    />
                                                                                }
                                                                            </Col>
                                                                        </>
                                                                    }
                                                                </Row>
                                                            }
                                                            {index === (values.bunkering.length - 1) && values.bunkering.length < 10 &&
                                                                <Label
                                                                    className="link_color_blue mt-2 mb-0"
                                                                    onClick={() => {
                                                                        push({
                                                                            fuelKey: values.bunkering.length,
                                                                            fuelCategory: null,
                                                                            fuelEUParameter: null,
                                                                            e: null,
                                                                            co2_equivalent_factor_WtT: null,
                                                                            lcv: null,
                                                                            precedence_id: null,
                                                                            precedence_id_type: null,
                                                                            bdn_identification_number: null,
                                                                            quantity_bunkered: null,
                                                                            bunkering_supply_name: '',
                                                                            viscosity: null,
                                                                            density_temperature: null,
                                                                            quantity_remaining: 0,
                                                                            previous_quantity_remaining: 0,
                                                                            status: '',
                                                                            full_consumption_date: null,
                                                                            sulphur_percentage: null,
                                                                            density: null,
                                                                            date_of_bunkering: '',
                                                                            vessel_fuel_name: '',
                                                                            fuel_type_name: null as number,
                                                                            fuel_sub_type: null,
                                                                            port_of_bunkering: values.bunkering[0].port_of_bunkering,
                                                                            port_of_bunkering_name: values.bunkering[0].port_of_bunkering_name,
                                                                            other_port: values.bunkering[0].other_port,
                                                                            other_port_name: values.bunkering[0].other_port_name,
                                                                            other_country: values.bunkering[0].other_country,
                                                                            other_country_name: values.bunkering[0].other_country_name,
                                                                            vessel: VesselID,
                                                                            voyage_information: VoyageID,
                                                                            vessel_reporting_information: ReportID
                                                                        });
                                                                        let array: any[] = [...extraActions];
                                                                        array.push({
                                                                            fuelClass: false,
                                                                            fuelSubTypePrecedence: 0,
                                                                            fuelTypePrecedence: 0,
                                                                            otherPort: false,
                                                                            addOtherPort: false
                                                                        })
                                                                        setExtraActions(array);
                                                                    }
                                                                    }
                                                                >
                                                                    Add another {!isFirstReport ? 'bunkering' : 'batch'}
                                                                </Label>
                                                            }
                                                            {values?.bunkering?.length > 1 &&
                                                                <hr />
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                    {deletePopoverBool && deleteBatch && values?.bunkering?.length > 1 &&
                                        <DeletePopOver
                                            state={deletePopoverBool}
                                            setState={setDeletePopoverBool}
                                            target={`delete_bunkering_${deleteIndex}`}
                                            onClick={() => {
                                                if (touched?.bunkering) {
                                                    touched.bunkering[deleteIndex] = {};
                                                }
                                                if (deleteBatch?.id > 0) {
                                                    deleteBunkering(deleteBatch, values);
                                                } else {
                                                    values?.bunkering?.splice(deleteIndex, 1);
                                                    let array: any[] = [...extraActions];
                                                    array.splice(deleteIndex, 1);
                                                    setExtraActions(array);
                                                    setDeletePopoverBool(false);
                                                    setDeleteBatch(null);
                                                    setDeleteIndex(null);
                                                    setRefreshKey(refreshKey + 1)
                                                }
                                            }}
                                            message={`Are you sure you want to delete this bunkering?`}
                                        />
                                    }
                                </CardBody>
                                <CardFooter className='p-2 py-3'>
                                    {isFirstReport ?
                                        <Row>
                                            <NavigationButtons
                                                activeTab={activeTab}
                                                toggleTab={toggleTab}
                                                isSubmitting={isSubmitting}
                                            />
                                        </Row>
                                        :
                                        <Row className="ele_row1">
                                            <div className="d-flex flex-wrap gap-5">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className='btn_size_cstm pos-end'
                                                    onClick={() => {
                                                        values.bunkering.forEach((bunker: any) => {
                                                            if (values.bunkering[0]?.port_of_bunkering === 0 || values.bunkering[0]?.port_of_bunkering === null) {
                                                                bunker.port_of_bunkering = port;
                                                            } else {
                                                                bunker.port_of_bunkering = values.bunkering[0]?.port_of_bunkering;
                                                            }
                                                        })
                                                    }}
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                    Next <i className="bx bx-chevron-right ms-1" />
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn_size_cstm"
                                                    onClick={() => {
                                                        handleDynamicPreviousTabId(
                                                            true,
                                                            tabsIdList,
                                                            record,
                                                            toggleTab,
                                                            toggleDynamicTabs,
                                                            previousTabIndex,
                                                            tabsList,
                                                            "Bunkering"
                                                        )
                                                    }}
                                                >
                                                    <i className="bx bx-chevron-left me-1"
                                                        onClick={() => !isFirstReport && setErrorMessage(null)}
                                                    /> Previous</Button>
                                            </div>
                                        </Row>
                                    }
                                </CardFooter>
                                <FormValuesDebug values={[values, errors, extraActions, touched]} />
                            </Form>
                        )}
                    </Formik >
                }
            </Card>
        </React.Fragment >
    )
}

export default BunkeringComponent