import env from "environment_system/env_system";

export const setAuthData = (data: any) => {
  if (data) {
    localStorage.setItem('authData', data);
    sessionStorage.setItem('authData', data);
  }
};

export const isLoggedIn = () => {
  const authData: string | null = localStorage.getItem("authData");
  const sessionAuthData: string | null = sessionStorage.getItem("authData");
  const token = JSON.parse(authData)?.token;
  const sessionToken = JSON.parse(sessionAuthData)?.token;
  if (token && sessionToken) {
    return true;
  }
  return false;
};

export const getAuthData = (param: string = "") => {
  let authString = localStorage.getItem("authData") ?? "";
  if (authString) {
    let authArray = JSON.parse(authString) ?? [];
    if (param) {
      return authArray[param] ?? [];
    }
    return authArray;
  } else {
    if (window.location.pathname !== '/login') {
      window.location.href = "/login";
    }
  }
  return null;
};

export const getAuthSessionData = (param: string = "") => {
  let authString = sessionStorage.getItem("authData") ?? "";
  if (authString) {
    let authArray = JSON.parse(authString) ?? [];
    if (param) {
      return authArray[param] ?? [];
    }
    return authArray;
  } else {
    if (window.location.pathname !== '/login') {
      window.location.href = "/login";
    }
  }
};

export const clearLogout = () => {
  localStorage.clear();
  sessionStorage.clear();
};

// handle rolesby offline or online
export const hasRole = (role: string) => {
  if (env.desktop_app) {
    let roles = getAuthData("roles");
    return role === roles?.role_constant;
  } else {
    const roles = getAuthData("roles");
    return roles.some(
      (item: { role_constant: string }) => item.role_constant === role
    );
  }
};
/**
 * Returns array of vessel's ids assigned to user
 * @returns Array
 */
export const userVessels = () => {
  return getAuthData("vessels");
};

/**
 * Returns id of logged in user
 * @returns Number
 */
export const loggedInUser = () => {
  return getAuthData("user_id");
};
