import React, { useState } from 'react';
import {
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Card,
    Navbar
} from 'reactstrap';
import classnames from 'classnames';
import Layout from 'HorizontalMenu/Menu';
import TutorialVideos from './TutorialVideos';
import FAQ from './FAQ';
import { useDocumentTitle } from 'Components/useDocument.hooks';

const HelpComponent: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('1');
    useDocumentTitle('Help Center - ecoSAIL');

    const toggle = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <Container className="mt-4">
            <Layout children={Navbar} />
            <div className="page-content">
                <Card className='p-2'>
                    <h3 className="mb-4">Help Center</h3>
                    <Nav tabs className='border-0'>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab === '1',
                                })}
                                onClick={() => { toggle('1'); }}
                            >
                                Video Tutorials
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab === '2',
                                })} onClick={() => { toggle('2'); }}
                            >
                                FAQ
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="mt-3">
                        {/* Video Player Tab */}
                        <TabPane tabId="1">
                            <TutorialVideos />
                        </TabPane>
                        <TabPane tabId="2">
                            <FAQ />
                        </TabPane>
                    </TabContent>
                </Card>
            </div >
        </Container>
    );
};

export default HelpComponent;
