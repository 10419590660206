import React, { useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

interface VideoItem {
  id: string;
  title: string;
  description: string;
  url: string;
}

const TutorialVideos: React.FC = () => {
  const [selectedVideo, setSelectedVideo] = useState<string | null>('video1');

  // Sample video data
  const videos: VideoItem[] = [
    {
      id: 'video1',
      title: 'Getting Started',
      description: 'Learn the basics of our platform',
      url: 'https://example.com/video1.mp4',
    },
    {
      id: 'video2',
      title: 'Advanced Features',
      description: 'Discover advanced features and tools',
      url: 'https://example.com/video2.mp4',
    },
    {
      id: 'video3',
      title: 'Tips and Tricks',
      description: 'Optimize your workflow with these tips',
      url: 'https://example.com/video3.mp4',
    },
  ];

  const selectedVideoData = videos.find((v) => v.id === selectedVideo);

  return (
    <Row>
      <Col md="4">
        <h4 className="mb-3">Tutorial Videos</h4>
        <div className="video-list">
          {videos.map((video) => (
            <Card
              key={video.id}
              className={`mb-2 ${selectedVideo === video.id ? 'border-primary' : ''}`}
              onClick={() => setSelectedVideo(video.id)}
              style={{ cursor: 'pointer' }}
            >
              <CardBody>
                <CardTitle tag="h5">{video.title}</CardTitle>
                <p className="mb-0">{video.description}</p>
              </CardBody>
            </Card>
          ))}
        </div>
      </Col>
      <Col md="8">
        <div className="video-player">
          <Row>
            {/* Video Section (Left) */}
            <Col md={8} className="d-flex flex-column">
              <Card className="mb-1 border-0 shadow mb-0">
                <h3 className="text-left">{selectedVideoData?.title}</h3>
              </Card>
              <div
                style={{
                  width: '100%',
                  maxHeight: '600px',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                }}
              >
                {selectedVideoData && (
                  <video
                    controls
                    className="embed-responsive-item"
                    src={selectedVideoData.url}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                )}
              </div>
            </Col>
            <Col md={4}>
              <Card className="mt-3 p-3 border-0 shadow" style={{ height: '100%' }}>
                <p className="text-left">{selectedVideoData?.description}</p>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default TutorialVideos;
