import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button, Card, CardBody, CardFooter, Col, Input, Row, Label } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadCargoCoolingWithkWh } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { FormikProps, useFormik } from 'formik';
import { errResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { queryClient } from 'react-query/queryClient';
import { useEffect } from 'react';
import { SpOpsConfigurationConstant } from 'shared/constants';
import isEqual from "fast-deep-equal";
import React from 'react';

interface CargoCoolingType {
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    activeTab?: any;
    setTabFormik?: (value: FormikProps<any>) => void;
    refreshForm:boolean;
    refreshVesselMachineries:number;
}

const CargoCooling = ({
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    setFormik,
    activeTab,
    setTabFormik,
    refreshForm,
    refreshVesselMachineries,
}: CargoCoolingType) => {
    /** Get the VesselID from the Redux store */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /** 
     * Fetches cargo cooling machinery data with kWh meter information. 
     * Uses the `useQuery` hook to load the cargo cooling machinery associated with the vessel.
     */
    const { data: CargoCooling, isLoading: CargoCoolingLoading, isError: CargoCoolingError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CargoCoolingWithkWhMachineries.key, VesselID],
            async () => { return await loadCargoCoolingWithkWh(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** 
     * Initializes the form values based on the cargo cooling data.
     * 
     * @returns Cargo cooling data to be used as initial values in Formik.
     */
    const getCargoCoolingInitailValues = () => {
        if (CargoCooling?.length > 0) {
            return CargoCooling;
        }
    }

    /** Formik configuration object for cargo cooling form */
    const CargoCoolingFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            cargoCooling: getCargoCoolingInitailValues()
        },
        onSubmit: () => {
            CargoCoolingFormik.setSubmitting(false)
            CargoCoolingFormik?.values?.cargoCooling?.forEach((val: any) => {
                apiGlobal.put(`/special_operation_energy_setting/${val.id}/`, val)
                    .then(res => {
                        if (res.status === 200) {
                            successToast("Data saved successfully!");
                            queryClient.invalidateQueries(queryKeyes.vessel.CargoCoolingWithkWhMachineries.key);
                        }
                    })
                    .catch(err => {
                        if (errResponse.includes(err?.response?.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    });
            })
        }
    })

    const tabName = 'Cargo Cooling';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = isEqual(values,initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
    };

    /**Start of UseEffect */
    useEffect(() => {
        if (activeTab === SpOpsConfigurationConstant.SpOpsCargoCoolingId && CargoCoolingFormik.values !== CargoCoolingFormik.initialValues) {
            setFormik(CargoCoolingFormik);
            setTabFormik(CargoCoolingFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab,  CargoCoolingFormik]);
    useEffect(() => {
        handleFormikStateChange(CargoCoolingFormik?.values, CargoCoolingFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CargoCoolingFormik?.values, CargoCoolingFormik?.initialValues]);

    if (CargoCoolingLoading) {
        return <Loading message='Loading required data!' />
    } else if (CargoCoolingError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
    return (
        <React.Fragment>
            <Card className='border-0'>
                {CargoCoolingLoading && <Loading message="Loading required data!" />}
                {CargoCoolingError && <ErrorComponent message="Error loading required data!" />}
                {!(CargoCoolingLoading) && !(CargoCoolingError) &&
                    <form onSubmit={CargoCoolingFormik?.handleSubmit} noValidate autoComplete='off'>
                        <CardBody className='p-0'>
                            <Row>
                                <Col sm={12}>
                                    <div className="table-responsive p-0">
                                        <table className="table mb-2" key={`${refreshVesselMachineries}-${refreshForm}-cargo-cooling`}>
                                            <thead className="table-light">
                                                <tr>
                                                    <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                    <th className='p-2 align-middle'>Machinery name</th>
                                                    <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {CargoCoolingFormik.initialValues &&
                                                        CargoCoolingFormik?.initialValues?.cargoCooling?.map((cargoCooling: any, index: number) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                    <td className='p-2 align-middle'>{cargoCooling?.vessel_machinery_ec_group_name}</td>
                                                                    <td className='p-2 align-middle text-center'>
                                                                        <div className="square-switch sqswitch mt-1">
                                                                            <Input
                                                                                type="checkbox"
                                                                                switch="none"
                                                                                name={`cargoCooling.${index}.cargo_cooling_kwh_meter`}
                                                                                id={`cargo_cooling_kwh_meter_${index}`}
                                                                                defaultChecked={CargoCoolingFormik?.values?.cargoCooling[index]?.cargo_cooling_kwh_meter}
                                                                                onChange={(e: any) => CargoCoolingFormik?.setFieldValue(`cargoCooling.${index}.cargo_cooling_kwh_meter`, e.target.checked)}
                                                                            />
                                                                            <Label
                                                                                htmlFor={`cargo_cooling_kwh_meter_${index}`}
                                                                                data-on-label="Yes"
                                                                                data-off-label="No"
                                                                                className="mb-0"
                                                                            ></Label>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className='p-2 py-3 mb-3'>
                            <Button type="submit" color='primary' className='justify_right' disabled={isConfigurationButtonDisabled(vessel)}>Save</Button>
                        </CardFooter>
                        <Row className='mt-2'>
                            <FormValuesDebug values={[CargoCoolingFormik?.values, CargoCoolingFormik?.errors, CargoCoolingFormik.initialValues]} />
                        </Row>
                    </form>
                }
            </Card>
        </React.Fragment>
    )}
}
export default CargoCooling;