/* eslint-disable no-restricted-syntax */
import ErrorComponent from "Components/ErrorComponent";
import Loading from "Components/Loading";
import Layout from "HorizontalMenu/Menu";
import { useState } from "react";
import { useQuery } from "react-query";
import { Card, Navbar, Table } from "reactstrap";
import { queryKeyes } from "shared/queryKeys";
import {
  syncFileStatus,
  syncFileStatusByFileName,
} from "VesselMaster/vesselMaster.hooks";

const SyncReport = () => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFileData, setSelectedFileData] = useState<any>(null);


  // Queries for fetching all file status
  const {
    data: syncReportObj = [],
    isLoading: syncReportLoading,
    isError: syncReportError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.sync.SyncFileStatusList],
    async () => await syncFileStatus(),
    {
      enabled: true,
      staleTime: Infinity,
    }
  );

  // Function to handle row click
  const handleRowClick = async (file: any) => {
    setSelectedFile(file); // store selected file
    try {
      // Fetch file-specific data using the file name
      const fileData = await syncFileStatusByFileName(file.file_name);
      setSelectedFileData(fileData);
    } catch (error) {
      console.error("Error fetching file-specific data:", error);
      setSelectedFileData(null); // Handle error and clear data
    }
  };

  return (
    <>
      <Layout children={Navbar} />
      <div className="page-content">
        <div className="m-3">
          <button className="m-3 btn btn-primary waves-effect waves-light w-sm">
            Button 1
          </button>
          <button className="m-3 btn btn-primary waves-effect waves-light w-sm">
            Button 2
          </button>
          <button className="m-3 btn btn-primary waves-effect waves-light w-sm">
            Button 3
          </button>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Card className="p-3">
              {syncReportLoading && <Loading message="Loading required data!" />}
              {syncReportError && (<ErrorComponent message="Error loading data" />)}
              <Table className="table-striped table-bordered">
                <thead>
                  <tr>
                    <th className="thead-dark text-left col-6 p-3">Files</th>
                    <th className="thead-dark text-center col-3 p-3">
                      Files Local Stage
                    </th>
                    <th className="thead-dark text-center col-3 p-3">
                      Files Server Stage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {syncReportObj && syncReportObj.length > 0 ? (
                    syncReportObj
                      .filter(
                        (item: any) => item.current_offline_stage !== "CMPT"
                      )
                      .map((item: any) => {
                        const serverStageClassMap: { [key: string]: string } = {
                          SPNDG: "bg-light badge text-center",
                          SPRCN: "bg-dark badge text-center",
                          SVLD: "bg-warning badge text-center",
                          SCMPT: "bg-success badge text-center",
                          SRJCT: "bg-danger badge text-center",
                        };
                        const offlineStageClassMap: { [key: string]: string } = {
                          PNDG: "bg-light badge text-center",
                          UPDN: "bg-secondary badge text-center",
                          UPLDD: "bg-warning badge text-center",
                          PRCN: "bg-dark badge text-center",
                          CMPT: "bg-success badge text-center",
                          RJCT: "bg-danger badge text-center",
                        };
                        const serverStageClass =
                          serverStageClassMap[item.current_server_stage] || "";
                        const offlineStageClass =
                          offlineStageClassMap[item.current_offline_stage] || "";

                        return (
                          <tr
                            key={item.id}
                            className="align-middle"
                            onClick={() => handleRowClick(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <td className="text-left align-middle col-6 p-3">
                              {item.file_name}
                            </td>
                            <td className={`text-center align-middle col-3 p-3`}>
                              <span className={offlineStageClass}>
                                {item.current_offline_stage}
                              </span>
                            </td>
                            <td className={`text-center align-middle col-3 p-3`}>
                              <span className={serverStageClass}>
                                {item.current_server_stage}
                              </span>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center p-3">
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </div>
          <div className="col-md-6">
            <Card className="p-3">
              {selectedFileData ? (
                <div>
                  <h5>File Details for {selectedFile?.file_name}</h5>
                  <pre>{JSON.stringify(selectedFileData, null, 2)}</pre>
                </div>
              ) : (
                <div>Select a file to see details</div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SyncReport;