import ErrorTooltip from 'Components/ErrorTooltip';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { Field, useFormikContext } from 'formik';
import React from 'react'
import { Card, CardHeader, Row, Col, CardBody, Label, Input } from 'reactstrap';
import env from "environment_system/env_system";

interface BallastDetailsPorps {
    alertBool: boolean;
}

const BallastDetails = ({ alertBool }: BallastDetailsPorps) => {
    const { values, errors, touched, handleChange, handleBlur }:
        { values: any, errors: any, touched: any; handleChange: any, handleBlur: any } = useFormikContext<any>();
    return (
        <Card className="p-0 mb-0 rounded-0 border-start-0 border-end-0">
            <CardHeader className="px-0 py-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Ballast Details
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <CardBody className="ps-2 pr-2 pt-0 pb-0 mt-2">
                <Row>
                    <Col sm={2} className="px-0">
                        <Label className="asteric mb-0" for="permanent">
                            Permanent ballast
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="permanent_msg"
                            ></i>
                        </Label>
                        <ToolTip
                            target="permanent_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "permanent_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group mb-3 ">
                            <Field name="ballast_details.permanent">
                                {() => (
                                    <Input
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id="permanent"
                                        name="ballast_details.permanent"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        defaultValue={
                                            values?.ballast_details?.permanent
                                        }
                                    />
                                )}
                            </Field>
                            <div className="input-group-text round_border">
                                mt
                            </div>
                            {errors?.ballast_details &&
                                touched?.ballast_details?.permanent &&
                                errors?.ballast_details?.permanent &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="permanent"
                                        message={errors?.ballast_details?.permanent}
                                        open={
                                            errors?.ballast_details &&
                                                errors?.ballast_details?.permanent
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </div>
                    </Col>
                    <Col sm={2} className="px-0">
                        <Label className="asteric mb-0" for="addition">
                            Additional ballast
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="addition_msg"
                            ></i>
                        </Label>
                        <ToolTip
                            target="addition_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "addition_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group mb-3">
                            <Field name="ballast_details.addition">
                                {() => (
                                    <Input
                                        type="text"
                                        className="form-control text-right max-width-7 border-end-0"
                                        id="addition"
                                        name="ballast_details.addition"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        defaultValue={
                                            values?.ballast_details?.addition
                                        }
                                    />
                                )}
                            </Field>
                            <div className="input-group-text round_border">
                                mt
                            </div>
                            {errors?.ballast_details &&
                                touched?.ballast_details?.addition &&
                                errors?.ballast_details?.addition &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="addition"
                                        message={errors?.ballast_details?.addition}
                                        open={
                                            errors?.ballast_details &&
                                                errors?.ballast_details?.addition
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </div>
                    </Col>
                    <Col sm={3} className="px-0">
                        <Label className="asteric mb-0" for="total">
                            Total ballast
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="total_msg"
                            ></i>
                        </Label>
                        <ToolTip
                            target="total_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) => item.target === "total_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group mb-3">
                            <Field name="ballast_details.total">
                                {() => (
                                    <Input
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id="total"
                                        name="ballast_details.total"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        defaultValue={values?.ballast_details?.total}
                                    />
                                )}
                            </Field>
                            <div className="input-group-text round_border">
                                mt
                            </div>
                            {errors?.ballast_details &&
                                touched?.ballast_details?.total &&
                                errors?.ballast_details?.total &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="total"
                                        message={errors?.ballast_details?.total}
                                        open={
                                            errors?.ballast_details &&
                                                errors?.ballast_details?.total
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}

export default BallastDetails