import ErrorTooltip from 'Components/ErrorTooltip';
import FuelAdjustmentPopup from 'Components/FuelAdjustmentPopup';
import env from 'environment_system/env_system';
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik';
import React from 'react'
import { Row, Card, CardHeader, Col, CardBody, Label, Input } from 'reactstrap';
import { FuelTypes } from 'shared/constants';
interface FuelConsumptionBatchComponentType {
    fuelTypes: any;
    loadFuelConsumptionSum: any;
    FuelROBObject: any;
    PreviousFuelROB: any;
    loadFuelBatchConsumptionSum: any;
    loadFuelBatchAdjustmentSum: any;
    finalBatchConsumptionArray: any;
    totalLNGBunkerROB: any;
    handleFuelBatchConsumptionChanges: any;
    BatchConsumptionObject: any;
    setFuelBatchIndex: any;
    setAdjustmentPopup: any;
    setBunker: any;
    adjustmentPopup: any;
    bunker: any;
    handleFuelBatchROB: any;
    tog_backdrop: any;
    fuelBatchIndex: any;
}

const FuelConsumptionBatchComponent = ({ fuelTypes, loadFuelConsumptionSum, FuelROBObject, PreviousFuelROB,
    loadFuelBatchConsumptionSum, loadFuelBatchAdjustmentSum, finalBatchConsumptionArray, totalLNGBunkerROB,
    handleFuelBatchConsumptionChanges, BatchConsumptionObject, setFuelBatchIndex, setAdjustmentPopup, setBunker, adjustmentPopup,
    bunker, handleFuelBatchROB, tog_backdrop, fuelBatchIndex
}: FuelConsumptionBatchComponentType) => {
    const { values, errors, touched, handleChange, handleBlur }:
        { values: any, errors: any, touched: any, handleChange: any, handleBlur: any } = useFormikContext<any>();
    return (
        <Row className='px-0 m-0'>
            <Card className='px-0 rounded-0 mb-0 border-start-0 border-end-0 border-bottom-0'>
                <CardHeader className='p-2'>
                    <div className="text-center">
                        <Row>
                            <Col className='ps-0'>
                                <h4 className="page_title pos-start mb-0">Fuel Consumption - Batch</h4>
                                <p className="card-title-desc pos-start">All readings since last report</p>
                            </Col>
                        </Row>
                    </div>
                </CardHeader>
                <CardBody className='px-0 py-0 mt-2'>
                    <Row>
                        {fuelTypes && fuelTypes?.length > 0 &&
                            <div className="table-responsive pb-2">
                                <table className="table mb-0 p-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th className='p-2 align-middle sr-no-width'>#</th>
                                            <th className='p-2 align-middle'>Fuel</th>
                                            <th className='p-2 align-middle'>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            fuelTypes?.filter((item: any) => item.precedence_id !== FuelTypes.LNG_CARGO).map((fuelType: any, index: number) => {
                                                return (
                                                    <>
                                                        <tr key={`fueltypec-${fuelType.fuel_type}`} data-id={`fueltype-${fuelType.fuel_type}`}>
                                                            <td className='p-2 text-center'>{index + 1}</td>
                                                            <td className='p-2'>{fuelType.fuel_type_name}<br />
                                                                <small>
                                                                    Total consp: <b>{loadFuelConsumptionSum(values?.fuelConsumption)[fuelType.precedence_id]?.toFixed(4)} mt</b>
                                                                </small><br />
                                                                <small>ROB before consp: <b>
                                                                    {(FuelROBObject?.length > 0 && FuelROBObject[0].id > 0) ?
                                                                        FuelROBObject.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                            return parseFloat(rob.previous_rob).toFixed(4);
                                                                        }) :
                                                                        (PreviousFuelROB && PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).length === 0) ?
                                                                            0.0000 : PreviousFuelROB &&
                                                                            PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                                return parseFloat(rob.rob).toFixed(4);
                                                                            })}
                                                                </b>
                                                                </small><br />
                                                                <small>ROB after consp: <b>
                                                                    {(FuelROBObject?.length > 0 && FuelROBObject[0].id > 0) ?
                                                                        (FuelROBObject.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                            return parseFloat(rob.previous_rob).toFixed(4);
                                                                        }) - parseFloat(loadFuelBatchConsumptionSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0) +
                                                                            parseFloat(loadFuelBatchAdjustmentSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0)).toFixed(4) :
                                                                        (PreviousFuelROB && PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).length === 0) ?
                                                                            0.0000 : PreviousFuelROB &&
                                                                            (parseFloat(PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                                return rob.rob;
                                                                            })) - parseFloat(loadFuelBatchConsumptionSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0) +
                                                                                parseFloat(loadFuelBatchAdjustmentSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0))
                                                                                .toFixed(4)}
                                                                </b>
                                                                </small>
                                                                <div>
                                                                    {typeof errors.fuelConsumptionBatch === 'string' ? (
                                                                        <Label className='error'>{errors.fuelConsumptionBatch}</Label>
                                                                    ) : null
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className='p-0 m-0'>
                                                                <table className='table-bordered width-100 p-0 m-0'>
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th style={{ width: '56%' }} className='p-2 align-middle'>Fuel batches</th>
                                                                            <th style={{ width: '14%' }} className='p-2 align-middle text-center'>ROB</th>
                                                                            <th style={{ width: '15%' }} className='p-2 align-middle text-center asteric'>Consumption</th>
                                                                            <th style={{ width: '25%' }} className='p-2 align-middle text-center'>Adjustment</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {finalBatchConsumptionArray?.filter((item: any) => item.fuel_type_name === fuelType.fuel_type && item.quantity_remaining > 0)
                                                                            .map((batch: any, idx: number) => (
                                                                                <tr key={idx}>
                                                                                    <td className='p-2 align-middle'>
                                                                                        {batch.precedence_id_type === FuelTypes.LNG_BUNKER ? 'LNG' : batch.bunkering_supply_name}</td>
                                                                                    <td className='p-2 text-center align-middle'>
                                                                                        <Label
                                                                                            className={
                                                                                                (((totalLNGBunkerROB - parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                    fuel.fuel_batches === batch.id)[0]?.fuel_consumption) +
                                                                                                    parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                        fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)) < 0) &&
                                                                                                    batch.precedence_id_type === FuelTypes.LNG_BUNKER) ?
                                                                                                    'label-red mb-0' : (((parseFloat(batch?.previous_quantity_remaining) -
                                                                                                        parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                            fuel.fuel_batches === batch.id)[0]?.fuel_consumption) +
                                                                                                        parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                            fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)) < 0) &&
                                                                                                        batch.precedence_id_type !== FuelTypes.LNG_BUNKER) ? 'label-red mb-0' : 'mb-0'}
                                                                                        >{batch.precedence_id_type === FuelTypes.LNG_BUNKER &&
                                                                                            Number.isNaN((totalLNGBunkerROB -
                                                                                                (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                    fuel.fuel_batches === batch.id)[0]?.fuel_consumption) ?? 0) +
                                                                                                (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                    fuel.fuel_batches === batch.id)[0]?.adjustment_quantity) ?? 0)
                                                                                            )) ? totalLNGBunkerROB :
                                                                                            batch.precedence_id_type === FuelTypes.LNG_BUNKER &&
                                                                                                !Number.isNaN((totalLNGBunkerROB -
                                                                                                    (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                        fuel.fuel_batches === batch.id)[0]?.fuel_consumption) ?? 0) +
                                                                                                    (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                        fuel.fuel_batches === batch.id)[0]?.adjustment_quantity) ?? 0)
                                                                                                )) ?
                                                                                                (totalLNGBunkerROB -
                                                                                                    (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                        fuel.fuel_batches === batch.id)[0]?.fuel_consumption) ?? 0) +
                                                                                                    (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                        fuel.fuel_batches === batch.id)[0]?.adjustment_quantity) ?? 0)
                                                                                                ).toFixed(4) :
                                                                                                Number.isNaN((parseFloat(batch?.quantity_remaining ?? 0)) -
                                                                                                    parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                        fuel.fuel_batches === batch.id)[0]?.fuel_consumption ?? 0) +
                                                                                                    parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                        fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)
                                                                                                ) ? batch?.quantity_remaining :
                                                                                                    values?.fuelConsumptionBatch[0]?.id > 0 ?
                                                                                                        ((parseFloat(batch?.previous_quantity_remaining ?? 0)) -
                                                                                                            parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                fuel.fuel_batches === batch.id)[0]?.fuel_consumption ?? 0) +
                                                                                                            parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)
                                                                                                        ).toFixed(4) :
                                                                                                        ((parseFloat(batch?.quantity_remaining ?? 0)) -
                                                                                                            parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                fuel.fuel_batches === batch.id)[0]?.fuel_consumption ?? 0) +
                                                                                                            parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)
                                                                                                        ).toFixed(4)
                                                                                            } mt</Label>
                                                                                    </td>
                                                                                    <FieldArray name='fuelConsumptionBatch'>
                                                                                        {() => (
                                                                                            <>
                                                                                                {values && values?.fuelConsumptionBatch?.filter((fuel: any) => fuel.fuel_batches === batch.id)
                                                                                                    .map((fuelbatch: any) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <td className='p-2 align-middle text-center'>
                                                                                                                    <div className='d-inline-block'>
                                                                                                                        <Field name={`fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(fuelbatch)}.fuel_consumption`}>
                                                                                                                            {({ field, form }: FieldProps) => (
                                                                                                                                <div className="input-group">
                                                                                                                                    <Input
                                                                                                                                        type="text"
                                                                                                                                        className="form-control text-right max-width-7"
                                                                                                                                        id={`fuel_consumption_${values?.fuelConsumptionBatch.indexOf(fuelbatch)}`}
                                                                                                                                        name={field.name}
                                                                                                                                        onChange={(e: any) => {
                                                                                                                                            handleChange(e);
                                                                                                                                            handleFuelBatchConsumptionChanges(
                                                                                                                                                e,
                                                                                                                                                form,
                                                                                                                                                batch,
                                                                                                                                                values,
                                                                                                                                                fuelbatch,
                                                                                                                                                values?.fuelConsumptionBatch[values?.fuelConsumptionBatch.indexOf(fuelbatch)]
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                        onBlur={(e: any) => {
                                                                                                                                            handleBlur(e);
                                                                                                                                            handleChange(e);
                                                                                                                                            handleFuelBatchConsumptionChanges(
                                                                                                                                                e,
                                                                                                                                                form,
                                                                                                                                                batch,
                                                                                                                                                values,
                                                                                                                                                fuelbatch,
                                                                                                                                                values?.fuelConsumptionBatch[values?.fuelConsumptionBatch.indexOf(fuelbatch)]
                                                                                                                                            );
                                                                                                                                        }}
                                                                                                                                        defaultValue={BatchConsumptionObject !== undefined && BatchConsumptionObject.length > 0 ?
                                                                                                                                            BatchConsumptionObject?.filter((item: any) => item.id === fuelbatch.id)[0]?.fuel_consumption : null
                                                                                                                                        }
                                                                                                                                    />
                                                                                                                                    <div className="input-group-text">mt</div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </Field>
                                                                                                                    </div>
                                                                                                                    {errors?.fuelConsumptionBatch && touched?.fuelConsumptionBatch && touched?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption &&
                                                                                                                        errors?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption && env?.form_validation &&
                                                                                                                        <ErrorTooltip
                                                                                                                            target={`fuel_consumption_${values?.fuelConsumptionBatch.indexOf(fuelbatch)}`}
                                                                                                                            message={errors?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption}
                                                                                                                            open={(errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption) && !adjustmentPopup ? true : false}
                                                                                                                        />
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className='p-3 align-middle text-center'>
                                                                                                                    <div className='d-inline-block'>
                                                                                                                        <Field name={`fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(fuelbatch)}.adjustment_quantity`}>
                                                                                                                            {({ field }: FieldProps) => (
                                                                                                                                <div className="input-group">
                                                                                                                                    <Input
                                                                                                                                        type='text'
                                                                                                                                        name={field.name}
                                                                                                                                        id={`adjustment_quantity_${values?.fuelConsumptionBatch.indexOf(fuelbatch)}`}
                                                                                                                                        onClick={() => {
                                                                                                                                            setBunker(batch);
                                                                                                                                            setAdjustmentPopup(true);
                                                                                                                                            setFuelBatchIndex(values?.fuelConsumptionBatch.indexOf(fuelbatch));
                                                                                                                                        }}
                                                                                                                                        onKeyUp={() => {
                                                                                                                                            setBunker(batch);
                                                                                                                                            if (fuelBatchIndex !== values?.fuelConsumptionBatch.indexOf(fuelbatch)) {
                                                                                                                                                setFuelBatchIndex(values?.fuelConsumptionBatch.indexOf(fuelbatch));
                                                                                                                                            }
                                                                                                                                            setAdjustmentPopup(true);
                                                                                                                                        }}
                                                                                                                                        className='form-control text-right max-width-7'
                                                                                                                                        value={values?.fuelConsumptionBatch[values?.fuelConsumptionBatch.indexOf(fuelbatch)]?.adjustment_quantity}
                                                                                                                                    />
                                                                                                                                    <div className="input-group-text">mt</div>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </Field>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </>
                                                                                                        )
                                                                                                    })}
                                                                                            </>
                                                                                        )}
                                                                                    </FieldArray>
                                                                                </tr>
                                                                            ))}
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        {
                                                            errors?.finalBatchConsumptionArray &&
                                                            errors?.finalBatchConsumptionArray[values?.fuelConsumptionBatch.findIndex((item: any) => item.fuel_name === fuelType.fuel_type)] && (
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <div className='label-red pos-end2'>{errors?.finalBatchConsumptionArray[values?.fuelConsumptionBatch.findIndex((item: any) => item.fuel_name === fuelType.fuel_type)]}</div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                        {adjustmentPopup && fuelBatchIndex !== null &&
                                            <FuelAdjustmentPopup
                                                state={adjustmentPopup}
                                                setState={setAdjustmentPopup}
                                                tog_backdrop={tog_backdrop}
                                                target={`fuelConsumptionBatch_${fuelBatchIndex}`}
                                                fuelBatch={fuelBatchIndex}
                                                handleFuelBatchROB={handleFuelBatchROB}
                                                bunker={bunker}
                                            />
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                    </Row>
                </CardBody>
            </Card>
        </Row>
    )
}

export default FuelConsumptionBatchComponent
