import React, { createContext, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Row,
  Button,
  Form,
  Card,
  CardFooter,
} from "reactstrap";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import apiGlobal, { apiMedia } from "global/api.global";
import {
  AlertColourTypeConstant,
  AlertTypeConstant,
  FileStatus,
  ReportingEvents,
  VesselTypeConstant,
} from "shared/constants";
import {
  loadBallastDetailsObject,
  loadBillOfLadingObject,
  loadCargoDetails,
  loadDraftsDisplacement,
  loadReeferContainerDetailsData,
  loadInitialLNGCargoROBObject,
  loadLNGCargoPreviousROB,
  loadLNGCargoROBObject,
  loadLNGTankDetailsObject,
  loadVesselLNGtanks,
  loadVesselOtherSettingsOperation,
  loadPreviousCargoDetails,
} from "VesselMaster/vesselMaster.hooks";
import {
  DraftsDisplacementValidationMessages,
  commonValidationMessages,
} from "Components/ValidationErrorMessages";
import { queryClient } from "react-query/queryClient";
import { dynamicSetFieldErrors, handleServerResponse } from "GenericForms/Helper";
import env from "environment_system/env_system";
import ErrorComponent from "Components/ErrorComponent";
import Loading from "Components/Loading";
import AlertPopup from "Alerts/AlertPopup";
import { AlertMessages } from "Alerts/AlertMessages";
import DraftsAndDisplacement from "./DraftsAndDisplacement";
import CargoDetails from "./CargoDetails";
import CargoTankParameter from "./CargoTankParameter";
import ReeferContainerComponent from "./ReeferContainer";
import BillOfLadding from "./BillOfLadding";
import BallastDetails from "./BallastDetails";
import BallastOperations from "./BallastOperations";
import { Formik } from "formik";
import { useDocumentTitle } from "Components/useDocument.hooks";

const LoadingCTMSContext = createContext(null);
const DischargingCTMSContext = createContext(null);
export const UpdateLoadingCTMS = () => {
  return useContext(LoadingCTMSContext);
};

export const UpdateDischargingCTMS = () => {
  return useContext(DischargingCTMSContext);
};

interface DraftsCargoLadingType {
  record: any;
  vessel: any;
  VesselID: number;
  VoyageID: number;
  ReportID: number;
  activeTab: number;
  toggleTab: any;
  setErrorMessage: any;
}

const DraftsCargoLadingComponent = ({
  record,
  vessel,
  VesselID,
  VoyageID,
  ReportID,
  activeTab,
  toggleTab,
  setErrorMessage,
}: DraftsCargoLadingType) => {
  /** State variables */
  useDocumentTitle("Drafts, Cargo Details Report - ecoSAIL");
  // const [blFigureAttachment, setBlFigureAttachment] = useState<Array<any>>([]);
  // const [surveyAttachment, setSurveyAttachment] = useState<Array<any>>([]);
  // const [letterofProtestAttachment, setLetterofProtestAttachment] = useState<Array<any>>([]);
  // const [blFigureAttachmentBool, setBlFigureAttachmentBool] = useState(false);
  // const [surveyAttachmentBool, setSurveyAttachmentBool] = useState(false);
  // const [letterofProtestAttachmentBool, setLetterofProtestAttachmentBool] = useState(false);
  const [loadingPopOverBool, setLoadingPopOverBool] = useState<boolean>(false);
  const [loadingPopUpBool, setLoadingPopUpBool] = useState<boolean>(false);
  const [loadingCTMS, setLoadingCTMS] = useState<boolean>(false);
  const [cargoDetailsId, setCargoDetailsId] = useState<number>(0);
  const [initialLNGCargoId, setInitialLNGCargoId] = useState<number>(0);
  const [dischargingPopOverBool, setDischargingPopOverBool] = useState<boolean>(false);
  const [dischargingPopUpBool, setDischargingPopUpBool] = useState<boolean>(false);
  const [dischargingCTMS, setDischargingCTMS] = useState<boolean>(false);
  const [baplieFiles, setBaplieFiles] = useState<Array<any>>([{}]);
  const [logBook, setLogBook] = useState<Array<any>>([{}]);
  const [refreshKey, setRefreshKey] = useState<number>(0)
  const [alertBool, setAlertBool] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>(null);
  const [alertColourType, setAlertColourType] = useState<any>(null);
  const [alertType, setAlertType] = useState<string>(null);
  const [fieldId, setFieldId] = useState<string>(null);
  const [formTitle, setFormTitle] = useState<string>(null);
  const deleteBaplieFileObj: any = {
    baplie_file: null,
    baplie_file_path: null,
  }
  const deleteLogBookObj: any = {
    log_book_upload: null,
    log_book_file_path: null,
  }
  /** State variables end */

  /** Queries */
  /** Drafts & Displacement object used for edit */
  const {
    data: DraftsDisplacementObject,
    isLoading: DraftsDisplacementObjectLoading,
    isError: DraftsDisplacementObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.DraftsDisplacement.key, VesselID, ReportID],
    async () => {
      return await loadDraftsDisplacement(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Cargo Details object used for edit */
  const {
    data: CargoDetailsObject,
    isLoading: CargoDetailsObjectLoading,
    isError: CargoDetailsObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.CargoDetails.key, VesselID, ReportID],
    async () => {
      return await loadCargoDetails(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Ballast Details object used for edit */
  const {
    data: BallastDetailsObject,
    isLoading: BallastDetailsObjectLoading,
    isError: BallastDetailsObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.BallastDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadBallastDetailsObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Bill of Lading object used for edit */
  const {
    data: BillOfLadingObject,
    isLoading: BillOfLadingObjectLoading,
    isError: BillOfLadingObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.BillOfLadingObject.key, VesselID, ReportID],
    async () => {
      return await loadBillOfLadingObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** LNG cargo's previous report's */
  const {
    data: LNGCargoPreviousROB,
    isLoading: LNGCargoPreviousROBLoading,
    isError: LNGCargoPreviousROBError,
  } = useQuery(
    [queryKeyes.vessel.LNGCargoPreviousROB.key, VesselID, ReportID],
    async () => {
      return await loadLNGCargoPreviousROB(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** LNG cargo ROB Object used for edit */
  const {
    data: LNGCargoROBObject,
    isLoading: LNGCargoROBObjectLoading,
    isError: LNGCargoROBObjectError,
  } = useQuery(
    [queryKeyes.vessel.LNGCargoROBObject.key, VesselID, ReportID],
    async () => {
      return await loadLNGCargoROBObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Initial LNG cargo ROB Object used for edit */
  const {
    data: InitialLNGCargoObject,
    isLoading: InitialLNGCargoObjectLoading,
    isError: InitialLNGCargoObjectError,
  } = useQuery(
    [queryKeyes.vessel.InitialLNGCargoROBObject.key, VesselID],
    async () => {
      return await loadInitialLNGCargoROBObject(VesselID);
    },
    { staleTime: Infinity }
  );
  /** Vessel's LNG tanks */
  const {
    data: VesselLNGTanks,
    isLoading: VesselLNGTanksLoading,
    isError: VesselLNGTanksError,
  } = useQuery(
    [queryKeyes.vessel.VesselLNGTanks.key, VesselID],
    async () => {
      return await loadVesselLNGtanks(VesselID);
    },
    { staleTime: Infinity }
  );
  /** LNG Tank Details used for edit */
  const {
    data: LNGTankDetails,
    isLoading: LNGTankDetailsLoading,
    isError: LNGTankDetailsError,
  } = useQuery(
    [queryKeyes.vessel.LNGTankDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadLNGTankDetailsObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** LNG Tank Details used for edit */
  const {
    data: ReeferContainer,
    isLoading: ReeferContainerLoading,
    isError: ReeferContainerError,
  } = useQuery(
    [queryKeyes.vessel.ReeferContainerDetailsObject.key, VesselID, ReportID],
    async () => {
      return await loadReeferContainerDetailsData(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Vessel's other setting;s object used for edit */
  const { data: OtherSettings, isLoading: OtherSettingsLoading, isError: OtherSettingsError }:
    { data: any, isLoading: any, isError: any } = useQuery(
      [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
      async () => { return await loadVesselOtherSettingsOperation(VesselID) },
      {
        enabled: true,
        staleTime: Infinity,
      }
    );
  /** Queries end */

  /** Assign values to initial object of Drafts & Displacement */
  const getInitialDraftsDisplacement = () => {
    if (
      DraftsDisplacementObject?.length > 0 &&
      DraftsDisplacementObject[0].id > 0
    ) {
      return DraftsDisplacementObject[0];
    } else {
      return {
        fwd_draft: null,
        draft_mid: null,
        draft_aft: null,
        displacement: null,
        trim: 0,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Cargo Details */
  const getInitialCargoDetails = () => {
    if (CargoDetailsObject?.length > 0 && CargoDetailsObject[0]?.id > 0) {
      return CargoDetailsObject[0];
    } else {
      return {
        cargo_detail: "",
        amount_of_cargo_loaded: null as number,
        amount_of_cargo_discharged: null as number,
        total_amount_of_cargo_onboard: null as number,
        no_of_reefer_container: 0,
        cargo_adjusted: 0,
        adjustment_reason: "",
        lng_foe_factor: null,
        loading: false,
        discharging: false,
        loading_completion: false,
        discharging_completion: false,
        is_loading_ctms: false,
        is_discharging_ctms: false,
        OOG_units: 0,
        feet_20: 0,
        feet_40: 0,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Ballast Details */
  const getInitialBallastDetails = () => {
    if (BallastDetailsObject?.length > 0 && BallastDetailsObject[0]?.id > 0) {
      return BallastDetailsObject[0];
    } else {
      return {
        permanent: null,
        addition: null,
        total: null,
        loaded: null,
        discharged: null,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Bill of Lading */
  const getInitialBillOfLading = () => {
    if (BillOfLadingObject?.length > 0 && BillOfLadingObject[0]?.id > 0) {
      return BillOfLadingObject[0];
    } else {
      return {
        is_bill_of_lading_issued_since_last_report: false,
        cargo_survey: false,
        letter_of_protest: false,
        bl_figure: 0,
        //bl_figure_attachment: null as any,
        ship_figure_of_bl_figure: 0,
        //survey_attachment: null as any,
        surveyor_figure_of_bl_figure: 0,
        //letter_of_protest_attachment: null as any,
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };
  /** Assign values to initial object of Reefer Container Details */
  const getInitialReeferContainerDetails = () => {
    if (vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) {
      if (ReeferContainer && ReeferContainer?.length > 0) {
        return ReeferContainer[0];
      } else {
        return {
          loaded_container: 0,
          discharged_container: 0,
          total_onboard_container: 0,
          baplie_file: "",
          log_book_upload: "",
          reason_for_change: "",
          baplie_file_status: FileStatus.NA,
          log_book_status: FileStatus.PENDING,
          vessel: VesselID,
          voyage_information: VoyageID,
          vessel_reporting_information: ReportID,
        };
      }
    } else {
      return null
    }
  };
  /** Assign initial values to LNG cargo ROB formik object */
  const getLNGCargoROBInitialVals = () => {
    if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
      if (LNGCargoROBObject && LNGCargoROBObject.length > 0) {
        return LNGCargoROBObject[0];
      } else {
        return {
          cargo_consumed: 0,
          previous_lng_cargo_rob_id: null,
          cargo_rob: LNGCargoPreviousROB?.cargo_rob ?? 0,
          cargo_loaded_cargo_detail: null,
          cargo_discharge_cargo_detail: null,
          lng_initial_cargo_rob: null,
          vessel: VesselID,
          voyage_information: VoyageID,
          vessel_reporting_information: ReportID,
        };
      }
    }
  };

  /** Assign initial values to initial LNG cargo ROB formik object */
  const getInitialLNGCargoROB = () => {
    if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
      if (InitialLNGCargoObject && InitialLNGCargoObject.length > 0) {
        if (InitialLNGCargoObject[0]?.start_report === ReportID) {
          InitialLNGCargoObject[0].loading_completion = true;
        } else if (InitialLNGCargoObject[0]?.end_report === ReportID) {
          InitialLNGCargoObject[0].discharging_completion = true;
        } else {
          InitialLNGCargoObject[0].loading_completion = false;
          InitialLNGCargoObject[0].discharging_completion = false;
        }
        delete InitialLNGCargoObject[0].start_file_path;
        delete InitialLNGCargoObject[0].end_file_path;
        return InitialLNGCargoObject[0];
      } else {
        return ({
          loaded_cargo: 0,
          loading_ctms: false,
          status: "Ongoing",
          discharge_cargo: 0,
          discharge_ctms: false,
          start_report: null as number,
          end_report: null as number,
          vessel: VesselID,
        });
      }
    }
  };

  /** Assign initial values to vessel's LNG tanks formik object */
  const getVesselLNGTanks = () => {
    if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
      if (LNGTankDetails && LNGTankDetails[0]?.id > 0) {
        return LNGTankDetails;
      } else {
        let array: any[] = [];
        VesselLNGTanks?.forEach((tank: any) => {
          array.push({
            temperature: null as number,
            pressure: null,
            tank: tank?.id,
            tank_name: tank?.name_of_tanks,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
          });
        });
        return array;
      }
    }
  };

  /** UseEffect */
  useEffect(() => {
    DraftCargoLadingFormik.initialValues = {
      draft_and_displacement: getInitialDraftsDisplacement(),
      cargo_detail: getInitialCargoDetails(),
      ballast_details: getInitialBallastDetails(),
      bill_of_lading: getInitialBillOfLading(),
      reefer_container_details: getInitialReeferContainerDetails(),
      lng_cargo_rob: getLNGCargoROBInitialVals(),
      n2_adjustment: getInitialLNGCargoROB(),
      vessel_reporting_lng_tank_detail: getVesselLNGTanks(),
    };
    setRefreshKey(refreshKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    DraftsDisplacementObject,
    CargoDetailsObject,
    BillOfLadingObject,
    LNGCargoPreviousROB,
    LNGCargoROBObject,
    LNGCargoPreviousROBError,
    LNGTankDetails,
    VesselLNGTanks,
    InitialLNGCargoObject,
    ReeferContainer
  ]);
  /** UseEffect end */

  /** Formik object for all forms */
  const DraftCargoLadingFormik = {
    initialValues: {
      draft_and_displacement: getInitialDraftsDisplacement(),
      cargo_detail: getInitialCargoDetails(),
      ballast_details: getInitialBallastDetails(),
      bill_of_lading: getInitialBillOfLading(),
      reefer_container_details: getInitialReeferContainerDetails(),
      lng_cargo_rob: getLNGCargoROBInitialVals(),
      n2_adjustment: getInitialLNGCargoROB(),
      vessel_reporting_lng_tank_detail: getVesselLNGTanks(),
    },
    validationSchema: Yup.object().shape({
      draft_and_displacement: Yup.object({
        fwd_draft: Yup.string()
          .matches(
            /^\d{0,2}(?:\.\d{1,4})?$/,
            commonValidationMessages.before2after4
          )
          .required(commonValidationMessages.required),
        draft_mid: Yup.string()
          .matches(
            /^\d{0,2}(?:\.\d{1,4})?$/,
            commonValidationMessages.before2after4
          )
          .required(commonValidationMessages.required),
        draft_aft: Yup.string()
          .matches(
            /^\d{0,2}(?:\.\d{1,4})?$/,
            commonValidationMessages.before2after4
          )
          .required(commonValidationMessages.required),
        displacement: Yup.string()
          .matches(
            /^\d{0,6}(?:\.\d{1,4})?$/,
            DraftsDisplacementValidationMessages.displacement
          )
          .required(commonValidationMessages.required),
      }),
      cargo_detail: Yup.object({
        OOG_units: Yup.string().when([], function () {
          if (vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) {
            return Yup.string().matches(/^\d{1,7}$/, commonValidationMessages.wholenumberupto7digits); // Whole numbers up to 7 digits only
          } else {
            return Yup.string().matches(
              /^(?!0\d)(\d{1,7}|0)(\.\d{1,4})?$/,
              commonValidationMessages.before7after4
            );
          }
        }),
        cargo_detail: Yup.string()
          .min(2, commonValidationMessages.min2)
          .max(50, commonValidationMessages.max50Char)
          .when("$fieldAvailability", (field: any, schema) => {
            return ((record?.is_this_cargo_loading_discharging === 'no') ||
              (record?.is_this_cargo_loading_discharging === null))
              ? schema.nullable()
              : schema.required(commonValidationMessages.required);
          }),
        amount_of_cargo_loaded: Yup.string()
          .when([], function () {
            if (vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) {
              return Yup.string().matches(/^\d{1,7}$/, commonValidationMessages.wholenumberupto7digits); // Whole numbers up to 7 digits only
            } else {
              return Yup.string().matches(
                /^(?!0\d)(\d{1,7}|0)(\.\d{1,4})?$/,
                commonValidationMessages.before7after4
              );
            }
          })
          .when("$fieldAvailability", (field: any, schema) => {
            return ((record?.is_this_cargo_loading_discharging === 'no') ||
              (record?.is_this_cargo_loading_discharging === null))
              ? schema.nullable()
              : schema.required(commonValidationMessages.required);
          }),
        amount_of_cargo_discharged: Yup.string()
          .when([], function () {
            if (vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) {
              return Yup.string().matches(/^\d{1,7}$/, commonValidationMessages.wholenumberupto7digits); // Whole numbers up to 7 digits only
            } else {
              return Yup.string().matches(
                /^(?!0\d)(\d{1,7}|0)(\.\d{1,4})?$/,
                commonValidationMessages.before7after4
              );
            }
          })
          .when("$fieldAvailability", (field: any, schema) => {
            return ((record?.is_this_cargo_loading_discharging === 'no') ||
              (record?.is_this_cargo_loading_discharging === null))
              ? schema.nullable()
              : schema.required(commonValidationMessages.required);
          }),
        total_amount_of_cargo_onboard: Yup.string()
          .when([], function () {
            if (vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) {
              return Yup.string().matches(/^\d{1,7}$/, commonValidationMessages.wholenumberupto7digits); // Whole numbers up to 7 digits only
            }
          })
          .when(
            "$fieldVisibility",
            (fieldVisibility: any, schema) => {
              return ((record?.is_this_cargo_loading_discharging === 'no') ||
                (record?.is_this_cargo_loading_discharging === null))
                ? schema.nullable()
                : schema
                  .matches(
                    /^(?!0\d)(\d{1,7}|0)(\.\d{1,4})?$/,
                    commonValidationMessages.before7after4
                  )
                  .required(commonValidationMessages.required);
            }
          ),
        no_of_reefer_container: Yup.string()
          .min(1, `${commonValidationMessages.min1}`)
          .max(5, `${commonValidationMessages.max50}`),
        cargo_adjusted: Yup.string()
          .matches(
            /^[+-]?\d{0,7}(?:\.\d{1,4})?$/,
            commonValidationMessages.before7after4
          )
          .nullable(),
        adjustment_reason: Yup.string()
          .when("cargo_adjusted", (val: any, schema) => {
            return (val && val[0] !== '0' &&
              vessel.vessel_type === VesselTypeConstant.LNG_CARRIER &&
              record?.is_this_cargo_loading_discharging === 'yes')
              ? schema.required(commonValidationMessages.required)
              : schema.nullable();
          })
          .max(50, commonValidationMessages.max50Char),
        feet_20: Yup.string()
          .when([], function () {
            if (vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) {
              return Yup.string().matches(/^\d{1,7}$/, commonValidationMessages.wholenumberupto7digits); // Whole numbers up to 7 digits only
            } else {
              return Yup.string().matches(
                /^(?!0\d)(\d{1,7}|0)(\.\d{1,4})?$/,
                commonValidationMessages.before7after4
              );
            }
          }).nullable(),
        feet_40: Yup.string()
          .when([], function () {
            if (vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) {
              return Yup.string().matches(/^\d{1,7}$/, commonValidationMessages.wholenumberupto7digits); // Whole numbers up to 7 digits only
            } else {
              return Yup.string().matches(
                /^(?!0\d)(\d{1,7}|0)(\.\d{1,4})?$/,
                commonValidationMessages.before7after4
              );
            }
          }).nullable(),
      }),
      ballast_details: Yup.object({
        permanent: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,4})?$/,
            commonValidationMessages.before7after4
          )
          .required(commonValidationMessages.required),
        addition: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,4})?$/,
            commonValidationMessages.before7after4
          )
          .required(commonValidationMessages.required),
        total: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,4})?$/,
            commonValidationMessages.before7after4
          )
          .required(commonValidationMessages.required),
        loaded: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,4})?$/,
            commonValidationMessages.before7after4
          )
          .required(commonValidationMessages.required),
        discharged: Yup.string()
          .matches(
            /^\d{0,7}(?:\.\d{1,4})?$/,
            commonValidationMessages.before7after4
          )
          .required(commonValidationMessages.required),
      }),
      bill_of_lading: Yup.object({
        is_bill_of_lading_issued_since_last_report: Yup.boolean(),
        cargo_survey: Yup.boolean(),
        letter_of_protest: Yup.boolean(),
        bl_figure: Yup.string().matches(
          /^\d{0,7}(?:\.\d{1,4})?$/,
          commonValidationMessages.before7after4
        ).required(commonValidationMessages.required),
        //bl_figure_attachment: Yup.string().nullable(),
        ship_figure_of_bl_figure: Yup.string().matches(
          /^\d{0,7}(?:\.\d{1,4})?$/,
          commonValidationMessages.before7after4
        ).required(commonValidationMessages.required),
        //survey_attachment: Yup.string().nullable(),
        surveyor_figure_of_bl_figure: Yup.string().matches(
          /^\d{0,7}(?:\.\d{1,4})?$/,
          commonValidationMessages.before7after4
        )
          .when('cargo_survey', {
            is: true,
            then: (schema) => schema.required(commonValidationMessages.required),
            otherwise: (schema) => schema.nullable()
          }),
        //letter_of_protest_attachment: Yup.string().nullable(),
      }),
      vessel_reporting_lng_tank_detail: Yup.array(
        Yup.object({
          temperature: Yup.number()
            .min(-180, commonValidationMessages.min_180)
            .max(50, commonValidationMessages.max50)
            .required(commonValidationMessages.required),
          pressure: Yup.number()
            .min(0, commonValidationMessages.min0)
            .max(250, commonValidationMessages.max250)
            .required(commonValidationMessages.required),
        })
      ),
      reefer_container_details: Yup.lazy(() => {
        if (
          (vessel?.vessel_type === VesselTypeConstant.CONTAINER ||
            vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
          OtherSettings && OtherSettings[0]?.reefer_container_available === true &&
          record &&
          (
            (record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
              record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
              record?.is_this_cargo_loading_discharging === "yes") ||
            (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
              record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH)
          )
        ) {
          return Yup.object({
            loaded_container: Yup.number()
              .integer(commonValidationMessages.integer)
              .min(0, commonValidationMessages.positive)
              .max(999999, commonValidationMessages.max6)
              .required(commonValidationMessages.required),
            discharged_container: Yup.number()
              .integer(commonValidationMessages.integer)
              .min(0, commonValidationMessages.positive)
              .max(999999, commonValidationMessages.max6)
              .required(commonValidationMessages.required),
            total_onboard_container: Yup.number()
              .integer(commonValidationMessages.integer)
              .min(0, commonValidationMessages.positive)
              .max(999999, commonValidationMessages.max6)
              .required(commonValidationMessages.required),
            feet_20: Yup.number()
              .integer(commonValidationMessages.integer)
              .min(0, commonValidationMessages.positive)
              .max(999999, commonValidationMessages.max6)
              .nullable(),
            feet_40: Yup.number()
              .integer(commonValidationMessages.integer)
              .min(0, commonValidationMessages.positive)
              .max(999999, commonValidationMessages.max6)
              .nullable(),
          });
        }
        // If conditions don't match, return a schema that allows reefer_container_details to be optional
        return Yup.object().nullable();
      }),
    }),
  };

  /** Calculate LNG cargo ROB */
  const updateTotalCargoOnboard = (
    form: any,
    values: any,
    e: any,
    op: string
  ) => {
    if (op === "loading") {
      form.setFieldValue(
        "cargo_detail.total_amount_of_cargo_onboard",
        (
          parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) +
          parseFloat(e.target.value) -
          parseFloat(values?.cargo_detail?.amount_of_cargo_discharged ?? 0) -
          parseFloat(values?.lng_cargo_rob?.cargo_consumed ?? 0) +
          parseFloat(values?.cargo_detail?.cargo_adjusted ?? 0)
        ).toFixed(2)
      );
    } else if (op === "discharging") {
      form.setFieldValue(
        "cargo_detail.total_amount_of_cargo_onboard",
        (
          parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) +
          parseFloat(values?.cargo_detail?.amount_of_cargo_loaded ?? 0) -
          parseFloat(e.target.value) -
          parseFloat(values?.lng_cargo_rob?.cargo_consumed ?? 0) +
          parseFloat(values?.cargo_detail?.cargo_adjusted ?? 0)
        ).toFixed(2)
      );
    } else {
      form.setFieldValue(
        "cargo_detail.total_amount_of_cargo_onboard",
        (
          parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) +
          parseFloat(values?.cargo_detail?.amount_of_cargo_loaded ?? 0) -
          parseFloat(values?.cargo_detail?.amount_of_cargo_discharged ?? 0) -
          parseFloat(values?.lng_cargo_rob?.cargo_consumed ?? 0) +
          parseFloat(e.target.value)
        ).toFixed(2)
      );
    }
  };

  /**querry to get cargo detaila from previous repory */
  const { data: prevCargodetails, isLoading: loadingPrevCD } = useQuery(
    [queryKeyes.vessel.PreviousCargoDetails.key, VesselID],
    async () => {
      return await loadPreviousCargoDetails(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  const prevCargoOnboard = prevCargodetails?.total_amount_of_cargo_onboard

  /**function to calculate if the total cargo on boaed is within 5% margin of error 
   * @param total_amount_of_cargo_onboard
   * @param amount_of_cargo_discharged
   * @param amount_of_cargo_loaded
   * sets up the alert popup 
  */
  const handleCargoOnboardBlur = (reportedQty: number, cargoLoaded: number, cargoDischarged: number) => {
    const calculatedQty = prevCargoOnboard + cargoLoaded - cargoDischarged;
    if (env?.alerts && (reportedQty < calculatedQty * 0.95 || reportedQty > calculatedQty * 1.05)) {
      setAlertBool(true);
      setAlertMessage(AlertMessages.CargoDetails.total_amount_of_cargo_onboard);
      setAlertColourType(AlertColourTypeConstant.DANGER);
      setAlertType(AlertTypeConstant.WITH_REASON);
      setFieldId("total_amount_of_cargo_onboard");
      setFormTitle("Cargo Details");
    }
  };

  const AmountOfCargoDischagedOnBlur = (e: any, values: any, handleBlur: any, handleChange: any, form: any) => {
    if (env?.alerts && prevCargoOnboard !== undefined &&
      e.target.value > prevCargodetails?.total_amount_of_cargo_onboard) {
      setAlertBool(true);
      setAlertMessage(AlertMessages.CargoDetails.amount_of_cargo_discharged);
      setAlertColourType(AlertColourTypeConstant.DANGER);
      setAlertType(AlertTypeConstant.WITH_REASON);
      setFieldId("amount_of_cargo_discharged")
      setFormTitle("Cargo Details");
    }
    if (env?.alerts && e.target.value !== null && values?.cargo_detail?.total_amount_of_cargo_onboard !== null) {
      handleCargoOnboardBlur(
        parseFloat(values?.cargo_detail?.total_amount_of_cargo_onboard),
        parseFloat(values?.cargo_detail?.amount_of_cargo_loaded),
        parseFloat(e.target.value),
      )
    }
    handleBlur(e);
    handleChange(e);
    if (
      e.target.value !== null &&
      e.target.value > 0
    ) {
      form.setFieldValue(
        "cargo_detail.discharging",
        true
      );
    } else {
      form.setFieldValue(
        "cargo_detail.discharging",
        false
      );
    }
    if (
      vessel?.vessel_type ===
      VesselTypeConstant.LNG_CARRIER
    ) {
      updateTotalCargoOnboard(
        form,
        values,
        e,
        "discharging"
      );
    }
  }

  /**combined loading state of all queries */
  const isLoading = (
    DraftsDisplacementObjectLoading ||
    CargoDetailsObjectLoading ||
    BillOfLadingObjectLoading ||
    BallastDetailsObjectLoading ||
    LNGCargoPreviousROBLoading ||
    LNGCargoROBObjectLoading ||
    InitialLNGCargoObjectLoading ||
    VesselLNGTanksLoading ||
    LNGTankDetailsLoading ||
    ReeferContainerLoading ||
    OtherSettingsLoading ||
    loadingPrevCD
  );

  return (
    <Card className="deck-card-body mb-0 border-0">
      {isLoading && <Loading message="Loading required data!" />}
      {OtherSettingsError && <ErrorComponent message="Error loading required data!" />
      }
      {DraftsDisplacementObjectError && getInitialDraftsDisplacement()}
      {CargoDetailsObjectError && getInitialCargoDetails()}
      {BallastDetailsObjectError && getInitialBallastDetails()}
      {BillOfLadingObjectError && getInitialBillOfLading()}
      {LNGCargoROBObjectError &&
        (DraftCargoLadingFormik.initialValues.lng_cargo_rob =
          getLNGCargoROBInitialVals())}
      {InitialLNGCargoObjectError && getInitialLNGCargoROB()}
      {LNGTankDetailsError && getVesselLNGTanks()}
      {ReeferContainerError && getInitialReeferContainerDetails()}

      {!(
        DraftsDisplacementObjectLoading ||
        CargoDetailsObjectLoading ||
        BillOfLadingObjectLoading ||
        LNGCargoPreviousROBLoading ||
        LNGCargoROBObjectLoading ||
        InitialLNGCargoObjectLoading ||
        VesselLNGTanksLoading ||
        LNGTankDetailsLoading ||
        OtherSettingsLoading ||
        ReeferContainerLoading
      ) &&
        !(LNGCargoPreviousROBError || VesselLNGTanksError || OtherSettingsError || ReeferContainerError) && (
          <Formik
            onSubmit={async (values: any, actions: any) => {
              if (env?.form_validation === false) {
                setErrorMessage(null)
                toggleTab(activeTab + 1);
              }
              actions.setSubmitting(true);
              const responseArray: any = [];
              /** Drafts & Displacement submit */
              if (
                DraftsDisplacementObject &&
                DraftsDisplacementObject[0]?.id > 0
              ) {
                responseArray.push(apiGlobal
                  .put(
                    `/draft_and_displacement/${values.draft_and_displacement.id}/`,
                    values.draft_and_displacement
                  ))
              } else {
                responseArray.push(apiGlobal
                  .post(`/draft_and_displacement/`, values.draft_and_displacement))
              }
              /** Ballast Details submit */
              if (BallastDetailsObject && BallastDetailsObject[0]?.id) {
                responseArray.push(apiGlobal
                  .put(
                    `/ballast_details/${values?.ballast_details.id}/`,
                    values?.ballast_details
                  ))
              } else {
                responseArray.push(apiGlobal
                  .post(`/ballast_details/`, values.ballast_details))
              }
              /** Cargo Details submit */
              if (record &&
                ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                  record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                  record?.is_this_cargo_loading_discharging === "yes") ||
                  (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                    record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH))) {
                if (CargoDetailsObject && CargoDetailsObject[0]?.id) {
                  setCargoDetailsId(values.cargo_detail.id);
                  responseArray.push(apiGlobal
                    .put(
                      `/cargo_detail/${values.cargo_detail.id}/`,
                      values.cargo_detail
                    ))
                } else {
                  responseArray.push(apiGlobal
                    .post(`/cargo_detail/`, values.cargo_detail))
                }
              }
              /** Bill of Lading submit */
              if ((values?.cargo_detail?.total_amount_of_cargo_onboard > 0 && values?.cargo_detail?.total_amount_of_cargo_onboard !== null)) {
                if (
                  BillOfLadingObject?.length > 0 &&
                  BillOfLadingObject[0]?.id > 0
                ) {
                  responseArray.push(apiGlobal
                    .put(
                      `/bill_of_lading/${values.bill_of_lading.id}/`,
                      values.bill_of_lading
                    ))
                } else {
                  responseArray.push(apiGlobal
                    .post(`/bill_of_lading/`, values.bill_of_lading))
                }
              }
              /** Reefer Containers submit */
              if ((vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                OtherSettings && OtherSettings[0]?.reefer_container_available === true &&
                record &&
                ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                  record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                  record?.is_this_cargo_loading_discharging === "yes") ||
                  (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                    record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH))) {
                if (values.reefer_container_details.id) {
                  const formData = new FormData();
                  Object.keys(values.reefer_container_details).forEach((item: any) => {
                    formData.append(item, values.reefer_container_details[item]);
                  });
                  if (baplieFiles && baplieFiles[0].file) {
                    formData.append("baplie_file", baplieFiles[0].file);
                  }
                  if (logBook && logBook[1]?.file) {
                    formData.append("log_book_upload", logBook[1]?.file);
                  }
                  responseArray.push(apiMedia
                    .put(
                      `/reefer_container_details/${ReeferContainer[0].id}/`,
                      formData
                    ))
                } else {
                  const formData = new FormData();
                  Object.keys(values.reefer_container_details).forEach((item: any) => {
                    formData.append(item, values.reefer_container_details[item]);
                  });
                  if (baplieFiles && baplieFiles[0].file) {
                    formData.append("baplie_file", baplieFiles[0].file);
                  }
                  if (logBook && logBook[1]?.file) {
                    formData.append("log_book_upload", logBook[1]?.file);
                  }
                  responseArray.push(apiMedia
                    .post(`/reefer_container_details/`, formData))
                }
              }
              /** Update intial LNG Cargo ROB */
              if (
                vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                record &&
                record?.is_this_cargo_loading_discharging === 'yes'
              ) {
                if (values.cargo_detail.amount_of_cargo_loaded > 0) {
                  values.n2_adjustment.loaded_cargo =
                    values?.cargo_detail?.amount_of_cargo_loaded;
                  values.n2_adjustment.loading_ctms = loadingCTMS;
                }
                if (values.cargo_detail.amount_of_cargo_discharged > 0) {
                  values.n2_adjustment.discharge_cargo =
                    values?.cargo_detail?.amount_of_cargo_discharged;
                  values.n2_adjustment.discharge_ctms = dischargingCTMS;
                }
                if (values?.cargo_detail?.loading_completion === true) {
                  values.n2_adjustment.start_report = ReportID;
                }
                if (values?.cargo_detail?.discharging_completion === true) {
                  values.n2_adjustment.end_report = ReportID;
                }
                if (
                  values?.n2_adjustment?.end_report !== null ||
                  InitialLNGCargoObject[0]?.start_report === ReportID
                ) {
                  responseArray.push(
                    apiGlobal.put(
                      `/n2_adjustment/${values?.n2_adjustment?.id}/`,
                      values?.n2_adjustment
                    ))
                } else if (
                  values?.n2_adjustment?.start_report === ReportID &&
                  InitialLNGCargoObject[0]?.start_report !== ReportID
                ) {
                  const res = await apiGlobal
                    .post(`/n2_adjustment/`, values?.n2_adjustment)
                  if (res.status === 201) {
                    setInitialLNGCargoId(res?.data?.id);
                  }
                  responseArray.push(res);
                }
              }
              /** Submit LNG cargo ROB */
              if (
                vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                record &&
                record?.is_this_cargo_loading_discharging === 'yes'
              ) {
                if (values?.cargo_detail?.amount_of_cargo_loaded > 0) {
                  values.lng_cargo_rob.cargo_loaded_cargo_detail =
                    cargoDetailsId === 0
                      ? CargoDetailsObject[0]?.id
                      : cargoDetailsId;
                }
                if (values?.cargo_detail?.amount_of_cargo_discharged > 0) {
                  values.lng_cargo_rob.cargo_discharge_cargo_detail =
                    cargoDetailsId === 0
                      ? CargoDetailsObject[0]?.id
                      : cargoDetailsId;
                }
                if (values?.n2_adjustment?.start_report === ReportID) {
                  values.lng_cargo_rob.lng_initial_cargo_rob =
                    initialLNGCargoId;
                  values.lng_cargo_rob.cargo_rob =
                    values.cargo_detail.total_amount_of_cargo_onboard;
                }
                values.lng_cargo_rob.lng_initial_cargo_rob =
                  InitialLNGCargoObject[0]?.id;
                values.lng_cargo_rob.cargo_rob = parseFloat(
                  values.cargo_detail.total_amount_of_cargo_onboard
                );
                if (LNGCargoROBObject && LNGCargoROBObject.length > 0) {
                  responseArray.push(apiGlobal
                    .put(
                      `/lng_cargo_rob/${values?.lng_cargo_rob?.id}/`,
                      values?.lng_cargo_rob
                    ))
                } else {
                  responseArray.push(apiGlobal
                    .post(`/lng_cargo_rob/`, values?.lng_cargo_rob))
                }
              }
              /** Cargo Parameters Submit */
              if (
                vessel &&
                vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER
              ) {
                if (LNGTankDetails && LNGTankDetails[0]?.id) {
                  values.vessel_reporting_lng_tank_detail.forEach((lngTank: any) => {
                    responseArray.push(apiGlobal
                      .put(
                        `/vessel_reporting_lng_tank_detail/${lngTank.id}/`,
                        lngTank
                      ))
                  });
                } else {
                  responseArray.push(apiGlobal
                    .post(
                      `/vessel_reporting_lng_tank_detail/`,
                      values.vessel_reporting_lng_tank_detail
                    ))
                }
              }
              await handleServerResponse(responseArray).then(async (res) => {
                if (res === true) {
                  setBaplieFiles([{}])
                  await queryClient.invalidateQueries(queryKeyes.vessel.DraftsDisplacement.key);
                  await queryClient.invalidateQueries(queryKeyes.vessel.BallastDetailsObject.key);
                  if ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                    record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                    record?.is_this_cargo_loading_discharging === "yes") ||
                    (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                      record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH)) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.CargoDetails.key);
                  }
                  if (values?.cargo_detail?.total_amount_of_cargo_onboard > 0 && values?.cargo_detail?.total_amount_of_cargo_onboard !== null) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.BillOfLadingObject.key);
                  }
                  if ((vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                    OtherSettings && OtherSettings[0]?.reefer_container_available === true &&
                    record &&
                    ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                      record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                      record?.is_this_cargo_loading_discharging === "yes") ||
                      (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                        record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH))) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.FCElectricalReeferObject.key);
                    await queryClient.invalidateQueries(queryKeyes.vessel.ReeferContainerDetailsObject.key);
                  }
                  if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.InitialLNGCargoROBObject.key);
                    await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoPreviousROB.key);
                    await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoQualityObject.key);
                    await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoROBObject.key);
                  }
                  if (env?.form_validation === true) {
                    setErrorMessage(null)
                    toggleTab(activeTab + 1);
                  }
                } else if (Array.isArray(res)) {
                  if (res.filter((item: any) => item.status === "fulfilled")?.length > 0) {
                    res.filter((item: any) => item.status === "fulfilled")?.forEach(async (obj: any) => {
                      const apiURL = obj?.value?.config?.url?.replace(/\d+/g, "").replace(/\/$/, "");
                      if (apiURL === "/cargo_detail/" &&
                        ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                          record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                          record?.is_this_cargo_loading_discharging === "yes") ||
                          (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                            record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH))) {
                        await queryClient.invalidateQueries(queryKeyes.vessel.CargoDetails.key);
                      } else if (
                        apiURL === "/bill_of_lading/" &&
                        values?.cargo_detail?.total_amount_of_cargo_onboard > 0 &&
                        values?.cargo_detail?.total_amount_of_cargo_onboard !== null) {
                        await queryClient.invalidateQueries(queryKeyes.vessel.BillOfLadingObject.key);
                      } else if ((vessel?.vessel_type === VesselTypeConstant.CONTAINER ||
                        vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                        OtherSettings && OtherSettings[0]?.reefer_container_available === true &&
                        record && ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                          record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                          record?.is_this_cargo_loading_discharging === "yes") ||
                          (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                            record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH)) &&
                        apiURL === "/reefer_container_details/") {
                        setBaplieFiles([{}])
                        await queryClient.invalidateQueries(queryKeyes.vessel.FCElectricalReeferObject.key);
                        await queryClient.invalidateQueries(queryKeyes.vessel.ReeferContainerDetailsObject.key);
                      } else if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
                        if (apiURL === "/lng_cargo_rob/") {
                          await queryClient.invalidateQueries(queryKeyes.vessel.InitialLNGCargoROBObject.key);
                          await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoROBObject.key);
                        } else if (apiURL === "/n2_adjustment/") {
                          await queryClient.invalidateQueries(queryKeyes.vessel.InitialLNGCargoROBObject.key);
                        } else if (apiURL === "/vessel_reporting_lng_tank_detail/") {
                          await queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoQualityObject.key);
                        }
                      } else if (apiURL === "/ballast_details/") {
                        await queryClient.invalidateQueries(queryKeyes.vessel.BallastDetailsObject.key);
                      } else if (apiURL === "/draft_and_displacement/") {
                        await queryClient.invalidateQueries(queryKeyes.vessel.DraftsDisplacement.key);
                      }
                    })
                  }
                  setErrorMessage(res)
                  setAlertBool(false)
                  dynamicSetFieldErrors(res, actions)
                }
                actions.setSubmitting(false);
              })
            }}
            initialValues={DraftCargoLadingFormik.initialValues}
            validationSchema={env?.form_validation === true ? DraftCargoLadingFormik.validationSchema : null}
          >
            {({
              values,
              errors,
              handleSubmit,
              setErrors,
              isSubmitting,
            }: {
              values: any;
              errors: any;
              handleSubmit: any;
              handleChange: any;
              setErrors: any;
              touched: any;
              handleBlur: any;
              isSubmitting: any;
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <React.Fragment>
                  {/* Draft And Displacement Compoenent */}
                  <DraftsAndDisplacement
                    alertBool={alertBool} // Example for passing alertBool prop
                  />
                  {/* Cargo Tank Parameter Component */}
                  {record &&
                    ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                      record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                      record?.is_this_cargo_loading_discharging === "yes") ||
                      (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                        record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH)) &&
                    (
                      <CargoDetails
                        alertBool={alertBool}
                        handleCargoOnboardBlur={handleCargoOnboardBlur}
                        updateTotalCargoOnboard={updateTotalCargoOnboard}
                        setLoadingPopOverBool={setLoadingPopOverBool}
                        setLoadingPopUpBool={setLoadingPopUpBool}
                        loadingPopOverBool={loadingPopOverBool}
                        loadingPopUpBool={loadingPopUpBool}
                        setLoadingCTMS={setLoadingCTMS}
                        AmountOfCargoDischagedOnBlur={AmountOfCargoDischagedOnBlur}
                        setDischargingPopOverBool={setDischargingPopOverBool}
                        setDischargingPopUpBool={setDischargingPopUpBool}
                        setDischargingCTMS={setDischargingCTMS}
                        dischargingPopUpBool={dischargingPopUpBool}
                        dischargingPopOverBool={dischargingPopOverBool}
                        LNGCargoPreviousROB={LNGCargoPreviousROB}
                      />
                    )}
                  {vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER && (
                    <CargoTankParameter
                      alertBool={alertBool}
                    />
                  )}
                  {/* Reefer conatainer Details components */}
                  {(vessel?.vessel_type === VesselTypeConstant.CONTAINER || vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                    OtherSettings && OtherSettings[0]?.reefer_container_available === true &&
                    record &&
                    ((record?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                      record?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                      record?.is_this_cargo_loading_discharging === "yes") ||
                      (record?.reporting_event === ReportingEvents.NOON_FWE_IP ||
                        record?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH)) &&
                    (
                      <ReeferContainerComponent
                        alertBool={alertBool}
                        deleteBaplieFileObj={deleteBaplieFileObj}
                        setRefreshKey={setRefreshKey}
                        refreshKey={refreshKey}
                        baplieFiles={baplieFiles}
                        setBaplieFiles={setBaplieFiles}
                        deleteLogBookObj={deleteLogBookObj}
                        logBook={logBook}
                        setLogBook={setLogBook}
                      />
                    )}
                  {/* Bill of ladding component */}
                  {(values?.cargo_detail?.total_amount_of_cargo_onboard > 0 && values?.cargo_detail?.total_amount_of_cargo_onboard !== null) &&
                    <BillOfLadding
                      alertBool={alertBool}
                    />}
                  {/* Ballast Details Component */}
                  <BallastDetails
                    alertBool={alertBool}
                  />
                  {/* Ballast Operations Component */}
                  <BallastOperations
                    alertBool={alertBool}
                  />
                  {/* Alert Popup component */}
                  {alertBool &&
                    <AlertPopup
                      state={alertBool}
                      setState={setAlertBool}
                      alertMessage={alertMessage}
                      setAlertMessage={setAlertMessage}
                      AlertColourType={alertColourType}
                      setAlertColourType={setAlertColourType}
                      alertType={alertType}
                      setAlertType={setAlertType}
                      formTitle={formTitle}
                      fieldId={fieldId}
                    />
                  }
                  <CardFooter className="p-2 py-3">
                    <Row className="ele_row1">
                      <div className="d-flex flex-wrap gap-5">
                        <Button
                          type="submit"
                          color="primary"
                          className="btn_size_cstm pos-end"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                          Next <i className="bx bx-chevron-right ms-1" />
                        </Button>
                        <Button
                          type="button"
                          color="primary"
                          className="btn_size_cstm"
                          onClick={() => {
                            setErrors({});
                            toggleTab(activeTab - 1);
                            setErrorMessage(null)
                          }}
                        >
                          <i className="bx bx-chevron-left me-1" /> Previous
                        </Button>
                      </div>
                    </Row>
                  </CardFooter>
                  <FormValuesDebug
                    values={[
                      values,
                      errors,
                      DraftCargoLadingFormik.initialValues,
                    ]}
                  />
                </React.Fragment>
              </Form>
            )}
          </Formik>
        )}
    </Card>
  );
};

export default DraftsCargoLadingComponent;
