import { FilterErrorMessage, handleServerResponse } from "GenericForms/Helper";
import apiGlobal from "global/api.global";
import React from "react";
import { Button } from "reactstrap";
import { ErrorCode } from "shared/constants";
import { queryKeyes } from "shared/queryKeys";

/** Error Reporting interface */
interface ErrorReportingInterface {
    errorMessages: any;
    ui_url: string;
    setErrorMessage: (errorMessage: any) => void;
}

/**
 * to send POST request to server of error data
 * @param RequestArray 
 * @param ui_url 
 * @param setErrorMessage 
 */
const handleSubmit = (RequestArray: any[], ui_url: any, setErrorMessage: any) => {
    if (RequestArray) {
        const responseArray: any = [];
        RequestArray.forEach((RequestObj: any,) => {
            const RequestObject: any = {
                payload: RequestObj.payload,
                api_url: RequestObj.api_url,
                response_code: RequestObj.response_code,
                response_message: RequestObj.response_message,
                http_method: RequestObj.http_method,
                loggedin_user: RequestObj.loggedin_user,
                ui_url: ui_url,
                user_profile: RequestObj.user_profile
            }
            responseArray.push(apiGlobal
                .post(queryKeyes.vessel.ErrorReport.url(), RequestObject))
        })
        handleServerResponse(responseArray)
    }
    setErrorMessage(null)
}



/**
 * To view Error in tabular format
 * @param errorMessages 
 * @param ui_url 
 * @param setErrorMessage 
 * @returns 
 */
const ErrorReport = ({ errorMessages, ui_url, setErrorMessage }: ErrorReportingInterface) => {
    /** filter error messages */
    errorMessages = FilterErrorMessage(errorMessages, [ErrorCode.NON_FIELD_ERROR])
    if (errorMessages) {
        return (
            <div className="card border border-danger">
                <div className="card-header bg-transparent border-danger">
                    <h5 className="my-0 text-danger">Form Submission Error Message</h5>
                </div>
                <div className="card-body">
                    {/* Looping through the errorMessages array to render each table */}
                    {errorMessages.error &&
                        errorMessages.error.map((errorMessage: any, index: number) => {
                            /** If it is an array (nested array) */
                            if (Array.isArray(errorMessage)) {
                                return errorMessage.map((errorObj: any, subIndex: number) => {
                                    /** if error type is in html format */
                                    if (typeof errorObj === 'string' && errorObj.includes("<!DOCTYPE html>")) {
                                        const parser = new DOMParser();
                                        const doc = parser.parseFromString(errorObj, 'text/html');
                                        const summaryDiv = doc.getElementById('summary');
                                        const exceptionValue = summaryDiv ? summaryDiv.querySelector('td pre')?.innerHTML : '';
                                        const h1Error = summaryDiv ? summaryDiv.querySelector('h1')?.innerHTML : '';
                                        /** Extract the Request URL */
                                        const requestUrl = summaryDiv ? summaryDiv.querySelector('tr:nth-child(2) td')?.textContent : '';
                                        /** Extract only api name with uderscore (by default) */
                                        const extractedAPIEndPoint = requestUrl
                                            ? requestUrl.split('/').slice(-2, -1)[0] // Extracts the second-to-last part of the URL
                                            : '';
                                        const formattedAPIName = extractedAPIEndPoint
                                            ? extractedAPIEndPoint.replace(/_/g, ' ') // Replace underscores with spaces
                                                .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
                                            : '';
                                        /** set error message */
                                        errorMessages.api_data[index].response_message = exceptionValue
                                        return (
                                            <React.Fragment>
                                                <h4>{formattedAPIName}</h4>
                                                <div>{h1Error}</div>
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <div key={`${index}-${subIndex}`}>
                                                <h4>{`${errorMessages?.api_data[index]?.response_message[subIndex]?.api_name} - ${subIndex + 1}`}</h4>
                                                <div className="table-responsive mb-4">
                                                    <table className="table mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Field</th>
                                                                <th>Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {Object.keys(errorObj).map((key: string, objIndex) =>
                                                                key !== "api_name" && (
                                                                    <tr key={objIndex}>
                                                                        <td>{objIndex + 1}</td>
                                                                        <td>{key}</td>
                                                                        <td>
                                                                            {`${errorObj[key]?.code ? errorObj[key].code + " - " : ""} ${errorObj[key]?.message}`}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        );
                                    }
                                });
                            } else {
                                /** If it's a single error object, render it normally */
                                if (typeof errorMessage === 'string' && errorMessage?.includes("<!DOCTYPE html>")) {
                                    /** if error type is in html format */
                                    const parser = new DOMParser();
                                    const doc = parser.parseFromString(errorMessage, 'text/html');
                                    const summaryDiv = doc.getElementById('summary');
                                    const exceptionValue = summaryDiv ? summaryDiv.querySelector('td pre')?.innerHTML : '';
                                    const h1Error = summaryDiv ? summaryDiv.querySelector('h1')?.innerHTML : '';
                                    /** Extract the Request URL */
                                    const requestUrl = summaryDiv ? summaryDiv.querySelector('tr:nth-child(2) td')?.textContent : '';
                                    /** Extract only api name with uderscore (by default) */
                                    const extractedAPIEndPoint = requestUrl
                                        ? requestUrl.split('/').slice(-2, -1)[0] // Extracts the second-to-last part of the URL
                                        : '';
                                    const formattedAPIName = extractedAPIEndPoint
                                        ? extractedAPIEndPoint.replace(/_/g, ' ') // Replace underscores with spaces
                                            .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize the first letter of each word
                                        : '';
                                    /** set error message */
                                    errorMessages.api_data[index].response_message = exceptionValue
                                    return (
                                        <React.Fragment>
                                            <h4>{formattedAPIName}</h4>
                                            <div>{h1Error}</div>
                                        </React.Fragment>
                                    )
                                } else {
                                    return (
                                        <div key={index}>
                                            <h4>{errorMessages?.api_data[index]?.response_message?.api_name}</h4>
                                            <div className="table-responsive mb-4">
                                                <table className="table mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Field</th>
                                                            <th>Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.keys(errorMessage).map((key: string, objIndex) => (
                                                            key !== "api_name" && (
                                                                <tr key={objIndex}>
                                                                    <td>{objIndex + 1}</td>
                                                                    <td>{key}</td>
                                                                    <td>
                                                                        {`${errorMessage[key]?.code ? errorMessage[key].code + " - " : ""} ${errorMessage[key]?.message}`}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        })
                    }
                </div>
                <div className="p-2 d-flex justify-content-end">
                    {/* call handleSubmit function to send mail to admin by sending post request to error_report api */}
                    <Button
                        color="danger"
                        onClick={() => handleSubmit(errorMessages.api_data, ui_url, setErrorMessage)}
                    >Report</Button>
                </div>
            </div>
        );
    }
};

export default ErrorReport;
