import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse, handleServerResponse } from 'GenericForms/Helper';
import { loadCIIAdjustmentData, loadCIIAdjustmentUpdate } from 'VesselMaster/vesselMaster.hooks';
import { Field, FieldArray, Formik } from 'formik';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import { Button, Col, Form, Label, Modal, Row } from 'reactstrap';
import { AdjustmentApprovalStatus } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

interface VoyageAdjustmentPopupType {
    state: boolean,
    setState: any,
    toggle: any,
    event: any,
    target: string,
    root: string
}

const VoyageAdjustmentPopup = ({
    state,
    setState,
    toggle,
    event,
    target,
    root
}: VoyageAdjustmentPopupType) => {
    /** State variables start */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [btnAction, setBtnAction] = useState('');
    /** State variables end */

    /** Queries */
    /** CII Adjustment records to ensure fuel sub types */
    const { data: ciiAdjustment, isLoading: ciiAdjustmentLoading, isError: ciiAdjustmentError } = useQuery(
        [queryKeyes.vessel.CIIAdjustment.key, VesselID],
        async () => {
            return await loadCIIAdjustmentData(VesselID);
        }, { staleTime: Infinity }
    );
    /** CII Adjustment record that will be updated */
    const { data: CIIAdjustmentPUT, isLoading: CIIAdjustmentPUTLoading, isError: CIIAdjustmentPUTError } = useQuery(
        [queryKeyes.vessel.CIIAdjustmentUpdate.key, VesselID, event.id],
        async () => {
            return await loadCIIAdjustmentUpdate(VesselID, event.id);
        }, { staleTime: Infinity }
    );
    /** Queries end */
    return (
        <Modal
            isOpen={state}
            toggle={() => {
                toggle();
            }}
            backdrop={"static"}
            id={target}
            size='xl'
        >
            <div className="modal-header p-2">
                <h5 className="modal-title m-0" id="staticBackdropLabel">
                    Select applicable CII Adjustment events
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setState(false);
                    }}
                    aria-label="Close"
                />
            </div>
            {(ciiAdjustmentLoading || CIIAdjustmentPUTLoading) && <div className='mb-3'><Loading message='Loading required data!' /></div>}
            {(ciiAdjustmentError || CIIAdjustmentPUTError) && <div className='mb-3'><ErrorComponent message='Unable to load required data!' /></div>}
            {!(ciiAdjustmentLoading || CIIAdjustmentPUTLoading) &&
                !(ciiAdjustmentLoading || CIIAdjustmentPUTError) &&
                <div className="modal-body p-2">
                    {CIIAdjustmentPUT?.length <= 0 ?
                        <Label className='mb-0'>No data</Label> :
                        <Formik
                            initialValues={{
                                adjustment: CIIAdjustmentPUT
                            }}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(false);
                                const handleResponse = (response: any) => {
                                    if (response.status === 200) {
                                        successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.pagination.VoyageAdjustmentPage.key);
                                        toggle();
                                    }
                                }
                                if (btnAction === 'save') {
                                    let arrayResponse: any = [];
                                    values?.adjustment?.map((val: any) => {
                                        arrayResponse.push(apiGlobal.put(`/cii_adjustment_reporting_for_approval/${val.id}/`, val))
                                        return val;
                                    });
                                    handleServerResponse(arrayResponse).then(async (res) => {
                                        if (res) {
                                            queryClient.invalidateQueries(queryKeyes.vessel.CIIAdjustmentUpdate.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.CIIAdjustment.key);
                                            queryClient.invalidateQueries(queryKeyes.pagination.VoyageAdjustmentPage.key);
                                            toggle();
                                        }
                                    });
                                }
                                if (btnAction === 'approve') {
                                    event.user_approved = AdjustmentApprovalStatus.APPROVED;
                                    apiGlobal.put(`/vessel_reporting_cii_adjustment/${event.id}/`, event).then(res => {
                                        handleResponse(res);
                                    }).catch(err => {
                                        if (errResponse.includes(err.response.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                                }
                                if (btnAction === 'disapprove') {
                                    event.user_approved = AdjustmentApprovalStatus.DISAPPROVED;
                                    apiGlobal.put(`/vessel_reporting_cii_adjustment/${event.id}/`, event).then(res => {
                                        handleResponse(res);
                                    }).catch(err => {
                                        if (errResponse.includes(err.response.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                                }
                            }}
                        >
                            {props => (
                                <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off' className='tabHeight'>
                                    <div className="table-responsive mb-2">
                                        <table className="table mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className='p-2'>Applicable</th>
                                                    <th className='p-2'>Date</th>
                                                    <th className='p-2'>Reporting Event</th>
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('hfo_consumption') &&
                                                        <th className='p-2'>HFO</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lfo_consumption') &&
                                                        <th className='p-2'>LFO</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('mdo_consumption') &&
                                                        <th className='p-2'>MDO</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('mgo_consumption') &&
                                                        <th className='p-2'>MGO</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lng_consumption') &&
                                                        <th className='p-2'>LNG</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lpg_butane_consumption') &&
                                                        <th className='p-2'>LPG Butane</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lpg_propane_consumption') &&
                                                        <th className='p-2'>LPG Propane</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('hygrogen_gas_consumption') &&
                                                        <th className='p-2'>Hydrogen</th>}
                                                    {Object.keys(ciiAdjustment[0]?.consumption)?.includes('nh3_consumption') &&
                                                        <th className='p-2'>Ammonia</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name="adjustment">
                                                    {() => (
                                                        <>
                                                            {props?.values?.adjustment?.map((adjustment: any, index: number) => {
                                                                return (
                                                                    <tr>
                                                                        <td className='p-2'>
                                                                            <Field
                                                                                type='checkbox'
                                                                                id={`cii_adjustment${index}`}
                                                                                name={`adjustment.${index}.user_approval`}
                                                                                values={props?.values?.adjustment?.user_approval}
                                                                            />
                                                                        </td>
                                                                        <td className='p-2'>
                                                                            {adjustment?.reporting_date_time?.toString()?.substring(0, 10)}&nbsp;
                                                                            {adjustment?.reporting_date_time?.toString()?.substring(12, 16)}
                                                                        </td>
                                                                        <td className='p-2'>
                                                                            {adjustment?.reporting_event_name}
                                                                        </td>
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('hfo_consumption') &&
                                                                            <td className='p-2'>{adjustment?.hfo_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lfo_consumption') &&
                                                                            <td className='p-2'>{adjustment?.lfo_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('mdo_consumption') &&
                                                                            <td className='p-2'>{adjustment?.mdo_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('mgo_consumption') &&
                                                                            <td className='p-2'>{adjustment?.mgo_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lng_consumption') &&
                                                                            <td className='p-2'>{adjustment?.lng_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lpg_butane_consumption') &&
                                                                            <td className='p-2'>{adjustment?.lpg_butane_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('lpg_propane_consumption') &&
                                                                            <td className='p-2'>{adjustment?.lpg_propane_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('hygrogen_gas_consumption') &&
                                                                            <td className='p-2'>{adjustment?.hyadrogen_gas_consumption}</td>}
                                                                        {Object.keys(ciiAdjustment[0]?.consumption)?.includes('nh3_consumption') &&
                                                                            <td className='p-2'>{adjustment?.nh3_consumption}</td>}
                                                                    </tr>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </tbody>
                                        </table>
                                    </div>
                                    <Row>
                                        {root === 'pending' ?
                                            <Col sm={{ size: 8, offset: 4 }}>
                                                <Button type='submit' className='btn pos-end-14' color='danger' onClick={() => {
                                                    setBtnAction('disapprove');
                                                }}>Disapprove</Button>
                                                <Button type='submit' className='btn pos-end-10' color='primary' onClick={() => {
                                                    setBtnAction('save');
                                                }}>Save</Button>
                                                <Button type='submit' className='btn justify_right' color='primary' onClick={() => {
                                                    setBtnAction('approve');
                                                }}>Save & Approve</Button>
                                            </Col> :
                                            <Col sm={{ size: 1, offset: 11 }}>
                                                <Button type='submit' className='btn justify_right' color='primary' onClick={() => {
                                                    setBtnAction('save');
                                                }} disabled={props.isSubmitting}>
                                                    {props.isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                    Update
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                    <FormValuesDebug values={[props?.values, props?.errors]} />
                                </Form>
                            )}
                        </Formik>
                    }
                </div>
            }
        </Modal>
    )
}

export default VoyageAdjustmentPopup