import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';
import { Field, useFormikContext } from 'formik';
import React from 'react'
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
interface SteamDumpDetailsType {
    adjustmentPopup: boolean;
}

const SteamDumpDetailsComponent = ({ adjustmentPopup }: SteamDumpDetailsType) => {
    const { errors }:
        { errors: any } = useFormikContext<any>();
    return (
        <Row>
            <Col className='p-0'>
                <Card className='mb-0 pr-0 rounded-0'>
                    <CardHeader className='p-2'>
                        <div className="text-center">
                            <Row>
                                <Col>
                                    <h4 className="page_title pos-start mb-0">Steam Dump Details</h4>
                                    <p className="card-title-desc pos-start">All readings since last report</p>
                                </Col>
                            </Row>
                        </div>
                    </CardHeader>
                    <CardBody sm={12} className='px-2 py-0 align-middle'>
                        <Row className='my-3'>
                            <Col className='d-flex'>
                                <div className='align-middle mr-1'>
                                    <Label className='mb-0' htmlFor='valveDetails' for='valveDetails'>Steam dump valve</Label>
                                </div>
                                <div>
                                    <div className='input-group'>
                                        <Field
                                            type="text"
                                            name="steamDumpDetails.steam_dump_valve_percentage"
                                            id="valveDetails"
                                            className="form-control text-right max-width-7"
                                        />
                                        <div className='input-group-text'>% open</div>
                                    </div>
                                    {errors?.steamDumpDetails && errors?.steamDumpDetails?.steam_dump_valve_percentage && env?.form_validation === true &&
                                        <ErrorTooltip
                                            target='valveDetails'
                                            message={errors?.steamDumpDetails?.steam_dump_valve_percentage}
                                            open={errors?.steamDumpDetails && errors?.steamDumpDetails?.steam_dump_valve_percentage && !adjustmentPopup ? true : false}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default SteamDumpDetailsComponent
