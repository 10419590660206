import React, { useState } from "react";
import {
    Row,
    Col,
    Form,
    Input,
    FormFeedback,
    Label,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import PropTypes from "prop-types";
import withRouter from "../Components/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from "../Media/LogoLogin.png";
import CarouselPage from "../Components/CarouselPage";
import { errorToast, infoToast, successToast } from "../Components/Toasts";
import { getAuthData, setAuthData } from "../utils/auth/authUtils";
import env from '../environment_system/env_system';
import { encrypt } from "GenericForms/Helper";
import apiGlobal from "global/api.global";
import { useDocumentTitle } from "Components/useDocument.hooks";

const Login = () => {
    /** State variables start */
    useDocumentTitle('Login - ecoSAIL');
    const [PasswordType, setPasswordType] = useState<'password' | 'text'>('password');
    const [blockedAlert, setBlockedAlert] = useState(false);
    /** State variables end */

    /** Login's formik object */
    const LoginFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: "",
            password: "",
            checkbox: false,
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().min(1).required("Please Enter Your Password"),
        }),
        onSubmit: async (values) => {
            LoginFormik.setSubmitting(true);
            infoToast("Checking Logging! Please wait!");
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let verification = false;
            const encryptedPassword = encrypt(values?.password);
            await apiGlobal.post('login/', {
                email: values?.username,
                password: encryptedPassword,
            }).then(async (response: any) => {
                if (response.status === 200) {
                    setBlockedAlert(false);
                    verification = true;
                    // eslint-disable-next-line no-restricted-syntax
                    await setAuthData(JSON.stringify(response.data));
                    if (env.desktop_app === true && getAuthData()) {
                        successToast('Logged in successfully!');
                        window.location.href = "/#dashboard";
                    } else {
                        successToast("Logged in successfully!");
                        window.location.href = "/dashboard";
                    }
                } else {
                    verification = false;
                }
                return response.data;
            }).catch((error: any) => {
                if (error?.response?.data?.status[0] === "403") {
                    setBlockedAlert(true);
                } else {
                    errorToast(error?.response?.data?.error[0]);
                }
            })
            LoginFormik.setSubmitting(false);
        },
    });

    // const { error } = useSelector((state: any) => ({
    //     error: state.login.error,
    // }));
    const toggle = () => setBlockedAlert(!blockedAlert);
    return (
        <React.Fragment>
            <div className="auth-page">
                {/* <Container fluid className="p-0"> */}
                <Row className="g-0">
                    <Col lg={4} md={5} className="col-xxl-3">
                        <div className="auth-full-page-content d-flex p-sm-5 p-4">
                            <div className="w-100">
                                <div className="d-flex flex-column h-100">
                                    <div className="mb-4 mb-md-5 text-center">
                                        <div className="d-block auth-logo">
                                            <img src={logo} alt="" height="140" />{" "}
                                        </div>
                                    </div>
                                    <Form
                                        className="custom-form mt-4 pt-2"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            LoginFormik.handleSubmit();
                                        }}
                                        autoComplete="off"
                                        noValidate
                                    >
                                        {/* {error ? <Alert color="danger">{error}</Alert> : null} */}
                                        <div className="mb-3">
                                            <Label className="form-label mb-0">Email</Label>
                                            <Input
                                                name="username"
                                                className="form-control"
                                                type="email"
                                                onChange={LoginFormik.handleChange}
                                                onBlur={LoginFormik.handleBlur}
                                                defaultValue={LoginFormik.values.username}
                                                invalid={
                                                    LoginFormik.touched.username &&
                                                        LoginFormik.errors.username
                                                        ? true : false
                                                }
                                            />
                                            {LoginFormik.touched.username &&
                                                LoginFormik.errors.username ? (
                                                <FormFeedback type="invalid">
                                                    {LoginFormik.errors.username}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <Label className="form-label mb-0">Password</Label>
                                        <div className="mb-3 position-relative">
                                            <Input
                                                name="password"
                                                className="form-control"
                                                defaultValue={LoginFormik.values.password}
                                                type={PasswordType}
                                                onChange={LoginFormik.handleChange}
                                                onBlur={LoginFormik.handleBlur}
                                                invalid={
                                                    LoginFormik.touched.password &&
                                                        LoginFormik.errors.password
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <i
                                                className={`mdi ${PasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'} eye`}
                                                onClick={() => setPasswordType(PasswordType === 'password' ? 'text' : 'password')}
                                            />
                                            {LoginFormik.touched.password &&
                                                LoginFormik.errors.password ? (
                                                <FormFeedback type="invalid">
                                                    {LoginFormik.errors.password}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col">
                                                <div className="mt-3 d-grid">
                                                    <button className="primary btn btn-primary" disabled={LoginFormik.isSubmitting} type="submit">
                                                        {
                                                            LoginFormik.isSubmitting ?
                                                                <React.Fragment>
                                                                    <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                                                                    Loading...
                                                                </React.Fragment> :
                                                                'Log In'
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <Modal isOpen={blockedAlert} toggle={toggle} centered>
                                            <ModalHeader className="bg-transparent border-danger text-danger">
                                                Notification
                                            </ModalHeader>
                                            <ModalBody>
                                                <div className="pt-2 pb-2" >
                                                    <CardBody>
                                                        <h5 className="card-title text-danger">
                                                            Account Blocked
                                                        </h5>
                                                        <p className="card-text text-danger">
                                                            Your account is blocked. Contact your manager for further details.
                                                        </p>
                                                    </CardBody>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                        {/*{env?.desktop_app === true &&
                                            verification === true &&
                                            <>
                                             <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="remember-check"
                                                        name="checkbox"
                                                        onChange={(e: any) => validation.handleChange(e)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="remember-check"
                                                    >
                                                        Check this to continue
                                                    </label>
                                                </div>
                                                <Row className="mt-3">
                                                    {validation.values.checkbox === false &&
                                                        <Navigate to={"/dashboard"} />
                                                    }
                                                </Row>
                                            </>
                                        } */}
                                    </Form>
                                    <div className="mt-4 mt-md-5 text-center">
                                        <p className="mb-0">
                                            © {new Date().getFullYear()}. Developed by{" "}
                                            ecoSAIL
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <CarouselPage />
                </Row>
                {/* </Container> */}
            </div >
            <ToastContainer />
        </React.Fragment >
    );
};

export default withRouter(Login);
Login.propTypes = {
    history: PropTypes.object,
};


