import React from 'react';
import { Card, CardBody, Col, Row, Label, Form } from "reactstrap";
import male from "../../assets/images/users/avatar-1.jpg"
import female from "../../assets/images/users/femaleavatar-1.jpg"
import other from "../../assets/images/users/other.jpg"
import '../../global/GlobalCSS.css';
import { getAuthData } from 'utils/auth/authUtils';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import { fetchUserProfileById } from 'VesselMaster/vesselMaster.hooks';

const ProfileDetail = () => {
    const userId = getAuthData('user_id');
    const { data: profileData }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.user.profileID.key, userId],
            async () => { return await fetchUserProfileById(userId) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    return (
        <React.Fragment>
            <Card className="shadow p-4" style={{ maxWidth: '800px', width: '100%', borderRadius: '10px' }}>
                <Row>
                    <Col lg={12} className="ps-2">
                        <Card className='pos-center' style={{ maxWidth: '25rem' }}>
                            <CardBody>
                                <Form>
                                    <Row className='mb-3'>
                                        <Col lg={12}>
                                            <div className="d-flex">
                                                <div className="ms-0">
                                                    {(profileData?.gender === null ) || (profileData?.gender === "Male") ? 
                                                    <img
                                                        src={male}
                                                        alt=""
                                                        className="avatar-1 rounded-circle img-thumbnail "
                                                        style={{ maxHeight: "4rem" }}
                                                    /> 
                                                    : (profileData?.gender === "Female") ? 
                                                    <img
                                                        src={female}
                                                        alt=""
                                                        className="avatar-1 rounded-circle img-thumbnail "
                                                        style={{ maxHeight: "4rem" }}
                                                    /> : 
                                                    <img
                                                        src={other}
                                                        alt=""
                                                        className="avatar-1 rounded-circle img-thumbnail "
                                                        style={{ maxHeight: "4rem" }}
                                                    />
                                                    }
                                                </div>
                                                {<div className="flex-grow-1 align-self-center ms-3">
                                                    <div className="text-muted">
                                                        <h3 className="">Profile Details</h3>
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="mb-3">
                                                <Label for="first_name" className='mb-0 bold_lbl pe-2'>Name:</Label>
                                                <Label>{profileData?.first_name} {profileData?.middle_name} {profileData?.last_name}</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="mb-3">
                                                <Label for="last_name" className='mb-0 bold_lbl pe-2'>Mobile No:</Label>
                                                <Label>{profileData?.mobile}</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="mb-3">
                                                <Label for="user_role" className='mb-0 bold_lbl pe-2'>Role : </Label>
                                                <Label>{profileData?.role}</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="mb-3">
                                                <Label for="assigne_ship" className='mb-0 bold_lbl pe-2'>Gender : </Label>
                                                <Label>{profileData?.gender}</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={10}>
                                            <div className="mb-3">
                                                <Label for="login_email" className='mb-0 bold_lbl pe-2'>Email id : </Label>
                                                <Label>{profileData?.email}</Label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <h6 className='text-center'>Note : If you want to change your profile details, please contact the administrator.</h6>
                    </Col>
                </Row>
                <div >
                </div>
            </Card>
        </React.Fragment>
    )
}
export default ProfileDetail;