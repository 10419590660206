import { AlertMessages } from 'Alerts/AlertMessages';
import ErrorTooltip from 'Components/ErrorTooltip';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import env from 'environment_system/env_system';
import { Field, FieldArray, useFormikContext } from 'formik';
import React from 'react'
import Select from 'react-select';
import { Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { VesselMachineryConstant, FuelTypes, FUELLCV, ReportingEvents, AlertColourTypeConstant, AlertTypeConstant } from 'shared/constants';
interface FuelConsumptionType {
    adjustmentPopup: boolean;
    machineryFuelGroup: any;
    vesselMachineries: any;
    fuelTypes: any;
    setMeasurementMethod: any;
    measurementMethodOptions: any;
    Fuel_Consumption_inputs: any
    calculateTotalDOeq: any;
    SpeedAndConsumption: any;
    record: any;
    setCount: any;
    setAlertBool: any;
    setAlertMessage: any;
    setAlertColourType: any;
    setAlertType: any;
    machineryEnabled: any;
    loadFuelConsumptionSum: any;
    count: any
}

const FuelConsumption = ({ adjustmentPopup, machineryFuelGroup, vesselMachineries, fuelTypes,
    setMeasurementMethod, measurementMethodOptions, Fuel_Consumption_inputs, calculateTotalDOeq, SpeedAndConsumption, record,
    setCount, setAlertBool, setAlertMessage, setAlertColourType, setAlertType, machineryEnabled, loadFuelConsumptionSum, count }: FuelConsumptionType) => {
    const { values, errors, touched, handleChange, handleBlur }:
        { values: any, errors: any, touched: any, handleChange: any, handleBlur: any } = useFormikContext<any>();
    return (
        <Row className='px-0 m-0'>
            <Card className='px-0 rounded-0 mb-0 m-0 border-bottom-0 border-start-0 border-end-0'>
                <CardHeader className='p-2'>
                    <div className="text-center">
                        <Row>
                            <Col className='ps-0'>
                                <h4 className="page_title pos-start mb-0">Fuel Consumption</h4>
                                <p className="card-title-desc pos-start">All readings since last report</p>
                            </Col>
                        </Row>
                    </div>
                </CardHeader>
                <CardBody className='p-0 mt-2'>
                    <Row>
                        <Col lg={4} className='ps-2'>
                            <Label className='mb-0' for='method_used_to_measure_fuel_oil_consumption'>
                                Method used to measure fuel consumption
                                <i className='bx bx-info-circle ml-2p' id='method_used_to_measure_fuel_oil_consumption_msg' />
                            </Label>
                            <ToolTip target='method_used_to_measure_fuel_oil_consumption_msg'
                                message={`${TooltipMsg.FuelConsumption
                                    .filter((item: any) => item.target === 'method_used_to_measure_fuel_oil_consumption_msg')
                                    .map((tool: any) => { return tool.message })}`}
                            />
                            <Field name={`fuelConsumption.${0}.method_used_to_measure_fuel_oil_consumption`}>
                                {() => (
                                    <Select
                                        options={measurementMethodOptions}
                                        getOptionLabel={(option: any) => option.label}
                                        getOptionValue={(option: any) => option.id}
                                        onChange={(e: any) => setMeasurementMethod(e.label)}
                                        className='mb-3'
                                        inputId='method_used_to_measure_fuel_oil_consumption'
                                        defaultValue={{
                                            id: measurementMethodOptions
                                                .filter((item: any) => values?.fuelConsumption[0]?.method_used_to_measure_fuel_oil_consumption === item.label)
                                                .map((method: any) => { return method.label }),
                                            label: values?.fuelConsumption[0]?.method_used_to_measure_fuel_oil_consumption
                                        }}
                                    />
                                )}
                            </Field>
                        </Col>
                    </Row>
                    {(machineryFuelGroup && machineryFuelGroup?.length > 0) &&
                        <div className="table-responsive">
                            <table className="table mb-0">
                                <thead className="table-light">
                                    <tr>
                                        <th className='p-2 align-middle sr-no-width'>#</th>
                                        {Fuel_Consumption_inputs.filter((table: any) => table.table_columns === true).sort((a: any, b: any) => a.table_columns_sequence - b.table_columns_sequence).map(({ name, ...props }: any) => {
                                            return (
                                                <th key={name} className={props.className + 'text-left p-2'}>{props.label}</th>
                                            )
                                        })
                                        }
                                        {fuelTypes?.filter((item: any) => item.display === true)
                                            .sort((a: any, b: any) => a.precedence_id - b.precedence_id).map((fuelType: any, index: number) => {
                                                return (
                                                    <th className="p-2 text-center" key={index}>{fuelType.fuel_type_name}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {vesselMachineries.flatMap((machine: any, index: any) => {
                                        return (
                                            <tr key={index}>
                                                <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                <td className='p-2 align-middle'>{machine.vessel_machinery_fc_group_name}</td>
                                                <FieldArray name='fuelConsumption'>
                                                    {() => (
                                                        <React.Fragment>
                                                            {values.fuelConsumption?.sort((a: any, b: any) => a.precedence_id - b.precedence_id)
                                                                ?.filter((fuelConsumptionMachine: any) => fuelConsumptionMachine.vessel_machinery_fc_group === machine.id)
                                                                .map((fuel_consumptions: any, index: number) => (
                                                                    <td className="p-2 align-middle" key={index}>
                                                                        <div className="input-group justify-content-center">
                                                                            <Field name={`fuelConsumption.${values.fuelConsumption.indexOf(fuel_consumptions)}.fuel_consumed`}>
                                                                                {() => (
                                                                                    <Input
                                                                                        name={`fuelConsumption.${values.fuelConsumption.indexOf(fuel_consumptions)}.fuel_consumed`}
                                                                                        type="text"
                                                                                        className="form-control max-width-7 text-right"
                                                                                        id={`fuel_consumed_${(values.fuelConsumption.indexOf(fuel_consumptions))}`}
                                                                                        onChange={(e: any) => {
                                                                                            handleChange(e);
                                                                                            setCount(count + 1);
                                                                                        }}
                                                                                        onBlur={async (e) => {
                                                                                            handleBlur(e);
                                                                                            if (env.alerts) {
                                                                                                if (values?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.machinery_precedence_id === VesselMachineryConstant.MAIN_ENGINE) {
                                                                                                    await calculateTotalDOeq(
                                                                                                        {},
                                                                                                        values?.fuelConsumption?.filter((item: any) => item.machinery_precedence_id === VesselMachineryConstant.MAIN_ENGINE),
                                                                                                        () => { },
                                                                                                        null,
                                                                                                        false,
                                                                                                        "precedence_id",
                                                                                                        "fuel_consumed",
                                                                                                        false
                                                                                                    ).then((res: any) => {
                                                                                                        if (res?.DOeq > 0) {
                                                                                                            let DOeqSpeedAndConsumption;
                                                                                                            if (SpeedAndConsumption?.vessel_fuel === FuelTypes.DIESEL_OIL) {
                                                                                                                DOeqSpeedAndConsumption = SpeedAndConsumption?.fuel_consumption
                                                                                                            } else {
                                                                                                                DOeqSpeedAndConsumption =
                                                                                                                    ((SpeedAndConsumption?.fuel_consumption ?? 0) *
                                                                                                                        (res?.FuelLCVMaster?.filter((item: any) => item.fuel_type_precedence_id === SpeedAndConsumption?.vessel_fuel)[0]?.lcv * 1000000) /
                                                                                                                        FUELLCV.DO)
                                                                                                            }
                                                                                                            if (record?.reporting_event === ReportingEvents.NOON_RFA_AS || record?.reporting_event === ReportingEvents.HASP ||
                                                                                                                record?.reporting_event === ReportingEvents.EOSP) {
                                                                                                                if (res.DOeq > (parseFloat(DOeqSpeedAndConsumption.toFixed(2)) + (parseFloat(DOeqSpeedAndConsumption.toFixed(2)) * 0.1))) {
                                                                                                                    setAlertBool(true);
                                                                                                                    setAlertMessage(AlertMessages.FuelConsumption.ten_per_more);
                                                                                                                    setAlertColourType(AlertColourTypeConstant.WARNING);
                                                                                                                    setAlertType(AlertTypeConstant.INFO_ONLY);
                                                                                                                } else if (res.DOeq > (parseFloat(DOeqSpeedAndConsumption.toFixed(2)) + (parseFloat(DOeqSpeedAndConsumption.toFixed(2)) * 0.05))) {
                                                                                                                    setAlertBool(true);
                                                                                                                    setAlertMessage(AlertMessages.FuelConsumption.five_per_more);
                                                                                                                    setAlertColourType(AlertColourTypeConstant.WARNING);
                                                                                                                    setAlertType(AlertTypeConstant.INFO_ONLY);
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                        disabled={machineryEnabled(machine.id, fuel_consumptions.vessel_fuel)}
                                                                                        value={machineryEnabled(machine.id, fuel_consumptions.vessel_fuel) === true ? 0 :
                                                                                            values?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                            <div className="input-group-text round_border">mt</div>
                                                                            {errors?.fuelConsumption && touched?.fuelConsumption && touched?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed &&
                                                                                errors?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed &&
                                                                                env?.form_validation &&
                                                                                <ErrorTooltip
                                                                                    target={`fuel_consumed_${(values.fuelConsumption.indexOf(fuel_consumptions))}`}
                                                                                    message={errors?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed}
                                                                                    open={(errors?.fuelConsumption && errors?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed) &&
                                                                                        !adjustmentPopup ? true : false}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                ))
                                                            }
                                                        </React.Fragment>
                                                    )}
                                                </FieldArray>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th className="p-2" colSpan={2}></th>
                                        {fuelTypes && fuelTypes.length > 0 && fuelTypes
                                            .sort((a: any, b: any) => a.precedence_id - b.precedence_id).map((fuelType: any) => {
                                                return (
                                                    <th className="p-2 text-center" key={fuelType.id}>
                                                        {fuelType.fuel_type_name} - {loadFuelConsumptionSum(values?.fuelConsumption)[fuelType.precedence_id]?.toFixed(4) || 0}
                                                    </th>
                                                )
                                            }
                                            )}
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    }
                </CardBody>
            </Card>
        </Row>
    )
}

export default FuelConsumption
