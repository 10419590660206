import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import apiGlobal from "../global/api.global";
import * as Yup from "yup";
import "../global/GlobalCSS.css";
import Layout from "../HorizontalMenu/Menu";
import Navbar from "../HorizontalMenu/Navbar";
import VesselDetailsHeader from "../Components/VesselDetailsHeader";
import { useDispatch, useSelector } from "react-redux";
import {
    VesselState,
    getVesselsAction,
    setVesselState
} from "../Store/Generic/ReportingSlice";
import { errorToast, successToast } from "../Components/Toasts";
import { PortConstant, VoyageConstant } from "shared/constants";
import {
    loadLastReportVessel,
    loadOnboardingVoyageObject,
    loadOtherPorts,
    loadVoyageObject,
} from "VesselMaster/vesselMaster.hooks";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { queryClient } from "react-query/queryClient";
import CreateVoyageStructure from "./CreateVoyageStructure";
import env from 'environment_system/env_system';
import { useDocumentTitle } from "Components/useDocument.hooks";

interface CreateVoyageType {
    voyageId?: number;
    toggleTab?: (activeTab: number) => void,
    activeTab?: number
    setVoyageId?: (voyageId: number) => void
    isFirstReport?: boolean
}

const CreateVoyage = ({
    voyageId,
    toggleTab,
    activeTab,
    setVoyageId,
    isFirstReport = false
}: CreateVoyageType) => {
    /** State variables */
    const dispatch = useDispatch();
    const { VesselState, VesselID } = useSelector(
        (state: any) => state.Reporting
    );
    const [otherPort, setOtherPort] = useState(false);
    const [addOtherPort, setAddOtherPort] = useState(false);
    const [documentTitle, setDocumentTitle] = useState<any>(null);
    useDocumentTitle(documentTitle);
    // /** State variables end */

    /** useQueries */
    /** Voyage object used for edit */
    const {
        data: VoyageObject,
        isLoading: VoyageObjectLoading,
    } = useQuery(
        [
            isFirstReport
                ? queryKeyes.vessel.GetOnboardingVoyageByVessel.key
                : queryKeyes.vessel.VoyageObject.key,
            isFirstReport ? VesselID : voyageId
        ],
        async () => {
            return isFirstReport
                ? await loadOnboardingVoyageObject(VesselID)
                : await loadVoyageObject(voyageId);
        },
        // { staleTime: Infinity }
    );

    /** Other Ports from master */
    const {
        data: OtherPortsObject,
        isLoading: OtherPortsLoading,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.masters.OtherPortMaster.key],
        async () => {
            return await loadOtherPorts();
        },
        { staleTime: Infinity }
    );
    /** useQueries end */

    /** useEffect start */
    useEffect(() => {
        dispatch(getVesselsAction("vessel_master" as string));
    }, [dispatch]);
    
    useEffect(() => {
        setDocumentTitle(voyageId ? 'Edit Voyage - ecoSAIL' : (isFirstReport ? 'Create Voyage - Onboarding - ecoSAIL' : 'Create Voyage - ecoSAIL'));
    }, [voyageId, isFirstReport]);
    /** useEffect end */


    /** Assigning initial object to voyage's formik object */
    const getInitialValues = () => {
        if (VoyageObject && VoyageObject?.id > 0) {
            return VoyageObject
        } else {
            return {
                voyage_number: "",
                voyage_status: VoyageConstant.NEWVOYAGE,
                departure_date_time: null,
                departure_port: null,
                other_port: null as number,
                other_port_name: null as string,
                other_country: null as number,
                other_country_name: null as string,
                vessel: VesselID,
            };
        }
    };

    /**useEffect start */
    useEffect(() => {
        if (VoyageObject && VoyageObject?.id > 0) {
            if (VoyageObject?.port_precedence_id === PortConstant.OTHER) {
                setOtherPort(true);
            }
        }
    }, [VoyageObject]);
    /** useEffect end */
    const { data: report } = useQuery(
        [queryKeyes.vessel.LastVesselReport.key, VesselID],
        async () => await loadLastReportVessel(VesselID),
        {
            staleTime: Infinity
        }
    )
    const prev_report = report?.local_time
    /** Voyage Information formik object */
    const VoyageFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            voyage_number: Yup.string()
                .matches(
                    /^(?![-/])[A-Za-z0-9-/&]{1,50}$/,
                    "Please enter up to 50 alphabets/numbers only"
                )
                .required(commonValidationMessages.required),
            departure_port: Yup.string().required(commonValidationMessages.required),
            departure_date_time: Yup.string()
                .required(commonValidationMessages.required)
                .test(
                    "is-greater-than-prev-report",
                    "Departure date & time must be later than the last report time",
                    function (value) {
                        const prevReportTime = prev_report ? new Date(prev_report).getTime() : null;
                        const departureTime = value ? new Date(value).getTime() : null;
                        if (prevReportTime && departureTime) {
                            return departureTime > prevReportTime;
                        }
                        return true;
                    }
                ),
            other_port: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(
                        otherPort === true &&
                        OtherPortsObject &&
                        OtherPortsObject?.length > 0
                    )
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_port_name: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_country: Yup.number().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
        }),
    };

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record));
    };

    /** POST request for Voyage Information */
    const postVoyage = (values: any) => {
        apiGlobal
            .post(
                isFirstReport
                    ? queryKeyes.vessel.OnboardVoyageObject.url()
                    : queryKeyes.vessel.VoyageInformation.url(),
                values
            )
            .then((res) => {
                if (res.status === 201) {
                    setTimeout(() => {
                        successToast("Data saved successfully!");
                    }, 1000)

                    if (isFirstReport) {
                        if (env.form_validation === true) {
                            toggleTab(activeTab + 1);
                        }
                        queryClient.invalidateQueries(queryKeyes.vessel.GetOnboardingVoyageByVessel.key);
                    } else {
                        handleVesselState("VOYAGE_REPORTING");
                        queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                    }
                    if (VesselState !== "VOYAGE_REPORTING") {
                        setVoyageId(res.data.id);
                    }
                }
            })
            .catch((err) => {
                errorToast(err?.response?.data?.error);
            });
    };


    /** PUT request for Voyage Information */
    const putVoyage = (values: any) => {
        apiGlobal
            .put(`${isFirstReport ?
                `${queryKeyes.vessel.OnboardVoyageObject.url()}` :
                `${queryKeyes.vessel.VoyageInformation.url()}`}${values?.id}/`, values)
            .then(async (res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        successToast("Data saved successfully!");
                    }, 1000)
                    queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                    if (isFirstReport) {
                        if (env.form_validation === true) {
                            toggleTab(activeTab + 1);
                        }
                        await queryClient.invalidateQueries(queryKeyes.vessel.GetOnboardingVoyageByVessel.key);
                    } else {
                        handleVesselState("VOYAGE_REPORTING");
                        queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                    }
                    if (VesselState !== "VOYAGE_REPORTING") {
                        setVoyageId(res.data.id);
                    }
                }
            })
            .catch((err) => {
                errorToast(err?.response?.data?.error);
            });
    };

    /** Voyage submit function */
    const voyageSubmit = (values: any) => {
        if (VoyageObject && VoyageObject?.id > 0) {
            putVoyage(values);
        } else {
            postVoyage(values);
        }
    };
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={2}>
                            <button
                                color="primary"
                                className="btn btn-primary mb-3"
                                onClick={() => {
                                    handleVesselState(isFirstReport ? null : "VOYAGE_REPORTING");
                                }}
                            >
                                <i className="bx bx-chevron-left me-1" />
                                Back
                            </button>
                        </Col>
                        <Col sm={10}>
                            <VesselDetailsHeader />
                        </Col>
                    </Row>
                    <CreateVoyageStructure
                        VoyageObjectLoading={VoyageObjectLoading}
                        OtherPortsLoading={OtherPortsLoading}
                        voyageSubmit={voyageSubmit}
                        VoyageFormik={VoyageFormik}
                        otherPort={otherPort}
                        setOtherPort={setOtherPort}
                        addOtherPort={addOtherPort}
                        setAddOtherPort={setAddOtherPort}
                        OtherPortsObject={OtherPortsObject}
                        VoyageObject={VoyageObject}
                        toggleTab={toggleTab}
                        activeTab={activeTab}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CreateVoyage;
