import Layout from 'HorizontalMenu/Menu';
import React from 'react';
import { Navbar, Container, Card, CardBody, NavLink } from 'reactstrap';
import ProfileDetail from './ProfileDetails';
import ForcedPasswordChange from 'Users/ForcedPasswordChange';
import classnames from "classnames";
import { useDocumentTitle } from 'Components/useDocument.hooks';

const ProfileSetting = () => {
    const [activeTab, setActiveTab] = React.useState("Profile");
    useDocumentTitle('Profile Setting - ecoSAIL');

    const renderContent = () => {
        switch (activeTab) {
            case "Profile":
                return <ProfileDetail />;
            case "ChangePassword":
                return (
                    <ForcedPasswordChange message={''} />
                );
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <Card className='shadow-sm mb-4 p-0'>
                <div className="page-content">
                    <Container fluid>
                        <h4 className="mb-2 ml-2">Profile Setting</h4>
                        <CardBody>
                            <div className="row">
                                {/* Left Side Menu */}
                                <div className="col-md-3">
                                    <div className="list-group">
                                        <NavLink
                                            color={activeTab === "Profile" ? "primary" : "light"}
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                "mb-1 list-group-item list-group-item-action": true,
                                                active: activeTab === `Profile`,
                                            })}
                                            onClick={() => setActiveTab("Profile")}
                                        >
                                            Profile
                                        </NavLink>
                                        <NavLink
                                            color={activeTab === "ChangePassword" ? "primary" : "light"}
                                            // className="list-group-item list-group-item-action text-start"
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                "mb-1 list-group-item list-group-item-action": true,
                                                active: activeTab === `ChangePassword`,
                                            })}
                                            onClick={() => setActiveTab("ChangePassword")}
                                        >
                                            Change Password
                                        </NavLink>
                                    </div>
                                </div>
                                {/* <Card> */}
                                {/* Right Side Content */}
                                <div className="col-md-9">{renderContent()}</div>
                                {/* </Card> */}
                            </div>
                        </CardBody>
                    </Container>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default ProfileSetting;