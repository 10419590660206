import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { errResponse, handleServerResponse } from 'GenericForms/Helper';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Row, Col, Button, Card, CardHeader, CardBody, Label, CardFooter } from 'reactstrap'
import { queryKeyes } from 'shared/queryKeys';
import { loadBDNFiles, loadBunkeringObject } from 'VesselMaster/vesselMaster.hooks';
import Select from "react-select";
import { customStyle } from 'shared/CommonCSS';
import { useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal, { apiMedia } from 'global/api.global';
import { BDNConstant, FileStatus } from 'shared/constants';
import { queryClient } from 'react-query/queryClient';
import DeletePopOver from 'Components/DeletePopOver';
import ReportingFileUpload from 'Components/ReportingFileUpload';
import * as Yup from "yup";
import env from 'environment_system/env_system';
import ErrorTooltip from 'Components/ErrorTooltip';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import { useDocumentTitle } from 'Components/useDocument.hooks';

interface BunkeringType {
    ReportID: number;
    VesselID: number;
    clearErrors?: boolean,
    setModalState?: (value: boolean) => void;
    modalState?: boolean;
    toggleDynamicTabs?: (value: number) => void;
    previousTabIndex?: number;
    setErrorMessage?: any;
}

const BunkeringFileUpload = ({
    ReportID,
    VesselID,
    setModalState,
    modalState = false,
    toggleDynamicTabs,
    previousTabIndex,
    setErrorMessage
}: BunkeringType) => {
    /** State variables */
    const [count, setCount] = useState(0);
    const [formKey, setFormKey] = useState(0);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popOverId, setPopOverId] = useState(0);
    const [BDNFile, setBDNFile] = useState<Array<any>>([{}]);
    const [BDNFileRenderCount, setBDNFileRenderCount] = useState(0);
    const [deleteBunkeringObject, setDeleteBunkeringObject] = useState<any>();
    const [deleteUploadedFile, setDeleteUploadedFile] = useState<boolean>(false);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [batchDeleteBool, setBatchDeleteBool] = useState<boolean>(false);
    const [batchDeleteId, setBatchDeleteId] = useState<number>(0);
    const [BDNObject, setBDNObject] = useState<any>({});
    const DeleteFileObj: any = {
        bdn_file: null,
        file_path: null,
    }
    useDocumentTitle("BDN File Upload - ecoSAIL");
    /** State variables end */

    /** useQueries */
    /**Bunkering object used for edit */
    const { data: BunkeringObject, isLoading: BunkeringObjectLoading, isError: BunkeringObjectError } = useQuery(
        [queryKeyes.vessel.BunkeringObject.key, VesselID, ReportID],
        async () => {
            return await loadBunkeringObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /**Bunkering object used for edit */
    const { data: BDNFileObj, isLoading: BDNFilesLoading, isError: BDNFilesError } = useQuery(
        [queryKeyes.vessel.BDNfiles.key, VesselID, ReportID],
        async () => {
            return await loadBDNFiles(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /**convert "[bunkering]" into array safely  */
    const safeParseJSON = (value: any): any[] => {
        try {
            const parsed = JSON.parse(value);
            return Array.isArray(parsed) ? parsed : [];
        } catch {
            return [];
        }
    };

    /** Assign values to formik's initial object */
    const getInitialValues = (): any[] => {
        let BDNArray: any[] = [];
        if (BDNFileObj && BDNFileObj.length > 0) {
            BDNArray = BDNFileObj.map((obj: any) => ({
                ...obj,
                bunkering: obj?.bunkering && obj.bunkering !== "NaN"
                    ? safeParseJSON(obj.bunkering)
                    : []
            }));
        } else {
            BDNArray.push({
                bdn_file: null,
                status: BDNConstant.PENDING,
                file_name: "",
                bunkering: [],
                vessel_reporting_information: ReportID
            });
        }
        return BDNArray;
    };


    // Use useEffect to set state after initial render
    useEffect(() => {
        if (BDNFileRenderCount < 1 && BDNFileObj && BDNFileObj.length > 0) {
            let updatedBDNFile: any[] = []
            BDNFileObj.forEach(() => {
                updatedBDNFile.push({})
            })
            setBDNFileRenderCount(BDNFileRenderCount + 1)
            setBDNFile(updatedBDNFile);
            setCount(count + 1);
            setRefreshKey(refreshKey + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BDNFileObj]);  // Empty dependency array to only run once when the component is mounted

    /** BDN Upload Formik object */
    const BDNFileUploadFromik = {
        initialValues: {
            bunkering: getInitialValues(),
        },
        validationSchema: Yup.object().shape({
            bunkering: Yup.array().of(
                Yup.object().shape({
                    bunkering: Yup.array()
                        .when({
                            is: () => BunkeringObject && BunkeringObject?.length > 0,
                            then: (schema) => schema.min(1, commonValidationMessages.required).required(commonValidationMessages.required),
                            otherwise: (schema) => schema.nullable(),
                        })
                        .default([])
                })
            ),
        }),
    }

    /** Post submit actions based on response */
    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
        } else if (response.status === 204) {
            successToast("Data deleted successfully!");
        }
        setFormKey(formKey + 1);
    }

    /** POST request for Bunkering */
    const BunkeringFileUploadSubmit = async (values: any, actions: any) => {
        if (env?.form_validation === false && modalState === false) {
            setErrorMessage(null)
            toggleDynamicTabs(previousTabIndex + 1);
        }
        const responseArray: any = [];
        Array.isArray(values?.bunkering) && values?.bunkering?.forEach(async (bunker: any, index: number) => {
            if (bunker.id) {
                if (bunker?.bunkering?.length === 0 || bunker?.bunkering === null) {
                    responseArray.push(apiGlobal.delete(`/bdn_file_upload/${bunker.id}/`, deleteBunkeringObject));
                }
                const formData = new FormData();
                Object.keys(bunker).forEach(key => {
                    if (bunker[key] === null || undefined) return;
                    formData.append(key, bunker[key]);
                });
                if (BDNFile && Array.isArray(BDNFile) && BDNFile[index]?.file) {
                    formData.set('bdn_file', BDNFile[index].file)
                }
                responseArray.push(apiMedia.put(`/bdn_file_upload/${bunker.id}/`, formData).then(res => {
                    if (res.status === 200) {
                        BDNFile[index] = {}
                    }
                }));
            } else {
                if (bunker.bunkering?.length > 0) {
                    const formData = new FormData();
                    Object.keys(bunker).forEach(key => {
                        if (values.bunkering[key] === null || undefined) return;
                        formData.append(key, bunker[key]);
                    });
                    if (BDNFile && Array.isArray(BDNFile) && BDNFile[index]?.file) {
                        formData.set('bdn_file', BDNFile[index].file)
                    }
                    responseArray.push(apiMedia.post(`/bdn_file_upload/`, formData).then(res => {
                        if (res.status === 201) {
                            BDNFile[index] = {}
                        }
                    })
                    )
                }
            }
        })
        await handleServerResponse(responseArray).then(async (res: any) => {
            if (res === true) {
                await queryClient.invalidateQueries(queryKeyes.vessel.BDNfiles.key);
                setDeleteBunkeringObject(null);
                setRefreshKey(refreshKey + 1);
                if (modalState) {
                    setModalState(!modalState);
                    await queryClient.invalidateQueries(queryKeyes.pagination.BDNPendingFilesPage.key);
                }
                if (env?.form_validation === true && modalState === false) {
                    setErrorMessage(null);
                    toggleDynamicTabs(previousTabIndex + 1);
                }
            } else {
                if (modalState === false) {
                    setErrorMessage(res)
                }
            }
        })
        actions.setSubmitting(false);
    }

    /**
     * delete BDN file object
     * if BDN object is saved then send DELETE request to server
     * else delete from UI only
     * @param index current index
     */
    const deleteFileUploadItem = (lastBunkerDelete: boolean, index: number) => {
        if (deleteBunkeringObject && deleteBunkeringObject.id) {
            if (!lastBunkerDelete) {
                apiGlobal.delete(`/bdn_file_upload/${deleteBunkeringObject.id}/`, deleteBunkeringObject).then(async res => {
                    await queryClient.invalidateQueries(queryKeyes.vessel.BDNfiles.key);
                    handleResponse(res);
                    BDNFile[index] = {}
                    setDeleteBunkeringObject(null)
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            } else {
                let updatedObj: any = {
                    bunkering: null,
                    bdn_file: null,
                }
                apiGlobal.patch(`/bdn_file_upload/${deleteBunkeringObject.id}/`, updatedObj).then(async res => {
                    await queryClient.invalidateQueries(queryKeyes.vessel.BDNfiles.key);
                    handleResponse(res);
                    BDNFile[index] = {}
                    setDeleteBunkeringObject(null)
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
        } else {
            BDNFile[index] = {}
        }
    };

    useEffect(() => {
        setFormKey(formKey + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BunkeringObject])


    const BDNFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            bunkering: getInitialValues(),
        },
        validationSchema: env?.form_validation === true ? BDNFileUploadFromik.validationSchema : null,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true);
            BunkeringFileUploadSubmit(values, actions);
        },
    });

    /** Delete batch */
    const deleteSelectedBatch = async () => {
        BDNObject.bunkering = BDNObject?.bunkering?.filter((item: any) => item !== batchDeleteId);
        let responseArray: any[] = [];
        if (BDNObject.bunkering.length > 0) {
            responseArray.push(apiGlobal.put(`/bdn_file_upload/${BDNObject?.id}/`, BDNObject));
            await handleServerResponse(responseArray).then(async (res) => {
                if (res === true) {
                    await BDNFormik?.setFieldValue(`bunkering.${popOverId - 1}.bunkering`,
                        BDNFormik?.values?.bunkering[popOverId - 1]?.bunkering?.filter((item: any) => item !== batchDeleteId)
                    );
                    setCount(count + 1);
                    await queryClient.invalidateQueries(queryKeyes.vessel.BDNfiles.key);
                    setBatchDeleteBool(false);
                    setPopOverId(0);
                    setBDNObject({});
                }
            })
        } else {
            await BDNFormik?.setFieldValue(`bunkering.${popOverId - 1}.bunkering`,
                BDNFormik?.values?.bunkering[popOverId - 1]?.bunkering?.filter((item: any) => item !== batchDeleteId)
            );
            setCount(count + 1);
            setBatchDeleteBool(false);
            setPopOverId(0);
            setBDNObject({});
            await BDNFormik?.setFieldValue(`bunkering.${popOverId - 1}.bunkering`,
                BDNFormik?.values?.bunkering[popOverId - 1]?.bunkering?.filter((item: any) => item !== batchDeleteId)
            );
        }
    }

    return (
        <React.Fragment>
            {(BunkeringObjectLoading || BDNFilesLoading) && <Loading message='Loading required data!' />}
            {(BunkeringObjectError || BDNFilesError) && <ErrorComponent message='Error loading component' />}
            {BDNFilesError && getInitialValues()}
            {BDNFilesError && <ErrorComponent message='Error loading component' />}
            {(!BunkeringObjectLoading && !BunkeringObjectError && !BDNFilesLoading && !BDNFilesError) &&
                <Card className='p-0 mb-0 border-0'>
                    <CardHeader className="p-2">
                        <div className="text-center">
                            <Row>
                                <Col>
                                    <h4 className="page_title pos-start mb-0">BDN(s) Upload</h4>
                                    <p className="card-title-desc pos-start">
                                        All readings since last report
                                    </p>
                                </Col>
                                <Col>
                                    <ReportDetailsHeader />
                                </Col>
                            </Row>
                        </div>
                    </CardHeader>
                    <CardBody className='px-2'>
                        {!(BunkeringObjectLoading || BDNFilesLoading) && !(BunkeringObjectError || BDNFilesError) &&
                            <form autoComplete="off" onSubmit={BDNFormik.handleSubmit} noValidate key={refreshKey}>
                                <React.Fragment>
                                    <h5>Select bunkers for common BDN file upload</h5>
                                    {Array.isArray(BDNFormik?.values?.bunkering)
                                        && BDNFormik?.values?.bunkering
                                        && BDNFormik?.values?.bunkering?.map((bunker: any, index: number) => {
                                            const selectedBunkeringIds = BDNFormik?.values.bunkering.flatMap((obj: any) => obj.bunkering) || [];
                                            return (
                                                <React.Fragment key={index}>
                                                    <Row>
                                                        <Col sm={5}>
                                                            <strong className='mb-0 me-2'>Select bunkers</strong>
                                                            <div id={`bunkering_${index}_bunkering`}>
                                                                <Select
                                                                    key={`${count}-${index}`}
                                                                    name={`bunkering.${index}.bunkering`}
                                                                    inputId={`bunkering_${index}_bunkering`}
                                                                    className="label-w-20"
                                                                    options={BunkeringObject.filter(
                                                                        (item: any) => !BDNFormik.values.bunkering
                                                                            .flatMap((b: any) => b.bunkering) // Extract all selected IDs
                                                                            .includes(item.id) // Exclude them from options
                                                                    )}
                                                                    getOptionLabel={(e: any) => e.bunkering_supply_name}
                                                                    getOptionValue={(e: any) => e.id}
                                                                    styles={customStyle}
                                                                    isMulti={true}
                                                                    isClearable={false}
                                                                    onBlur={() => BDNFormik.setFieldTouched(`bunkering.${index}.bunkering`, true)}
                                                                    onChange={(e: any, actionMeta: any) => {
                                                                        if (actionMeta.action === "remove-value" && BDNFormik?.values?.bunkering[index]?.id) {
                                                                            setBatchDeleteBool(true);
                                                                            setBatchDeleteId(actionMeta?.removedValue?.id);
                                                                            setPopOverId(index + 1);
                                                                            setBDNObject(BDNFormik?.values?.bunkering[index]);
                                                                            setCount(count + 1);
                                                                        }
                                                                        if (
                                                                            (e?.length === 0 && BDNFile[index]?.file) ||
                                                                            (e?.length === 0 && BDNFormik?.values?.bunkering[index]?.bdn_file !== null)
                                                                        ) {
                                                                            setDeleteUploadedFile(true);
                                                                            setDeleteBunkeringObject(bunker)
                                                                            setPopOverId(index + 1);
                                                                            setCount(count + 1);
                                                                        }
                                                                        if ((e?.length > 0 ||
                                                                            (e?.length === 0 && (!BDNFile[index]?.file) && (BDNFormik?.values?.bunkering[index]?.bdn_file === null))) &&
                                                                            (actionMeta.action === "select-option" || (actionMeta.action === "remove-value" && !BDNFormik?.values?.bunkering[index]?.id))) {
                                                                            BDNFormik?.setFieldValue(`bunkering.${index}.bunkering`, e.map((bunker: any) => { return bunker?.id }));
                                                                            setCount(count + 1);
                                                                        }
                                                                    }}
                                                                    value={
                                                                        BDNFormik?.values?.bunkering[index]?.bunkering?.length > 0 &&
                                                                        BDNFormik?.values?.bunkering[index]?.bunkering?.map((id: any) => {
                                                                            const matchedItem = BunkeringObject.find((item: any) => item.id === id);
                                                                            return matchedItem ? matchedItem : null;
                                                                        }).filter((item: any) => item !== null)
                                                                    }
                                                                />
                                                                {BDNFormik.errors?.bunkering
                                                                    && BDNFormik?.touched?.bunkering && BDNFormik?.touched?.bunkering[index]?.bunkering &&
                                                                    BDNFormik.errors?.bunkering[index]?.bunkering
                                                                    && env?.form_validation === true && document.getElementById(`bunkering_${index}_bunkering`) !== null &&
                                                                    <ErrorTooltip
                                                                        target={`bunkering_${index}_bunkering`}
                                                                        message={BDNFormik.errors?.bunkering[index]?.bunkering}
                                                                        open={(BDNFormik.errors?.bunkering && BDNFormik.errors?.bunkering[index]?.bunkering) ? true : false}
                                                                    />
                                                                }
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            {index !== undefined &&
                                                                <div className='m-0 p-0'>
                                                                    <ReportingFileUpload
                                                                        key={index}
                                                                        setFile={setBDNFile}
                                                                        file={BDNFile}
                                                                        fileUploadStatus={BDNFileObj[index]?.status === FileStatus.UPLOADED}
                                                                        fileURL={BDNFileObj[index]?.file_path}
                                                                        deleteURL={`/bdn_file_upload/`}
                                                                        invalidateQuery={queryKeyes.vessel.BDNfiles.key}
                                                                        deleteID={BDNFileObj[index]?.id}
                                                                        refreshKey={formKey}
                                                                        setRefreshKey={setRefreshKey}
                                                                        title={''}
                                                                        DeleteFileObj={DeleteFileObj}
                                                                        sm={4}
                                                                        index={index}
                                                                        isFileUploadDisable={bunker?.bunkering?.length === 0 || bunker.bunkering === null}
                                                                    />
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col sm={1} className='d-flex'>
                                                            {BDNFormik?.values?.bunkering?.length === 1 ? null : (
                                                                <React.Fragment>
                                                                    <button
                                                                        type="button"
                                                                        className="btn justify_right"
                                                                        id={`delete-button-${index + 1}`}
                                                                    >
                                                                        <i
                                                                            className="dripicons-trash icon_s18"
                                                                            onClick={() => {
                                                                                setPopoverOpen(true);
                                                                                setPopOverId(index + 1);
                                                                                setDeleteBunkeringObject(bunker)
                                                                            }}
                                                                            onBlur={(e: any) => { BDNFormik?.handleBlur(e); }}
                                                                        />
                                                                    </button>
                                                                </React.Fragment>
                                                            )}

                                                        </Col>
                                                    </Row>
                                                    {
                                                        index === (BDNFormik?.values.bunkering.length - 1) && BunkeringObject?.length > 1 &&
                                                        BDNFormik?.values.bunkering.length < BunkeringObject?.length && selectedBunkeringIds.length < BunkeringObject?.length &&
                                                        <Label
                                                            className="link_color_blue pointer"
                                                            style={{ position: 'relative' }}
                                                            onClick={() => {
                                                                let arr = [...BDNFormik?.values?.bunkering];
                                                                arr.push({
                                                                    bdn_file: null,
                                                                    status: BDNConstant.PENDING,
                                                                    file_name: "",
                                                                    bunkering: [],
                                                                    vessel_reporting_information: ReportID
                                                                });
                                                                BDNFormik?.setFieldValue("bunkering", arr)
                                                                const addEmptyBDNObject = [...BDNFile, {}]
                                                                setBDNFile(addEmptyBDNObject)
                                                            }}
                                                        >
                                                            Add another BDN file
                                                        </Label>
                                                    }
                                                </React.Fragment>
                                            )
                                        })}
                                    {popoverOpen &&
                                        <DeletePopOver
                                            target={`delete-button-${popOverId}`}
                                            state={popoverOpen}
                                            setState={setPopoverOpen}
                                            onClick={async () => {
                                                setPopoverOpen(false);
                                                await deleteFileUploadItem(false, popOverId - 1)
                                                BDNFormik?.values?.bunkering.splice(popOverId - 1, 1);
                                                BDNFormik?.setFieldError(`bunkering.${popOverId - 1}`, null);
                                                setCount(count + 1);
                                            }}
                                        />
                                    }
                                    {deleteUploadedFile &&  (popOverId - 1 >= 0)  && (
                                        <DeletePopOver
                                            target={`bunkering_${popOverId - 1}_bunkering`}
                                            state={deleteUploadedFile}
                                            setState={setDeleteUploadedFile}
                                            onClick={async () => {
                                                // Remove the item after confirmation
                                                setDeleteUploadedFile(false);
                                                await deleteFileUploadItem(true, popOverId - 1);
                                                BDNFormik?.setFieldValue(`bunkering.${popOverId - 1}.bunkering`, null);
                                                setCount(count + 1); // Update the count to trigger re-render
                                            }}
                                            message="Would you also like to delete the attached file?"
                                            noClick={() => {
                                                // If "No" is clicked, just reset the selection
                                                BDNFormik?.setFieldValue(`bunkering.${popOverId - 1}.bunkering`, null);
                                                setCount(count + 1);
                                            }}
                                        />
                                    )}
                                    {batchDeleteBool && (popOverId - 1 >= 0) &&
                                        <DeletePopOver
                                            state={batchDeleteBool}
                                            setState={setBatchDeleteBool}
                                            target={`bunkering_${popOverId - 1}_bunkering`}
                                            onClick={async () => {
                                                if (batchDeleteId) {
                                                    await deleteSelectedBatch();
                                                }
                                            }}
                                            message={`Are you sure you want to delete this batch?`}
                                        />
                                    }
                                </React.Fragment>
                                <CardFooter className='p-2 py-3'>
                                    {modalState ?
                                        <Row className="ele_row1">
                                            <Col sm={{ size: 2, offset: 10 }}>
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className='btn_size_cstm pos-end'
                                                    disabled={BDNFormik?.isSubmitting}
                                                >
                                                    {BDNFormik?.isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row> :
                                        <Row className="ele_row1">
                                            <div className="d-flex flex-wrap gap-5">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className='btn_size_cstm pos-end'
                                                    disabled={BDNFormik?.isSubmitting}
                                                >
                                                    {BDNFormik?.isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                                    Next <i className="bx bx-chevron-right ms-1" />
                                                </Button>
                                                <Button
                                                    type="button"
                                                    color="primary"
                                                    className="btn_size_cstm"
                                                    onClick={() => {
                                                        setErrorMessage(null)
                                                        toggleDynamicTabs(previousTabIndex - 1);
                                                        BDNFormik.setErrors({});
                                                    }
                                                    }
                                                >
                                                    <i className="bx bx-chevron-left me-1"
                                                        onClick={() => setErrorMessage(null)}
                                                    /> Previous</Button>
                                            </div>
                                        </Row>
                                    }
                                </CardFooter>
                                <div className='mt-4'>
                                    <FormValuesDebug values={[BDNFormik?.values, BDNFormik?.errors, BDNFormik.touched]} />
                                </div>
                            </form>
                        }
                    </CardBody>
                </Card >
            }
        </React.Fragment >
    )
}

export default BunkeringFileUpload