import ErrorTooltip from 'Components/ErrorTooltip';
import { Field, useFormikContext } from 'formik';
import React from 'react'
import { Card, CardHeader, Row, Col, CardBody, Input } from 'reactstrap';
import env from "environment_system/env_system";

interface CargoTankParameterinterface {
    alertBool: boolean
}

const CargoTankParameter = ({ alertBool }: CargoTankParameterinterface) => {
    const { values, errors, touched, handleChange, handleBlur }:
        { values: any, errors: any, touched: any, handleChange: any, handleBlur: any } = useFormikContext<any>();
    return (
        <Card className="p-0 mb-0 rounded-0 border-end-0 border-start-0 border-bottom-0 border-top-0">
            <CardHeader className="p-2 border-0">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Cargo Tank Parameters
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <CardBody className="px-2 py-0 border-0">
                <div className="table-responsive pr-0">
                    <table className="table mb-0">
                        <thead className="table-light">
                            <tr>
                                <th className="p-2 align-middle sr-no-width">
                                    #
                                </th>
                                <th className="p-2 align-middle">Tank Name</th>
                                <th className="p-2 align-middle text-center asteric">
                                    Temperature
                                </th>
                                <th className="p-2 align-middle text-center asteric">
                                    Pressure
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {values?.vessel_reporting_lng_tank_detail?.map(
                                (tank: any, index: any) => {
                                    return (
                                        <tr key={index}>
                                            <td className="p-2 align-middle text-center">
                                                {index + 1}
                                            </td>
                                            <td className="p-2 align-middle">
                                                <p className="mb-0">
                                                    {tank?.tank_name}
                                                </p>
                                            </td>
                                            <td className="p-2 text-center align-middle">
                                                <div className="d-inline-block">
                                                    <div className="input-group">
                                                        <Field name="swell_height">
                                                            {() => (
                                                                <Input
                                                                    type="text"
                                                                    name={`vessel_reporting_lng_tank_detail.${index}.temperature`}
                                                                    id={`temperature_${index}`}
                                                                    className="form-control text-right max-width-7"
                                                                    onBlur={handleBlur}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                    }}
                                                                    defaultValue={
                                                                        values?.vessel_reporting_lng_tank_detail[
                                                                            index
                                                                        ]?.temperature
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                        <div className="input-group-text">
                                                            <sup>o</sup>C
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors?.vessel_reporting_lng_tank_detail &&
                                                    touched?.vessel_reporting_lng_tank_detail &&
                                                    touched?.vessel_reporting_lng_tank_detail[index]
                                                        ?.temperature &&
                                                    errors?.vessel_reporting_lng_tank_detail[index]
                                                        ?.temperature &&
                                                    !alertBool &&
                                                    env?.form_validation === true && (
                                                        <ErrorTooltip
                                                            target={`temperature_${index}`}
                                                            message={
                                                                errors?.vessel_reporting_lng_tank_detail[index]
                                                                    ?.temperature
                                                            }
                                                            open={
                                                                errors?.vessel_reporting_lng_tank_detail &&
                                                                    errors?.vessel_reporting_lng_tank_detail[index]
                                                                        ?.temperature
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )}
                                            </td>
                                            <td className="p-2 text-center align-middle">
                                                <div className="d-inline-block">
                                                    <div className="input-group">
                                                        <Field
                                                            name={`vessel_reporting_lng_tank_detail.${index}.pressure`}
                                                        >
                                                            {() => (
                                                                <Input
                                                                    type="text"
                                                                    name={`vessel_reporting_lng_tank_detail.${index}.pressure`}
                                                                    id={`pressure-${index}`}
                                                                    className="form-control text-right max-width-7"
                                                                    onBlur={handleBlur}
                                                                    onChange={(e: any) => {
                                                                        handleChange(e);
                                                                    }}
                                                                    defaultValue={
                                                                        values?.vessel_reporting_lng_tank_detail[
                                                                            index
                                                                        ]?.pressure
                                                                    }
                                                                />
                                                            )}
                                                        </Field>
                                                        <div className="input-group-text">
                                                            mbar
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors?.vessel_reporting_lng_tank_detail &&
                                                    touched?.vessel_reporting_lng_tank_detail &&
                                                    touched?.vessel_reporting_lng_tank_detail[index]
                                                        ?.pressure &&
                                                    errors?.vessel_reporting_lng_tank_detail[index]
                                                        ?.pressure &&
                                                    !alertBool &&
                                                    env?.form_validation === true && (
                                                        <ErrorTooltip
                                                            target={`pressure-${index}`}
                                                            message={
                                                                errors?.vessel_reporting_lng_tank_detail[index]
                                                                    ?.pressure
                                                            }
                                                            open={
                                                                errors?.vessel_reporting_lng_tank_detail &&
                                                                    errors?.vessel_reporting_lng_tank_detail[index]
                                                                        ?.pressure
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    )}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    )
}

export default CargoTankParameter