export interface VesselMachineryList {
  id: number | any;
  vessel_machinery_name: string;
  machinery_name: string;
  precedence_id: number | null;
}

export interface FuelType {
  id: number;
  fuel_type_name: string;
}

export interface LubeOil {
  id: number;
  vessel_lube_oil_name: string;
}

export interface Inputs {
  id?: string;
  name?: string | any;
  placeholder?: string;
  label?: string;
  table_columns?: boolean;
  table_columns_sequence?: number;
  className?: string;
  tooltip?: boolean;
}

export interface MachineRunningHrs {
  vessel_machinery_id: number;
  running_hours_since_last_report: number;
  avg_power: number;
}

export const Voyage_Parameter_Forms: { [x: string]: Inputs[] } = {
  voyage_parameter: [
    {
      name: "machinery_name",
      label: "Machinery name",
    },
    {
      name: "set_rpm",
      label: "Set propeller RPM",
      className: "asteric",
    },

    {
      name: "avg_rpm",
      label: "Average propeller RPM",
      className: "asteric",
    },

    {
      name: "total_revolutions",
      label: "Revolutions since last report",
      className: "asteric",
    },
    {
      name: "propeller_pitch",
      label: "Propeller pitch",
      className: "asteric",
    },
    {
      name: "slip",
      label: "Slip %",
      className: "",
    },
  ],
};

export const FO_Bunkering_Forms: { [x: string]: Inputs[] } = {
  fo_bunkering: [
    {
      name: "fuel_type_name",
      label: "Fuel name",
      table_columns: true,
      table_columns_sequence: 1,
    },
    {
      name: "quantity_bunkered",
      label: "Quantity bunkered",
      table_columns: true,
      table_columns_sequence: 2,
      className: "asteric",
    },
    {
      name: "density",
      label: "Fuel Density",
      table_columns: true,
      table_columns_sequence: 3,
      className: "asteric",
    },
    {
      name: "sulphur_percentage",
      label: "Sulphur percentage",
      table_columns: true,
      table_columns_sequence: 4,
      className: "asteric",
    },
    {
      name: "viscosity",
      label: "Viscosity",
      table_columns: true,
      table_columns_sequence: 5,
      className: "asteric",
    },
    {
      name: "bunkering_port",
      label: "Port of bunkering",
      table_columns: true,
      table_columns_sequence: 6,
      className: "asteric",
    },
    {
      name: "bunkering_date",
      label: "Date of bunkering",
      table_columns: true,
      table_columns_sequence: 7,
      className: "asteric",
    },
  ],
};

export const FO_ROB_Forms: { [x: string]: Inputs[] } = {
  fo_lo_rob: [
    {
      name: "fuel_type_name",
      label: "Fuel type",
      table_columns: true,
      table_columns_sequence: 1,
      className: "text-center",
    },
    {
      name: "fuel_rob",
      label: "Fuel ROB",
      table_columns: true,
      table_columns_sequence: 2,
      className: "asteric text-center",
    },
  ],
};

export const LO_ROB_Forms: { [x: string]: Inputs[] } = {
  fo_lo_rob: [
    {
      name: "vessel_lube_oil_name",
      label: "Lube oil",
      table_columns: true,
      table_columns_sequence: 1,
      className: "text-center",
    },
    {
      name: "vessel_lube_oil_consumption",
      label: "Lube oil consumption",
      table_columns: true,
      table_columns_sequence: 2,
      className: "asteric text-center",
    },
    {
      name: "lube_rob",
      label: "Lube oil ROB",
      table_columns: true,
      table_columns_sequence: 3,
      className: "text-center",
    },
  ],
};

export const Fuel_Consumption_Forms: { [x: string]: Inputs[] } = {
  fuel_consumption: [
    {
      name: "machinery_name",
      label: "Machinery name",
      table_columns: true,
      table_columns_sequence: 1,
      className: "text-center",
    },
  ],
};

export const LO_Bunkering_Forms: { [x: string]: Inputs[] } = {
  lo_bunkering: [
    {
      name: "vessel_lube_oil_name",
      label: "Lube oil",
      table_columns: true,
      table_columns_sequence: 1,
      className: "text-center",
    },
    {
      name: "quantity_bunkered",
      label: "Quantity bunkered",
      table_columns: true,
      table_columns_sequence: 2,
      className: "asteric text-center",
    },
    {
      name: "bunkering_date",
      label: "Date of bunkering",
      table_columns: true,
      table_columns_sequence: 4,
      className: "asteric text-center",
    },
  ],
};

export const Energy_Forms: { [x: string]: Inputs[] } = {
  energy: [
    {
      name: "machinery_name",
      label: "Machinery name",
      table_columns: true,
      table_columns_sequence: 1,
      className: ''
    },
    {
      name: "enery_developed",
      label: "Energy developed/consumed",
      table_columns: true,
      table_columns_sequence: 2,
      className: "asteric",
      tooltip: true,
    },
    {
      name: "running_hrs",
      label: "Running hours",
      table_columns: true,
      table_columns_sequence: 3,
      className: "asteric",
    },
    {
      name: "avg_power",
      label: "Average power",
      table_columns: true,
      table_columns_sequence: 4,
      className: "asteric",
    },
  ],
};

export const LO_Debunkering_Forms: { [x: string]: Inputs[] } = {
  lo_debunkering: [
    {
      name: "vessel_lube_oil_name",
      label: "Lube oil",
      table_columns: true,
      table_columns_sequence: 1,
      className: "text-center",
    },
    {
      name: "quantity_bunkered",
      label: "Quantity debunkered",
      table_columns: true,
      table_columns_sequence: 2,
      className: "asteric text-center",
    },
    {
      name: "bunkering_date",
      label: "Date of debunkering",
      table_columns: true,
      table_columns_sequence: 4,
      className: "asteric text-center",
    },
  ],
};

export const FO_Debunkering_Forms: { [x: string]: Inputs[] } = {
  fo_debunkering: [
    {
      name: "fuel_type_name",
      label: "Fuel name",
      table_columns: true,
      table_columns_sequence: 1,
      className: "text-center",
    },
    {
      name: "quantity_bunkered",
      label: "Quantity debunkered",
      table_columns: true,
      table_columns_sequence: 2,
      className: "text-center",
    },
    {
      name: "rob",
      label: "ROB",
      table_columns: true,
      table_columns_sequence: 3,
      className: "text-center",
    },
    {
      name: "bunkering_port",
      label: "Port of debunkering",
      table_columns: true,
      table_columns_sequence: 4,
      className: "text-center",
    },
    {
      name: "bunkering_date",
      label: "Date of debunkering",
      table_columns: true,
      table_columns_sequence: 5,
      className: "text-center",
    },
  ],
};

type Form =
  | "voyage_parameter"
  | "fo_bunkering"
  | "fo_lo_rob"
  | "fuel_consumption"
  | "lo_bunkering"
  | "energy"
  | "fo_debunkering"
  | "lo_debunkering";

export const getInputs = (section: Form) => {
  return {
    Voyage_Parameter_inputs: Voyage_Parameter_Forms[section],
    FO_Bunkering_inputs: FO_Bunkering_Forms[section],
    FO_ROB_inputs: FO_ROB_Forms[section],
    LO_ROB_inputs: LO_ROB_Forms[section],
    Fuel_Consumption_inputs: Fuel_Consumption_Forms[section],
    LO_Bunkering_inputs: LO_Bunkering_Forms[section],
    Energy_inputs: Energy_Forms[section],
    LO_Debunkering_inputs: LO_Debunkering_Forms[section],
    FO_Debunkering_inputs: FO_Debunkering_Forms[section],
  };
};
