import React, { useState } from 'react'
import { Button, Card, CardBody, CardTitle, Col, Collapse, Row } from 'reactstrap';

interface FAQItem {
    id: number;
    category: string; // New property for category
    question: string;
    answer: string;
}
const FAQ = () => {
    const [openFAQ, setOpenFAQ] = useState<number | null>(null);
    const [selectedCategory, setSelectedCategory] = useState<string>('General');
    const toggleFAQ = (id: number) => {
        setOpenFAQ(openFAQ === id ? null : id);
    };

    const faqs: FAQItem[] = [
        // General FAQs
        { id: 1, category: 'General', question: 'How do I reset my password?', answer: 'To reset your password, click on the "Forgot Password" link on the login page and follow the instructions sent to your email.' },
        { id: 2, category: 'General', question: 'Can I access the system on my mobile?', answer: 'Yes, our platform is fully responsive and works on all modern mobile devices and tablets.' },
        { id: 3, category: 'General', question: 'How do I update my profile information?', answer: 'Navigate to your profile settings and click "Edit Profile" to update your details.' },

        // Deck Officer FAQs
        { id: 4, category: 'Deck Officer', question: 'What are the requirements for a Deck Officer promotion?', answer: 'Promotions depend on certifications, experience, and performance evaluations.' },
        { id: 5, category: 'Deck Officer', question: 'How do I report a navigational hazard?', answer: 'All navigational hazards should be reported to the officer on watch and logged in the system.' },
        { id: 6, category: 'Deck Officer', question: 'What is the procedure for cargo loading?', answer: 'Cargo loading follows the pre-established plan approved by the Chief Officer and safety regulations.' },

        // Chief Engineer FAQs
        { id: 7, category: 'Chief Engineer', question: 'How do I request engine maintenance?', answer: 'Submit a maintenance request through the Engineering Department’s system and notify the Chief Engineer.' },
        { id: 8, category: 'Chief Engineer', question: 'What safety measures should be followed in the engine room?', answer: 'Always wear PPE, follow lockout/tagout procedures, and report any anomalies immediately.' },
        { id: 9, category: 'Chief Engineer', question: 'How do I report an engine failure?', answer: 'Report engine failures immediately to the bridge and log them in the engineering logbook.' },

        // Manager FAQs
        { id: 10, category: 'Manager', question: 'How do I approve crew leave requests?', answer: 'Go to the HR portal, review the leave requests, and approve or decline as necessary.' },
        { id: 11, category: 'Manager', question: 'How can I monitor crew performance?', answer: 'Crew performance can be tracked through regular evaluations and feedback reports in the management system.' },
        { id: 12, category: 'Manager', question: 'What is the procedure for hiring new crew members?', answer: 'Hiring involves reviewing applications, conducting interviews, and verifying certifications before final approval.' }
    ];


    // Get unique categories from FAQs
    const faqCategories = Array.from(new Set(faqs.map(faq => faq.category)));
    return (
        <React.Fragment>
            <Row>
                <Col md="4">
                    <h4 className="mb-3">FAQ Categories</h4>
                    {faqCategories.map((category: string) => (
                        <Card
                            key={category}
                            id={category}
                            className={`mb-2 ${selectedCategory === category ? 'border-primary' : ''}`}
                            onClick={() => {
                                setSelectedCategory(category);
                                setOpenFAQ(null); // Reset open FAQ when switching categories
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <CardBody>
                                <CardTitle tag="h5">{category.charAt(0).toUpperCase() + category.slice(1)}</CardTitle>
                            </CardBody>
                        </Card>
                    ))}
                </Col>
                <Col md="8">
                    <div className="faq-container">
                        {faqs.filter(faq => faq.category === selectedCategory).map(faq => (
                            <Card key={faq.id} className="mb-2 shadow-sm">
                                <CardBody>
                                    <CardTitle
                                        tag="h5"
                                        onClick={() => toggleFAQ(faq.id)}
                                        style={{ cursor: 'pointer', transition: 'background-color 0.3s' }}
                                        className="d-flex justify-content-between align-items-center p-2 rounded"
                                    >
                                        {faq.question}
                                        <Button color="link" className="p-0">
                                            {openFAQ === faq.id ? <i className='bx bx-chevron-up icon_s23'></i> : <i className='bx bx-chevron-down icon_s23'></i>}
                                        </Button>
                                    </CardTitle>
                                    <Collapse isOpen={openFAQ === faq.id}>
                                        <p className="mt-3">{faq.answer}</p>
                                    </Collapse>
                                </CardBody>
                            </Card>
                        ))}
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default FAQ