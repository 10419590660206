import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import VesselDetailsHeader from 'Components/VesselDetailsHeader';
import Layout from 'HorizontalMenu/Menu';
import React, { useEffect, useState } from 'react'
import { Navbar, Container, Card, CardHeader, Row, Col, CardBody, Label, Input, Button, CardFooter } from 'reactstrap';
import { VesselState, setVesselState } from "../Store/Generic/ReportingSlice";
import { RootState } from 'index';
import { useDispatch, useSelector } from 'react-redux';
import { calculateGeometricDistance, calculateUTCTime, calculateTimeDurationBetwnReports, checkInvalidPrimaryKey, dateTimeFormat, filterUniqueByFields, handleServerResponse, dynamicSetFieldErrors } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadAdditionalEvents, loadCurrentYear, loadLastReportVessel, loadLoadConditionOptions, loadMaxSpeedConsumptionByVessel, loadPreviousVesselReport, loadStraitCanalOptions, loadVesselReportObject, nextPossibleEvents, nextPossibleOperations } from 'VesselMaster/vesselMaster.hooks';
import { AlertColourTypeConstant, AlertTypeConstant, EventPrecedence, ManeouveringTimeLimit as ManeuveringTimeLimit, ReportingEvents, ReportingOpConstant, ReportingTypeConstant, VesselTypeConstant } from 'shared/constants';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { useFormik } from 'formik';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import * as Yup from 'yup';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import env from 'environment_system/env_system';
import ErrorTooltip from 'Components/ErrorTooltip';
import Cleave from "cleave.js/react";
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Select from 'react-select';
import { customStyle } from 'shared/CommonCSS';
import ThreeStateSwitch from 'Components/ThreeStateSwitch';
import AlertPopup from 'Alerts/AlertPopup';
import { AlertMessages } from 'Alerts/AlertMessages';
import { useDocumentTitle } from 'Components/useDocument.hooks';
import ErrorReport from 'Components/ErrorReport';

interface CreateVesselReportType {
    reportId?: number,
}

const CreateVesselReport = ({
    reportId,
}: CreateVesselReportType) => {
    /** State variables */
    const dispatch = useDispatch();
    const { VesselState, Voyages, VoyageID, VesselID, Vessels } = useSelector(
        (state: RootState) => state.Reporting
    );
    const voyage = Voyages.find((rec: any) => rec.id === VoyageID);
    const [reportingType, setReportingType] = useState("");
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [eventId, setEventId] = useState(0);
    const [count, setCount] = useState(0);
    const [refreshCheckboxes, setRefreshCheckboxes] = useState(0);
    const [alertBool, setAlertBool] = useState(false);
    const [alertColourType, setAlertColourType] = useState(null);
    const [alertType, setAlertType] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [fieldId, setFieldId] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    useDocumentTitle("Create Vessel Report - ecoSAIL");
    /** State variables end */

    /** Set vessel globally */
    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record));
    };

    /** Queries start */
    /** Vessel report object used for edit */
    const { data: VesselReportObject, isLoading: VesselReportObjectLoading, isError: VesselReportObjectError }:
        { data: any; isLoading: any; isError: any } = useQuery(
            [queryKeyes.vessel.VesselReportingObject.key, checkInvalidPrimaryKey(reportId) && reportId],
            async () => {
                return await loadVesselReportObject(checkInvalidPrimaryKey(reportId) && reportId);
            },
            { staleTime: Infinity }
        );
    /** Vessel's previous report object */
    const { data: lastRecord, isLoading: lastRecordLoading, isError: lastRecordError }:
        { data: any; isLoading: any; isError: any } = useQuery(
            reportId ? [queryKeyes.vessel.PreviousVesselReport.key, reportId, VesselID] :
                [queryKeyes.vessel.LastVesselReport.key, reportId, VesselID],
            async () => {
                if (reportId) {
                    return await loadPreviousVesselReport(reportId, VesselID);
                } else {
                    return await loadLastReportVessel(VesselID);
                }
            }
        );
    /** Load reporting event options based on previous reporting event */
    const { data: reportingEvent, isLoading: reportingEventIsLoading, isError: reportingEventIsError } = useQuery(
        [queryKeyes.vessel.nextReportingEvent, eventId],
        async () => {
            if (eventId) {
                setCount(count + 1);
                return await nextPossibleEvents(eventId);
            }
            return { id: 0, event: "No Records found" };
        },
    );
    /** Load additional reporting events based on previous reporting event */
    const { data: additionalEvents, isLoading: additionalEventsIsLoading, isError: additionalEventsIsError } = useQuery(
        [queryKeyes.vessel.CIIAdjustmentAdditionalEvents, eventId],
        async () => {
            if (eventId) {
                setCount(count + 1);
                return await loadAdditionalEvents(eventId);
            }
            return { id: 0, event: "No Records found" };
        },
        {
            staleTime: Infinity,
        }
    );
    /** Load current year object from master */
    const { data: currentYear, isLoading: currentYearLoading, isError: currentYearError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CurrentYear.key],
            async () => {
                return await loadCurrentYear();
            },
            { staleTime: Infinity }
        );
    /** Load load conditions from master */
    const { data: LoadConditionOptions, isLoading: LoadingLoadCondition, isError: ErrorLoadCondition } = useQuery(
        [queryKeyes.masters.vesselLoadingCondition.key, VesselState],
        async () => await loadLoadConditionOptions(),
        {
            enabled: true,
            staleTime: Infinity
        });
    /** Load load conditions from master */
    const { data: StraitCanals, isLoading: LoadingStraitCanal, isError: ErrorStraitCanal } = useQuery(
        [queryKeyes.masters.straitCanal.key, VesselState],
        async () => await loadStraitCanalOptions(),
        {
            enabled: true,
            staleTime: Infinity
        });
    /** Vessel report object used for edit */
    const { data: MaxSpeedConsumption, isLoading: MaxSpeedConsumptionLoading, isError: MaxSpeedConsumptionError }:
        { data: any; isLoading: any; isError: any } = useQuery(
            [queryKeyes.vessel.MaxSpeedConsumptionByVessel.key, VesselID],
            async () => await loadMaxSpeedConsumptionByVessel(VesselID),
            {
                enabled: true,
                staleTime: Infinity
            });
    /** Queries end */

    /** useEffect start */
    useEffect(() => {
        if (lastRecord) {
            if (VesselReportObject && VesselReportObject?.id > 0) {
                setEventId(VesselReportObject?.previous_event_other_than_noon);
            } else if (lastRecord?.event_precedence_id === EventPrecedence.NOON ||
                lastRecord.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT) {
                setEventId(lastRecord?.previous_event_other_than_noon);
            } else {
                setEventId(lastRecord?.reporting_event);
            }
        }
        setCount(count + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselReportObject, lastRecord]);

    /** useEffect end */

    /** Assign initial values to the formik object */
    const getInitialValues = () => {
        if (VesselReportObject !== undefined && VesselReportObject.id > 0) {
            VesselReportObject.time_difference_prefix = VesselReportObject?.reporting_time_difference?.toString()?.substring(0, 1);
            return VesselReportObject;
        } else {
            return {
                reporting_time_utc: "",
                time_duration_since_last_report: "",
                time_difference_prefix: "-",
                reporting_time_difference: null,
                reporting_type: "",
                local_time: "",
                reporting_event: null,
                year: currentYear && currentYear?.id,
                reporting_operation_mode: 0,
                reporting_operation_mode_name: "",
                is_drifting_inolved: false,
                position_lat_deg: 0.0,
                position_lat_min: 0.0,
                position_lat_sec: 0.0,
                position_long_deg: 0.0,
                position_long_min: 0.0,
                position_long_sec: 0.0,
                lat_direction: "south",
                long_direction: "west",
                strait_canal_transit: null,
                no_of_hours_retarded: null,
                retarded_advanced: "retarded",
                remaining_distance_to_the_next_port: 0,
                distance_travelled: null,
                load_condition: null,
                is_noon_report: false,
                is_cii_adjustment_report: false,
                only_noon_or_combine_with_other_event: "none",
                cii_combine_with_other_event: false,
                cii_noon_combine_with_other_event: false,
                additional_events: false,
                is_strait_canal_transit: false,
                previous_event_other_than_noon: 0,
                previous_operation: lastRecord?.reporting_operation_mode,
                is_this_cargo_loading_discharging: null,
                is_sts_considered: false,
                vessel: VesselID,
                voyage_information: VoyageID,
            };
        }
    };

    /** Formik object of CreateVesselReport */
    const CreateVesselReportFormik = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(),
        validationSchema: Yup.object().shape({
            local_time: Yup.date().min(new Date(dateTimeFormat(lastRecord?.local_time)), `${commonValidationMessages.minDateLastRecord}`)
                .required(commonValidationMessages.required),
            time_duration_since_last_report: Yup.string(),
            reporting_time_difference: Yup.string().required(
                commonValidationMessages.required
            ),
            reporting_event: Yup.number().required(commonValidationMessages.required),
            reporting_operation_mode: Yup.number().required(
                commonValidationMessages.required
            ),
            position_lat_deg: Yup.number()
                .min(0, commonValidationMessages.minLatDegree)
                .max(360, commonValidationMessages.maxLatDegree)
                .required(commonValidationMessages.required),
            position_lat_min: Yup.number()
                .min(0, commonValidationMessages.minMinutes)
                .max(60, commonValidationMessages.maxMinutes)
                .required(commonValidationMessages.required),
            position_lat_sec: Yup.number()
                .min(0, commonValidationMessages.minSecond)
                .max(60, commonValidationMessages.maxSecond)
                .required(commonValidationMessages.required),
            position_long_deg: Yup.number()
                .min(0, commonValidationMessages.minLogDegree)
                .max(360, commonValidationMessages.maxLogDegree)
                .required(commonValidationMessages.required),
            position_long_min: Yup.number()
                .min(0, commonValidationMessages.minMinutes)
                .max(60, commonValidationMessages.maxMinutes)
                .required(commonValidationMessages.required),
            position_long_sec: Yup.number()
                .min(0, commonValidationMessages.minSecond)
                .max(60, commonValidationMessages.maxSecond)
                .required(commonValidationMessages.required),
            lat_direction: Yup.string(),
            long_direction: Yup.string(),
            strait_canal_transit: Yup.number().nullable(),
            no_of_hours_retarded: Yup.string().required(commonValidationMessages.required),
            remaining_distance_to_the_next_port: Yup.number(),
            distance_travelled: Yup.string().matches(
                /^\d{0,3}(?:\.\d{1,4})?$/,
                `${commonValidationMessages.before3after4}`
            ).when("reporting_event", {
                is: (value: number) => (value === ReportingEvents.DEPARTURE_FROM_BERTH || value === ReportingEvents.HEAVE_ANCHOR),
                then: (schema: any) => schema.nullable(),
                otherwise: (schema: any) => schema.required(commonValidationMessages.required),
            }),
            load_condition: Yup.number().required(commonValidationMessages.required),
            is_noon_report: Yup.boolean(),
            is_this_cargo_loading_discharging: Yup.string().when("$reporting_event", {
                is: (value: number) => (value !== ReportingEvents.NOON_FWE_IP &&
                    value !== ReportingEvents.DEPARTURE_FROM_BERTH),
                then: (schema) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }),
            only_noon_or_combine_with_other_event: Yup.string(),
            is_strait_canal_transit: Yup.boolean(),
            vessel: Yup.number(),
            voyage_information: Yup.number(),
        }),
        onSubmit: async (values, actions) => {
            actions.setSubmitting(true);
            const responseArray: any = [];
            values.reporting_time_utc = calculateUTCTime(
                CreateVesselReportFormik?.values?.local_time,
                CreateVesselReportFormik?.values?.reporting_time_difference,
                CreateVesselReportFormik?.values?.id
            )
            if (VesselReportObject && VesselReportObject.id > 0 && reportId) {
                responseArray.push(apiGlobal.put(`/vessel_reporting_information/${reportId}/`, values));
            } else {
                responseArray.push(apiGlobal.post(`/vessel_reporting_information/`, values));
            }
            await handleServerResponse(responseArray).then(async (res) => {
                if (res === true) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.VesselReportingObject.key);
                    await queryClient.invalidateQueries(queryKeyes.pagination.completeReportPage.key);
                    handleVesselState("VESSEL_REPORTING");
                } else {
                    setErrorMessage(res);
                    dynamicSetFieldErrors(res, actions, false);
                }
            });
            actions.setSubmitting(false);
        }
    });
    useEffect(() => {
        CreateVesselReportFormik.initialValues = getInitialValues();
        CreateVesselReportFormik.values = getInitialValues();
        CreateVesselReportFormik.setFieldValue("previous_operation", lastRecord?.reporting_operation_mode);
        setRefreshKey((prevKey) => prevKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselReportObject, lastRecord]);
    useEffect(() => {
        calculateSteamingTime();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CreateVesselReportFormik?.values?.local_time, CreateVesselReportFormik?.values?.reporting_time_difference]);

    const calculateSteamingTime = () => {
        const dt1 = new Date(lastRecord?.reporting_time_utc);
        const dt2 = new Date(`${calculateUTCTime(
            CreateVesselReportFormik?.values?.local_time,
            CreateVesselReportFormik?.values?.reporting_time_difference,
            CreateVesselReportFormik?.values?.id
        )?.toString()?.substring(0, 10)}T${calculateUTCTime(
            CreateVesselReportFormik?.values?.local_time,
            CreateVesselReportFormik?.values?.reporting_time_difference,
            CreateVesselReportFormik?.values?.id
        )?.toString()?.substring(11, 16)
            }:00Z`)
        let timeDifference = Math.floor(
            (Date.UTC(
                dt2.getFullYear(),
                dt2.getUTCMonth(),
                dt2.getUTCDate(),
                dt2.getUTCHours(),
                dt2.getUTCMinutes(),
                dt2.getUTCSeconds()
            ) -
                Date.UTC(
                    dt1.getFullYear(),
                    dt1.getUTCMonth(),
                    dt1.getUTCDate(),
                    dt1.getUTCHours(),
                    dt1.getUTCMinutes(),
                    dt1.getUTCSeconds()
                )) /
            (1000 * 60)
        );
        CreateVesselReportFormik?.setFieldValue("time_duration_since_last_report", timeDifference);
        return timeDifference;
    }

    /** Calculate steaming time */
    const handleReportingDateTimeChanges = (e: any, generateAlert: boolean) => {
        CreateVesselReportFormik?.setFieldValue("reporting_time_utc",
            calculateUTCTime(
                CreateVesselReportFormik?.values?.local_time,
                CreateVesselReportFormik?.values?.reporting_time_difference,
                CreateVesselReportFormik?.values?.id
            )
        );
        CreateVesselReportFormik?.setFieldValue("local_time", e.target.value);
        if (env?.alerts && generateAlert && calculateSteamingTime() > 1440) {
            setAlertMessage(AlertMessages.VesselReporting.reporting_time_utc);
            setAlertColourType(AlertColourTypeConstant.WARNING);
            setAlertType(AlertTypeConstant.WITH_REASON);
            setFieldId(e.target.id);
            setAlertBool(true);
            setFieldId('local_time');
        }
    };

    /** Verify if the vessel has advanced or retarded */
    const verifyAdvancedRetarded = () => {
        if (parseFloat(lastRecord?.reporting_time_difference) < parseFloat(CreateVesselReportFormik?.values?.reporting_time_difference)) {
            return "advanced";
        } else {
            return "retarded";
        }
    }

    /** Calculate number of hours retarded/advanced */
    const handleTimeDifferenceTextboxChanges = (e: any, isTimeDifference: boolean) => {
        CreateVesselReportFormik?.setFieldValue("reporting_time_utc",
            calculateUTCTime(
                CreateVesselReportFormik?.values?.local_time,
                CreateVesselReportFormik?.values?.reporting_time_difference,
                CreateVesselReportFormik?.values?.id
            )
        );
        if (CreateVesselReportFormik?.values.time_difference_prefix === undefined) {
            CreateVesselReportFormik?.setFieldValue("time_difference_prefix", "-");
        }
        if (isTimeDifference) {
            CreateVesselReportFormik?.setFieldValue(
                "reporting_time_difference",
                `${CreateVesselReportFormik?.values.time_difference_prefix}${e.target.value}`
            );
        }
        /** Calculation of number of hours retarded/advanced */
        let lastReportingTimeDifference: any;
        let currentReportingTimeDifference: any;
        if (
            lastRecord.reporting_time_difference === "-00:00" ||
            lastRecord.reporting_time_difference === "+00.00"
        ) {
            lastReportingTimeDifference = 0;
        } else {
            if (lastRecord) {
                lastReportingTimeDifference =
                    parseInt(
                        lastRecord?.reporting_time_difference?.toString().substring(1, 3)
                    ) +
                    parseInt(
                        lastRecord?.reporting_time_difference?.toString().substring(4, 6)
                    ) /
                    60;
            }
        }
        if (isTimeDifference) {
            currentReportingTimeDifference =
                parseInt(e.target.value.toString().substring(0, 2)) +
                parseInt(e.target.value.toString().substring(3, 5)) / 60;
        } else {
            currentReportingTimeDifference =
                parseInt(CreateVesselReportFormik?.values?.reporting_time_difference?.substring(1, 3)) +
                parseInt(CreateVesselReportFormik?.values?.reporting_time_difference?.substring(4, 6)) / 60;
        }
        let timeDifference =
            parseFloat(
                `${isTimeDifference ? CreateVesselReportFormik?.values?.time_difference_prefix : (e.target.checked === true ? "+" : "-")}${currentReportingTimeDifference}`
            ) -
            parseFloat(
                `${lastRecord?.reporting_time_difference?.toString().substring(0, 1)}${lastReportingTimeDifference}`
            );
        if (!Number.isNaN(timeDifference)) {
            if (timeDifference > 0) {
                CreateVesselReportFormik?.setFieldValue(
                    "no_of_hours_retarded", timeDifference.toFixed(2)
                );
            } else if (timeDifference < 0) {
                CreateVesselReportFormik?.setFieldValue("no_of_hours_retarded", timeDifference.toFixed(2));
            } else {
                CreateVesselReportFormik?.setFieldValue("no_of_hours_retarded", parseInt(`+${0}`));
            }
            CreateVesselReportFormik?.setFieldValue("retarded_advanced", verifyAdvancedRetarded());
        }
    };

    /** Handle time difference(retarding/advancing) */
    const handleTimePrefixSwitchChanges = (e: any) => {
        let sign = e.target.checked === true ? "+" : "-";
        CreateVesselReportFormik?.setFieldValue("reporting_time_utc",
            calculateUTCTime(
                CreateVesselReportFormik?.values?.local_time,
                `${sign}${CreateVesselReportFormik?.values?.reporting_time_difference?.toString()?.substring(1, 6)}`,
                CreateVesselReportFormik?.values?.id
            )
        );
        CreateVesselReportFormik?.setFieldValue("time_difference_prefix", sign);
        if (CreateVesselReportFormik?.values.reporting_time_difference !== null) {
            CreateVesselReportFormik?.setFieldValue(
                "reporting_time_difference",
                `${sign}${CreateVesselReportFormik?.values?.reporting_time_difference?.toString()?.substring(1, 6)}`
            );
        }
    };

    /** Set current operation & previous operation  */
    const loadNextPossibleOperations = async (currEvent: any) => {
        try {
            if (lastRecord.event_precedence_id === EventPrecedence.NOON ||
                lastRecord.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT) {
                CreateVesselReportFormik?.setFieldValue("reporting_operation_mode", lastRecord.reporting_operation_mode);
                CreateVesselReportFormik?.setFieldValue("reporting_operation_mode_name", lastRecord.reporting_operation_mode_name);
                CreateVesselReportFormik?.setFieldValue("previous_operation", lastRecord.reporting_operation_mode);
            } else {
                const nextPossibleOperation = await nextPossibleOperations(currEvent, lastRecord.reporting_event);
                CreateVesselReportFormik?.setFieldValue("reporting_operation_mode", nextPossibleOperation[0]?.possible_operations_slr);
                CreateVesselReportFormik?.setFieldValue("reporting_operation_mode_name", nextPossibleOperation[0]?.possible_operations_slr_name);
                if (
                    CreateVesselReportFormik?.values.only_noon_or_combine_with_other_event === "combine_with_other_report" ||
                    CreateVesselReportFormik?.values.is_noon_report === false
                ) {
                    CreateVesselReportFormik?.setFieldValue("previous_operation", nextPossibleOperation[0].possible_operations_slr);
                } else {
                    CreateVesselReportFormik?.setFieldValue("previous_operation", lastRecord.reporting_operation_mode);
                }
                return nextPossibleOperation[0];
            }
        } catch (err) {
            console.error(err);
            return [];
        }
    };

    /** Changes based on whether the report is noon, not noon or combined */
    const handleIsNoonReportSwitchChanges = (e: any) => {
        CreateVesselReportFormik?.setFieldValue("is_noon_report", e.target.checked);
        CreateVesselReportFormik?.setFieldValue(
            "only_noon_or_combine_with_other_event",
            e.target.checked === true && CreateVesselReportFormik?.values?.is_cii_adjustment_report === false
                ? "only_noon_report" :
                e.target.checked === true && CreateVesselReportFormik?.values?.is_cii_adjustment_report === true ?
                    "combine_with_other_report" : "none"
        );
        if (e.target.checked === false) {
            CreateVesselReportFormik?.setFieldValue("cii_noon_combine_with_other_event", false);
        } else {
            CreateVesselReportFormik?.setFieldValue("cii_combine_with_other_event", false);
        }
        if ((e.target.checked === true && CreateVesselReportFormik?.values?.is_cii_adjustment_report === false) ||
            (e.target.checked === true && CreateVesselReportFormik?.values?.is_cii_adjustment_report === true &&
                CreateVesselReportFormik?.values?.cii_noon_combine_with_other_event === false
            )) {
            CreateVesselReportFormik?.setFieldValue(
                "reporting_event",
                reportingEvent[0]?.next_possible_events?.next_possible_events
                    .filter((item: any) => item?.event_precedence_id === EventPrecedence.NOON)[0]?.id
            );
            loadNextPossibleOperations(reportingEvent[0]?.next_possible_events?.next_possible_events
                .filter((item: any) => item?.event_precedence_id === EventPrecedence.NOON)[0]?.id);
        } else if (
            (e.target.checked === false &&
                CreateVesselReportFormik?.values?.is_cii_adjustment_report === true &&
                CreateVesselReportFormik?.values?.cii_combine_with_other_event === false)
        ) {
            CreateVesselReportFormik?.setFieldValue(
                "reporting_event",
                reportingEvent[0]?.next_possible_events?.next_possible_events
                    .filter((item: any) => item?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT)[0]?.id
            );
            loadNextPossibleOperations(reportingEvent[0]?.next_possible_events?.next_possible_events
                .filter((item: any) => item?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT)[0]?.id);
        } else if (e.target.checked === false && CreateVesselReportFormik?.values?.is_cii_adjustment_report === false) {
            CreateVesselReportFormik?.setFieldValue("reporting_event", null);
            CreateVesselReportFormik?.setFieldValue("reporting_event_name", null);
        }
        setReportingType(
            reportingEvent[0]?.next_possible_events?.next_possible_events
                .filter((item: any) => item?.event_precedence_id === EventPrecedence.NOON)[0]?.reporting_type_name
        );
        if (
            lastRecord?.event_precedence_id === EventPrecedence.NOON ||
            lastRecord?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT
        ) {
            CreateVesselReportFormik?.setFieldValue("previous_event_other_than_noon", lastRecord?.previous_event_other_than_noon);
        } else {
            CreateVesselReportFormik?.setFieldValue("previous_event_other_than_noon", lastRecord?.reporting_event);
        }
        setRefreshCheckboxes(refreshCheckboxes + 1);
    };

    /** Changes based on whether this report is the beginning of CII adjustment event  */
    const handleIsCIIReportSwitchChanges = (e: any) => {
        CreateVesselReportFormik?.setFieldValue("is_cii_adjustment_report", e.target.checked);
        if (e.target.checked === false) {
            CreateVesselReportFormik?.setFieldValue("cii_combine_with_other_event", false);
            CreateVesselReportFormik?.setFieldValue("cii_noon_combine_with_other_event", false);
        }
        if (e.target.checked === true && CreateVesselReportFormik?.values.is_noon_report === true) {
            CreateVesselReportFormik?.setFieldValue("only_noon_or_combine_with_other_event", "combine_with_other_report");
            CreateVesselReportFormik?.setFieldValue(
                "reporting_event",
                reportingEvent[0]?.next_possible_events?.next_possible_events
                    .filter((item: any) => item?.event_precedence_id === EventPrecedence.NOON)[0]?.id
            );
        } else if (e.target.checked === true &&
            CreateVesselReportFormik?.values.cii_combine_with_other_event === false &&
            CreateVesselReportFormik?.values?.is_noon_report === false) {
            CreateVesselReportFormik?.setFieldValue(
                "reporting_event",
                reportingEvent[0]?.next_possible_events?.next_possible_events
                    .filter((item: any) => item?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT)[0]?.id
            );
            CreateVesselReportFormik?.setFieldValue("reporting_operation_mode", lastRecord.reporting_operation_mode);
            CreateVesselReportFormik?.setFieldValue("reporting_operation_mode_name", lastRecord.reporting_operation_mode_name);
            setReportingType(lastRecord.reporting_type);
        } else if (e.target.checked === false && CreateVesselReportFormik?.values.is_noon_report === false) {
            CreateVesselReportFormik?.setFieldValue("reporting_event", null);
            CreateVesselReportFormik?.setFieldValue("reporting_event_name", null);
        }
        if (lastRecord?.event_precedence_id === EventPrecedence.NOON || lastRecord?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT) {
            CreateVesselReportFormik?.setFieldValue("previous_event_other_than_noon", lastRecord.previous_event_other_than_noon);
        } else {
            CreateVesselReportFormik?.setFieldValue("previous_event_other_than_noon", lastRecord.reporting_event);
        }

        setRefreshCheckboxes(refreshCheckboxes + 1);
    };

    /** Changes based on which event is select */
    const handleReportingEventDropdownChanges = (e: any) => {
        if (e?.last_operation_name && e?.last_operation_id) {
            CreateVesselReportFormik?.setFieldValue("reporting_operation_mode", e?.last_operation_id);
            CreateVesselReportFormik?.setFieldValue("reporting_operation_mode_name", e?.last_operation_name);
        }
        CreateVesselReportFormik?.setFieldValue("reporting_event", e.id);
        loadNextPossibleOperations(e.id);
        if (CreateVesselReportFormik?.values?.additional_events === false) {
            let indx = reportingEvent[0]?.next_possible_events?.next_possible_events?.indexOf(e);
            setReportingType(reportingEvent[0]?.next_possible_events?.next_possible_events[indx]?.reporting_type_name);
        } else {
            let indx = additionalEvents[0]?.next_possible_events?.next_possible_events?.indexOf(e);
            setReportingType(additionalEvents[0]?.next_possible_events?.next_possible_events[indx]?.reporting_type_name);
        }
        if (lastRecord.event_precedence_id === EventPrecedence.NOON || lastRecord?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT) {
            CreateVesselReportFormik?.setFieldValue("previous_event_other_than_noon", lastRecord.previous_event_other_than_noon);
        } else {
            CreateVesselReportFormik?.setFieldValue("previous_event_other_than_noon", lastRecord.reporting_event);
        }
    };

    const generateDistanceAlert = (distance: string) => {
        if (env?.alerts && parseFloat(distance) < calculateGeometricDistance(lastRecord, CreateVesselReportFormik?.values)) {
            setAlertColourType(AlertColourTypeConstant.WARNING);
            setAlertType(AlertTypeConstant.INFO_ONLY);
            setAlertMessage(AlertMessages.VesselReporting.distance_travelled_position_coordindates);
            setAlertBool(true);
        }
        let calcDistance: number;
        if (distance !== "" || !MaxSpeedConsumption?.speed) {
            calcDistance = (((CreateVesselReportFormik?.values?.time_duration_since_last_report / 60) * parseFloat(MaxSpeedConsumption?.speed)));
        } else {
            calcDistance = null;
        }
        if (calcDistance) {
            if (env?.alerts && calcDistance < parseFloat(CreateVesselReportFormik?.values?.distance_travelled)) {
                setAlertColourType(AlertColourTypeConstant.WARNING);
                setAlertType(AlertTypeConstant.WITH_REASON);
                setFieldId('distance_travelled');
                setAlertMessage(AlertMessages.VesselReporting.distance_travelled_speed_time);
                setAlertBool(true);
            }
        }
    }

    return (
        <React.Fragment>
            {(VesselState === "CREATE_VESSEL_REPORTING" ||
                VesselState === "EDIT") && (
                    <React.Fragment>
                        <Layout children={Navbar} />
                        <div className="page-content">
                            <Container fluid>
                                <Card>
                                    <CardHeader className="pt-0 pb-0">
                                        <Row>
                                            <Col sm={2} className="d-flex align-items-center">
                                                <button
                                                    color="primary"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        handleVesselState("VESSEL_REPORTING");
                                                    }}
                                                >
                                                    <i className="bx bx-chevron-left me-1" />
                                                    Back
                                                </button>
                                            </Col>
                                            <Col sm={10}>
                                                <VesselDetailsHeader />
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    {(
                                        VesselReportObjectLoading ||
                                        lastRecordLoading ||
                                        reportingEventIsLoading ||
                                        additionalEventsIsLoading ||
                                        currentYearLoading ||
                                        LoadingLoadCondition ||
                                        LoadingStraitCanal ||
                                        MaxSpeedConsumptionLoading
                                    ) && <Loading message="Loading required data!" />}
                                    {(
                                        VesselReportObjectError ||
                                        lastRecordError ||
                                        reportingEventIsError ||
                                        additionalEventsIsError ||
                                        currentYearError ||
                                        ErrorLoadCondition ||
                                        ErrorStraitCanal ||
                                        MaxSpeedConsumptionError
                                    ) && <ErrorComponent message="Unable to load required data!" />}
                                    {!(
                                        VesselReportObjectLoading ||
                                        lastRecordLoading ||
                                        reportingEventIsLoading ||
                                        additionalEventsIsLoading ||
                                        currentYearLoading ||
                                        LoadingLoadCondition ||
                                        LoadingStraitCanal ||
                                        MaxSpeedConsumptionLoading
                                    ) &&
                                        !(
                                            VesselReportObjectError ||
                                            lastRecordError ||
                                            reportingEventIsError ||
                                            additionalEventsIsError ||
                                            currentYearError ||
                                            ErrorLoadCondition ||
                                            ErrorStraitCanal ||
                                            MaxSpeedConsumptionError
                                        ) &&
                                        <React.Fragment>
                                            <CardBody>
                                                <form noValidate autoComplete='off' onSubmit={CreateVesselReportFormik?.handleSubmit} key={refreshKey}>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <h4 className="mb-4">Vessel Reporting</h4>
                                                        </Col>
                                                        <Col
                                                            lg={{ size: 4, offset: 4 }}
                                                            className="d-flex justify-content-end"
                                                        >
                                                            <Label>Voyage number:
                                                                <i
                                                                    className="bx bx-info-circle me-2"
                                                                    id="voyage_number_msg"
                                                                />
                                                            </Label>
                                                            <ToolTip
                                                                target="voyage_number_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "voyage_number_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <h5>{voyage && voyage.voyage_number}</h5>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-2'>
                                                        {((CreateVesselReportFormik?.values?.reporting_time_utc !== "" &&
                                                            CreateVesselReportFormik?.values?.reporting_time_utc !== null) || VesselReportObject) && (
                                                                <Col lg={4}>
                                                                    <Label className='mb-0'>UTC time:</Label><br />
                                                                    <h5 className="mb-0">{calculateUTCTime(
                                                                        CreateVesselReportFormik?.values?.local_time,
                                                                        CreateVesselReportFormik?.values?.reporting_time_difference,
                                                                        CreateVesselReportFormik?.values?.id
                                                                    )}</h5>
                                                                </Col>
                                                            )}
                                                        <Col>
                                                            <Label className="mb-0 asteric" for="position_lat_deg">
                                                                Vessel position (Latitude)<i className="bx bx-info-circle ml-2p" id="position_lat_deg_lbl" />
                                                            </Label>
                                                            <ToolTip
                                                                target="position_lat_deg_lbl"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "position_lat_deg_lbl")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <div className="d-flex gap-1">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-4"
                                                                    id="position_lat_deg"
                                                                    name="position_lat_deg"
                                                                    placeholder="deg"
                                                                    onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                    defaultValue={CreateVesselReportFormik?.values?.position_lat_deg}
                                                                />
                                                                {CreateVesselReportFormik?.errors.position_lat_deg && CreateVesselReportFormik?.touched.position_lat_deg &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={`position_lat_deg`}
                                                                            message={CreateVesselReportFormik?.errors?.position_lat_deg}
                                                                            open={CreateVesselReportFormik?.errors?.position_lat_deg ? true : false}
                                                                        />
                                                                    )}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-4"
                                                                    id="position_lat_min"
                                                                    name="position_lat_min"
                                                                    placeholder="min"
                                                                    onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                    defaultValue={CreateVesselReportFormik?.values?.position_lat_min}
                                                                />
                                                                {CreateVesselReportFormik?.errors.position_lat_min && CreateVesselReportFormik?.touched.position_lat_min &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"position_lat_min"}
                                                                            message={CreateVesselReportFormik?.errors.position_lat_min}
                                                                            open={CreateVesselReportFormik?.errors.position_lat_min ? true : false}
                                                                        />
                                                                    )}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-4"
                                                                    id="position_lat_sec"
                                                                    name="position_lat_sec"
                                                                    placeholder="sec"
                                                                    onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                    defaultValue={CreateVesselReportFormik?.values?.position_lat_sec}
                                                                />
                                                                {CreateVesselReportFormik?.errors.position_lat_sec && CreateVesselReportFormik?.touched.position_lat_sec &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"position_lat_sec"}
                                                                            message={CreateVesselReportFormik?.errors.position_lat_sec}
                                                                            open={CreateVesselReportFormik?.errors.position_lat_sec ? true : false}
                                                                        />
                                                                    )}
                                                                <div className="square-switch sqswitch mt-1">
                                                                    <Input
                                                                        type="checkbox"
                                                                        switch="none"
                                                                        name="lat_direction"
                                                                        id="north_south"
                                                                        defaultChecked={CreateVesselReportFormik?.values?.lat_direction === "north" ? true : false}
                                                                        onChange={(e: any) =>
                                                                            CreateVesselReportFormik?.setFieldValue(
                                                                                "lat_direction", e.target.checked === true ? "north" : "south"
                                                                            )
                                                                        }
                                                                    />
                                                                    <Label
                                                                        htmlFor="north_south"
                                                                        data-on-label="N"
                                                                        data-off-label="S"
                                                                        className="mt-1"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <Label className="mb-0 asteric" for="position_long_deg">
                                                                Vessel position (Longitude)<i className="bx bx-info-circle ml-2p" id="position_long_deg_lbl" />
                                                            </Label>
                                                            <ToolTip
                                                                target="position_long_deg_lbl"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "position_long_deg_lbl")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <div className="d-flex gap-1">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-4"
                                                                    id="position_long_deg"
                                                                    name="position_long_deg"
                                                                    placeholder="deg"
                                                                    onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                    defaultValue={CreateVesselReportFormik?.values?.position_long_deg}
                                                                />
                                                                {CreateVesselReportFormik?.errors?.position_long_deg && CreateVesselReportFormik?.touched?.position_long_deg &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"position_long_deg"}
                                                                            message={CreateVesselReportFormik?.errors.position_long_deg}
                                                                            open={CreateVesselReportFormik?.errors.position_long_deg ? true : false}
                                                                        />
                                                                    )}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-4"
                                                                    id="position_long_min"
                                                                    name="position_long_min"
                                                                    placeholder="min"
                                                                    onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                    defaultValue={CreateVesselReportFormik?.values?.position_long_min}
                                                                />
                                                                {CreateVesselReportFormik?.errors.position_long_min && CreateVesselReportFormik?.touched.position_long_min &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"position_long_min"}
                                                                            message={CreateVesselReportFormik?.errors.position_long_min}
                                                                            open={CreateVesselReportFormik?.errors.position_long_min ? true : false}
                                                                        />
                                                                    )}
                                                                <Input
                                                                    type="text"
                                                                    className="form-control text-right max-width-4"
                                                                    id="position_long_sec"
                                                                    name="position_long_sec"
                                                                    placeholder="sec"
                                                                    onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                    defaultValue={CreateVesselReportFormik?.values?.position_long_sec}
                                                                />
                                                                {CreateVesselReportFormik?.errors.position_long_sec && CreateVesselReportFormik?.touched.position_long_sec &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"position_long_sec"}
                                                                            message={CreateVesselReportFormik?.errors.position_long_sec}
                                                                            open={CreateVesselReportFormik?.errors.position_long_sec ? true : false}
                                                                        />
                                                                    )}
                                                                <div className="square-switch sqswitch mt-1">
                                                                    <Input
                                                                        type="checkbox"
                                                                        switch="none"
                                                                        name="long_direction"
                                                                        id="east_west"
                                                                        defaultChecked={CreateVesselReportFormik?.values?.long_direction === "east" ? true : false}
                                                                        onChange={(e: any) =>
                                                                            CreateVesselReportFormik?.setFieldValue(
                                                                                "long_direction", e.target.checked === true ? "east" : "west"
                                                                            )
                                                                        }
                                                                    />
                                                                    <Label
                                                                        htmlFor="east_west"
                                                                        data-on-label="E"
                                                                        data-off-label="W"
                                                                        className="mt-1"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={4}>
                                                            <div className="form-group mb-2">
                                                                <Label className="asteric mb-0" for="position_long_deg">
                                                                    Reporting date & time (Local)<i className="bx bx-info-circle ml-2p" id="reporting_time_utc_msg" />
                                                                </Label>
                                                                <ToolTip
                                                                    target="reporting_time_utc_msg"
                                                                    message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "reporting_time_utc_msg")
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <Input
                                                                    type="datetime-local"
                                                                    id="local_time"
                                                                    name="local_time"
                                                                    className="datetimepicker text-uppercase"
                                                                    min={lastRecord && lastRecord?.local_time?.toString()?.substring(0, 16)}
                                                                    max="2050-12-31T00:00"
                                                                    defaultValue={CreateVesselReportFormik?.values?.local_time?.toString()?.substring(0, 16)}
                                                                    onChange={(e: any) => handleReportingDateTimeChanges(e, false)}
                                                                    onBlur={(e: any) => {
                                                                        CreateVesselReportFormik?.handleBlur(e);
                                                                        CreateVesselReportFormik?.handleChange(e);
                                                                        handleReportingDateTimeChanges(e, true);
                                                                    }}
                                                                />
                                                                {CreateVesselReportFormik?.errors.local_time && CreateVesselReportFormik?.touched.local_time &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"local_time"}
                                                                            message={CreateVesselReportFormik?.errors.local_time}
                                                                            open={CreateVesselReportFormik?.errors.local_time ? true : false}
                                                                        />
                                                                    )}
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label htmlFor="reporting_time_difference" className="form-label mb-0 asteric">
                                                                Difference between local time & UTC<i className="bx bx-info-circle ml-2p" id="time_difference_msg" />
                                                            </Label>
                                                            <ToolTip
                                                                target="time_difference_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "time_difference_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <div className="ele_row1">
                                                                <div className="square-switch sqswitch ms-0 ml-1 mt-1">
                                                                    <Input
                                                                        type="checkbox"
                                                                        switch="none"
                                                                        name="time_difference_prefix"
                                                                        id="time_difference_prefix"
                                                                        defaultChecked={
                                                                            (CreateVesselReportFormik?.values?.time_difference_prefix === "-" ||
                                                                                CreateVesselReportFormik?.values?.reporting_time_difference?.substring(0, 1) === "-")
                                                                                ? false : true
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            CreateVesselReportFormik?.handleChange(e);
                                                                            handleTimePrefixSwitchChanges(e);
                                                                            handleTimeDifferenceTextboxChanges(e, false);
                                                                        }}
                                                                        onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    />
                                                                    <Label
                                                                        htmlFor="time_difference_prefix"
                                                                        data-on-label="+"
                                                                        data-off-label="-"
                                                                        className="mt-1"
                                                                    />
                                                                    {CreateVesselReportFormik?.errors?.time_difference_prefix && CreateVesselReportFormik?.touched?.time_difference_prefix &&
                                                                        env?.form_validation === true && !alertBool && (
                                                                            <ErrorTooltip
                                                                                target={"time_difference_prefix"}
                                                                                message={CreateVesselReportFormik?.errors.time_difference_prefix}
                                                                                open={CreateVesselReportFormik?.errors.time_difference_prefix ? true : false}
                                                                            />
                                                                        )}
                                                                </div>
                                                                <Cleave
                                                                    id="reporting_time_difference"
                                                                    name="reporting_time_difference"
                                                                    placeholder="HH:MM"
                                                                    options={{
                                                                        time: true,
                                                                        timePattern: ["h", "m"],
                                                                    }}
                                                                    className="form-control max-width-5 text-center p-2 max-h-2"
                                                                    onChange={(e: any) => handleTimeDifferenceTextboxChanges(e, true)}
                                                                    onBlur={(e: any) => {
                                                                        CreateVesselReportFormik?.handleBlur(e);
                                                                        handleTimeDifferenceTextboxChanges(e, true);
                                                                    }}
                                                                    value={CreateVesselReportFormik?.values?.reporting_time_difference}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label className="asteric mb-0" for="no_of_hours_retarded">
                                                                Hours retarded/advanced since last report?<i className="bx bx-info-circle ml-2p" id="no_of_hours_retarded_msg" />
                                                            </Label>
                                                            <ToolTip
                                                                target="no_of_hours_retarded_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "no_of_hours_retarded_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <div className="input-group">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control max-width-7 text-right"
                                                                    id="no_of_hours_retarded"
                                                                    name="no_of_hours_retarded"
                                                                    value={CreateVesselReportFormik?.values?.no_of_hours_retarded < 0 ?
                                                                        CreateVesselReportFormik?.values?.no_of_hours_retarded?.toString()?.substring(1) :
                                                                        CreateVesselReportFormik?.values?.no_of_hours_retarded
                                                                    }
                                                                    onBlur={CreateVesselReportFormik?.handleBlur}
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                />
                                                                <div className="input-group-text round_border">
                                                                    {verifyAdvancedRetarded()}
                                                                </div>
                                                                {CreateVesselReportFormik?.errors?.no_of_hours_retarded && CreateVesselReportFormik?.touched?.no_of_hours_retarded &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"no_of_hours_retarded"}
                                                                            message={CreateVesselReportFormik?.errors.no_of_hours_retarded}
                                                                            open={CreateVesselReportFormik?.errors.no_of_hours_retarded ? true : false}
                                                                        />
                                                                    )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-2'>
                                                        <Col lg={4}>
                                                            <div className="d-flex flex-wrap mb-2">
                                                                <Label className="mt-1 min-width-14" for="noon_report">
                                                                    Is this noon report?<i className="bx bx-info-circle ml-2p" id="noon_report_msg" />
                                                                </Label>
                                                                <ToolTip
                                                                    target="noon_report_msg"
                                                                    message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "noon_report_msg")
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className="square-switch sqswitch mt-1">
                                                                    <Input
                                                                        type="checkbox"
                                                                        switch="none"
                                                                        name="is_noon_report"
                                                                        id="noon_report"
                                                                        onChange={(e: any) => handleIsNoonReportSwitchChanges(e)}
                                                                        defaultChecked={CreateVesselReportFormik?.values?.is_noon_report}
                                                                    />
                                                                    <Label
                                                                        htmlFor="noon_report"
                                                                        data-on-label="Yes"
                                                                        data-off-label="No"
                                                                        className="mt-1"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-wrap mb-2">
                                                                <Label className="mt-1 min-width-14" for="is_cii_adjustment_report">
                                                                    Is this a beginning of CII<br />adjustment FC<sub>voyage</sub> event?
                                                                    <i className="bx bx-info-circle ml-2p" id="cii_adjustment_report_msg" />
                                                                </Label>
                                                                <ToolTip
                                                                    target="cii_adjustment_report_msg"
                                                                    message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "cii_adjustment_report_msg")
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className="square-switch sqswitch">
                                                                    <Input
                                                                        type="checkbox"
                                                                        switch="none"
                                                                        name="is_cii_adjustment_report"
                                                                        id="is_cii_adjustment_report"
                                                                        onChange={(e: any) => handleIsCIIReportSwitchChanges(e)}
                                                                        defaultChecked={CreateVesselReportFormik?.values?.is_cii_adjustment_report}
                                                                    />
                                                                    <Label
                                                                        htmlFor="is_cii_adjustment_report"
                                                                        data-on-label="Yes"
                                                                        data-off-label="No"
                                                                        className="mt-1"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col key={refreshCheckboxes}>
                                                            {CreateVesselReportFormik?.values?.is_noon_report === true &&
                                                                CreateVesselReportFormik?.values?.is_cii_adjustment_report === true ? (
                                                                <React.Fragment>
                                                                    <Input
                                                                        className="form-check-input me-1 mt-3"
                                                                        type="checkbox"
                                                                        name="cii_noon_combine_with_other_event"
                                                                        id="cii_combine_with_other_event"
                                                                        onChange={(e: any) => {
                                                                            CreateVesselReportFormik?.setFieldValue("cii_noon_combine_with_other_event", e.target.checked);
                                                                            if (e.target.checked === true) {
                                                                                CreateVesselReportFormik?.setFieldValue("reporting_event", null);
                                                                                CreateVesselReportFormik?.setFieldValue("reporting_event_name", null);
                                                                                setCount(count + 1);
                                                                            } else {
                                                                                CreateVesselReportFormik?.setFieldValue(
                                                                                    "reporting_event",
                                                                                    reportingEvent[0]?.next_possible_events?.next_possible_events
                                                                                        .filter((item: any) => item?.event_precedence_id === EventPrecedence.NOON)[0]?.id
                                                                                );
                                                                            }
                                                                        }}
                                                                        defaultChecked={
                                                                            CreateVesselReportFormik?.values?.cii_noon_combine_with_other_event
                                                                        }
                                                                    />
                                                                    <Label for="cii_combine_with_other_event" className='margin-top-0-8 mb-0'>
                                                                        Is combined with other event?
                                                                    </Label>
                                                                    <br />
                                                                </React.Fragment>
                                                            ) : CreateVesselReportFormik?.values?.is_noon_report === true &&
                                                                CreateVesselReportFormik?.values?.is_cii_adjustment_report === false ? (
                                                                <div className="ele_row1">
                                                                    <div className="form-check">
                                                                        <Input
                                                                            className="form-check-input mt-3"
                                                                            type="radio"
                                                                            name="only_noon_or_combine_with_other_event"
                                                                            id="only_noon"
                                                                            defaultChecked={
                                                                                CreateVesselReportFormik?.values?.only_noon_or_combine_with_other_event === "combine_with_other_report"
                                                                                    ? false : true
                                                                            }
                                                                            onChange={(e: any) => {
                                                                                CreateVesselReportFormik?.setFieldValue(
                                                                                    "only_noon_or_combine_with_other_event",
                                                                                    e.target.checked === true ? "only_noon_report" : "combine_with_other_report"
                                                                                );
                                                                                CreateVesselReportFormik?.setFieldValue(
                                                                                    "reporting_event",
                                                                                    reportingEvent[0]?.next_possible_events?.next_possible_events
                                                                                        .filter((item: any) => item?.event_precedence_id === EventPrecedence.NOON)[0]?.id
                                                                                );
                                                                            }}
                                                                        />
                                                                        <Label
                                                                            className="form-check-label margin-top-0-8 mr-2"
                                                                            htmlFor="only_noon"
                                                                        >
                                                                            Only noon report
                                                                        </Label>
                                                                    </div>
                                                                    <div className="form-check">
                                                                        <Input
                                                                            className="form-check-input mt-3"
                                                                            type="radio"
                                                                            name="only_noon_or_combine_with_other_event"
                                                                            id="combined_with_other_event"
                                                                            onChange={(e: any) => {
                                                                                CreateVesselReportFormik?.setFieldValue(
                                                                                    "only_noon_or_combine_with_other_event",
                                                                                    e.target.checked === true ? "combine_with_other_report" : "only_noon_report"
                                                                                );
                                                                                if (e.target.checked === true) {
                                                                                    CreateVesselReportFormik?.setFieldValue("reporting_event", null);
                                                                                    CreateVesselReportFormik?.setFieldValue("reporting_event_name", null);
                                                                                    setCount(count + 1);
                                                                                }
                                                                            }}
                                                                            defaultChecked={
                                                                                CreateVesselReportFormik?.values?.only_noon_or_combine_with_other_event === "combine_with_other_report"
                                                                                    ? true : false
                                                                            }
                                                                        />
                                                                        <Label
                                                                            className="form-check-label margin-top-0-8"
                                                                            htmlFor="combined_with_other_event"
                                                                        >
                                                                            Combined with other event
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            ) : CreateVesselReportFormik?.values?.is_noon_report === false &&
                                                                CreateVesselReportFormik?.values?.is_cii_adjustment_report === true ? (
                                                                <React.Fragment>
                                                                    <Input
                                                                        className="form-check-input me-1 mt-3"
                                                                        type="checkbox"
                                                                        id="combined_with_other_event"
                                                                        name="cii_combine_with_other_event"
                                                                        onChange={(e: any) => {
                                                                            CreateVesselReportFormik?.handleChange(e);
                                                                            if (e.target.checked) {
                                                                                CreateVesselReportFormik?.setFieldValue("reporting_event", null);
                                                                                CreateVesselReportFormik?.setFieldValue("reporting_event_name", null);
                                                                                setCount(count + 1);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Label for="combined_with_other_event" className='margin-top-0-8 mb-0'>
                                                                        Is combined with other event?
                                                                    </Label>
                                                                </React.Fragment>
                                                            ) : null}
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label className="asteric mb-0">
                                                                Reporting type<i className="bx bx-info-circle ml-2p mb-0" id="reporting_type_msg" />
                                                            </Label>
                                                            <br></br>
                                                            <ToolTip
                                                                target="reporting_type_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "reporting_type_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <h5 className="mb-0">
                                                                {VesselReportObject !== undefined && VesselReportObject.id > 0 &&
                                                                    reportingType === "" ? CreateVesselReportFormik?.values?.reporting_type : reportingType}
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-2'>
                                                        <Col lg={4}>
                                                            <Label>Last reporting event name - </Label>
                                                            <h5 className="mb-0">
                                                                {lastRecord.reporting_event_name}
                                                            </h5>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label>Last reporting operation name - </Label>
                                                            <h5 className="mb-0">
                                                                {lastRecord.reporting_operation_mode_name}
                                                            </h5>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label>Last reporting engine state - </Label>
                                                            <h5 className="mb-0">
                                                                {lastRecord.status_name}
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-2'> {/*height-6-25 */}
                                                        <Col lg={4}>
                                                            <Label className="asteric mb-0" for="reporting_event">
                                                                Reporting event<i className="bx bx-info-circle ml-2p" id="reporting_event_msg" />
                                                            </Label>
                                                            <ToolTip
                                                                target="reporting_event_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "reporting_event_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            {((CreateVesselReportFormik?.values?.only_noon_or_combine_with_other_event === "only_noon_report" &&
                                                                CreateVesselReportFormik?.values?.is_cii_adjustment_report === false &&
                                                                CreateVesselReportFormik?.values?.cii_noon_combine_with_other_event === false) ||
                                                                (CreateVesselReportFormik?.values?.is_noon_report === true &&
                                                                    CreateVesselReportFormik?.values?.is_cii_adjustment_report === true &&
                                                                    CreateVesselReportFormik?.values?.cii_noon_combine_with_other_event === false)) ?
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="reporting_event_disabled"
                                                                    name="reporting_event"
                                                                    value={
                                                                        reportingEvent &&
                                                                        reportingEvent[0]?.next_possible_events?.next_possible_events
                                                                            .filter((item: any) => item?.event_precedence_id === EventPrecedence.NOON)[0]?.event
                                                                    }
                                                                    disabled
                                                                />
                                                                : (CreateVesselReportFormik?.values?.is_noon_report === false &&
                                                                    CreateVesselReportFormik?.values?.is_cii_adjustment_report === true &&
                                                                    CreateVesselReportFormik?.values?.cii_noon_combine_with_other_event === false &&
                                                                    CreateVesselReportFormik?.values?.cii_combine_with_other_event === false) ?
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="reporting_event_disabled"
                                                                        name="reporting_event"
                                                                        value={
                                                                            reportingEvent[0]?.next_possible_events?.next_possible_events
                                                                                .filter((item: any) => item?.event_precedence_id === EventPrecedence?.CII_ADJUSTMENT_EVENT)[0]?.event
                                                                        }
                                                                        disabled
                                                                    />
                                                                    : (CreateVesselReportFormik?.values?.is_noon_report === true &&
                                                                        CreateVesselReportFormik?.values?.is_cii_adjustment_report === true &&
                                                                        CreateVesselReportFormik?.values?.cii_noon_combine_with_other_event === true) ||
                                                                        (CreateVesselReportFormik?.values?.only_noon_or_combine_with_other_event === "combine_with_other_report" &&
                                                                            CreateVesselReportFormik?.values?.is_cii_adjustment_report === false &&
                                                                            CreateVesselReportFormik?.values?.cii_noon_combine_with_other_event === false) ||
                                                                        (CreateVesselReportFormik?.values?.is_noon_report === false &&
                                                                            CreateVesselReportFormik?.values?.is_cii_adjustment_report === true &&
                                                                            CreateVesselReportFormik?.values?.cii_combine_with_other_event === true) ||
                                                                        (CreateVesselReportFormik?.values?.is_noon_report === false &&
                                                                            CreateVesselReportFormik?.values?.is_cii_adjustment_report === false) ? (
                                                                        <React.Fragment>
                                                                            <Select
                                                                                name="reporting_event"
                                                                                inputId="reporting_event"
                                                                                id='reporting_event'
                                                                                key={count}
                                                                                options={CreateVesselReportFormik?.values?.additional_events === false ?
                                                                                    reportingEvent && reportingEvent[0]?.next_possible_events?.next_possible_events
                                                                                        .filter((item: any) => item.event_precedence_id !== EventPrecedence.CII_ADJUSTMENT_EVENT &&
                                                                                            item.event_precedence_id !== EventPrecedence.NOON) :
                                                                                    filterUniqueByFields(additionalEvents[0]?.next_possible_events?.next_possible_events, ['id'])}
                                                                                getOptionLabel={(e: any) => e?.event}
                                                                                getOptionValue={(e: any) => e?.id}
                                                                                defaultValue={
                                                                                    {
                                                                                        id: CreateVesselReportFormik?.values?.reporting_event,
                                                                                        event: CreateVesselReportFormik?.values?.reporting_event_name,
                                                                                    }
                                                                                }
                                                                                onChange={(e: any) => {
                                                                                    CreateVesselReportFormik?.setFieldTouched("reporting_event", true);
                                                                                    handleReportingEventDropdownChanges(e);
                                                                                }}
                                                                                menuPortalTarget={document.body}
                                                                                styles={customStyle}
                                                                                className="mb-0"
                                                                                onBlur={CreateVesselReportFormik?.handleBlur}
                                                                            />
                                                                            {CreateVesselReportFormik?.errors?.reporting_event && CreateVesselReportFormik?.touched?.reporting_event &&
                                                                                env?.form_validation === true && !alertBool && document.getElementById("reporting_event") !== null && (
                                                                                    <ErrorTooltip
                                                                                        target={"reporting_event"}
                                                                                        message={CreateVesselReportFormik?.errors.reporting_event}
                                                                                        open={!!CreateVesselReportFormik?.errors.reporting_event}
                                                                                    />
                                                                                )}
                                                                            {/* <Label className="mt-0 font-smaller mb-0">
                                                                                Select this if you need to choose
                                                                                an event not listed above
                                                                            </Label>
                                                                            <Input
                                                                                className="form-check-input mr-1 justify_right"
                                                                                type="checkbox"
                                                                                name="additional_events"
                                                                                id="additional_events"
                                                                                onChange={(e: any) => {
                                                                                    CreateVesselReportFormik?.handleChange(e);
                                                                                }}
                                                                                defaultChecked={CreateVesselReportFormik?.values?.additional_events}
                                                                            />
                                                                            <Label for="additional_events" className='mb-0'>Additional events</Label> */}
                                                                        </React.Fragment>
                                                                    ) : null
                                                            }
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label className="asteric mb-0" for="reporting_operation_mode_name">
                                                                Reporting operation since last report<i className="bx bx-info-circle ml-2p" id="reporting_operation_mode_msg" />
                                                            </Label>
                                                            <ToolTip
                                                                target="reporting_operation_mode_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "reporting_operation_mode_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <h5 className="mt-2">
                                                                {lastRecord && lastRecord?.event_precedence_id === EventPrecedence.NOON &&
                                                                    CreateVesselReportFormik?.values?.only_noon_or_combine_with_other_event === "only_noon_report" ?
                                                                    lastRecord?.reporting_operation_mode_name : CreateVesselReportFormik?.values?.reporting_operation_mode_name}
                                                            </h5>
                                                        </Col>
                                                        <Col>
                                                            {CreateVesselReportFormik?.values &&
                                                                CreateVesselReportFormik?.values?.reporting_operation_mode === ReportingOpConstant.MANEUVERING && (
                                                                    <div className="d-flex flex-wrap gap-2">
                                                                        <Label for="is_strait_canal_transit">
                                                                            Is this strait-canal transit? <i className="bx bx-info-circle ml-2p" id="is_strait_canal_transit_lbl" />
                                                                        </Label>
                                                                        <ToolTip
                                                                            target="is_strait_canal_transit_lbl"
                                                                            message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "is_strait_canal_transit_lbl")
                                                                                .map((tool: any) => { return tool.message })}`}
                                                                        />
                                                                        <div className="square-switch sqswitch">
                                                                            <Input
                                                                                type="checkbox"
                                                                                switch="none"
                                                                                name="is_strait_canal_transit"
                                                                                id="is_strait_canal_transit"
                                                                                defaultChecked={CreateVesselReportFormik?.values?.is_strait_canal_transit}
                                                                                onChange={(e: any) => CreateVesselReportFormik?.setFieldValue("is_strait_canal_transit", e.target.checked)}
                                                                            />
                                                                            <Label
                                                                                htmlFor="is_strait_canal_transit"
                                                                                data-on-label="Yes"
                                                                                data-off-label="No"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-2'>
                                                        {CreateVesselReportFormik?.values &&
                                                            CreateVesselReportFormik?.values?.reporting_event !== ReportingEvents.NOON_FWE_IP &&
                                                            CreateVesselReportFormik?.values?.reporting_event !== ReportingEvents.DEPARTURE_FROM_BERTH &&
                                                            <Col lg={4}>
                                                                <ThreeStateSwitch
                                                                    formik={CreateVesselReportFormik}
                                                                    error={CreateVesselReportFormik?.errors.is_this_cargo_loading_discharging}
                                                                    touched={CreateVesselReportFormik?.touched.is_this_cargo_loading_discharging}
                                                                    errorMessage={CreateVesselReportFormik?.errors?.is_this_cargo_loading_discharging}
                                                                    switchLabel={`Is there any change in cargo onboard?`}
                                                                    switchLabelId='is_this_cargo_loading_discharging'
                                                                    switchFieldName='is_this_cargo_loading_discharging'
                                                                    state1Label='Yes'
                                                                    state1Id='yes'
                                                                    state1Value={'yes'}
                                                                    state1DefaultValue={CreateVesselReportFormik?.values?.is_this_cargo_loading_discharging === 'yes' ? true : false}
                                                                    state2Label=''
                                                                    state2Id='na'
                                                                    state2Value={null}
                                                                    state2DefaultValue={CreateVesselReportFormik?.values?.is_this_cargo_loading_discharging === null ? true : false}
                                                                    state3Label='No'
                                                                    state3Id='no'
                                                                    state3Value={'no'}
                                                                    state3DefaultValue={CreateVesselReportFormik?.values?.is_this_cargo_loading_discharging === 'no' ? true : false}
                                                                />
                                                            </Col>
                                                        }
                                                        <Col lg={4}>
                                                            <Label
                                                                for="distance_travelled"
                                                                className="mb-0"
                                                            >
                                                                Distance travelled since last report
                                                                <i
                                                                    className="bx bx-info-circle ml-2p"
                                                                    id="distance_travelled_msg"
                                                                />
                                                            </Label>
                                                            <ToolTip
                                                                target="distance_travelled_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "distance_travelled_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <div className="input-group">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control max-width-7 text-right"
                                                                    id="distance_travelled"
                                                                    name="distance_travelled"
                                                                    onChange={(e: any) => CreateVesselReportFormik?.handleChange(e)}
                                                                    onBlur={(e: any) => {
                                                                        CreateVesselReportFormik?.handleChange(e);
                                                                        CreateVesselReportFormik?.handleBlur(e);
                                                                        generateDistanceAlert(e.target.value);
                                                                    }}
                                                                    disabled={
                                                                        (CreateVesselReportFormik?.values?.reporting_event === ReportingEvents.HEAVE_ANCHOR ||
                                                                            CreateVesselReportFormik?.values?.reporting_event === ReportingEvents.DEPARTURE_FROM_BERTH) ?
                                                                            true : false
                                                                    }
                                                                    defaultValue={CreateVesselReportFormik?.values?.distance_travelled}
                                                                />
                                                                <div className="input-group-text round_border">
                                                                    nm
                                                                </div>
                                                                {CreateVesselReportFormik?.errors.distance_travelled && CreateVesselReportFormik?.touched.distance_travelled &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"distance_travelled"}
                                                                            message={CreateVesselReportFormik?.errors.distance_travelled}
                                                                            open={CreateVesselReportFormik?.errors.distance_travelled ? true : false}
                                                                        />
                                                                    )}
                                                            </div>
                                                        </Col>
                                                        <Col lg={4}>
                                                            <Label
                                                                for="remaining_distance_to_the_next_port"
                                                                className="mb-0"
                                                            >
                                                                Remaining distance to the next port
                                                                <i
                                                                    className="bx bx-info-circle ml-2p"
                                                                    id="remaining_distance_msg"
                                                                />
                                                            </Label>
                                                            <ToolTip
                                                                target="remaining_distance_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "remaining_distance_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <div className="input-group">
                                                                <Input
                                                                    type="text"
                                                                    className="form-control max-width-7 text-right"
                                                                    id="remaining_distance_to_the_next_port"
                                                                    name="remaining_distance_to_the_next_port"
                                                                    disabled={reportingType === ReportingTypeConstant.INPORT ? true : false}
                                                                    onChange={CreateVesselReportFormik?.handleChange}
                                                                    onBlur={(e: any) => {
                                                                        CreateVesselReportFormik?.handleBlur(e);
                                                                        CreateVesselReportFormik?.handleChange(e);
                                                                        const lastRemainingDistance = lastRecord?.remaining_distance_to_the_next_port;
                                                                        const distanceTravelled = CreateVesselReportFormik?.values?.distance_travelled;
                                                                        const currentRemainingDistance = parseFloat(e.target.value);
                                                                        if (lastRemainingDistance && distanceTravelled) {
                                                                            const expectedRemainingDistance = lastRemainingDistance - distanceTravelled;
                                                                            const deviation = Math.abs(currentRemainingDistance - expectedRemainingDistance);
                                                                            const deviationPercentage = (deviation / expectedRemainingDistance) * 100;
                                                                            if (env?.alerts && deviationPercentage > 10) {
                                                                                setAlertBool(true);
                                                                                setAlertMessage("The entered remaining distance deviates by more than 10% from the expected value.")
                                                                                setAlertColourType(AlertColourTypeConstant.WARNING)
                                                                                setAlertType(AlertTypeConstant.WITH_REASON);
                                                                                setFieldId('remaining_distance_to_the_next_port');
                                                                            }
                                                                        }
                                                                    }}

                                                                    defaultValue={CreateVesselReportFormik?.values?.remaining_distance_to_the_next_port}
                                                                />
                                                                <div className="input-group-text round_border">
                                                                    nm
                                                                </div>
                                                                {CreateVesselReportFormik?.errors.remaining_distance_to_the_next_port &&
                                                                    env?.form_validation === true && !alertBool && (
                                                                        <ErrorTooltip
                                                                            target={"remaining_distance_to_the_next_port"}
                                                                            message={CreateVesselReportFormik?.errors.remaining_distance_to_the_next_port}
                                                                            open={CreateVesselReportFormik?.errors.remaining_distance_to_the_next_port ? true : false}
                                                                        />
                                                                    )}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-2 height-5'>
                                                        <Col lg={4}>
                                                            <Label className="asteric mb-0" for="load_condition">
                                                                Vessel load condition<i className="bx bx-info-circle ml-2p" id="load_condition_msg" />
                                                            </Label>
                                                            <br></br>
                                                            <ToolTip
                                                                target="load_condition_msg"
                                                                message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "load_condition_msg")
                                                                    .map((tool: any) => { return tool.message })}`}
                                                            />
                                                            <Select
                                                                name="load_condition"
                                                                inputId="load_condition"
                                                                options={LoadConditionOptions}
                                                                getOptionLabel={(e: any) => e?.load_condition_name}
                                                                getOptionValue={(e: any) => e?.id}
                                                                onChange={(selectedOption: any) => {
                                                                    CreateVesselReportFormik?.setFieldValue("load_condition", selectedOption?.id);
                                                                }}
                                                                onBlur={(selectedOption: any) => {
                                                                    CreateVesselReportFormik?.handleBlur(selectedOption);
                                                                    if (env?.alerts && lastRecord?.load_condition !== selectedOption?.id && CreateVesselReportFormik?.values?.is_this_cargo_loading_discharging !== "yes") {
                                                                        setAlertColourType(AlertColourTypeConstant.WARNING);
                                                                        setAlertType(AlertTypeConstant.WITH_YES_NO_ACTION);
                                                                        setAlertMessage(AlertMessages.VesselReporting.load_condition);
                                                                        setFieldId('load_condition');
                                                                        setAlertBool(true);
                                                                    }
                                                                }}
                                                                defaultValue={
                                                                    VesselReportObject !== undefined && VesselReportObject.id > 0 ?
                                                                        {
                                                                            id: CreateVesselReportFormik?.values?.load_condition,
                                                                            load_condition_name: CreateVesselReportFormik?.values?.load_condition_name,
                                                                        } : null
                                                                }
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                            />
                                                            {CreateVesselReportFormik?.errors?.load_condition && CreateVesselReportFormik?.touched?.load_condition &&
                                                                env?.form_validation === true && !alertBool && (
                                                                    <ErrorTooltip
                                                                        target={"load_condition"}
                                                                        message={CreateVesselReportFormik?.errors.load_condition}
                                                                        open={CreateVesselReportFormik?.errors.load_condition ? true : false}
                                                                    />
                                                                )}
                                                        </Col>
                                                        {CreateVesselReportFormik?.values?.reporting_operation_mode === ReportingOpConstant.MANEUVERING &&
                                                            calculateTimeDurationBetwnReports
                                                                (lastRecord?.reporting_time_utc, CreateVesselReportFormik?.values?.reporting_time_utc) >= ManeuveringTimeLimit &&
                                                            <Col lg={4}>
                                                                <div className="d-flex flex-wrap gap-2">
                                                                    <Label className="mt-2" for="is_drifting_inolved">
                                                                        Is drifting involved since last report?<i className="bx bx-info-circle ml-2p" id="is_drifting_inolved_msg" />
                                                                    </Label>
                                                                    <ToolTip
                                                                        target="is_drifting_inolved_msg"
                                                                        message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "is_drifting_inolved_msg")
                                                                            .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className="square-switch sqswitch mt-1">
                                                                        <Input
                                                                            type="checkbox"
                                                                            switch="none"
                                                                            name="is_drifting_inolved"
                                                                            id="is_drifting_inolved"
                                                                            onChange={(e: any) => CreateVesselReportFormik?.setFieldValue("is_drifting_inolved", e.target.checked)}
                                                                            defaultChecked={CreateVesselReportFormik?.values?.is_drifting_inolved}
                                                                        />
                                                                        <Label
                                                                            htmlFor="is_drifting_inolved"
                                                                            data-on-label="Yes"
                                                                            data-off-label="No"
                                                                            className="mt-1"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        }
                                                        {CreateVesselReportFormik?.values &&
                                                            CreateVesselReportFormik?.values?.is_strait_canal_transit === true &&
                                                            CreateVesselReportFormik?.values?.reporting_operation_mode === ReportingOpConstant.MANEUVERING &&
                                                            (
                                                                <Col lg={4}>
                                                                    <React.Fragment>
                                                                        <Label className="mb-0" for="strait_canal_transit">
                                                                            Strait-Canal transit<i className="bx bx-info-circle ml-2p" id="strait_canal" />
                                                                        </Label>
                                                                        <br></br>
                                                                        <ToolTip
                                                                            target="strait_canal"
                                                                            message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "strait_canal")
                                                                                .map((tool: any) => { return tool.message })}`}
                                                                        />
                                                                        <Select
                                                                            name="strait_canal_transit"
                                                                            inputId="strait_canal_transit"
                                                                            options={StraitCanals?.sort((a: any, b: any) => a.strait_canal_transit.localeCompare(b.strait_canal_transit))}
                                                                            getOptionLabel={(e) => e.strait_canal_transit}
                                                                            getOptionValue={(e) => e.id}
                                                                            onChange={(selectedOption: any) => {
                                                                                CreateVesselReportFormik?.setFieldValue("strait_canal_transit", selectedOption.id);
                                                                            }}
                                                                            defaultValue={
                                                                                VesselReportObject !== undefined && VesselReportObject.id > 0 ?
                                                                                    {
                                                                                        id: CreateVesselReportFormik?.values?.strait_canal_transit,
                                                                                        strait_canal_transit: CreateVesselReportFormik?.values?.strait_canal_transit_name,
                                                                                    } : null
                                                                            }
                                                                            menuPortalTarget={document.body}
                                                                            styles={customStyle}
                                                                        />
                                                                    </React.Fragment>
                                                                </Col>

                                                            )}
                                                        {VesselReportObject !== undefined &&
                                                            (vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) &&
                                                            (VesselReportObject.id > 0 &&
                                                                reportingType === "" ? CreateVesselReportFormik?.values?.reporting_type : reportingType) === ReportingTypeConstant.INPORT && (
                                                                <Col lg={4}>
                                                                    <div className="d-flex flex-wrap">
                                                                        <Label className="min-width-14" for="is_sts_considered">
                                                                            Is this the beginning of an<br />
                                                                            STS operation? <i className="bx bx-info-circle ml-2p" id="is_sts_considered_msg" />
                                                                        </Label>
                                                                        <ToolTip
                                                                            target="is_sts_considered_msg"
                                                                            message={`${TooltipMsg.CreateReport.filter((item: any) => item.target === "is_sts_considered_msg")
                                                                                .map((tool: any) => { return tool.message })}`}
                                                                        />
                                                                        <div className="square-switch sqswitch mt-1">
                                                                            <Input
                                                                                type="checkbox"
                                                                                switch="none"
                                                                                name="is_sts_considered"
                                                                                id="is_sts_considered"
                                                                                onChange={(e: any) => CreateVesselReportFormik?.setFieldValue("is_sts_considered", e.target.checked)}
                                                                                defaultChecked={CreateVesselReportFormik?.values?.is_sts_considered}
                                                                            />
                                                                            <Label
                                                                                htmlFor="is_sts_considered"
                                                                                data-on-label="Yes"
                                                                                data-off-label="No"
                                                                                className="mt-1"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            )}
                                                    </Row>
                                                    <Row className='mb-0'>
                                                        <div className="d-flex flex-wrap gap-5 grp_justify_right btn_Margin">
                                                            <Button
                                                                type="submit"
                                                                color="primary"
                                                                className="btn_size4_5_cstm"
                                                            >
                                                                Save
                                                            </Button>
                                                            <Button
                                                                type="reset"
                                                                color="danger"
                                                                className="btn_size4_5_cstm"
                                                                onClick={() => {
                                                                    CreateVesselReportFormik?.resetForm();
                                                                    setCount(count + 1);
                                                                    setRefreshKey(refreshKey + 1);
                                                                }}
                                                            >
                                                                Reset
                                                            </Button>
                                                        </div>
                                                    </Row>
                                                    <FormValuesDebug values={[CreateVesselReportFormik?.values, CreateVesselReportFormik?.errors, CreateVesselReportFormik?.touched, CreateVesselReportFormik?.initialValues]} />
                                                </form>
                                            </CardBody>
                                            {errorMessage &&
                                                <CardFooter>
                                                    <ErrorReport
                                                        errorMessages={errorMessage}
                                                        ui_url={window.location.pathname}
                                                        setErrorMessage={setErrorMessage} />
                                                </CardFooter>
                                            }
                                        </React.Fragment>
                                    }
                                </Card>
                            </Container>
                        </div>
                        {alertBool &&
                            <AlertPopup
                                state={alertBool}
                                setState={setAlertBool}
                                alertMessage={alertMessage}
                                setAlertMessage={setAlertMessage}
                                AlertColourType={alertColourType}
                                setAlertColourType={setAlertColourType}
                                alertType={alertType}
                                setAlertType={setAlertType}
                                formTitle='Vessel Reporting Information'
                                fieldId={fieldId}
                            />
                        }
                    </React.Fragment>
                )}
        </React.Fragment>
    )
}
export default CreateVesselReport;
