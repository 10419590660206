import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import PopOver from 'Components/PopOver';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { useFormik } from 'formik';
import { handleServerResponse, setSelectValueToAllFields } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Label, Modal, Row } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadReportingSensorMaster, loadSensorUnitMaster, loadVesselMachineries, loadVesselSensorByID } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from "yup";

interface AddSensorInterFace {
    state: any;
    setState: any;
    editID: any;
    setParentRefreshKey: any
    parentRefreshkey: number;
}

const AddSensorModal = ({ state, setState, editID, setParentRefreshKey, parentRefreshkey }: AddSensorInterFace) => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [deleteIndex, setDeleteIndex] = useState<number>();
    const [deletePopOverState, setDeletePopOverState] = useState(false);
    const [formRefreshKey, setFormRefreshKey] = useState<number>(0)
    const [refreshKey, setRefreshKey] = useState<number>(0)

    /** Queries start */
    /** Load Reporting Sensor Master List Array */
    const {
        data: ReportingSensorMaster,
        isLoading: ReportingSensorMasterLoading,
        isError: ReportingSensorMasterError,
    } = useQuery(
        [queryKeyes.masters.ReportingSensorMaster.key],
        async () => {
            return await loadReportingSensorMaster();
        },
        { staleTime: Infinity }
    )

    /** load Sensor Unit Master Lists */
    const {
        data: SensorUnitMaster,
        isLoading: SensorUnitMasterLoading,
        isError: SensorUnitMasterError,
    } = useQuery(
        [queryKeyes.masters.SensorUnitMaster],
        async () => {
            return await loadSensorUnitMaster();
        },
        { staleTime: Infinity }
    )

    /** load Vessel machinaries list by vesselID */
    const {
        data: VesselMachineries,
        isLoading: VesselMachineriesLoading,
        isError: VesselMachineriesError,
    } = useQuery(
        [queryKeyes.vessel.VesselMachineries.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
        { staleTime: Infinity }
    )

    /** get vessel sensor record by its ID */
    const {
        data: VesselSensor,
        isLoading: VesselSensorLoading,
        isError: VesselSensorError,
    } = useQuery(
        [queryKeyes.vessel.VesselSensorByID.key, editID],
        async () => {
            return await loadVesselSensorByID(editID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** set initial values for sensor */
    const getInitialValues = (): any => {
        let arr: any[] = [];
        if (VesselSensor && editID) {
            arr.push(VesselSensor)
        } else {
            arr.push({
                vessel_sensor_name: null,
                unit: null,
                sensor_unit: null,
                reporting_sensor: null,
                vessel_machinery: null,
                vessel: VesselID
            })
        }
        return arr
    }

    /**validation schem of vessel pressure */
    const validationSchema = () => {
        return Yup.object().shape({
            sensor: Yup.array().of(
                Yup.object().shape({
                    vessel_sensor_name: Yup.string()
                        .max(50, commonValidationMessages.max50Char)
                        .required(commonValidationMessages.required),
                    unit: Yup.string().nullable(),
                    sensor_unit: Yup.number().required(commonValidationMessages.required),
                    reporting_sensor: Yup.number().required(commonValidationMessages.required),
                    vessel_machinery: Yup.number().required(commonValidationMessages.required),
                })
            ),
        });
    };

    /**
    * add or edit sensor Formik
    */
    const AddSensorFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: { sensor: getInitialValues() },
        validationSchema: validationSchema(),
        onSubmit: async (values, actions) => {
            const responseArray: any[] = []
            actions.setSubmitting(true)
            if (VesselSensor && editID) {
                /** push request promise to responseArray */
                responseArray.push(apiGlobal.put(`${queryKeyes.vessel.VesselSensor.url()}${editID}/`, values.sensor[0]))
            } else {
                /** push request promise to responseArray */
                responseArray.push(apiGlobal.post(`${queryKeyes.vessel.VesselSensor.url()}`, values.sensor))
            }
            /** call handleServerResponse to handle request */
            await handleServerResponse(responseArray).then((res) => {
                if (res) {
                    queryClient.invalidateQueries(queryKeyes.vessel.VesselSensorByVesselID.key);
                    setParentRefreshKey(parentRefreshkey + 1)
                    handlePopoverState()
                }
            })
            actions.setSubmitting(false)
        }
    })

    /**
    * add new sensor to formik values
    */
    const addNewSensor = () => {
        /** Get the current sensors */
        const sensors = AddSensorFormik?.values?.sensor || [];
        /** Create a new sensor object to push to the array */
        const newSensor: any = {
            vessel_sensor_name: null,
            unit: AddSensorFormik?.values?.sensor[0]?.unit ?? null,
            sensor_unit: AddSensorFormik?.values?.sensor[0]?.sensor_unit ?? null,
            reporting_sensor: AddSensorFormik?.values?.sensor[0]?.reporting_sensor ?? null,
            vessel_machinery: AddSensorFormik?.values?.sensor[0]?.vessel_machinery ?? null,
            vessel: VesselID
        };
        /** Use setFieldValue to update the sensors array */
        AddSensorFormik.setFieldValue("sensor", [...sensors, newSensor]);
    }

    /**
    * To remove sensor from specific index
    * @param index removeble index
    */
    const removeSensor = async (index?: number) => {
        /** Get the current sensors */
        const sensors = AddSensorFormik?.values?.sensor || [];
        /** remove sensor from specific index */
        const updatedSensors = [...sensors];
        updatedSensors.splice(index, 1);
        /** Update the form field value */
        AddSensorFormik.setFieldValue("sensor", updatedSensors);
    }

    /** Open or close modal  */
    function handlePopoverState() {
        setState(!state)
        setFormRefreshKey(formRefreshKey + 1)
    }

    /** useEffect */
    useEffect(() => {
        if (editID) {
            setFormRefreshKey(formRefreshKey + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselSensor]);

    return (
        <Modal
            size="lg"
            isOpen={state}
            toggle={() => {
                handlePopoverState();
            }}
        >
            <div className="modal-header p-2">
                <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                    Add New Sensor
                </h5>
                <button
                    onClick={() => {
                        handlePopoverState();
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Card className='border-0 p-0'>
                    {(
                        VesselMachineriesLoading ||
                        SensorUnitMasterLoading ||
                        ReportingSensorMasterLoading ||
                        VesselSensorLoading
                    ) && <Loading message="Loading required data!" />}
                    {(
                        VesselMachineriesError ||
                        SensorUnitMasterError ||
                        ReportingSensorMasterError ||
                        VesselSensorError
                    ) && <ErrorComponent message="Unable to load required data!" />}
                    {!(
                        VesselMachineriesLoading ||
                        SensorUnitMasterLoading ||
                        ReportingSensorMasterLoading ||
                        VesselSensorLoading
                    ) &&
                        !(
                            VesselMachineriesError ||
                            SensorUnitMasterError ||
                            ReportingSensorMasterError ||
                            VesselSensorError
                        ) &&
                        <Form noValidate autoComplete='off' onSubmit={AddSensorFormik.handleSubmit} key={`add-sesnor-${formRefreshKey}`}>
                            <CardBody className='p-0'>
                                <Row className="mb-3">
                                    <Col>
                                        <Label className="asteric mb-0">
                                            Select Machinery
                                        </Label>
                                        <Select
                                            name="vessel_machinery"
                                            inputId="vessel_machinery"
                                            className="max-width-13 z-index-100"
                                            options={VesselMachineries}
                                            getOptionLabel={(e: any) => e.machinery}
                                            getOptionValue={(e: any) => e.id}
                                            onBlur={AddSensorFormik?.handleBlur}
                                            onChange={(e: any) => setSelectValueToAllFields(e.id, 'vessel_machinery', AddSensorFormik?.values?.sensor, AddSensorFormik, "sensor")}
                                            menuPortalTarget={document.body}
                                            styles={customStyle}
                                            defaultValue={editID && {
                                                id: AddSensorFormik?.values?.sensor[0]?.vessel_machinery,
                                                machinery: AddSensorFormik?.values?.sensor[0]?.vessel_machinery_name
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Label className="asteric mb-0">
                                            Select Sensors
                                        </Label>
                                        <Select
                                            name="reporting_sensor"
                                            inputId="reporting_sensor"
                                            className="max-width-13 z-index-100"
                                            options={ReportingSensorMaster}
                                            getOptionLabel={(e: any) => e.reporting_sensor_name}
                                            getOptionValue={(e: any) => e.id}
                                            onBlur={AddSensorFormik?.handleBlur}
                                            onChange={(e: any) => {
                                                setSelectValueToAllFields(e.id, 'reporting_sensor', AddSensorFormik?.values?.sensor, AddSensorFormik, "sensor")
                                                setRefreshKey(refreshKey + 1)
                                            }}
                                            menuPortalTarget={document.body}
                                            styles={customStyle}
                                            defaultValue={editID && {
                                                id: AddSensorFormik?.values?.sensor[0]?.reporting_sensor,
                                                reporting_sensor_name: AddSensorFormik?.values?.sensor[0]?.reporting_sensor_name
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Label className="asteric mb-0">
                                            Select Sensor Units
                                        </Label>
                                        <Select
                                            name="sensor_unit"
                                            inputId="sensor_unit"
                                            className="max-width-13 z-index-100"
                                            options={
                                                /** Filter SensorUnitMaster by matching IDs from ReportingSensorMaster */
                                                SensorUnitMaster && ReportingSensorMaster &&
                                                SensorUnitMaster?.filter((unit: any) =>
                                                    ReportingSensorMaster
                                                        ?.find((sensor: any) => sensor.id === AddSensorFormik?.values?.sensor[0]?.reporting_sensor)?.sensor_unit_master
                                                        ?.includes(unit.id)
                                                )
                                            }
                                            getOptionLabel={(e: any) => e.name}
                                            getOptionValue={(e: any) => e.id}
                                            onBlur={AddSensorFormik?.handleBlur}
                                            onChange={(e: any) => setSelectValueToAllFields(e.id, 'sensor_unit', AddSensorFormik?.values?.sensor, AddSensorFormik, "sensor")}
                                            menuPortalTarget={document.body}
                                            styles={customStyle}
                                            key={refreshKey}
                                            defaultValue={editID && {
                                                id: AddSensorFormik?.values?.sensor[0]?.sensor_unit,
                                                name: AddSensorFormik?.values?.sensor[0]?.sensor_name
                                            }}
                                        />
                                    </Col>
                                    <Col>
                                        <Label className="asteric mb-0">
                                            Unit
                                        </Label>
                                        <Input
                                            className='max-width-8'
                                            type="text"
                                            name="unit"
                                            onChange={(e: any) => setSelectValueToAllFields(e.target.value, 'unit', AddSensorFormik?.values?.sensor, AddSensorFormik, "sensor")}
                                            value={editID && AddSensorFormik?.values?.sensor[0]?.unit}
                                        />
                                    </Col>
                                </Row>
                                {AddSensorFormik?.values?.sensor?.map((sensor: any, index: any) => (
                                    <Row>
                                        <Col lg={4}>
                                            <Label className="asteric mb-0">
                                                Vessel Sensor Name
                                            </Label>
                                            <Input
                                                type="text"
                                                name="vessel_sensor_name"
                                                className='mb-2'
                                                value={AddSensorFormik?.values?.sensor[index]?.vessel_sensor_name}
                                                onChange={(e) => AddSensorFormik?.setFieldValue(`sensor.${index}.vessel_sensor_name`, e.target.value)}
                                            />
                                        </Col>
                                        {/* do not show add or delete button on edit mode */}
                                        {!editID &&
                                            <Col>
                                                {/* delete button */}
                                                <div className="ele_row1">
                                                    {AddSensorFormik.values?.sensor
                                                        ?.length === 1 ? null : (
                                                        <button
                                                            id={`DeleteSensorName${index}`}
                                                            type="button"
                                                            className="btn ml-1 mt-3"
                                                            onClick={() => {
                                                                setDeletePopOverState(true)
                                                                setDeleteIndex(index)
                                                            }}
                                                        >
                                                            <i
                                                                className="dripicons-trash icon_s18 cursor-pointer"
                                                            />
                                                        </button>
                                                    )}
                                                    {/* add button when there are more than one record */}
                                                    {index === AddSensorFormik.values?.sensor.length - 1 &&
                                                        (
                                                            <Button
                                                                type="button"
                                                                className="btn ml-1 mt-3"
                                                                color="primary"
                                                                onClick={() =>
                                                                    addNewSensor()
                                                                }
                                                            >
                                                                <i
                                                                    className="dripicons-plus icon_s18 navbar_menu"
                                                                />
                                                            </Button>
                                                        )}
                                                </div>
                                            </Col>
                                        }
                                    </Row>
                                ))}
                                {/* delete pop over */}
                                {deletePopOverState &&
                                    <PopOver
                                        target={`DeleteSensorName${deleteIndex}`}
                                        handleClick={() => removeSensor(deleteIndex)}
                                        message={`Are you sure you want to delete this vessel sensor name`}
                                        state={deletePopOverState}
                                        setState={setDeletePopOverState}
                                    />
                                }
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                        <Button
                                            type="submit"
                                            color='primary'
                                            className="btn_size4_6_cstm"
                                            disabled={AddSensorFormik.isSubmitting}
                                        >
                                            {AddSensorFormik.isSubmitting && <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />}
                                            Save
                                        </Button>
                                        <Button
                                            type="reset"
                                            color="danger"
                                            className="btn_size4_5_cstm"
                                            onClick={() => {
                                                AddSensorFormik.resetForm();
                                                setFormRefreshKey(formRefreshKey + 1)
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </Row>
                                <FormValuesDebug values={[AddSensorFormik.values, AddSensorFormik.errors, AddSensorFormik.initialValues]} />
                            </CardFooter>
                        </Form>
                    }
                </Card>
            </div>
        </Modal >
    )
}

export default AddSensorModal