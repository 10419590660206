import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button, Card, CardBody, CardFooter, Col, Input, Label, Row } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { loadCargoReliquificationWithkWh } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import { FormikProps, useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { queryClient } from 'react-query/queryClient';
import { useEffect } from 'react';
import { SpOpsConfigurationConstant } from 'shared/constants';
import isEqual from "fast-deep-equal";

interface CargoReliquificationType {
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    activeTab: any;
    setTabFormik?: (value: FormikProps<any>) => void;
    refreshForm:boolean;
    refreshVesselMachineries:number;
}

const CargoReliquification = ({
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    activeTab,
    setTabFormik,
    refreshForm,
    refreshVesselMachineries,
}: CargoReliquificationType) => {
    /** Retrieves the VesselID from the Redux store. */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /**
     * Fetches cargo reliquification data along with machinery kWh meter details using the `useQuery` hook.
     * It loads data specific to the vessel's ID and keeps the result in cache indefinitely (`staleTime: Infinity`).
     */
    const { data: CargoReliquification, isLoading: CargoReliquificationLoading, isError: CargoReliquificationError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CargoReliquificationWithkWhMachineries.key, VesselID],
            async () => { return await loadCargoReliquificationWithkWh(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /**
     * Determines the initial values for the cargo reliquification form based on the fetched data.
     * If there is any cargo reliquification data available, it is returned for use as initial values.
     */
    const getCargoReliquificationInitailValues = () => {
        if (CargoReliquification?.length > 0) {
            return CargoReliquification;
        }
    };

    /** Defines the Formik configuration object for handling the cargo reliquification form. */
    const CargoReliquificationFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            cargoReliquification: getCargoReliquificationInitailValues(),
        },
        onSubmit: () => {
            CargoReliquificationFormik.setSubmitting(false)
            CargoReliquificationFormik?.values?.cargoReliquification?.forEach((val: any) => {
                apiGlobal.put(`/special_operation_energy_setting/${val.id}/`, val)
                    .then(res => {
                        if (res.status === 200) {
                            successToast("Data saved successfully!");
                            queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquificationWithkWhMachineries.key);
                        }
                    })
                    .catch(err => {
                        if (errResponse.includes(err?.response?.status)) {
                            errorToast("Internal error occured, please contact the admin");
                        }
                    });
            })
        }
    });

    const tabName = 'Cargo Reliquification';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual =isEqual(values,initialValues); 
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
    };

    /**Start of UseEffect */
    useEffect(()=>{
        if(activeTab === SpOpsConfigurationConstant.SpOpsCargoReliquificationId && CargoReliquificationFormik.values !== CargoReliquificationFormik.initialValues){
            setTabFormik(CargoReliquificationFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },  [activeTab,  CargoReliquificationFormik]);
    useEffect(() => {
        handleFormikStateChange(CargoReliquificationFormik?.values, CargoReliquificationFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CargoReliquificationFormik?.values, CargoReliquificationFormik?.initialValues]);

    if (CargoReliquificationLoading) {
        return <Loading message='Loading required data!' />
    } else if (CargoReliquificationError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <>
                <Card className='border-0'>
                    {CargoReliquificationLoading && <Loading message="Loading required data!" />}
                    {CargoReliquificationError && <ErrorComponent message="Error loading required data!" />}
                    {!(CargoReliquificationLoading) && !(CargoReliquificationError) &&
                        <form onSubmit={CargoReliquificationFormik?.handleSubmit} noValidate autoComplete='off'>
                            <CardBody className='p-0'>
                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive p-0">
                                            <table className="table mb-2" key={`${refreshVesselMachineries}-${refreshForm}-cargo-reliquification`}>
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                        <th className='p-2 align-middle'>Machinery name</th>
                                                        <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <>
                                                        {CargoReliquificationFormik?.initialValues &&
                                                            CargoReliquificationFormik?.initialValues?.cargoReliquification?.map((cargoReliquification: any, index: number) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                        <td className='p-2 align-middle'>{cargoReliquification.vessel_machinery_ec_group_name}</td>
                                                                        <td className='p-2 align-middle text-center'>
                                                                            <div className="square-switch sqswitch mt-1">
                                                                                <Input
                                                                                    type="checkbox"
                                                                                    switch="none"
                                                                                    name={`cargoReliquification.${index}.cargo_reliquification_kwh_meter`}
                                                                                    id={`cargo_reliquification_kwh_meter_${index}`}
                                                                                    defaultChecked={CargoReliquificationFormik?.values?.cargoReliquification[index]?.cargo_reliquification_kwh_meter}
                                                                                    onChange={(e: any) => CargoReliquificationFormik?.setFieldValue(`cargoReliquification.${index}.cargo_reliquification_kwh_meter`, e.target.checked)}
                                                                                />
                                                                                <Label
                                                                                    htmlFor={`cargo_reliquification_kwh_meter_${index}`}
                                                                                    data-on-label="Yes"
                                                                                    data-off-label="No"
                                                                                    className="mb-0"
                                                                                ></Label>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3 mb-3'>
                                <Button type="submit" color='primary' className='justify_right' disabled={isConfigurationButtonDisabled(vessel)}>Save</Button>
                            </CardFooter>
                            <Row className='mt-2'>
                                <FormValuesDebug values={[CargoReliquificationFormik?.values, CargoReliquificationFormik?.errors, CargoReliquificationFormik.initialValues]} />
                            </Row>
                        </form>
                    }
                </Card>
            </>
        )
    }
}
export default CargoReliquification;