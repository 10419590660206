import { useFormik } from 'formik';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap'
import { AlertTypeConstant } from 'shared/constants'
import { getAuthData } from 'utils/auth/authUtils';

const Alert = ({
    alertMessage,
    alertType,
    handlePopUpState,
    fieldId,
    formTitle,
    generatedFor
}: any) => {
    /** State variables */
    const { VesselID, ReportID } = useSelector((state: RootState) => state.Reporting);
    /** State variables end */

    /** Assign initial values to the formik object */
    const getInitialValues = (): any => {
        return {
            alert_message: alertMessage,
            response_text: "",
            response_value: null,
            form_title: formTitle,
            vessel_reporting_information: ReportID > 0 ? ReportID : null,
            vessel: VesselID,
            user: getAuthData('user_id'),
            generated_for: generatedFor
        }
    }

    /** Alert's formik object */
    const AlertFormik = useFormik({
        initialValues: getInitialValues(),
        onSubmit: () => { },
        enableReinitialize: true,
    })

    /** useEffect */
    useEffect(() => {
        AlertFormik.initialValues = getInitialValues();
        AlertFormik.values = getInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alertMessage, formTitle, generatedFor, ReportID, VesselID]);
    /** useEffect end */

    /** Alert POST function */
    const alertSubmit = (responseValue: string) => {
        AlertFormik.values.response_value = responseValue;
        apiGlobal.post(`alert_details/`, AlertFormik.values).then(res => {
            if (res.status === 201) {
                handlePopUpState();
            }
        }).catch(err => {
            console.error(err);
        })
    }
    return (
        <Row>
            <Col>
                <p>{alertMessage}</p>
                {alertType === AlertTypeConstant.WITH_REASON &&
                    <textarea
                        className="form-control bg-white mb-2"
                        id="response_text"
                        name="response_text"
                        rows={4}
                        onChange={AlertFormik.handleChange}
                        onBlur={AlertFormik.handleChange}
                    />
                }
                {(alertType === AlertTypeConstant.INFO_ONLY) &&
                    <Button
                        onClick={() => alertSubmit("ok")}
                        type="button"
                        color="primary"
                        className="btn_size_cstm"
                    >
                        OK
                    </Button>
                }
                {(alertType === AlertTypeConstant.WITH_YES_NO_ACTION || alertType === AlertTypeConstant.WITH_REASON) &&
                    <div className="d-flex justify-content-center gap-3">
                        <Button
                            onClick={() => {
                                alertSubmit("yes")
                                document?.getElementById(fieldId)?.blur();
                            }}
                            type="button"
                            color="primary"
                            className="btn_size_cstm"
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={() => {
                                alertSubmit("no");
                                document?.getElementById(fieldId)?.focus();
                            }}
                            type="button"
                            color="danger"
                            className="btn_size_cstm"
                        >
                            No
                        </Button>
                    </div>
                }
            </Col>
        </Row>
    )
}

export default Alert