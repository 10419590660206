import React from "react";
import { Button, Card, Col, Form, Row } from "reactstrap";
import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../..';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadVesselLubeOils } from 'VesselMaster/vesselMaster.hooks';
import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { useState } from "react";
import CreateLubeOil from "./CreateLubeOil";
import PopUp from "Components/PopUp";
import DeletePopOver from "Components/DeletePopOver";
import { queryClient } from "react-query/queryClient";
import ToolTip from "Components/ToolTip";
import Message from "Components/Message";

const LubeOil = () => {
    /** State variables start */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [createNewBool, setCreateNewBool] = useState(false);
    const [deleteBool, setDeleteBool] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState<number>(null);
    const [hoverId, setHoverId] = useState<string | null>(null);
    /** State variables end */

    /** useQueries */
    const { data: vessellubeoilObject, isLoading: vessellubeoilObjectLoading, isError: vessellubeoilObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselLubeOils.key],
            async () => { return await loadVesselLubeOils(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** useQueries end */


    const getInitialValueVesselLubeOilForm = () => {
        if (vessellubeoilObject?.length > 0 && vessellubeoilObject[0].id > 0) {
            return vessellubeoilObject;
        } else {
            return [];
        }
    }

    const lubeoilFormik = {
        initialValues: {
            vessellubeoil: getInitialValueVesselLubeOilForm(),
        },
    };

    /** Function to delete vessel lube oil */
    const deleteVesselLubeOil = async () => {
        apiGlobal.delete(`/vessel_lube_oil_list/${vessellubeoilObject[deleteIndex].id}/`).then(res => {
            if (res.status === 200 || res.status === 204) {
                successToast("Data deleted successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.VesselLubeOils.key);
                setDeleteBool(false);
            }
        }).catch(err => {
            if (errResponse.includes(err.response.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }

    return (
        <React.Fragment>
            <Card className='border-0'>
                {vessellubeoilObjectLoading && <Loading message='Loading required data!' />}
                {vessellubeoilObjectError && <ErrorComponent message='Unable to load required data!' />}
                {!(vessellubeoilObjectLoading) && !(vessellubeoilObjectError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            const handleResponse = (response: any) => {
                                if (response.status === 200) {
                                    successToast("Data saved successfully!");
                                }
                            };
                            values.vessellubeoil.forEach((oil: any) => {
                                apiGlobal.put(`/vessel_lube_oil_list/${oil.id}/`, oil)
                                    .then(res => handleResponse(res))
                                    .catch(err => {
                                        if (err.response && errResponse.includes(err.response.status)) {
                                            errorToast("Internal error occurred, please contact the admin");
                                        }
                                    });
                            });
                        }}
                        initialValues={lubeoilFormik.initialValues}
                    >
                        {(props: any) => (
                            <Form onSubmit={props.handleSubmit} noValidate autoComplete='off'>
                                <Row className="mb-3">
                                    <Col sm={6}>
                                        <h4 className="mb-0 align-middle">Vessel Lube Oil List</h4>
                                    </Col>
                                    <Col sm={6}>
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn waves-effect waves-light justify_right"
                                            data-toggle="modal"
                                            data-target=".bs-example-modal-xl"
                                            onClick={() => {
                                                setCreateNewBool(true);
                                            }}
                                            disabled={isConfigurationButtonDisabled(vessel)}
                                        >
                                            <i className="dripicons-plus font-size-16 align-middle me-2"></i>
                                            Add New
                                        </Button>
                                    </Col>
                                </Row>
                                {createNewBool &&
                                    <PopUp
                                        state={createNewBool}
                                        setState={setCreateNewBool}
                                        body={<CreateLubeOil
                                            setCreateNewBool={setCreateNewBool} />}
                                        title="Add New"
                                        size="md"
                                    />
                                }
                                <Row>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="p-2 align-middle sr-no-width">#</th>
                                                    <th className="p-2 align-middle">Vessel lube oil name</th>
                                                    <th className="p-2 align-middle">Lube oil</th>
                                                    <th className="p-2 align-middle text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <React.Fragment>
                                                    {(vessellubeoilObject && vessellubeoilObject.length) > 0 || vessellubeoilObject.length === undefined ? (
                                                        vessellubeoilObject.map((lube: { [key: string]: string | number | boolean | Date }, index: number) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                    <td className='p-2 align-middle'>{lube?.vessel_lube_oil_name as string}</td>
                                                                    <td className='p-2 align-middle'>{lube?.lube_name as string}</td>
                                                                    <td className='p-2 align-middle text-center'>
                                                                        {isConfigurationButtonDisabled(vessel) ? (
                                                                            <i className='dripicons-trash icon_s18 pointer disabled-icon' />
                                                                        ) : (
                                                                            <i
                                                                                id={`Delete_Vessel_Lube_Oil_${index}`}
                                                                                className='dripicons-trash icon_s18 pointer'
                                                                                onMouseEnter={() => setHoverId(`Delete_Vessel_Lube_Oil_${index}`)}
                                                                                onMouseLeave={() => setHoverId(null)}
                                                                                onClick={() => {
                                                                                    setDeleteBool(true);
                                                                                    setDeleteIndex(index);
                                                                                    setHoverId(null);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={4} className="text-center">
                                                                <React.Fragment>
                                                                    {(vessellubeoilObject && vessellubeoilObject.length) > 0 || vessellubeoilObject.length === undefined ? (
                                                                        vessellubeoilObject.map((lube: { [key: string]: string | number | boolean | Date }, index: number) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                    <td className='p-2 align-middle'>{lube?.vessel_lube_oil_name as string}</td>
                                                                                    <td className='p-2 align-middle'>{lube?.lube_name as string}</td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        {isConfigurationButtonDisabled(vessel) ? (
                                                                                            <i className='dripicons-trash icon_s18 pointer disabled-icon' />
                                                                                        ) : (
                                                                                            <i
                                                                                                id={`Delete_Vessel_Lube_Oil_${index}`}
                                                                                                className='dripicons-trash icon_s18 pointer'
                                                                                                onMouseEnter={() => setHoverId(`Delete_Vessel_Lube_Oil_${index}`)}
                                                                                                onMouseLeave={() => setHoverId(null)}
                                                                                                onClick={() => {
                                                                                                    setDeleteBool(true);
                                                                                                    setDeleteIndex(index);
                                                                                                    setHoverId(null);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ) : (
                                                                        <tr>
                                                                            <td colSpan={4} className="text-center"> <Message message="No Lube Oil data available. Please configure the Lube Oil details." type="warning" width="40rem" /></td>
                                                                        </tr>
                                                                    )}
                                                                </React.Fragment>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>

                                                {deleteBool &&
                                                    <DeletePopOver
                                                        target={`Delete_Vessel_Lube_Oil_${deleteIndex}`}
                                                        state={deleteBool}
                                                        setState={setDeleteBool}
                                                        onClick={() => deleteVesselLubeOil()}
                                                    />
                                                }
                                                {hoverId !== null &&
                                                    <ToolTip
                                                        target={hoverId}
                                                        message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                                                        isOpen={hoverId !== null}
                                                    />
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </Row>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props.values, props.errors, lubeoilFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card >
            <p>- Select the types of lube oils used in this vessel.</p>
        </React.Fragment>
    );
}

export default LubeOil;

