import React, { useEffect, useState } from "react";
import ErrorComponent from "Components/ErrorComponent";
import Loading from "Components/Loading";
import { handleServerResponse } from "GenericForms/Helper";
import {
    loadPortActivities,
    loadPortActivitiesObject,
} from "VesselMaster/vesselMaster.hooks";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import apiGlobal from "global/api.global";
import { useQuery } from "react-query";
import {
    Row,
    Col,
    Label,
    Input,
    Button,
    Form,
    CardHeader,
    Card,
    CardBody,
    CardFooter,
} from "reactstrap";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import env from "environment_system/env_system";
import { queryClient } from "react-query/queryClient";

interface PortActivitiesComponentType {
    activeAddTab: number;
    toggleAddTab: any;
    VesselID: number;
    VoyageID: number;
    ReportID: number;
    setErrorMessage: any;
}

const PortActivitiesComponent = ({
    activeAddTab,
    toggleAddTab,
    VesselID,
    VoyageID,
    ReportID,
    setErrorMessage
}: PortActivitiesComponentType) => {
    /** State variables start **/
    const [refreshKey, setRefreshKey] = useState(0);
    /** State variables end **/

    /** Queries */
    /** Load Port Activities */
    const {
        data: PortActivities,
        isLoading: PortActivitiesLoading,
        isError: PortActivitiesError,
    } = useQuery(
        [queryKeyes.masters.PortActivities.key],
        async () => {
            return await loadPortActivities();
        },
        { staleTime: Infinity }
    );
    /** Load Port Activities' object used for edit */
    const {
        data: PortActivitiesObject,
        isLoading: PortActivitiesObjectLoading,
        isError: PortActivitiesObjectError,
    } = useQuery(
        [queryKeyes.vessel.LfondsPortActivitiesObject.key, VesselID, ReportID],
        async () => {
            return await loadPortActivitiesObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to initial object of Port Activities */
    const getInitialValues = () => {
        let obj: any[] = [];
        if (PortActivitiesObject?.length > 0 && PortActivitiesObject[0]?.id > 0) {
            return PortActivitiesObject;
        } else {
            if (
                obj !== undefined &&
                PortActivities !== undefined &&
                obj <= PortActivities.length
            ) {
                PortActivities.map((activity: any) => {
                    obj.push({
                        is_port_activity: false,
                        comment: "",
                        port_activity_name: activity.port_activity_name,
                        port_activity: activity.id,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                    });
                    return "";
                });
            }
            return obj;
        }
    };

    /** Port Activities' formik object */
    const PortActivitiesFormik = {
        initialValues: {
            portActivities: getInitialValues(),
        },
    };

    useEffect(() => {
        PortActivitiesFormik.initialValues.portActivities = getInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PortActivitiesObject]);

    return (
        <Card className="p-0 mb-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Port Activities</h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(PortActivitiesLoading || PortActivitiesObjectLoading) && (
                <Loading message="Loading required data!" />
            )}
            {PortActivitiesError && (
                <ErrorComponent message="Unable to load required data!" />
            )}
            {PortActivitiesObjectError && getInitialValues()}
            {!PortActivitiesLoading && !PortActivitiesError && (
                <Formik
                    onSubmit={async (values: any, actions: any) => {
                        if (env?.form_validation === false) {
                            setErrorMessage(null)
                            toggleAddTab(activeAddTab + 1);
                        }
                        actions.setSubmitting(true);
                        let responseArray: any = [];
                        if (PortActivitiesObject && PortActivitiesObject.length > 0) {
                            values.portActivities.forEach((val: any) => {
                                responseArray.push(apiGlobal.put(`/lfonds_port_activity_detail/${val.id}/`, val));
                            })
                        } else {
                            responseArray.push(apiGlobal.post(`/lfonds_port_activity_detail/`, values.portActivities));
                        }
                        await handleServerResponse(responseArray).then(async (res: any) => {
                            if (res === true) {
                                await queryClient.invalidateQueries(queryKeyes.vessel.LfondsPortActivitiesObject.key);
                                setRefreshKey(refreshKey + 1);
                                if (env?.form_validation === true) {
                                    setErrorMessage(null)
                                    toggleAddTab(activeAddTab + 1);
                                }
                            } else {
                                setErrorMessage(res)
                            }
                        })
                        actions.setSubmitting(false);
                    }}
                    initialValues={PortActivitiesFormik.initialValues}
                    key={refreshKey}
                >
                    {({
                        values,
                        errors,
                        handleSubmit,
                        handleChange,
                        setErrors,
                        isSubmitting
                    }: {
                        values: any;
                        errors: any;
                        handleSubmit: any;
                        handleChange: any;
                        setErrors: any;
                        isSubmitting: any;
                    }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className="px-2 py-0 mt-2">
                                <FieldArray name="portActivities">
                                    {() => (
                                        <React.Fragment>
                                            <Row>
                                                <Col sm={6}>
                                                    {values && values?.portActivities?.filter((_item: any, index: number) => index <= 6)?.map(
                                                        (activity: any, index: number) => {
                                                            return (
                                                                <Row key={index} className="mb-1">
                                                                    <Col lg={4} className="mt-4">
                                                                        <Label className="mb-0 mt-3" htmlFor={`port_activity_${index}`}>{activity.port_activity_name}</Label>
                                                                    </Col>
                                                                    <Col lg={2} className="mt-4">
                                                                        <Field
                                                                            name={`portActivities.${index}.is_port_activity`}
                                                                        >
                                                                            {({ field }: FieldProps) => (
                                                                                <div className="square-switch sqswitch mt-3">
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        switch="none"
                                                                                        name={field.name}
                                                                                        id={`port_activity_${index}`}
                                                                                        onChange={(e: any) =>
                                                                                            handleChange(e)
                                                                                        }
                                                                                        defaultChecked={
                                                                                            values?.portActivities[index]
                                                                                                .is_port_activity
                                                                                        }
                                                                                    />
                                                                                    <Label
                                                                                        htmlFor={`port_activity_${index}`}
                                                                                        data-on-label="Yes"
                                                                                        data-off-label="No"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Col>
                                                                    {values &&
                                                                        values?.portActivities[index]
                                                                            .is_port_activity === true && (
                                                                            <Col lg={6}>
                                                                                <Field
                                                                                    as="textarea"
                                                                                    name={`portActivities.${index}.comment`}
                                                                                    className="form-control"
                                                                                    rows={4}
                                                                                />
                                                                            </Col>
                                                                        )}
                                                                </Row>
                                                            )
                                                        }
                                                    )}
                                                </Col>
                                                <Col sm={6}>
                                                    {values && values?.portActivities?.filter((_item: any, index: number) => index > 6)?.map(
                                                        (activity: any, index: number) => {
                                                            return (
                                                                <Row key={index} className="mb-1">
                                                                    <Col lg={4} className="mt-4">
                                                                        <Label className="mb-0 mt-3" htmlFor={`port_activity_${values?.portActivities?.indexOf(activity)}`}>{activity.port_activity_name}</Label>
                                                                    </Col>
                                                                    <Col lg={2} className="mt-4">
                                                                        <Field
                                                                            name={`portActivities.${values?.portActivities?.indexOf(activity)}.is_port_activity`}
                                                                        >
                                                                            {({ field }: FieldProps) => (
                                                                                <div className="square-switch sqswitch mt-3">
                                                                                    <Input
                                                                                        type="checkbox"
                                                                                        switch="none"
                                                                                        name={field.name}
                                                                                        id={`port_activity_${values?.portActivities?.indexOf(activity)}`}
                                                                                        onChange={(e: any) =>
                                                                                            handleChange(e)
                                                                                        }
                                                                                        defaultChecked={
                                                                                            values?.portActivities[values?.portActivities?.indexOf(activity)]
                                                                                                .is_port_activity
                                                                                        }
                                                                                    />
                                                                                    <Label
                                                                                        htmlFor={`port_activity_${values?.portActivities?.indexOf(activity)}`}
                                                                                        data-on-label="Yes"
                                                                                        data-off-label="No"
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Col>
                                                                    {values &&
                                                                        values?.portActivities[values?.portActivities?.indexOf(activity)]
                                                                            .is_port_activity === true && (
                                                                            <Col lg={6}>
                                                                                <Field
                                                                                    as="textarea"
                                                                                    name={`portActivities.${values?.portActivities?.indexOf(activity)}.comment`}
                                                                                    className="form-control"
                                                                                    rows={4}
                                                                                />
                                                                            </Col>
                                                                        )}
                                                                </Row>
                                                            )
                                                        }
                                                    )}
                                                </Col>
                                            </Row>
                                        </React.Fragment>
                                    )}
                                </FieldArray>
                            </CardBody>
                            <CardFooter className="p-2">
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5 ">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting &&
                                                <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                                            }
                                            Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn_size_cstm"
                                            onClick={() => {
                                                setErrors({})
                                                errors = {};
                                                setErrorMessage(null)
                                                toggleAddTab(activeAddTab - 1);
                                            }}
                                        >
                                            <i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug
                                values={[values, errors, PortActivitiesFormik.initialValues]}
                            />
                        </Form>
                    )}
                </Formik>
            )}
        </Card>
    );
};

export default PortActivitiesComponent;
