import React, { useState, useEffect } from 'react';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal from 'global/api.global';
import { queryKeyes } from 'shared/queryKeys';
import { clearAuthKey, decrypt, encrypt } from 'GenericForms/Helper';
import DisplayPassword from './DisplayPassword';
import { getAuthData } from 'utils/auth/authUtils';

interface TempPasswordType {
    userID: number;
    userName: string;
    setState: (state: boolean) => void;
}

const TempPassword = ({ userID, userName, setState }: TempPasswordType) => {
    const [authenticated] = useState<boolean>(
        localStorage.getItem("Authorization_key") !== null
    );
    const [password, setPassword] = useState<string>("");
    const [PasswordType, setPasswordType] = useState<'password' | 'text'>('password');
    const [tempPassword, setTempPassword] = useState<string>("");
    const [display, setDisplay] = useState<boolean>(false);

    const HandelAuth = async (password: string) => {
        if (password.length < 8) {
            errorToast("Password should be atleast 8 characters long");
            return;
        }
        const encryptedPassword = encrypt(password);
        await apiGlobal.post(queryKeyes.user.view_temp_password.url(), {
            user_id: userID,
            password: encryptedPassword
        }).then((res) => {
            if (res.status === 200) {
                setTempPassword(decrypt(res.data.temp_password));
                localStorage.setItem("Authorization_key", res?.data?.authorization_key);
                sessionStorage.setItem("Authorization_key", res?.data?.authorization_key);
                successToast("User authenticated successfully");
                setDisplay(true);
                clearAuthKey();
            }
        }).catch((err) => {
            errorToast("Error occurred while authenticating" + err?.response?.data?.error);
        });
    };


    useEffect(() => {
        if (authenticated) {
            const key = localStorage.getItem("Authorization_key");
            apiGlobal.post(queryKeyes.user.view_temp_password.url(), {
                user_id: userID,
                authorization_key: key,
            })
                .then((res) => {
                    if (res.status === 200) {
                        setTempPassword(decrypt(res.data.temp_password));
                        localStorage.setItem("Authorization_key", res.data.authorization_key);
                        sessionStorage.setItem("Authorization_key", res.data.authorization_key);
                        clearAuthKey();
                        successToast("Temporary password fetched successfully");
                        setDisplay(true);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    errorToast(err?.response?.data?.message || "Error occurred while fetching temporary password");
                });
        }
    }, [authenticated, userID]);


    if (display) {
        return (
            <React.Fragment>
                <DisplayPassword userName={userName} tempPassword={tempPassword} setState={setState} />
            </React.Fragment >
        )
    }
    const user = getAuthData('first_name');
    return (
        <div className="p-2">
            {authenticated ? (
                <DisplayPassword userName={userName} tempPassword={tempPassword} setState={setState} />
            ) : (
                <React.Fragment>
                    <div className="text-left">
                        <h5>Hi {user} ,</h5>
                        <h6> Authenticate Yourself</h6>
                    </div>
                    <Row className="align-items-center d-flex">
                        <Col>
                            <Input
                                type={PasswordType}
                                placeholder="Enter your password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <i
                                className={`mdi ${PasswordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'} eye mr-1`}
                                onClick={() => setPasswordType(PasswordType === 'password' ? 'text' : 'password')}
                            />
                        </Col>
                        <Col xs="auto">
                            <Button color="primary" size="sm" onClick={() => HandelAuth(password)} style={{ height: '38px' }}>
                                Authenticate
                            </Button>
                        </Col>
                    </Row>
                    <Label className='mt-2'> <b>[Note]</b> To view the temporary password  of the user you need to authenticate with your password  </Label>
                </React.Fragment>
            )}
        </div>
    );
};

export default TempPassword;