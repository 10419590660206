import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label,
    Button,
    Form,
    Input,
} from "reactstrap";
import * as Yup from "yup";
import { FormikProps, useFormik } from "formik";
import apiGlobal from "../../global/api.global";
import { useSelector } from "react-redux";
import { RootState } from "index";
import env from 'environment_system/env_system'
import ErrorTooltip from "Components/ErrorTooltip";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { loadSatelliteCommByVessel } from "VesselMaster/vesselMaster.hooks";
import Loading from "Components/Loading";
import { handleServerResponse, isConfigurationButtonDisabled } from "GenericForms/Helper";
import { queryClient } from "react-query/queryClient";
import { VesselConfigrationTabs, VesselDataConstants } from "shared/constants";
import PhoneInput from "react-phone-input-2";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import isEqual from "fast-deep-equal";

interface SatelliteCommunicationType {
    activeTab?: number | string,
    VesselConfActiveTab?: number,
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    setTabFormik?: (value: FormikProps<any>) => void;
    setDisableYesButtonInsideTab?: (value:boolean) => void;
    saveBeforeChangePopUpInsideTab?: boolean;
}

const SatelliteCommunication = ({
    activeTab,
    VesselConfActiveTab,
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    setTabFormik,
    setDisableYesButtonInsideTab,
    saveBeforeChangePopUpInsideTab,
}: SatelliteCommunicationType) => {
    /** State variables start */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [countryCode, setCountryCode] = useState<string>('');
    const [refreshKey, setRefreshKey] = useState<number>(0);
    /** State varibles end */

    /** Queries */
    /** Sate communication object used for edit */
    const { data: satelliteComm, isLoading: satelliteCommLoading } = useQuery(
        [queryKeyes.vessel.SatelliteCommunicationByVessel.key, VesselID],
        async () => {
            return await loadSatelliteCommByVessel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign initial values to Satellite communication's formik object */
    const getInitialValues = () => {
        if (satelliteComm && satelliteComm[0]?.id > 0) {
            return satelliteComm[0];
        } else {
            return ({
                email: "",
                phone_bridge: "",
                phone_master: "",
                fax: "",
                immrst_c1: "",
                immrst_c2: "",
                mmmsi: "",
                vessel: VesselID,
            })
        }
    }

    /** Satellite communication's formik object */
    const SatelliteCommFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            email: Yup.string()
                .email(commonValidationMessages.email)
                .required(commonValidationMessages.required),
            phone_bridge: Yup.string()
                .test('required', commonValidationMessages.required, (value) => {
                    return !(`+${countryCode} ` === value)
                }),
            // .matches(/^[0-9]{1,14}$/, "Enter upto 14 digits only")
            phone_master: Yup.string().nullable(),
            // .matches(/^[0-9]{1,14}$/, "Enter upto 14 digits only"),
            fax: Yup.string().nullable(),
            // .matches(/^[0-9]{1,14}$/, "Please enter upto 14 digits only"),
            immrst_c1: Yup.string().nullable(),
            // .matches(
            //     /^[4][0-9]{1,9}$/,
            //     "Please enter 9 upto digits only and the first digit should be 4"
            // ),
            immrst_c2: Yup.string().nullable(),
            // .matches(
            //     /^[4][0-9]{1,9}$/,
            //     "Please enter 9 upto digits only and the first digit should be 4"
            // ),
            mmmsi: Yup.string().nullable(),
            // .matches(/^[0-9]{1,9}$/, "Please enter upto 9 digits only")
        }),
        onSubmit: async () => {
            SatelliteCommFormik.setSubmitting(true);
            submitSatelliteComm();
        }
    });

    /** Satellite communication's submit function */
    const submitSatelliteComm = async () => {
        const responseArray: any = [];
        if (satelliteComm && satelliteComm[0]?.id > 0) {
            responseArray.push(apiGlobal
                .put(`/${queryKeyes.vessel.SatelliteCommunication.url()}${SatelliteCommFormik?.values?.id}/`, SatelliteCommFormik?.values))
        } else {
            responseArray.push(apiGlobal
                .post(`/${queryKeyes.vessel.SatelliteCommunication.url()}`, SatelliteCommFormik?.values))
        }
        await handleServerResponse(responseArray).then(async (res) => {
            if (res === true) {
                await queryClient.invalidateQueries(queryKeyes.vessel.SatelliteCommunicationByVessel.key);
                setRefreshKey(refreshKey + 1);
            }
            SatelliteCommFormik?.setSubmitting(false);
        })
    }

    const tabName = 'Satellite Communication';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = isEqual(values, initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
    };

    /**Start of UseEffect*/
    useEffect(() => {
        if (activeTab === VesselDataConstants.SATELLITE_COMMUNICATION && SatelliteCommFormik.values !== SatelliteCommFormik.initialValues) {
            Object.keys(SatelliteCommFormik?.errors).length > 0? setDisableYesButtonInsideTab(true) : setDisableYesButtonInsideTab(false);
            setTabFormik(SatelliteCommFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, SatelliteCommFormik,SatelliteCommFormik?.errors]);
    useEffect(() => {
        if (setCheckValuesBeforeSwitch) {
            handleFormikStateChange(SatelliteCommFormik?.values, SatelliteCommFormik?.initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SatelliteCommFormik?.values, SatelliteCommFormik?.initialValues]);
    useEffect(() => {
        SatelliteCommFormik.initialValues = getInitialValues();
        SatelliteCommFormik.values = getInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [satelliteComm, VesselID])
    useEffect(() => {
        if (VesselConfActiveTab === VesselConfigrationTabs.VESSEL_DATA && activeTab !== "2") {
            SatelliteCommFormik?.setTouched({})
            SatelliteCommFormik?.setErrors({})
        }
        if (VesselConfActiveTab !== VesselConfigrationTabs.VESSEL_DATA) {
            SatelliteCommFormik?.setTouched({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, VesselConfActiveTab]);
    /**End of UseEffect*/

    return (
        <React.Fragment>
            {satelliteCommLoading && <Loading message="Loading required data!" />}
            {!satelliteCommLoading &&
                <Form noValidate autoComplete="off" onSubmit={SatelliteCommFormik?.handleSubmit} key={refreshKey}>
                    <Row className="mb-2">
                        <Col lg={4}>
                            <Label className="asteric mb-0" htmlFor="email">Email ID</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                value={SatelliteCommFormik.values.email}
                                onChange={SatelliteCommFormik.handleChange}
                                onBlur={SatelliteCommFormik.handleBlur}
                            />
                            {env?.form_validation && SatelliteCommFormik.touched.email && SatelliteCommFormik.errors.email && (
                                <ErrorTooltip
                                    target="email"
                                    message={SatelliteCommFormik.errors.email}
                                    open={!saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.SATELLITE_COMMUNICATION &&
                                          Boolean(SatelliteCommFormik.errors.email)}
                                />
                            )}
                        </Col>
                        <Col lg={4}>
                            <Label htmlFor="phone_bridge" className="asteric mb-0">Phone Bridge</Label>
                            <PhoneInput
                                containerStyle={{ width: '100%' }}
                                inputStyle={{ width: '100%' }}
                                country="in"
                                value={SatelliteCommFormik.values.phone_bridge}
                                onChange={(value: string) => {
                                    SatelliteCommFormik.setFieldValue('phone_bridge', value);
                                }}
                                onBlur={(e: any, country: any) => {
                                    SatelliteCommFormik.handleBlur(e);
                                    setCountryCode(country.dialCode);
                                    const phoneNumber = e.target.value.substring(country.dialCode.length + 2);
                                    SatelliteCommFormik.setFieldValue('phone_bridge', `+${country.dialCode} ${phoneNumber}`);
                                    e.target.id = "phone_bridge";
                                }}
                                placeholder=""
                            />
                            {env?.form_validation && SatelliteCommFormik.touched.phone_bridge && SatelliteCommFormik.errors.phone_bridge && (
                                <ErrorTooltip
                                    target="phone_bridge"
                                    message={SatelliteCommFormik.errors.phone_bridge}
                                    open={!saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.SATELLITE_COMMUNICATION && Boolean(SatelliteCommFormik.errors.phone_bridge)}
                                />
                            )}
                        </Col>
                        <Col lg={4}>
                            <Label htmlFor="phone_master" className="mb-0">Phone Master</Label>
                            <PhoneInput
                                containerStyle={{ width: '100%' }}
                                inputStyle={{ width: '100%' }}
                                country="in"
                                value={SatelliteCommFormik.values.phone_master}
                                onChange={(value: string) => {
                                    SatelliteCommFormik.setFieldValue('phone_master', value);
                                }}
                                onBlur={(e: any, country: any) => {
                                    SatelliteCommFormik.handleBlur(e);
                                    const phoneNumber = e.target.value.substring(country.dialCode.length + 2);
                                    SatelliteCommFormik.setFieldValue('phone_master', `+${country.dialCode} ${phoneNumber}`);
                                    e.target.id = "phone_master";
                                }}
                                placeholder=""
                            />
                            {env?.form_validation && SatelliteCommFormik.touched.phone_master && SatelliteCommFormik.errors.phone_master && (
                                <ErrorTooltip
                                    target="phone_master"
                                    message={SatelliteCommFormik.errors.phone_master}
                                    open={!saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.SATELLITE_COMMUNICATION && Boolean(SatelliteCommFormik.errors.phone_master)}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col lg={4}>
                            <Label htmlFor="fax" className="mb-0">Fax</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="fax"
                                name="fax"
                                onChange={SatelliteCommFormik.handleChange}
                                onBlur={SatelliteCommFormik.handleBlur}
                                value={SatelliteCommFormik.values.fax}
                            />
                            {env?.form_validation && SatelliteCommFormik.touched.fax && SatelliteCommFormik.errors.fax && (
                                <ErrorTooltip
                                    target="fax"
                                    message={SatelliteCommFormik.errors.fax}
                                    open={!saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.SATELLITE_COMMUNICATION && Boolean(SatelliteCommFormik.errors.fax)}
                                />
                            )}
                        </Col>
                        <Col lg={4}>
                            <Label htmlFor="immrst_c1" className="mb-0">Immrst.C1</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="immrst_c1"
                                name="immrst_c1"
                                onChange={SatelliteCommFormik.handleChange}
                                onBlur={SatelliteCommFormik.handleBlur}
                                value={SatelliteCommFormik.values.immrst_c1}
                            />
                            {env?.form_validation && SatelliteCommFormik.touched.immrst_c1 && SatelliteCommFormik.errors.immrst_c1 && (
                                <ErrorTooltip
                                    target="immrst_c1"
                                    message={SatelliteCommFormik.errors.immrst_c1}
                                    open={ !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.SATELLITE_COMMUNICATION && Boolean(SatelliteCommFormik.errors.immrst_c1)}
                                />
                            )}
                        </Col>
                        <Col lg={4}>
                            <Label htmlFor="immrst_c2" className="mb-0">Immrst.C2</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="immrst_c2"
                                name="immrst_c2"
                                onChange={SatelliteCommFormik.handleChange}
                                onBlur={SatelliteCommFormik.handleBlur}
                                value={SatelliteCommFormik.values.immrst_c2}
                            />
                            {env?.form_validation && SatelliteCommFormik.touched.immrst_c2 && SatelliteCommFormik.errors.immrst_c2 && (
                                <ErrorTooltip
                                    target="immrst_c2"
                                    message={SatelliteCommFormik.errors.immrst_c2}
                                    open={ !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.SATELLITE_COMMUNICATION && Boolean(SatelliteCommFormik.errors.immrst_c2)}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className="mb-2">
                        <Col lg={4}>
                            <Label htmlFor="mmmsi" className="mb-0">MMSI</Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="mmmsi"
                                name="mmmsi"
                                onChange={SatelliteCommFormik.handleChange}
                                onBlur={SatelliteCommFormik.handleBlur}
                                value={SatelliteCommFormik.values.mmmsi}
                            />
                            {env?.form_validation && SatelliteCommFormik.touched.mmmsi && SatelliteCommFormik.errors.mmmsi && (
                                <ErrorTooltip
                                    target="mmmsi"
                                    message={SatelliteCommFormik.errors.mmmsi}
                                    open={ !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.SATELLITE_COMMUNICATION && Boolean(SatelliteCommFormik.errors.mmmsi)}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <div className="d-flex flex-wrap gap-5 grp_justify_right">
                            <Button
                                type="submit"
                                color="primary"
                                className="btn_size4_5_cstm"
                                disabled={isConfigurationButtonDisabled(vessel)}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                color="danger"
                                className="btn_size4_5_cstm"
                                onClick={() => {
                                    SatelliteCommFormik.resetForm();
                                }}
                                disabled={isConfigurationButtonDisabled(vessel)}
                            >
                                Reset
                            </Button>
                        </div>
                    </Row>
                    <FormValuesDebug values={[SatelliteCommFormik?.values, SatelliteCommFormik?.errors, SatelliteCommFormik?.touched, SatelliteCommFormik.initialValues]} />
                </Form>
            }
        </React.Fragment>
    );
};

export default SatelliteCommunication;
