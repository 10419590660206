import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import ReportingFileUpload from 'Components/ReportingFileUpload';
import { errorToast, successToast } from 'Components/Toasts';
import { checkInvalidPrimaryKey, errResponse } from 'GenericForms/Helper';
import { apiMedia } from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import { Button, CardBody, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { queryKeyes } from 'shared/queryKeys';
import { GetPageWithID } from '../pendingpage.hook';
import PageNavButton from '../PageNavButton';
import { VesselTypeConstant } from 'shared/constants';


const ReferContainer: React.FC = () => {
    const { Vessels, VesselID } = useSelector((state: RootState) => state.Reporting);
    const [modalState, setModalState] = useState(false);
    const [fileObject, setFileObject] = useState<any>();
    const [pageUrl, setPageUrl] = useState("");
    const [file, setFile] = useState<any>([{}])
    const [update, setUpdate] = useState<boolean>(false);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /** get pending reefer container records with pagination */

    useEffect(() => {
        if (!checkInvalidPrimaryKey(VesselID)) {
            return;
        }
        if (vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP || vessel?.vessel_type === VesselTypeConstant.CONTAINER) {
            setPageUrl(queryKeyes.vessel.PendingReeferContainer.url(VesselID));
        }
    }, [VesselID, vessel?.vessel_type]);

    const { data: PendingReeferContainerObj, isLoading, isError } = useQuery(
        [queryKeyes.vessel.PendingReeferContainer.key, pageUrl, VesselID],
        async () => await GetPageWithID(VesselID, pageUrl),
    );

    /** Queries end */
    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }
    function tog_backdrop() {
        setModalState(!modalState);
        removeBodyCss();
    }

    /**
    * set modal true to upload file
    * set current object to setFileObject
    * @param file 
    * @param obj 
    */
    const handleUploadButtonClick = (obj: any) => {
        setModalState(!modalState);
        setFileObject(obj);
    }

    /** to upload CII Files */
    const PendingFileUploadSubmit = async () => {
        if (fileObject && file && file.length > 0) {
            fileObject.hasOwnProperty('log_book_upload') ?
                fileObject.log_book_upload = file[0].file :
                fileObject.baplie_file = file[0].file
            apiMedia.patch(`/reefer_container_details/${fileObject.id}/`, fileObject)
                .then(async res => {
                    if (res.status === 200) {
                        await queryClient.invalidateQueries(queryKeyes.vessel.PendingReeferContainer.key);
                        setUpdate(!update);
                        setModalState(!modalState);
                        setFile([{}])
                        successToast("Success! Files uploaded!");
                    }
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        setFile([{}])
                        errorToast("Internal error occured, please contact the admin");
                    }
                })
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={4}>
                    {isLoading && <Loading message='Loading required data!' />}
                </Col>
                <Col lg={4}>
                    {isError && < ErrorComponent message={'Unable to load required data!'} />}
                </Col>
            </Row>
            {(!isLoading && !isError) &&
                <CardBody className="p-0 pb-0 mt-2">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th className="p-2 align-middle sr-no-width">#</th>
                                    <th className="p-2 align-middle" style={{ width: '22%' }}>
                                        Date & Time (Local)
                                    </th>
                                    <th className="p-2 align-middle">Name  of Report</th>
                                    <th className="p-2 align-middle d-flex justify-content-between">
                                        <span>Documents</span>
                                        <span className='mr-2'>Action</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {PendingReeferContainerObj && PendingReeferContainerObj.length === 0 ? (
                                    <tr>
                                        <td colSpan={5} className="align-middle text-center">
                                            No pending files to upload.
                                        </td>
                                    </tr>
                                ) : (
                                    PendingReeferContainerObj && PendingReeferContainerObj.results.map((report: any, index: any) => (
                                        <React.Fragment key={report.id}>
                                            {/* Main Row */}
                                            <tr>
                                                <td className="align-middle p-2 text-center">{index + 1}</td>
                                                <td className="align-middle p-2">{report.report_date_time}</td>
                                                <td className="align-middle p-2">{report.name_of_report}</td>
                                                <td className="align-middle p-0" colSpan={2}>
                                                    {/* Nested Table for Files */}
                                                    <table className="table-bordered width-100">
                                                        <tbody>
                                                            {report.files.map((file: any, fileIndex: any) => (
                                                                <tr key={fileIndex}>
                                                                    <td
                                                                        className="align-middle p-2"
                                                                        style={{ width: '70%' }}
                                                                    >
                                                                        {file.hasOwnProperty('log_book_upload') ? 'Log Book' : 'Baplie file'}
                                                                    </td>
                                                                    <td className="align-middle p-2 text-center">
                                                                        <Button
                                                                            type="button"
                                                                            className="btn-sm"
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                handleUploadButtonClick(file)
                                                                            }
                                                                        >
                                                                            Upload
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                )}
                            </tbody>
                            <tfoot>
                                {PendingReeferContainerObj?.results?.length > 0 &&
                                    <tr>
                                        <td colSpan={5} className="p-2 ">
                                            <PageNavButton setPageUrl={setPageUrl} pageobj={PendingReeferContainerObj} pageUrl={pageUrl} />
                                        </td>
                                    </tr>
                                }
                            </tfoot>
                        </table>
                    </div>
                </CardBody>
            }
            <Modal
                size='md'
                isOpen={modalState}
                toggle={() => tog_backdrop()}
                backdrop={"static"}
                id="staticBackdrop"
            >
                <ModalHeader className='p-2'>Upload Pending CII Adjustment File
                    <button
                        onClick={async () => {
                            setModalState(false);
                            setFile([{}])
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className='p-2'>
                    <ReportingFileUpload
                        setFile={setFile}
                        file={file}
                        index={0}
                        sm={5}
                    />
                    <div className="mt-1 mb-5">
                        <Button type="submit" color='primary' className='pos-end' onClick={async () => {
                            setModalState(false)
                            await PendingFileUploadSubmit()
                        }}>Save</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

export default ReferContainer;
