import React, { useEffect } from 'react'
import { getInputs } from '../DeckOfficer.model';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { vesselFuel, loadLastCPWarrantiesFuelOptions, loadCurrentCPWarrantiesFuelOptions, loadSpeedConsumptionObject } from 'VesselMaster/vesselMaster.hooks';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import * as Yup from "yup";
import { dateTimeFormat, handleServerResponse } from 'GenericForms/Helper';
import env from 'environment_system/env_system';
import apiGlobal from 'global/api.global';
import { useFormik } from 'formik';
import { queryClient } from 'react-query/queryClient';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import ErrorTooltip from 'Components/ErrorTooltip';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { AlertColourTypeConstant, AlertTypeConstant, FUELLCV, FuelTypes } from "../../../shared/constants";
import { useState } from "react";
import AlertPopup from "Alerts/AlertPopup";
import { AlertMessages } from "Alerts/AlertMessages";
import { calculateTotalDOeq } from 'Simulator/CIIProjection/CommonFunctions';
import isEqual from "fast-deep-equal";
import DeletePopOver from 'Components/DeletePopOver';
import { useDocumentTitle } from 'Components/useDocument.hooks';
interface CPFormType {
    VesselID: number;
    VoyageID: number;
    ReportID: number;
    toggleTab: any;
    record: any;
    activeTab: any;
    lastRecord: any;
    vessel: any;
    setErrorMessage: any
}

const CpWarrantyComponent = ({ VesselID, VoyageID, ReportID,
    toggleTab, record, activeTab, lastRecord, setErrorMessage
}: CPFormType) => {
    // state variables for the CPWarraanties
    useDocumentTitle("CP Warranties Report - ecoSAIL");
    let { inputs } = getInputs("cp_warranties");
    const [alertBool, setAlertBool] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>(null);
    const [alertColourType, setAlertColourType] = useState<any>(null);
    const [alertType, setAlertType] = useState<string>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [saveBeforeChangePopUp, setStateSaveBeforeChangePopUp] = useState<boolean>(false);

    const {
        data: fuelTypes,
        isLoading: fuelTypeLoading,
        isError: isFuelTypeError,
    } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    const {
        data: lastCPWarranty,
        isLoading: lastCPWarrantyLoading,
        isError: lastCPWarrantyError,
    } = useQuery(
        [queryKeyes.vessel.lastCPWarranties.key, VesselID, ReportID],
        async () => {
            return await loadLastCPWarrantiesFuelOptions(VesselID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /** current CP warranty */
    const {
        data: currentCPWarranty,
        isLoading: currentCPWarrantyLoading,
        isError: currentCPWarrantyError,
    } = useQuery(
        [queryKeyes.vessel.currentCPWarranties.key, VesselID, ReportID],
        async () => {
            return await loadCurrentCPWarrantiesFuelOptions(VesselID, ReportID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    const CpFormObjectInitialValues = () => {
        if (currentCPWarranty) {
            return {
                // ...currentCPWarranty, // Spread the object to maintain other fields
                // Ensure `speed` has all necessary fields
                speed: {
                    id: currentCPWarranty?.speed?.id ?? null,
                    ordered_speed: currentCPWarranty?.speed?.ordered_speed ?? null,
                    date_of_change: currentCPWarranty?.speed?.date_of_change ?? null,
                    is_there_any_changes_since_last_report:
                        currentCPWarranty?.speed?.is_there_any_changes_since_last_report ?? false,
                    vessel: VesselID,
                    vessel_reporting_information: ReportID,
                    voyage_information: VoyageID,
                },
                // Ensure `fuel` is correctly structured
                fuel: Array.isArray(currentCPWarranty?.fuel)
                    && currentCPWarranty.fuel.map((fuel: any) => ({
                        id: fuel.id ?? null,
                        precedence_id: fuel.precedence_id ?? null,
                        ordered_fuel_oil_consumption: fuel.ordered_fuel_oil_consumption ?? null,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        vessel_fuel: fuel.vessel_fuel ?? null,
                    })), // Default empty array if `fuel` is not present
            };
        } else if (lastCPWarranty) {
            return {
                // ...lastCPWarranty, // Spread the object to maintain other fields
                // Ensure `speed` has all necessary fields
                speed: {
                    ordered_speed: lastCPWarranty?.speed?.ordered_speed ?? null,
                    date_of_change: lastCPWarranty?.speed?.date_of_change ?? null,
                    is_there_any_changes_since_last_report: false,
                    vessel: VesselID,
                    vessel_reporting_information: ReportID,
                    voyage_information: VoyageID,
                },
                fuel: lastCPWarranty?.fuel && Array.isArray(lastCPWarranty?.fuel)
                    && lastCPWarranty.fuel.map((fuel: any) => ({
                        precedence_id: fuel.precedence_id ?? null,
                        ordered_fuel_oil_consumption: fuel.ordered_fuel_oil_consumption ?? null,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        vessel_fuel: fuel.vessel_fuel ?? null,
                    })),
            };
        } else {
            return {
                speed: {
                    ordered_speed: null,
                    date_of_change: null,
                    is_there_any_changes_since_last_report: false,
                    vessel: VesselID,
                    vessel_reporting_information: ReportID,
                    voyage_information: VoyageID,
                },
                fuel: Array.isArray(fuelTypes)
                    && fuelTypes.map((fuel: any) => ({
                        precedence_id: fuel.precedence_id ?? null,
                        ordered_fuel_oil_consumption: null as number | null,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        vessel_fuel: fuel.fuel_type ?? null,
                    })),
            };
        }
    };

    const validationSchemaCpWarranties =
        Yup.object().shape({
            speed: Yup.object().shape({
                ordered_speed: Yup.string()
                    .matches(
                        /^\d{0,2}(?:\.\d{1,4})?$/,
                        `${commonValidationMessages.before2after4}`
                    ).when([], function () {
                        if (CPWarrantiesFormik?.values?.speed?.is_there_any_changes_since_last_report === true ||
                            (!currentCPWarranty && !lastCPWarranty)
                        ) {
                            return Yup.mixed().required(commonValidationMessages.required)
                        }
                    }).nullable(),
                lng_foe: Yup.string()
                    .matches(
                        /^\d{0,2}(?:\.\d{1,4})?$/,
                        `${commonValidationMessages.before2after4}`
                    )
                    .nullable(),
                is_there_any_changes_since_last_report: Yup.boolean(),
                date_of_change: Yup.date().when([], function (value: any, schema: any) {
                    if (CPWarrantiesFormik?.values?.speed?.is_there_any_changes_since_last_report === true ||
                        (!currentCPWarranty && !lastCPWarranty)) {
                        return schema.max(
                            new Date(dateTimeFormat(record?.local_time)),
                            commonValidationMessages.maxDateCurrentRecord
                        )
                            .min(
                                new Date(dateTimeFormat(lastRecord?.local_time)),
                                commonValidationMessages.minDateLastRecord
                            )
                            .required(commonValidationMessages.required)
                    }
                })
            }),
            fuel: Yup.array().of((
                Yup.object().shape({
                    ordered_fuel_oil_consumption: Yup.number()
                        .typeError("Please enter a valid number")
                        .when([], function () {
                            if (CPWarrantiesFormik?.values?.speed?.is_there_any_changes_since_last_report === true ||
                                (!currentCPWarranty && !lastCPWarranty)) {
                                return Yup.mixed().required(commonValidationMessages.required)
                            }
                        })
                })
            )),
        })

    const CPWarrantiesFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: CpFormObjectInitialValues(),
        validationSchema: env?.form_validation === true ? validationSchemaCpWarranties : null,
        onSubmit: async (values: any, actions: any) => {
            if (env?.form_validation === false) {
                setErrorMessage(null)
                toggleTab(activeTab + 1);
            }
            try {
                actions.setSubmitting(true);

                const responseArray: any = [];
                if (env?.form_validation === false) {
                    setErrorMessage(null);
                    toggleTab(activeTab + 1);
                }
                // Handle Speed Data (PUT if id exists, POST if not)
                if (CPWarrantiesFormik?.values) {
                    responseArray.push(
                        apiGlobal.post(`${queryKeyes.vessel.CPWarrantiesFuel.url()}`, values)
                    );
                }
                const response = await handleServerResponse(responseArray);

                if (response === true) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.currentCPWarranties.key);
                    setRefreshKey(refreshKey + 1);
                    if (env?.form_validation === true) {
                        setErrorMessage(null);
                        toggleTab(activeTab + 1);
                    }
                } else {
                    // setErrorMessage(response) //to show api error in table
                    // dynamicSetFieldErrors(response, actions) // to show api errror in tooltip
                }
            } catch (error) {
                console.error("Error submitting CP Warranties data:", error);
                setErrorMessage("An unexpected error occurred. Please try again.");
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    /** load speed and consumption by cp warranties ordered speed */
    const { data: SpeedAndConsumption } = useQuery(
        [queryKeyes.vessel.SpeedConsumptionObject.key, VesselID, ReportID, CPWarrantiesFormik?.values],
        async () => {
            return await loadSpeedConsumptionObject(VesselID, ReportID, CPWarrantiesFormik?.values?.speed?.ordered_speed);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    const handleSaveAndSwitchTab = async (formik: any) => {
        await formik.submitForm();
        formik.initialValues = formik.values;
        formik.resetForm({ values: formik.initialValues });
        setRefreshKey(refreshKey + 1);
        setStateSaveBeforeChangePopUp(false);
    }
    const handleNoSaveAndSwitchTab = (formik: any) => {
        formik.setValues(formik.initialValues);
        setRefreshKey(refreshKey + 1);
        setStateSaveBeforeChangePopUp(false); // Close the popup
    };
    /** useEffect */
    useEffect(() => {
        CPWarrantiesFormik.initialValues = CpFormObjectInitialValues();
        CPWarrantiesFormik.values = CpFormObjectInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastCPWarranty, currentCPWarranty, fuelTypes]);

    if (lastCPWarrantyLoading || fuelTypeLoading || currentCPWarrantyLoading) {
        return <Loading message="Loading required data!" />;
    } else if (lastCPWarrantyError || isFuelTypeError || currentCPWarrantyError) {
        return <ErrorComponent message="Unable to load required data!" />;
    } else {
        return (
            <React.Fragment>
                <Card className="p-0 mb-0 border-0">
                    <CardHeader className="p-2">
                        <div className="text-center">
                            <Row>
                                <Col>
                                    <h4 className="page_title pos-start mb-0">CP Warranties</h4>
                                    <p className="card-title-desc pos-start">
                                        All readings since last report
                                    </p>
                                </Col>
                                <Col>
                                    <ReportDetailsHeader />
                                </Col>
                            </Row>
                        </div>
                    </CardHeader>
                    <form className="needs-validation" autoComplete="off" noValidate onSubmit={CPWarrantiesFormik.handleSubmit} key={refreshKey}>
                        <CardBody className="px-2 py-0 mt-2">
                            <Col sm="4" className="mt-4 mb-3">
                                <div className="form-check">
                                    <Label for="is_there_any_changes_since_last_report">
                                        Is there any change since last report?
                                        <i
                                            className="bx bx-info-circle ml-2p"
                                            id="formCheck1"
                                        ></i>
                                    </Label>
                                    <input
                                        name="speed.is_there_any_changes_since_last_report"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="is_there_any_changes_since_last_report"
                                        defaultChecked={CPWarrantiesFormik?.values?.speed?.is_there_any_changes_since_last_report || false}
                                        onChange={(e: any) => {
                                            const isChecked = e.target.checked;
                                            if (!isChecked) {
                                                const areValuesEqual = isEqual(CPWarrantiesFormik?.values, CPWarrantiesFormik?.initialValues);
                                                setStateSaveBeforeChangePopUp(!areValuesEqual);
                                            }
                                            // Update checkbox state after reset
                                            CPWarrantiesFormik.setFieldValue("speed.is_there_any_changes_since_last_report", isChecked);
                                        }}
                                    />
                                    <ToolTip
                                        target="formCheck1"
                                        message={`${TooltipMsg.CP_Warranties.filter(
                                            (item: any) => item.target === "formCheck1"
                                        ).map((tool: any) => {
                                            return tool.message;
                                        })}`}
                                    />
                                </div>
                            </Col>
                            <DeletePopOver
                                state={saveBeforeChangePopUp}
                                setState={setStateSaveBeforeChangePopUp}
                                target='formCheck1'
                                onClick={() => handleSaveAndSwitchTab(CPWarrantiesFormik)} // Save and switch
                                noClick={() => handleNoSaveAndSwitchTab(CPWarrantiesFormik)} // Discard and switch
                                message='There are unsaved changes. Do you want to save them?'
                            />
                            <Row className="mb-2">
                                <Col sm={3} className="mb-3">
                                    <Label className="asteric mb-0" for="ordered_speed">
                                        Ordered speed
                                        <i className="bx bx-info-circle ml-2p" id="ordered_speed_msg"></i>
                                    </Label>
                                    <ToolTip
                                        target="ordered_speed_msg"
                                        message={`${TooltipMsg.CP_Warranties.filter(
                                            (item: any) => item.target === "ordered_speed_msg"
                                        ).map((tool: any) => {
                                            return tool.message;
                                        })}`}
                                    />
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            name="speed.ordered_speed"
                                            className="form-control max-width-7 text-right"
                                            id="ordered_speed"
                                            disabled={(!currentCPWarranty && !lastCPWarranty) === true ? false : (!CPWarrantiesFormik?.values?.speed?.is_there_any_changes_since_last_report)}
                                            onBlur={(e: any) => {
                                                CPWarrantiesFormik?.handleBlur(e);
                                                CPWarrantiesFormik?.handleChange(e);
                                            }}
                                            defaultValue={CPWarrantiesFormik?.values?.speed?.ordered_speed ?? null}
                                        />
                                        <div className="input-group-text round_border">knots</div>
                                    </div>
                                    {CPWarrantiesFormik?.errors?.speed?.ordered_speed &&
                                        CPWarrantiesFormik?.touched?.speed?.ordered_speed &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target="ordered_speed"
                                                message={CPWarrantiesFormik?.errors?.speed?.ordered_speed}
                                                open={!!CPWarrantiesFormik?.errors?.speed?.ordered_speed}
                                            />
                                        )}
                                </Col>
                                <Col sm={3}>
                                    <div>
                                        <Label className="asteric mb-0" for="date_of_change">
                                            Date & time of change (Local)
                                            <i
                                                className="bx bx-info-circle ml-2p"
                                                id="date_time_of_change_msg"
                                            ></i>
                                        </Label>
                                        <ToolTip
                                            target="date_time_of_change_msg"
                                            message={`${TooltipMsg.CP_Warranties.filter(
                                                (item: any) =>
                                                    item.target === "date_time_of_change_msg"
                                            ).map((tool: any) => {
                                                return tool.message;
                                            })}`}
                                        />
                                        <Input
                                            name="speed.date_of_change"
                                            type="datetime-local"
                                            id="date_of_change"
                                            value={dateTimeFormat(CPWarrantiesFormik?.values?.speed?.date_of_change, false, true)}
                                            className="datetimepicker text-uppercase mb-2 mt-0"
                                            min={dateTimeFormat(lastRecord?.local_time)}
                                            max={dateTimeFormat(record?.local_time)}
                                            onBlur={CPWarrantiesFormik?.handleBlur}
                                            disabled={(!currentCPWarranty && !lastCPWarranty) === true ? false : !CPWarrantiesFormik?.values?.speed?.is_there_any_changes_since_last_report}
                                            onChange={(e: any) => CPWarrantiesFormik?.handleChange(e)}
                                        />
                                    </div>
                                    {CPWarrantiesFormik?.errors?.speed?.date_of_change &&
                                        CPWarrantiesFormik?.touched?.speed?.date_of_change &&
                                        env?.form_validation === true && (
                                            <ErrorTooltip
                                                target={`date_of_change`}
                                                message={CPWarrantiesFormik?.errors?.speed?.date_of_change}
                                                open={CPWarrantiesFormik?.errors?.speed?.date_of_change ? true : false}
                                            />
                                        )}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ size: 8 }}>
                                    <div key="fuel_key" className="table-responsive ">
                                        <table className="table mb-2">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="p2 align-middle sr-no-width">#</th>
                                                    {inputs
                                                        ?.filter(
                                                            (table: any) => table.table_columns === true
                                                        )
                                                        ?.sort(
                                                            (a: any, b: any) =>
                                                                a.table_columns_sequence -
                                                                b.table_columns_sequence
                                                        )
                                                        ?.map(({ ...props }, index: number) => {
                                                            return (
                                                                <th
                                                                    key={index}
                                                                    className={
                                                                        props.label === "Fuel type"
                                                                            ? "text-left align-middle p-2"
                                                                            : props.className +
                                                                            " align-middle p-2"
                                                                    }
                                                                >
                                                                    {props.label}
                                                                </th>
                                                            );
                                                        })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {CPWarrantiesFormik?.values?.fuel
                                                    ?.sort((a: any, b: any) => a.id - b.id)
                                                    ?.map((vesselFuelType: any, index: number) => (
                                                        <tr key={index}>
                                                            <td className="p-2 align-middle text-center">
                                                                {index + 1}
                                                            </td>
                                                            <td className="p-2 align-middle">
                                                                {
                                                                    fuelTypes?.filter(
                                                                        (fuel: any) =>
                                                                            fuel.fuel_type ===
                                                                            vesselFuelType.vessel_fuel
                                                                    )[0]?.fuel_type_name
                                                                }
                                                            </td>
                                                            <td className="p-2 align-middle">
                                                                <div className="input-group">
                                                                    <Input
                                                                        name={`fuel.${index}.ordered_fuel_oil_consumption`}
                                                                        type="text"
                                                                        className="form-control max-width-7 text-right"
                                                                        id={`ordered_fuel_oil_consumption_${index}`}
                                                                        disabled={(!currentCPWarranty && !lastCPWarranty) === true ? false : !CPWarrantiesFormik?.values?.speed?.is_there_any_changes_since_last_report}
                                                                        onBlur={async (e: any) => {
                                                                            await calculateTotalDOeq(
                                                                                {},
                                                                                CPWarrantiesFormik?.values?.fuel,
                                                                                () => { },
                                                                                null,
                                                                                false,
                                                                                "precedence_id",
                                                                                "ordered_fuel_oil_consumption",
                                                                                false
                                                                            ).then((res: any) => {
                                                                                let DOeqSpeedAndConsumption;
                                                                                if (SpeedAndConsumption?.vessel_fuel === FuelTypes.DIESEL_OIL) {
                                                                                    DOeqSpeedAndConsumption = SpeedAndConsumption?.fuel_consumption
                                                                                } else {
                                                                                    DOeqSpeedAndConsumption =
                                                                                        ((SpeedAndConsumption?.fuel_consumption ?? 0) *
                                                                                            (res?.FuelLCVMaster?.filter((item: any) => item.fuel_type_precedence_id === SpeedAndConsumption?.vessel_fuel)[0]?.lcv * 1000000) /
                                                                                            FUELLCV.DO)
                                                                                }
                                                                                CPWarrantiesFormik.handleBlur(e)
                                                                                if (env?.alerts && res?.DOeq > DOeqSpeedAndConsumption + 3) {
                                                                                    setAlertMessage(AlertMessages.CpWarranties.ordered_fuel_oil_consumption_gt_3);
                                                                                    setAlertColourType(AlertColourTypeConstant.INFO);
                                                                                    setAlertType(AlertTypeConstant.INFO_ONLY);
                                                                                    setAlertBool(true);
                                                                                } else if (env?.alerts && res.DOeq < DOeqSpeedAndConsumption - 3) {
                                                                                    setAlertMessage(AlertMessages.CpWarranties.ordered_fuel_oil_consumption_lt_3);
                                                                                    setAlertColourType(AlertColourTypeConstant.INFO);
                                                                                    setAlertType(AlertTypeConstant.INFO_ONLY);
                                                                                    setAlertBool(true);
                                                                                }
                                                                            })
                                                                        }}
                                                                        onChange={CPWarrantiesFormik?.handleChange}
                                                                        value={CPWarrantiesFormik?.values?.fuel?.[index]?.ordered_fuel_oil_consumption ?? null}
                                                                    />
                                                                    <div className="input-group-text round_border">
                                                                        mt/day
                                                                    </div>
                                                                </div>
                                                                {CPWarrantiesFormik?.errors?.fuel &&
                                                                    CPWarrantiesFormik?.touched?.fuel &&
                                                                    CPWarrantiesFormik?.touched?.fuel?.[index]
                                                                        ?.ordered_fuel_oil_consumption &&
                                                                    CPWarrantiesFormik?.errors?.fuel?.[index]
                                                                        ?.ordered_fuel_oil_consumption &&
                                                                    env?.form_validation === true && (
                                                                        <ErrorTooltip
                                                                            target={`ordered_fuel_oil_consumption_${index}`}
                                                                            message={
                                                                                CPWarrantiesFormik?.errors?.fuel?.[index]
                                                                                    ?.ordered_fuel_oil_consumption
                                                                            }
                                                                            open={
                                                                                CPWarrantiesFormik?.errors?.fuel &&
                                                                                    CPWarrantiesFormik?.errors?.fuel?.[index]
                                                                                        ?.ordered_fuel_oil_consumption
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                        />
                                                                    )}
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        {alertBool &&
                            <AlertPopup
                                state={alertBool}
                                setState={setAlertBool}
                                alertMessage={alertMessage}
                                setAlertMessage={setAlertMessage}
                                AlertColourType={alertColourType}
                                setAlertColourType={setAlertColourType}
                                alertType={alertType}
                                setAlertType={setAlertType}
                                formTitle={'CP Warranties'}
                            />
                        }
                        <CardFooter className="p-2 py-3">
                            <Row className="ele_row1">
                                <div className="d-flex flex-wrap gap-5">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn_size_cstm pos-end"
                                    >
                                        Next <i className="bx bx-chevron-right ms-1" />
                                    </Button>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn_size_cstm"
                                        onClick={() => {
                                            setErrorMessage(null);
                                            CPWarrantiesFormik?.setErrors({});
                                            toggleTab(activeTab - 1);
                                        }}
                                    >
                                        <i className="bx bx-chevron-left me-1" /> Previous
                                    </Button>
                                </div>
                            </Row>
                        </CardFooter>
                        <FormValuesDebug
                            values={[
                                CPWarrantiesFormik?.values,
                                CPWarrantiesFormik?.errors,
                                CPWarrantiesFormik?.initialValues,
                            ]}
                        />
                    </form>
                </Card>
            </React.Fragment>
        )
    }
}

export default CpWarrantyComponent
