import Layout from "HorizontalMenu/Menu";
import { getVesselsAction, setOnboardedVessels, setVesselID } from "Store/Generic/ReportingSlice";
import { RootState } from "index";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Row,
    Label,
    Container,
    Navbar,
    Card,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import classnames from "classnames";
import Select from "react-select";
import PendingSTSAdjustment from "./PendingSTSAdjustment";
import ApprovedSTSAdjustment from "./ApprovedSTSAdjustment";
import { customStyle } from "shared/CommonCSS";
import { OnboardingStatus, VesselTypeConstant } from "shared/constants";
import { useDocumentTitle } from "Components/useDocument.hooks";
import Message from "Components/Message";

const STSAdjustment = () => {
    /** State variables start */
    const dispatch = useDispatch();
    const { Vessels, onboardedVessels } = useSelector((state: RootState) => state.Reporting);
    const [activeAdjustmentsTab, setActiveAdjustmentsTab] = useState("1");
    const [tab, setTab] = useState("pending");
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    useDocumentTitle("STS Adjustment - ecoSAIL");
    /** State variables end */

    /** set global vessel */
    const handleVesselSelectionChanges = (e: any) => {
        dispatch(setVesselID(e ? e?.id : null));
    };

    /** Change tabs */
    const toggle = (tab: any) => {
        if (activeAdjustmentsTab !== tab) {
            setActiveAdjustmentsTab(tab);
        }
    };

    /** useEffect */
    /** Populate Vessels array */
    useEffect(() => {
        dispatch(getVesselsAction("vessel_master" as string));
    }, [dispatch]);

    /** Effect 2: Set onboarded vessels when Vessels data is available */
    useEffect(() => {
        if (Array.isArray(Vessels)) {
            const onboardedVessels = Vessels.filter(
                (vessel) => vessel.onboarding_status === OnboardingStatus.ONBOARDED
            );
            dispatch(setOnboardedVessels(onboardedVessels));
        }
    }, [Vessels, dispatch]);
    /** useEffect end */

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={4}>
                            <Label className="mb-0">Select vessel</Label>
                            <Select
                                options={
                                    onboardedVessels.filter(
                                        (item: any) =>
                                            item.display === true &&
                                            (item.vessel_type === VesselTypeConstant.TANKER || item.vessel_type === VesselTypeConstant.LNG_CARRIER
                                            )) as any[]
                                }
                                getOptionLabel={(option: any) => {
                                    const vesselName = option.vessel_name;
                                    const vesselType = option.vessel_type ? `(${option.vessel_type})` : '';
                                    return `${vesselName} ${vesselType}`;
                                }}
                                getOptionValue={(option: any) => option.id}
                                onChange={(e: any) => handleVesselSelectionChanges(e)}
                                menuPortalTarget={document.body}
                                styles={customStyle}
                                isClearable={true}
                                defaultValue={VesselID && vessel?.vessel_name ? {
                                    id: VesselID,
                                    vessel_name: `${vessel.vessel_name} (${vessel.vessel_type})`,
                                } : null}
                            />
                        </Col>
                        <Col sm={8} className="pt-3 text-end">
                            <div className="d-inline-block">
                                <h3 className="m-0">STS Adjustment</h3>
                            </div>
                        </Col>
                    </Row>
                    {VesselID ? (
                        <Row className="mb-3 px-2">
                            <Card className="p-0">
                                <CardHeader className="pb-0">
                                    <Nav tabs className="border-0">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeAdjustmentsTab === "1",
                                                })}
                                                onClick={() => {
                                                    setTab("pending");
                                                    toggle("1");
                                                }}
                                            >
                                                Pending
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeAdjustmentsTab === "2",
                                                })}
                                                onClick={() => {
                                                    setTab("approved");
                                                    toggle("2");
                                                }}
                                            >
                                                Approved
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <TabContent
                                    activeTab={activeAdjustmentsTab}
                                    className="text-muted"
                                >
                                    <TabPane tabId="1" className="mx-0 mt-2">
                                        <PendingSTSAdjustment active={tab} />
                                    </TabPane>
                                    <TabPane tabId="2" className="mx-0 mt-2">
                                        <ApprovedSTSAdjustment active={tab} />
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Row>
                    ) : (
                        <Message message='Please select a vessel to proceed.' type='warning' width='367px' />
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default STSAdjustment;
