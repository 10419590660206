import Loading from "Components/Loading";
import { handleServerResponse } from "GenericForms/Helper";
import { loadTugsUsageObject } from "VesselMaster/vesselMaster.hooks";
import env from "environment_system/env_system";
import { Field, Formik } from "formik";
import apiGlobal from "global/api.global";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { queryClient } from "react-query/queryClient";
import {
  Row,
  Col,
  Form,
  Label,
  Button,
  CardHeader,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";

interface TugsUsageComponentType {
  activeAddTab: number;
  toggleAddTab: any;
  VesselID: number;
  VoyageID: number;
  ReportID: number;
  setErrorMessage: (value: boolean) => void
}

const TugsUsageComponent = ({
  activeAddTab,
  toggleAddTab,
  VesselID,
  VoyageID,
  ReportID,
  setErrorMessage
}: TugsUsageComponentType) => {
  /** State variables start*/
  const [refreshKey, setRefreshKey] = useState(0);
  /** State variables end */

  /** Queries */
  /** Tugs Used object used for edit */
  const {
    data: TugsUsageObject,
    isLoading: TugsUsageObjectLoading,
    isError: TugsUsageObjectError,
  } = useQuery(
    [queryKeyes.vessel.LfondsTugsUsageObject.key, VesselID, ReportID],
    async () => {
      return await loadTugsUsageObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Queries end */

  /** Assign values to initial object of Tugs Usage */
  const getInitialTugsUsageValues = () => {
    if (TugsUsageObject && TugsUsageObject[0]?.id > 0) {
      return TugsUsageObject[0];
    } else {
      return {
        number_of_tugs: 0,
        remarks: "",
        vessel: VesselID,
        voyage_information: VoyageID,
        vessel_reporting_information: ReportID,
      };
    }
  };

  /** Tugs Usage formik object */
  const TugsUsageFormik = {
    initialValues: getInitialTugsUsageValues(),
  };

  useEffect(() => {
    TugsUsageFormik.initialValues = getInitialTugsUsageValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TugsUsageObject, getInitialTugsUsageValues])
  return (
    <Card className="p-0 mb-0">
      <CardHeader className="p-2">
        <div className="text-center">
          <Row>
            <Col>
              <h4 className="page_title pos-start mb-0">Tugs Usage</h4>
              <p className="card-title-desc pos-start">
                All readings since last report
              </p>
            </Col>
          </Row>
        </div>
      </CardHeader>
      {TugsUsageObjectLoading && <Loading message="Loading required data!" />}
      {TugsUsageObjectError && getInitialTugsUsageValues()}
      {!TugsUsageObjectLoading && (
        <Formik
          onSubmit={async (values: any, actions: any) => {
            if (env?.form_validation === false) {
              setErrorMessage(null)
              toggleAddTab(activeAddTab + 1);
            }
            actions.setSubmitting(true);
            let responseArray: any = [];
            if (TugsUsageObject && TugsUsageObject[0]?.id > 0) {
              responseArray.push(apiGlobal.put(`/lfonds_tug_usage/${values?.id}/`, values));
            } else {
              responseArray.push(apiGlobal.post(`/lfonds_tug_usage/`, values));
            }
            await handleServerResponse(responseArray).then(async (res: any) => {
              if (res === true) {
                await queryClient.invalidateQueries(queryKeyes.vessel.LfondsTugsUsageObject.key);
                setRefreshKey(refreshKey + 1);
                if (env?.form_validation === true) {
                  setErrorMessage(null)
                  toggleAddTab(activeAddTab + 1);
                }
              } else {
                setErrorMessage(res)
              }
            })
            actions.setSubmitting(false);
          }}
          initialValues={TugsUsageFormik.initialValues}
          key={refreshKey}
        >
          {({
            values,
            errors,
            handleSubmit,
            setErrors,
            isSubmitting
          }: {
            values: any;
            errors: any;
            handleSubmit: any;
            handleChange: any;
            setErrors: any;
            isSubmitting: any;
          }) => (
            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <CardBody className="px-2 py-0 mt-2">
                <Row className="mb-3">
                  <Col sm={3}>
                    <Label className="mb-0" for="number_of_tugs">
                      Number of tugs used
                    </Label>
                    <Field
                      type="text"
                      name="number_of_tugs"
                      id="number_of_tugs"
                      className="form-control max-width-13"
                    />
                  </Col>
                  <Col sm={4}>
                    <Label className="mb-0" for="remarks">
                      Remarks
                    </Label>
                    <Field
                      as="textarea"
                      name="remarks"
                      id="remarks"
                      className="form-control"
                      rows={3}
                    />
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="p-2">
                <Row className="ele_row1">
                  <div className="d-flex flex-wrap gap-5">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn_size_cstm pos-end"
                      disabled={isSubmitting}
                    >
                      {isSubmitting &&
                        <i className="ms-2 spinner-border spinner-border-sm text-light me-2" />
                      }
                      Next <i className="bx bx-chevron-right ms-1" />
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="btn_size_cstm"
                      onClick={() => {
                        setErrors({})
                        errors = {};
                        setErrorMessage(null)
                        toggleAddTab(activeAddTab - 1);
                      }}
                    >
                      <i className="bx bx-chevron-left me-1" /> Previous
                    </Button>
                  </div>
                </Row>
              </CardFooter>
              <FormValuesDebug
                values={[values, errors, TugsUsageFormik.initialValues]}
              />
            </Form>
          )}
        </Formik>
      )}
    </Card>
  );
};

export default TugsUsageComponent;
