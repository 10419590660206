interface Environment {
  base_url: string;
  media_base_url?: string;
  token?: string;
  token_prefix?: string;
  base_name?: string;
  debugSetting?: boolean;
  desktop_app?: boolean;
  masters?: boolean;
  form_validation?: boolean;
  logger?: boolean;
  company_title: string;
  additional_info_deck: string;
  additional_info_engg: string;
  fuel_eu: boolean;
  encription_key: string;
  alerts: boolean;
  help: boolean;
}

const env: Environment = {
  base_url: process.env.REACT_APP_API_URL,
  media_base_url: process.env.REACT_APP_MEDIA_URL,
  token_prefix: process.env.REACT_APP_TOKEN_PREFIX,
  base_name: process.env.REACT_APP_BASE_NAME,
  debugSetting: process.env.REACT_APP_DEBUGSETTING === "TRUE" ? true : false,
  masters: process.env.REACT_APP_MASTER === "TRUE" ? true : false,
  desktop_app: process.env.REACT_APP_DESKTOP_APP === "TRUE" ? true : false,
  form_validation:
    process.env.REACT_APP_FORM_VALIDATION === "TRUE" ? true : false,
  logger: process.env.REACT_APP_LOGGER === "TRUE" ? true : false,
  company_title:
    process.env.REACT_APP_COMPANY_TITLE ?? "ECOSAIL INFOTECH PVT LTD",
  additional_info_deck: process.env.REACT_APP_ADDITIONAL_INFO_DECK,
  additional_info_engg: process.env.REACT_APP_ADDITIONAL_INFO_ENGG,
  fuel_eu: process.env.REACT_APP_FUEL_EUM === "TRUE" ? true : false,
  encription_key: process.env.REACT_APP_ENCRYPTION_KEY,
  alerts: process.env.REACT_APP_ALERTS === "TRUE" ? true : false,
  help: process.env.REACT_APP_HELP === "TRUE" ? true : false,
};

export default env;
