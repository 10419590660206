import { RootState } from "index";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import { customStyle } from "shared/CommonCSS";
import { setVesselID } from "Store/Generic/ReportingSlice";
import classnames from "classnames";
import { Roles, VesselTypeConstant } from "shared/constants";
import { hasRole } from "utils/auth/authUtils";
import PendingLNGCargoDetails from "./tabs/PendingLNGCargoDetails";
import CIIPendingFiles from "./tabs/CIIPendingFiles";
import PendingBDNFiles from "./tabs/PendingBDNFiles";
import { PendingEDNFiles } from "./tabs/PendingEDNFiles";
import PendingSpOpLogBook from "./tabs/PendingSpOpLogBook";
import ReferContainer from "./tabs/ReferContainer";
// import ReferContainer from "./tabs/ReferContainer";
const Pending = () => {
  /** State variables start */
  const dispatch = useDispatch();
  const { Vessels, VesselID, onboardedVessels } = useSelector(
    (state: RootState) => state.Reporting
  );
  const [activeAdjustmentsTab, setActiveAdjustmentsTab] = useState("1");
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);

  const handleVesselSelectionChanges = (e: any) => {
    dispatch(setVesselID(e.id));

    const selectedVessel = Vessels.find((v: any) => v.id === e.id);
    if (!selectedVessel) return;

    let defaultTab = activeAdjustmentsTab;
    if (defaultTab === "2" && selectedVessel?.vessel_type !== VesselTypeConstant.LNG_CARRIER) {
      defaultTab = "1";
    }
    if (defaultTab === "5" && selectedVessel?.vessel_type !== VesselTypeConstant.CONTAINER && selectedVessel?.vessel_type !== VesselTypeConstant.GENERAL_CARGO_SHIP) {
      defaultTab = "6";
    }
    if (defaultTab === "6" && (selectedVessel?.vessel_type === VesselTypeConstant.CONTAINER || selectedVessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP)) {
      defaultTab = "5";
    }
    setActiveAdjustmentsTab(defaultTab);
  };


  /** Change tabs */
  const toggle = (tab: any) => {
    if (activeAdjustmentsTab !== tab) {
      setActiveAdjustmentsTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Card className="p-0">
        <CardHeader className="p-2">
          <h5
            style={{ display: "inline" }}
            key={"dashboard"}
            className="page_title ps-0 mb-2"
          >
            Pending Files
          </h5>
        </CardHeader>
        <CardBody className="p-2">
          <Row className="mb-2">
            <Col sm={4}>
              <Label className="mb-0">Select vessel</Label>
              <Select
                options={onboardedVessels as any[]}
                getOptionLabel={(option: any) => {
                  const vesselName = option.vessel_name;
                  const vesselType = option.vessel_type ? `(${option.vessel_type})` : '';
                  return `${vesselName} ${vesselType}`;
                }}
                getOptionValue={(option: any) => option.id}
                onChange={(e: any) => handleVesselSelectionChanges(e)}
                menuPortalTarget={document.body}
                styles={customStyle}
                defaultValue={VesselID && vessel?.vessel_name ? {
                  id: VesselID,
                  vessel_name: `${vessel.vessel_name} (${vessel.vessel_type})`,
                } : null}
              />
            </Col>
          </Row>
          <Row className="mb-3 px-2">
            <Card className="p-0 border-0">
              <CardHeader className="pb-0">
                <Nav tabs className="border-0">
                  {(hasRole(Roles.ES_ADMIN) ||
                    hasRole(Roles.MANAGER) ||
                    hasRole(Roles.MASTER) ||
                    hasRole(Roles.CHIEFENGINEER)) && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeAdjustmentsTab === "1",
                          })}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          CII Adjustment Files
                        </NavLink>
                      </NavItem>
                    )}
                  {(hasRole(Roles.ES_ADMIN) ||
                    hasRole(Roles.MANAGER) ||
                    hasRole(Roles.MASTER) ||
                    hasRole(Roles.CHIEFENGINEER)) &&
                    Vessels.filter(
                      (item: any) =>
                        item.id === VesselID &&
                        item.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                        item.display === true
                    ).length > 0 && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeAdjustmentsTab === "2",
                          })}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          N<sub>2</sub> Adjustments Files
                        </NavLink>
                      </NavItem>
                    )}
                  {(hasRole(Roles.ES_ADMIN) ||
                    hasRole(Roles.MANAGER) ||
                    hasRole(Roles.MASTER) ||
                    hasRole(Roles.CHIEFENGINEER)) && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeAdjustmentsTab === "3",
                          })}
                          onClick={() => {
                            toggle("3");
                          }}
                        >
                          BDN Files
                        </NavLink>
                      </NavItem>
                    )}
                  {(hasRole(Roles.ES_ADMIN) ||
                    hasRole(Roles.MANAGER) ||
                    hasRole(Roles.MASTER) ||
                    hasRole(Roles.CHIEFENGINEER)) && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeAdjustmentsTab === "4",
                          })}
                          onClick={() => {
                            toggle("4");
                          }}
                        >
                          EDN Files
                        </NavLink>
                      </NavItem>
                    )}
                  {(hasRole(Roles.ES_ADMIN) ||
                    hasRole(Roles.MANAGER) ||
                    hasRole(Roles.MASTER) ||
                    hasRole(Roles.CHIEFENGINEER)) &&
                    Vessels.filter(
                      (item: any) =>
                        item.id === VesselID &&
                        (item.vessel_type === VesselTypeConstant.CONTAINER ||
                          item.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                        item.display === true
                    ).length > 0 && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeAdjustmentsTab === "5",
                          })}
                          onClick={() => {
                            toggle("5");
                          }}
                        >
                          Reefer Container
                        </NavLink>
                      </NavItem>
                    )}
                  {(hasRole(Roles.ES_ADMIN) ||
                    hasRole(Roles.MANAGER) ||
                    hasRole(Roles.MASTER) ||
                    hasRole(Roles.CHIEFENGINEER)) &&
                    Vessels.some(
                      (item: any) =>
                        item.id === VesselID &&
                        item.display === true &&
                        (item.vessel_type !== VesselTypeConstant.CONTAINER &&
                          item.vessel_type !== VesselTypeConstant.GENERAL_CARGO_SHIP)
                    ) && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeAdjustmentsTab === "6",
                          })}
                          onClick={() => toggle("6")}
                        >
                          Log Book Files
                        </NavLink>
                      </NavItem>
                    )}
                </Nav>
              </CardHeader>
              <CardBody className="p-0">
                <TabContent
                  activeTab={activeAdjustmentsTab}
                  className="text-muted p-0"
                >
                  <TabPane tabId="1" className="mx-0">
                    <CIIPendingFiles />
                  </TabPane>
                  <TabPane tabId="2" className="mx-0">
                    <PendingLNGCargoDetails />
                  </TabPane>
                  <TabPane tabId={'3'} className="mx-0">
                    <PendingBDNFiles />
                  </TabPane>
                  <TabPane tabId={'4'} className="mx-0">
                    <PendingEDNFiles />
                  </TabPane>
                  <TabPane tabId={'5'} className="mx-0">
                    <ReferContainer />
                  </TabPane>
                  <TabPane tabId={'6'} className="mx-0">
                    <PendingSpOpLogBook />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Pending;
