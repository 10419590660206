import ToolTip from "Components/ToolTip";
import React from "react";
import { Button, Col, Row } from "reactstrap";

const SaveBeforeChange = ({
  tabName,
  onYes,
  onNo,
  disableYesButton,
}: {
  tabName?: string;
  onYes?: () => void;
  onNo?: () => void;
  disableYesButton?: boolean;
}) => {

  return (
    <React.Fragment>
      <div>
        <p>Do you want to save {tabName} tab changes?</p>
      </div>
      <Row className="d-flex gap-3 flex-wrap mt-3  justify-content-center">
        <Col sm={2}>
          <div id={`yes_btn`}>
            <Button
              type="button"
              color="primary"
              className="mt-3"
              onClick={onYes}
              disabled={disableYesButton}
            >
              Yes
            </Button>
            </div>
            {disableYesButton &&
              <ToolTip
                target={`yes_btn`}
                message={`Cant save changes, please enter valid values and try again`}
              />
            }
        </Col>
        <Col sm={2}>
          <Button
            type="button"
            color="danger"
            className="mt-3"
            onClick={onNo}
          >
            No
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SaveBeforeChange;
