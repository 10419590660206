import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { errorToast, successToast } from 'Components/Toasts';
import { FormikProps, useFormik } from 'formik';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CardFooter, Col, Form, Input, Label, Row } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { LoadOnboardingSpeedAndConsumption, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import Select from 'react-select';
import { LoadConditionConstant, Roles, VesselConfigrationTabs } from 'shared/constants';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import * as Yup from "yup";
import env from 'environment_system/env_system';
import ErrorTooltip from 'Components/ErrorTooltip';
import { errResponse, handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import DeletePopOver from 'Components/DeletePopOver';
import { setVesselState } from 'Store/Generic/ReportingSlice';
import { useLocation } from 'react-router-dom';
import { hasRole } from 'utils/auth/authUtils';
import PopUp from 'Components/PopUp';
import ConfigurationPendingFileStatus from './ConfigurationPendingFileStatus';
import isEqual from "fast-deep-equal";

interface SpeedConsumptionType {
    VesselConfActiveTab: number,
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    deletePopOverActive?: boolean;
    setDeletePopOverActive?: (value: boolean) => void;
}

const SpeedConsumptionTable = ({
    VesselConfActiveTab,
    deletePopOverActive,
    setDeletePopOverActive,
    setCheckValuesBeforeSwitch,
    setTabName,
    setFormik,
    formik,
}: SpeedConsumptionType) => {
    /** State variables */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [selectedFuel, setSelectedFuel] = useState<number>(null);
    const [selectedFuelName, setSelectedFuelName] = useState<string>(null);
    const [deleteBool, setDeleteBool] = useState(false);
    const [deleteId, setDeleteId] = useState<number>(null);
    const [deleteIndex, setDeleteIndex] = useState<number>(null);
    const [deleteString, setDeleteString] = useState<string>(null);
    const [configStatusBool, setConfigStatusBool] = useState<boolean>(false);
    const [selectRefresh, setSelectRefresh] = useState(0);
    const dispatch = useDispatch();
    let location = useLocation();
    /** State variables end */

    /** useQueries */
    /** Load speed and consumption on vessel */
    const {
        data: SpeedAndConsumption,
        isLoading: SpeedAndConsumptionLoading
    }: { data: any[]; isLoading: any } = useQuery(
        [queryKeyes.vessel.OnboardingSpeedAndConsumptionByVessel.key, VesselID],
        async () => {
            return await LoadOnboardingSpeedAndConsumption(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Load distinct fuel types configured on vessel */
    const {
        data: VesselFuelTypes,
        isLoading: VesselFuelTypesLoading,
        isError: VesselFuelTypesError,
    } = useQuery(
        [queryKeyes.vessel.vesselFuelType.key, VesselID],
        async () => {
            return await loadVesselFuelTypes(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /** Asign initial values to formik object */
    const getInitialValues = (condition: string) => {
        if (SpeedAndConsumption && SpeedAndConsumption.length > 0) {
            if (condition === 'laden') {
                const filteredItems = SpeedAndConsumption.filter((item: any) => item.load_condition === LoadConditionConstant.LADEN);
                if (filteredItems.length > 0) {
                    return filteredItems.map((item: any) => ({
                        ...item,
                        title: 'Laden Condition',
                    }));
                } else {
                    let arr: { [key: string]: string | number | boolean | null }[] = [];
                    arr.push({
                        speed: null,
                        fuel_consumption: null,
                        power: null,
                        vessel_fuel: selectedFuel ?? null,
                        load_condition: condition === 'laden' ? LoadConditionConstant.LADEN : LoadConditionConstant.BALLAST,
                        title: condition === 'laden' ? 'Laden Condition' : 'Ballast Condition',
                        vessel: VesselID,
                    })
                    return arr;
                }
            } else {
                const filteredItems = SpeedAndConsumption.filter((item: any) => item.load_condition === LoadConditionConstant.BALLAST);
                if (filteredItems.length > 0) {
                    return filteredItems.map((item: any) => ({
                        ...item,
                        title: 'Ballast Condition',
                    }));
                } else {
                    let arr: { [key: string]: string | number | boolean | null }[] = [];
                    arr.push({
                        speed: null,
                        fuel_consumption: null,
                        power: null,
                        vessel_fuel: selectedFuel ?? null,
                        load_condition: condition === 'laden' ? LoadConditionConstant.LADEN : LoadConditionConstant.BALLAST,
                        title: condition === 'laden' ? 'Laden Condition' : 'Ballast Condition',
                        vessel: VesselID,
                    })
                    return arr;
                }
            }
        } else {
            let arr: { [key: string]: string | number | boolean | null }[] = [];
            arr.push({
                speed: null,
                fuel_consumption: null,
                power: null,
                vessel_fuel: selectedFuel ?? null,
                load_condition: condition === 'laden' ? LoadConditionConstant.LADEN : LoadConditionConstant.BALLAST,
                title: condition === 'laden' ? 'Laden Condition' : 'Ballast Condition',
                vessel: VesselID,
            })
            return arr;
        }
    }

    const deleteSpeedAndConsumption = async (SpeedConsumptionFormik?: any) => {
        if (deleteId) {
            try {
                const res = await apiGlobal.delete(`${queryKeyes.vessel.OnboardingSpeedAndConsumption.url()}${deleteId}/`);
                if (res.status === 200 || res.status === 204) {
                    successToast("Data deleted successfully!");
                    await queryClient.invalidateQueries([queryKeyes.vessel.OnboardingSpeedAndConsumptionByVessel.key, VesselID]);
                    if (deleteString && Array.isArray(SpeedConsumptionFormik?.values[deleteString as 'ladenSpeedConsumption' | 'ballastSpeedConsumption'])) {
                        SpeedConsumptionFormik?.values[deleteString as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.splice(deleteIndex, 1);
                    }
                    setRefreshKey(refreshKey + 1)
                    setDeleteBool(false);
                    setDeleteIndex(null);
                    setDeleteId(null);
                    setDeleteString(null);
                }
            } catch (err: any) {
                if (errResponse.includes(err?.response?.status)) {
                    errorToast("Internal error occurred, please contact the admin");
                }
            }
        } else {
            if (deleteString && Array.isArray(SpeedConsumptionFormik?.values[deleteString as 'ladenSpeedConsumption' | 'ballastSpeedConsumption'])) {
                SpeedConsumptionFormik?.values[deleteString as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.splice(deleteIndex, 1);
            }
            setDeleteBool(false);
            setDeleteIndex(null);
            setDeleteId(null);
            setDeleteString(null);
        }
    };

    /** formik object */
    const SpeedConsumptionFormik: any = useFormik({
        initialValues: {
            ladenSpeedConsumption: getInitialValues('laden'),
            ballastSpeedConsumption: getInitialValues('ballast'),
        },
        validationSchema: Yup.object().shape({
            ladenSpeedConsumption: Yup.array(
                Yup.object({
                    vessel_fuel: Yup.number().required(commonValidationMessages.required),
                    speed: Yup.number()
                        .required(commonValidationMessages.required)
                        .moreThan(0, "Speed must be greater than 0"),

                    fuel_consumption: Yup.number()
                        .required(commonValidationMessages.required)
                        .moreThan(0, "Fuel consumption must be greater than 0"),

                    power: Yup.number()
                        .required(commonValidationMessages.required)
                        .moreThan(0, "Power must be greater than 0"),
                })
            ),
            ballastSpeedConsumption: Yup.array(
                Yup.object({
                    vessel_fuel: Yup.number().required(commonValidationMessages.required),
                    speed: Yup.number()
                        .required(commonValidationMessages.required)
                        .moreThan(0, "Speed must be greater than 0"),

                    fuel_consumption: Yup.number()
                        .required(commonValidationMessages.required)
                        .moreThan(0, "Fuel consumption must be greater than 0"),

                    power: Yup.number()
                        .required(commonValidationMessages.required)
                        .moreThan(0, "Power must be greater than 0"),
                })
            ),
        }),
        onSubmit: async () => {
            const responseArray: any[] = []
            SpeedConsumptionFormik.setSubmitting(true);
            // Set vessel_fuel for each item in ladenSpeedConsumption and ballastSpeedConsumption
            Object.keys(SpeedConsumptionFormik.values)?.forEach((speedConsumption: any) => {
                SpeedConsumptionFormik.values[speedConsumption as 'ladenSpeedConsumption' | 'ballastSpeedConsumption']?.forEach((item: any) => {
                    item.vessel_fuel = selectedFuel;
                });
            });
            // Function to handle PUT and POST requests
            const handleSpeedConsumption = (speedConsumptionList: any[]) => {
                speedConsumptionList.map((value) => {
                    const url = queryKeyes.vessel.OnboardingSpeedAndConsumption.url();
                    if (value &&
                        value?.speed > "0" && value?.fuel_consumption > "0" && value?.power > "0") {
                        const request = value.id
                            ? apiGlobal.put(`${url}${value.id}/`, value)  // PUT if id exists
                            : apiGlobal.post(`${url}`, value);  // POST if no id
                        responseArray.push(request);
                    }
                });
            };
            // Handle both ballastSpeedConsumption and ladenSpeedConsumption
            handleSpeedConsumption(SpeedConsumptionFormik?.values?.ballastSpeedConsumption);
            handleSpeedConsumption(SpeedConsumptionFormik?.values?.ladenSpeedConsumption);
            // Wait for all requests to finish
            await handleServerResponse(responseArray).then(async (res) => {
                if (res === true) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.OnboardingSpeedAndConsumptionByVessel.key);
                    setRefreshKey(refreshKey + 1);
                }
            })
            if (location.pathname === '/onboarding') {
                dispatch(setVesselState(null));
            }
            SpeedConsumptionFormik.setSubmitting(false);
        }
    })

    const tabName = 'Vessel General Info';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = isEqual(values, initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        return areValuesEqual;
    };

    /**Start of useEffect */
    // useEffect(() => {
    //     if (SpeedAndConsumption && SpeedAndConsumption.length > 0) {
    //         setSelectedFuel(SpeedAndConsumption[0]?.vessel_fuel);
    //         setSelectedFuelName(SpeedAndConsumption[0]?.vessel_fuel_name);
    //         setRefreshKey(refreshKey + 1);
    //         SpeedConsumptionFormik.setValues({
    //             ladenSpeedConsumption: getInitialValues('laden'),
    //             ballastSpeedConsumption: getInitialValues('ballast'),
    //         });
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [SpeedAndConsumption])
    useEffect(() => {
        !deletePopOverActive && setDeleteBool(false)
    }, [deletePopOverActive])
    useEffect(() => {
        if (VesselConfActiveTab === VesselConfigrationTabs.SPEED_CONSUMPTION && SpeedConsumptionFormik.values !== SpeedConsumptionFormik.initialValues) {
            setFormik(SpeedConsumptionFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselConfActiveTab, formik, SpeedConsumptionFormik]);
    useEffect(() => {
        SpeedConsumptionFormik.initialValues.ladenSpeedConsumption = getInitialValues('laden');
        SpeedConsumptionFormik.initialValues.ballastSpeedConsumption = getInitialValues('ballast');
        SpeedConsumptionFormik.values.ladenSpeedConsumption = getInitialValues('laden');
        SpeedConsumptionFormik.values.ballastSpeedConsumption = getInitialValues('ballast');
        setSelectedFuel(SpeedAndConsumption && SpeedAndConsumption[0]?.vessel_fuel);
        setSelectedFuelName(SpeedAndConsumption && SpeedAndConsumption[0]?.vessel_fuel_name)
        setSelectRefresh(selectRefresh + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpeedAndConsumption])
    useEffect(() => {
        if (VesselConfActiveTab !== VesselConfigrationTabs.SPEED_CONSUMPTION) {
            SpeedConsumptionFormik?.setTouched({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselConfActiveTab]);
    useEffect(() => {
        handleFormikStateChange(SpeedConsumptionFormik?.values, SpeedConsumptionFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpeedConsumptionFormik?.values, SpeedConsumptionFormik?.initialValues]);
    /**End of useEffect */

    if (VesselFuelTypesLoading || SpeedAndConsumptionLoading) {
        return <Loading message='Loading required data!' />
    }
    if (VesselFuelTypesError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <React.Fragment>
                {(VesselFuelTypesLoading || SpeedAndConsumptionLoading) && <Loading message='Loading required data!' />}
                {VesselFuelTypesError && <ErrorComponent message='Unable to load required data!' />}
                {!(VesselFuelTypesLoading || SpeedAndConsumptionLoading) && !VesselFuelTypesError &&
                    <Form onSubmit={SpeedConsumptionFormik?.handleSubmit} noValidate autoComplete='off' key={refreshKey}>
                        <Row className='mb-2'>
                            <h5 className='mb-0'>Main Engine Consumption</h5>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Label className="mb-0">Select fuel</Label>
                                <Select
                                    key={selectRefresh}
                                    name="ladenSpeedConsumption.0.vessel_fuel"
                                    inputId="vessel_fuel_id"
                                    options={VesselFuelTypes}
                                    className="mb-2 max-width-13"
                                    getOptionLabel={(option: any) => option?.fuel_type_name}
                                    getOptionValue={(option: any) => option.fuel_type}
                                    onBlur={() =>
                                        SpeedConsumptionFormik.setFieldTouched(
                                            "ladenSpeedConsumption.0.vessel_fuel",
                                            true
                                        )
                                    }
                                    onChange={(e: any) => {
                                        // Update all relevant speed consumption fields with the selected fuel
                                        Object.keys(SpeedConsumptionFormik.values).forEach((speedConsumption) => {
                                            SpeedConsumptionFormik.values[
                                                speedConsumption as "ladenSpeedConsumption" | "ballastSpeedConsumption"
                                            ].forEach((item: any) => {
                                                item.vessel_fuel = e?.fuel_type;
                                            });
                                        });
                                        setSelectedFuel(e?.fuel_type);
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={customStyle}
                                    defaultValue={
                                        SpeedAndConsumption &&
                                        SpeedAndConsumption.length > 0 && {
                                            fuel_type: selectedFuel,
                                            fuel_type_name: selectedFuelName,
                                        }}
                                />
                                {SpeedConsumptionFormik.touched.ladenSpeedConsumption &&
                                    SpeedConsumptionFormik.errors.ladenSpeedConsumption &&
                                    SpeedConsumptionFormik.touched.ladenSpeedConsumption[0] &&
                                    SpeedConsumptionFormik.errors.ladenSpeedConsumption[0] &&
                                    env?.form_validation === true && (
                                        <ErrorTooltip
                                            target="vessel_fuel_id"
                                            message={
                                                SpeedConsumptionFormik.errors.ladenSpeedConsumption[0].vessel_fuel
                                            }
                                            open={
                                                !!SpeedConsumptionFormik.errors.ladenSpeedConsumption[0]
                                                    ?.vessel_fuel
                                            }
                                        />
                                    )}
                            </Col>
                        </Row>
                        <Row>
                            {Object.keys(SpeedConsumptionFormik.values).map((value: string, indx: number) => (
                                <Col sm={6} key={indx}>
                                    <React.Fragment>
                                        <h6 className="mb-0">
                                            {SpeedConsumptionFormik.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption'][0]?.title}
                                        </h6>
                                        <div className="table-responsive mb-3">
                                            <table className="table mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="p-2 align-middle sr-no-width">#</th>
                                                        <th className="p-2 align-middle text-center asteric">Speed</th>
                                                        <th className="p-2 align-middle text-center asteric">Consumption</th>
                                                        <th className="p-2 align-middle text-center asteric">Power</th>
                                                        <th className="p-2 align-middle text-center" colSpan={2}>Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(SpeedConsumptionFormik?.values[value]) &&
                                                        (SpeedConsumptionFormik?.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption'] ?? [])
                                                            ?.map((item: any, index: number) => (
                                                                item ? ( // Check if item exists before rendering
                                                                    <tr key={index}>
                                                                        <td className="p-2 text-center align-middle">
                                                                            <Label className="mb-0">{index + 1}</Label>
                                                                        </td>
                                                                        <td className="p-2">
                                                                            <div className="input-group">
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control text-right"
                                                                                    name={`${value}.${index}.speed`}
                                                                                    value={item?.speed || ''}
                                                                                    onChange={SpeedConsumptionFormik.handleChange}
                                                                                    onBlur={SpeedConsumptionFormik.handleBlur}
                                                                                    id={`speed_${index}`} // Unique ID for Tooltip Target
                                                                                />
                                                                                <div className="input-group-text small-unit-size">knots</div>
                                                                            </div>
                                                                            {(SpeedConsumptionFormik?.errors?.[value]?.[index]?.speed) &&
                                                                                (SpeedConsumptionFormik?.touched?.[value]?.[index]?.speed) && (
                                                                                    <ErrorTooltip
                                                                                        target={`speed_${index}`}
                                                                                        message={SpeedConsumptionFormik?.errors?.[value]?.[index]?.speed}
                                                                                        open={!!SpeedConsumptionFormik?.errors?.[value]?.[index]?.speed}
                                                                                    />
                                                                                )}
                                                                        </td>
                                                                        <td className="p-2">
                                                                            <div className="input-group">
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control text-right"
                                                                                    name={`${value}.${index}.fuel_consumption`}
                                                                                    value={item.fuel_consumption || ''}
                                                                                    onChange={SpeedConsumptionFormik.handleChange}
                                                                                    onBlur={SpeedConsumptionFormik.handleBlur}
                                                                                    id={`fuel_consumption_${index}`} // Unique ID for Tooltip Target
                                                                                />
                                                                                <div className="input-group-text">mt/day</div>
                                                                            </div>
                                                                            {(SpeedConsumptionFormik?.errors?.[value]?.[index]?.fuel_consumption) &&
                                                                                (SpeedConsumptionFormik?.touched?.[value]?.[index]?.fuel_consumption) && (
                                                                                    <ErrorTooltip
                                                                                        target={`fuel_consumption_${index}`}
                                                                                        message={SpeedConsumptionFormik?.errors?.[value]?.[index]?.fuel_consumption}
                                                                                        open={!!SpeedConsumptionFormik?.errors?.[value]?.[index]?.fuel_consumption}
                                                                                    />
                                                                                )}
                                                                        </td>
                                                                        <td className="p-2">
                                                                            <div className="input-group">
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control text-right"
                                                                                    name={`${value}.${index}.power`}
                                                                                    value={item.power || ''}
                                                                                    onChange={SpeedConsumptionFormik.handleChange}
                                                                                    onBlur={SpeedConsumptionFormik.handleBlur}
                                                                                    id={`power_${index}`} // Unique ID for Tooltip Target
                                                                                />
                                                                                <div className="input-group-text small-unit-size">kW</div>
                                                                            </div>
                                                                            {(SpeedConsumptionFormik?.errors?.[value]?.[index]?.power) &&
                                                                                (SpeedConsumptionFormik?.touched?.[value]?.[index]?.power) && (
                                                                                    <ErrorTooltip
                                                                                        target={`power_${index}`}
                                                                                        message={SpeedConsumptionFormik?.errors?.[value]?.[index]?.power}
                                                                                        open={!!SpeedConsumptionFormik?.errors?.[value]?.[index]?.power}
                                                                                    />
                                                                                )}
                                                                        </td>
                                                                        {SpeedConsumptionFormik.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption'].length === 1 ? null : (
                                                                            <td className="p-2 align-middle">
                                                                                {isConfigurationButtonDisabled(vessel) ? (
                                                                                    <i className="dripicons-trash icon_s18 disabled-icon" />
                                                                                ) : (
                                                                                    <i
                                                                                        className="dripicons-trash icon_s18 pointer"
                                                                                        id={`delete_icon_${value}_${index}`}
                                                                                        onClick={() => {
                                                                                            setDeleteBool(true);
                                                                                            setDeletePopOverActive(true);
                                                                                            setDeleteString(value);
                                                                                            setDeleteIndex(index);
                                                                                            setDeleteId(item.id);
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </td>
                                                                        )}
                                                                        {index === SpeedConsumptionFormik.values[value as 'ladenSpeedConsumption' | 'ballastSpeedConsumption'].length - 1 &&
                                                                            index <= 10 && (
                                                                                <td className="align-middle p-1">
                                                                                    <Button
                                                                                        type="button"
                                                                                        className="btn"
                                                                                        color="primary"
                                                                                        disabled={isConfigurationButtonDisabled(vessel)}
                                                                                        onClick={() => {
                                                                                            SpeedConsumptionFormik.setFieldValue(value, [
                                                                                                ...SpeedConsumptionFormik.values[value],
                                                                                                {
                                                                                                    speed: null,
                                                                                                    fuel_consumption: null,
                                                                                                    power: null,
                                                                                                    load_condition:
                                                                                                        value === 'ladenSpeedConsumption'
                                                                                                            ? LoadConditionConstant.LADEN
                                                                                                            : LoadConditionConstant.BALLAST,
                                                                                                    vessel_fuel: selectedFuel,
                                                                                                    vessel: VesselID,
                                                                                                },
                                                                                            ]);
                                                                                        }}
                                                                                    >
                                                                                        <i className="dripicons-plus icon_s18 pointer" />
                                                                                    </Button>
                                                                                </td>
                                                                            )}
                                                                    </tr>
                                                                ) : null // Render nothing if item doesn't exist
                                                            ))}
                                                    {deleteBool && deleteString && deletePopOverActive && (
                                                        <DeletePopOver
                                                            target={`delete_icon_${deleteString}_${deleteIndex}`}
                                                            state={deleteBool}
                                                            setState={setDeleteBool}
                                                            onClick={async () => {
                                                                setDeleteString(null);
                                                                setDeleteIndex(null);
                                                                SpeedConsumptionFormik.setFieldValue(
                                                                    deleteString,
                                                                    SpeedConsumptionFormik.values[deleteString].filter((_: any, i: number) => i !== deleteIndex)
                                                                );
                                                                await deleteSpeedAndConsumption(SpeedConsumptionFormik);
                                                            }}
                                                        />
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </React.Fragment>
                                </Col>
                            ))}
                        </Row>

                        {deleteBool && deleteString &&
                            <DeletePopOver
                                target={`delete_icon_${deleteString}_${deleteIndex}`}
                                state={deleteBool}
                                setState={setDeleteBool}
                                onClick={() => deleteSpeedAndConsumption(SpeedConsumptionFormik)}
                            />
                        }
                        <CardFooter className='p-2'>
                            <Row className='mb-2'>
                                <Col sm={11} className='text-end'>
                                    {hasRole(Roles.ES_ADMIN) &&
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-primary"
                                            onClick={() => setConfigStatusBool(true)}
                                        >Configuration Status</Button>
                                    }

                                </Col>
                                <Col className='text-end'>
                                    <Button
                                        type="submit"
                                        className="btn btn-primary"
                                        color="primary"
                                        disabled={isConfigurationButtonDisabled(vessel)}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                            <p className='mb-0'>- This table is necessary for generating alerts in vessel reporting, unless sufficient historical data is available.</p>
                            <FormValuesDebug
                                values={[
                                    SpeedConsumptionFormik?.values,
                                    SpeedConsumptionFormik?.errors,
                                    SpeedConsumptionFormik.initialValues,
                                ]}
                            />
                        </CardFooter>
                        {configStatusBool &&
                            <PopUp
                                title="Configuration Status List"
                                state={configStatusBool}
                                setState={setConfigStatusBool}
                                body={
                                    <ConfigurationPendingFileStatus VesselID={VesselID} />
                                }
                                size="md"
                            />
                        }
                    </Form>
                }
            </React.Fragment >
        )
    }
}

export default SpeedConsumptionTable;
