import LNGCargoQuality from 'Components/LNGCargoQuality';
import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import { checkInvalidPrimaryKey, errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Row, Col, Button, Modal } from 'reactstrap';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import N2AdjustmentTableHeadComponent from './N2AdjustmentTableHeadComponent';
import { useQuery } from 'react-query';
import { GetPageWithID } from 'Dashboard/PendingFiles/pendingpage.hook';
import { queryKeyes } from 'shared/queryKeys';
import PageNavButton from 'Dashboard/PendingFiles/PageNavButton';
import { queryClient } from 'react-query/queryClient';
import Message from 'Components/Message';

const DisapprovedN2Adjustment = ({ active }: any) => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [n2adjustment, setN2Adjustment] = useState<any>({});
    const formSubmitRef = useRef<any>(null);
    const [revokeBool, setRevokeBool] = useState<any>({});
    const [url, setUrl] = useState<string>('');
    /** State variables end */
    /** Open-close modal */
    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /** Function to revoke N2 Correction */
    const revokeN2Correction = () => {
        n2adjustment.approval_status = AdjustmentApprovalStatus.PENDING;
        delete n2adjustment.ctms_start_file
        delete n2adjustment.ctms_end_file
        apiGlobal.put(`/${queryKeyes.vessel.voyageN2Adjustment.url}/${n2adjustment?.id}/`, n2adjustment).then(res => {
            if (res.status === 201) {
                successToast("Data saved successfully!");
                queryClient.invalidateQueries(queryKeyes.vessel.N2AdjustmentObjectByVessel.key);
                setRevokeBool(false);
            }
        }).catch(err => {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        })
    }

    const { data: DisapprovedN2Adjustment } = useQuery(
        [queryKeyes.pagination.N2AdjustmentPage.key, VesselID, url],
        async () => await GetPageWithID(VesselID, url),
        {
            enabled: true,
        }
    )

    useEffect(() => {
        if (checkInvalidPrimaryKey(VesselID)) {
            setUrl(queryKeyes.pagination.N2AdjustmentPage.url(VesselID, AdjustmentApprovalStatus.DISAPPROVED));
        }
    }, [VesselID, active])

    return (
        <React.Fragment>
            {DisapprovedN2Adjustment?.results?.length === 0 || DisapprovedN2Adjustment?.results?.length === undefined ?
                <div className='ml-1 mt-2'>
                    <Message
                        message='No disapproved N<sub>2</sub> corrections available for this vessel'
                        type='danger'
                        width='500px'
                        html={true}
                    />
                </div> :
                <React.Fragment>
                    <Row className='mb-0'>
                        <Col lg={7}>
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <N2AdjustmentTableHeadComponent />
                                    <tbody>
                                        {DisapprovedN2Adjustment && DisapprovedN2Adjustment?.results?.map((n2: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                    <td className='nopadtop align-middle p-2'>
                                                        <>
                                                            <b>{n2?.start_date_time?.toString().substring(0, 10)}&nbsp;
                                                                {n2?.start_date_time?.toString().substring(11, 16)}</b> to
                                                            <b> {n2?.end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                {n2?.end_date_time?.toString().substring(11, 16)}</b>
                                                        </>
                                                    </td>
                                                    {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                        <td className='align-middle text-center p-2'>
                                                            <Button
                                                                type='button'
                                                                className='btn btn-primary waves-effect btn-label waves-light'
                                                                color='primary'
                                                                id={`disapprove_btn${n2?.id}`}
                                                                onClick={() => {
                                                                    setN2Adjustment(n2);
                                                                    setRevokeBool(true);
                                                                }}
                                                            >
                                                                <i className="bx bx-left-arrow-circle label-icon"></i>
                                                                Mark as pending
                                                            </Button>
                                                            {revokeBool === true &&
                                                                <PopOver
                                                                    target={`disapprove_btn${n2adjustment?.id}`}
                                                                    handleClick={revokeN2Correction}
                                                                    message={'Are you sure you want to revoke?'}
                                                                    state={revokeBool}
                                                                    setState={setRevokeBool}
                                                                />
                                                            }
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                        }
                                        {adjustmentPopup === true &&
                                            <Modal
                                                size='xl'
                                                isOpen={adjustmentPopup}
                                                toggle={() => {
                                                    tog_backdrop();
                                                }}
                                                backdrop={"static"}
                                                id="staticBackdrop"
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="staticBackdropLabel">
                                                        LNG Cargo Quality Details
                                                    </h5>
                                                    <button
                                                        onClick={() => {
                                                            setAdjustmentPopup(false);
                                                        }}
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <Row>
                                                        <Col lg={6}>
                                                            <LNGCargoQuality
                                                                VesselID={VesselID}
                                                                reportId={n2adjustment?.start_report}
                                                                cargoOperation={'loading'}
                                                                cardHeader={'LNG Cargo Loading Quality Details'}
                                                                form="n2Adjustment"
                                                                adjustmentDetails={n2adjustment}
                                                                ref={formSubmitRef}
                                                                setState={setAdjustmentPopup}
                                                            />
                                                        </Col>
                                                        <Col lg={6}>
                                                            <LNGCargoQuality
                                                                VesselID={VesselID}
                                                                reportId={n2adjustment?.end_report}
                                                                cargoOperation={'discharging'}
                                                                cardHeader={'LNG Cargo Discharging Quality Details'}
                                                                form="n2Adjustment"
                                                                adjustmentDetails={n2adjustment}
                                                                ref={formSubmitRef}
                                                                setState={setAdjustmentPopup}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={{ size: 2, offset: 10 }}>
                                                            <Button type='button' className='btn justify_right' color='primary' onClick={() => {
                                                                if (formSubmitRef.current) {
                                                                    formSubmitRef.current.submitForm();
                                                                }
                                                            }}>Approve</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Modal>
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={7}>
                                                <PageNavButton
                                                    pageobj={DisapprovedN2Adjustment}
                                                    setPageUrl={setUrl}
                                                    pageUrl={url}
                                                />
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default DisapprovedN2Adjustment