import ReportDetailsHeader from 'Components/ReportDetailsHeader';
import React from 'react';
import { Card, CardHeader, Col, Row, TabContent, TabPane } from 'reactstrap';
import MainEngineComponent from './partials/MainEngineComponent';
import AuxEngineComponent from './partials/AuxEngineComponent';
import CountersFlowmetersComponent from './partials/CountersFlowmetersComponent';
import { EventPrecedence } from 'shared/constants';

interface EconAddInformationType {
    toggleTab: any,
    tabsIdList: any[],
    toggleDynamicTabs: any
    ReportID: number,
    VesselID: number,
    VoyageID: number,
    previousTabIndex: number,
    record: any,
    setErrorMessage: any,
    activeAddEconTab: number,
    toggleAddEconTab: (value: number) => void
}

const EconAddInformation = ({
    toggleDynamicTabs,
    ReportID,
    VesselID,
    VoyageID,
    previousTabIndex,
    record,
    setErrorMessage,
    activeAddEconTab,
    toggleAddEconTab
}: EconAddInformationType) => {
    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                <TabContent activeTab={activeAddEconTab} className="tabHeight">
                    <TabPane tabId={(record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") ? 1 : 0}>
                        <MainEngineComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddEconTab}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                            setErrorMessage={setErrorMessage}
                        />
                    </TabPane>
                    <TabPane tabId={(record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") ? 2 : 0}>
                        <AuxEngineComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddEconTab}
                            setErrorMessage={setErrorMessage}
                        />
                    </TabPane>
                    <TabPane tabId={(record?.event_precedence_id === EventPrecedence.NOON || record?.only_noon_or_combine_with_other_event === "combine_with_other_report") ? 3 : 1}>
                        <CountersFlowmetersComponent
                            VesselID={VesselID}
                            VoyageID={VoyageID}
                            ReportID={ReportID}
                            toggleAddTab={toggleAddEconTab}
                            toggleDynamicTabs={toggleDynamicTabs}
                            previousTabIndex={previousTabIndex}
                            record={record}
                            setErrorMessage={setErrorMessage}
                        />
                    </TabPane>
                </TabContent>
            </div>
        </Card>
    )
}

export default EconAddInformation