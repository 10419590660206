import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import classname from "classnames";
import withRouter from "../Components/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import env from "environment_system/env_system";
import { hasRole } from "utils/auth/authUtils";
import { Roles, VesselTypeConstant } from "shared/constants";
import { RootState } from "index";
import { setVesselID, setVesselState } from "Store/Generic/ReportingSlice";

const Navbar = () => {
    const { Vessels, VesselID } = useSelector(
        (state: RootState) => state.Reporting
    );
    const dispatch = useDispatch();
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const { leftMenu } = useSelector((state: any) => ({
        leftMenu: state.Layout.leftMenu,
    }));
    const [masters, setMasters] = useState<boolean>(false);
    const [analysis, setAnalysis] = useState<boolean>(false);
    const [adjustments, setAdjustments] = useState<boolean>(false);
    const [simulator, setSimulator] = useState<boolean>(false);
    const [other, setOther] = useState<boolean>(false);
    const location = useLocation();
    const [reports, setReports] = useState<boolean>(false);
    const pathName = location.pathname;

    useEffect(() => {
        let matchingMenuItem = null;
        const ul: any = document.getElementById("navigation");
        const items: any = ul.getElementsByTagName("a");
        removeActivation(items);
        for (let i = 0; i < items.length; ++i) {
            if (window.location.href === items[i].href) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [pathName]);

    function activateParentDropdown(item: any) {
        item.classList.add("active");
        const parent = item.closest(".dropdown-menu");
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    const removeActivation = (items: any) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                if (parent.classList.contains("active")) {
                    parent.classList.remove("active");
                }
            }
        }
    };

    const hasLNGCarrierWithDisplay = Vessels.some(
        vessel => vessel.vessel_type === VesselTypeConstant.LNG_CARRIER && vessel.display === true
    );
    const hasTankerWithDisplay = Vessels.some(
        vessel => (vessel.vessel_type === VesselTypeConstant.TANKER || vessel.vessel_type === VesselTypeConstant.LNG_CARRIER) && vessel.display === true
    );
    // /**
    //  * Add Active class to the menu
    //  *
    //  * @param target
    //  * @returns
    //  */
    // const activeMenu = (target: any) => {
    //     return (location.pathname.indexOf(target) === 0) ? ' active ' : '';
    // }
    return (
        <React.Fragment>
            <Row className={hasRole(Roles.ES_ADMIN || Roles.MANAGER) ? 'mb-2' : ''}>
                <div className="topnav">
                    <div className="container-fluid">
                        <nav
                            className="navbar navbar-light navbar-expand-lg topnav-menu"
                            id="navigation"
                        >
                            <Collapse
                                isOpen={leftMenu}
                                className="navbar-collapse"
                                id="topnav-menu-content"
                            >
                                <ul className="navbar-nav">
                                    {(hasRole(Roles.ES_ADMIN) ||
                                        hasRole(Roles.MANAGER) ||
                                        hasRole(Roles.MASTER) ||
                                        hasRole(Roles.CHIEFENGINEER)) && (
                                            <li className="mr-1 nav-item">
                                                <Link
                                                    className={"nav-link p-2 mt-1 dropdown-toggle arrow-none dropdown"}
                                                    to="/dashboard"
                                                >
                                                    <span className="font-15">Dashboard</span>
                                                </Link>
                                            </li>
                                        )}
                                    {hasRole(Roles.ES_ADMIN) && (
                                        // hasRole(Roles.MANAGER)) &&
                                        <li className="mr-1 nav-item dropdown">
                                            <Link
                                                className="nav-link p-2 mt-1 dropdown-toggle arrow-none"
                                                to="/fleet_assessment"
                                            >
                                                <span className="font-15">Fleet Assessment</span>
                                            </Link>
                                        </li>
                                    )}
                                    {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                                        <li className="mr-1 nav-item dropdown">
                                            <Link
                                                to="/#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setAnalysis(!analysis);
                                                }}
                                                className="nav-link p-2 mt-1 dropdown-toggle arrow-none"
                                            >
                                                <span className="font-15">Emission Analysis<div className="arrow-down"></div> </span>
                                            </Link>
                                            <div
                                                className={classname(
                                                    "dropdown-menu mega-dropdown-menu dropdown-menu-right dropdown-mega-menu-md",
                                                    { show: analysis }
                                                )}
                                            >
                                                <div className="ps-2 p-lg-0">
                                                    <Row>
                                                        <Col lg={2}>
                                                            <Link
                                                                className={"dropdown-item"}
                                                                to="/cii_assessment"
                                                            >
                                                                <span> CII Assessment </span>
                                                            </Link>
                                                            <Link className={"dropdown-item"} to="/imo_dcs">
                                                                <span>IMO DCS </span>
                                                            </Link>
                                                            <Link className={"dropdown-item"} to="/eu_mrv_ets">
                                                                <span> EU MRV/ETS </span>
                                                            </Link>
                                                            {/* <Link className={"dropdown-item"} to="/uk_mrv">
                                                                 <span className="font-15">UK MRV</span>
                                                            </Link> */}
                                                            {env?.fuel_eu &&
                                                                <Link className={"dropdown-item"} to="/fuel_eu">
                                                                    <span> FuelEU </span>
                                                                </Link>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {/* {(hasRole(Roles.ES_ADMIN) ||
                                hasRole(Roles.MANAGER)) &&
                                <li className="width-3 nav-item dropdown">
                                    <Link
                                        className="nav-link p-2 mt-1 dropdown-toggle arrow-none"
                                        to="/vessel_performance"
                                    >
                                         <span className="font-15">Vessel Performance</span>
                                    </Link>
                                </li>
                            } */}
                                    {(hasRole(Roles.ES_ADMIN) ||
                                        hasRole(Roles.MANAGER) ||
                                        hasRole(Roles.MASTER) ||
                                        hasRole(Roles.CHIEFENGINEER)) && (
                                            <li className="mr-1 nav-item dropdown">
                                                <Link
                                                    to="/masters/vessel_reporting"
                                                    className={"nav-link p-2 mt-1 dropdown-toggle arrow-none"}
                                                    onClick={() => dispatch(setVesselState(null))}
                                                >
                                                    <span className="font-15">Vessel Reporting</span>
                                                </Link>
                                            </li>
                                        )}
                                    {hasRole(Roles.ES_ADMIN) && (
                                        <li className="mr-1 nav-item dropdown">
                                            <Link
                                                to="/#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setAdjustments(!adjustments);
                                                }}
                                                className="nav-link p-2 mt-1 dropdown-toggle arrow-none"
                                            >
                                                <span className="font-15">Adjustments<div className="arrow-down"></div></span>
                                            </Link>
                                            <div
                                                className={classname(
                                                    "dropdown-menu mega-dropdown-menu dropdown-menu-right dropdown-mega-menu-md",
                                                    { show: adjustments }
                                                )}
                                            >
                                                <div className="ps-2 p-lg-0">
                                                    <Row>
                                                        <Col lg={2}>
                                                            <Link
                                                                to="/voyage_adjustment"
                                                                className="dropdown-item"
                                                            >
                                                                <span>Voyage Adjustment</span>
                                                            </Link>
                                                            {hasTankerWithDisplay && (
                                                                <Link
                                                                    to="/sts_adjustment"
                                                                    className="dropdown-item"
                                                                    onClick={() => {
                                                                        if (vessel?.vessel_type !== VesselTypeConstant.TANKER) {
                                                                            dispatch(setVesselID(null))
                                                                        }
                                                                    }}
                                                                >
                                                                    <span> STS Adjustment</span>
                                                                </Link>
                                                            )}
                                                            <Link
                                                                to="/FC(elec+boiler+other)_adjustment"
                                                                className="dropdown-item"
                                                            >
                                                                <span> FC<sub>(elec + boiler + other)</sub></span>
                                                            </Link>
                                                            {hasLNGCarrierWithDisplay && (
                                                                <Link
                                                                    to="/n2_correction"
                                                                    className="dropdown-item"
                                                                    onClick={() => {
                                                                        if (vessel?.vessel_type !== VesselTypeConstant.LNG_CARRIER) {
                                                                            dispatch(setVesselID(null))
                                                                        }
                                                                    }}
                                                                >
                                                                    <span> N<sub>2</sub> Correction</span>
                                                                </Link>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                    {/* {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)
                                    || hasRole(Roles.MASTER)
                                    || hasRole(Roles.CHIEFENGINEER)) && (env.desktop_app === true) && (
                                        <li className="width-3 nav-item">
                                            <Link className="nav-link p-2 mt-1" to="/sync_report">
                                                SyncReport
                                            </Link>
                                        </li>
                                    )} */}
                                    {/* {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)
                                    || hasRole(Roles.MASTER)
                                    || hasRole(Roles.CHIEFENGINEER)) && (
                                        <li className="width-3 nav-item">
                                            <Link className="nav-link p-2 mt-1" to="/notifications">
                                                Notification
                                            </Link>
                                        </li>
                                    )} */}
                                    {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                                        <li className="mr-1 nav-item dropdown">
                                            <Link
                                                to="/#"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    setOther(!other)
                                                }}
                                                className="nav-link p-2 mt-1 dropdown-toggle arrow-none"
                                            >
                                                <span className="font-15">Other<div className="arrow-down" /></span>
                                            </Link>
                                            <div
                                                className={classname(
                                                    "dropdown-menu mega-dropdown-menu dropdown-menu-right dropdown-mega-menu-md",
                                                    { show: other }
                                                )}
                                            >
                                                <div className="ps-2 p-lg-0">
                                                    <Row>
                                                        <Col lg={2}>
                                                            {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                                                                <Link className="dropdown-item" to="/users">
                                                                    <span>User Management</span>
                                                                </Link>
                                                            )}
                                                            {hasRole(Roles.ES_ADMIN) && (
                                                                <Link className="dropdown-item" to="/pooling">
                                                                    <span>Pooling</span>
                                                                </Link>
                                                            )}
                                                            {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER) || hasRole(Roles.MASTER)) && (
                                                                <Link
                                                                    className="dropdown-item"
                                                                    to="/onboarding"
                                                                    onClick={() => dispatch(setVesselState(null))}
                                                                >
                                                                    <span>Onboarding</span>
                                                                </Link>
                                                            )}

                                                            {/* Reports Submenu */}
                                                            {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                                                                <div className="dropdown-submenu">
                                                                    <Link
                                                                        to="/#"
                                                                        className="dropdown-item dropdown-toggle"
                                                                        onClick={(e) => {
                                                                            e.preventDefault()
                                                                            setReports(!reports)
                                                                        }}
                                                                    >
                                                                        <i className="bx bx-chevron-left" />Reports
                                                                    </Link>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link to="/custom_company_report" className="dropdown-item">
                                                                            <span>Custom Report</span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {/* Simulator Submenu */}
                                                            {hasRole(Roles.ES_ADMIN) && (
                                                                <div className="dropdown-submenu">
                                                                    <Link
                                                                        to="/#"
                                                                        className="dropdown-item dropdown-toggle arrow-none"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setSimulator(!simulator);
                                                                        }}
                                                                    >
                                                                        <i className="bx bx-chevron-left" /> Simulator
                                                                    </Link>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link to="/cii_simulator" className="dropdown-item"
                                                                            onClick={() => {
                                                                                setSimulator(!simulator);

                                                                            }}>
                                                                            <span>CII Simulator</span>
                                                                        </Link>
                                                                        {/* Uncomment if needed */}
                                                                        <Link to="/voyage_estimator" className="dropdown-item">
                                                                            Voyage Estimator
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {env?.masters === true && hasRole(Roles.ES_ADMIN) && (
                                                                <div className="dropdown-submenu">
                                                                    <Link
                                                                        to="/#"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setMasters(!masters)
                                                                        }}
                                                                        className="dropdown-item dropdown-toggle"
                                                                    >
                                                                        <span><i className="bx bx-chevron-left" /> Masters</span>
                                                                    </Link>
                                                                    <div className="dropdown-menu master-dropdown-menu dropdown-menu-right">
                                                                        <div className="ps-2 p-lg-0">
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/region_master" className="dropdown-item">Region Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/contact_type_master" className="dropdown-item">Contact Type Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/fuel_type_master" className="dropdown-item">Fuel Type Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/fuel_sub_type_master" className="dropdown-item">Fuel Sub Type Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/vessel_type_master" className="dropdown-item">Vessel Type Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/hull_type_master" className="dropdown-item">Hull Type Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/direction_master" className="dropdown-item">Direction Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/wind_direction_master" className="dropdown-item">Wind Direction Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/vessel_load_condition_master" className="dropdown-item">Load Condition Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/beaufort_scale_master" className="dropdown-item">Beaufort Scale Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/strait_canal_transit_list_master" className="dropdown-item">Strait Canal Transit</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/country_master" className="dropdown-item">Country Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/vessel_owner_master" className="dropdown-item">Vessel Owner Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/fuel_master" className="dropdown-item">Fuel Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/vessel_sub_type_master" className="dropdown-item">Vessel Sub Type Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/port_master" className="dropdown-item">Port Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/cii_adjustment_events_master" className="dropdown-item">CII Adjustment Event Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/machinery_list_master" className="dropdown-item">Machinery List Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/vessel_master" className="dropdown-item">Vessel Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/lube_oil_list_master" className="dropdown-item">Lube Oil List Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/sensor_list_master" className="dropdown-item">Sensor List Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/current_direction_master" className="dropdown-item">Current Direction Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/douglas_sea_scale_master" className="dropdown-item">Douglas Sea Scale Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/reporting_type_master" className="dropdown-item">Reporting Type Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/reporting_events_master" className="dropdown-item">Reporting Events Master</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/reporting_operation_mode_master" className="dropdown-item">Reporting Operation</Link>
                                                                                </Col>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/reporting_cii_exclusions_master" className="dropdown-item">Reporting CII Exclusion</Link>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={4}>
                                                                                    <Link to="/masters/fuel_category_master" className="dropdown-item">Fuel Category Master</Link>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </Collapse>
                        </nav>
                    </div>
                </div>
            </Row>
        </React.Fragment>
    );
};

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
};

export default withRouter(Navbar);
