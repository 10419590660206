import ErrorTooltip from 'Components/ErrorTooltip';
import env from 'environment_system/env_system';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react'
import { Input } from 'reactstrap';

const SpOpEnergyConsumptionComponent = ({
    energyConsumptions,
    handleChange,
    AuxEngineMaxSFOC,
    EnergyObject,
    loadwsum,
    kwhMeterPopup
}: any) => {
    const { values, errors }: { values: any, errors: any } = useFormikContext();
    return (
        <React.Fragment>
            <div className='d-inline-block'>
                <div className="input-group">
                    <Field name={`withkWhMeter.functional.${values.withkWhMeter.functional.indexOf(energyConsumptions)}.energy_consumed`}>
                        {({ field, form }: FieldProps) => (
                            <Input
                                type="text"
                                className="form-control max-width-7 text-right"
                                id={`energy_consumed-${values.withkWhMeter.functional.indexOf(energyConsumptions)}`}
                                name={field.name}
                                onBlur={(e: any) => {
                                    handleChange(e);
                                    form.setFieldValue(`withkWhMeter.functional.${values?.withkWhMeter?.functional?.indexOf(energyConsumptions)}.equivalent_fuel_consumption`,
                                        (AuxEngineMaxSFOC?.max_eedi_eexi_technical_file * (e.target.value) / 1000000)?.toFixed(4));
                                }}
                                defaultValue={
                                    values?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.energy_consumed
                                }
                                disabled={values?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true ? true : false}
                                value={((EnergyObject && EnergyObject?.length > 0) && values?.withkWhMeter?.functional
                                [values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true) ?
                                    values?.withkWhMeter?.functional
                                    [values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.energy_consumed :
                                    values?.withkWhMeter?.functional
                                    [values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.is_kwh_meter_not_functional === true ?
                                        loadwsum(values?.withkWhMeter?.nonfunctional?.filter((item: any) =>
                                            item?.vessel_machinery_name === energyConsumptions.vessel_machinery_ec_group), field.name, form)
                                        : null}
                            />
                        )}
                    </Field>
                    <div className="input-group-text">kWh</div>
                </div>
            </div>
            {errors?.withkWhMeter && errors?.withkWhMeter?.functional &&
                errors?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)] &&
                errors?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.energy_consumed &&
                env?.form_validation === true &&
                <ErrorTooltip
                    target={`energy_consumed-${values.withkWhMeter.functional.indexOf(energyConsumptions)}`}
                    message={errors?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.energy_consumed}
                    open={errors?.withkWhMeter?.functional[values?.withkWhMeter?.functional?.indexOf(energyConsumptions)]?.energy_consumed && !kwhMeterPopup ? true : false}
                />
            }
        </React.Fragment>
    )
}

export default SpOpEnergyConsumptionComponent