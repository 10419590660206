import React, { useState, useEffect } from 'react';
import { CardBody } from "reactstrap";
import '../global/GlobalCSS.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { VesselState, getReportsAction, setVesselState, setReportID } from "../Store/Generic/ReportingSlice";
import VoyageInformation from '../VoyageInformation/VoyageInformation';
import ToolTip from '../Components/ToolTip';
import CaptainIcon from '../Media/DC9.png';
import EngineerIcon from '../Media/E4.png';
import CaptainBlue from '../Media/DC3.png';
import EditIcon from '../Media/ED2.png';
import EngineerBlue from '../Media/E5.png';
import { DO_ENGG_ReportingStatus, ReportingStatusConstant, Roles, VoyageConstant } from '../shared/constants';
import EditVesselReport from './EditVesselReport';
import { queryKeyes } from 'shared/queryKeys';
import { vesselVoyageReportingList } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import CreateVesselReport from './CreateVesselReport';
import apiGlobal from 'global/api.global';
import ViewReport from '../Media/VD2.png'
import ViewReportModal from '../Components/ViewReportModal';
import SubmitErrorPopup from 'Components/SubmitErrorPopup';
import { hasRole } from 'utils/auth/authUtils';
import { queryClient } from 'react-query/queryClient';
import ErrorComponent from 'Components/ErrorComponent';
import VesselTableHeadComponent from './VesselTableHeadComponent';
import env from 'environment_system/env_system';
import Loading from 'Components/Loading';
import { errResponse } from 'GenericForms/Helper';
import { errorToast } from 'Components/Toasts';
import PopOver from 'Components/PopOver';
import Message from 'Components/Message';

const OngoingReports = () => {
    //const [modal_backdrop_view, setmodal_backdrop_view] = useState(false);
    const [viewReport, setViewReport] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [submitPopover, setSubmitPopover] = useState(false);
    const [submitReport, setSubmitReport] = useState(0);
    const [reportId, setReportId] = useState<number>(0);
    const [hoverId, setHoverId] = useState<string | null>(null);
    const dispatch = useDispatch();
    const [tooltipOpen, setTooltipOpen] = useState(false);

    // State Varibles for the Vessels 
    const { VesselState, Voyages, VoyageID, VesselID } = useSelector((state: RootState) => state.Reporting);
    const voyage = Voyages.find((rec: any) => rec.id === VoyageID);
    const handleVesselState = (reportId: number, record: VesselState) => {
        dispatch(setReportID(reportId))
        dispatch(setVesselState(record))
    }

    /**
     * fucntion returns Vessel Voyage Reporting information 
     * @param number VesselID
     * @param number VoyageID
     * @returns mix
     */
    const { data: reports, isLoading: reportsLoading, isError: reportsError } = useQuery(
        [queryKeyes.vessel.vesselVoyageReporting.key, VesselID, VoyageID],
        async () => { return await vesselVoyageReportingList(VesselID, VoyageID) },
        { staleTime: Infinity }
    )

    /**
     * Load vessel software usage
     * @returns vessel software usage
     */
    // const { data: vesselSoftwareUsage } = useQuery(
    //     [queryKeyes.vessel.vesselSoftwareUsage.key],
    //     async () => { return await loadVesselSoftwareUsage() },
    //     { staleTime: Infinity }
    // )

    // const getVesselStatus = (vesselSoftwareUsage: any[], vesselID: any) => {
    //     return vesselSoftwareUsage?.filter((item) => item.vessel === vesselID);
    // };

    const isButtonDisabled = () => {
        // if (env.desktop_app) return false;
        // return !(vesselStatus?.[0]?.status_mode === VesselOnlineOfflineStatus.ONLINE);
        if (reports && reports?.length > 0 && reports[0]?.vessel_reporting_status === ReportingStatusConstant.OPEN) {
            return true;
        }
        return false
    };

    // const vesselStatus = getVesselStatus(vesselSoftwareUsage, VesselID);

    useEffect(() => {
        dispatch(getReportsAction('vessel_reporting_information' as string));
    }, [dispatch]);

    useEffect(() => {
        queryClient.invalidateQueries(queryKeyes.vessel.vesselVoyageReporting.key);
    }, [])

    const handleViewChanges = (id: number) => {
        setViewReport(true);
        setReportId(id);
    }

    const handleReportSubmit = async (report: any): Promise<any | null> => {
        try {
            const check = await apiGlobal.get(`vessel_reporting_information/check_previous_reports/?vessel_id=${VesselID}&vessel_reporting_information_id=${report.id}`)
            if (check.data.result === true) {
                /**Offline flag */
                if (env.desktop_app === false) {
                    await apiGlobal.post(`/submit/`, ({
                        vessel_id: VesselID,
                        vessel_reporting_information_id: report.id
                    })).then(() => {
                        apiGlobal.post(`/submit/`, ({
                            vessel_id: VesselID,
                            vessel_reporting_information_id: report.id
                        })).then((res) => {
                            if (res.status === 200 || res.status === 201) {
                                queryClient.invalidateQueries(queryKeyes.pagination.completeReportPage.key);
                            }
                        });
                    });
                }
                report.vessel_reporting_status = ReportingStatusConstant.SENT;
                const res = await apiGlobal.put(`/vessel_reporting_information/${report.id}/`, report);
                vesselVoyageReportingList(VesselID, VoyageID);
                setSubmitPopover(false);
                setReportId(null);
                return res.data;
            } else {
                setReportId(report.id);
                setSubmitError(true);
                setSubmitPopover(false);
            }
            queryClient.invalidateQueries(queryKeyes.pagination.completeReportPage.key)
            setSubmitPopover(false);
        } catch (err: any) {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
            setSubmitPopover(false);
            setReportId(null);
            return null;
        }
    };

    return (
        <React.Fragment>
            {(voyage && (voyage.voyage_status === VoyageConstant.ONGOING || voyage.voyage_status === VoyageConstant.NEWVOYAGE)) && (
                <div className='ele_row mb-2'>
                    <h4 id='owner_table' className='m-0'>Vessel Reporting Information</h4>
                    {((hasRole(Roles.ES_ADMIN) || hasRole(Roles.MASTER))) &&
                        <React.Fragment>
                            {/* Wrapper div for handling tooltip events */}
                            <div
                                id="create_vesssel_reporting"
                                onMouseEnter={() => {
                                    setTooltipOpen(true);
                                }}
                                onMouseLeave={() => {
                                    setTooltipOpen(false);
                                }}
                                style={{ display: "inline-block" }} // Prevents full-width div
                            >
                                <button
                                    color='primary'
                                    className='btn btn-primary'
                                    disabled={isButtonDisabled()}
                                    onClick={() => !isButtonDisabled() &&
                                        handleVesselState(-1, 'CREATE_VESSEL_REPORTING')
                                    }
                                >
                                    <i className="dripicons-plus font-size-16 align-middle me-2" />
                                    Create New
                                </button>
                            </div>
                            {/* {isButtonDisabled() &&
                                <ToolTip
                                    isOpen={tooltipOpen}
                                    target="tooltip-wrapper"
                                    message={`Vessel's software usage is set to offline. Online reporting is disabled.`}
                                />} */}
                            {isButtonDisabled() &&
                                <ToolTip
                                    isOpen={tooltipOpen}
                                    target="create_vesssel_reporting"
                                    message="Please submit the ongoing report first"
                                />
                            }
                        </React.Fragment>
                    }
                </div>
            )}
            <CardBody className='p-0'>
                <div className="table-responsive" id='owner_table'>
                    {reportsLoading && <Loading message='Loading required data!' />}
                    {reportsError && <ErrorComponent message="Error loading required data!" />}
                    {((!reportsLoading && !reportsError)) &&
                        <table className="table mb-0">
                            <VesselTableHeadComponent reportStatus="ongoing" />
                            <tbody>
                                {reports.length > 0 ? (
                                    reports
                                        ?.sort((a: any, b: any) => b.id - a.id)
                                        .filter((item: any) => item.vessel_reporting_status === ReportingStatusConstant.OPEN)
                                        .map((report: any, index: number) => (
                                            <React.Fragment key={report.id}>
                                                <tr className="">
                                                    <td className="p-2 align-middle text-center">{index + 1}</td>
                                                    <td className="p-2 align-middle">{report.reporting_event_name}</td>
                                                    <td className="p-2 align-middle text-center">{report.reporting_type}</td>
                                                    <td className="p-2 align-middle text-center">
                                                        {report?.local_time?.slice(0, 10)} {report?.local_time?.slice(11, 16)}
                                                    </td>
                                                    <td className="p-2 align-middle text-center">
                                                        <img
                                                            alt="View Report"
                                                            className={`align-middle pointer ${hasRole(Roles.CHIEFENGINEER) && 'justify_right me-2'}`}
                                                            src={ViewReport}
                                                            height="29"
                                                            id={`View_Details_${report.id}`}
                                                            onMouseEnter={() => setHoverId(`View_Details_${report.id}`)}
                                                            onMouseLeave={() => setHoverId(null)}
                                                            onClick={() => {
                                                                handleViewChanges(report.id);
                                                                setHoverId(null);
                                                            }}
                                                        />
                                                    </td>
                                                    {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MASTER)) && (
                                                        <td className="p-2 align-middle text-center">
                                                            <img
                                                                src={EditIcon}
                                                                alt="Edit Icon"
                                                                height="28"
                                                                className="pointer"
                                                                id={`Edit_Details_${report.id}`}
                                                                onMouseEnter={() => setHoverId(`Edit_Details_${report.id}`)}
                                                                onMouseLeave={() => setHoverId(null)}
                                                                onClick={() => {
                                                                    handleVesselState(report.id, 'EDIT');
                                                                    setHoverId(null);
                                                                }}
                                                            />
                                                        </td>
                                                    )}
                                                    {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MASTER)) && (
                                                        <td className="p-2 align-middle text-center">
                                                            <img
                                                                alt="Deck Officer Report"
                                                                className="pointer"
                                                                src={report.officer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE ? CaptainBlue : CaptainIcon}
                                                                height="30"
                                                                id={`Deck_Officer_Reporting_${report.id}`}
                                                                onMouseEnter={() => setHoverId(`Deck_Officer_Reporting_${report.id}`)}
                                                                onMouseLeave={() => setHoverId(null)}
                                                                onClick={() => {
                                                                    handleVesselState(report.id, 'DO_REPORTING');
                                                                    setHoverId(null);
                                                                }}
                                                            />
                                                        </td>
                                                    )}
                                                    {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.CHIEFENGINEER)) && (
                                                        <td className="p-2 align-middle text-center">
                                                            <img
                                                                alt="Engineer Report"
                                                                src={report.engineer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE ? EngineerBlue : EngineerIcon}
                                                                className={`pointer ${hasRole(Roles.CHIEFENGINEER) && 'justify_left'}`}
                                                                height="30"
                                                                id={`Engineer_Reporting_${report.id}`}
                                                                onMouseEnter={() => setHoverId(`Engineer_Reporting_${report.id}`)}
                                                                onMouseLeave={() => setHoverId(null)}
                                                                onClick={() => {
                                                                    handleVesselState(report.id, 'ENGG_REPORTING');
                                                                    setHoverId(null);
                                                                }}
                                                            />
                                                        </td>
                                                    )}
                                                    {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MASTER)) && (
                                                        <td className="p-2 align-middle text-center">
                                                            {report.officer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE &&
                                                                report.engineer_reporting_status === DO_ENGG_ReportingStatus.COMPLETE && (
                                                                    <i
                                                                        className="bx bx-send icon_s23 align-middle pointer ms-2"
                                                                        id={`Submit_Record_${report.id}`}
                                                                        onMouseEnter={() => setHoverId(`Submit_Record_${report.id}`)}
                                                                        onMouseLeave={() => setHoverId(null)}
                                                                        onClick={() => {
                                                                            setSubmitPopover(true);
                                                                            setSubmitReport(report);
                                                                            setReportId(report.id);
                                                                            setHoverId(null);
                                                                        }}
                                                                    />
                                                                )}
                                                            {reportId === report.id && submitError && (
                                                                <SubmitErrorPopup setState={setSubmitError} state={submitError} modalId={(report.id).toString()} />
                                                            )}
                                                        </td>
                                                    )}
                                                    {reportId === report.id && viewReport && (
                                                        <ViewReportModal
                                                            state={viewReport}
                                                            setState={setViewReport}
                                                            vesselId={VesselID}
                                                            reportId={reportId}
                                                            modalId={`${VesselID}${reportId}`}
                                                        />
                                                    )}
                                                </tr>
                                            </React.Fragment>
                                        ))
                                ) : (
                                    <tr>
                                        <td colSpan={12} className="text-center p-3">
                                            <Message message="No ongoing reports available at the moment." type="danger" width="500px" />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                    {hoverId !== null &&
                        <ToolTip
                            target={hoverId}
                            message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                            isOpen={hoverId !== null}
                        />
                    }
                </div>
            </CardBody>
            {VesselState === 'EDIT' && <EditVesselReport />}
            {VesselState === 'VOYAGE_REPORTING' && <VoyageInformation />}
            {VesselState === 'CREATE_VESSEL_REPORTING' && <CreateVesselReport />}
            {submitPopover &&
                < PopOver
                    target={`Submit_Record_${reportId}`}
                    state={submitPopover}
                    setState={setSubmitPopover}
                    message="Are you sure you want to submit the form?"
                    handleClick={() => handleReportSubmit(submitReport)}
                />
            }
        </React.Fragment >
    )
}

export default OngoingReports