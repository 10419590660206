import React, { useEffect, useState } from "react";
import { Col, Row, Input, Label, Form, Button, Card } from "reactstrap";
import * as Yup from "yup";
import { FormikProps, useFormik } from "formik";
import "../../../global/GlobalCSS.css";
import "flatpickr/dist/themes/material_blue.css";
import { apiMedia } from '../../../global/api.global';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { loadHullTypes, loadVesselGenInfoObject, loadVesselOwners } from 'VesselMaster/vesselMaster.hooks';
import { errorToast, successToast } from '../../../Components/Toasts';
import { customStyle } from '../../../shared/CommonCSS';
import { checkEmptyStringValue, errResponse, searchPorts } from 'GenericForms/Helper';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { queryClient } from 'react-query/queryClient';
import { RootState } from 'index';
import Loading from 'Components/Loading';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import env from 'environment_system/env_system'
import ErrorTooltip from "Components/ErrorTooltip";
import ReportingFileUpload from "Components/ReportingFileUpload";
import { FileItem } from "shared/CommonInterface";
import Select from "react-select";
import { isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { FileStatus, VesselConfigrationTabs, VesselDataConstants } from "shared/constants";
import { VesselImages } from "shared/constants";
import isEqual from "fast-deep-equal";

interface VesselGeneralInfoTypes {
    toggleTab?: (activeTab: number) => void,
    activeTab?: number | string,
    VesselConfActiveTab?: number,
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    setTabFormik?: (value: FormikProps<any>) => void;
    refreshForm?: boolean;
    setDisableYesButtonInsideTab?: (value: boolean) => void;
    saveBeforeChangePopUpInsideTab?: boolean;
}

const VesselGeneralInfo = ({
    activeTab,
    VesselConfActiveTab,
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    setTabFormik,
    refreshForm,
    setDisableYesButtonInsideTab,
    saveBeforeChangePopUpInsideTab,
}: VesselGeneralInfoTypes) => {
    /** State variables start */
    const { VesselID, Vessels } = useSelector(
        (state: RootState) => state.Reporting
    );
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const [images, setImages] = useState<any[]>([{}]);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const [fileLength, setFileLength] = useState<number>(0);

    /** State variables end */
    const handleSetFile = (files: any[]) => {
        const adaptedFiles: FileItem[] = files.map(file => ({
            name: file.name || '',
            preview: file.preview || '',
            file: file.file || null,
        }));
        setImages(adaptedFiles); // Update the state with the transformed data
    };
    /** Queries start */
    /** Vessel general info object used for edit */
    const {
        data: VesselGenInfoObject,
        isLoading: VesselGenInfoObjectLoading,
        isError: VesselGenInfoObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.VesselGenInfoObject.key, VesselID],
        async () => {
            return await loadVesselGenInfoObject(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Vessel owner list from master */
    const { data: VesselOwners, isLoading: VesselOwnersLoading }:
        { data: any[], isLoading: any } = useQuery(
            [queryKeyes.masters.VesselOwnerMaster.key],
            async () => {
                return await loadVesselOwners();
            },
            {
                enabled: true,
                staleTime: Infinity,
            }
        )
    /** Hull types list from master */
    const { data: HullTypes, isLoading: hullTypesLoading }:
        { data: any[], isLoading: any } = useQuery(
            [queryKeyes.masters.HullTypeMaster.key],
            async () => {
                return await loadHullTypes();
            },
            {
                enabled: true,
                staleTime: Infinity,
            }
        )
    /** Queries end */

    /**useEffect start */
    useEffect(() => {
        VesselGenInfoFormik.initialValues = getInitialValues();
        VesselGenInfoFormik.values = getInitialValues();
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselGenInfoObject]);

    useEffect(() => {
        if (images && VesselGenInfoObject && VesselGenInfoObject[0] && VesselGenInfoObject[0]?.images) {
            const totalImageLength = images.filter(image => image.file)?.length + VesselGenInfoObject[0].images?.length;
            setFileLength(totalImageLength)
        }
    }, [images, VesselGenInfoObject]);
    /** useEffect end */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        if (VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0) {
            return VesselGenInfoObject[0];
        } else {
            return {
                call_sign: "",
                date_of_built: null,
                official_number: "",
                hull_number: "",
                dead_weight: null,
                vessel_description: "",
                images: null,
                vessel: vessel?.id,
                country_name: null,
                port_of_registry: null,
                vessel_owner: null,
                hull_type_name: null,
                gross_tonage: null,
                net_tonage: null,
                normal_ballast_fwd_draft: 0,
                normal_ballast_mid_draft: 0,
                normal_ballast_aft_draft: 0,
                normal_laden_fwd_draft: 0,
                normal_laden_mid_draft: 0,
                normal_laden_aft_draft: 0,
            };
        }
    };

    /** Vessel General Info Formik Object */
    const VesselGenInfoFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(),
        validationSchema: Yup.object().shape({
            call_sign: Yup.string().nullable(),
            //     .matches(
            //     /^[A-Za-z0-9][a-zA-Z0-9-_.]{1,7}$/,
            //     VesselGenInfoMessages.call_sign
            // ),
            official_number: Yup.string().nullable(),
            // .matches(
            //     /^[0-9]{1,10}$/,
            //     VesselGenInfoMessages.official_number
            // ),
            hull_number: Yup.string().nullable(),
            //     .matches(
            //     /^[A-Za-z0-9][a-zA-Z0-9-_.]{11}$/,
            //     "Please enter 12 aplphabets/digits only"
            // ),
            dead_weight: Yup.string()
                .required(commonValidationMessages.required)
                .matches(/^[0-9]+$/, commonValidationMessages.number)
                .max(8, commonValidationMessages.max8),
            gross_tonage: Yup.string()
                .required(commonValidationMessages.required)
                .matches(/^[0-9]+$/, commonValidationMessages.number)
                .max(8, commonValidationMessages.max8),
            net_tonage: Yup.string()
                .required(commonValidationMessages.required)
                .matches(/^[0-9]+$/, commonValidationMessages.number)
                .max(8, commonValidationMessages.max8),
            port_of_registry: Yup.number().required(commonValidationMessages.required)
        }),
        onSubmit: () => {
            VesselGenInfoFormik.setSubmitting(true); // Start spinner
            const formData = new FormData();
            Object.keys(VesselGenInfoFormik?.values).forEach((item: any) => {
                if (VesselGenInfoFormik?.values[item] === null || undefined) return; // if null assign blank string
                formData.append(item, VesselGenInfoFormik?.values[item]);// else continue with value
            });
            images.forEach((image: any,) => {
                /** append file to formData only if not null */
                if (image.file !== null && image.file !== undefined) {
                    formData.append("image", image.file)
                }
            })
            const savePromise = VesselGenInfoObject && VesselGenInfoObject[0]?.id
                ? apiMedia.put(`vessel_general_information/${VesselGenInfoObject[0]?.id}/`, formData)
                : apiMedia.post(`vessel_general_information/`, formData);
            savePromise
                .then((res: any) => {
                    if (res.status === 200 || 201) {
                        successToast("Data saved successfully!");
                        queryClient.invalidateQueries(queryKeyes.vessel.VesselGenInfoObject.key);
                        setImages([{}])
                        setRefreshKey(refreshKey + 1);
                    }
                })
                .catch((err: any) => {
                    if (errResponse.includes(err?.response?.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                    setImages([{}])
                    setRefreshKey(refreshKey + 1);
                })
            VesselGenInfoFormik?.setSubmitting(false);
        }
    })

    const tabName = 'Vessel General Info';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = isEqual(values, initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
    };

    /** Effects start */
    useEffect(() => {
        if (activeTab === VesselDataConstants.VESSEL_GENERAL_INFO && VesselGenInfoFormik.values !== VesselGenInfoFormik.initialValues) {
            Object.keys(VesselGenInfoFormik?.errors).length > 0? setDisableYesButtonInsideTab(true) : setDisableYesButtonInsideTab(false);
            setTabFormik(VesselGenInfoFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, VesselGenInfoFormik?.values, VesselGenInfoFormik?.errors]);
    useEffect(() => {
        if (VesselConfActiveTab === VesselConfigrationTabs.VESSEL_DATA && activeTab !== VesselDataConstants.VESSEL_GENERAL_INFO) {
            VesselGenInfoFormik?.setTouched({})
        }
        if (VesselConfActiveTab !== VesselConfigrationTabs.VESSEL_DATA) {
            VesselGenInfoFormik?.setTouched({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, VesselConfActiveTab]);
    useEffect(() => {
        if (setCheckValuesBeforeSwitch) {
            handleFormikStateChange(VesselGenInfoFormik?.values, VesselGenInfoFormik?.initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselGenInfoFormik?.values, VesselGenInfoFormik?.initialValues]);
    /** Effects end */

    if (VesselGenInfoObjectLoading || VesselOwnersLoading || hullTypesLoading) {
        return <Loading message="Loading required info!" />;
    } else {
        return (
            <React.Fragment>
                {VesselGenInfoObjectLoading && (
                    <Loading message="Loading required info!" />
                )}
                {VesselGenInfoObjectError && getInitialValues()}
                {!VesselGenInfoObjectLoading &&
                    <Form onSubmit={VesselGenInfoFormik?.handleSubmit} autoComplete='off' noValidate key={`vessel_genral-info-${refreshKey}`}>
                        <Row className='mb-2'>
                            <Col lg={4}>
                                <Label>Vessel name</Label>
                                <h5>{vessel?.vessel_name}</h5>
                            </Col>
                            <Col lg={4}>
                                <Label>IMO number</Label>
                                <h5>{vessel?.IMO_number}</h5>
                            </Col>
                            <Col lg={4}>
                                <Label>Vessel type</Label>
                                <h5>{vessel?.vessel_type}</h5>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col lg={3}>
                                <Label>Select flag administrator</Label>
                                <h5>{VesselGenInfoFormik?.values?.country}</h5>
                            </Col>
                            <Col lg={3}>
                                <Label className="mb-0 asteric">Enter port of registry</Label>
                                <AsyncSelect
                                    name="port_of_registry"
                                    cacheOptions
                                    defaultOptions
                                    inputId="port_of_registry"
                                    key={`${refreshForm}-${refreshKey}`}
                                    loadOptions={(e: any) => searchPorts(e)}
                                    getOptionLabel={(e: any) => e.port_name}
                                    getOptionValue={(e: any) => e.id}
                                    onChange={(selectedOption: any) => {
                                        VesselGenInfoFormik?.setFieldValue("port_of_registry", selectedOption.id);
                                        VesselGenInfoFormik?.setFieldValue("country_name", selectedOption.country_name)
                                        VesselGenInfoFormik?.setFieldValue("country", selectedOption.country)
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={customStyle}
                                    defaultValue={
                                        VesselGenInfoObject &&
                                        VesselGenInfoObject[0]?.id > 0 && {
                                            id: VesselGenInfoObject[0]?.port_of_registry,
                                            port_name: VesselGenInfoObject[0]?.port_name,
                                        }
                                    }
                                    noOptionsMessage={(e: any) => {
                                        if (e?.inputValue?.toString()?.length > 2) {
                                            return "Please select the Other option and enter the port name in the textbox provided";
                                        }
                                        return "Please enter the first 3 characters of port name";
                                    }}
                                />
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.port_of_registry && (
                                    <ErrorTooltip
                                        target="port_of_registry"
                                        message={VesselGenInfoFormik?.errors?.port_of_registry as string}
                                        open={!saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.port_of_registry as string ? true : false} 
                                    />
                                )}
                            </Col>
                            <Col lg={3}>
                                <Label className="mb-0">Select vessel owner</Label>
                                <Select
                                    name={`vessel_owner`}
                                    inputId={`vessel_owner`}
                                    options={VesselOwners}
                                    getOptionLabel={(option: any) => {
                                        const companyName = option.company_name;
                                        return `${companyName}`;
                                    }}
                                    getOptionValue={(option: any) => option.id}
                                    isClearable={true}
                                    onChange={(e: any) => VesselGenInfoFormik?.setFieldValue("vessel_owner", e?.id || null)}
                                    menuPortalTarget={document.body}
                                    styles={customStyle}
                                    defaultValue={
                                        VesselGenInfoObject && VesselGenInfoObject[0]?.id > 0 && VesselGenInfoObject[0]?.vessel_owner
                                            ? {
                                                id: VesselGenInfoObject[0]?.vessel_owner,
                                                company_name: `${VesselGenInfoObject[0]?.vessel_owner_name} (${VesselGenInfoObject[0]?.vessel_owner})`,
                                            }
                                            : null
                                    }
                                />
                            </Col>
                            <Col lg={3}>
                                <Label className="mb-0">Call sign</Label>
                                <Input
                                    type="text"
                                    name="call_sign"
                                    id="call_sign"
                                    className="form-control max-width-8"
                                    value={VesselGenInfoFormik.values.call_sign}
                                    onChange={VesselGenInfoFormik.handleChange}
                                    onBlur={VesselGenInfoFormik.handleBlur}
                                />
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.call_sign && (
                                    <ErrorTooltip
                                        target="call_sign"
                                        message={VesselGenInfoFormik?.errors?.call_sign as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.call_sign as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col lg={3}>
                                <Label className="mb-0">Year of Built</Label>
                                <Select
                                    name="date_of_built"
                                    id="date_of_built" // Provide the id for accessibility
                                    value={
                                        VesselGenInfoFormik?.values?.date_of_built
                                            ? { value: VesselGenInfoFormik?.values?.date_of_built, label: VesselGenInfoFormik?.values?.date_of_built }
                                            : null
                                    }
                                    onChange={(selectedOption) => {
                                        const value = selectedOption ? selectedOption.value : '';
                                        VesselGenInfoFormik.setFieldValue("date_of_built", value); // Update the form state directly
                                    }}
                                    options={Array.from({ length: 100 }, (_, i) => {
                                        const year = new Date().getFullYear() - i;
                                        return { value: year, label: year.toString() }; // Ensure `label` is a string
                                    })}
                                    placeholder="Select Year"
                                    filterOption={(candidate, input) => {
                                        if (!input) return true; // Show all options if there's no input
                                        const inputValue = typeof input === 'string' ? input.toLowerCase() : '';
                                        const candidateValue = typeof candidate.label === 'string' ? candidate.label.toLowerCase() : '';
                                        return candidateValue.includes(inputValue);
                                    }}
                                />
                            </Col>
                            <Col lg={3}>
                                <Label className="mb-0">Official number</Label>
                                <Input
                                    type="text"
                                    name="official_number"
                                    id="official_number"
                                    className="form-control"
                                    value={VesselGenInfoFormik.values.official_number} // Bind value
                                    onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                    onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                />
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.official_number && (
                                    <ErrorTooltip
                                        target="official_number"
                                        message={VesselGenInfoFormik?.errors?.official_number as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.official_number as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                            <Col lg={3}>
                                <Label className="mb-0">Select hull type</Label>
                                <Select
                                    name="hull_type_name"
                                    id="hull_type_name"
                                    options={HullTypes}
                                    getOptionLabel={(e: any) => e.hull_type_name}
                                    getOptionValue={(e: any) => e.id}
                                    onChange={(e: any) =>
                                        VesselGenInfoFormik?.setFieldValue("hull_type_name", e.id)
                                    }
                                    menuPortalTarget={document.body}
                                    defaultValue={
                                        VesselGenInfoObject &&
                                        VesselGenInfoObject[0]?.id > 0 && {
                                            id: VesselGenInfoObject[0]?.hull_type_name,
                                            hull_type_name: VesselGenInfoObject[0]?.hull_type,
                                        }
                                    }
                                />
                            </Col>
                            <Col lg={3}>
                                <Label className="mb-0">Hull number</Label>
                                <Input
                                    type="text"
                                    name="hull_number"
                                    id="hull_number"
                                    className="form-control max-width-8"
                                    value={VesselGenInfoFormik.values.hull_number}
                                    onChange={VesselGenInfoFormik.handleChange}
                                    onBlur={VesselGenInfoFormik.handleBlur}
                                />
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.hull_number && (
                                    <ErrorTooltip
                                        target="hull_number"
                                        message={VesselGenInfoFormik?.errors?.hull_number as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.hull_number as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Row>
                                <Col lg={2}>
                                    <Label className="mb-0 asteric">Deadweight</Label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            name="dead_weight"
                                            id="dead_weight"
                                            className="form-control max-width-8 text-right"
                                            value={VesselGenInfoFormik.values.dead_weight} // Bind value
                                            onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                            onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                        />
                                        <div className="input-group-text">
                                            mt
                                        </div>
                                    </div>
                                    {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.dead_weight && (
                                        <ErrorTooltip
                                            target="dead_weight"
                                            message={VesselGenInfoFormik?.errors?.dead_weight as string}
                                            open={
                                                !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                                VesselGenInfoFormik?.errors?.dead_weight as string
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                                </Col>
                                <Col lg={2}>
                                    <Label className="mb-0 asteric">Gross tonnage</Label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            name="gross_tonage"
                                            id="gross_tonage"
                                            className="form-control max-width-8 text-right"
                                            value={VesselGenInfoFormik.values.gross_tonage} // Bind value
                                            onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                            onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                        />

                                        <div className="input-group-text">
                                            mt
                                        </div>
                                    </div>
                                    {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.gross_tonage && (
                                        <ErrorTooltip
                                            target="gross_tonage"
                                            message={VesselGenInfoFormik?.errors?.gross_tonage as string}
                                            open={
                                                !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                                VesselGenInfoFormik?.errors?.gross_tonage as string
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                                </Col>
                                <Col lg={2} >
                                    <Label className="mb-0 asteric">Net tonnage</Label>
                                    <div className="input-group">
                                        <Input
                                            type="text"
                                            name="net_tonage"
                                            id="net_tonage"
                                            className="form-control max-width-8 text-right"
                                            value={VesselGenInfoFormik.values.net_tonage} // Bind value
                                            onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                            onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                        />
                                        <div className="input-group-text">
                                            mt
                                        </div>
                                    </div>
                                    {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.net_tonage && (
                                        <ErrorTooltip
                                            target="net_tonage"
                                            message={VesselGenInfoFormik?.errors?.net_tonage as string}
                                            open={
                                                !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                                VesselGenInfoFormik?.errors?.net_tonage as string
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                                </Col>
                                <Col lg={4}>
                                    <Label className="mb-0 ms-2">Vessel description</Label>
                                    <Input
                                        name="vessel_description"
                                        type="text"
                                        id="vessel_description"
                                        className="form-control ms-2"
                                        value={VesselGenInfoFormik.values.vessel_description} // Bind value correctly
                                        onChange={(e: any) => {
                                            checkEmptyStringValue(e, "vessel_description", VesselGenInfoFormik.setFieldValue);
                                        }} // Handle changes
                                        onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                        rows={4}
                                    />
                                    {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.vessel_description && (
                                        <ErrorTooltip
                                            target="vessel_description"
                                            message={VesselGenInfoFormik?.errors?.vessel_description as string}
                                            open={
                                                !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                                !!VesselGenInfoFormik?.errors?.vessel_description
                                            }
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Row>
                        <Row className="mt-2">
                            <Col lg={6}>
                                <h5>Normal ballast draft</h5>
                            </Col>
                            <Col lg={6}>
                                <h5>Normal laden draft</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2}>
                                <Label className='mb-0 '>Fwd draft </Label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="normal_ballast_fwd_draft"
                                        id="normal_ballast_fwd_draft"
                                        className="form-control max-width-8 text-right"
                                        value={VesselGenInfoFormik.values.normal_ballast_fwd_draft} // Bind value
                                        onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                        onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                    />
                                    <div className="input-group-text">
                                        m
                                    </div>
                                </div>
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.normal_ballast_fwd_draft && (
                                    <ErrorTooltip
                                        target="normal_ballast_fwd_draft"
                                        message={VesselGenInfoFormik?.errors?.normal_ballast_fwd_draft as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.normal_ballast_fwd_draft as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                            <Col lg={2}>
                                <Label className='mb-0'>Mid draft </Label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="normal_ballast_mid_draft"
                                        id="normal_ballast_mid_draft"
                                        className="form-control max-width-8 text-right"
                                        value={VesselGenInfoFormik.values.normal_ballast_mid_draft} // Bind value
                                        onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                        onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                    />
                                    <div className="input-group-text">
                                        m
                                    </div>
                                </div>
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.normal_ballast_mid_draft && (
                                    <ErrorTooltip
                                        target="normal_ballast_mid_draft"
                                        message={VesselGenInfoFormik?.errors?.normal_ballast_mid_draft as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.normal_ballast_mid_draft as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                            <Col lg={2}>
                                <Label className='mb-0 '>Aft draft </Label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="normal_ballast_aft_draft"
                                        id="normal_ballast_aft_draft"
                                        className="form-control max-width-8 text-right"
                                        value={VesselGenInfoFormik.values.normal_ballast_aft_draft} // Bind value
                                        onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                        onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                    />
                                    <div className="input-group-text">
                                        m
                                    </div>
                                </div>
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.normal_ballast_aft_draft && (
                                    <ErrorTooltip
                                        target="normal_ballast_aft_draft"
                                        message={VesselGenInfoFormik?.errors?.normal_ballast_aft_draft as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.normal_ballast_aft_draft as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                            <Col lg={2}>
                                <Label className="mb-0 ">Fwd draft</Label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="normal_laden_fwd_draft"
                                        id="normal_laden_fwd_draft"
                                        className="form-control max-width-8 text-right"
                                        value={VesselGenInfoFormik.values.normal_laden_fwd_draft} // Bind value
                                        onChange={(e) => {
                                            // VesselGenInfoFormik?.handleChange();
                                            checkEmptyStringValue(e, "normal_laden_fwd_draft", VesselGenInfoFormik.setFieldValue);
                                        }} // Handle changes
                                        onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                    />
                                    <div className="input-group-text">
                                        m
                                    </div>
                                </div>
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.normal_laden_fwd_draft && (
                                    <ErrorTooltip
                                        target="normal_laden_fwd_draft"
                                        message={VesselGenInfoFormik?.errors?.normal_laden_fwd_draft as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.normal_laden_fwd_draft as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                            <Col lg={2}>
                                <Label className="mb-0 ">Mid draft</Label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="normal_laden_mid_draft"
                                        id="normal_laden_mid_draft"
                                        className="form-control max-width-8 text-right"
                                        value={VesselGenInfoFormik.values.normal_laden_mid_draft} // Bind value
                                        onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                        onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                    />
                                    <div className="input-group-text">
                                        m
                                    </div>
                                </div>
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.normal_laden_mid_draft && (
                                    <ErrorTooltip
                                        target="normal_laden_mid_draft"
                                        message={VesselGenInfoFormik?.errors?.normal_laden_mid_draft as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.normal_laden_mid_draft as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                            <Col lg={2}>
                                <Label className="mb-0 ">Aft draft</Label>
                                <div className="input-group">
                                    <Input
                                        type="text"
                                        name="normal_laden_aft_draft"
                                        id="normal_laden_aft_draft"
                                        className="form-control max-width-8 text-right"
                                        value={VesselGenInfoFormik.values.normal_laden_aft_draft} // Bind value
                                        onChange={VesselGenInfoFormik.handleChange} // Handle changes
                                        onBlur={VesselGenInfoFormik.handleBlur} // Handle blur events
                                    />
                                    <div className="input-group-text">
                                        m
                                    </div>
                                </div>
                                {VesselGenInfoFormik?.errors && env?.form_validation === true && VesselGenInfoFormik?.touched?.normal_laden_aft_draft && (
                                    <ErrorTooltip
                                        target="normal_laden_aft_draft"
                                        message={VesselGenInfoFormik?.errors?.normal_laden_aft_draft as string}
                                        open={
                                            !saveBeforeChangePopUpInsideTab && activeTab === VesselDataConstants.VESSEL_GENERAL_INFO &&
                                            VesselGenInfoFormik?.errors?.normal_laden_aft_draft as string
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                            </Col>
                        </Row>
                        <Row className='mb-2 pt-2'>
                            <Card>
                                <ReportingFileUpload
                                    setFile={handleSetFile}
                                    file={images}
                                    fileUploadStatus={VesselGenInfoFormik.values.status === FileStatus.UPLOADED}
                                    fileURL={VesselGenInfoFormik.values.images}
                                    deleteURL={`/vessel_image/`}
                                    invalidateQuery={queryKeyes.vessel.VesselGenInfoObject.key}
                                    deleteID={vessel?.id}
                                    refreshKey={refreshKey}
                                    setRefreshKey={setRefreshKey}
                                    isFileUploadDisable={VesselImages.maxFile === fileLength}
                                    DeleteFileObj={VesselGenInfoFormik.values.images}
                                    index={0}
                                    isMultiple={true}
                                    maxFiles={VesselImages.maxFile}
                                    CustomAcceptableFileTypes={{
                                        'image/jpeg': ['.jpg', '.jpeg'],
                                        'image/png': ['.png']
                                    }}
                                />
                            </Card>
                        </Row><Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size_6_cstm"
                                    disabled={isConfigurationButtonDisabled(vessel)
                                        || VesselGenInfoFormik?.isSubmitting
                                    }
                                >
                                    {VesselGenInfoFormik?.isSubmitting &&
                                        (<i className="me-2 spinner-border spinner-border-sm text-light" />)}
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size_6_cstm"
                                    onClick={() => {
                                        VesselGenInfoFormik?.resetForm();
                                    }}
                                    disabled={isConfigurationButtonDisabled(vessel)}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Row>
                        <FormValuesDebug
                            values={[
                                VesselGenInfoFormik?.values,
                                VesselGenInfoFormik?.errors,
                                VesselGenInfoFormik.initialValues,
                            ]}
                        />
                    </Form>
                }
            </React.Fragment >

        )
    }
}

export default VesselGeneralInfo;