import React, { createContext, useContext } from 'react'
import ErrorTooltip from 'Components/ErrorTooltip';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import env from "environment_system/env_system";
import { VesselTypeConstant } from 'shared/constants';
import PopOver from 'Components/PopOver';
import { useSelector } from 'react-redux';
import { RootState } from 'index';
import PopUp from 'Components/PopUp';
import LNGCargoQuality from 'Components/LNGCargoQuality';

const LoadingCTMSContext = createContext(null);
const DischargingCTMSContext = createContext(null);
export const UpdateLoadingCTMS = () => {
    return useContext(LoadingCTMSContext);
};

export const UpdateDischargingCTMS = () => {
    return useContext(DischargingCTMSContext);
};

interface CargoDetailsProps {
    alertBool: boolean;
    handleCargoOnboardBlur: any,
    updateTotalCargoOnboard: any,
    setLoadingPopOverBool: any,
    setLoadingPopUpBool: any,
    loadingPopOverBool: boolean,
    loadingPopUpBool: boolean,
    setLoadingCTMS: any,
    AmountOfCargoDischagedOnBlur: any,
    setDischargingPopOverBool: any,
    setDischargingPopUpBool: any,
    setDischargingCTMS: any,
    dischargingPopUpBool: boolean,
    dischargingPopOverBool: boolean,
    LNGCargoPreviousROB: any
}

const CargoDetails = ({
    alertBool,
    handleCargoOnboardBlur,
    updateTotalCargoOnboard,
    setLoadingPopOverBool,
    setLoadingPopUpBool,
    loadingPopOverBool,
    loadingPopUpBool,
    setLoadingCTMS,
    AmountOfCargoDischagedOnBlur,
    setDischargingPopOverBool,
    setDischargingPopUpBool,
    setDischargingCTMS,
    dischargingPopUpBool,
    dischargingPopOverBool,
    LNGCargoPreviousROB
}: CargoDetailsProps) => {
    const { values, errors, touched, handleChange, handleBlur }:
        { values: any, errors: any, touched: any, handleChange: any, handleBlur: any } = useFormikContext<any>();
    const { VesselID, Vessels, ReportID } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    return (
        <Card className="mb-0 border-0">
            <CardHeader className="py-2 px-0">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Cargo Details
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <CardBody className="px-0 py-0 mt-2">
                <Row>
                    <Col sm={2}>
                        <Label
                            className="asteric mb-0"
                            for="amount_of_cargo_loaded"
                        >
                            Cargo loaded
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="amount_of_cargo_loaded_msg"
                            ></i>
                        </Label>
                        <ToolTip
                            target="amount_of_cargo_loaded_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) =>
                                    item.target === "amount_of_cargo_loaded_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group">
                            <Field name="cargo_detail.amount_of_cargo_loaded">
                                {({ field, form }: FieldProps) => (
                                    <Input
                                        type="text"
                                        className="form-control text-right"
                                        id="amount_of_cargo_loaded"
                                        name={field.name}
                                        onBlur={(e: any) => {
                                            handleBlur(e);
                                            if (e.target.value !== null && values?.cargo_detail?.total_amount_of_cargo_onboard !== null) {
                                                handleCargoOnboardBlur(
                                                    parseFloat(values?.cargo_detail?.total_amount_of_cargo_onboard),
                                                    parseFloat(e.target.value),
                                                    parseFloat(values?.cargo_detail?.amount_of_cargo_discharged)
                                                );
                                            }
                                            handleChange(e);
                                            if (
                                                e.target.value !== null &&
                                                e.target.value > 0
                                            ) {
                                                form.setFieldValue(
                                                    "cargo_detail.loading",
                                                    true
                                                );
                                            } else {
                                                form.setFieldValue(
                                                    "cargo_detail.loading",
                                                    false
                                                );
                                            }
                                            if (
                                                vessel?.vessel_type ===
                                                VesselTypeConstant.LNG_CARRIER
                                            ) {
                                                updateTotalCargoOnboard(
                                                    form,
                                                    values,
                                                    e,
                                                    "loading"
                                                );
                                            }
                                        }}
                                        onChange={(e: any) => {
                                            handleChange(e);
                                            if (
                                                vessel?.vessel_type ===
                                                VesselTypeConstant.LNG_CARRIER
                                            ) {
                                                updateTotalCargoOnboard(
                                                    form,
                                                    values,
                                                    e,
                                                    "loading"
                                                );
                                            }
                                        }}
                                        defaultValue={
                                            values?.cargo_detail
                                                ?.amount_of_cargo_loaded
                                        }
                                    />
                                )}
                            </Field>
                            {vessel?.vessel_type ===
                                VesselTypeConstant.CONTAINER ? (
                                <div className="input-group-text round_border">
                                    TEU
                                </div>
                            ) : (
                                <div className="input-group-text round_border">
                                    mt
                                </div>
                            )}
                        </div>
                        {errors?.cargo_detail &&
                            touched?.cargo_detail?.amount_of_cargo_loaded &&
                            errors?.cargo_detail?.amount_of_cargo_loaded &&
                            !alertBool &&
                            env?.form_validation === true && (
                                <ErrorTooltip
                                    target="amount_of_cargo_loaded"
                                    message={
                                        errors?.cargo_detail
                                            ?.amount_of_cargo_loaded
                                    }
                                    open={
                                        errors?.cargo_detail &&
                                            errors?.cargo_detail
                                                ?.amount_of_cargo_loaded
                                            ? true
                                            : false
                                    }
                                />
                            )}
                        {vessel?.vessel_type ===
                            VesselTypeConstant.LNG_CARRIER &&
                            values?.cargo_detail?.loading === true && (
                                <Col sm={12}>
                                    <div className="ele_row1">
                                        <Field name="cargo_detail.loading_completion">
                                            {({ field, form }: FieldProps) => (
                                                <Input
                                                    type="checkbox"
                                                    id="loading_completion"
                                                    name={field.name}
                                                    onChange={(e: any) => {
                                                        form.setFieldValue(
                                                            field.name,
                                                            e.target.checked
                                                        );
                                                        if (e.target.checked === true) {
                                                            setLoadingPopOverBool(true);
                                                        } else {
                                                            setLoadingPopOverBool(false);
                                                        }
                                                    }}
                                                    defaultChecked={
                                                        values?.cargo_detail
                                                            ?.loading_completion
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <Label className="ms-2">
                                            Is this completion of loading?
                                        </Label>
                                    </div>
                                </Col>
                            )}
                        {vessel?.vessel_type ===
                            VesselTypeConstant.LNG_CARRIER &&
                            values?.cargo_detail?.loading_completion ===
                            true && (
                                <PopOver
                                    target="loading_completion"
                                    handleClick={() => {
                                        setLoadingPopUpBool(true);
                                        setLoadingPopOverBool(false);
                                    }}
                                    message={"Is loading CTMS available?"}
                                    state={loadingPopOverBool}
                                    setState={setLoadingPopOverBool}
                                />
                            )}
                        {loadingPopUpBool === true && (
                            <LoadingCTMSContext.Provider
                                value={setLoadingCTMS}
                            >
                                <PopUp
                                    state={loadingPopUpBool}
                                    setState={setLoadingPopUpBool}
                                    body={
                                        <LNGCargoQuality
                                            VesselID={VesselID}
                                            cargoOperation={"loading"}
                                            cardHeader={
                                                "LNG Cargo Loading Quality Details"
                                            }
                                            reportId={ReportID}
                                            setState={setLoadingPopUpBool}
                                        />
                                    }
                                    title={"Loading CTMS Report"}
                                />
                            </LoadingCTMSContext.Provider>
                        )}
                    </Col>
                    <Col sm={2}>
                        <Label
                            className="asteric mb-0"
                            for="amount_of_cargo_discharged"
                        >
                            Cargo discharged
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="amount_of_cargo_discharged_msg"
                            ></i>
                        </Label>
                        <ToolTip
                            target="amount_of_cargo_discharged_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) =>
                                    item.target ===
                                    "amount_of_cargo_discharged_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group">
                            <Field name="cargo_detail.amount_of_cargo_discharged">
                                {({ field, form }: FieldProps) => (
                                    <Input
                                        type="text"
                                        className="form-control text-right"
                                        id="amount_of_cargo_discharged"
                                        name={field.name}
                                        onBlur={(e: any) => {
                                            AmountOfCargoDischagedOnBlur(e, values, handleBlur, handleChange, form)
                                        }}
                                        onChange={(e: any) => {
                                            handleChange(e);
                                            if (
                                                vessel?.vessel_type ===
                                                VesselTypeConstant.LNG_CARRIER
                                            ) {
                                                updateTotalCargoOnboard(
                                                    form,
                                                    values,
                                                    e,
                                                    "discharging"
                                                );
                                            }
                                        }}
                                        defaultValue={
                                            values.cargo_detail
                                                .amount_of_cargo_discharged
                                        }
                                    />
                                )}
                            </Field>
                            {vessel?.vessel_type === "Container" ? (
                                <div className="input-group-text">TEU</div>
                            ) : (
                                <div className="input-group-text round_border">
                                    mt
                                </div>
                            )}
                        </div>
                        {errors?.cargo_detail &&
                            touched?.cargo_detail
                                ?.amount_of_cargo_discharged &&
                            errors?.cargo_detail
                                ?.amount_of_cargo_discharged &&
                            !alertBool &&
                            env?.form_validation === true && (
                                <ErrorTooltip
                                    target="amount_of_cargo_discharged"
                                    message={
                                        errors?.cargo_detail
                                            ?.amount_of_cargo_discharged
                                    }
                                    open={
                                        errors?.cargo_detail &&
                                            errors?.cargo_detail
                                                ?.amount_of_cargo_discharged
                                            ? true
                                            : false
                                    }
                                />
                            )}
                        {vessel?.vessel_type ===
                            VesselTypeConstant.LNG_CARRIER &&
                            values?.cargo_detail?.discharging === true && (
                                <Col sm={12}>
                                    <div className="ele_row1">
                                        <Field name="cargo_detail.discharging_completion">
                                            {({ field, form }: FieldProps) => (
                                                <Input
                                                    type="checkbox"
                                                    id="discharging_completion"
                                                    name={field.name}
                                                    onChange={(e: any) => {
                                                        form.setFieldValue(
                                                            field.name,
                                                            e.target.checked
                                                        );
                                                        if (e.target.checked === true) {
                                                            setDischargingPopOverBool(true);
                                                        } else {
                                                            setDischargingPopOverBool(false);
                                                        }
                                                    }}
                                                    defaultChecked={
                                                        values?.cargo_detail
                                                            ?.discharging_completion
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <Label className="ms-2">
                                            Is this completion of discharging?
                                        </Label>
                                    </div>
                                </Col>
                            )}
                        <br />
                        {vessel?.vessel_type ===
                            VesselTypeConstant.LNG_CARRIER &&
                            values?.cargo_detail?.discharging_completion ===
                            true && (
                                <PopOver
                                    target="discharging_completion"
                                    handleClick={() => {
                                        setDischargingPopUpBool(true);
                                        setDischargingPopOverBool(false);
                                    }}
                                    message={"Is discharging CTMS available?"}
                                    state={dischargingPopOverBool}
                                    setState={setDischargingPopOverBool}
                                />
                            )}
                        {dischargingPopUpBool === true && (
                            <DischargingCTMSContext.Provider
                                value={setDischargingCTMS}
                            >
                                <PopUp
                                    state={dischargingPopUpBool}
                                    setState={setDischargingPopUpBool}
                                    body={
                                        <LNGCargoQuality
                                            VesselID={VesselID}
                                            cargoOperation={"discharging"}
                                            cardHeader={
                                                "LNG Cargo Discharging Quality Details"
                                            }
                                            reportId={ReportID}
                                            setState={setDischargingPopUpBool}
                                        />
                                    }
                                    title={"Discharging CTMS Report"}
                                />
                            </DischargingCTMSContext.Provider>
                        )}
                    </Col>
                    <Col sm={2}>
                        <Label
                            className="asteric mb-0"
                            for="total_amount_of_cargo_onboard"
                        >
                            Cargo onboard
                            <i
                                className="bx bx-info-circle ml-2p"
                                id="total_amount_of_cargo_onboard_msg"
                            />
                        </Label>
                        <ToolTip
                            target="total_amount_of_cargo_onboard_msg"
                            message={`${TooltipMsg.Draft_and_Displacement.filter(
                                (item: any) =>
                                    item.target ===
                                    "total_amount_of_cargo_onboard_msg"
                            ).map((tool: any) => {
                                return tool.message;
                            })}`}
                        />
                        <div className="input-group">
                            <Field name="cargo_detail.total_amount_of_cargo_onboard">
                                {({ field }: FieldProps) => (
                                    <Input
                                        type="text"
                                        id="total_amount_of_cargo_onboard"
                                        name={field.name}
                                        className="form-control text-right"
                                        onBlur={(e: any) => {
                                            handleCargoOnboardBlur(
                                                parseFloat(e.target.value),
                                                parseFloat(values?.cargo_detail?.amount_of_cargo_loaded),
                                                parseFloat(values?.cargo_detail?.amount_of_cargo_discharged));
                                            handleBlur(e);
                                        }}
                                        onChange={(e: any) => handleChange(e)}
                                        disabled={vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER}
                                        value={
                                            vessel?.vessel_type !==
                                                VesselTypeConstant.LNG_CARRIER
                                                ? values?.cargo_detail
                                                    ?.total_amount_of_cargo_onboard
                                                : vessel?.vessel_type ===
                                                    VesselTypeConstant.LNG_CARRIER &&
                                                    Number.isNaN(
                                                        values?.cargo_detail
                                                            ?.total_amount_of_cargo_onboard
                                                    ) === true
                                                    ? null
                                                    : (LNGCargoPreviousROB && LNGCargoPreviousROB?.id > 0)
                                                        ?
                                                        (parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) +
                                                            parseFloat(values?.cargo_detail?.amount_of_cargo_loaded ?? 0) -
                                                            parseFloat(values?.cargo_detail?.amount_of_cargo_discharged ?? 0) +
                                                            parseFloat(values?.cargo_detail?.cargo_adjusted ?? 0) -
                                                            parseFloat(values?.lng_cargo_rob?.cargo_consumed ?? 0)
                                                        )
                                                        :
                                                        values?.cargo_detail
                                                            ?.total_amount_of_cargo_onboard
                                        }
                                    />
                                )}
                            </Field>
                            {vessel?.vessel_type === "Container" ? (
                                <div className="input-group-text">TEU</div>
                            ) : (
                                <div className="input-group-text round_border">
                                    mt
                                </div>
                            )}
                            {errors?.cargo_detail &&
                                touched?.cargo_detail
                                    ?.total_amount_of_cargo_onboard &&
                                errors?.cargo_detail
                                    ?.total_amount_of_cargo_onboard &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="total_amount_of_cargo_onboard"
                                        message={
                                            errors?.cargo_detail
                                                ?.total_amount_of_cargo_onboard
                                        }
                                        open={
                                            errors?.cargo_detail &&
                                                errors?.cargo_detail
                                                    ?.total_amount_of_cargo_onboard
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </div>
                    </Col>
                    <Col sm={3}>
                        <React.Fragment>
                            <Label
                                className="asteric mb-0"
                                for="cargoDetails_cargo_detail"
                            >
                                Cargo details
                                <i
                                    className="bx bx-info-circle ml-2p"
                                    id="cargo_detail_msg"
                                />
                            </Label>
                            <ToolTip
                                target="cargo_detail_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) =>
                                        item.target === "cargo_detail_msg"
                                ).map((tool: any) => {
                                    return tool.message;
                                })}`}
                            />
                            <Field name="cargo_detail.cargo_detail">
                                {() => (
                                    <textarea
                                        className="form-control"
                                        name="cargo_detail.cargo_detail"
                                        id="cargoDetails_cargo_detail"
                                        rows={
                                            values?.cargo_detail?.cargo_detail?.toString()
                                                ?.length < 10
                                                ? 1
                                                : 2
                                        }
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                        defaultValue={
                                            values?.cargo_detail?.cargo_detail
                                        }
                                    />
                                )}
                            </Field>
                            {errors?.cargo_detail &&
                                touched?.cargo_detail?.cargo_detail &&
                                errors?.cargo_detail?.cargo_detail &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="cargoDetails_cargo_detail"
                                        message={
                                            errors?.cargo_detail?.cargo_detail
                                        }
                                        open={
                                            errors?.cargo_detail &&
                                                errors?.cargo_detail?.cargo_detail
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </React.Fragment>
                    </Col>
                </Row>
                {vessel?.vessel_type === VesselTypeConstant.CONTAINER &&
                    <Row className="mb-3">
                        <Col sm={2}>
                            <Label
                                className="mb-0"
                                for="OOG_units"
                            >
                                OOG units
                                <i
                                    className="bx bx-info-circle ml-2p"
                                    id="OOG_units_msg"
                                ></i>
                            </Label>
                            <ToolTip
                                target="OOG_units_msg"
                                message={`${TooltipMsg.Draft_and_Displacement.filter(
                                    (item: any) =>
                                        item.target === "OOG_units_msg"
                                ).map((tool: any) => {
                                    return tool.message;
                                })}`}
                            />
                            <div className="input-group">
                                <Field name="cargo_detail.OOG_units">
                                    {({ field }: FieldProps) => (
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            id="OOG_units"
                                            name={field.name}
                                            onBlur={(e: any) => {
                                                handleBlur(e);
                                                handleChange(e);
                                            }}
                                            onChange={(e: any) => {
                                                handleBlur(e);
                                                handleChange(e);
                                            }}
                                            defaultValue={
                                                values.cargo_detail.OOG_units
                                            }
                                        />
                                    )}
                                </Field>
                                <div className="input-group-text">mt</div>
                            </div>
                            {errors?.cargo_detail &&
                                touched?.cargo_detail?.OOG_units &&
                                errors?.cargo_detail?.OOG_units &&
                                !alertBool &&
                                env?.form_validation === true && (
                                    <ErrorTooltip
                                        target="OOG_units"
                                        message={
                                            errors?.cargo_detail?.OOG_units
                                        }
                                        open={
                                            errors?.cargo_detail &&
                                                errors?.cargo_detail?.OOG_units
                                                ? true
                                                : false
                                        }
                                    />
                                )}
                        </Col>
                        <Col sm={2}>
                            <Label className="mb-0" for="feet_20">
                                20 ft units
                            </Label>
                            <i className="bx bx-info-circle ml-2p" id="feet_20_info"></i>
                            <ToolTip target="feet_20_info" message="Info message for 20 ft units" />
                            <div className="input-group">
                                <Field name="cargo_detail.feet_20">
                                    {({ field }: FieldProps) => (
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            id="feet_20"
                                            {...field} // Automatically links Formik field
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.cargo_detail?.feet_20 || ""}
                                        />
                                    )}
                                </Field>
                                <div className="input-group-text round_border">
                                    TEU
                                </div>
                                {errors.cargo_detail?.feet_20 &&
                                    !alertBool &&
                                    env?.form_validation === true && (
                                        <ErrorTooltip
                                            target="feet_20"
                                            message={errors.cargo_detail?.feet_20}
                                            open={!!errors.cargo_detail?.feet_20}
                                        />
                                    )}
                            </div>
                        </Col>
                        <Col sm={2}>
                            <Label className="mb-0" for="feet_40">
                                40 ft units
                            </Label>
                            <i className="bx bx-info-circle ml-2p" id="feet_40_info"></i>

                            <ToolTip
                                target="feet_40_info"
                                message="Info message for 40 ft units"
                            />
                            <div className="input-group">
                                <Field name="cargo_detail.feet_40">
                                    {({ field }: FieldProps) => (
                                        <Input
                                            type="text"
                                            className="form-control text-right"
                                            id="feet_40"
                                            {...field} // Automatically links Formik field
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            defaultValue={values.cargo_detail?.feet_40 || ""}
                                        />
                                    )}
                                </Field>
                                <div className="input-group-text round_border">TEU</div>
                                {errors.cargo_detail?.feet_40 &&
                                    !alertBool &&
                                    env?.form_validation === true && (
                                        <ErrorTooltip
                                            target="feet_40"
                                            message={errors.cargo_detail?.feet_40}
                                            open={!!errors.cargo_detail?.feet_40}
                                        />
                                    )}
                            </div>
                        </Col>
                    </Row>
                }
                {vessel?.vessel_type ===
                    VesselTypeConstant.LNG_CARRIER && (
                        <Row className="mb-3">
                            <Col sm={3}>
                                <Label
                                    className="asteric mb-0"
                                    for="adjustment_reason"
                                >
                                    Reason for cargo adjustment
                                    <i
                                        className="bx bx-info-circle ml-2p"
                                        id="adjustment_reason_msg"
                                    ></i>
                                </Label>
                                <ToolTip
                                    target="adjustment_reason_msg"
                                    message={`${TooltipMsg.Draft_and_Displacement.filter(
                                        (item: any) =>
                                            item.target === "adjustment_reason_msg"
                                    ).map((tool: any) => {
                                        return tool.message;
                                    })}`}
                                />
                                <Field name="cargo_detail.adjustment_reason">
                                    {() => (
                                        <textarea
                                            className="form-control"
                                            id="adjustment_reason"
                                            name="cargo_detail.adjustment_reason"
                                            rows={
                                                values?.cargo_detail?.adjustment_reason?.toString()
                                                    ?.length < 10
                                                    ? 1
                                                    : 2
                                            }
                                            onBlur={handleBlur}
                                            onChange={(e: any) => handleChange(e)}
                                            defaultValue={
                                                values?.cargo_detail?.adjustment_reason
                                            }
                                            disabled={
                                                values?.cargo_detail?.cargo_adjusted ===
                                                0 ||
                                                values?.cargo_detail?.cargo_adjusted ===
                                                "0"
                                            }
                                        />
                                    )}
                                </Field>
                                {errors?.cargo_detail &&
                                    touched?.cargo_detail?.adjustment_reason &&
                                    errors?.cargo_detail?.adjustment_reason &&
                                    !alertBool &&
                                    env?.form_validation === true && (
                                        <ErrorTooltip
                                            target="adjustment_reason"
                                            message={
                                                errors?.cargo_detail?.adjustment_reason
                                            }
                                            open={
                                                errors?.cargo_detail &&
                                                    errors?.cargo_detail?.adjustment_reason
                                                    ? true
                                                    : false
                                            }
                                        />
                                    )}
                            </Col>
                            <Col sm={2}>
                                <>
                                    <Label className="mb-0" for="cargo_adjusted">
                                        Cargo adjusted
                                        <i
                                            className="bx bx-info-circle ml-2p"
                                            id="cargo_adjusted_msg"
                                        ></i>
                                    </Label>
                                    <ToolTip
                                        target="cargo_adjusted_msg"
                                        message={`${TooltipMsg.Draft_and_Displacement.filter(
                                            (item: any) =>
                                                item.target === "cargo_adjusted_msg"
                                        ).map((tool: any) => {
                                            return tool.message;
                                        })}`}
                                    />
                                    <div className="input-group">
                                        <Field name="cargo_detail.cargo_adjusted">
                                            {({ field, form }: FieldProps) => (
                                                <Input
                                                    type="text"
                                                    className="form-control text-right"
                                                    id="cargo_adjusted"
                                                    name={field.name}
                                                    onBlur={(e: any) => {
                                                        handleChange(e);
                                                        updateTotalCargoOnboard(
                                                            form,
                                                            values,
                                                            e,
                                                            "adjusted"
                                                        );
                                                    }}
                                                    onChange={(e: any) => {
                                                        updateTotalCargoOnboard(
                                                            form,
                                                            values,
                                                            e,
                                                            "adjusted"
                                                        );
                                                    }}
                                                    defaultValue={
                                                        values.cargo_detail.cargo_adjusted
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <div className="input-group-text round_border">
                                            mt
                                        </div>
                                    </div>
                                </>
                            </Col>
                            <Col sm={2}>
                                <Label className="mb-0">Cargo used</Label>
                                <div className="input-group">
                                    {" "}
                                    <Field
                                        type="text"
                                        name="lng_cargo_rob.cargo_consumed"
                                        id="lng_cargo_rob.cargo_consumed"
                                        className="form-control text-right"
                                        disabled
                                    />
                                    <div className="input-group-text round_border">
                                        mt
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}
            </CardBody>
        </Card>
    )
}

export default CargoDetails