import ErrorTooltip from 'Components/ErrorTooltip';
import ReportingFileUpload from 'Components/ReportingFileUpload';
import ToolTip from 'Components/ToolTip';
import { TooltipMsg } from 'Components/ToolTipMessage';
import env from 'environment_system/env_system';
import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react'
import { Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { ReportingOpConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
interface ShoreSupplyType {
    adjustmentPopup: boolean;
    record: any;
    shoreSupplyPort: string;
    refreshKey: number;
    setRefreshKey: any;
    EDNFiles: any;
    setEDNFiles: any;
    FileStatus: any;
    DeleteFileObj: any;
}

const ShoreSupplyComponent = ({ adjustmentPopup, record, shoreSupplyPort, refreshKey, setRefreshKey, EDNFiles, setEDNFiles, FileStatus, DeleteFileObj }: ShoreSupplyType) => {
    const { values, errors }:
        { values: any, errors: any } = useFormikContext<any>();
    return (
        <Row className='px-0 m-0'>
            <Col sm={12} className='pr-0 px-0'>
                {record && (record?.reporting_operation_mode === ReportingOpConstant.ANCHOR_STAY ||
                    record?.reporting_operation_mode === ReportingOpConstant.PORT_STAY) &&
                    <Card className='rounded-0 mb-0 border-bottom-0 border-start-0'>
                        <CardHeader className='p-0'>
                            <div className="text-center">
                                <Row>
                                    <Col className='pr-0'>
                                        <h4 className="page_title pos-start mb-0">Shore Supply</h4>
                                        <p className="card-title-desc pos-start">All readings since last report</p>
                                    </Col>
                                </Row>
                            </div>
                        </CardHeader>
                        <CardBody className='px-0 py-0 shore_supply_margin mb-0'>
                            <Row>
                                <Col>
                                    <div className="d-flex flex-wrap gap-2">
                                        <Label for='is_shore_supply_used'>Is shore supply used?
                                            <i className='bx bx-info-circle ml-2p' id='is_shore_supply_used_msg' />
                                        </Label>
                                        <ToolTip target='is_shore_supply_used_msg'
                                            message={`${TooltipMsg.FuelConsumption
                                                .filter((item: any) => item.target === 'is_shore_supply_used_msg')
                                                .map((tool: any) => { return tool.message })}`}
                                        />
                                        <Field name={'shoreSupply.is_shore_supply_used'}>
                                            {({ field, form }: FieldProps) => (
                                                <div className="square-switch sqswitch">
                                                    <Input
                                                        type="checkbox"
                                                        switch="none"
                                                        name={field.name}
                                                        id="is_shore_supply_used"
                                                        onChange={(e: any) => {
                                                            form.setFieldValue(field.name, e.target.checked);
                                                            /**if is_edn_issue is true when we uncheck is_shore_supply_used then 
                                                             * is_edn_issue also make false and uncheck it*/
                                                            if (e.target.checked === false) {
                                                                form.setFieldValue('shoreSupply.is_edn_issue', e.target.checked);
                                                            }
                                                        }}
                                                        defaultChecked={values?.shoreSupply?.is_shore_supply_used}
                                                    />
                                                    <Label
                                                        htmlFor="is_shore_supply_used"
                                                        data-on-label='Yes'
                                                        data-off-label='No'
                                                    >
                                                    </Label>
                                                </div>
                                            )}
                                        </Field>
                                    </div>
                                </Col>
                            </Row>
                            {values && values?.shoreSupply?.is_shore_supply_used === true &&
                                <Row className='mb-3'>
                                    <Col sm={2}>
                                        <Label className='mb-0' for='time_on_shore_supply'>Time on shore supply
                                            <i className='bx bx-info-circle ml-2p' id='time_on_shore_supply_msg' />
                                        </Label>
                                        <ToolTip target='time_on_shore_supply_msg'
                                            message={`${TooltipMsg.FuelConsumption
                                                .filter((item: any) => item.target === 'time_on_shore_supply_msg')
                                                .map((tool: any) => { return tool.message })}`}
                                        />
                                        <div className='input-group'>
                                            <Field
                                                type="text"
                                                name="shoreSupply.time_on_shore_supply"
                                                id='time_on_shore_supply'
                                                className="form-control text-right max-width-7"
                                            />
                                            <div className='input-group-text'>hr</div>
                                        </div>
                                        {errors?.shoreSupply && errors?.shoreSupply?.time_on_shore_supply && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target={`time_on_shore_supply`}
                                                message={errors?.shoreSupply?.time_on_shore_supply}
                                                open={(errors?.shoreSupply && errors?.shoreSupply?.time_on_shore_supply) && !adjustmentPopup ? true : false}
                                            />
                                        }
                                    </Col>
                                    <Col sm={3}>

                                        <Label className='mb-0' for='quantity_of_shore_supply'>Quantity of shore supply used
                                            <i className='bx bx-info-circle ml-2p' id='quantity_of_shore_supply_msg' />
                                        </Label>
                                        <ToolTip target='quantity_of_shore_supply_msg'
                                            message={`${TooltipMsg.FuelConsumption
                                                .filter((item: any) => item.target === 'quantity_of_shore_supply_msg')
                                                .map((tool: any) => { return tool.message })}`}
                                        />
                                        <div className='input-group'>
                                            <Field
                                                type="text"
                                                name="shoreSupply.quantity_of_shore_supply"
                                                id='quantity_of_shore_supply'
                                                className="form-control text-right max-width-7 "
                                            />
                                            <div className='input-group-text'>kWh</div>
                                        </div>
                                        {errors?.shoreSupply && errors?.shoreSupply?.quantity_of_shore_supply && env?.form_validation === true &&
                                            <ErrorTooltip
                                                target={`quantity_of_shore_supply`}
                                                message={errors?.shoreSupply?.quantity_of_shore_supply}
                                                open={errors?.shoreSupply && errors?.shoreSupply?.quantity_of_shore_supply && !adjustmentPopup ? true : false}
                                            />
                                        }
                                    </Col>
                                    <Col sm={4}>
                                        <Label className='mb-0' for='port_name_msg'>Port Name
                                            <i className='ml-2p' id='port_name_msg' />
                                        </Label>
                                        <h4>
                                            {shoreSupplyPort}
                                        </h4>
                                    </Col>
                                </Row>
                            }
                            {env?.fuel_eu === true && values?.shoreSupply?.is_shore_supply_used === true &&
                                <Row>
                                    <Col sm={2}>
                                        <div className="d-flex flex-wrap gap-2">
                                            <Label for='is_edn_issue' className="edn-label-width">Is EDN issued?
                                                <i className='bx bx-info-circle ml-2p' id='is_edn_issue_msg' />
                                            </Label>
                                            <ToolTip target='is_edn_issue_msg'
                                                message={`${TooltipMsg.FuelConsumption
                                                    .filter((item: any) => item.target === 'is_edn_issue_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <Field name={'shoreSupply.is_edn_issue'}>
                                                {({ field, form }: FieldProps) => (
                                                    <div className="square-switch sqswitch">
                                                        <Input
                                                            type="checkbox"
                                                            switch="none"
                                                            name={field.name}
                                                            id="is_edn_issue"
                                                            onChange={(e: any) => {
                                                                form.setFieldValue(field.name, e.target.checked);
                                                            }}
                                                            defaultChecked={values?.shoreSupply.is_edn_issue}
                                                        />
                                                        <Label
                                                            htmlFor="is_edn_issue"
                                                            data-on-label='Yes'
                                                            data-off-label='No'
                                                        >
                                                        </Label>
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {values &&
                                values?.shoreSupply?.is_edn_issue === true &&
                                values?.shoreSupply?.is_shore_supply_used === true &&
                                <React.Fragment>
                                    <Row className='pe-2'>
                                        <Col sm={2}>
                                            <Label className='mb-0' for='total_time'>Total time
                                                <i className='bx bx-info-circle ml-2p' id='total_time_msg' />
                                            </Label>
                                            <ToolTip target='total_time_msg'
                                                message={`${TooltipMsg.FuelConsumption
                                                    .filter((item: any) => item.target === 'total_time_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className='input-group'>
                                                <Field
                                                    type="text"
                                                    name="shoreSupply.total_time"
                                                    id='total_time'
                                                    className="form-control text-right max-width-7"
                                                />
                                                <div className='input-group-text'>hr</div>
                                            </div>
                                            {errors?.shoreSupply && errors?.shoreSupply?.total_time && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target={`total_time`}
                                                    message={errors?.shoreSupply?.total_time}
                                                    open={(errors?.shoreSupply && errors?.shoreSupply?.total_time) && !adjustmentPopup ? true : false}
                                                />
                                            }
                                        </Col>
                                        <Col sm={2}>
                                            <Label className='mb-0' for='total_kw_used'>Total quantity used
                                                <i className='bx bx-info-circle ml-2p' id='total_kw_used_msg' />
                                            </Label>
                                            <ToolTip target='total_kw_used_msg'
                                                message={`${TooltipMsg.FuelConsumption
                                                    .filter((item: any) => item.target === 'total_kw_used_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className='input-group'>
                                                <Field
                                                    type="text"
                                                    name="shoreSupply.total_kw_used"
                                                    id='total_kw_used'
                                                    className="form-control text-right max-width-7 "
                                                />
                                                <div className='input-group-text'>kWh</div>
                                            </div>
                                            {errors?.shoreSupply && errors?.shoreSupply?.total_kw_used && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target={`total_kw_used`}
                                                    message={errors?.shoreSupply?.total_kw_used}
                                                    open={errors?.shoreSupply && errors?.shoreSupply?.total_kw_used && !adjustmentPopup ? true : false}
                                                />
                                            }
                                        </Col>
                                        <Col sm={2}>
                                            <Label className='mb-0' for='co2_equivalent'>CO<sub>2</sub> equivalent
                                                <i className='bx bx-info-circle ml-2p' id='co2_equivalent_msg' />
                                            </Label>
                                            <ToolTip target='co2_equivalent_msg'
                                                message={`${TooltipMsg.FuelConsumption
                                                    .filter((item: any) => item.target === 'co2_equivalent_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <div className='input-group'>
                                                <Field
                                                    type="text"
                                                    name="shoreSupply.co2_equivalent"
                                                    id='co2_equivalent'
                                                    className="form-control text-right max-width-7 "
                                                />
                                                <div className='input-group-text'>kWh</div>
                                            </div>
                                            {errors?.shoreSupply && errors?.shoreSupply?.co2_equivalent && env?.form_validation === true &&
                                                <ErrorTooltip
                                                    target={`co2_equivalent`}
                                                    message={errors?.shoreSupply?.co2_equivalent}
                                                    open={errors?.shoreSupply && errors?.shoreSupply?.co2_equivalent && !adjustmentPopup ? true : false}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                    <ReportingFileUpload
                                        setFile={setEDNFiles}
                                        file={EDNFiles}
                                        fileUploadStatus={values?.shoreSupply?.status === FileStatus.UPLOADED}
                                        fileURL={values?.shoreSupply?.file_path}
                                        title={`EDN File`}
                                        deleteURL={`/shore_supply/`}
                                        DeleteFileObj={DeleteFileObj}
                                        invalidateQuery={queryKeyes.vessel.ShoreSupplyObject.key}
                                        deleteID={values?.shoreSupply?.id}
                                        refreshKey={refreshKey}
                                        setRefreshKey={setRefreshKey}
                                        index={0}
                                    />
                                </React.Fragment>
                            }
                        </CardBody>
                    </Card>
                }
            </Col>
        </Row>
    )
}

export default ShoreSupplyComponent
