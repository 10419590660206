import PopUp from 'Components/PopUp';
import Layout from 'HorizontalMenu/Menu';
import React, { useState } from 'react';
import { Navbar, Container, Row, Button } from 'reactstrap';
import TempPassword from './TempPassword';
import PopOver from 'Components/PopOver';
import CreateUserComponent from './CreateUserComponent';
import DeletePopOver from 'Components/DeletePopOver'
import ResetPassword from './ResetPassword';
import { errorToast, successToast } from 'Components/Toasts';
import { fetchUserProfile } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import apiGlobal from 'global/api.global';
import { queryClient } from 'react-query/queryClient';
import ToolTip from 'Components/ToolTip';
import { getAuthData } from 'utils/auth/authUtils';
import { useDocumentTitle } from 'Components/useDocument.hooks';

const Users = () => {
    /** State variables start */
    const [hoverId, setHoverId] = useState<string>('');
    const [createUserBool, setCreateUserBool] = useState<boolean>(false);
    const [tempPopUpBool, setTempPopUpBool] = useState<boolean>(false);
    const [deleteUserBool, setDeleteUserBool] = useState<boolean>(false);
    const [resetPasswordBool, setResetPasswordBool] = useState<boolean>(false);
    const [blockUserBool, setBlockUserBool] = useState<boolean>(false);
    const [editUserBool, setEditUserBool] = useState<boolean>(false);
    const [refreshTableKey, setRefreshTableKey] = useState<number>(0);
    /**common popover state */
    const [target, setTarget] = useState<string>('block_user');
    const [name, setName] = useState<string>('');
    const [status, setStatus] = useState<null | boolean>(null);
    const [userID, setUserID] = useState<number>(0);
    useDocumentTitle('Users Management - ecoSAIL');
    /*common popover   end */
    /** State variables end */

    /**
     * Fetch user profile
     * @param
     * @returns all user profile
     */
    const { data: profile, isLoading, isError } = useQuery(
        [queryKeyes.user.profile.key],
        async () => {
            return await fetchUserProfile();
        },
        { staleTime: Infinity }
    )

    /**
     * block and unblock user
     * @param userId 
     */
    const BlockUnblock = async (userId: number) => {
        await apiGlobal.post(queryKeyes.user.block.url(), { user_id: userId })
            .then((res) => {
                if (res.status === 200) {
                    successToast(res.data.message);
                    queryClient.invalidateQueries(queryKeyes.user.profile.key);
                }
            })
            .catch((err) => {
                errorToast(err.response?.data?.error || "An error occurred");
            })
            .finally(() => {
                setBlockUserBool(false);
            });
    };

    /**
     * Deleet user display false 
     * @param userId 
     */
    const DeleteUser = async (userId: number) => {
        await apiGlobal
            .post(queryKeyes.user.delete.url(), { user_id: userId })
            .then((res) => {
                if (res.status === 200) {
                    successToast("Data deleted successfully!");
                    queryClient.invalidateQueries(queryKeyes.user.profile.key);
                }
            })
            .catch((err: any) => {
                errorToast(err.response?.data?.error || "An error occurred");
            })
            .finally(() => {
                setDeleteUserBool(false);
            });
    };
    const currentUser = getAuthData();
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content" >
                <Container fluid>
                    <Row className="mb-2">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">Users</h5>
                            <Button
                                className="ms-auto justify_right"
                                color="primary"
                                onClick={() => { setCreateUserBool(true); }}
                            >
                                <i className="dripicons-plus font-size-16 align-middle me-2" /> Create
                            </Button>
                        </div>
                    </Row>
                    {isLoading && <Loading message='Loading required data!' />}
                    {isError && <ErrorComponent message='Error loading required data!' />}
                    {!isLoading && !isError &&
                        <Row>
                            <div className="table-responsive">
                                <table className="table mb-2" key={refreshTableKey}>
                                    <thead className="table-light">
                                        <tr>
                                            <th className="p-2 align middle sr-no-width">#</th>
                                            <th className="p-2 align middle" style={{ width: '20%' }}>Name</th>
                                            <th className="p-2 align middle" style={{ width: '20%' }}>Mobile</th>
                                            <th className="p-2 align middle" style={{ width: '30%' }}>Email</th>
                                            <th className="p-2 align middle" style={{ width: '20%' }}>Role</th>
                                            <th className="p-2 align middle text-center" colSpan={5} style={{ width: '5%' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {profile
                                            ?.filter((user: any) => user.display)
                                            .map((user: any, index: number) => (
                                                <tr key={user.id}>
                                                    <td className="p-2 align middle">{index + 1}</td>
                                                    <td className="p-2 align middle">
                                                        {user?.first_name} {user?.middle_name} {user?.last_name}
                                                    </td>
                                                    <td className={`p-2 align middle  ${user?.mobile ? '' : 'text-center'}`}>{user?.mobile || '- - -'}</td>
                                                    <td className={`p-2 align middle  ${user?.email ? '' : 'text-center'}`}>{user?.email || '- - -'}</td>
                                                    <td className="p-2 align middle">{user?.role_name}</td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className={"btn btn-soft-primary waves-effect waves-light"}
                                                            id={`Edit_User_Profile_${user?.id}`}
                                                            onMouseEnter={() => setHoverId(`Edit_User_Profile_${user?.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={async () => {
                                                                setEditUserBool(true);
                                                                setUserID(user?.id);
                                                                setHoverId("");
                                                            }}
                                                        >
                                                            <i className="bx bx-user font-size-16 align-middle"></i>
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className={(currentUser.user_id === user?.id || user.force_password_change !== true)
                                                                ? "btn bg-secondary-subtle waves-effect waves-light"
                                                                : "btn btn-soft-primary waves-effect waves-light"}
                                                            id={(currentUser.user_id === user?.id || user.force_password_change !== true)
                                                                ? `Password_Not_Available_${user?.id}`
                                                                : `View_Password_${user?.id}`}
                                                            onMouseEnter={() => setHoverId((currentUser.user_id === user?.id || user.force_password_change !== true)
                                                                ? `Password_Not_Available_${user?.id}`
                                                                : `View_Password_${user?.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                if (!(currentUser.user_id === user?.id || user.force_password_change !== true)) {
                                                                    setTempPopUpBool(true);
                                                                    setUserID(user?.id);
                                                                    setName(
                                                                        `${user?.first_name || ""} ${user?.middle_name || ""} ${user?.last_name || ""}`.trim()
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <i
                                                                className={`dripicons-preview font-size-16 align-middle ${currentUser.user_id === user?.id || user.force_password_change !== true
                                                                    ? "text-secondary"
                                                                    : ""
                                                                    }`}
                                                            ></i>
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className={(currentUser.user_id === user?.id)
                                                                ? "btn bg-secondary-subtle waves-effect waves-light"
                                                                : "btn btn-soft-primary waves-effect waves-light"}
                                                            id={(currentUser.user_id === user?.id)
                                                                ? `Disabled_Reset_Password_${user?.id}`
                                                                : `Reset_Password_${user?.id}`}
                                                            onMouseEnter={() => setHoverId((currentUser.user_id === user?.id)
                                                                ? `Disabled_Reset_Password_${user?.id}`
                                                                : `Reset_Password_${user?.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                if (!(currentUser.user_id === user?.id)) {
                                                                    setResetPasswordBool(true)
                                                                    setHoverId("")
                                                                    setName(
                                                                        `${user?.first_name || ""} ${user?.middle_name || ""} ${user?.last_name || ""}`.trim()
                                                                    ); setUserID(user?.id)
                                                                }
                                                            }}
                                                        >
                                                            <i className={`bx bx-reset font-size-16 align-middle ${currentUser.user_id === user?.id
                                                                ? "text-secondary"
                                                                : ""
                                                                }`}></i>
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align-middle">
                                                        <button
                                                            type="button"
                                                            className={
                                                                currentUser.user_id === user?.id
                                                                    ? "btn bg-secondary-subtle waves-effect waves-light"
                                                                    : `btn ${user?.block ? "btn-soft-success" : "btn-soft-danger"} waves-effect waves-light`
                                                            }
                                                            id={
                                                                currentUser.user_id === user?.id
                                                                    ? `Disabled_Block_User_${user?.id}`
                                                                    : user?.block
                                                                        ? `Unblock_User_${user?.id}`
                                                                        : `Block_User_${user?.id}`
                                                            }
                                                            onMouseEnter={() =>
                                                                setHoverId(
                                                                    currentUser.user_id === user?.id
                                                                        ? `Disabled_Block_User_${user?.id}`
                                                                        : user?.block
                                                                            ? `Unblock_User_${user?.id}`
                                                                            : `Block_User_${user?.id}`
                                                                )
                                                            }
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                if (currentUser.user_id !== user?.id) {
                                                                    setBlockUserBool(true);
                                                                    setTarget(
                                                                        user?.block ? `Unblock_User_${user?.id}` : `Block_User_${user?.id}`
                                                                    );
                                                                    setName(
                                                                        `${user?.first_name || ""} ${user?.middle_name || ""} ${user?.last_name || ""}`.trim()
                                                                    );
                                                                    setStatus(user?.block);
                                                                    setHoverId("");
                                                                }
                                                            }}
                                                        >
                                                            <i
                                                                className={`bx font-size-16 align-middle ${currentUser.user_id === user?.id
                                                                    ? "text-secondary bxs-user-x"
                                                                    : user?.block
                                                                        ? "bxs-user-check text-success"
                                                                        : "bxs-user-x text-danger"
                                                                    }`}
                                                            />
                                                        </button>
                                                    </td>
                                                    <td className="p-2 align middle">
                                                        <button
                                                            type="button"
                                                            className={(currentUser.user_id === user?.id)
                                                                ? "btn bg-secondary-subtle waves-effect waves-light "
                                                                : "btn btn-soft-danger waves-effect waves-light"}
                                                            id={(currentUser.user_id === user?.id)
                                                                ? `Disabled_Delete_User_${user.id}`
                                                                : `Delete_User_${user.id}`
                                                            }
                                                            onMouseEnter={() => setHoverId((currentUser.user_id === user?.id)
                                                                ? `Disabled_Delete_User_${user.id}`
                                                                : `Delete_User_${user.id}`)}
                                                            onMouseLeave={() => setHoverId("")}
                                                            onClick={() => {
                                                                if (!(currentUser.user_id === user?.id)) {
                                                                    setDeleteUserBool(true);
                                                                    setHoverId("");
                                                                    setTarget((currentUser.user_id === user?.id)
                                                                        ? `Disabled_Delete_User_${user.id}`
                                                                        : `Delete_User_${user.id}`);
                                                                    setName(
                                                                        `${user.first_name || ""} ${user.middle_name || ""} ${user.last_name || ""}`.trim()
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <i className={`dripicons-trash font-size-16 align-middle ${currentUser.user_id === user?.id
                                                                ? "text-secondary"
                                                                : ""
                                                                }`} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </Row>
                    }
                </Container>
            </div>
            {resetPasswordBool &&
                <PopUp
                    state={resetPasswordBool}
                    setState={setResetPasswordBool}
                    body={<ResetPassword
                        userID={userID}
                        name={name}
                        setState={setResetPasswordBool}
                    />}
                    title="Reset password"
                    size="md"
                />
            }
            {tempPopUpBool &&
                <PopUp
                    state={tempPopUpBool}
                    setState={setTempPopUpBool}
                    body={<TempPassword
                        userID={userID}
                        userName={name}
                        setState={setTempPopUpBool}
                    />}
                    title="View temporary password"
                    size="md"

                />
            }
            {blockUserBool &&
                <PopOver
                    key={target}
                    state={blockUserBool}
                    message={`Are you sure you want to ${status ? "unblock" : "block"} ${name} ?`}
                    setState={setBlockUserBool}
                    target={target}
                    handleClick={() => {
                        const userId = parseInt(target.split("_").pop());
                        BlockUnblock(userId);
                    }}
                    placement="top"
                />
            }
            {deleteUserBool &&
                <DeletePopOver
                    state={deleteUserBool}
                    message={`Are you sure you want to delete ${name} ?`}
                    setState={setDeleteUserBool}
                    target={target}
                    onClick={() => {
                        const userId = parseInt(target.split("_").pop());
                        DeleteUser(userId);
                    }}
                    key={target}
                />}
            {editUserBool &&
                <PopUp
                    state={editUserBool}
                    setState={setEditUserBool}
                    body={<CreateUserComponent
                        setRefreshTableKey={setRefreshTableKey}
                        refreshTableKey={refreshTableKey}
                        setState={setEditUserBool}
                        userID={userID}
                    />}
                    title="Edit User"
                    size="lg"
                />
            }
            {hoverId && document.getElementById(hoverId) !== null &&
                <ToolTip
                    target={hoverId}
                    message={hoverId ? hoverId.replace(/_\d+$/, '').replace(/_/g, ' ') : ''}
                    isOpen={hoverId ? true : false}
                />
            }
            <PopUp
                state={createUserBool}
                setState={setCreateUserBool}
                body={<CreateUserComponent
                    setRefreshTableKey={setRefreshTableKey}
                    refreshTableKey={refreshTableKey}
                    setState={setCreateUserBool} />}
                title="Add User"
                size="lg"
            />
        </React.Fragment >
    );
}

export default Users;
