import React, { useState, useEffect } from "react";
import { CardBody, TabContent, TabPane, Col, Container, Row, Card, CardHeader, CardFooter } from 'reactstrap';
import Layout from "../../HorizontalMenu/Menu";
import Navbar from "../../HorizontalMenu/Navbar";
import '../../global/GlobalCSS.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../';
import { VesselState, setVesselState } from "../../Store/Generic/ReportingSlice";
import VesselDetailsHeader from "../../Components/VesselDetailsHeader";
import apiGlobal from "../../global/api.global";
import { useQuery } from "react-query";
import { queryKeyes } from "../../shared/queryKeys";
import { AddInfoConstant, DeckReportTabsConstant, EngineStatus, EventPrecedence, ReportingTypeConstant, VesselTypeConstant } from "../../shared/constants";
import { loadCIIAdjustmentEvents, loadPreviousVesselReport } from "../../VesselMaster/vesselMaster.hooks";
import DraftsCargoLadingComponent from "./ReportComponents/DraftsCargoLading/DraftsCargoLadingComponent";
import STSOperationComponent from "./ReportComponents/STSOperationComponent";
import ETADComponent from "./ReportComponents/ETADComponent";
import LfondsAddInformation from "./AdditionalInformation/Lfonds/LfondsAddInformation";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import WeatherData from "./ReportComponents/WeatherData";
import env from "environment_system/env_system";
import ProactiveAddInformation from "./AdditionalInformation/Proactive/ProactiveAddInformation";
import CIIAdjustmentComponent from "./ReportComponents/CIIAdjustmentComponent";
import DeckRemarks from "./ReportComponents/DeckRemarks";
import EconAddInformation from "./AdditionalInformation/Econ/EconAddInformation";
import ErrorReport from "Components/ErrorReport";
import { useLocation } from "react-router-dom";
import CpWarrantyComponent from "./ReportComponents/CpWarrantyComponent";

const DeckOfficerWizard = () => {
    /** State variables start */
    const { VesselID, VoyageID, ReportID, Reports, Vessels } = useSelector((state: RootState) => state.Reporting);
    let record = Reports.find((rec: any) => rec.id === ReportID) ?? null;
    let vessel = Vessels.find((rec: any) => rec.id === VesselID) ?? null;
    const [activeTab, setactiveTab] = useState(1);
    const [lastRecord, setLastRecord] = useState({ created_on: '', arrival_datetime: '', arrival_port: '', departure_port: '', departure_datetime: '', local_time: '' });
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState<any>()
    const location = useLocation()
    const [activeAddLfondsTab, setActiveAddLfondsTab] = useState(0);
    const [filteredCIIEventsList, setFilteredCIIEventsList] = useState<Array<{ [key: string]: string | number | Date | boolean }>>([]);
    /** State variables end */

    /** Change wizard's tabs */
    function toggleTab(tab: number) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= (env?.additional_info_deck === AddInfoConstant.NONE ? DeckReportTabsConstant.RemarksId - 1 : DeckReportTabsConstant.RemarksId)) {
                setactiveTab(tab);
            }
            if (env?.additional_info_deck === AddInfoConstant.LFONDS_DECK ||
                (env?.additional_info_deck === AddInfoConstant.ECON_DECK &&
                    record?.reporting_type === ReportingTypeConstant.ATSEA)) {
                toggleAddLfondsTab(1);
            }
        }
    }

    /** Queries */
    const { data: PreviousVesselReport, isLoading: PreviousVesselReportLoading, isError: PreviousVesselReportError } = useQuery(
        [queryKeyes.vessel.PreviousVesselReport.key, VesselID, ReportID],
        () => loadPreviousVesselReport(ReportID, VesselID),
        { staleTime: Infinity });
    /** get CII adjustment Vessel Reporting */
    const {
        data: CIIAdjustmentEvents,
        isLoading: CIIAdjustmentEventsLoading,
    } = useQuery(
        [queryKeyes.vessel.VesselReportingCIIAdjusment.key, VesselID],
        async () => {
            return await loadCIIAdjustmentEvents(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** UseEffect */
    useEffect(() => {
        const loadLastRecord = async () => {
            await apiGlobal.get(`/vessel_reporting_information/get_previous_by_id/?id=${ReportID}&vessel_id=${VesselID}`).then(res => {
                setLastRecord(res.data);
                return res.data;
            }).catch(err => {
                console.error(err);
            })
        }; loadLastRecord();
    }, [ReportID, VesselID]);
    /** filter CIIEvents */
    useEffect(() => {
        CIIAdjustmentEvents && CIIAdjustmentEvents.length > 0 &&
            setFilteredCIIEventsList(
                CIIAdjustmentEvents.filter((item: { [key: string]: string | number | Date | boolean }) =>
                    item.cii_begin_date_time <= record?.local_time &&
                    (item.cii_end_date_time >= record?.local_time || item.cii_end_date_time === null)))
    }, [CIIAdjustmentEvents, record]);
    /** useEffect end */


    /** Set vessel state globally */
    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record))
    }

    /** If CII Adjustment, STS Operation & CP Warranties all 3 tabs are visible */
    const ciiStsCpTabsVisible = () => {
        if (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true && (record && (record?.is_cii_adjustment_report ||
                filteredCIIEventsList?.length > 0)) && record.status_name === EngineStatus.ringFullAway)) {
            return true;
        } else {
            return false;
        }
    }

    /** If any 2 amongst CII Adjustment, STS Operation & CP Warranties are visible */
    const ciiStsCpTabsAny2Visible = () => {
        if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true) && (record && (record?.is_cii_adjustment_report ||
                filteredCIIEventsList?.length > 0)) && record?.status_name === EngineStatus.ringFullAway) ||
            (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && !(record && (record?.is_cii_adjustment_report ||
                    filteredCIIEventsList?.length > 0)) && record?.status_name === EngineStatus.ringFullAway) ||
            (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && (record && (record?.is_cii_adjustment_report ||
                    filteredCIIEventsList?.length > 0)) && !(record?.status_name === EngineStatus.ringFullAway))) {
            return true;
        } else {
            return false;
        }
    }

    /** If any 1 amongst CII Adjustment, STS Operation & CP Warranties are visible */
    const ciiStsCpTabsAny1Visible = () => {
        if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true) && !(record && (record?.is_cii_adjustment_report ||
                filteredCIIEventsList?.length > 0)) && record?.status_name === EngineStatus.ringFullAway) ||
            (!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && (record && (record?.is_cii_adjustment_report ||
                    filteredCIIEventsList?.length > 0)) && !(record?.status_name === EngineStatus.ringFullAway)) ||
            (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && !(record && (record?.is_cii_adjustment_report ||
                    filteredCIIEventsList?.length > 0)) && !(record?.status_name === EngineStatus.ringFullAway))) {
            return true;
        } else {
            return false;
        }
    }

    /** If none amongst CII Adjustment, STS Operation & CP Warranties are visible */
    const ciiStsCpTabsNoneVisible = () => {
        if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true) && !(record && (record?.is_cii_adjustment_report ||
                filteredCIIEventsList?.length > 0)) && !(record?.status_name === EngineStatus.ringFullAway))) {
            return true;
        } else {
            return false;
        }
    }

    /** Assign tabId to CII Adjustment according to mentioned conditions */
    const CIIAdjustmentTabId = () => {
        if (record && (record?.is_cii_adjustment_report ||
            filteredCIIEventsList?.length > 0)) {
            return DeckReportTabsConstant.CIIAdjustmentId;
        } else {
            return -1;
        }
    }

    /** Assign tabId to STS Operationt according to mentioned conditions */
    const STSTabId = () => {
        if (vessel && record && ((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true && (record && (record?.is_cii_adjustment_report ||
                filteredCIIEventsList?.length > 0)))) {
            return DeckReportTabsConstant.STSId;
        } else if (vessel && record && ((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
            && record?.is_sts_considered === true && !(record && (record?.is_cii_adjustment_report ||
                filteredCIIEventsList?.length > 0)))) {
            return DeckReportTabsConstant.STSId - 1;
        } else {
            return -1
        }
    }

    /** Assign tabId to CP Warranties according to mentioned conditions */
    const CPWarrantiesTabId = () => {
        if (vessel && record && record.status_name === EngineStatus.ringFullAway) {
            if (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true && (record && (record?.is_cii_adjustment_report ||
                    filteredCIIEventsList?.length > 0)))) {
                return DeckReportTabsConstant.CPWarrantiesId;
            } else if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && (record && (record?.is_cii_adjustment_report ||
                    filteredCIIEventsList?.length > 0))) ||
                (((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                    && record?.is_sts_considered === true) && !(record && (record?.is_cii_adjustment_report ||
                        filteredCIIEventsList?.length > 0)))
            ) {
                return DeckReportTabsConstant.CPWarrantiesId - 1;
            } else if ((!((vessel?.vessel_type === VesselTypeConstant.TANKER || vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER)
                && record?.is_sts_considered === true) && !(record && (record?.is_cii_adjustment_report ||
                    filteredCIIEventsList?.length > 0)))) {
                return DeckReportTabsConstant.CPWarrantiesId - 2;
            }
        } else {
            return -1;
        }
    }

    /** Assign tabId to Deck Officer reporting's tabs according to mentioned conditions */
    const deckOfficerTabId = (tab: string) => {
        if (ciiStsCpTabsVisible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId;
            } else if (((tab === 'Econ' && record?.reporting_type === ReportingTypeConstant.ATSEA) || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId;
            } else if (tab === 'Remarks') {
                if (env?.additional_info_deck === AddInfoConstant.NONE ||
                    (env?.additional_info_deck === AddInfoConstant.ECON_DECK &&
                        record?.reporting_type !== ReportingTypeConstant.ATSEA)
                ) {
                    return DeckReportTabsConstant.RemarksId - 1;
                } else {
                    return DeckReportTabsConstant.RemarksId
                }
            }
        } else if (ciiStsCpTabsAny2Visible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId - 1;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId - 1;
            } else if (((tab === 'Econ' && record?.reporting_type === ReportingTypeConstant.ATSEA) || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId - 1;
            } else if (tab === 'Remarks') {
                if (env?.additional_info_deck === AddInfoConstant.NONE ||
                    (env?.additional_info_deck === AddInfoConstant.ECON_DECK &&
                        record?.reporting_type !== ReportingTypeConstant.ATSEA)
                ) {
                    return DeckReportTabsConstant.RemarksId - 2;
                } else {
                    return DeckReportTabsConstant.RemarksId - 1;
                }
            }
        } else if (ciiStsCpTabsAny1Visible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId - 2;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId - 2;
            } else if (((tab === 'Econ' && record?.reporting_type === ReportingTypeConstant.ATSEA) || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId - 2;
            } else if (tab === 'Remarks') {
                if (env?.additional_info_deck === AddInfoConstant.NONE ||
                    (env?.additional_info_deck === AddInfoConstant.ECON_DECK &&
                        record?.reporting_type !== ReportingTypeConstant.ATSEA)
                ) {
                    return DeckReportTabsConstant.RemarksId - 3;
                } else {
                    return DeckReportTabsConstant.RemarksId - 2;
                }
            }
        } else if (ciiStsCpTabsNoneVisible()) {
            if (tab === 'DraftsCargoReeferBillBallast') {
                return DeckReportTabsConstant.DraftsCargoReeferBillBallastId - 3;
            } else if (tab === 'ETAD') {
                return DeckReportTabsConstant.ETADId - 3;
            } else if (((tab === 'Econ' && record?.reporting_type === ReportingTypeConstant.ATSEA) || tab === 'Proactive' || tab === 'Lfonds') && env?.additional_info_deck !== AddInfoConstant.NONE) {
                return DeckReportTabsConstant.AdditionalDetailsId - 3;
            } else if (tab === 'Remarks') {
                if (env?.additional_info_deck === AddInfoConstant.NONE ||
                    (env?.additional_info_deck === AddInfoConstant.ECON_DECK &&
                        record?.reporting_type !== ReportingTypeConstant.ATSEA)
                ) {
                    return DeckReportTabsConstant.RemarksId - 4;
                } else {
                    return DeckReportTabsConstant.RemarksId - 3;
                }
            }
        }
    }

    /** Change Lfonds' additional info tabs */
    const toggleAddLfondsTab = (tab: number) => {
        if (tab >= 1 && tab <= (record && record?.reporting_type === ReportingTypeConstant.ATSEA ? 1 :
            ((record?.event_precedence_id === EventPrecedence.DROP_ANCHOR) ||
                (record?.event_precedence_id === EventPrecedence.HEAVE_ANCHOR)) ? 5 :
                ((record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_BERTH) ||
                    (record?.event_precedence_id === EventPrecedence.DEPARTURE_FROM_DRIFTING_PORT)) ? 4 : 3)) {
            setActiveAddLfondsTab(tab);
        }
    }
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Card>
                        <CardHeader className="p-2">
                            <Row>
                                <Col sm="2" className="d-flex align-items-center">
                                    <button color='primary' className='btn btn-primary' onClick={() => { handleVesselState('VESSEL_REPORTING') }}>
                                        <i className="bx bx-chevron-left me-1" /> Back
                                    </button>
                                </Col>
                                <Col sm="10">
                                    <VesselDetailsHeader />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                            {(PreviousVesselReportLoading || CIIAdjustmentEventsLoading) && <Loading message='Loading required data!' />}
                            {PreviousVesselReportError && <ErrorComponent message='Unable to load required data!' />}
                            {!(PreviousVesselReportLoading || CIIAdjustmentEventsLoading) && !PreviousVesselReportError &&
                                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                    <TabContent
                                        activeTab={activeTab}
                                    >
                                        {activeTab === DeckReportTabsConstant.WeatherDataId &&
                                            <TabPane tabId={DeckReportTabsConstant.WeatherDataId} className="tabHeight">
                                                <WeatherData
                                                    lastReocrd={lastRecord}
                                                    record={record}
                                                    vessel={vessel}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === CIIAdjustmentTabId() &&
                                            <TabPane tabId={CIIAdjustmentTabId()} className="">
                                                <CIIAdjustmentComponent
                                                    record={record}
                                                    VesselID={VesselID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                    filteredCIIEventsList={filteredCIIEventsList}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === STSTabId() &&
                                            <TabPane tabId={STSTabId()}
                                                className="tabHeight">
                                                <STSOperationComponent
                                                    record={record}
                                                    lastRecord={PreviousVesselReport}
                                                    VesselID={VesselID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === CPWarrantiesTabId() &&
                                            <TabPane tabId={CPWarrantiesTabId()}>
                                                <CpWarrantyComponent
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    ReportID={ReportID}
                                                    record={record}
                                                    toggleTab={toggleTab}
                                                    activeTab={activeTab}
                                                    lastRecord={lastRecord}
                                                    vessel={vessel}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId("DraftsCargoReeferBillBallast") &&
                                            <TabPane tabId={deckOfficerTabId("DraftsCargoReeferBillBallast")}>
                                                <DraftsCargoLadingComponent
                                                    record={record}
                                                    vessel={vessel}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    ReportID={ReportID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId("ETAD") &&
                                            <TabPane tabId={deckOfficerTabId("ETAD")}>
                                                <ETADComponent
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                    toggleTab={toggleTab}
                                                    key="ETADComponent"
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.ECON_DECK ? "Econ" : "") &&
                                            <TabPane tabId={record?.reporting_type === ReportingTypeConstant.ATSEA ? deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.ECON_DECK ? "Econ" : "") : 0}>
                                                <EconAddInformation
                                                    toggleTab={toggleTab}
                                                    activeTab={activeTab}
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    record={record}
                                                    setErrorMessage={setErrorMessage}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.LFONDS_DECK ? "Lfonds" : "") &&
                                            <TabPane tabId={deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.LFONDS_DECK ? "Lfonds" : "")}>
                                                <LfondsAddInformation
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    record={record}
                                                    vessel={vessel}
                                                    setErrorMessage={setErrorMessage}
                                                    activeAddLfondsTab={activeAddLfondsTab}
                                                    toggleAddLfondsTab={toggleAddLfondsTab}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.PROACTIVE_DECK ? "Proactive" : "") &&
                                            <TabPane tabId={deckOfficerTabId(env?.additional_info_deck === AddInfoConstant.PROACTIVE_DECK ? "Proactive" : "")}>
                                                <ProactiveAddInformation
                                                    ReportID={ReportID}
                                                    VesselID={VesselID}
                                                    VoyageID={VoyageID}
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    record={record}
                                                    lastRecord={lastRecord}
                                                />
                                            </TabPane>
                                        }
                                        {activeTab === deckOfficerTabId("Remarks") &&
                                            <TabPane className="px-2" tabId={deckOfficerTabId("Remarks")}>
                                                <DeckRemarks
                                                    activeTab={activeTab}
                                                    toggleTab={toggleTab}
                                                    record={record}
                                                    handleVesselState={handleVesselState}
                                                    setErrorMessage={setErrorMessage}
                                                    toggleAddLfondsTab={toggleAddLfondsTab}
                                                />
                                            </TabPane>
                                        }
                                    </TabContent>
                                </div>
                            }
                        </CardBody>
                        {/* If we get error from server then call ErrorReport component to show error with fields in footer */}
                        {
                            errorMessage &&
                            <CardFooter>
                                <ErrorReport
                                    errorMessages={errorMessage}
                                    ui_url={location.pathname}
                                    setErrorMessage={setErrorMessage} />
                            </CardFooter>
                        }
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DeckOfficerWizard;
