import { createSlice } from "@reduxjs/toolkit"
import { apiCall } from "../../utils/middlewares/ApiActions";

export interface Reporting {
    [key: string]: string | number | boolean | string[] | any,
}
export type VesselState = null |
    'VIEW' |
    'EDIT' |
    'NEW' |
    'ADDITIONAL_INFO' |
    'VESSEL_CONFIG' |
    'VOYAGE_REPORTING' |
    'VOYAGE_REPORTING_EDIT' |
    'CREATE_VOYAGE_REPORTING' |
    'VESSEL_REPORTING' |
    'CREATE_VESSEL_REPORTING' |
    'DO_REPORTING' |
    'ENGG_REPORTING' |
    'INITIAL_VESSEL_REPORTING' |
    'REPORTING_DATA_IMPORT' |
    'VIEW_IMPORTED_DATA'

export interface ReportingState {
    Vessels: Reporting[],
    onboardedVessels: Reporting[],
    loading: boolean,
    error: string | null,
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    Reports: Reporting[],
    Voyages: Reporting[],
    VesselState: VesselState,
    ReportingImportFileID: number,
}

const initialState: ReportingState = {
    Vessels: [],
    onboardedVessels: [],
    loading: false,
    error: null,
    VesselID: null,
    VoyageID: null,
    ReportID: null,
    Reports: [],
    Voyages: [],
    VesselState: null,
    ReportingImportFileID: null
}

const ReportingSlice = createSlice({
    name: "reporting",
    initialState,
    reducers: {
        apiRequested: (state) => {
            state.loading = true;
        },
        apiRequestedFailed: (state) => {
            state.loading = false;
        },
        setVesselState: (state, action) => {
            state.VesselState = action.payload
        },
        setVesselID: (state, action) => {
            state.VesselID = action.payload
        },
        setVoyageID: (state, action) => {
            state.VoyageID = action.payload
        },
        setReportID: (state, action) => {
            state.ReportID = action.payload
        },
        getVessels: (state, action) => {
            state.Vessels = action.payload;
            state.loading = false;
        },
        getVoyages: (state, action) => {
            state.Voyages = action.payload;
            state.loading = false;
        },
        getReports: (state, action) => {
            state.Reports = action.payload;
            state.loading = false;
        },
        setData: (state, action) => {
            state.Vessels.push(action.payload);
            state.loading = false;
            state.VesselState = null;
        },
        setReportingImportFileID: (state, action) => {
            state.ReportingImportFileID = action.payload
        },
        setOnboardedVessels: (state, action) => {
            state.onboardedVessels = action.payload;
            state.loading = false;
        }
    }
});

export const {
    apiRequested,
    apiRequestedFailed,
    setVesselState,
    setVesselID,
    setVoyageID,
    setReportID,
    getVessels,
    getVoyages,
    getReports,
    setReportingImportFileID,
    setOnboardedVessels
} = ReportingSlice.actions;
export default ReportingSlice.reducer;

// Action functions for generic forms
export const getVesselsAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: getVessels.type,
        onError: apiRequestedFailed.type,
    }
)

export const getVoyagesAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: getVoyages.type,
        onError: apiRequestedFailed.type,
    }
)

export const getReportsAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: getReports.type,
        onError: apiRequestedFailed.type,
    }
)