import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button, Card, CardBody, CardFooter, Col, Input, Label, Row } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { loadCargoHeatingWithkWh } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { FormikProps, useFormik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { queryClient } from 'react-query/queryClient';
import { useEffect } from 'react';
import { SpOpsConfigurationConstant } from 'shared/constants';
import isEqual from "fast-deep-equal";

interface CargoHeatingType {
    setCheckVesselDataValuesBeforeSwitch?: (value: boolean) => void;
    setVesselDataTabName?: (value: string) => void;
    setCheckValuesBeforeSwitch?: (value: boolean) => void;
    setTabName?: (value: string) => void;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    activeTab: any;
    setTabFormik?: (value: FormikProps<any>) => void;
    refreshForm:boolean;
    refreshVesselMachineries:number;
}

const CargoHeating = ({
    setCheckVesselDataValuesBeforeSwitch,
    setVesselDataTabName,
    setCheckValuesBeforeSwitch,
    setTabName,
    activeTab,
    setTabFormik,
    refreshForm,
    refreshVesselMachineries,
}: CargoHeatingType) => {
    /** Get the VesselID from the Redux store */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /** 
     * Fetches cargo heating machinery data with kWh meter information.
     * Uses the `useQuery` hook to load the cargo heating machinery associated with the vessel.
     */
    const { data: cargoHeatingObject, isLoading: cargoHeatingLoading, isError: cargoHeatingError } = useQuery(
        [queryKeyes.vessel.CargoHeatingWithkWhMachineries.key, VesselID],
        async () => await loadCargoHeatingWithkWh(VesselID),
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /** 
     * Initializes the form values based on the cargo heating data.
     * 
     * @returns Cargo heating data to be used as initial values in Formik.
     */
    const getInitialValueSpecialOperationCargoHeatingForm = () => {
        if (cargoHeatingObject?.length > 0 && cargoHeatingObject[0].id > 0) {
            return cargoHeatingObject;
        } else {
            return [];
        }
    };

    /** Formik configuration object for cargo heating form */
    const SpecialOperationCargoHeatingFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            SpecialOperationEc: getInitialValueSpecialOperationCargoHeatingForm(),
        },
        onSubmit: () => {
            SpecialOperationCargoHeatingFormik.setSubmitting(true);
            SpecialOperationCargoHeatingFormik?.values?.SpecialOperationEc?.foreach((machine: any) => {
                apiGlobal
                    .put(`/special_operation_energy_setting/${machine.id}/`, machine)
                    .then((res) => {
                        if (res.status === 200) {
                            successToast("Data saved successfully!");
                            queryClient.invalidateQueries(queryKeyes.vessel.CargoHeatingWithkWhMachineries.key);
                        }
                    })
                    .catch((err) => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occurred, please contact the admin");
                        }
                    });
            });
            SpecialOperationCargoHeatingFormik.setSubmitting(false);
        }
    });

    const tabName = 'Cargo Heating';
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = isEqual(values, initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName(tabName);
        setCheckVesselDataValuesBeforeSwitch(areValuesEqual);
        setVesselDataTabName(tabName);
        return areValuesEqual;
    };

    /**Start of UseEffect */
    useEffect(() => {
        if (activeTab === SpOpsConfigurationConstant.SpOpsCargoHeatingId && SpecialOperationCargoHeatingFormik.values !== SpecialOperationCargoHeatingFormik.initialValues) {
            setTabFormik(SpecialOperationCargoHeatingFormik);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab, SpecialOperationCargoHeatingFormik]);
    useEffect(() => {
        handleFormikStateChange(SpecialOperationCargoHeatingFormik?.values, SpecialOperationCargoHeatingFormik?.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SpecialOperationCargoHeatingFormik?.values, SpecialOperationCargoHeatingFormik?.initialValues]);

    if (cargoHeatingLoading) {
        return <Loading message='Loading required data!' />
    } else if (cargoHeatingError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <Card className="border-0">
                {cargoHeatingLoading && <Loading message="Loading required data!" />}
                {cargoHeatingError && <ErrorComponent message="Unable to load required data!" />}
                {!cargoHeatingLoading && !cargoHeatingError &&
                    <form onSubmit={SpecialOperationCargoHeatingFormik?.handleSubmit} noValidate autoComplete="off">
                        <CardBody className="p-0">
                            <Row>
                                <Col sm={12}>
                                    <div className="table-responsive mb-5">
                                        <table className="table" key={`${refreshVesselMachineries}-${refreshForm}-cargo-heating`}>
                                            <thead className="table-light">
                                                <tr>
                                                    <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                    <th className='p-2 align-middle'>Machinery name</th>
                                                    <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <>
                                                    {SpecialOperationCargoHeatingFormik?.initialValues &&
                                                        SpecialOperationCargoHeatingFormik?.initialValues?.SpecialOperationEc?.map(
                                                            (cargoHeating: any, index: any) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                        <td className='p-2 align-middle'>{cargoHeating.vessel_machinery_ec_group_name}</td>
                                                                        <td className='p-2 align-middle text-center'>
                                                                            <div className="square-switch sqswitch mt-1">
                                                                                <Input
                                                                                    type="checkbox"
                                                                                    switch="none"
                                                                                    name={`SpecialOperationEc.${index}.cargo_heating_kwh_meter`}
                                                                                    id={`cargo_heating_kwh_meter${index}`}
                                                                                    defaultChecked={SpecialOperationCargoHeatingFormik?.values?.SpecialOperationEc[index]?.cargo_heating_kwh_meter}
                                                                                    onChange={(e: any) => SpecialOperationCargoHeatingFormik?.setFieldValue(`SpecialOperationEc.${index}.cargo_heating_kwh_meter`, e.target.checked)}
                                                                                />
                                                                                <Label
                                                                                    htmlFor={`cargo_heating_kwh_meter${index}`}
                                                                                    data-on-label="Yes"
                                                                                    data-off-label="No"
                                                                                    className="mb-0"
                                                                                ></Label>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )}
                                                </>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="p-2 py-3 mb-3">
                            <Button type="submit" color="primary" className="justify_right" disabled={isConfigurationButtonDisabled(vessel)}>
                                Save
                            </Button>
                        </CardFooter>
                        <Row className="mt-2">
                            <FormValuesDebug values={[SpecialOperationCargoHeatingFormik?.values, SpecialOperationCargoHeatingFormik?.errors, SpecialOperationCargoHeatingFormik.initialValues]} />
                        </Row>
                    </form>}
            </Card>
        )
    };
};

export default CargoHeating;
