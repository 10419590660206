import ErrorComponent from 'Components/ErrorComponent';
import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import env from 'environment_system/env_system';
import { FormikProps, useFormik } from 'formik';
import { checkEmptyStringValue, handleServerResponse, isConfigurationButtonDisabled } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, CardBody, Col, Form, Input, Label, Row } from "reactstrap";
import { customStyle } from 'shared/CommonCSS';
import { VesselConfigrationTabs, VesselTypeConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadVesselOtherSettingsOperation } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';
import isEqual from "fast-deep-equal";

interface OtherOtherSettingFormik {
    setCheckValuesBeforeSwitch: (value: boolean) => void;
    setTabName: (value: string) => void;
    VesselConfActiveTab: any;
    setFormik?: (value: FormikProps<any>) => void;
    formik?: FormikProps<any>;
    refreshForm?: boolean;
    setDisableYesButton?: (value: boolean) => void;
    saveBeforeChangePopUp?: boolean;
}

const Other = ({ setCheckValuesBeforeSwitch, setTabName, VesselConfActiveTab, setFormik, refreshForm, setDisableYesButton, saveBeforeChangePopUp }: OtherOtherSettingFormik) => {
    /** State variables */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);
    const [count, setCount] = useState(0);
    const [windRefreshkey, setWindRefreshKey] = useState(0);
    const [EEDIRefreshkey, setEEDIRefreshKey] = useState(0);
    const fwindOptions = [
        { value: 0.95, label: 0.95 },
        { value: 0.97, label: 0.97 },
        { value: 0.99, label: 0.99 },
    ];
    let vessel = Vessels.find((rec: any) => rec.id === VesselID) ?? null;
    /** State variables end */

    /** Queries */
    /** Vessel's other setting;s object used for edit */
    const { data: OtherSettings, isLoading: OtherSettingsLoading, isError: OtherSettingsError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
            async () => { return await loadVesselOtherSettingsOperation(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** End Queries */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        if (OtherSettings && OtherSettings[0]?.id > 0) {
            return OtherSettings[0];
        } else {
            return ({
                is_vse_mention: false,
                vse_value: null,
                reefer_container_name: "",
                is_wind_assistance_system_available: false,
                pwind: null,
                fwind: null,
                pprop: null,
                power_rating: null,
                is_steam_dump_valve_in_use: false,
                reefer_container_available: false,
                vessel: VesselID
            })
        }
    }

    /** Other Setting's formik object */
    const OtherSettingFormik: any = useFormik({
        enableReinitialize: true,
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            vse_value: Yup.number().nullable().when('is_vse_mention', {
                is: true,
                then: (schema: any) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }),
            pwind: Yup.number().nullable(),
            pprop: Yup.number().nullable(),
            fwind: Yup.number().when('is_wind_assistance_system_available', {
                is: true,
                then: (schema: any) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }).max(1, commonValidationMessages.max1).min(0, commonValidationMessages.min0),
        }),
        onSubmit: async () => {
            OtherSettingFormik.setSubmitting(true);
            let responseArray: any[] = [];
            if (!OtherSettingFormik?.values?.is_vse_mention) {
                OtherSettingFormik.values.vse_value = null; // Reset to empty string if unchecked
            }
            if (!OtherSettingFormik?.values?.is_wind_assistance_system_available) {
                OtherSettingFormik.values.fwind = null; // Reset to empty string if unchecked
                OtherSettingFormik.values.pwind = null; // Reset to empty string if unchecked
                OtherSettingFormik.values.pprop = null;
            }
            if (OtherSettings && OtherSettings[0]?.id > 0) {
                responseArray.push(apiGlobal.put(`/other_setting/${OtherSettingFormik?.values?.id}/`, OtherSettingFormik?.values));
            } else {
                responseArray.push(apiGlobal.post(`/other_setting/`, OtherSettingFormik?.values));
            }
            /** handle server response */
            await handleServerResponse(responseArray).then(async (res) => {
                if (res) {
                    await queryClient.invalidateQueries(queryKeyes.vessel.VesselOtherSettingsObject.key);
                    setCount(count + 1);
                }
            });
            OtherSettingFormik.setSubmitting(false);
        }
    })

    if (OtherSettingsError) {
        setCount(count + 1);
    }

    /** Handle formik state changes */
    const handleFormikStateChange = (values: any, initialValues: any) => {
        const areValuesEqual = isEqual(values, initialValues);
        setCheckValuesBeforeSwitch(areValuesEqual);
        setTabName('Other');
        return areValuesEqual;
    };

    // useEffect(() => {
    //     if (VesselConfActiveTab === VesselConfigrationTabs.OTHER && formik !== OtherSettingFormik) {
    //         setFormik(OtherSettingFormik);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [VesselConfActiveTab, formik, OtherSettingFormik]);
    useEffect(() => {
        if (VesselConfActiveTab === VesselConfigrationTabs.OTHER && OtherSettingFormik.values !== OtherSettingFormik.initialValues) {
            Object.keys(OtherSettingFormik?.errors).length > 0? setDisableYesButton(true) : setDisableYesButton(false);
            setFormik(OtherSettingFormik); // Example: Assign formik instance for the "Other" tab
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselConfActiveTab, OtherSettingFormik, OtherSettingFormik?.errors]);

    useEffect(() => {
        if (VesselConfActiveTab !== VesselConfigrationTabs.OTHER) {
            OtherSettingFormik?.setTouched({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselConfActiveTab]);
    useEffect(() => {
        handleFormikStateChange(OtherSettingFormik.values, OtherSettingFormik.initialValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [OtherSettingFormik.values, OtherSettingFormik.initialValues]);

    if (OtherSettingsLoading) {
        return <Loading message='Loading required data!' />
    } else if (OtherSettingsError) {
        return <ErrorComponent message='Unable to load required data!' />
    } else {
        return (
            <CardBody className='pb-0 pt-0'>
                {OtherSettingsLoading && <Loading message='Loading required data!' />}
                {!OtherSettingsLoading &&
                    <Form autoComplete="off" onSubmit={OtherSettingFormik?.handleSubmit} noValidate key={`${refreshForm}-${count}`}>
                        <Row className='mb-2'>
                            <Col sm={4}>
                                <div className="d-flex flex-wrap mt-4">
                                    <Label className='width-17' for='is_vse_mention'>Is f<sub>iVSE</sub> mentioned in EEDI / EEXI?</Label>
                                    <div className="square-switch sqswitch">
                                        <Input
                                            type="checkbox"
                                            switch="none"
                                            name="is_vse_mention"
                                            id="is_vse_mention"
                                            onChange={(e: any) => {
                                                OtherSettingFormik?.setFieldValue("is_vse_mention", e.target.checked)
                                                if (e.target.checked === false) {
                                                    OtherSettingFormik?.setFieldValue("vse_value", null)
                                                    setEEDIRefreshKey(EEDIRefreshkey + 1)
                                                }
                                            }}
                                            checked={OtherSettingFormik?.values?.is_vse_mention}
                                        />
                                        <Label
                                            for="is_vse_mention"
                                            data-on-label='Yes'
                                            data-off-label='No'
                                            className='mb-0'
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} key={EEDIRefreshkey}>
                                <Label className='mb-0 asteric' for=''>f<sub>iVSE</sub> value</Label>
                                <Input
                                    type="text"
                                    name="vse_value"
                                    id="vse_value"
                                    value={OtherSettingFormik?.values?.vse_value}
                                    onChange={(e: any) => {
                                        checkEmptyStringValue(e, "vse_value", OtherSettingFormik?.setFieldValue);
                                    }}
                                    className="form-control max-width-7 text-right mb-2"
                                    disabled={!OtherSettingFormik?.values?.is_vse_mention}
                                />
                                {
                                    OtherSettingFormik?.errors && env?.form_validation === true && OtherSettingFormik?.touched?.vse_value &&
                                    OtherSettingFormik?.values?.is_vse_mention === true &&
                                    <ErrorTooltip
                                        target='vse_value'
                                        message={OtherSettingFormik?.errors?.vse_value}
                                        open={!saveBeforeChangePopUp && VesselConfActiveTab === VesselConfigrationTabs.OTHER && OtherSettingFormik?.errors?.vse_value ? true : false} 
                                    />
                                }
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col sm={4}>
                                <div className="d-flex flex-wrap mt-4">
                                    <Label className='width-17' for='is_wind_assistance_system_available'>
                                        Is wind assistance system available?
                                    </Label>
                                    <div className="square-switch sqswitch">
                                        <Input
                                            type="checkbox"
                                            switch="none"
                                            name="is_wind_assistance_system_available"
                                            id="is_wind_assistance_system_available"
                                            onChange={(e: any) => {
                                                OtherSettingFormik?.setFieldValue("is_wind_assistance_system_available", e.target.checked)
                                                if (e.target.checked === false) {
                                                    OtherSettingFormik?.setFieldValue(`fwind`, null)
                                                    OtherSettingFormik?.setFieldValue('pwind', null)
                                                    OtherSettingFormik?.setFieldValue('pprop', null)
                                                    setWindRefreshKey(windRefreshkey + 1)
                                                }
                                            }
                                            }
                                            checked={OtherSettingFormik?.values?.is_wind_assistance_system_available}
                                        />
                                        <Label
                                            for="is_wind_assistance_system_available"
                                            data-on-label='Yes'
                                            data-off-label='No'
                                            className='mb-0'
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col sm={2} >
                                <Label className='mb-0 asteric' for=''>f<sub>wind</sub></Label>
                                <Select
                                    key={windRefreshkey}
                                    name={'fwind'}
                                    options={fwindOptions as any[]}
                                    getOptionLabel={(option: any) => option.label}
                                    getOptionValue={(option: any) => option.label}
                                    value={{
                                        id: OtherSettingFormik?.values?.fwind,
                                        label: OtherSettingFormik?.values?.fwind
                                    }}
                                    onChange={(e: any) =>
                                        OtherSettingFormik?.setFieldValue('fwind', e?.label)
                                    }
                                    styles={customStyle}
                                    isDisabled={!OtherSettingFormik?.values?.is_wind_assistance_system_available}
                                />
                            </Col>
                            <Col sm={2}>
                                <Label className='mb-0' for=''>P<sub>wind</sub></Label>
                                <div className='input-group'>
                                    <Input
                                        key={windRefreshkey}
                                        type="text"
                                        name="pwind"
                                        id="pwind"
                                        className="form-control max-width-7 text-right"
                                        onChange={OtherSettingFormik?.handleChange}
                                        onBlur={OtherSettingFormik?.handleChange}
                                        defaultValue={OtherSettingFormik?.values?.pwind}
                                        disabled={!OtherSettingFormik?.values?.is_wind_assistance_system_available}
                                    />
                                    <div className='input-group-text'>kW</div>
                                </div>
                            </Col>
                            <Col sm={2}>
                                <Label className='mb-0' for=''>P<sub>prop</sub></Label>
                                <div className='input-group'>
                                    <Input
                                        key={windRefreshkey}
                                        type="text"
                                        name="pprop"
                                        id="pprop"
                                        className="form-control max-width-7 text-right"
                                        onChange={OtherSettingFormik?.handleChange}
                                        onBlur={OtherSettingFormik?.handleChange}
                                        defaultValue={OtherSettingFormik?.values?.pprop}
                                        disabled={!OtherSettingFormik?.values?.is_wind_assistance_system_available}
                                    />
                                    <div className='input-group-text'>kW</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mb-2'>
                            <Col sm={4}>
                                <div className="d-flex flex-wrap mt-4">
                                    <Label className='width-17' for='is_steam_dump_valve_in_use'>
                                        Is Steam dump valve used?<br />(Only for steam ship)
                                    </Label>
                                    <div className="square-switch sqswitch">
                                        <Input
                                            type="checkbox"
                                            switch="none"
                                            name="is_steam_dump_valve_in_use"
                                            id="is_steam_dump_valve_in_use"
                                            checked={OtherSettingFormik?.values?.is_steam_dump_valve_in_use}
                                            onChange={(e: any) => OtherSettingFormik?.setFieldValue("is_steam_dump_valve_in_use", e.target.checked)}
                                        />
                                        <Label
                                            for="is_steam_dump_valve_in_use"
                                            data-on-label='Yes'
                                            data-off-label='No'
                                            className='mb-0'
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {vessel?.vessel_type === VesselTypeConstant.CONTAINER &&
                            <Row className='mb-2'>
                                <Col sm={4}>
                                    <div className="d-flex flex-wrap mt-4">
                                        <Label className='width-17' for='reefer_container_available'>
                                            Does this vessel have a provision to carry reefer container?
                                        </Label>
                                        <div className="square-switch sqswitch">
                                            <Input
                                                type="checkbox"
                                                switch="none"
                                                name="reefer_container_available"
                                                id="reefer_container_available"
                                                onChange={(e: any) => OtherSettingFormik?.setFieldValue("reefer_container_available", e.target.checked)}
                                                defaultChecked={OtherSettingFormik?.values?.reefer_container_available}
                                            />
                                            <Label
                                                for="reefer_container_available"
                                                data-on-label='Yes'
                                                data-off-label='No'
                                                className='mb-0'
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button type="submit" color="primary" className='btn_size4_5_cstm' disabled={isConfigurationButtonDisabled(vessel)}>Save</Button>
                                <Button type="reset" color="danger" className='btn_size4_5_cstm' disabled={isConfigurationButtonDisabled(vessel)}>Reset</Button>
                            </div>
                        </Row>
                        <FormValuesDebug values={[OtherSettingFormik?.values, OtherSettingFormik?.errors, OtherSettingFormik.initialValues]} />
                    </Form>
                }
                <p> - Set values.</p>
            </CardBody>
        )
    }
}

export default Other
