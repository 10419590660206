import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
// Redux
import { Link } from "react-router-dom";
// users
import male from "../../assets/images/users/avatar-1.jpg"
import female from "../../assets/images/users/femaleavatar-1.jpg"
import other from "../../assets/images/users/other.jpg"
//redux
import { clearLogout, getAuthData } from "utils/auth/authUtils";
import { successToast } from "Components/Toasts";
import env from "environment_system/env_system";
import { useQuery } from "react-query";
import { fetchUserProfileById } from "VesselMaster/vesselMaster.hooks";
import { queryKeyes } from "shared/queryKeys";

const ProfileMenu = () => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("User");

  useEffect(() => {
    const getAuthUser = getAuthData();
    if (getAuthUser) {
      setusername(getAuthUser['first_name']);
    }
  }, []);

  const userId = getAuthData('user_id');
  const { data: profileData }:
    { data: any, isLoading: any, isError: any } = useQuery(
      [queryKeyes.user.profileID.key, userId],
      async () => { return await fetchUserProfileById(userId) },
      {
        enabled: true,
        staleTime: Infinity,
      }
    );

  const handleLogout = () => {
    clearLogout();
    successToast('Success! Redirecting to Dashboard', env['base_name']);
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light"
          id="page-header-user-dropdown"
          tag="button"
        >
          {(profileData?.gender === null) || (profileData?.gender === "Male") ?
            <img
              src={male}
              alt=""
              className="avatar-1 rounded-circle img-thumbnail "
              style={{ maxHeight: "3rem" }}
            />
            : (profileData?.gender === "Female") ?
              <img
                src={female}
                alt=""
                className="avatar-1 rounded-circle img-thumbnail "
                style={{ maxHeight: "3rem" }}
              /> :
              <img
                src={other}
                alt=""
                className="avatar-1 rounded-circle img-thumbnail "
                style={{ maxHeight: "3rem" }}
              />
          }
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {username}<br />
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <Link to={"/contacts-profile"} className="dropdown-item">
            <i className="bx bx-user font-size-16 align-middle me-1" />
            Profile
          </Link>
          {env.help && (
            <React.Fragment>
              <div className="dropdown-divider" />
              <Link to={'/help'} className="dropdown-item">
                <i className="bx bx-help-circle font-size-16 align-middle me-1" />
                Help
              </Link>
            </React.Fragment>
          )}
          <div className="dropdown-divider" />
          <Link to={'/login'} onClick={handleLogout} className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default (ProfileMenu);
