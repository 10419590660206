import DeletePopOver from 'Components/DeletePopOver';
import ErrorTooltip from 'Components/ErrorTooltip';
import { errorToast, successToast } from 'Components/Toasts';
import ToolTip from 'Components/ToolTip';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import env from 'environment_system/env_system';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import { errResponse, setSelectValueToAllFields } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Card, Col, Form, Label, Row } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadLubeOilMaster } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';

interface CreateLubeOilType {
    setCreateNewBool: (value: boolean) => void;
}

const CreateLubeOil = ({ setCreateNewBool }: CreateLubeOilType) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [popOverBool, setPopOverBool] = useState<boolean | null>(false);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [refreshKey, setRefreshKey] = useState<number | null>(0);
    const [removeFn, setRemoveFn] = useState<((index: number) => void) | null>(null);
    /** State variables end */

    /** Assign initial values to Formik object */
    const getInitialValues = () => {
        return [
            {
                vessel_lube_oil_name: '',
                status: false,
                vessel: VesselID,
                lube_oil_name: null as number,
            },
        ];
    };

    const validationSchema = Yup.object().shape({
        lubeOil: Yup.array().of(
            Yup.object().shape({
                vessel_lube_oil_name: Yup.string().required(commonValidationMessages.required),
                lube_oil_name: Yup.number().required(commonValidationMessages.required),
            })
        ),
    });

    /** Vessel lube oil formik object */
    const CreateLubeOilFormik = {
        initialValues: {
            lubeOil: getInitialValues(),
        },
        validationSchema: validationSchema,
    };

    const { data: LubeOils, isLoading: lubeOilsLoading, isError: lubeOilsError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.masters.LubeOilMaster.key],
            async () => {
                return await loadLubeOilMaster();
            },
            {
                enabled: true,
                staleTime: Infinity,
            }
        )

    return (
        <Card className="p-2 mb-0 border-0">
            <Formik
                onSubmit={(values: { [key: string]: { [key: string]: string | number | boolean | null }[] }, _actions: { setSubmitting: (arg: boolean) => void }) => {
                    values?.lubeOil?.forEach((lubeOil: { [key: string]: string | number | boolean | null }) => {
                        lubeOil.lube_oil_name = values?.lubeOil[0]?.lube_oil_name;
                    });
                    apiGlobal
                        .post(`/vessel_lube_oil_list/`, values?.lubeOil)
                        .then(() => {
                            successToast('Data saved successfully!');
                            queryClient.invalidateQueries(queryKeyes.vessel.VesselLubeOils.key);
                            setCreateNewBool(false);
                        })
                        .catch((err) => {
                            if (errResponse.includes(err.response.status)) {
                                errorToast('Internal error occurred, please contact the admin');
                            }
                        });
                }}
                initialValues={CreateLubeOilFormik.initialValues as { [key: string]: { [key: string]: string | number | boolean | null }[] }}
                validationSchema={CreateLubeOilFormik.validationSchema}
                key={refreshKey}
            >
                {(props: any) => (
                    <Form onSubmit={props.handleSubmit} noValidate autoComplete="off">
                        <Row className="mb-2">
                            <Col sm={8}>
                                <Label className="asteric mb-0" for="vessel_lube_oil">
                                    Select Lube Oil
                                </Label>
                                <Field name="lubeOil.0.lube_oil_name">
                                    {({ field }: FieldProps) => (
                                        <Select
                                            name={field.name}
                                            id={`lubeOil_0_lube_oil_name`}
                                            options={LubeOils || []} // Ensure options is an empty array if LubeOils is undefined
                                            getOptionLabel={(e: { lube_oil_name?: string | null }) => e?.lube_oil_name ?? "Unknown"}
                                            getOptionValue={(e: { [key: string]: string | number | boolean | null }) => e.id as string}
                                            className="select-height"
                                            styles={customStyle}
                                            isLoading={lubeOilsLoading} // Show loading state
                                            isDisabled={lubeOilsLoading || lubeOilsError} // Disable if loading or error
                                            value={LubeOils?.find(option => option?.id === field.value) ?? null}
                                            onChange={(e: any) => setSelectValueToAllFields(
                                                e?.id,
                                                "lube_oil_name",
                                                props?.values?.lubeOil,
                                                props,
                                                'lubeOil')}
                                        />
                                    )}
                                </Field>
                                {props?.errors?.lubeOil?.[0]?.lube_oil_name &&
                                    props?.touched?.lubeOil?.[0]?.lube_oil_name &&
                                    env.form_validation && (
                                        <ErrorTooltip
                                            target={`lubeOil_0_lube_oil_name`}
                                            message={props.errors.lubeOil?.[0].lube_oil_name}
                                            open={!!props.errors.lubeOil?.[0].lube_oil_name}
                                        />
                                    )}
                            </Col>
                        </Row>
                        <FieldArray name="lubeOil">
                            {({ push, remove }) => (
                                <React.Fragment>
                                    {props?.values?.lubeOil?.map((_lubeOil: { [key: string]: string | number | boolean | null }, index: number) => {
                                        const fieldId = `lubeOil_${index}_vessel_lube_oil_name`;
                                        return (
                                            <React.Fragment key={index}>
                                                <Row className="mb-2">
                                                    <Col sm={8}>
                                                        <Label className="asteric mb-0" for={fieldId} id='lube_oil_msg'>
                                                            Lube Oil name
                                                            <i className="bx bx-info-circle ml-2p" />
                                                        </Label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            id={fieldId}
                                                            name={`lubeOil.${index}.vessel_lube_oil_name`}
                                                        />
                                                        {props?.errors?.lubeOil?.[index]?.vessel_lube_oil_name &&
                                                            props?.touched?.lubeOil?.[index]?.vessel_lube_oil_name &&
                                                            env.form_validation && (
                                                                <ErrorTooltip
                                                                    target={fieldId}
                                                                    message={props?.errors?.lubeOil?.[index].vessel_lube_oil_name}
                                                                    open={!!props?.errors?.lubeOil?.[index]?.vessel_lube_oil_name}
                                                                />
                                                            )}
                                                    </Col>
                                                    <Col sm={4}>
                                                        <div className="ele_row1 align-middle">
                                                            {props?.values?.lubeOil?.length === 1 ? null : (
                                                                <button
                                                                    type="button"
                                                                    className="btn mt-4"
                                                                    onClick={() => {
                                                                        setDeleteIndex(index);
                                                                        setRemoveFn(() => remove);
                                                                        setPopOverBool(true);
                                                                    }}
                                                                >
                                                                    <i
                                                                        id={`delete_lube_oil${index}`}
                                                                        className="dripicons-trash icon_s18 cursor-pointer"
                                                                        style={{ cursor: 'pointer' }}
                                                                    />
                                                                </button>
                                                            )}
                                                            {index === props?.values?.lubeOil?.length - 1 &&
                                                                props?.values?.lubeOil?.length < 10 && (
                                                                    <Button
                                                                        type="button"
                                                                        className="btn"
                                                                        color="primary mt-4"
                                                                        onClick={() =>
                                                                            push({
                                                                                vessel_lube_oil_name: '',
                                                                                status: false,
                                                                                vessel: VesselID,
                                                                                lube_oil_name: props?.values?.lubeOil?.[0]?.lube_oil_name,
                                                                            })
                                                                        }
                                                                    >
                                                                        <i className="dripicons-plus icon_s18 navbar_menu" />
                                                                    </Button>
                                                                )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                        </FieldArray>
                        {popOverBool && removeFn && deleteIndex !== null && (
                            <DeletePopOver
                                target={`delete_lube_oil${deleteIndex}`}
                                state={popOverBool}
                                setState={setPopOverBool}
                                onClick={async () => {
                                    removeFn(deleteIndex);
                                    setPopOverBool(false);
                                }}
                            />
                        )}
                        <ToolTip
                            target="lube_oil_msg"
                            message="Enter the generic name of the Lub oil observed on the vessel. Note this name will be displayed in the reports for tracking the supplies and consumption."
                        />
                        <Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button type="submit" color="primary" className="btn_size4_5_cstm">
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                    onClick={() => setRefreshKey(refreshKey + 1)}
                                >
                                    Reset
                                </Button>
                            </div>
                        </Row>
                        <FormValuesDebug values={[props.values, props.errors, CreateLubeOilFormik.initialValues]} />
                    </Form>
                )}
            </Formik>
        </Card>
    );
};

export default CreateLubeOil;
